import { FC } from "react";
import { FancyTextWidgetProps } from "../../../../database/orderPage";
import FancyTextComponent from "../../../Dashboard/Builder/FancyText/fancyTextComponent";

/**
 * In the process of being replaced by the page builder's version
 * @deprecated
 */
const FancyTextWidget: FC<FancyTextWidgetProps> = ({
  text,
  overflow,
  style,
}) => {
  return (
    <FancyTextComponent value={style} noWrap={!overflow} align={style.align} />
  );
};

export default FancyTextWidget;
