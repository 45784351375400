import { memo, Fragment, FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MessageThread, Message } from "../../../database/message";
import {
  useMessageThread,
  useMarkReadMessages,
} from "../../../utilities/useMessages";
import uuid from "uuid/v4";
import { FirestoreUpdate } from "../../../utilities/firestoreUpdateType";
import useRefreshInterval from "../../../utilities/useRefreshInterval";
import useSiteUser from "../../UserProvider/useSiteUser";
import CommSettings from "../Components/commSettings";
import { storeKey } from "../../../Firebase/siteFirebase";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { useSiteFirebase } from "../../../Firebase/context";
import { history } from "../../App/history";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MUIPopover from "../../Dashboard/Components/mui-popover";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import getTimeString from "../../../utilities/getTimeString";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { INPUT_MAX_LENGTH } from "../../../utilities/constants/appConstants";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useLocation } from "react-router-dom";
import useToast from "../../Main/useToast";

interface MessageThreadModalProps {
  threadId: string;
  /**
   * This prop can be used to show content immediately, if the
   * parent component already has a copy of the thread. The modal
   * will set up its own subscription either way
   */
  initialThread?: MessageThread;
  onCancel?: () => void;
}

const MessageThreadModal: FC<MessageThreadModalProps> = memo(
  ({ threadId, initialThread, onCancel }) => {
    const { user } = useSiteUser();
    const { t } = useTranslation();
    const location = useLocation<{ commSettings?: boolean }>();
    const siteSettings = useSiteSettings();
    const firebase = useSiteFirebase();
    const maxLength = INPUT_MAX_LENGTH;
    const toast = useToast();
    // Periodically update so the time labels stay up to date
    useRefreshInterval(10000);
    const { thread, messages, error } = useMessageThread(
      threadId,
      initialThread
    );

    useEffect(() => {
      if (error) {
        let message = error.message;
        if (error.code === "permission-denied") {
          message = t("store.messages.permissionDenied");
        }
        toast({
          dialog: true,
          message,
          color: "error",
          onUnmount: () => {
            if (onCancel) {
              onCancel();
            } else {
              history.goBack();
            }
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    //UPDATE THREAD AS READ SINCE THEY OPENED IT
    useMarkReadMessages(threadId, messages, "customer");
    const [message, setMessage] = useState("");
    const sendMessage = async () => {
      if (!message) {
        return;
      }
      setMessage("");
      const messageId = uuid();
      const now = new Date();
      const newMessage: FirestoreUpdate<Message> = {
        messageId,
        from: "customer",
        message,
        timeSent: now,
        resolved: false,
        read: false,
      };
      try {
        await firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("messageThreads")
          .doc(threadId)
          .set(
            {
              read: false,
              resolved: false,
              messages: {
                [messageId]: newMessage,
              },
              latestMessageTimestamp: now,
            },
            { merge: true }
          );
      } catch (error) {
        if (error.code === "permission-denied") {
          toast({
            dialog: true,
            message: t("store.messages.permissionDenied"),
            color: "error",
          });
        } else {
          toast({
            dialog: true,
            message: t("toast.systemErrorCall"),
            color: "error",
          });
        }
      }
    };

    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    return (
      <Fragment>
        <DialogContent
          className="smartWidthMd"
          style={{ paddingLeft: "0", paddingRight: 0 }}
        >
          {siteSettings.phone && (
            <Typography align="center" style={{ padding: "0 1em" }}>
              {t("store.contactUs.ratherCall")}
              &nbsp;
              <a
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "black",
                  wordBreak: "break-word",
                }}
                href={`tel:+${siteSettings.phone.raw}`}
              >
                {siteSettings.phone.formatted}
              </a>
            </Typography>
          )}

          {user && thread && (
            <div style={{ textAlign: "center", padding: "0 1em" }}>
              <Button
                size="small"
                variant="outlined"
                style={{
                  marginTop: "1em",
                  marginBottom: "1em",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (location.state?.commSettings !== true)
                    history.push(location.pathname, {
                      ...location.state,
                      commSettings: true,
                    });
                  setAnchor(e.currentTarget);
                }}
              >
                {t("store.orders.commSettings")}
              </Button>
              {anchor && (
                <MUIPopover
                  anchorEl={anchor}
                  route={""}
                  stateMatch={(state) => Boolean(state.commSettings)}
                >
                  <CommSettings
                    email={thread.email}
                    phone={thread.phone ?? undefined}
                  />
                </MUIPopover>
              )}
            </div>
          )}

          {thread ? (
            <Fragment>
              <div
                style={{
                  padding: "0.25em 1em 0.25em 1em",
                  maxHeight: "35vh",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                  backgroundColor: "white",
                  marginBottom: "max(1vw, 1em)",
                }}
              >
                <div style={{}}>
                  {messages.map((message, index) => (
                    <Typography
                      component="div"
                      align="left"
                      key={message.messageId}
                      style={{ wordBreak: "break-word" }}
                    >
                      <Typography
                        component="div"
                        color="textSecondary"
                        variant="body2"
                      >
                        {message.from === "customer"
                          ? `${t("store.orders.you")} ${
                              getTimeString({
                                request: "created",
                                currentTime: new Date(),
                                timePlanned: null,
                                timePlannedOrig: null,
                                timeCreated: message.timeSent.toDate(),
                                timeCompleted: null,
                                orderIncrement: null,
                                text: "",
                                t,
                              }).timeCreated
                            }`
                          : `${t("store.orders.admin")} ${
                              getTimeString({
                                request: "created",
                                currentTime: new Date(),
                                timePlanned: null,
                                timePlannedOrig: null,
                                timeCreated: message.timeSent.toDate(),
                                timeCompleted: null,
                                orderIncrement: null,
                                text: "",
                                t,
                              }).timeCreated
                            }`}
                      </Typography>
                      <div
                        style={{
                          marginBottom: "0.5em",
                          wordBreak: "break-word",
                        }}
                      >
                        {message.message}
                      </div>
                    </Typography>
                  ))}
                </div>
              </div>
            </Fragment>
          ) : (
            <Typography
              component="div"
              className="anim_fadeInOut"
              style={{
                opacity: "0",
                width: "100%",
                height: "8em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography color="textSecondary">
                {t("store.orders.loading")}
              </Typography>
            </Typography>
          )}

          <FormControl fullWidth style={{ padding: "0 1em" }}>
            <TextField
              label={t("dashboard.messages.enterMessage")}
              multiline
              rowsMax={8}
              fullWidth={true}
              value={message}
              onChange={(e) =>
                setMessage(e.target.value.substring(0, maxLength))
              }
            />
            {maxLength - message.length < 31 && (
              <FormHelperText
                className="anim_fadeIn_0503"
                style={{ opacity: 0, paddingLeft: "0.25em" }}
              >
                {t("store.orders.charactersLeft", {
                  count: maxLength - message.length,
                })}
              </FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <span style={{ flexGrow: 1 }}></span>
          <Button
            onClick={() => {
              if (onCancel) {
                onCancel();
              } else {
                history.goBack();
              }
            }}
          >
            {t("store.close")}
          </Button>
          <Button
            onClick={(e) => {
              sendMessage();
            }}
          >
            {t("store.send")}
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
);

export default MessageThreadModal;
