import * as R from "ramda";
import hash from "object-hash";
import {
  OptionSelection,
  isNumberOptionSelection,
  isListOptionSelection,
  CartItem,
  CartItemCollection,
  CartItemWithRevisions,
} from "../database/cart";
import {
  SiteSettings,
  defaultRoundingMode,
  paymentGateway,
} from "../database/siteSettings";
import {
  listOptionPriceMode,
  isNumberOption,
  isListOption,
} from "../database/option";
import Dinero from "dinero.js";
import { CatalogItem_WithDefaults } from "../database/catalogItem";
import {
  PriceInfoDinero,
  Order,
  PriceInfo,
  mergePriceInfo,
  Revision,
  mergePriceInfoDinero,
  priceInfoDineroToPriceInfo,
  revisionStatus,
  revisionIsComplete,
  revisionIsEmpty,
} from "../database/order";
import {
  Payment,
  paymentMethod,
  BancontactPayment,
  StripeCreditPayment,
  XenditCreditPayment,
  MarkedAsPaidPayment,
  StripeCheckoutPayment,
  PaypalPayment,
} from "../database/payment";
import { StoreConfiguration } from "../database/store";

/**
 * **********************************************
 * This file is used by both the client side code
 * and the serverside code.
 * **********************************************
 */

/**
 * Configures Dinero with the settings found in the store location, or
 * defaults if they are necessary.
 */
export const initDinero = (siteSettings: SiteSettings): void => {
  const { currency, precision, locale, roundingMode, format } =
    siteSettings.currency;

  Dinero.defaultCurrency = currency || "USD";
  Dinero.defaultPrecision = typeof precision === "number" ? precision : 2;
  Dinero.globalLocale = locale || "en-US";
  Dinero.globalRoundingMode = roundingMode || defaultRoundingMode;
  Dinero.globalFormat = format || "$0,0.00";
};

/**
 * Calculates the cost of the item taking into account optionSelections
 */
export const calculateCatalogItemPricePerUnit = (
  catalogItem: CatalogItem_WithDefaults,
  optionSelections?: OptionSelection[]
) => {
  let price = Dinero({ amount: catalogItem.price });

  if (optionSelections) {
    optionSelections.forEach((selection) => {
      const option = catalogItem.options.find(
        (option) => option.optionId === selection.optionId
      );
      if (isListOption(option) && isListOptionSelection(selection)) {
        option.items.forEach((optionItem) => {
          if (selection.selectedItems[optionItem.id]) {
            if (option.priceMode === listOptionPriceMode.ADD) {
              price = price.add(Dinero({ amount: optionItem.price }));
            }
          }
        });
      } else if (isNumberOption(option) && isNumberOptionSelection(selection)) {
        price = price.add(
          Dinero({
            amount: (option.price / option.priceDenominator) * selection.value,
          })
        );
      }
    });
  }

  let tax;
  if (catalogItem.priceIncludesTax) {
    tax = price
      .multiply(catalogItem.taxPercentage / 100)
      .divide(1 + catalogItem.taxPercentage / 100);
  } else {
    tax = price.multiply(catalogItem.taxPercentage / 100);
  }

  return {
    price,
    tax,
    taxPercentage: catalogItem.taxPercentage,
  };
};

export const calculateCartItemPrice = (cartItem: CartItem) => {
  const {
    price: pricePerUnit,
    tax: taxPerUnit,
    taxPercentage,
  } = calculateCatalogItemPricePerUnit(
    cartItem.catalogItem,
    cartItem.optionSelections
  );

  let denominator = cartItem.catalogItem.unit?.priceDenominator ?? 1;
  return {
    taxPercentage,
    perUnit: {
      price: pricePerUnit,
      tax: taxPerUnit,
    },
    total: {
      price: pricePerUnit.multiply(cartItem.count / denominator),
      tax: taxPerUnit.multiply(cartItem.count / denominator),
    },
  };
};

/**
 * Converts a cart item into a string. If the strings are identical, then the
 * two items are treated as treated as equivalent.
 */
export const getSignature = (item: CartItem): string => {
  return (
    item.catalogItem.itemId +
    item.instructions +
    item.belongsTo +
    Boolean(item.byAdmin) +
    // Old code just did a JSON.stringify, but unfortunately different
    //   orders of the properties make that not good enough
    hash(item.optionSelections)
  );
};

export const getTotalsForItems = (
  items: CartItemCollection
): PriceInfoDinero & { itemCount: number } => {
  let itemCount = 0;
  let beforeTax = Dinero({ amount: 0 });
  const taxTable: Record<string, Dinero.Dinero> = {};
  let total = Dinero({ amount: 0 });

  Object.values(items).forEach((item: CartItem) => {
    if (item.catalogItem.unit) {
      // For the purposes of counting how many items are in the cart, we treat
      // an order for, say, 500g as 1 item not 500 items. The 500 still contribute
      // to the cost.
      itemCount += 1;
    } else {
      itemCount += item.count;
    }

    const { priceIncludesTax, taxPercentage } = item.catalogItem;
    const { price: pricePerUnit } = calculateCatalogItemPricePerUnit(
      item.catalogItem,
      item.optionSelections
    );
    let denominator = item.catalogItem.unit?.priceDenominator ?? 1;
    const price = pricePerUnit.multiply(item.count / denominator);
    if (item.catalogItem.priceIncludesTax) {
      total = total.add(price);
    } else {
      beforeTax = beforeTax.add(price);
    }
    if (!taxTable[taxPercentage]) {
      taxTable[taxPercentage] = Dinero({ amount: 0 });
    }
    if (priceIncludesTax) {
      taxTable[taxPercentage] = taxTable[taxPercentage].add(
        price.multiply(taxPercentage / 100).divide(1 + taxPercentage / 100)
      );
    } else {
      taxTable[taxPercentage] = taxTable[taxPercentage].add(
        price.multiply(taxPercentage / 100)
      );
    }
  });
  let totalTax = Dinero({ amount: 0 });

  // Note: this code assumes that all items have the same value for priceIncludesTax.
  //   If that's not true, then the wrong values will be calculated.
  const priceIncludesTax =
    Object.values(items)[0]?.catalogItem.priceIncludesTax ?? false;
  if (!priceIncludesTax) {
    total = beforeTax;
  }
  Object.values(taxTable).forEach((tax) => {
    totalTax = totalTax.add(tax);
    if (priceIncludesTax) {
      beforeTax = total.subtract(tax);
    } else {
      total = total.add(tax);
    }
  });

  return {
    itemCount,
    beforeTax,
    taxTable,
    tax: totalTax,
    total,
  };
};

/**
 * Aggregates the price across revisions.
 */
export const getPriceWithRevisions = (order: Order): PriceInfo => {
  let priceWithRevisions: PriceInfo = {
    beforeTax: 0,
    tax: 0,
    total: 0,
    taxTable: {},
  };
  order.revisions.forEach((revision) => {
    if (revision.status !== revisionStatus.complete_declined) {
      priceWithRevisions = mergePriceInfo(
        priceWithRevisions,
        revision.priceAdjustment
      );
    }
  });
  return priceWithRevisions;
};

/**
 * Aggregates the items across revisions
 */
export const getCartItemsWithRevisions = (
  order: Order,
  // If true, then items in declined revisions will be included, marked as unable
  includeDeclined = false
) => {
  const result: {
    [cartItemId: string]: CartItemWithRevisions;
  } = {};
  order.revisions.forEach((revision) => {
    if (revision.status !== revisionStatus.complete_declined) {
      Object.values(revision.itemsAdded).forEach((item) => {
        result[item.cartItemId] = {
          ...item,
          unable: 0,
        };
      });
    } else if (includeDeclined) {
      Object.values(revision.itemsAdded).forEach((item) => {
        result[item.cartItemId] = {
          ...item,
          unable: item.count,
        };
      });
    }

    Object.values(revision.itemsUnable).forEach((item) => {
      if (!result[item.cartItemId]) {
        // Happens if a revision is marked complete_declined, thus removing its items
        return;
      }
      result[item.cartItemId].unable += item.count;
    });
  });

  return result;
};

/**
 * Calculates the price info for a revision
 */
export const getPriceAdjustment = (
  revision: Pick<
    Revision,
    "itemsAdded" | "itemsUnable" | "manualAdjustment" | "tip"
  >
): PriceInfo => {
  const additions = getTotalsForItems(revision.itemsAdded);
  const removals = getTotalsForItems(revision.itemsUnable);
  removals.beforeTax = removals.beforeTax.multiply(-1);
  removals.tax = removals.tax.multiply(-1);
  removals.total = removals.total.multiply(-1);
  removals.taxTable = R.mapObjIndexed(
    (tax) => tax.multiply(-1),
    removals.taxTable
  );

  const adjustment: PriceInfoDinero = mergePriceInfoDinero(additions, removals);
  if (revision.manualAdjustment !== 0) {
    const manualAdjustmentDinero = Dinero({
      amount: revision.manualAdjustment,
    });
    adjustment.beforeTax = adjustment.beforeTax.add(manualAdjustmentDinero);
    adjustment.total = adjustment.total.add(manualAdjustmentDinero);
  }
  if (revision.tip) {
    adjustment.tip = Dinero({ amount: revision.tip });
    adjustment.total = adjustment.total.add(adjustment.tip);
  }
  return priceInfoDineroToPriceInfo(adjustment);
};

export function createNewPayment(
  method: paymentMethod.bancontact
): BancontactPayment;
export function createNewPayment(
  method: paymentMethod.creditStripe
): StripeCreditPayment;
export function createNewPayment(
  method: paymentMethod.creditXendit
): XenditCreditPayment;
export function createNewPayment(
  method: paymentMethod.markedAsPaid
): MarkedAsPaidPayment;
export function createNewPayment(
  method: paymentMethod.checkoutStripe
): StripeCheckoutPayment;
export function createNewPayment(method: paymentMethod.paypal): PaypalPayment;
export function createNewPayment(method: paymentMethod): Payment;
export function createNewPayment(method: paymentMethod): Payment {
  switch (method) {
    case paymentMethod.bancontact: {
      const payment: BancontactPayment = {
        amount: 0,
        amountAwaitingApproval: 0,
        amountRefunded: 0,
        method,
        gateway: paymentGateway.stripe,
        sourceId: "",
        chargeId: "",
      };
      return payment;
    }

    case paymentMethod.creditStripe: {
      const payment: StripeCreditPayment = {
        amount: 0,
        amountAwaitingApproval: 0,
        amountRefunded: 0,
        method,
        gateway: paymentGateway.stripe,
        paymentIntentId: "",
        paymentMethodId: "",
        customerId: "",
      };
      return payment;
    }
    case paymentMethod.creditXendit: {
      const payment: XenditCreditPayment = {
        amount: 0,
        amountAwaitingApproval: 0,
        amountRefunded: 0,
        method,
        gateway: paymentGateway.xendit,
        tokenId: "",
        authenticationId: "",
        externalId: "",
      };
      return payment;
    }
    case paymentMethod.markedAsPaid: {
      const payment: MarkedAsPaidPayment = {
        amount: 0,
        amountAwaitingApproval: 0,
        amountRefunded: 0,
        method,
        gateway: null,
      };
      return payment;
    }
    case paymentMethod.checkoutStripe: {
      const payment: StripeCheckoutPayment = {
        amount: 0,
        amountAwaitingApproval: 0,
        amountRefunded: 0,
        method,
        gateway: paymentGateway.stripe,
        sessionId: "",
      };
      return payment;
    }
    case paymentMethod.paypal: {
      const payment: PaypalPayment = {
        amount: 0,
        amountAwaitingApproval: 0,
        amountRefunded: 0,
        method,
        gateway: paymentGateway.paypal,
        paypalOrderId: "",
        captureId: "",
      };
      return payment;
    }
  }
}

export const createNewRevision = (): Revision => {
  const revision: Revision = {
    itemsAdded: {},
    itemsUnable: {},
    manualAdjustment: 0,
    priceAdjustment: {
      beforeTax: 0,
      tax: 0,
      taxTable: {},
      total: 0,
    },
    reason: "",
    status: revisionStatus.incomplete,
    payment: null,
  };
  return revision;
};

/**
 * Updates the checked count of items in an order.
 */
export const setChecked = (
  order: Order,
  cartItem: CartItem,
  checked: number
): Order => {
  const updatedOrder = R.clone(order);
  // Update the checked property in the revision that has this item
  for (const revision of updatedOrder.revisions) {
    if (revision.itemsAdded[cartItem.cartItemId]) {
      revision.itemsAdded[cartItem.cartItemId].checked = checked;
    }
  }

  updatedOrder.cumulativeCartItems = getCartItemsWithRevisions(updatedOrder);
  return updatedOrder;
};

/**
 * Updates the unabled count of items in an order.
 */
export const setUnable = (
  order: Order,
  cartItem: CartItem,
  unable: number
): Order => {
  const unableChange =
    unable - order.cumulativeCartItems[cartItem.cartItemId].unable;
  if (unableChange === 0) {
    return order;
  }

  const updatedOrder = R.clone(order);

  // Find or create the revision which is being edited
  let revision = updatedOrder.revisions[updatedOrder.revisions.length - 1];
  if (revisionIsComplete(revision)) {
    revision = createNewRevision();
    updatedOrder.revisions.push(revision);
  }

  // Find or create the item in the revision, and update it
  const existingItem = revision.itemsUnable[cartItem.cartItemId];
  if (existingItem) {
    existingItem.count += unableChange;
  } else {
    const newItem = {
      ...cartItem,
      count: unableChange,
      checked: 0, // This value is irrelevant in the itemsUnable collection
    };
    revision.itemsUnable[cartItem.cartItemId] = newItem;
  }

  // If this change makes the revision irrelevant, remove it
  if (order.revisions.length > 1 && revisionIsEmpty(revision)) {
    const index = order.revisions.indexOf(revision);
    order.revisions.splice(index, 1);
  }

  // Fill in computed values
  revision.priceAdjustment = getPriceAdjustment(revision);
  revision.status = revisionStatus.incomplete;
  revision.payment = null;
  updatedOrder.cumulativeCartItems = getCartItemsWithRevisions(updatedOrder);
  updatedOrder.cumulativePrice = getPriceWithRevisions(updatedOrder);
  return updatedOrder;
};

/**
 * Updates the manual price adjustment or an order.
 */
export const setManualAdjustment = (
  order: Order,
  manualAdjustment: number,
  store: StoreConfiguration
): Order => {
  const updatedOrder = R.clone(order);

  // Find or create the revision which is being edited
  let revision = updatedOrder.revisions[updatedOrder.revisions.length - 1];
  if (revisionIsComplete(revision)) {
    revision = createNewRevision();
    updatedOrder.revisions.push(revision);
  }

  // Update and add computed values
  revision.manualAdjustment = manualAdjustment;
  revision.status = revisionStatus.incomplete;
  revision.payment = null;
  revision.priceAdjustment = getPriceAdjustment(revision);

  // If this change makes the revision irrelevant, remove it
  if (order.revisions.length > 1 && revisionIsEmpty(revision)) {
    const index = order.revisions.indexOf(revision);
    order.revisions.splice(index, 1);
  }

  updatedOrder.cumulativePrice = getPriceWithRevisions(updatedOrder);
  return updatedOrder;
};
