import * as R from "ramda";
import {
  CatalogItem_Database,
  CatalogItem_WithDefaults,
  CatalogItem_Database_v1,
  PredefinedUnit,
  Unit,
  Label,
} from "../database/catalogItem";
import { Option, optionType, UnitOption_deprecated } from "../database/option";
import { notUndefined } from "./notNull";
import { CurrencyOptions, PhoneNumber } from "../database/siteSettings";
import { Order_v1, Order, Revision, revisionStatus } from "../database/order";
import {
  DatabaseSuborder,
  DatabaseCartItem_v1,
  CartItem,
  OptionSelection,
} from "../database/cart";
import { StoreConfiguration } from "../database/store";
import {
  getCartItemsWithRevisions,
  getSignature,
  getPriceWithRevisions,
  getPriceAdjustment,
  createNewPayment,
} from "./orderProcessing";
import {
  createPhoneSearchStrings,
  createSearchStrings,
} from "./createSearchStrings";
import { formatNumber } from "../components/Store/Components/phoneInput/formatNumber";
import { countries } from "../components/Store/Components/phoneInput/countries";

export function convertItemAndAddDefaults(
  item: CatalogItem_Database,
  labels: { [labelId: string]: Label },
  taxPercentage: number,
  priceIncludesTax: boolean,
  currency: CurrencyOptions
): CatalogItem_WithDefaults {
  const newItem = {
    ...item,
    labels: item.labelIds
      ? item.labelIds.map((labelId) => labels[labelId]).filter(notUndefined)
      : [],
    labelCombinations: item.labelCombinations ?? [],
    textLabels: item.textLabels ?? [],
    imageUrls: item.imageUrls ?? {},
    imageSize: item.imageSize ?? "cover",
    useZoom: item.useZoom ?? false,
    useContain: item.useContain ?? false,
    imageMetadata: item.imageMetadata ?? {
      width: 0,
      height: 0,
    },
    widthWeight: 1,
    leftWeight: 0.5,
    topWeight: 0.5,
    aspectRatio: 1,
    taxPercentage: item.taxPercentage ?? taxPercentage,
    unavailable: item.unavailable ?? false,
    priceIncludesTax: priceIncludesTax ?? false,
    currency: currency,
    inActiveCatalog: item.inActiveCatalog ?? false,
  };
  delete newItem.labelIds;
  return newItem;
}

export const convertCartItemV1ToV2 = (
  cartItem: DatabaseCartItem_v1,
  itemLookup: Record<string, CatalogItem_WithDefaults>,
  storeConfig: Pick<
    StoreConfiguration,
    "taxPercentage" | "priceIncludesTax" | "currency"
  >
): CartItem => {
  let catalogItem = itemLookup[cartItem.itemId];
  if (!catalogItem) {
    const name = "Unknown item";
    catalogItem = {
      itemId: cartItem.itemId,
      catalogIds: [],
      name,
      nameSearch: createSearchStrings(name),
      description: "",
      options: [],
      labels: [],
      labelCombinations: [],
      textLabels: [],
      imageUrl: "",
      imageSize: "cover",
      imageUrls: {},
      imageZoom: { x: 0, y: 0 },
      useZoom: false,
      useContain: false,
      imageMetadata: {
        width: 0,
        height: 0,
      },
      widthWeight: 1,
      leftWeight: 0,
      topWeight: 0,
      aspectRatio: 1,
      price: 0,
      taxPercentage: storeConfig.taxPercentage,
      unavailable: false,
      priceIncludesTax: storeConfig.priceIncludesTax ?? false,
      currency: storeConfig.currency,
      hidden: false,
      inActiveCatalog: false,
    };
  }

  const newCartItem: CartItem = {
    ...R.pick(["cartItemId", "instructions"], cartItem),
    _version: 2,
    catalogItem,
    count: 1,
    checked: cartItem.checked ? 1 : 0,
  };

  if (cartItem.optionSelections) {
    const newSelections: OptionSelection[] = [];
    cartItem.optionSelections.forEach((selection) => {
      if (selection.type === "unit") {
        // Units used to be a custom option, but are now part of the main object
        newCartItem.count = selection.value;
        newCartItem.checked = cartItem.checked ? selection.value : 0;
      } else if (selection.type === optionType.LIST) {
        newSelections.push(R.omit(["price", "itemPrices"], selection));
      } else if (selection.type === optionType.NUMBER) {
        newSelections.push(R.omit(["price"], selection));
      }
    });
    newCartItem.optionSelections = newSelections;
  }

  return newCartItem;
};
/**
 * Takes the old order.suborders property, flattens it into a single collection,
 * converts them to the new CartItem format, and inserts them into a revision
 */
const createRevisionsFromSuborders = (
  order: Order_v1,
  itemLookup: Record<string, CatalogItem_WithDefaults>,
  storeConfig: Pick<
    StoreConfiguration,
    "taxPercentage" | "priceIncludesTax" | "currency" | "paymentGateway"
  >
): Revision[] => {
  // Cart ids were previously only unique within a suborder, not across
  //   the entire cart. So we need to assign new ids to ensure they
  //   are unique for the cart.
  let id = 1;
  const items: Record<string, CartItem> = {};
  const unable: Record<string, boolean> = {};
  Object.values(order.suborders).forEach((suborder: DatabaseSuborder) => {
    Object.values(suborder.items).forEach((item: DatabaseCartItem_v1) => {
      unable[id] = Boolean(item.unable);
      items[id] = {
        ...convertCartItemV1ToV2(item, itemLookup, storeConfig),
        cartItemId: "" + id,
      };
      id++;
    });
  });

  // Merge items together (old orders represented multiple items by having duplicates)
  const customerBySignature: Record<string, CartItem> = {};
  const adminBySignature: Record<string, CartItem> = {};
  let unableBySignature: Record<string, CartItem> = {};
  for (const itemId in items) {
    const signature = getSignature(items[itemId]);
    console.log("** ", signature);
    if (items[itemId].byAdmin) {
      if (!adminBySignature[signature]) {
        adminBySignature[signature] = R.clone(items[itemId]);
      } else {
        adminBySignature[signature].count += items[itemId].count;
        adminBySignature[signature].checked += items[itemId].checked;
      }
    } else {
      if (!customerBySignature[signature]) {
        customerBySignature[signature] = R.clone(items[itemId]);
      } else {
        customerBySignature[signature].count += items[itemId].count;
        customerBySignature[signature].checked += items[itemId].checked;
      }
    }

    if (!unableBySignature[signature]) {
      unableBySignature[signature] = R.clone(items[itemId]);
      unableBySignature[signature].count = unable[itemId]
        ? items[itemId].count
        : 0;
    } else {
      unableBySignature[signature].count += unable[itemId]
        ? items[itemId].count
        : 0;
    }
  }

  // Now that the signatures have done their job of keeping things unique, rekey the objects
  const itemsAddedByCustomer: Record<string, CartItem> = {};
  const itemsAddedByAdmin: Record<string, CartItem> = {};
  const itemsUnable: Record<string, CartItem> = {};

  Object.values(customerBySignature).forEach((cartItem) => {
    itemsAddedByCustomer[cartItem.cartItemId] = cartItem;
  });
  Object.values(adminBySignature).forEach((cartItem) => {
    itemsAddedByAdmin[cartItem.cartItemId] = cartItem;
  });
  Object.values(unableBySignature).forEach((cartItem) => {
    if (cartItem.count > 0) {
      itemsUnable[cartItem.cartItemId] = cartItem;
    }
  });

  // Create the initial order
  const initialOrder: Revision = {
    itemsAdded: itemsAddedByCustomer,
    itemsUnable: {},
    manualAdjustment: 0,
    priceAdjustment: {
      beforeTax: 0,
      tax: 0,
      taxTable: {},
      total: 0,
    },
    reason: "",
    status: revisionStatus.complete,
    payment: createNewPayment(order.paymentMethod),
  };
  initialOrder.priceAdjustment = getPriceAdjustment(initialOrder);

  const revisions = [initialOrder];

  // If necessary, create a revision with modifications
  if (
    Object.keys(itemsAddedByAdmin).length > 0 ||
    Object.keys(itemsUnable).length > 0
  ) {
    const unableRevision: Revision = {
      itemsAdded: itemsAddedByAdmin,
      itemsUnable: itemsUnable,
      manualAdjustment: 0,
      priceAdjustment: {
        beforeTax: 0,
        tax: 0,
        taxTable: {},
        total: 0,
      },
      reason: order.totalAdjustmentReason || "",
      status: revisionStatus.complete,
      payment: createNewPayment(order.paymentMethod),
    };
    unableRevision.priceAdjustment = getPriceAdjustment(unableRevision);
    revisions.push(unableRevision);
  }

  return revisions;
};

/**
 * Converts an order from V1 to v2. Main thing that changed was there is now an
 * array of "revisions" tracking the evolution of the order. Also grouped some
 * properties into a "recipient" object and added orderIdSearch for searching.
 */
export const convertOrderV1ToV2 = (
  order: Order_v1,
  itemLookup: Record<string, CatalogItem_WithDefaults>,
  storeConfig: Pick<
    StoreConfiguration,
    "taxPercentage" | "priceIncludesTax" | "currency" | "paymentGateway"
  >
): Order => {
  // Add any new properties
  const phone = convertPhone(order.customerPhone);
  const temp: Order = {
    ...order,
    _version: 2,
    migratedFromV1: true,
    orderIdSearch: createSearchStrings(order.orderId),
    revisions: createRevisionsFromSuborders(order, itemLookup, storeConfig),
    recipient: {
      userId: order.userId === "guest" ? null : order.userId,
      /**
       * TODO: it's not possible to construct an accurate
       * address object from just the string that's in the v1
       * objects. Is that ok? Maybe since we just have the one
       * business we could manually update their orders
       */
      address: {
        city: "",
        countryCode: "",
        streetLine1: "",
        zip: "",
      },
      email: order.customerEmail,
      emailSearch: createSearchStrings(order.customerEmail),
      phone,
      phoneSearch: createPhoneSearchStrings(phone),
    },
    cumulativePrice: {
      beforeTax: order.total - order.tax,
      tax: order.tax,
      taxTable: order.taxTable,
      total: order.total,
    },
    cumulativeCartItems: {},
  };

  // remove any obsolete properties
  const newOrder: Order = R.omit(
    [
      "customerAddress",
      "customerEmail",
      "customerPhone",
      "suborders",
      "paymentChargeToken",
      "paymentGateway",
      "paymentToken",
      "paymentMethod",
      "paymentCustomer",
      "paymentRechargeStatus",
      "paymentAuthSent",
      "tax",
      "taxTable",
      "total",
      "userId",
      "totalAdjustmentReason",
      "totalAdjustmentAmount",
      "totalAdjustmentLast",
    ],
    temp
  );

  // Add calculated properties:
  newOrder.cumulativeCartItems = getCartItemsWithRevisions(newOrder);
  newOrder.cumulativePrice = getPriceWithRevisions(newOrder);

  return newOrder;
};

export const convertPhone = (phone: string): PhoneNumber | null => {
  if (!phone) {
    return null;
  }
  /**
   * WARNING:
   * This block of code was written to specifically work with the data that happens to be in the
   * project6 and lpacorp databases. It will get things wrong if used for general migrations.
   **/
  const raw = phone.startsWith("+")
    ? phone.substr(1, phone.length - 1)
    : phone.startsWith("00")
    ? phone.substr(2, phone.length - 2)
    : phone;
  const countryCode =
    phone.startsWith("+32") || phone.startsWith("0032") ? "32" : "1";
  const mainNumber = raw.slice(countryCode.length);
  const matchingCountries = countries.filter(
    (country) => country.dialCode === countryCode
  );
  matchingCountries.sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
  const country = matchingCountries[0] ?? null;
  const formatted = `+${countryCode} ` + formatNumber(mainNumber, country);
  return {
    raw,
    countryCode,
    formatted,
  };
};

export const convertCatalogItemV1ToV2 = (
  item: CatalogItem_Database_v1,
  optionCollection: Record<string, Option | UnitOption_deprecated>,
  catalogIds: string[]
): CatalogItem_Database => {
  let unit: Unit | undefined;
  let itemOptions: Option[] = [];
  if (item.options) {
    // Options are no longer external documents, they are now part of the item.
    // Additionally, the unit option is in its own property, not part of the array.
    for (const optionRef of item.options) {
      const option = optionCollection[optionRef.optionId];
      if (option) {
        if (option.type === "unit") {
          // The following code for predefined units will not work for all. I just hand
          //   crafted it to work with project6 and lpa. Custom unit fallback works for all
          let knownUnit: PredefinedUnit["key"] | undefined;
          if (option.name === "grams") {
            knownUnit = "gram";
          } else if (option.name === "oz" || option.name === "ounces") {
            knownUnit = "ounce";
          } else if (option.name === "ml") {
            knownUnit = "milliliter";
          }

          if (knownUnit) {
            unit = {
              isCustomUnit: false,
              key: knownUnit,
              priceDenominator: option.priceDenominator,
              defaultValue: option.defaultValue,
              minValue: option.minValue,
              maxValue: option.maxValue,
              increment: option.increment,
            };
          } else {
            unit = {
              isCustomUnit: true,
              name: option.name,
              namePlural: option.name,
              priceDenominator: option.priceDenominator,
              defaultValue: option.defaultValue,
              minValue: option.minValue,
              maxValue: option.maxValue,
              increment: option.increment,
            };
          }
        } else {
          itemOptions.push(option);
        }
      }
    }
  }

  const newItem: CatalogItem_Database = {
    // Quantity field was removed
    ...R.omit(["quantity"], item),
    catalogIds,
    nameSearch: createSearchStrings(item.name),
    options: itemOptions,
    hidden: false,
  };
  if (unit) {
    newItem.unit = unit;
  }

  return newItem;
};
