import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Application } from "../../../../database/signup";
import { INPUT_MAX_EMAIL } from "../../../../utilities/constants/appConstants";
import { history } from "../../../App/history";

interface Group1Props {
  application: Application;
  setApplication: Dispatch<SetStateAction<Application>>;
}

const Group1: FC<Group1Props> = ({ application, setApplication }) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLDivElement | null>(null);

  const { question1, feedback1 } = application;

  useEffect(() => {
    const current = inputRef.current;
    if (current && question1 === "disagree") {
      const id = setTimeout(() => {
        current.scrollIntoView();
      }, 100);
      return () => clearTimeout(id);
    }
  }, [question1]);

  const nextButton = useRef<HTMLButtonElement | null>(null);

  return (
    <React.Fragment>
      <DialogContent>
        <Typography align="center" color="textPrimary" variant="h5">
          {t("gridbash.home.mainQuestion")}
        </Typography>
        <div style={{ marginTop: "1em" }} />
        <Typography align="left" color="primary">
          {t("gridbash.home.group1_st1")}
        </Typography>
        <div style={{ marginTop: "1em" }} />
        <FormControl fullWidth>
          <RadioGroup
            value={question1}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value as "agree" | "disagree";
              setApplication((prev) => ({
                ...prev,
                question1: value,
              }));
            }}
          >
            <FormControlLabel
              value="agree"
              control={<Radio />}
              label="I agree"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <FormControlLabel
              value="disagree"
              control={<Radio />}
              label="I disagree"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </RadioGroup>
          <div
            style={{
              height: question1 === "disagree" ? "10em" : "0em",
              overflow: "hidden",
              transition: "all 0.3s ease",
            }}
          >
            <div style={{ marginTop: "1em" }} />
            <Typography align="left" variant="body2">
              {t("gridbash.home.weWantToLearn")}
            </Typography>

            <TextField
              ref={inputRef}
              fullWidth
              variant="filled"
              label="Enter feedback"
              multiline
              rows={4}
              value={feedback1}
              onChange={(e) => {
                const value = e.target.value.substring(0, INPUT_MAX_EMAIL);
                setApplication((prev) => ({
                  ...prev,
                  feedback1: value,
                }));
              }}
              onBlur={(e) => {
                const value = e.target.value
                  .trim()
                  .substring(0, INPUT_MAX_EMAIL);
                setApplication((prev) => ({
                  ...prev,
                  feedback1: value,
                }));
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  const value = e.target.value
                    .trim()
                    .substring(0, INPUT_MAX_EMAIL);
                  setApplication((prev) => ({
                    ...prev,
                    feedback1: value,
                  }));
                  nextButton.current?.click();
                }
              }}
            />
          </div>
        </FormControl>
      </DialogContent>

      <DialogActions style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <div style={{ flexGrow: 1 }} />
        <Typography variant="body2" color="textSecondary">
          1&nbsp;/&nbsp;3
        </Typography>

        <Button
          ref={nextButton}
          fullWidth={false}
          disabled={!question1}
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            history.push("/am-i-in/2");
          }}
        >
          {t("gridbash.next")}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default Group1;
