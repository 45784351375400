import { Icon, ListItemIcon, MenuItem } from "@material-ui/core";
import { memo, Fragment, FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { history } from "../../../../App/history";
import MUIPopover from "../../../../Dashboard/Components/mui-popover";
import { useMeasure } from "../../../../../utilities/useMeasure";
import { useLocation } from "react-router-dom";
import { useSiteSettings } from "../../../../../customization/siteSettingsContext";
import {
  NavLinksWidgetEditorOverrides,
  NavLinksWidgetProps,
} from "./navLinksWidget";
import FancyTextComponent from "../../../../Dashboard/Builder/FancyText/fancyTextComponent";
import ROUTES from "../../../../../utilities/constants/routes";

const NavLinksMenu: FC<NavLinksWidgetProps & NavLinksWidgetEditorOverrides> =
  memo(
    ({
      onTabChanged,
      fancyText = { text: "" },
      elements = "textOnly",
      overflowStyle = "parent",
    }) => {
      const { pages } = useSiteSettings();
      const location = useLocation<{ navLinksMore?: boolean } | undefined>();

      const [measurement, setMeasurement] = useState({
        measuring: true,
        overflowCutoff: Number.MAX_SAFE_INTEGER,
      });
      const showMenu = measurement.overflowCutoff < pages.length;

      const moreButton = useRef<HTMLDivElement | null>(null);
      const offset = moreButton.current ? moreButton.current.clientWidth : 0;

      const [menuDiv, bounds] = useMeasure();
      const menuItemRefs = useRef<(HTMLSpanElement | null)[]>([]);

      useEffect(() => {
        // Contents or width changed. Need to recompute which elements fit
        setMeasurement((prev) => {
          if (!prev.measuring) {
            return {
              ...prev,
              measuring: true,
            };
          } else {
            return prev;
          }
        });
      }, [bounds.width, pages, fancyText, elements]);

      useLayoutEffect(() => {
        if (bounds.width === 0) {
          // not ready yet
          return;
        }

        if (measurement.measuring) {
          const firstElement = menuItemRefs.current[0];
          const freeSpace = firstElement?.offsetLeft ?? 0;
          const moreButtonWidth = moreButton.current?.offsetWidth ?? 0;
          let newCutoff: number;
          if (firstElement && freeSpace >= -moreButtonWidth) {
            // They all fit
            newCutoff = Number.MAX_SAFE_INTEGER;
          } else {
            // Can't fit them all, we'll need the "more" button.
            newCutoff = 0;
            if (fancyText.fullWidth) {
              // All items will be the same size. Find out how many we can fit at that size.
              let widestElement = moreButtonWidth;
              for (let i = 0; i < pages.length; i++) {
                const element = menuItemRefs.current[i];
                if (!element) {
                  continue;
                }
                widestElement = Math.max(widestElement, element.offsetWidth);
                const numElements = i + 2;
                if (widestElement * numElements >= bounds.width) {
                  // Can't fit more than this
                  newCutoff = i;
                  break;
                }
              }
            } else {
              let sum = moreButtonWidth;
              for (let i = 0; i < pages.length; i++) {
                const element = menuItemRefs.current[i];
                sum += element?.offsetWidth ?? 0;
                if (sum > bounds.width) {
                  newCutoff = i;
                  break;
                }
              }
            }
          }

          setMeasurement({
            measuring: false,
            overflowCutoff: newCutoff < 0 ? Number.MAX_SAFE_INTEGER : newCutoff,
          });
        }
      }, [bounds.width, fancyText.fullWidth, measurement, offset, pages]);

      const [anchor, setAnchor] = useState<HTMLSpanElement | null>(null);

      const paddingHoriz =
        fancyText.boxShadowX && Math.abs(fancyText.boxShadowX) > 0
          ? `${
              Math.abs(fancyText.boxShadowX ?? 0) +
              Math.abs(fancyText.boxShadowSpread ?? 0) +
              Math.abs(fancyText.boxShadowBlur ?? 0)
            }px`
          : `${
              (fancyText.boxShadowSpread ?? 0) + (fancyText.boxShadowBlur ?? 0)
            }px`;

      return (
        <Fragment>
          <div
            ref={menuDiv}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: "100%",
              position: "relative",
            }}
          >
            <div
              id="navLinksMenu-inview"
              style={{
                maxWidth: "100%",
                width: "100%",
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                justifyContent: "flex-end",
                overflow: "hidden",
                paddingLeft: paddingHoriz,
                paddingRight: paddingHoriz,
                paddingTop:
                  fancyText.boxShadowY && fancyText.boxShadowY < 0
                    ? `${Math.abs(
                        (fancyText.boxShadowY ?? 0) -
                          (fancyText.boxShadowSpread ?? 0) -
                          (fancyText.boxShadowBlur ?? 0)
                      )}px`
                    : `${
                        (fancyText.boxShadowSpread ?? 0) +
                        (fancyText.boxShadowBlur ?? 0)
                      }px`,
                paddingBottom:
                  fancyText.boxShadowY && fancyText.boxShadowY >= 0
                    ? `${
                        (fancyText.boxShadowY ?? 0) +
                        (fancyText.boxShadowSpread ?? 0) +
                        (fancyText.boxShadowBlur ?? 0)
                      }px`
                    : `${
                        (fancyText.boxShadowSpread ?? 0) +
                        (fancyText.boxShadowBlur ?? 0)
                      }px`,
              }}
            >
              {pages.map(
                (page, index) =>
                  (measurement.measuring ||
                    index < measurement.overflowCutoff) && (
                    <FancyTextComponent
                      ref={(element) => {
                        menuItemRefs.current[index] = element;
                      }}
                      key={page.pageId}
                      value={
                        elements === "iconOnly"
                          ? {
                              ...fancyText,
                              text: "",
                              icon: page.icon,
                              marginRight:
                                index === pages.length - 1
                                  ? 0
                                  : fancyText.marginRight,
                            }
                          : elements === "textOnly"
                          ? {
                              ...fancyText,
                              text: page.name,
                              icon: undefined,
                              marginRight:
                                index === pages.length - 1
                                  ? 0
                                  : fancyText.marginRight,
                            }
                          : {
                              ...fancyText,
                              text: page.name,
                              icon: page.icon,
                              iconPosition: fancyText.iconPosition ?? "left",
                              marginRight:
                                index === pages.length - 1
                                  ? 0
                                  : fancyText.marginRight,
                            }
                      }
                      button={true}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={(e) => {
                        if (onTabChanged) {
                          onTabChanged(page.pageId);
                        } else {
                          e.stopPropagation();
                          history.push(`${ROUTES.rootPath}${page.path}`);
                        }
                      }}
                    />
                  )
              )}
              {(measurement.measuring || showMenu) && (
                <Fragment>
                  <FancyTextComponent
                    ref={moreButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (location.state?.navLinksMore !== true) {
                        history.push(location.pathname, {
                          ...location.state,
                          navLinksMore: true,
                        });
                      }
                      setAnchor(e.currentTarget);
                    }}
                    value={
                      elements === "iconOnly"
                        ? {
                            ...fancyText,
                            text: "",
                            icon: "arrow_drop_down",
                            marginRight: 0,
                          }
                        : elements === "textOnly"
                        ? {
                            ...fancyText,
                            text: "More",
                            icon: undefined,
                            marginRight: 0,
                          }
                        : {
                            ...fancyText,
                            text: "More",
                            icon: "arrow_drop_down",
                            iconPosition: fancyText.iconPosition ?? "left",
                            marginRight: 0,
                          }
                    }
                    style={{
                      whiteSpace: "nowrap",
                      marginRight: 0,
                    }}
                    button={true}
                  />
                  {anchor && (
                    <MUIPopover
                      route={""}
                      stateMatch={(state) => state.navLinksMore === true}
                      anchorEl={anchor}
                      PaperProps={{
                        style: {
                          backgroundColor:
                            fancyText.backgroundColor &&
                            fancyText.backgroundColor !== "transparent"
                              ? overflowStyle === "parent"
                                ? "transparent"
                                : undefined
                              : undefined,
                          boxShadow:
                            fancyText.backgroundColor &&
                            fancyText.backgroundColor !== "transparent"
                              ? overflowStyle === "parent"
                                ? "none"
                                : undefined
                              : undefined,
                        },
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {pages.map((page, index) => {
                          if (index < measurement.overflowCutoff) {
                            return null;
                          }
                          return overflowStyle === "parent" ? (
                            <MenuItem
                              key={page.pageId}
                              onClick={(e) => {
                                if (onTabChanged) {
                                  onTabChanged(page.pageId);
                                } else {
                                  e.stopPropagation();
                                  history.push(
                                    `${ROUTES.rootPath}${page.path}`
                                  );
                                }
                              }}
                            >
                              <FancyTextComponent
                                value={
                                  elements === "iconOnly"
                                    ? {
                                        ...fancyText,
                                        text: "",
                                        icon: page.icon,
                                      }
                                    : elements === "textOnly"
                                    ? {
                                        ...fancyText,
                                        text: page.name,
                                        icon: undefined,
                                      }
                                    : {
                                        ...fancyText,
                                        text: page.name,
                                        icon: page.icon,
                                        iconPosition:
                                          fancyText.iconPosition ?? "left",
                                      }
                                }
                                style={{
                                  width: "100%",
                                  textAlign: fancyText.align,
                                  whiteSpace: "nowrap",
                                }}
                              />
                            </MenuItem>
                          ) : (
                            <MenuItem
                              key={page.pageId}
                              onClick={(e) => {
                                if (onTabChanged) {
                                  onTabChanged(page.pageId);
                                } else {
                                  e.stopPropagation();
                                  history.push(
                                    `${ROUTES.rootPath}${page.path}`
                                  );
                                }
                              }}
                              className={`gb-font-${fancyText.fontSize}`}
                            >
                              {elements === "iconOnly" ? (
                                <ListItemIcon>
                                  <Icon>{page.icon}</Icon>
                                </ListItemIcon>
                              ) : elements === "textOnly" ? (
                                page.name
                              ) : fancyText.iconPosition === "left" ? (
                                <Fragment>
                                  <ListItemIcon>
                                    <Icon>{page.icon}</Icon>
                                  </ListItemIcon>
                                  {page.name}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {page.name}
                                  <ListItemIcon>
                                    <Icon>{page.icon}</Icon>
                                  </ListItemIcon>
                                </Fragment>
                              )}
                            </MenuItem>
                          );
                        })}
                      </div>
                    </MUIPopover>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      );
    }
  );

export default NavLinksMenu;
