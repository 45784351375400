import { useState, FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useSiteUser from "../../UserProvider/useSiteUser";
import { history } from "../../App/history";
import { useSiteFirebase } from "../../../Firebase/context";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText, TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { AccountInfo } from "../../Main/useMain";
import { storeKey } from "../../../Firebase/siteFirebase";
import useToast from "../../Main/useToast";

interface AccountNameProps {
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
  initialName: string;
  initPath: string;
}

const AccountName: FC<AccountNameProps> = React.memo(
  ({ accountInfo, setOptimisticUpdate, initialName, initPath }) => {
    const { user } = useSiteUser();
    const { t } = useTranslation();
    const firebase = useSiteFirebase();
    const [newName, setNewName] = useState(initialName);

    const toast = useToast();

    const submit = async () => {
      if (!user || newName.trim() === initialName) {
        history.goBack();
        return;
      }

      try {
        setOptimisticUpdate({
          ...accountInfo,
          contactInfo: {
            ...accountInfo.contactInfo,
            name: newName.trim(),
          },
        });

        history.goBack();

        await firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("users")
          .doc(user.uid)
          .update({ "contactInfo.name": newName.trim() });
      } catch (error) {
        console.log("sTaccName.js", error);
        toast({
          dialog: true,
          color: "error",
          message: t("dashboard.account.errorChangingSetting"),
        });
      }
    };

    return (
      <React.Fragment>
        <DialogContent className="smartWidthMd">
          <FormControl fullWidth>
            <TextField
              fullWidth
              autoComplete="new-password"
              type="text"
              label={t("store.account.enterName")}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onBlur={(e) => {
                setNewName(e.target.value.trim());
              }}
            />
            <FormHelperText>{t("store.account.accountName")}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.goBack()}>{t("store.cancel")}</Button>
          <Button onClick={submit}>{t("store.submit")}</Button>
        </DialogActions>
      </React.Fragment>
    );
  }
);

export default AccountName;
