import * as React from "react";
import { useTranslation } from "react-i18next";
import { CatalogItem_WithDefaults } from "../../../../database/catalogItem";
import {
  OrderCell,
  orderCellIs,
  FancyTextWidgetProps,
  ImageWidgetProps,
  RichTextWidgetProps,
  OrderWidgetType,
} from "../../../../database/orderPage";
import { createCartItem } from "../../../CartManager/cartApiProvider";
import FancyTextComponent from "../../../Dashboard/Builder/FancyText/fancyTextComponent";
import getItemPriceLabels from "../getItemPriceLabels";
import ImageWidget from "./ImageWidget";
import IconFavoriteWidget from "./IconFavoriteWidget";
import IconCartWidget from "./IconCartWidget";
import { useThemeColor } from "../../../widgets/useThemeColor";
import GridWidget from "./gridWidget";
import FancyTextWidget from "./fancyTextWidget";
import { TFunction } from "i18next";
import RichTextWidget from "./richTextWidget";

export interface OrderCellRendererProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  cell: OrderCell;
  // onChange: (newValue: OrderCell<OrderWidgetType.logo>) => void;
  catalogItem: CatalogItem_WithDefaults;
  initPath: string;
  onImageReady: (result: "loaded" | "error" | "noUrl") => void;
  sizesString: string | undefined;
  toolbarVisible?: boolean;
}

/**
 * Extracts data from catalog items to be passed in as props to widgets
 */
const dataAdapters = {
  itemImage: (
    catalogItem: CatalogItem_WithDefaults
  ): Partial<ImageWidgetProps> => ({
    imageUrl: catalogItem.imageUrl,
    imageUrls: catalogItem.imageUrls,
    imageZoom: catalogItem.imageZoom,
    alt: catalogItem.name,
  }),
  itemName: (
    catalogItem: CatalogItem_WithDefaults
  ): Partial<FancyTextWidgetProps> => ({
    text: catalogItem.name,
  }),
  itemPrice: (
    catalogItem: CatalogItem_WithDefaults,
    t: TFunction
  ): Partial<FancyTextWidgetProps> => {
    const { priceLabel, priceEachLabel } = getItemPriceLabels({
      t,
      cartItem: createCartItem({
        catalogItem,
        cartItemId: "fake",
        count: 1,
        optionSelections: [],
      }),
    });
    return {
      text: priceEachLabel || priceLabel || "",
    };
  },
  itemDescription: (
    catalogItem: CatalogItem_WithDefaults
  ): Partial<RichTextWidgetProps> => ({
    richText: Array.isArray(catalogItem.description)
      ? catalogItem.description[0] ?? ""
      : catalogItem.description,
  }),
};

/**
 * TODO: this component needs refactoring. It was designed when the order page was its only
 * use, but now it's trying to do a mix of dashboard-side editing and client-side rendering,
 * which results in a mashup of unrelated props.
 */
const OrderCellRenderer = React.forwardRef<
  HTMLDivElement,
  OrderCellRendererProps
>(
  (
    {
      cell,
      catalogItem,
      initPath,
      onImageReady,
      sizesString,
      toolbarVisible = false,
      children,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const themeColor = useThemeColor();

    let element;
    if (orderCellIs(OrderWidgetType.image, cell)) {
      const extraProps =
        cell.dataSource && dataAdapters[cell.dataSource](catalogItem);
      element = (
        <ImageWidget
          initPath={initPath}
          onImageReady={onImageReady}
          sizesString={sizesString}
          {...cell.props}
          {...extraProps}
        />
      );
    } else if (orderCellIs(OrderWidgetType.fancyText, cell)) {
      const extraProps =
        cell.dataSource && dataAdapters[cell.dataSource](catalogItem, t);
      element = <FancyTextWidget {...cell.props} {...extraProps} />;
    } else if (orderCellIs(OrderWidgetType.richText, cell)) {
      const extraProps =
        cell.dataSource && dataAdapters[cell.dataSource](catalogItem);
      element = <RichTextWidget {...cell.props} {...extraProps} />;
    } else if (orderCellIs(OrderWidgetType.labelText, cell)) {
      element = catalogItem.textLabels.map((label, index) => {
        return (
          <React.Fragment key={index}>
            <FancyTextComponent
              value={label}
              noWrap={true}
              style={{
                overflow: "visible",
                marginRight: "0.75em",
                marginTop: cell.overflowX === "visible" ? "0.5em" : undefined,
              }}
            />
          </React.Fragment>
        );
      });
    } else if (orderCellIs(OrderWidgetType.labelFilter, cell)) {
      element =
        catalogItem.labels.length > 0 ? (
          catalogItem.labels.map((label) => {
            return (
              <React.Fragment key={label.labelId}>
                <FancyTextComponent
                  value={label}
                  noWrap={true}
                  style={{
                    overflow: "visible",
                    marginRight: "0.75em",
                    marginTop:
                      cell.overflowX === "visible" ? "0.5em" : undefined,
                  }}
                />
              </React.Fragment>
            );
          })
        ) : (
          //RENDER EMPTY (HIDDEN) SEARCH LABEL
          //HERE AS TO MAKE SPACING THE SAME
          <FancyTextComponent value={{ text: "" }} />
        );
    } else if (orderCellIs(OrderWidgetType.iconFavorite, cell)) {
      element = (
        <IconFavoriteWidget catalogItem={catalogItem} {...cell.props} />
      );
    } else if (orderCellIs(OrderWidgetType.iconCart, cell)) {
      element = <IconCartWidget catalogItem={catalogItem} {...cell.props} />;
    } else if (orderCellIs(OrderWidgetType.grid, cell)) {
      element = (
        <GridWidget
          catalogItem={catalogItem}
          onImageReady={onImageReady}
          {...cell.props}
          parentPBSm={null}
          parentPBLg={null}
          parentColumnSm={null}
          parentColumnMd={null}
          parentColumnLg={null}
          parentColumnGap={null}
          widthCorrection={0}
        />
      );
    } else if (orderCellIs(OrderWidgetType.emptySpace, cell)) {
      element = <React.Fragment>&nbsp;</React.Fragment>;
    }
    //  else if (cellIs(OrderWidgetType.logo)(cell)) {
    //   element = (
    //     <Logo
    //       cell={cell}
    //       onChange={onChange}
    //       initPath={`${initPath}/site-name`}
    //       toolbarVisible={toolbarVisible}
    //     />
    //   );
    // } else if (cellIs(OrderWidgetType.navIcons)(cell)) {
    //   element = <NavIcons />;
    // } else if (cellIs(OrderWidgetType.navLinks)(cell)) {
    //   element = <NavLinks />;
    // } else if (cellIs(OrderWidgetType.wealthyText)(cell)) {
    //   element = <WealthyText initPath={`${initPath}/text`} />;
    // }

    return (
      <div
        ref={ref}
        {...rest}
        className={`boxShadow${cell.elevation} ${rest.className ?? ""}`}
        style={{
          gridArea: "cell" + cell.id,
          minWidth: 0,
          height: cell.height ? `${cell.height}px` : undefined,
          //ACCOMODATING LABEL SETTINGS
          // display: cell.overflowX !== "visible" ? "flex" : undefined,
          display: "flex",
          justifyContent: cell.horizontalAlign,
          alignItems: cell.verticalAlign,
          textAlign:
            //ACCOMODATING LABEL SETTINGS
            cell.overflowX === "visible"
              ? cell.horizontalAlign === "flex-start"
                ? "left"
                : cell.horizontalAlign === "flex-end"
                ? "right"
                : "center"
              : undefined,
          // overflowX: cell.overflowX,
          // overflowY: cell.overflowY,
          backgroundColor: themeColor(cell.backgroundColor),
          border: cell.border.thickness
            ? `${cell.border.thickness}px ${cell.border.style} ${themeColor(
                cell.border.color
              )}`
            : undefined,
          borderRadius: `${cell.border.radius}px`,
          paddingLeft: `${cell.padding.left.value}px`,
          paddingRight: `${cell.padding.right.value}px`,
          paddingTop: `${cell.padding.top.value}px`,
          paddingBottom: `${cell.padding.bottom.value}px`,
          ...rest.style,
        }}
      >
        {element}
        {children}
      </div>
    );
  }
);

export default OrderCellRenderer;
