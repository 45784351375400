import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Popover, PopoverActions, PopoverProps } from "@material-ui/core";
import { history } from "../../App/history";
import { useMeasure } from "../../../utilities/useMeasure";
import usePrevious from "../../../utilities/usePrevious";
import useForkRef from "../../../utilities/useForkRef";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Route, useLocation } from "react-router-dom";
import { useUnmount } from "../../../utilities/useUnmount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      WebkitOverflowScrolling: "touch",
    },
    paper: {
      // NOT NEEDED IF WE KEEP CLASS MOBILES BELOW
      // [theme.breakpoints.down("xs")]: {
      //   minWidth: "auto",
      //   width:
      //     "100vw" // THIS IS OK AS MAT-UI'S POPOVER CLASS CORRECTS FOR MARGINS WITH A MAXWIDTH PROP
      // },
    },
    mobiles: {
      // KEEP POPOPS AT THE TOP IN PHONES
      // MAINLY TO COUNTERACT ANNOYING JUMPS FOR KEYBOARD SLIDEOUTS
      // IN ANDROID PHONES
      [theme.breakpoints.down(768)]: {
        position: "static",
        margin: "16px auto 0 auto",
      },
    },
  })
);
/**
 * Accepts all the same props as Popover, except you pass in
 * route instead of open (open will be calculated from the route)
 */
export type MUIPopoverProps = Omit<PopoverProps, "open"> & {
  route: string | string[];
  /**
   * Optional function to only show the popover if the location contains
   * a certain state.
   */
  stateMatch?: (locationState: { [key: string]: unknown }) => boolean;
  onUnmount?: () => void;
  useStandardAnim?: boolean;
  /**
   * If true, whatever the height is after the initial render will be kept
   * forever, even if the contents change size.
   */
  keepHeight?: boolean;
  keepPosition?: boolean;
};

/**
 * A material-ui popover, but tied to a url and will reposition itself if it grows
 * outside the window
 */
const MUIPopover: FC<MUIPopoverProps> = ({
  route,
  stateMatch = () => true,
  ...rest
}) => {
  const location = useLocation();

  const state = (location.state ?? {}) as { [key: string]: unknown };
  return (
    <Route path={route}>
      {stateMatch(state) && <InnerComponent {...rest} />}
    </Route>
  );
};

/**
 * This code is split into an inner component for performance. Only
 * if the route matches does it need to be rendered.
 */
const InnerComponent: FC<Omit<MUIPopoverProps, "open" | "route">> = ({
  onClose,
  onUnmount,
  useStandardAnim,
  keepHeight = false,
  keepPosition = false,
  ...rest
}) => {
  const actions = useRef<PopoverActions>(null);
  const touchDevice = window.matchMedia("(hover: none)").matches;

  const [measureRef, bounds] = useMeasure();
  const myRef = useRef<HTMLElement>(null);
  const combinedRef = useForkRef<Element | null>(measureRef, myRef);

  const prevBounds = usePrevious(bounds);
  const classes = useStyles();

  const [height, setHeight] = useState<number>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (keepHeight && !height && myRef.current) {
      setHeight(myRef.current.clientHeight);
    }
  });

  useLayoutEffect(() => {
    if (bounds !== prevBounds) {
      if (myRef.current) {
        const boundingRect = myRef.current.getBoundingClientRect();
        if (
          boundingRect.left < 10 ||
          boundingRect.top < 10 ||
          boundingRect.bottom > window.innerHeight - 10 ||
          boundingRect.right > window.innerWidth - 10
        ) {
          actions.current?.updatePosition();
        }
      }
    }
  });

  useUnmount(onUnmount);

  return (
    <Popover
      onClick={(e) => e.stopPropagation()}
      ref={(el: HTMLDivElement | null) => {
        // We want a ref to the Paper inside the popover to measure changes in its size.
        //   however, trying to pass that ref in screws up Popover's internal code and
        //   breaks the positioning of the popover. So instead we get a ref to the outer
        //   el and use a query selector to drill in to the Paper
        let paper = null;
        if (el) {
          paper = el.querySelector(`:scope > .${classes.paper}`);
        }
        combinedRef(paper);
      }}
      className={
        //USING MAT-UI ANIMATION RESULTS OFTEN IN ROUGH
        //TRANSITIONS DUE TO HEIGHT GROWING ANIMATION
        //ONLY USE WHERE DOM IS SPARSELY POPULATED
        useStandardAnim ? classes.root : `${classes.root} anim_fadeIn_0300`
      }
      style={{ opacity: useStandardAnim ? undefined : 0, height }}
      // It is important that the paper has a class we can look up in the ref.
      //   If for some reason we no longer need styling on the paper, do not
      //   delete the class, just make it an empty object
      classes={{ paper: classes.paper }}
      action={actions}
      open={true}
      anchorOrigin={
        rest.anchorOrigin ?? { vertical: "bottom", horizontal: "center" }
      }
      transformOrigin={
        rest.transformOrigin ?? { vertical: "top", horizontal: "center" }
      }
      onBackdropClick={(e) => {
        e.stopPropagation();
      }}
      onClose={(event, reason) => {
        if (onClose) {
          onClose(event, reason);
        }
        history.goBack();
      }}
      {...rest}
      PaperProps={{
        ...rest.PaperProps,
        style:
          keepHeight && height !== undefined
            ? {
                ...rest.PaperProps?.style,
                height,
                position: touchDevice ? "static" : undefined,
                margin: touchDevice ? "16px auto 0 auto" : undefined,
              }
            : {
                ...rest.PaperProps?.style,
                position: keepPosition
                  ? undefined
                  : touchDevice
                  ? "static"
                  : undefined,
                margin: keepPosition
                  ? undefined
                  : touchDevice
                  ? "16px auto 0 auto"
                  : undefined,
              },

        classes: {
          ...rest.PaperProps?.classes,
          // root: keepPosition ? undefined : classes.mobiles,
        },
      }}
    />
  );
};

export default MUIPopover;
