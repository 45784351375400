import { FC, Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
  SiteFirebaseContext,
  useGridBashFirebase,
} from "../../Firebase/context";
import GridbashMain from "./gridbashMain";
import { Redirect, Router } from "react-router";
import SiteLoader from "./SiteLoader";
import { GridbashClaims, GridbashRole } from "../../database/userData";
import { SiteFirebase } from "../../Firebase/siteFirebase";
import { Site } from "../../database/site";
import DashboardWrapper from "./DashboardWrapper";
import { history } from "../App/history";
import GridbashUserProvider from "../UserProvider/gridbashUserProvider";
import GridbashRegister from "./register";
import { ToastProvider } from "../Main/useToast";
import "../../i18n";
import ROUTES from "../../utilities/constants/routes";

let forceRefresh = false;

// Forces a refresh of claims on the next time the auth state changes. This
//   is needed to get the latest claims when the user accepts an invite
export const setForceRefresh = () => {
  forceRefresh = true;
};

const GridbashApp: FC = () => {
  const gridbashFirebase = useGridBashFirebase();
  const [dashboardFirebase, setDashboardFirebase] = useState<SiteFirebase>();
  /** when an admin signs in, we also need to sign them in to the dashboard */
  useEffect(() => {
    let userSiteId: string | null | undefined;
    const unsubscribe = gridbashFirebase.auth.onAuthStateChanged(
      async (user) => {
        if (user) {
          const { claims } = await user.getIdTokenResult(forceRefresh);
          forceRefresh = false;
          let { siteId, role } = claims as GridbashClaims;
          if (role === GridbashRole.superuser) {
            const superuserSiteOverride = sessionStorage.getItem(
              "superuserSiteOverride"
            );
            if (superuserSiteOverride) {
              siteId = superuserSiteOverride;
            }
          }
          userSiteId = siteId;
          if (siteId) {
            const snapshot = await gridbashFirebase.firestore
              .collection("sites")
              .doc(siteId)
              .get();
            const site = snapshot.data() as Site | undefined;
            if (!site) {
              console.warn("couldn't find the admin's site");
              // TODO: figure out what we want to happen in this case
              return;
            }

            const dashboardFirebase = SiteFirebase.createInstance({
              config: site.firebaseConfig,
              name: "dashboard" + site.siteId,
              functionRegion: site.region,
              vapidKey: site.vapidKey,
              gridbashFirebase,
              siteId: site.siteId,
            });
            setDashboardFirebase(dashboardFirebase);
          } else {
            console.warn("no site is set up for this admin");
          }
        } else {
          const firebaseInstance = SiteFirebase.getInstance(
            "dashboard" + userSiteId
          );
          if (firebaseInstance) {
            await firebaseInstance.auth.signOut();
          }

          setDashboardFirebase(undefined);
        }
      }
    );

    return unsubscribe;
  }, [gridbashFirebase]);

  return (
    <Suspense fallback={null}>
      <Router history={history}>
        <ToastProvider>
          <GridbashUserProvider>
            <Switch>
              <Route path="/dashboard">
                {dashboardFirebase ? (
                  <SiteFirebaseContext.Provider value={dashboardFirebase}>
                    <DashboardWrapper />
                  </SiteFirebaseContext.Provider>
                ) : (
                  <RedirectIfNotLoggedIn />
                )}
              </Route>
              {/**
               * This route handles popovers for the home page. It needs to be higher up
               * in the swich than the wildcard path="/:path".
               *
               * We also render another route with a GridbashMain at the bottom. That
               * handles when the url is '/'. As long as we keep the component types the
               * same, react is smart enough to reuse the existing GridbashMain rather
               * than unmount/remount.
               * */}
              <Route
                path={[
                  "/auth",
                  "/l",
                  ROUTES.ML_CONTACT,
                  "/features",
                  "/fees",
                  "/am-i-in",
                  "/apply",
                  "/applySequence",
                  "/terms-of-service",
                  "/reset",
                  "/image",
                  "/costs",
                  "/why-gridbash",
                ]}
              >
                <GridbashMain />
              </Route>
              <Route path="/register">
                <GridbashRegister />
              </Route>

              {/**
               * We need to reserve a route which is unambiguously for us. That way,
               * if we ever need to add a page, but someone already has a site with
               * that name, we know we can put it under our route.
               *
               * For example, if we want to add a /foo page, but someone already has
               * a site with that name, then we'll add it as /gb/foo instead
               * */}
              <Route path="/gb">
                <Redirect to="/" />
              </Route>
              <Route path="/:path">
                <SiteLoader />
              </Route>
              <Route>
                <GridbashMain />
              </Route>
            </Switch>
          </GridbashUserProvider>
        </ToastProvider>
      </Router>
    </Suspense>
  );
};

const RedirectIfNotLoggedIn = () => {
  const gridbashFirebase = useGridBashFirebase();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    gridbashFirebase.isReady().then(() => setReady(true));
  }, [gridbashFirebase]);

  if (ready && !gridbashFirebase.auth.currentUser) {
    return <Redirect to="/" />;
  } else {
    return null;
  }
};

export default GridbashApp;
