import { FC, useEffect, useState } from "react";
import * as React from "react";
import ROUTES from "../../../utilities/constants/routes";
import "../css/paymentAuth.css";
import { useTranslation } from "react-i18next";
import { paymentAuthUtils } from "../../../utilities/httpsCallables/httpsCallables";
import useSpinner from "../../Spinner/useSpinner";
import PaymentAuthMain from "./paymentAuthMain";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { Order } from "../../../database/order";
import { useSiteFirebase } from "../../../Firebase/context";
import Container from "@material-ui/core/Container";
import HotTubIcon from "@material-ui/icons/HotTub";

interface PaymentAuthProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentAuth: FC<PaymentAuthProps> = React.memo(({ setMainBgImage }) => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ orderId: string }>();
  const location =
    useLocation<{
      initialOrder: Order;
      successUrl?: string;
      goBackOnCancel?: boolean;
    }>();
  // Lock in these values at time of mount:
  const [successUrl] = useState(location.state?.successUrl);
  const [goBackOnCancel] = useState(location.state?.goBackOnCancel);

  const history = useHistory();
  const firebase = useSiteFirebase();
  const showSpinner = useSpinner();
  const [order, setOrder] = useState<Order | null>(
    location.state?.initialOrder ?? null
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchOrder = async () => {
      const hideSpinner = showSpinner({ lag: "none" });
      try {
        const response = await paymentAuthUtils(firebase, {
          action: "retrieve",
          orderId: match.params.orderId,
        });
        setError(false);
        setOrder(response.order);
      } catch (error) {
        setError(true);
        setOrder(null);
      } finally {
        hideSpinner();
      }
    };

    if (match.params.orderId) {
      fetchOrder();
    } else {
      setError(true);
    }
  }, [firebase, match.params.orderId, showSpinner]);

  return (
    <React.Fragment>
      <Container maxWidth="sm" style={{ marginTop: "2.5vh" }}>
        {order ? (
          <PaymentAuthMain
            order={order}
            successUrl={successUrl}
            goBackOnCancel={goBackOnCancel}
          />
        ) : error ? (
          <React.Fragment>
            <div>{t("store.paymentAuth.noActionNeeded")}</div>
            <div
              onClick={() => {
                history.push(ROUTES.STORE);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
                flexDirection: "column",
              }}
            >
              <HotTubIcon />
              <span style={{ textDecoration: "underline" }}>
                {t("store.paymentAuth.getBackLink")}
              </span>
            </div>
          </React.Fragment>
        ) : (
          <div>{t("common.pleaseWait")}</div>
        )}
      </Container>
    </React.Fragment>
  );
});

export default PaymentAuth;
