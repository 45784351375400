import { Fragment, memo } from "react";
import {
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useSettingsApi } from "../Settings/settingsApiContext";
import MUIDialog from "./mui-dialog";
import Icon from "@material-ui/core/Icon";
import { History } from "history";
import { history } from "../../App/history";
import { usePageApi } from "../Builder/WebsiteBuilder/pageApi/pageApiContext";
import { useTranslation } from "react-i18next";

const PublishAppBar = () => {
  const { t } = useTranslation();
  const {
    dirty: pagesDirty,
    revert: revertPages,
    publish: publishPages,
  } = usePageApi();
  const {
    settingsDirty,
    privateSettingsDirty,
    revert: revertSettings,
    publish: publishSettings,
  } = useSettingsApi();
  const dirty = settingsDirty || privateSettingsDirty || pagesDirty;

  return dirty ? (
    <Fragment>
      <MUIDialog route="" stateMatch={(state) => Boolean(state.publish)}>
        <DialogTitle className="smartWidthMd">
          {t("dashboard.unpublishedChanges")}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              revertSettings();
              revertPages();
              history.goBack();
            }}
          >
            {t("dashboard.undo")}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (settingsDirty || privateSettingsDirty) {
                publishSettings();
              }
              if (pagesDirty) {
                publishPages();
              }
              history.goBack();
            }}
          >
            {t("dashboard.publish")}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              history.goBack();
            }}
          >
            Continue Edit
          </Button>
        </DialogActions>
      </MUIDialog>
      <Tooltip title="Publish Changes">
        <IconButton
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            const { location } = history as History<{} | undefined>;
            history.push(location.pathname, {
              ...location.state,
              publish: true,
            });
          }}
        >
          <Icon>unpublished</Icon>
        </IconButton>
      </Tooltip>
    </Fragment>
  ) : null;
};

export default memo(PublishAppBar);
