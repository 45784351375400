import { Fragment, FC, useMemo } from "react";
import { getTotalsForItems } from "../../utilities/orderProcessing";
import useCartApi from "../CartManager/useCartApi";
import Dinero from "dinero.js";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface CartSummaryProps {}

const CartSummary: FC<CartSummaryProps> = () => {
  const { t } = useTranslation();
  const { cartItems } = useCartApi();
  const cartTotals = useMemo(() => getTotalsForItems(cartItems), [cartItems]);
  const greaterThanZero = cartTotals.tax.greaterThan(Dinero({ amount: 0 }));

  return (
    <div
      style={{
        width: "100%",
        padding: "max(1em, 1vw) 0.5em max(1em, 1vw) 0.5em",
      }}
    >
      <table
        style={{
          width: "100%",
          margin: "0 auto",
          top: 0,
        }}
      >
        <tbody>
          <Fragment>
            <tr
              style={{
                opacity: greaterThanZero ? 1 : 0,
                transition: "all 0.5s linear 0s",
              }}
            >
              <td style={{ textAlign: "left" }}>
                <Typography color="textPrimary" variant="h6">
                  {t("store.orders.subtotal")}
                </Typography>
              </td>
              <td style={{ textAlign: "right" }}>
                <Typography color="textPrimary" variant="h6">
                  {cartTotals.beforeTax.toFormat()}
                </Typography>
              </td>
            </tr>
            {Object.entries(cartTotals.taxTable).map(([taxRate, tax]) => (
              <tr
                key={taxRate}
                style={{
                  opacity: greaterThanZero ? 1 : 0,
                  transition: "all 0.5s linear 0s",
                }}
              >
                <td style={{ textAlign: "left" }}>
                  <Typography color="textPrimary">
                    {t("store.orders.taxTaxRate", { rate: taxRate })}
                  </Typography>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Typography color="textPrimary">{tax.toFormat()}</Typography>
                </td>
              </tr>
            ))}
          </Fragment>
          <tr>
            <td
              style={{
                textAlign: "left",
                fontWeight: "bold",
                paddingRight: "1em",
              }}
            >
              <Typography color="textPrimary" variant="h6">
                {t("store.orders.total")}
              </Typography>
            </td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
                paddingLeft: "1em",
              }}
            >
              <Typography color="textPrimary" variant="h6">
                {cartTotals.total.toFormat()}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CartSummary;
