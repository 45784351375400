import Typography from "@material-ui/core/Typography";
import { memo, Fragment, FC } from "react";
import CardReader1Img from "../img/BBPOS.jpeg";
import CardReader2Img from "../img/P400.jpeg";

interface HardwareProps {}

const Hardware: FC<HardwareProps> = memo(() => {
  return (
    <Fragment>
      <div
        style={{
          padding: "2em 2em 0 2em",
        }}
      >
        <Typography variant="h4">
          Accept in-person payments (optional)
        </Typography>
        <Typography>
          Accept in-person payments in a flash, including Google and Apple Pay.
        </Typography>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={CardReader1Img}
              alt="contacless card reader"
              style={{
                maxHeight: "250px",
                verticalAlign: "middle",
                marginBottom: "15px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={CardReader2Img}
              alt="card chip reader"
              style={{
                maxHeight: "500px",
                verticalAlign: "middle",
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default Hardware;
