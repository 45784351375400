import { useState, useMemo, FC, useEffect, useLayoutEffect, useRef } from "react";
import * as React from "react";
import "../css/orders.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../utilities/useQuery";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { useFetchCategory } from "../../../customization/useFetchCategory";
import CatTabs from "./Templates/catTabs";
import Grid1 from "./Templates/grid_1";
import { OrderTemplate } from "../../../database/orderPage";
import { IconButton, Typography } from "@material-ui/core";
import RightArrowIcon from "@material-ui/icons/ArrowRight";
import LeftArrowIcon from "@material-ui/icons/ArrowLeft";
import ItemOrder from "./Templates/gridItem_grid";
import { CatalogItem_WithDefaults } from "../../../database/catalogItem";

interface OrderPageProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
  headerHeight: number;
  heightCorrection?: number;
  widthCorrection?: number;
  buildScrolling?: number;
}

const OrderPage: FC<OrderPageProps> = (props) => {
  const { orderPageOptions } = useSiteSettings();
  // Need to figure out the height of the order items to know how many
  //   items to show per page
  const [height, setHeight] = useState<null | number>(null);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, []);

  if (height === null) {
    const fakeItem: CatalogItem_WithDefaults = {
      itemId: "fake",
      catalogIds: [],
      name: "",
      nameSearch: [],
      description: "",
      imageUrl: "",
      imageUrls: {},
      imageSize: "cover",
      imageZoom: { x: 0, y: 0 },
      useZoom: false,
      useContain: false,
      imageMetadata: {
        width: 0,
        height: 0,
      },
      widthWeight: 1,
      leftWeight: 0.5,
      topWeight: 0.5,
      aspectRatio: 1,
      price: 0,
      options: [],
      hidden: false,
      unavailable: false,
      inActiveCatalog: true,
      taxPercentage: 0,
      textLabels: [],
      labels: [],
      labelCombinations: [],
      priceIncludesTax: true,
      currency: {
        currency: "USD",
        locale: "",
      },
    };
    return (
      <ItemOrder
        ref={ref}
        style={{ opacity: 0 }}
        templateOptions={orderPageOptions}
        catalogItem={fakeItem}
        widthCorrection={props.widthCorrection ?? 0}
        rowGapOrig={0}
        columnGapOrig={0}
      />
    );
  }
  return <InnerComponent {...props} itemHeight={height} />;
};

const InnerComponent: FC<OrderPageProps & { itemHeight: number }> = React.memo(
  ({
    setMainBgImage,
    headerHeight,
    heightCorrection,
    widthCorrection,
    buildScrolling,
    itemHeight,
  }) => {
    const { catalog, orderPageOptions } = useSiteSettings();
    const categories = catalog.categories;
    const { t } = useTranslation();

    const query = useQuery();
    const [categoryId, setCategoryId] = useState<string>(() => {
      const idFromUrl = query.get("categoryId");
      if (idFromUrl && categories.find((cat) => cat.categoryId === idFromUrl)) {
        return idFromUrl;
      }
      return categories[0]?.categoryId ?? "";
    });
    const category = categories.find((cat) => cat.categoryId === categoryId);
    useEffect(() => {
      if (!category) {
        // This can happen if the admin switches catalogs, or deletes a category
        //   from the current catalog. Move the user to the first category.
        setCategoryId(categories[0]?.categoryId ?? "");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    // Lock in the page size at the time of mount. It would be quite tricky to
    //    support changing the page size when they change their window size, or
    //    when the admin publishes changes to columns.
    const pageSize = useMemo(() => {
      const {
        breakpointSm,
        breakpointLg,
        columnsSm,
        columnsMd,
        columnsLg,
        rowGap,
      } = orderPageOptions.props;
      let gridColumns = columnsLg;

      if (window.innerWidth - (widthCorrection ?? 0) < breakpointSm) {
        gridColumns = columnsSm;
      } else if (window.innerWidth - (widthCorrection ?? 0) < breakpointLg) {
        gridColumns = columnsMd;
      }

      // Goal with the rows is to have approximately enough for two screen-heights.
      const rowsPerScreen = window.innerHeight / (itemHeight + rowGap);
      const minRows = 4; // It's weird to show a tiny number of rows
      const gridRows = Math.max(minRows, Math.round(2 * rowsPerScreen));
      return gridRows * gridColumns;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
      items: categoryItems,
      page,
      totalPages,
      nextPage,
      prevPage,
    } = useFetchCategory(category, pageSize);

    const filteredCategories = useMemo(() => {
      return categories.filter((category) => !category.hidden);
    }, [categories]);

    useEffect(() => {
      setMainBgImage(false);
      window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    const [scrolling, setScrolling] = useState(0);

    useEffect(() => {
      if (buildScrolling !== undefined) return;
      let lastScrollY = 0;
      const onScroll = () => {
        //PREVENT CATEGORIES FROM SHOWING WHEN MODAL OR DRAWER OPENS
        if (document.documentElement.style.position === "fixed") return;
        if (window.scrollY > 0 && window.scrollY > lastScrollY) {
          setScrolling(window.scrollY);
        } else {
          if (window.scrollY <= 0) {
            setScrolling(0);
          } else {
            setScrolling(-1);
          }
        }
        lastScrollY = window.scrollY <= 0 ? 0 : window.scrollY;
      };
      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    }, [buildScrolling]);

    const [tabId, setTabId] = useState(0);

    return (
      <React.Fragment>
        {categories.length === 0 ? (
          <div>{t("store.orders.comingSoon")}</div>
        ) : (
          <React.Fragment>
            {orderPageOptions.template === OrderTemplate.original ? (
              <CatTabs
                headerHeight={headerHeight}
                scrolling={buildScrolling ? buildScrolling : scrolling}
                tabId={tabId}
                setTabId={setTabId}
                filteredCategories={filteredCategories}
                setCategoryId={setCategoryId}
              />
            ) : undefined}
            {orderPageOptions.template === OrderTemplate.original ? (
              <Grid1
                templateOptions={orderPageOptions}
                heightCorrection={heightCorrection}
                headerHeight={headerHeight}
                items={categoryItems}
                categoryId={categoryId}
                widthCorrection={widthCorrection}
              />
            ) : undefined}
            {totalPages > 1 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  disabled={page === 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    prevPage();
                  }}
                >
                  <LeftArrowIcon />
                </IconButton>
                <Typography>
                  {totalPages !== undefined
                    ? t("common.pageXofY", {
                        count: page + 1,
                        totalPages: totalPages,
                      })
                    : t("common.pageX", { count: page + 1 })}
                </Typography>
                <IconButton
                  disabled={page >= totalPages - 1}
                  onClick={(e) => {
                    e.stopPropagation();
                    nextPage();
                  }}
                >
                  <RightArrowIcon />
                </IconButton>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
);

export default OrderPage;
