import { FC, useState } from "react";
import * as React from "react";
import { Order, revisionIsEmpty } from "../../database/order";
import InvoiceRevision from "./invoiceRevision";
import InvoicePriceSummary from "./invoicePriceSummary";
import { Button } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/ArrowRight";
import { useTranslation } from "react-i18next";
import ManualAdjustment from "../Dashboard/Modals/dbCharge/manualAdjustment";
import MUIPopover, {
  MUIPopoverProps,
} from "../Dashboard/Components/mui-popover";
import { history } from "../App/history";

interface BaseProps {
  order: Order;
  showAdminFeatures: boolean;
  showAdjustment?: boolean;
  setShowAdjustment?: React.Dispatch<React.SetStateAction<boolean>>;
  manualAdjustmentStr?: string;
  setManualAdjustmentStr?: React.Dispatch<React.SetStateAction<string>>;
  reason?: string;
  setReason?: React.Dispatch<React.SetStateAction<string>>;
  setDeletedItems?: React.Dispatch<React.SetStateAction<Record<string, true>>>;
}

interface StoreSideProps extends BaseProps {
  showAdminFeatures: false;
  showAdjustment?: never;
  setShowAdjustment?: never;
  manualAdjustmentStr?: never;
  setManualAdjustmentStr?: never;
  reason?: never;
  setReason?: never;
  setDeletedItems?: never;
}

interface DashboardSideProps extends BaseProps {
  showAdminFeatures: true;
  showAdjustment: boolean;
  setShowAdjustment: React.Dispatch<React.SetStateAction<boolean>>;
  manualAdjustmentStr: string;
  setManualAdjustmentStr: React.Dispatch<React.SetStateAction<string>>;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  setDeletedItems: React.Dispatch<React.SetStateAction<Record<string, true>>>;
  initPath: string;
}

const Invoice: FC<StoreSideProps | DashboardSideProps> = (props) => {
  const { t } = useTranslation();
  const [popoverProps, setPopoverProps] = useState<MUIPopoverProps>();
  const openAdjustmentModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!props.showAdminFeatures) {
      return;
    }
    if (!window.location.pathname.includes("adjustment")) {
      history.push(`${props.initPath}/adjustment`);
    }
    setPopoverProps({
      anchorEl: e.currentTarget,
      route: `${props.initPath}/adjustment`,
      anchorOrigin: { vertical: "top", horizontal: "center" },
      transformOrigin: { vertical: "top", horizontal: "center" },
      children: (
        <ManualAdjustment
          order={props.order}
          amount={props.manualAdjustmentStr}
          setAmount={props.setManualAdjustmentStr}
          reason={props.reason}
          setReason={props.setReason}
          onCancel={() => history.goBack()}
        />
      ),
    });
  };

  return (
    <React.Fragment>
      {popoverProps && <MUIPopover {...popoverProps} />}
      <div
        style={{ marginTop: "max(1vw, 1em)", marginBottom: "max(1vw, 1em)" }}
      >
        <InvoicePriceSummary
          source="total"
          priceInfo={props.order.cumulativePrice}
        />
        {props.showAdminFeatures && (
          <div
            style={{
              textAlign: "center",
              marginTop: "0.25em",
              marginBottom: "max(1vw, 1em)",
              transition: "all 0.5s ease 0s",
              opacity: Number(props.manualAdjustmentStr) === 0 ? 1 : 0,
            }}
          >
            <Button
              fullWidth
              // variant="outlined"
              disabled={Number(props.manualAdjustmentStr) !== 0}
              startIcon={<TuneIcon />}
              onClick={(e) => {
                e.stopPropagation();
                openAdjustmentModal(e);
              }}
            >
              {t("dashboard.charges.applyAdjustment")}
            </Button>
          </div>
        )}
        {props.order.revisions.map(
          (revision, i) =>
            !revisionIsEmpty(revision) && (
              <InvoiceRevision
                key={i}
                revision={revision}
                index={i}
                setReason={props.setReason}
                setManualAdjustmentStr={props.setManualAdjustmentStr}
                order={props.order}
                openAdjustmentModal={openAdjustmentModal}
                showAdminFeatures={props.showAdminFeatures}
              />
            )
        )}
      </div>
    </React.Fragment>
  );
};

export default Invoice;
