import { Fragment, FC } from "react";
import { useTranslation } from "react-i18next";
import PasswordIcon from "@material-ui/icons/Security";
import { history } from "../../App/history";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import AccountPassword from "../../Store/Modals/accountPassword";
import MUIDialog from "../Components/mui-dialog";

interface PasswordListItemProps {
  initPath: string;
}

const PasswordListItem: FC<PasswordListItemProps> = ({ initPath }) => {
  const { t } = useTranslation();

  const popoverPath = `${initPath}/password`;

  return (
    <Fragment>
      <ListItem
        disableRipple
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(popoverPath);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <PasswordIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={t("store.account.accountPassword")}
          secondary="************"
        />
      </ListItem>

      <MUIDialog route={popoverPath}>
        <AccountPassword initPath={popoverPath} />
      </MUIDialog>
    </Fragment>
  );
};

export default PasswordListItem;
