import { useState, FC, useEffect } from "react";
import * as React from "react";
import ROUTES from "../../../utilities/constants/routes";
import useAccountInfo from "../../Main/useAccountInfo";
import useSiteUser from "../../UserProvider/useSiteUser";
import SectionSignedOut from "../Components/sectionSignedOut";
import { Container } from "@material-ui/core";
import List from "@material-ui/core/List";
import NameListItem from "../../Dashboard/Account/nameListItem";
import PasswordListItem from "../../Dashboard/Account/passwordListItem";
import EmailListItem from "../../Dashboard/Account/emailListItem";
import NotificationsListItem from "./notificationsListItem";
import PhoneListItem from "../../Dashboard/Account/phoneListItem";
import AddressListItem from "../../Dashboard/Account/addressListItem";
import { useOptimisticUpdate } from "../../../utilities/useOptimisticUpdate";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

interface AccountProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Account: FC<AccountProps> = React.memo(({ setMainBgImage }) => {
  const { user: authUser } = useSiteUser();
  const { t } = useTranslation();
  /**
   * We assume most operations will succeed and synchronously update the ui
   * while waiting for the database to confirm the change. In the rare cases
   * where it fails, we then need to roll back.
   */
  const realAccountInfo = useAccountInfo();
  const [accountInfo, setOptimisticUpdate] =
    useOptimisticUpdate(realAccountInfo);

  const [signedIn, setSignedIn] = useState(false);
  useEffect(() => {
    if (authUser) {
      const id = setTimeout(() => {
        setSignedIn(true);
        //WE NEED A DELAY FOR THE SIGN IN MODAL TO UNMOUNT
        //AND NOT CAUSE IT TO JUMP
      });
      return () => clearTimeout(id);
    } else {
      setSignedIn(false);
    }
  }, [authUser]);

  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ margin: "max(1em, 1vw) auto" }} maxWidth="sm">
      <Typography color="textPrimary" variant="h6">
        {t("store.account.myProfile")}
      </Typography>
      {authUser && signedIn ? (
        <div className="anim_moveUp_small_0300">
          <div style={{ padding: "0 0.5em" }}>
            <List component="nav">
              <NotificationsListItem
                accountInfo={accountInfo}
                setOptimisticUpdate={setOptimisticUpdate}
                initPath={ROUTES.ACCOUNT}
              />
              <EmailListItem initPath={ROUTES.ACCOUNT} />
              <PasswordListItem initPath={ROUTES.ACCOUNT} />
              <NameListItem
                accountInfo={accountInfo}
                setOptimisticUpdate={setOptimisticUpdate}
                initPath={ROUTES.ACCOUNT}
              />
              <PhoneListItem
                accountInfo={accountInfo}
                setOptimisticUpdate={setOptimisticUpdate}
                initPath={ROUTES.ACCOUNT}
              />
              <AddressListItem
                accountInfo={accountInfo}
                setOptimisticUpdate={setOptimisticUpdate}
                initPath={ROUTES.ACCOUNT}
              />
            </List>
          </div>
        </div>
      ) : (
        <div
          className="anim_fadeIn_0503 sTsignedOutSection"
          style={{ opacity: 0, marginBottom: "1em" }}
        >
          <SectionSignedOut />
        </div>
      )}
    </Container>
  );
});

export default Account;
