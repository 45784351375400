import { createContext, useContext } from "react";

export const WidgetEnvironmentContext = createContext<
  "editor" | "preview" | undefined
>(undefined);

/**
 * A context value indicating whether the widget is being rendered in the
 * builder, in a preview, or the actual site. Some widgets modify their
 * behavior based on this value.
 *
 * This is done via context instead of props, because widgets can be
 * nested arbitrarily deep inside other widgets, eg with Custom Widgets
 * or Grid Widgets.
 *
 * @example
 * ```typescript
 * const ExampleWidget = () => {
 *   const env = useWidgetEnvironment();
 *   return (
 *     <div
 *       onClick={(e) => {
 *         if (env === "editor") {
 *           // Do nothing. The click will fall through and focus the widget
 *         } else if (env === "preview") {
 *           alert("This button is unavailable in a preview.")
 *         } else {
 *           history.push(`${ROUTES.FAVORITES}`)
 *         }
 *       }}
 *     >
 *
 *     </div>
 *   )
 * }
 * ```
 */
export const useWidgetEnvironment = () => useContext(WidgetEnvironmentContext);
