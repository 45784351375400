import { memo, FC } from "react";
import SettingsApiProvider from "./settingsApiProvider";
import CatalogApiProvider from "../Catalog/CatalogApiProvider/catalogApiProvider";
import PageApiProvider from "../Builder/WebsiteBuilder/pageApi/pageApiProvider";

/**
 * If an admin is logged in, renders the providers for managing
 * editing state.
 */
const EditingProvider: FC<{}> = memo(({ children }) => {
  // Create the global editing apis. These are done at the top of the component
  //   tree so they can be retained when navigating to other pages
  return (
    // Settings needs to be on the outside, since gridApiProvider accesses it
    <SettingsApiProvider>
      <CatalogApiProvider>
        <PageApiProvider>{children}</PageApiProvider>
      </CatalogApiProvider>
    </SettingsApiProvider>
  );
});

export default EditingProvider;
