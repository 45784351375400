import {
  forwardRef,
  cloneElement,
  HTMLAttributes,
  useRef,
  useLayoutEffect,
  ReactElement,
  CSSProperties,
} from "react";
import { FixedSizeList } from "react-window";

interface Props {
  selectedIndex?: number;
  itemSize?: number;
  itemStyle?: CSSProperties;
}

/**
 * Custom listbox, for use with the autocomplete component. Implements
 * a virtualized list to improve performance on long lists.
 */
export const ListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & Props
>((props, ref) => {
  const {
    selectedIndex = -1,
    children,
    itemSize = 36,
    itemStyle,
    ...rest
  } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;

  const listRef = useRef<FixedSizeList>(null);
  useLayoutEffect(() => {
    if (selectedIndex > -1) {
      listRef.current?.scrollToItem(selectedIndex, "start");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref}>
      <div {...rest}>
        <FixedSizeList
          ref={listRef}
          height={250}
          width="100%"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {(props) => {
            if (!Array.isArray(children)) {
              return null;
            }
            const child = children[props.index];
            if (!child) {
              return null;
            }
            return cloneElement(child as ReactElement, {
              style: {
                ...props.style,
                ...itemStyle,
              },
            });
          }}
        </FixedSizeList>
      </div>
    </div>
  );
});
