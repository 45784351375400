import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import { AccountInfo } from "../../Main/useMain";
import AccountName from "../../Store/Modals/accountName";
import PersonIcon from "@material-ui/icons/Person";
import MUIDialog from "../Components/mui-dialog";

interface NameListItemProps {
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
  initPath: string;
}

const NameListItem: FC<NameListItemProps> = ({
  accountInfo,
  setOptimisticUpdate,
  initPath,
}) => {
  const { t } = useTranslation();

  const popoverPath = `${initPath}/name`;

  return (
    <React.Fragment>
      <ListItem
        disableRipple
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(popoverPath);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={t("store.account.accountName")}
          secondary={
            accountInfo.contactInfo.name || t("store.account.noNameButton")
          }
        />
      </ListItem>

      <MUIDialog route={popoverPath}>
        <AccountName
          accountInfo={accountInfo}
          setOptimisticUpdate={setOptimisticUpdate}
          initialName={accountInfo.contactInfo.name}
          initPath={popoverPath}
        />
      </MUIDialog>
    </React.Fragment>
  );
};

export default NameListItem;
