import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CatalogItem_Database } from "../../../database/catalogItem";
import { useSiteFirebase } from "../../../Firebase/context";
import { storeKey } from "../../../Firebase/siteFirebase";
import { notUndefined } from "../../../utilities/notNull";
import { unstable_batchedUpdates } from "react-dom";
import type firebase from "firebase";

type Doc = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;

interface Options {
  selectedLabels: Record<string, true>;
  pageSize?: number;
  // If true, only get items to display on the store (ie, active catalog, non-hidden)
  forStore?: boolean;
  cacheBust?: number;
}

/**
 * Searches the database for catalog items, and merges the results with
 * any edits that have been made locally but not published.
 */
export const useItemLabelSearch = ({
  selectedLabels,
  pageSize = 20,
  forStore = false,
  cacheBust,
}: Options) => {
  const siteFirebase = useSiteFirebase();

  const labelCombination = useMemo(() => {
    return Object.keys(selectedLabels)
      .sort((a, b) => {
        if (a < b) {
          return -1;
        } else if (b < a) {
          return 1;
        } else {
          return 0;
        }
      })
      .join("");
  }, [selectedLabels]);

  const [items, setItems] = useState<CatalogItem_Database[]>([]);
  const [loading, setLoading] = useState(Boolean(labelCombination));
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [lastDoc, setLastDoc] = useState<Doc | undefined>();

  let firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setItems([]);
    setLoading(Boolean(labelCombination));
    setHasMoreResults(false);
    setLastDoc(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheBust]);

  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const fetchData = useCallback(
    async (startAfter?: Doc) => {
      let query = siteFirebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("items")
        .where("labelCombinations", "array-contains", labelCombination);
      if (forStore) {
        query = query
          .where("inActiveCatalog", "==", true)
          .where("hidden", "==", false)
          .orderBy("name");
      } else {
        query = query.orderBy("name");
      }

      if (startAfter) {
        query = query.startAfter();
      }
      // We fetch 1 extra document to let us know whether there are more results
      query = query.limit(pageSize + 1);

      let snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
      try {
        snapshot = await query.get();
      } catch (err) {
        console.error(err);
        return;
      }

      if (unmounted.current) {
        return;
      }

      const newData: CatalogItem_Database[] = snapshot.docs
        .map((doc) => doc.data() as CatalogItem_Database | undefined)
        .filter(notUndefined);

      let hasMoreResults = false;
      if (newData.length === pageSize + 1) {
        // We fetch 1 more than the page size to give us a peek into the next page.
        //   If that peek was included in our results, then we have more results.
        hasMoreResults = true;
        // However, we don't want to display it to the user, and don't want to
        //   duplicate it if they move to the next page, so remove it.
        newData.pop();
      }

      // Since this is in an async function after an await, react can't
      // automatically batch the changes, so we do so manually
      unstable_batchedUpdates(() => {
        setLoading(false);
        setItems((prev) => [...prev, ...newData]);
        setHasMoreResults(hasMoreResults);
        setLastDoc(snapshot.docs[snapshot.docs.length - 2]);
      });
    },
    [siteFirebase.firestore, labelCombination, forStore, pageSize]
  );

  useEffect(() => {
    setItems([]);
    setLoading(true);
    setHasMoreResults(false);
    setLastDoc(undefined);
    if (labelCombination) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelCombination]);

  const getMoreResults = useCallback(() => {
    setLoading(true);
    return fetchData(lastDoc);
  }, [fetchData, lastDoc]);

  return { items, loading, hasMoreResults, getMoreResults } as const;
};
