import { FC, useState } from "react";
import { srcSetString } from "../../Main/main";
import { history } from "../../App/history";
import { CatalogItem_WithDefaults } from "../../../database/catalogItem";

interface EnlargedImgModalProps {
  catalogItem: CatalogItem_WithDefaults;
}

const EnlargedImgModal: FC<EnlargedImgModalProps> = ({ catalogItem }) => {
  const [imageInfoLarge, setImageInfoLarge] = useState<{
    loaded: boolean;
    error: boolean;
  }>({
    loaded: false,
    error: false,
  });

  return (
    <img
      onClick={(e) => {
        e.stopPropagation();
        history.goBack();
      }}
      src={catalogItem.imageUrl}
      /**
       * SRCSET EXPLAINED
       *
       * IN DEV TOOLS
       * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
       * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
       * ACTUALLY TAKING UP.
       *
       * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
       * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
       */
      srcSet={srcSetString(catalogItem.imageUrls)}
      /**
       * SIZES EXPLAINED
       *
       * THIS MODAL WILL NOT GROW WIDER THAN 1280PX
       * BECAUSE THE DEFAULT MUI DIALOG MAX WIDTH IS 1280PX
       *
       * FOR VIEWPORT WIDTHS SMALLER THAN 1280, USE
       * 100VW - 64PX BECAUSE THE MUIDIALOG HAS 32PX
       * MARGINS LEFT AND RIGHT
       */
      sizes="(min-width: 1280px) 1280px, calc(100vw - 64px)"
      alt={catalogItem.name}
      onLoad={() => {
        setImageInfoLarge({ loaded: true, error: false });
      }}
      onError={() => {
        setImageInfoLarge({ loaded: true, error: true });
      }}
      style={{
        objectFit: "contain",
        objectPosition: catalogItem.imageZoom
          ? `${catalogItem.imageZoom.x}% ${catalogItem.imageZoom.y}%`
          : "center",
        verticalAlign: "middle",
        maxWidth: "100%",
        maxHeight: "calc(100vh - 64px)", //MODAL HAS 32PX MARGINS
        opacity: imageInfoLarge.loaded ? 1 : 0,
        transition: "all 0.5s ease 0s",
      }}
    />
  );
};

export default EnlargedImgModal;
