import DOMPurify from "dompurify";
import { FC, useContext, useEffect } from "react";
import * as React from "react";
import { loadFonts } from "../../../../utilities/fontLoader";
import { getFontsInRichText } from "../../../../utilities/getFontsInRichText";
import { CatalogItemWidgetContext } from "./catalogItemWidget";

export interface WealthyTextWidgetProps {
  text?: string;
  datasource?: `description-${number}`;
  boxShadowX: number;
  boxShadowY: number;
  boxShadowSpread: number;
  boxShadowBlur: number;
  boxShadowColor: string;
  borderWidth: number;
  borderRadius: number;
  borderStyle:
    | "hidden"
    | "dotted"
    | "dashed"
    | "solid"
    | "double"
    | "groove"
    | "ridge"
    | "inset"
    | "outset"
    | "none"
    | undefined;
  borderColor: string;
  backgroundColor: string | undefined;
}

export interface WealthyTextWidgetEidtorOverrides {
  onClick?: (e: React.MouseEvent) => void;
}

const WealthyTextWidget: FC<
  WealthyTextWidgetProps & WealthyTextWidgetEidtorOverrides
> = ({
  text,
  onClick,
  datasource,
  boxShadowColor = "#000000",
  boxShadowSpread = 0,
  boxShadowBlur = 0,
  boxShadowX = 0,
  boxShadowY = 0,
  borderWidth = 0,
  borderColor = "#000000",
  borderRadius = 0,
  borderStyle = "solid",
  backgroundColor,
}) => {
  const catalogItem = useContext(CatalogItemWidgetContext);
  if (!text && catalogItem && datasource) {
    const index = Number(datasource.split("-")[1]);
    if (Array.isArray(catalogItem.description)) {
      text = catalogItem.description[index] ?? "";
    } else if (index === 0) {
      text = catalogItem.description;
    }
  }

  useEffect(() => {
    if (text) {
      // Ideally, we'll load all fonts up front when the page loads, but
      // if we missed any, load them now.
      const fonts = getFontsInRichText(text);
      loadFonts(fonts);
    }
  }, [text]);

  const boxShadowUsed: boolean =
    (typeof boxShadowBlur === "number" && boxShadowBlur > 0) ||
    (typeof boxShadowSpread === "number" && boxShadowSpread > 0) ||
    (typeof boxShadowX === "number" && boxShadowX !== 0) ||
    (typeof boxShadowY === "number" && boxShadowY !== 0);

  // const marginLeftBoxShadow = !boxShadowUsed
  //   ? 0
  //   : boxShadowX && boxShadowX < 0
  //   ? Math.abs(
  //       (boxShadowX ?? 0) - (boxShadowSpread ?? 0) - (boxShadowBlur ?? 0)
  //     )
  //   : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);
  // const marginRightBoxShadow = !boxShadowUsed
  //   ? 0
  //   : boxShadowX && boxShadowX > 0
  //   ? (boxShadowX ?? 0) + (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0)
  //   : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);

  // const marginTopBoxShadow = !boxShadowUsed
  //   ? 0
  //   : boxShadowY && boxShadowY < 0
  //   ? Math.abs(
  //       (boxShadowY ?? 0) - (boxShadowSpread ?? 0) - (boxShadowBlur ?? 0)
  //     )
  //   : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);
  // const marginBottomBoxShadow = !boxShadowUsed
  //   ? 0
  //   : boxShadowY && boxShadowY >= 0
  //   ? (boxShadowY ?? 0) + (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0)
  //   : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);

  return (
    <div
      className="ql-editor"
      style={{
        minHeight: 0,
        width: "100%",
        borderRadius: borderRadius > 0 ? `${borderRadius}px` : undefined,
        border:
          borderWidth > 0
            ? `${borderWidth}px ${borderStyle} ${borderColor}`
            : undefined,
        boxShadow: boxShadowUsed
          ? `${boxShadowX ?? 0}px ${boxShadowY ?? 0}px ${
              boxShadowBlur ?? 0
            }px ${boxShadowSpread ?? 0}px ${boxShadowColor ?? "black"}`
          : undefined,
        /**
         * MARGIN HAS BEEN COMMENTED OUT ON OCT 20 9:56AM
         * BECAUSE IT PREVENTS BOX-SHADOWS TO WORK IN PREVIEW
         *
         */
        // marginTop: !boxShadowUsed ? undefined : `${marginTopBoxShadow}px`,
        // marginRight: !boxShadowUsed ? undefined : `${marginRightBoxShadow}px`,
        // marginBottom: !boxShadowUsed ? undefined : `${marginBottomBoxShadow}px`,
        // marginLeft: !boxShadowUsed ? undefined : `${marginLeftBoxShadow}px`,
        backgroundColor,
      }}
      onClick={onClick}
      dangerouslySetInnerHTML={
        text
          ? {
              __html: DOMPurify.sanitize(text.trim()),
            }
          : undefined
      }
    />
  );
};

export default WealthyTextWidget;
