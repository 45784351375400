import { FC } from "react";
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import ListItem from "@material-ui/core/ListItem";
import EmailIcon from "@material-ui/icons/Email";
import PushIcon from "@material-ui/icons/Chat";
import SMSIcon from "@material-ui/icons/Sms";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface CommUIProps {}

const CommUI: FC<CommUIProps> = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "100%",
        gridTemplateAreas: `
"AppBar"
"MessageSection"`,
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",

        marginRight: "10px",
        marginLeft: "10px",
        maxHeight: "271px",
        overflow: "hidden",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          gridArea: "AppBar",
          backgroundColor: "#0c343d",
          color: "white",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          padding: "0.5em 1em",
        }}
      >
        <Typography noWrap style={{ fontSize: "0.7em" }}>
          LOCATION & TIMING
        </Typography>
        &nbsp;
        <div style={{ flexGrow: 1 }} />
        <Typography noWrap style={{ fontSize: "0.7em" }}>
          PAYMENTS
        </Typography>
        &nbsp;
        <div style={{ flexGrow: 1 }} />
        <div style={{ position: "relative" }}>
          <Typography noWrap style={{ fontSize: "0.7em" }}>
            COMMUNICATION
          </Typography>
          <div
            style={{
              position: "absolute",
              border: "1px solid cyan",
              bottom: "-9px",
              width: "100%",
            }}
          />
        </div>
        &nbsp;
        <div style={{ flexGrow: 1 }} />
        <Typography noWrap style={{ fontSize: "0.7em" }}>
          USERS
        </Typography>
      </div>
      <div
        style={{
          gridArea: "MessageSection",
          padding: "1em",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "60%",
            top: "50%",
            transform: "translate(-50%, -60%)",
            backgroundColor: "#f5f5f5",
            padding: "0.5em",
            borderRadius: "4px",
            boxShadow:
              "0px 6px 9px -2px rgb(0 0 0 / 20%), 0px 19px 33px 0px rgb(0 0 0 / 14%), 0px 5px 41px 3px rgb(0 0 0 / 12%)",
            textAlign: "left",
            zIndex: 1,
            width: "60%",
          }}
        >
          <Typography style={{ fontSize: "0.8em", marginBottom: "0.5em" }}>
            Email Settings
          </Typography>

          <div style={{ padding: "0.25em" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid gray",
              }}
            >
              <Typography style={{ fontSize: "0.6em" }}>Yes</Typography>
              <ExpandMoreIcon style={{ fontSize: "0.8em" }} />
            </div>

            <Typography
              color="textSecondary"
              style={{ fontSize: "0.5em", marginTop: "0.25em" }}
            >
              Enable Email Communication?
            </Typography>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid gray",
                marginTop: "0.25em",
              }}
            >
              <Typography style={{ fontSize: "0.6em" }}>Yes</Typography>
              <ExpandMoreIcon style={{ fontSize: "0.8em" }} />
            </div>

            <Typography
              color="textSecondary"
              style={{ fontSize: "0.5em", marginTop: "0.25em" }}
            >
              Send automatic receipts? (you can still do this manually)
            </Typography>

            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid gray",
                marginTop: "0.25em",
              }}
            >
              <Typography style={{ fontSize: "0.6em" }}>Yes</Typography>
              <ExpandMoreIcon style={{ fontSize: "0.8em" }} />
            </div>

            <Typography
              color="textSecondary"
              style={{ fontSize: "0.5em", marginTop: "0.25em" }}
            >
              Send automatic order updates? (you can still do this manually)
            </Typography>

            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid gray",
                marginTop: "0.25em",
              }}
            >
              <Typography style={{ fontSize: "0.6em" }}>
                support@companyname.com
              </Typography>
              <ExpandMoreIcon style={{ fontSize: "0.8em" }} />
            </div>

            <Typography
              color="textSecondary"
              style={{ fontSize: "0.5em", marginTop: "0.25em" }}
            >
              The 'from' address for manual emails
            </Typography>

            <div
              style={{
                marginTop: "0.25em",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid gray",
              }}
            >
              <Typography style={{ fontSize: "0.6em" }}>
                noreply@companyname.com
              </Typography>
              <ExpandMoreIcon style={{ fontSize: "0.8em" }} />
            </div>

            <Typography
              color="textSecondary"
              style={{ fontSize: "0.5em", marginTop: "0.25em" }}
            >
              The 'from' address for automated emails
            </Typography>
          </div>
        </div>
        <div style={{ marginBottom: "3em" }}>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <PhoneIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Phone"
              primaryTypographyProps={{ style: { fontSize: "0.7em" } }}
            />
          </ListItem>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <EmailIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Email"
              primaryTypographyProps={{ style: { fontSize: "0.7em" } }}
            />
          </ListItem>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <SMSIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="SMS"
              primaryTypographyProps={{ style: { fontSize: "0.7em" } }}
            />
          </ListItem>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <PushIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Push"
              primaryTypographyProps={{ style: { fontSize: "0.7em" } }}
            />
          </ListItem>
        </div>
      </div>
    </div>
  );
};

export default CommUI;
