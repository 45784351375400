import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../utilities/constants/routes";
import HomeIcon from "@material-ui/icons/Home";

export interface ClosedNotificationProps {
  nextTimeOpen: Date | null;
}

const ClosedNotification: FC<ClosedNotificationProps> = ({ nextTimeOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      className="anim_fadeIn_0505"
      style={{
        margin: "auto",
        color: "black",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        opacity: 0,
      }}
    >
      <Typography align="justify" variant="body2">
        {t("store.cart.notOpen")}
      </Typography>
      <br />
      <Typography align="justify">
        {nextTimeOpen &&
          t("store.cart.nextOpen", {
            date: nextTimeOpen.toLocaleDateString([], {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }),
          })}
      </Typography>
      <br />
      <Button
        variant="contained"
        onClick={() => {
          history.replace(ROUTES.STORE);
        }}
        startIcon={<HomeIcon />}
      >
        {t("store.orders.returnToHome")}
      </Button>
    </div>
  );
};

export default ClosedNotification;
