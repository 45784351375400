import IconButton from "@material-ui/core/IconButton";
import { Fragment, FC, ReactElement, useState } from "react";
import DoneIcon from "@material-ui/icons/DoneTwoTone";
import WarningIcon from "@material-ui/icons/WarningTwoTone";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import {
  Order,
  TimelineEvent,
  TimelineEventType,
} from "../../../database/order";
import { format } from "date-fns";
import { notUndefined } from "../../../utilities/notNull";

interface TimelineProps {
  isPast: boolean;
  order: Order;
}

const Timeline: FC<TimelineProps> = ({ order }) => {
  const { t } = useTranslation();

  const timeline = order.timeline ?? [];

  // Orders created before the timeline feature were added are missing data
  const isOldOrder = !timeline.find(
    (event) => event.type === TimelineEventType.created
  );

  /**
   * We display at most 4 steps in the timeline, even if we have more data:
   *
   * 1) When the order was created
   * 2) When it became in progress (if relevant)
   * 3) An alert about action needed (if relevant)
   * 4) When the order was reached a "done" state, or is predicted to. If
   *    The order has reached multiple such states (ready, intransit, or
   *    delivered) then we show the last one.
   */
  const createdStep = timeline.find(
    (event) => event.type === TimelineEventType.created
  );

  let inprogressStep: TimelineEvent | undefined;
  for (const step of timeline) {
    if (step.type === TimelineEventType.inprogress) {
      // Take the last inprogress step
      inprogressStep = step;
    } else if (step.type === TimelineEventType.inqueue) {
      // If it moves back to inqueue, ignore any inprogress steps that came before
      inprogressStep = undefined;
    }
  }

  // Find an alert step which has not been resolved
  let paymentNeededStep: TimelineEvent | undefined;
  let addressNeededStep: TimelineEvent | undefined;
  let upchargeSentStep: TimelineEvent | undefined;
  for (const step of timeline) {
    if (step.type === TimelineEventType.addressNeeded) {
      addressNeededStep = step;
    } else if (step.type === TimelineEventType.addressAdded) {
      addressNeededStep = undefined;
    } else if (step.type === TimelineEventType.upchargeSentToCustomer) {
      upchargeSentStep = step;
    } else if (
      step.type === TimelineEventType.upchargeAccepted ||
      step.type === TimelineEventType.upchargeRejected ||
      step.type === TimelineEventType.upchargeAbandoned
    ) {
      upchargeSentStep = undefined;
    }
  }
  const alertStep = [paymentNeededStep, addressNeededStep, upchargeSentStep]
    .filter(notUndefined)
    .sort((a, b) => a.timestamp - b.timestamp)[0] as TimelineEvent | undefined;

  let doneStep: TimelineEvent | undefined;
  for (const step of timeline) {
    if (
      step.type === TimelineEventType.ready ||
      step.type === TimelineEventType.intransit ||
      step.type === TimelineEventType.delivered
    ) {
      // Take the last ready/intransit/delivered step
      doneStep = step;
    } else if (
      step.type === TimelineEventType.inprogress ||
      step.type === TimelineEventType.inqueue
    ) {
      // If the order gets reopened, ignore any steps that came before it
      doneStep = undefined;
    }
  }

  const [showAll, setShowAll] = useState(!inprogressStep);

  if (isOldOrder) {
    return null;
  }

  return (
    <Fragment>
      {showAll && (
        <Fragment>
          {createdStep && (
            <Step
              timestamp={createdStep.timestamp}
              label={t("store.track.ordered")}
            />
          )}
          {doneStep && inprogressStep && (
            <Step
              timestamp={inprogressStep.timestamp}
              label={t("store.track.inprogress")}
            />
          )}
        </Fragment>
      )}
      {alertStep && (
        <Step
          timestamp={alertStep.timestamp}
          label={
            alertStep.type === TimelineEventType.addressNeeded
              ? t("store.track.missingDeliveryOptions")
              : t("store.track.awaitingAuthorization")
          }
          icon={<WarningIcon />}
          textColor="secondary"
          iconColor="secondary"
        />
      )}
      {!doneStep && inprogressStep && (
        <Step
          timestamp={inprogressStep.timestamp}
          label={t("store.track.inprogress")}
        />
      )}
      {!inprogressStep && (
        <Step
          timestamp={null}
          label={t("store.track.inprogress")}
          isEstimate
          textColor="textSecondary"
          iconColor="default"
        />
      )}
      {doneStep ? (
        <Step
          timestamp={doneStep.timestamp}
          label={
            doneStep.type === TimelineEventType.delivered
              ? t("store.track.delivered")
              : doneStep.type === TimelineEventType.intransit
              ? t("store.track.intransit")
              : order.delivery.delivery
              ? t("store.track.readyForTransit")
              : t("store.track.readyForPickup")
          }
        />
      ) : (
        <Step
          timestamp={order.delivery.timePlanned.toMillis()}
          label={
            order.delivery.delivery
              ? t("store.track.readyForTransit")
              : t("store.track.readyForPickup")
          }
          isEstimate
          textColor="textSecondary"
          iconColor="default"
        />
      )}
      <Button
        variant="outlined"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setShowAll(!showAll);
        }}
      >
        {showAll ? t("store.orders.hidePast") : t("store.orders.showAll")}
      </Button>
    </Fragment>
  );
};

interface StepProps {
  timestamp: number | null;
  label: string;
  iconColor?: "primary" | "secondary" | "default";
  textColor?: "primary" | "secondary" | "textSecondary";
  icon?: ReactElement;
  isEstimate?: boolean;
}

const Step = ({
  timestamp,
  label,
  iconColor = "primary",
  textColor = "primary",
  icon = <DoneIcon />,
  isEstimate = false,
}: StepProps) => {
  const { t } = useTranslation();
  let timeString;
  if (!timestamp) {
    timeString = t("store.track.pending");
  } else if (isEstimate) {
    timeString = `${t("store.track.eta")} ${format(
      new Date(timestamp),
      "PPp"
    )}`;
  } else {
    timeString = format(new Date(timestamp), "PPp");
  }
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          color={iconColor}
          disabled={isEstimate}
          style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
        >
          {icon}
        </IconButton>
        &nbsp;&nbsp;
        <div>
          <Typography>{timeString}</Typography>
          <Typography color={textColor} variant="body2">
            {label}
          </Typography>
        </div>
      </div>
      <div
        style={{
          borderLeft: "2px solid rgba(0,0,0,0.12)",
          height: "2em",
          marginLeft: "1.5em",
        }}
      />
    </Fragment>
  );
};

export default Timeline;
