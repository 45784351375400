import { MenuItem, ListItemIcon, Icon, ListItemText } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { WellKnownPageId } from "../../../database/siteSettings";
import ROUTES from "../../../utilities/constants/routes";
import { history } from "../../App/history";

interface MyAccountMenuProps {}

const MyAccountMenu: FC<MyAccountMenuProps> = (props) => {
  const { t } = useTranslation();
  const { navigationMenu } = useSiteSettings();
  const location = useLocation<{}>();

  const accountItem = navigationMenu.items.find((navigationItem) => {
    return navigationItem.pageId === WellKnownPageId.account;
  });
  const favItem = navigationMenu.items.find((navigationItem) => {
    return navigationItem.pageId === WellKnownPageId.favorites;
  });
  const trackItem = navigationMenu.items.find((navigationItem) => {
    return navigationItem.pageId === WellKnownPageId.track;
  });

  return (
    <div style={{ padding: "1em 0" }}>
      <MenuItem
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(ROUTES.TRACK);
        }}
      >
        <ListItemIcon>
          <Icon color="primary">{trackItem?.iconSettings.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={trackItem?.textSettings.text} />
      </MenuItem>
      <MenuItem
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(ROUTES.FAVORITES);
        }}
      >
        <ListItemIcon>
          <Icon color="primary">{favItem?.iconSettings.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={favItem?.textSettings.text} />
      </MenuItem>
      <MenuItem
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(ROUTES.ACCOUNT);
        }}
      >
        <ListItemIcon>
          <Icon color="primary">{accountItem?.iconSettings.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={accountItem?.textSettings.text} />
      </MenuItem>
      <MenuItem
        style={{ justifyContent: "center" }}
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(location.pathname, {
            ...location.state,
            signOut: true,
          });
        }}
      >
        <ListItemIcon>
          <Icon color="primary">logout</Icon>
        </ListItemIcon>
        <ListItemText primary={t("store.account.signOut2")} />
      </MenuItem>
    </div>
  );
};

export default MyAccountMenu;
