import { createContext } from "react";
import { PrivateSite } from "../../../database/site";
import { useContextThrowUndefined } from "../../../utilities/useContextThrowUndefined";

export const PrivateSiteContext = createContext<PrivateSite | undefined>(
  undefined
);

PrivateSiteContext.displayName = "Private Site";

/**
 * PrivateSite configuration. Found in the gridbash database at
 * /privateSites/{siteId}
 */
export const usePrivateSite = () =>
  useContextThrowUndefined(PrivateSiteContext);
