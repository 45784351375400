import { useState, useEffect, useCallback } from "react";

type Initializer<T> = T extends any ? T | (() => T) : never;

export interface StorageOptions<T> {
  /**
   * Where to store the value in storage. This key must be unique across the app
   * and must be the same each time the app runs.
   */
  key: string;
  /**
   * What value to use if nothing is found in storage.
   *
   * If the default value is expensive to calculate, you can pass in a function
   * instead of a value, and that function will be called at most one time
   */
  defaultValue: Initializer<T>;
  /**
   * If true, the initial render will remove the value from storage
   * and use the default value. This is useful if you sometimes want
   * to load from storage and sometimes don't. If you never want to
   * load from storage, just use useState.
   *  */
  reset?: boolean;
  /**
   * Custom function for turning data into a string for storage.
   * If omitted, the default will use JSON.stringify
   */
  serialize?: (value: T) => string;
  /**
   * Custom function for turning the string from storage back into data
   * If omitted, the default will use JSON.parse
   */
  deserialize?: (str: string) => T;
  /**
   * Checks the deserialized data to see if it's in the correct format. This
   * can be used to guard against changes in data format between code versions.
   * If the return value is false, then the default value will be used instead
   * of the deserialized value.
   */
  validate?: (value: any) => value is T;
}

const noOpStorage: Pick<Storage, "getItem" | "setItem" | "removeItem"> = {
  setItem: () => {},
  getItem: () => null,
  removeItem: () => {},
};

/**
 * Default functions for serializing/deserializing. Works in many cases, but
 * sometimes custom functions must be used instead
 */
export const defaultSerializer = (value: any) => JSON.stringify(value);
export const defaultDeserializer = (str: string) => JSON.parse(str);

/**
 * Functions for serializing/deserializing date objects
 */
export const serializeDate = (value: Date) => value.toJSON();
export const deserializeDate = (str: string) => new Date(str);

/**
 * Similar to useState, but the value is also mirrored to session storage.
 * On initial load, if the value is in session storage that value will be used.
 * Thereafter, any change will be written out to session storage.
 */
export const useStateWithSessionStorage = <T>(options: StorageOptions<T>) => {
  let storage = noOpStorage;
  try {
    storage = sessionStorage;
  } catch {
    // This can occur if the user has disabled session storage
  }
  return useStateWithGenericStorage(options, storage);
};

/**
 * Similar to useState, but the value is also mirrored to local storage.
 * On initial load, if the value is in local storage that value will be used.
 * Thereafter, any change will be written out to local storage.
 */
export const useStateWithLocalStorage = <T>(options: StorageOptions<T>) => {
  let storage = noOpStorage;
  try {
    storage = localStorage;
  } catch {
    // This can occur if the user has disabled local storage
  }
  return useStateWithGenericStorage(options, storage);
};

const useStateWithGenericStorage = <T>(
  options: StorageOptions<T>,
  storageInterface: Pick<Storage, "getItem" | "setItem" | "removeItem">
) => {
  const {
    key,
    defaultValue,
    reset = false,
    serialize = defaultSerializer,
    deserialize = defaultDeserializer,
    validate = () => true,
  } = options;

  const remove = useCallback(() => {
    storageInterface.removeItem(key);
  }, [key, storageInterface]);

  const extractDefaultValue = () => {
    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  };

  const [value, setValue] = useState<T>(() => {
    if (reset) {
      remove();
      return extractDefaultValue();
    }

    try {
      const fromStorage = storageInterface.getItem(key);
      if (fromStorage) {
        const value = deserialize(fromStorage);
        if (validate(value)) {
          return value;
        }
      }
      return extractDefaultValue();
    } catch {
      return extractDefaultValue();
    }
  });

  useEffect(() => {
    try {
      storageInterface.setItem(key, serialize(value));
    } catch (err) {}
  }, [key, serialize, storageInterface, value]);

  return [value, setValue, remove] as const;
};
