import {
  Typography,
  Snackbar,
  SnackbarOrigin,
  IconButton,
} from "@material-ui/core";
import { createContext, FC, ReactElement, useCallback, useRef, useState } from "react";
import { useContextThrowUndefined } from "../../utilities/useContextThrowUndefined";
import { history } from "../App/history";
import MUIDialog from "../Dashboard/Components/mui-dialog";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import RenderBlocker from "./renderBlocker";
import { History } from "history";

const useStyles = makeStyles((theme) => ({
  normal: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

interface BaseToastOptions {
  /** What message to display */
  message: string | ReactElement;
  /**
   * Color indicating the severity of the message. Defaults to "normal"
   */
  color?: "error" | "warning" | "normal";
  /**
   * If provided, the toast will automatically dismiss after this many milliseconds.
   * For interrupting toasts, the default is that it never dismisses.
   * For non-interrupting toasts, the default is 5 seconds
   */
  duration?: number;
  /**
   * Called when the toast unmounts
   */
  onUnmount?: () => void;
}

/**
 * A non-interrupting version of the toast
 */
interface SnackbarToastOptions extends BaseToastOptions {
  /**
   * Where to position the snackbar. Defaults to top center.
   */
  anchorOrigin?: SnackbarOrigin;
  dialog?: false;
}

/**
 * An interrupting version of the toast. A dialog is shown, and the user must
 * click or hit back to get rid of it
 */
interface DialogToastOptions extends BaseToastOptions {
  dialog: true;
  anchorOrigin?: never;
  align?: "left" | "center" | "right";
  width?: "small" | "medium" | "large";
}

export type ToastOptions = SnackbarToastOptions | DialogToastOptions;

export const ToastContext = createContext<
  ((options: ToastOptions) => void) | undefined
>(undefined);

ToastContext.displayName = "Toast";

export const ToastProvider: FC = ({ children }) => {
  const [toastProps, setToastProps] = useState<ToastOptions>();
  const toastTimerId = useRef(-1);

  const showToast = useCallback((options: ToastOptions) => {
    setToastProps(options);
    clearTimeout(toastTimerId.current);

    if (options.dialog) {
      const { location } = history as History<{ toast?: boolean } | undefined>;
      history.push(location.pathname, {
        ...location.state,
        toast: true,
      });

      if (options.duration !== undefined && options.duration > 0) {
        toastTimerId.current = window.setTimeout(() => {
          if (location.state?.toast) {
            history.goBack();
          }
        }, options.duration);
      }
    }
    // Else, no action needed. The snackbar will do its own autodismiss
  }, []);

  const classes = useStyles();

  return (
    <ToastContext.Provider value={showToast}>
      {toastProps && toastProps.dialog && (
        <MUIDialog
          PaperProps={{
            className:
              toastProps.color !== "error" ? classes.normal : classes.error,
          }}
          route={""}
          stateMatch={(state) => Boolean(state.toast)}
          onUnmount={toastProps.onUnmount}
        >
          <div
            className={
              toastProps.width === "small"
                ? "smartWidthSm"
                : toastProps.width === "large"
                ? undefined
                : "smartWidthMd"
            }
            style={{
              opacity: 1,
              padding: "1.5vw 2vw",
              height: "auto",
              maxHeight: "none",
              color: "white",
              borderRadius: "5px",
            }}
          >
            {typeof toastProps.message === "string" ? (
              <Typography align={toastProps.align || "left"} component="div">
                {toastProps.message}
              </Typography>
            ) : (
              toastProps.message
            )}
          </div>
        </MUIDialog>
      )}
      {toastProps && !toastProps.dialog && (
        <Snackbar
          anchorOrigin={
            toastProps.anchorOrigin ?? {
              vertical: "top",
              horizontal: "center",
            }
          }
          open={true}
          ContentProps={{
            classes: {
              root:
                toastProps.color !== "error" ? classes.normal : classes.error,
            },
          }}
          onClose={() => {
            toastProps.onUnmount?.();
            setToastProps(undefined);
          }}
          message={toastProps.message}
          autoHideDuration={toastProps.duration ?? 5000}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={() => {
                toastProps.onUnmount?.();
                setToastProps(undefined);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      )}
      <RenderBlocker>{children}</RenderBlocker>
    </ToastContext.Provider>
  );
};

/**
 * Shows a toast message to the user.
 *
 * If a route is provided, then we will push to browser history
 * and show a dialog. This interrupts the user and requires them
 * to click or hit back to proceed.
 *
 * If no route is provided, then a snackbar is shown. This does
 * not interrupt the user
 *
 * Note: Only one toast can be shown at a time, so this should
 * not be used for popovers which can launch other popovers.
 *
 * ```typescript
 * const toast = useToast();
 * const anchor = useRef<HTMLDivElement>(null);
 *
 * return (
 *   <button ref={anchor} onClick={() => {
 *     toast({
 *       route: `${ROUTES.CHECKOUT}/toast`,
 *       message: "Hello World",
 *     })
 *   }}>
 *     Click Me
 *   </button>
 * )
 * ```
 */
const useToast = () => useContextThrowUndefined(ToastContext);

export default useToast;
