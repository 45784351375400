import { SiteSettings } from "../../database/siteSettings";

class Routes {
  /**
   * rootPath changes based on whether we're on on an individually hosted site, or
   * one on gridbash. For individually hosted sites, it is "". For a site hosted at
   * gridbash.com/project6, rootPath changes to "project6"
   */
  rootPath = "";
  /**
   * Store is similar to rootPath, but it may end in a slash.
   *
   * This value is useful if you want to compare against the path name, and can
   * also be used for routing:
   *
   * ```typescript
   * // All these are good uses
   * const onHomePage = location.pathname === ROUTES.STORE;
   *
   * history.push(ROUTES.STORE);
   *
   * <Route path={ROUTES.STORE} />
   * ```
   *
   * But don't use this value to build up other strings, or you might get a double
   * slash in the path. Ie, do this:
   *
   * ```typescript
   * const path = `${ROUTES.rootPath}/toast`;
   * ```
   *
   * not this:
   *
   * ```typescript
   * const path = `${ROUTES.store}/toast // On individual sites, this becomes "//toast";
   * ```
   */
  get STORE() {
    return this.rootPath === "" ? "/" : this.rootPath;
  }
  get ABOUT() {
    return `${this.rootPath}/about`;
  }
  get ACCOUNT() {
    return `${this.rootPath}/account`;
  }
  get CART() {
    return `${this.rootPath}/cart`;
  }
  get CHECKOUT() {
    return `${this.rootPath}/checkout`;
  }
  get CONTACT() {
    return `${this.rootPath}/contact`;
  }
  get SEARCH() {
    return `${this.rootPath}/search`;
  }
  // Many login cases are handled with a popover, and those do *not* use
  //   this url. This url is specifically for external deeplinks
  get LOGIN_DEEPLINK() {
    return `${this.rootPath}/l`;
  }
  get MESSAGE_DEEPLINK() {
    return `${this.rootPath}/messages`;
  }
  get DASHBOARD() {
    return `/dashboard`;
  }
  get DASHBOARD_SUBSCRIBE() {
    return "/dashboard/subscribe";
  }
  get DASHBOARD_OVERVIEW() {
    return `/dashboard/overview`;
  }
  get DASHBOARD_ORDERS() {
    return `/dashboard/orders`;
  }
  get DASHBOARD_CATALOG() {
    return `/dashboard/catalogs`;
  }
  get DASHBOARD_MESSAGES() {
    return `/dashboard/messages`;
  }
  get DASHBOARD_PROMOTIONS() {
    return `/dashboard/promotions`;
  }
  get DASHBOARD_BUILDER() {
    return `/dashboard/builder`;
  }
  get DASHBOARD_GRID() {
    return `/dashboard/grid`;
  }
  get DASHBOARD_GRID_PAGE_EDITOR() {
    return `${this.DASHBOARD_GRID}/pages/edit/:pageId/(section)?/:sectionId?/(widget)?/:widetId?`;
  }
  get DASHBOARD_SALES() {
    return `/dashboard/sales`;
  }
  get DASHBOARD_SETTINGS() {
    return `/dashboard/settings`;
  }
  get DASHBOARD_ACCOUNT() {
    return `/dashboard/account`;
  }
  get DASHBOARD_TRACK() {
    return `/dashboard/track`;
  }
  get FAVORITES() {
    return `${this.rootPath}/favorites`;
  }
  get FAVORITES_PRODUCT() {
    return `${this.rootPath}/favorites/product`;
  }
  get GUEST() {
    return `${this.rootPath}/guest`;
  }
  get HOME() {
    return `${this.rootPath}/home`;
  }
  get ORDER() {
    return `${this.rootPath}/order`;
  }
  get ORDER_PRODUCT() {
    return `${this.rootPath}/order/product`;
  }
  get PAYMENTAUTH() {
    return `${this.rootPath}/paymentauth`;
  }
  get PRODUCT() {
    return `${this.rootPath}/product`;
  }
  get PROMOTIONS() {
    return `${this.rootPath}/promotions`;
  }
  get REGISTER() {
    return `${this.rootPath}/register`;
  }
  get RESET() {
    return `${this.rootPath}/reset`;
  }
  get SUPER_USER() {
    return `${this.rootPath}/superuser`;
  }
  get TRACK() {
    return `${this.rootPath}/track`;
  }
  get UNSUBSCRIBE() {
    return `${this.rootPath}/unsubscribe`;
  }

  //MODAL ROUTES
  ML_CONTACT = "/message";
  ML_SIGNIN = "/signin";
  ML_MYACCOUNT = "/my-account";
  ML_DRAWER = "/drawer";
  ML_TOAST = "/toast";
}

/**
 * Analyzes the extraPages property to make sure the routes
 * it contains don't conflict with routes used by the app
 */
export const ensureUniqueRoutes = (
  siteSettings: SiteSettings
): SiteSettings => {
  // TODO: This code doesn't have a direct connection to the routes above. It would
  //   be nice if we could extract this set automatically, so that changing the
  //   routes object automatically updates this code
  const knownRoutes = new Set([
    ROUTES.ORDER,
    ROUTES.TRACK,
    ROUTES.FAVORITES,
    ROUTES.CART,
    ROUTES.CHECKOUT,
    ROUTES.PAYMENTAUTH,
    ROUTES.ACCOUNT,
    ROUTES.REGISTER,
    ROUTES.RESET,
    ROUTES.DASHBOARD,
    ROUTES.LOGIN_DEEPLINK,
    ROUTES.MESSAGE_DEEPLINK,
    ROUTES.UNSUBSCRIBE,
    // Global popovers:
    `${ROUTES.rootPath}/${ROUTES.ML_SIGNIN}`,
    `${ROUTES.rootPath}/${ROUTES.ML_CONTACT}`,
    `${ROUTES.rootPath}/${ROUTES.ML_DRAWER}`,
    `${ROUTES.rootPath}/${ROUTES.ML_TOAST}`,
  ]);

  return {
    ...siteSettings,
    extraPageOptions: {
      ...siteSettings.extraPageOptions,
      pages: siteSettings.extraPageOptions.pages.map((extraPage) => {
        let path = `${ROUTES.rootPath}${extraPage.path}`;
        let suffix = 0;
        while (knownRoutes.has(path)) {
          suffix++;
          path = `${ROUTES.rootPath}${extraPage.path}${suffix}`;
        }
        knownRoutes.add(path);
        return {
          ...extraPage,
          path,
        };
      }),
    },
  };
};

const ROUTES = new Routes();
export default ROUTES;
