/**
 * Columns with a percent width can't be smaller than this
 */
export const minColumnPercent = 5;
/**
 * Columns with a px width can't be smaller than this
 */
export const minColumnPx = 20;

export const dragColor = "rgba(0, 0, 0, 0.2)";
export const highlightColor = "rgba(50, 255, 50, 0.3)";
export const focusColor = "rgba(26, 127, 255, 0.2)";
export const hoverColor = "rgba(120, 180, 255, 0.2)";
