import { FormControl, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { FC, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../../utilities/validateEmailPassPhone";
import { history } from "../../App/history";
import useSpinner from "../../Spinner/useSpinner";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { useSiteFirebase } from "../../../Firebase/context";
import useToast from "../../Main/useToast";

interface ResetProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Reset: FC<ResetProps> = ({ setMainBgImage }) => {
  const { t } = useTranslation();
  const firebase = useSiteFirebase();
  const showSpinner = useSpinner();
  const toast = useToast();

  const [authEmail, setAuthEmail] = useState("");
  const emailIsValid = validateEmail(authEmail);
  const [emailSent, setEmailSent] = useState(false);

  const anchor1 = useRef<HTMLDivElement | null>(null);

  async function resetPassword(email: string) {
    console.log("Attempting Password Reset...");
    const hideSpinner = showSpinner();

    try {
      await firebase.resetPassword(email);
      setEmailSent(true);
      toast({
        color: "normal",
        message: t("store.account.emailSent"),
      });
    } catch (error) {
      toast({
        color: "error",
        message: error.message,
        dialog: true,
      });
    } finally {
      hideSpinner();
    }
  }
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!emailIsValid) {
      return;
    } else {
      resetPassword(authEmail);
    }
  };

  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container ref={anchor1} maxWidth="sm">
      <DialogTitle style={{ textAlign: "left", paddingBottom: 0 }}>
        {t("store.account.sendResetLink")}
      </DialogTitle>
      <Typography align="left" variant="subtitle2">
        {t("store.account.checkSpam")}
      </Typography>

      <DialogContent>
        <form noValidate>
          <FormControl fullWidth>
            <TextField
              id="username"
              autoComplete="username"
              label={t("store.account.enterEmail")}
              type="email"
              fullWidth
              value={authEmail.toLowerCase()}
              onChange={(e) => setAuthEmail(e.currentTarget.value.trim())}
              error={authEmail.length > 0 && !emailIsValid}
              helperText={
                authEmail.length > 0 && !emailIsValid
                  ? t("store.account.badEmail")
                  : undefined
              }
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions style={{ paddingRight: "1em" }}>
        {!emailSent ? (
          <React.Fragment>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              {t("store.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {t("store.submit")}
            </Button>
          </React.Fragment>
        ) : (
          <Button startIcon={<ExitIcon />} onClick={() => history.goBack()}>
            {t("store.account.exit")}
          </Button>
        )}
      </DialogActions>
    </Container>
  );
};

export default Reset;
