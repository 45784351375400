import { createContext } from "react";
import {
  CustomWidgetTemplate,
  PageAggregation,
  PageConfig,
} from "../../../../../database/page";
import { SiteSettings } from "../../../../../database/siteSettings";
import { useContextThrowUndefined } from "../../../../../utilities/useContextThrowUndefined";
import { PageAction } from "./actions";
import { PageState } from "./reducer";
import { DispatchOptions } from "./useEditPages";

export interface PageApiValue {
  /**
   * Entire state object
   */
  pageState: PageState;
  /**
   * Summary information about the pages, plus the navigation section
   */
  pageSummaries: PageAggregation;
  /**
   * The page that is currently being edited
   */
  page: PageConfig | undefined;
  /**
   * The page that's currently being edited, as it exists in the database
   */
  originalPage: PageConfig | undefined;
  /**
   * True if we are currently loading the selected page
   */
  loadingPage: boolean;
  /**
   * All the pages we have in memory. This may not be all the pages that exist
   */
  pages: {
    [pageId in string]?: PageConfig;
  };
  /**
   * Settings that apply to all pages
   */
  pageSettings: SiteSettings["pageSettings"];
  /**
   * Custom widgets the admin has created. For example, the Order Item.
   */
  customWidgets: {
    [templateId: string]: CustomWidgetTemplate;
  };
  /**
   * Change which page is being edited
   */
  selectPage: (pageId: string) => void;
  /**
   * Undo an edit on the selected page
   */
  undo: () => void;
  /**
   * Redo an edit on the selected page
   */
  redo: () => void;
  /**
   * Set the undo index to a specific index
   */
  setUndoIndex: (index: number) => void;
  /**
   * How many changes are in the undo history
   */
  undoLength: number;
  /**
   * What index is currently selected in the undo history. Value may be -1.
   */
  undoIndex: number;
  /**
   * Whether there are unpublished changes
   */
  dirty: boolean;
  /**
   * Publish the changes
   */
  publish: () => void;
  /**
   * Revert the changes
   */
  revert: () => void;
  /**
   * Current states regarding saving to the user's part of the database,
   * so they can resume their work later.
   */
  backupStatus: null | "saving" | "saved" | "restored";
}

export const PageApiContext = createContext<PageApiValue | undefined>(
  undefined
);

PageApiContext.displayName = "Page editor";

/**
 * Contains the versions of pages which are being edited, and the functions
 * for doing those edits.
 */
export const usePageApi = () => useContextThrowUndefined(PageApiContext);

// This is split off from the main context to improve performance. Serveral componenets
//   only need the dispatch value, so by putting it in its own context, those components
//   can skip rerenders caused by other values changing.
export interface PageDispatchValue {
  /**
   * Dispatch an action to edit the page. See actions.ts for legal actions, and
   * reducer.ts for the implementation that updates the state
   *
   * @param action: the action to add to the history
   * @param options: options to modify how the action gets added to the history.
   */
  dispatch: (action: PageAction, options?: DispatchOptions) => void;
  /**
   * Looks for a PlaceholderAction in the history, and replaces it with a real action
   */
  replaceAction: (placeholderActionId: string, action: PageAction) => void;
}
export const PageDispatchContext = createContext<PageDispatchValue | undefined>(
  undefined
);

PageApiContext.displayName = "Page dispatch";

/**
 * Contains the versions of pages which are being edited, and the functions
 * for doing those edits.
 */
export const usePageDispatch = () =>
  useContextThrowUndefined(PageDispatchContext);
