import { FC, useContext, useEffect } from "react";
import * as React from "react";
import ROUTES from "../../../utilities/constants/routes";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { Button, Icon, Typography } from "@material-ui/core";
import { history } from "../../App/history";
import { createOrderItem } from "../../../utilities/defaultMenuItems";
import { WellKnownPageId } from "../../../database/siteSettings";
import { BuilderContext } from "../../Dashboard/Builder/OrdersBuilder/builderContext";

interface HomeProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
  headerHeight?: number;
}

const Home: FC<HomeProps> = React.memo(({ setMainBgImage, headerHeight }) => {
  const siteSettings = useSiteSettings();
  const { theme, homePageOptions, navigationMenu } = siteSettings;

  const builder = useContext(BuilderContext);

  const handleNav = (route: string) => {
    history.push(route, "fromHome");
  };
  const orderMenuItem =
    navigationMenu.items.find(
      (item) => item.pageId === WellKnownPageId.order
    ) ?? createOrderItem();

  useEffect(() => {
    setMainBgImage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {theme.sloganCenter && (
        <Typography
          className="anim_fadeIn_0510"
          style={{
            position: "absolute",
            paddingTop: headerHeight,
            bottom: "50%",
            transform: "translate(0, 50%)",
            opacity: 0,
            textShadow: "0 1px 1px #111",
            color: "white",
            fontWeight: "bold",
            fontFamily: theme.sloganCenter.fontFamily,
            fontSize: theme.sloganCenter.fontSize
              ? `${theme.sloganCenter.fontSize}rem`
              : "2.2em",
            width: "100%",
            zIndex: -1, //NEEDED FOR PREVENTING OVERLAYING MOCK DRAWER IN BUILDER
          }}
        >
          {theme.sloganCenter.text}
        </Typography>
      )}
      <div
        className="anim_fadeIn_0505"
        style={{
          opacity: 0,
          position: "absolute",
          bottom: "6%",
          transform: "translate(0, 6%)",
          width: "100%",
        }}
      >
        <Typography
          style={{
            textShadow: "0 1px 1px #111",
            userSelect: "none",
            fontWeight: "bold",
            color: "white",
            fontSize: theme.slogan.fontSize
              ? `${theme.slogan.fontSize}rem`
              : "1.5em",
            fontFamily:
              typeof theme.slogan === "string"
                ? undefined
                : theme.slogan.fontFamily,
          }}
        >
          {typeof theme.slogan === "string" ? theme.slogan : theme.slogan.text}
        </Typography>
        {homePageOptions &&
          homePageOptions.mainButton &&
          homePageOptions.mainButton.text && (
            <div style={{ marginTop: "2vh" }}>
              <Button
                startIcon={<Icon>{orderMenuItem.iconSettings.icon}</Icon>}
                color="primary"
                variant="contained"
                onClick={() => {
                  if (builder) {
                    return;
                  }
                  handleNav(ROUTES.ORDER);
                }}
                //homePageOptions.mainButton.fontSize
                style={{
                  fontFamily:
                    homePageOptions.mainButton &&
                    homePageOptions.mainButton.fontFamily
                      ? homePageOptions.mainButton.fontFamily
                      : undefined,
                  fontSize:
                    homePageOptions.mainButton &&
                    homePageOptions.mainButton.fontSize
                      ? `${homePageOptions.mainButton.fontSize}rem`
                      : undefined,
                }}
              >
                {homePageOptions.mainButton.text}
              </Button>
            </div>
          )}
      </div>
    </React.Fragment>
  );
});

export default Home;
