import WebFont from "webfontloader";
import { WEBFONTS } from "./constants/appConstants";

let promises: Record<string, Promise<void> | undefined> = {};

/**
 * Downloads the specified font or fonts. This function can safely be
 * called multiple times, and will only kick off one load per font
 */
export const loadFonts = (fontParam: string | string[]) => {
  let fonts = Array.isArray(fontParam) ? fontParam : [fontParam];

  let existingPromises: Promise<void>[] = [];
  fonts = fonts
    // Only load google fonts
    .filter((font) => !WEBFONTS.includes(font))
    .filter((font) => {
      const promise = promises[font];
      if (promise) {
        existingPromises.push(promise);
        return false;
      } else {
        return true;
      }
    });

  if (fonts.length > 0) {
    const promise = new Promise<void>((resolve) => {
      WebFont.load({
        google: {
          families: fonts,
        },
        active: resolve,
        inactive: () => {
          console.warn("Unable to load font(s)", ...fonts);
          resolve();
        },
      });
    });
    fonts.forEach((font) => {
      promises[font] = promise;
    });
    return Promise.all([promise, ...existingPromises]);
  } else {
    return Promise.all(existingPromises);
  }
};
