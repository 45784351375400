import {
  SendXenditChargePayload,
  StorePendingOrderPayload,
  AddMessageThreadToOrderPayload,
  GetStripePaymentIntentPayload,
  PaymentAuthUtilsPayload,
  CreateFirestoreBackupPayload,
  RateRequestPayload,
  DhlRequestPayload,
  DhlResponse,
  ShippingRequestPayload,
  DeclineRechargePayload,
  RechargeResponse,
  AddPaymentToOrderPayload,
  StripePaymentIntent,
  LockOrderPayload,
  SetDeliveryOptionsPayload,
  ManualNotificationPayload,
  CreateCheckoutSessionPayload,
  CreatePaypalTransactionPayload,
  CapturePaypalTransactionPayload,
  RechargePayload,
  StripeAccountPayload,
  CreateGridbashCheckoutSession,
  TwilioPhoneNumberPayload,
  UnsubscribeOrderEmailsPayload,
  PhoneIsBlockedPayload,
  UpdateSubscriptionPayload,
  UpdateCustomerPayload,
  UpdatePaymentMethodPayload,
} from "./types";
import { DhlRateResponse } from "../../dhl/rateRequest";
import { DhlShipmentResponse } from "../../dhl/shipmentRequest";
import { Order } from "../../database/order";
import { SiteFirebase } from "../../Firebase/siteFirebase";
import { GridbashFirebase } from "../../Firebase/gridBashFirebase";
import Stripe from "stripe";

/**
 * This file adds some typesafety around using firebase's httpsCallable.
 * These helper methods define strict types about what can be sent, and
 * this in turn allows the function code to assume that that is what it
 * received.
 */

export const sendXenditCharge = (
  firebase: SiteFirebase,
  data: SendXenditChargePayload
) => {
  return firebase.functions.httpsCallable("sendXenditCharge")(data);
};

export const getStripePaymentIntent = async (
  firebase: SiteFirebase,
  data: GetStripePaymentIntentPayload
): Promise<StripePaymentIntent> => {
  const response = await firebase.functions.httpsCallable(
    "getStripePaymentIntent"
  )(data);
  return {
    amount: data.amount,
    clientSecret: response.data.clientSecret,
    id: response.data.id,
  };
};

export async function paymentAuthUtils(
  firebase: SiteFirebase,
  payload: PaymentAuthUtilsPayload & { action: "retrieve" }
): Promise<{ order: Order }>;
export async function paymentAuthUtils(
  firebase: SiteFirebase,
  payload: PaymentAuthUtilsPayload
) {
  const response = await firebase.functions.httpsCallable("paymentAuthUtils")(
    payload
  );
  return response.data;
}

/**
 * Adds an order to the database. Used when an admin creates an order.
 * For customer orders, use createCheckoutSession
 * @param firebase
 * @param data
 */
export const storePendingOrder = (
  firebase: SiteFirebase,
  data: StorePendingOrderPayload
) => {
  return firebase.functions.httpsCallable("storePendingOrder")(data);
};

/**
 * Adds a payment to the latest revision of an order. Used when paying for an upcharge with
 * a brand new payment.
 */
export const addPaymentToOrder = (
  firebase: SiteFirebase,
  data: AddPaymentToOrderPayload
) => {
  return firebase.functions.httpsCallable("addPaymentToOrder")(data);
};

/**
 * Locks an order during the customer's payment so that the admin cannot make changes which
 * would change the price until the payment is complete.
 *
 * Note: addPaymentToOrder does locking automatically, so this is only needed if the order
 * already has a payment (Ie, for upcharge confirmation)
 */
export function lockOrder(firebase: SiteFirebase, payload: LockOrderPayload) {
  return firebase.functions.httpsCallable("lockOrder")(payload);
}

/**
 * Manually send an email related to an order. Normally these are sent automatically
 * by the watchOrders firebase function
 */
export const manualNotification = (
  firebase: SiteFirebase,
  data: ManualNotificationPayload
) => {
  return firebase.functions.httpsCallable("manualNotification")(data);
};

/**
 * Processes an upcharge or refund on an order.
 *
 * Note: this only works if the order has already been modified to have a revision
 * with an upcharge or refund.
 */
export const recharge = async (
  firebase: SiteFirebase,
  payload: RechargePayload
): Promise<RechargeResponse> => {
  const result = await firebase.functions.httpsCallable("recharge")(payload);

  return result.data;
};

/**
 * For the most part, sending messages does not require contacting the server.
 * But updating an order is privileged, so the act of connecting a message
 * thread to an order needs to be done server side to prevent other tampering
 * with the order.
 */
export const addMessageThreadToOrder = (
  firebase: SiteFirebase,
  data: AddMessageThreadToOrderPayload
) => {
  return firebase.functions.httpsCallable("addMessageThreadToOrder")(data);
};

/**
 * Updates an order to add delivery options. This will fail if the
 * order already has delivery options.
 */
export const setDeliveryOptions = (
  firebase: SiteFirebase,
  data: SetDeliveryOptionsPayload
) => {
  return firebase.functions.httpsCallable("setDeliveryOptions")(data);
};

export const createFirestoreBackup = (
  firebase: SiteFirebase,
  data: CreateFirestoreBackupPayload
) => {
  return firebase.functions.httpsCallable("createFirestoreBackup")(data);
};

export function callDhl(
  firebase: SiteFirebase,
  payload: ShippingRequestPayload
): Promise<{ data: DhlShipmentResponse }>;
export function callDhl(
  firebase: SiteFirebase,
  payload: RateRequestPayload
): Promise<{ data: DhlRateResponse }>;
export function callDhl(
  firebase: SiteFirebase,
  payload: DhlRequestPayload
): Promise<{ data: DhlResponse }> {
  return firebase.functions.httpsCallable("callDhl")(payload);
}

export function declineRecharge(
  firebase: SiteFirebase,
  payload: DeclineRechargePayload
) {
  return firebase.functions.httpsCallable("declineRecharge")(payload);
}

/**
 * creates a stripe checkout session and returns the id.
 *
 * @param payload pass in an OrderRequest to create a new order, or pass in
 * an object with just an orderId to do an upcharge on an existing order
 */
export async function createCheckoutSession(
  firebase: SiteFirebase,
  payload: CreateCheckoutSessionPayload
) {
  const result = await firebase.functions.httpsCallable(
    "createCheckoutSession"
  )(payload);
  return result.data as { id: string };
}

/**
 * Queries whether an order with a given id exists. Orders in the pending
 * state will return false as though they didn't exist.
 *
 * This is necessary because customers do not have direct access to query
 * orders, yet the client side code needs to know whether stripe checkout
 * has finished.
 */
export async function orderExists(
  firebase: SiteFirebase,
  payload: { orderId: string }
): Promise<boolean> {
  const result = await firebase.functions.httpsCallable("orderExists")(payload);
  return result.data as boolean;
}

/**
 * Starts a purchase, using paypal.
 *
 * @returns Promise which resolves to the paypal orderId. This paypal order id
 * can then be used by PayPalButtons to open the payment popover.
 *
 * Note: we use the term "orderId" elsewhere in the code for the id of a
 * document in our "orders" collection. The paypal order id is unrelated.
 */
export async function createPaypalTransaction(
  firebase: SiteFirebase,
  payload: CreatePaypalTransactionPayload
): Promise<string> {
  const result = await firebase.functions.httpsCallable(
    "createPaypalTransaction"
  )(payload);
  return result.data.paypalOrderId as string;
}

/**
 * Finishes a paypal purchase.
 */
export function capturePaypalTransaction(
  firebase: SiteFirebase,
  payload: CapturePaypalTransactionPayload
) {
  return firebase.functions.httpsCallable("capturePaypalTransaction")(payload);
}

/**
 * Unlocks an order in the lockedForPayment state
 */
export function unlockOrder(
  firebase: SiteFirebase,
  payload: { orderId: string }
) {
  return firebase.functions.httpsCallable("unlockOrder")(payload);
}

/**
 * Tools for managing a stripe account
 */
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload & { type: "create" }
): Promise<string>;
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload & { type: "resumeOnboarding" }
): Promise<string>;
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload & { type: "getInvoice" }
): Promise<
  Stripe.Invoice & {
    payment_intent: Stripe.PaymentIntent | null;
  }
>;
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload & { type: "listInvoices" }
): Promise<Stripe.Response<Stripe.ApiList<Stripe.Invoice>>>;
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload & { type: "getPaymentMethod" }
): Promise<Stripe.Response<Stripe.PaymentMethod>>;
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload & { type: "listPaymentMethods" }
): Promise<Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>>>;
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload & { type: "delete" }
): Promise<void>;
export async function stripeAccount(
  firebase: GridbashFirebase,
  payload: StripeAccountPayload
): Promise<
  | string
  | Stripe.Response<Stripe.ApiList<Stripe.Invoice>>
  | Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>>
  | (Stripe.Invoice & {
      payment_intent: Stripe.PaymentIntent | null;
    })
  | Stripe.Response<Stripe.PaymentMethod>
  | void
> {
  const result = await firebase.functions.httpsCallable("stripeAccount")(
    payload
  );
  return result.data;
}

/**
 * Starts a checkout session for gridbash. Ie, this is for sites that
 * want to buy a subscription with us.
 */
export async function createCheckoutSession_gridbash(
  firebase: GridbashFirebase,
  payload: CreateGridbashCheckoutSession
): Promise<string> {
  const result = await firebase.functions.httpsCallable(
    "createCheckoutSession"
  )(payload);
  return result.data as string;
}

/**
 * Refreshes the statistics on twilio usage. Calling this function
 * should be throttled to avoid hitting twilio rate limits
 */
export function twilioRefresh(firebase: SiteFirebase) {
  return firebase.functions.httpsCallable("twilioRefresh")();
}

/**
 * Provisions or modifies a twilio phone number
 */
export function twilioPhoneNumber(
  firebase: SiteFirebase,
  payload: TwilioPhoneNumberPayload
) {
  return firebase.functions.httpsCallable("twilioPhoneNumber")(payload);
}

/**
 * Refreshes the statistics on stripe usage. Calling this function
 * should be throttled to avoid hitting stripe rate limits.
 */
export function stripeRefresh(firebase: SiteFirebase) {
  return firebase.functions.httpsCallable("stripeRefresh")();
}

/**
 * Unsubscribes a customer from receiving notifications about the status/
 * due date of their order.
 */
export function unsubscribeOrderEmails(
  firebase: SiteFirebase,
  payload: UnsubscribeOrderEmailsPayload
) {
  return firebase.functions.httpsCallable("unsubscribeOrderEmails")(payload);
}

/**
 * Checks if a phone number is blocked from sms
 */
export async function phoneIsBlocked(
  firebase: SiteFirebase,
  payload: PhoneIsBlockedPayload
) {
  const result = await firebase.functions.httpsCallable("phoneIsBlocked")(
    payload
  );
  return result.data as boolean;
}

/**
 * Updates a site's communication subscription
 */
export function updateSubscription(
  firebase: GridbashFirebase,
  payload: UpdateSubscriptionPayload
) {
  return firebase.functions.httpsCallable("updateSubscription")(payload);
}

/**
 * Updates a site's stripe customer object
 */
export function updateCustomer(
  firebase: GridbashFirebase,
  payload: UpdateCustomerPayload
) {
  return firebase.functions.httpsCallable("updateCustomer")(payload);
}

/**
 * Update a stripe payment method
 */
export function updatePaymentMethod(
  firebase: GridbashFirebase,
  payload: UpdatePaymentMethodPayload
) {
  return firebase.functions.httpsCallable("updatePaymentMethod")(payload);
}
