let original: string = document.body.style.cssText;
const modifications: {
  setup: (style: CSSStyleDeclaration) => void;
  cleanup?: () => void;
}[] = [];

const apply = (cleanup?: () => void) => {
  setTimeout(() => {
    document.body.style.cssText = original;
    for (const mod of modifications) {
      mod.setup(document.body.style); // It's expected that modification will mutate the style
    }
    cleanup?.();
  });
};

/**
 * When multiple pieces of code are trying to modify the <body>'s style, we can get
 * them trampling on eachother. This code lets multiple piece of code apply changes
 * and then be able to remove those changes later in a way that allows other changes
 * to remain.
 *
 * @example
 * ```typescript
 * useEffect(() => {
 *   const teardown = applyBodyStyle(style => {
 *     style.overflow = 'hidden';
 *   })
 *   return teardown;
 * }, [])
 * ```
 */
export const applyBodyStyle = (
  setup: (style: CSSStyleDeclaration) => void,
  cleanup?: () => void
) => {
  modifications.push({ setup, cleanup });
  apply();

  // Create a teardown function
  return () => {
    const index = modifications.findIndex((mod) => mod.setup === setup);
    let cleanup: (() => void) | undefined;
    if (index > -1) {
      cleanup = modifications[index].cleanup;
      modifications.splice(index, 1);
    }
    apply(cleanup);
  };
};
