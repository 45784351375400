import {
  NavigationItem,
  SiteSettings,
  WellKnownPageId,
  StandardNavigationItem,
} from "../database/siteSettings";

// export const createDivider = (): Divider => ({
//   type: "divider",
//   navItemId: uuid(),
// });

export const createHomeItem = (): StandardNavigationItem => ({
  type: "standard",
  navItemId: WellKnownPageId.home,
  pageId: WellKnownPageId.home,
  iconSettings: {
    icon: "home",
    iconType: "googleFont",
  },
  textSettings: {
    text: "",
    translations: {},
    textKey: "store.navigation.homeLink",
  },
});

export const createOrderItem = (): StandardNavigationItem => ({
  type: "standard",
  navItemId: WellKnownPageId.order,
  pageId: WellKnownPageId.order,
  iconSettings: {
    icon: "restaurant",
    iconType: "googleFont",
  },
  textSettings: {
    text: "",
    translations: {},
    textKey: "store.navigation.orderLink",
  },
});

export const createTrackItem = (): StandardNavigationItem => ({
  type: "standard",
  navItemId: WellKnownPageId.track,
  pageId: WellKnownPageId.track,
  iconSettings: {
    icon: "restore",
    iconType: "googleFont",
  },
  textSettings: {
    text: "",
    translations: {},
    textKey: "store.navigation.trackLink",
  },
});

export const createFavoritesItem = (): StandardNavigationItem => ({
  type: "standard",
  navItemId: WellKnownPageId.favorites,
  pageId: WellKnownPageId.favorites,
  iconSettings: {
    icon: "favorite",
    iconType: "googleFont",
  },
  textSettings: {
    text: "",
    translations: {},
    textKey: "store.navigation.favoritesLink",
  },
});

// export const createLoginItem = (): LoginNavigationItem => ({
//   type: "login",
//   navItemId: uuid(),
//   loggedOut: {
//     iconSettings: {
//       icon: "how_to_reg",
//       iconType: "googleFont",
//     },
//     textSettings: {
//       text: "",
//       translations: {},
//       textKey: "store.navigation.signInLink"
//     },
//   },
//   loggedIn: {
//     iconSettings: {
//       icon: "perm_identity",
//       iconType: "googleFont",
//     },
//     textSettings: {
//       text: "",
//       translations: {},
//       textKey: "store.navigation.signOutLink"
//     },
//   },
// });

export const createAccountSettingsItem = (): StandardNavigationItem => ({
  type: "standard",
  navItemId: WellKnownPageId.account,
  pageId: WellKnownPageId.account,
  iconSettings: {
    icon: "settings",
    iconType: "googleFont",
  },
  textSettings: {
    text: "",
    translations: {},
    textKey: "store.navigation.settingsLink",
  },
});

export const createMessagesItem = (): StandardNavigationItem => ({
  type: "standard",
  navItemId: WellKnownPageId.message,
  pageId: WellKnownPageId.message,
  popup: true,
  iconSettings: {
    icon: "message",
    iconType: "googleFont",
  },
  textSettings: {
    text: "",
    translations: {},
    textKey: "store.navigation.contactUsLink",
  },
});

export const createDefaultMenu = () => {
  return [
    createHomeItem(),
    createOrderItem(),
    createTrackItem(),
    createFavoritesItem(),
    createAccountSettingsItem(),
    createMessagesItem(),
  ];
};

export const getMissingItems = (
  items: NavigationItem[],
  features: SiteSettings["features"]
) => {
  const missingHome = !items.find(
    (item) => item.type === "standard" && item.pageId === WellKnownPageId.home
  );

  const missingOrder =
    features.showCatalog &&
    !items.find(
      (item) =>
        item.type === "standard" && item.pageId === WellKnownPageId.order
    );

  const missingTrack =
    features.showCatalog &&
    features.customerAccounts &&
    !items.find(
      (item) =>
        item.type === "standard" && item.pageId === WellKnownPageId.track
    );

  const missingFavorites =
    features.showCatalog &&
    features.customerAccounts &&
    !items.find(
      (item) =>
        item.type === "standard" && item.pageId === WellKnownPageId.favorites
    );

  const missingLogin = false;
  // features.customerAccounts && !items.find((item) => item.type === "login");

  const missingAccountSettings =
    features.customerAccounts &&
    !items.find(
      (item) =>
        item.type === "standard" && item.pageId === WellKnownPageId.account
    );

  const missingMessages =
    features.customerMessages &&
    !items.find(
      (item) =>
        item.type === "standard" && item.pageId === WellKnownPageId.message
    );

  const missingSomething =
    missingHome ||
    missingOrder ||
    missingTrack ||
    missingFavorites ||
    missingLogin ||
    missingAccountSettings ||
    missingMessages;

  return {
    missingHome,
    missingOrder,
    missingTrack,
    missingFavorites,
    missingLogin,
    missingAccountSettings,
    missingMessages,
    missingSomething,
  };
};
