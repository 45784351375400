import { useState, useEffect, FC } from "react";
import SiteUserContext, { SiteUserState } from "./siteUserContext";
import { RawStore } from "../../database/store";
import { useSiteFirebase } from "../../Firebase/context";

export interface SiteUserProviderProps {
  store: RawStore;
}

const SiteUserProvider: FC<SiteUserProviderProps> = ({ children, store }) => {
  const firebase = useSiteFirebase();
  const [userState, setUserState] = useState<SiteUserState>({
    user: null,
    role: null,
    siteId: null,
    loading: true,
  });

  useEffect(
    () =>
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user === null) {
          setUserState({
            user: null,
            role: null,
            siteId: null,
            loading: false,
          });
        } else {
          const { claims } = await user.getIdTokenResult();
          const { role, siteId } = claims;
          setUserState({
            user,
            role: role ?? null,
            siteId: siteId ?? null,
            loading: false,
          });
        }
      }),
    [firebase.auth]
  );

  return (
    <SiteUserContext.Provider value={userState}>
      {children}
    </SiteUserContext.Provider>
  );
};

export default SiteUserProvider;
