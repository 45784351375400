import { useRef, useEffect } from "react";

/**
 * Runs code on unmount, while also making sure to use the
 * latest version of the function. Useful if your unmount
 * logic needs access to variables in its closure
 */
export const useUnmount = (fn?: (() => void) | (() => Promise<void>)): void => {
  const fnRef = useRef(fn);

  // update the ref each render so if it change the newest callback will be invoked
  fnRef.current = fn;

  useEffect(
    () => () => {
      fnRef.current?.();
    },
    []
  );
};
