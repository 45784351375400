import { BackgroundConfig } from "../components/Dashboard/Builder/WebsiteBuilder/widgetEditors/customWidgetEditor/containerSettings/backgroundEditor";
import { BorderConfig } from "../components/Dashboard/Builder/WebsiteBuilder/widgetEditors/customWidgetEditor/containerSettings/borderEditor";
import { BoxShadowConfig } from "../components/Dashboard/Builder/WebsiteBuilder/widgetEditors/customWidgetEditor/containerSettings/boxShadowEditor";
import { PaddingConfig } from "../components/Dashboard/Builder/WebsiteBuilder/widgetEditors/customWidgetEditor/containerSettings/paddingEditor";
import { ColorConfig } from "../components/widgets/colorConfig";
import {
  PageCell,
  PageWidgetType,
} from "../components/Store/PageRenderer/widgetType";

/**
 * Initial implementation of custom pages
 */
export type PageConfig_v1 = {
  path: string;
  pageId: string;
  template: PageTemplate;
};

export type PageTemplate = RichTextTemplate;

export interface RichTextTemplate {
  type: "richText";
  content: string;
}

export interface PageConfig {
  pageId: string;
  /**
   * What url path to show the page at, or null to use the pageId
   */
  path: string | null;
  /**
   * When this page was last modified
   */
  lastEdit?: {
    userId: string;
    timestamp: number;
  };
  header?: PageHeader;
  /**
   * Contents of the page
   */
  rows: PageRow[];
}

export interface PageHeader {
  /**
   * The background of the header. Note that this isn't limited to a single section:
   * it should render behind all the navigation sections, and all the header sections.
   */
  backgroundImage?: {
    url: string;
    urls: { [key: string]: string };
    anchorHorizontal: "left" | "center" | "right";
    anchorVertical: "top" | "center" | "bottom";
    opacity: number;
  };
  backgroundColor?: ColorConfig;
  height: "cover" | "large" | "medium" | "auto";
  /**
   * If true, the background extends behind navigation
   */
  includeNavigation: boolean;
  rows: PageRow[];
}

// Page row used to be its own type, but is now just a special cell.
//   Keeping this type alias for quicker reference
export type PageRow = PageCell<PageWidgetType.row>;

export interface PageColumn {
  /*  Percent, between 0 and 100. The columns should sum to 100% */
  width: number;
  widthUnit?: "percent" | "px";
  cellAlign: "top" | "center" | "bottom";
  cells: PageCell[];
  backgroundImage?: {
    url: string;
    urls: { [key: string]: string };
    anchorHorizontal: "left" | "center" | "right";
    anchorVertical: "top" | "center" | "bottom";
    opacity: number;
  };
  backgroundColor?: ColorConfig;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
}

export interface CustomWidgetTemplate {
  templateId: string;
  name?: string;
  background?: BackgroundConfig;
  border?: BorderConfig;
  boxShadow?: BoxShadowConfig;
  padding?: PaddingConfig;
  rows: PageRow[];
}

/**
 * A list of all the pages with a few pieces of data. Used to render
 * the left hand column of the website builder, without having to
 * download the full pages
 *
 * Found in site firestore at /stores/store/aggregations/pages
 */
export interface PageAggregation {
  pages: PageSummary[];
  navigation: {
    rows: PageRow[];
  };
  /**
   * When the summaries and navigation were last modified
   */
  lastEdit?: {
    userId: string;
    timestamp: number;
  };
}

export type PageSummary = CustomPageSummary | PredefinedPageSummary;

export enum PredefinedPageId {
  order = "order",
  track = "track",
  favorites = "favorites",
  account = "account",
  message = "message",
}

export interface PredefinedPageSummary {
  predefined: true;
  pageId: PredefinedPageId;
  name: string;
  icon?: string;
  isHomePage: boolean;
  hideFromNavigation: boolean;
}

export interface CustomPageSummary {
  predefined?: never;
  pageId: string;
  name: string;
  icon?: string;
  isHomePage: boolean;
  hideFromNavigation: boolean;
}
