import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { Fragment, FC } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";

interface TermsProps {}

const Terms: FC<TermsProps> = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <DialogTitle>Terms Of Service</DialogTitle>
      <DialogContent className="smartWidthMd">
        <Typography>
          By using our services you agree to the following terms.
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="If you use our services on behalf of your company, your company agrees to them too." />
          </ListItem>
          <ListItem>
            <ListItemText primary="You retain all rights to your content when you use it in our services. However, we do reserve the right to remove any content that you make available through the marketplace that we think is objectionable (e.g., illegal, obscene, hateful or harmful to you, our customers or us)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="We are continuously taking steps to keep our service safe and secure, but we cannot guarantee prevention of security breaches.  It is of vital importance that you play your part by keeping your passwords safe, and safeguarding your devices and account access. You will also need to let us know immediately if you think an unauthorized person is using your account. In case of a dispute about who owns your account, we reserve the right to make the decision." />
          </ListItem>
          <ListItem>
            <ListItemText primary="We can terminate your account and/or deny you access to our services at any time, for any reason. You can also choose to deactivate your account at any time." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If we stop working together (you and us), we are not responsible for any losses you experience because of the termination of our services or for removing your data from our servers." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If we get sued or fined due to something you did by using our services, you agree to cover our losses." />
          </ListItem>
          <ListItem>
            <ListItemText primary="We are sincerely doing the best we can to equip you and your customers with an exceptional experience. We provide our service as-is, without any warranties. If you’re not happy with something, or feel that something didn’t work out as expected, let us know and we will put forward an honest attempt to best accommodate the situation." />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("gridbash.close")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Terms;
