import { TFunction } from "i18next";
import { PostalAddress } from "../database/siteSettings";
import { notUndefined } from "./notNull";

export const addressToString = (
  address: PostalAddress | null | undefined,
  t: TFunction
) => {
  if (!address) return "";

  return [
    address.streetLine1,
    address.streetLine2,
    address.streetLine3,
    address.city,
    address.stateCode && t(`store.account.state.${address.stateCode}`),
    address.zip,
    address.countryCode && t(`store.account.country.${address.countryCode}`),
  ]
    .filter(notUndefined)
    .join(" ");
};
