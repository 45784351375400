import { useState } from "react";
import * as React from "react";
import { CartItemWithRevisions } from "../../../database/cart";
import { optionType } from "../../../database/option";
import { getUnitName } from "../../../utilities/getUnitName";
import { useTranslation } from "react-i18next";
import { CardActions, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import Card from "@material-ui/core/Card";
import IconFavoriteWidget from "../Orders/Templates/IconFavoriteWidget";
import getItemPriceLabels from "../Orders/getItemPriceLabels";
import ImageIcon from "@material-ui/icons/Image";
import { srcSetString } from "../../Main/main";

export interface TrackingDetailCartItemProps {
  cartItem: CartItemWithRevisions;
  index: number;
  initPath: string;
}

const TrackingDetailCartItem: React.FC<TrackingDetailCartItemProps> = ({
  cartItem,
  index,
  initPath,
}) => {
  const { t } = useTranslation();
  const { catalogItem } = cartItem;

  const { priceLabel } = getItemPriceLabels({
    t,
    cartItem,
  });

  const [imageInfo, setImageInfo] = useState<{
    loaded: boolean;
    error: boolean;
  }>({
    loaded: false,
    error: false,
  });

  const imgSize = 112;

  return (
    <Card
      variant="outlined"
      style={{ marginBottom: "max(1em, 1vw)", padding: "1em" }}
    >
      {catalogItem.imageUrl ? (
        imageInfo.error ? (
          <div
            style={{
              textAlign: "center",
              opacity: "0.5",
              padding: "1em",
            }}
          >
            <ImageIcon fontSize="large" />
          </div>
        ) : (
          <div style={{ height: imgSize }}>
            <img
              className="boxShadow2"
              src={catalogItem.imageUrl}
              /**
               * SRCSET EXPLAINED
               *
               * IN DEV TOOLS
               * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
               * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
               * ACTUALLY TAKING UP.
               *
               * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
               * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
               */
              srcSet={srcSetString(catalogItem.imageUrls)}
              /**
               * SIZES EXPLAINED
               *
               * FOR IMAGES THAT DONT HAVE METADATA (OLD IMAGES)
               * THIS MODAL WILL NOT GROW WIDER THAN 675PX
               * BECAUSE OF CSS CLASS SMARTWIDTHLG
               * WE ALSO HAVE PADDING LEFT AND RIGHT SET TO max(1.5vw, 1em)
               * SO WE DOUBLE THAT TO max(3vw, 2em)
               *
               * FOR IMAGES THAT HAVE METADATA WE USE THE ASPECT RATIO
               * TO CALCULATE THE WIDTH THAT WILL BE RENDERED BASED
               * ON THE HEIGHT WE SET IN THE STYLE
               *
               * HEIGHT IS SET TO 112PX
               * => WIDTH WILL THEN BE (META WIDTH / META HEIGHT) * 112
               */
              sizes={
                catalogItem.imageMetadata?.width &&
                catalogItem.imageMetadata?.height
                  ? `${Math.round(
                      (catalogItem.imageMetadata.width /
                        catalogItem.imageMetadata.height) *
                        imgSize
                    )}px`
                  : "(min-width: 675px) calc(675px - max(3vw, 2em)), calc(100vw - max(3vw, 2em))"
              }
              alt={catalogItem.name}
              onLoad={() => {
                setImageInfo({ loaded: true, error: false });
              }}
              onError={() => {
                setImageInfo({ loaded: true, error: true });
              }}
              style={{
                objectFit: "cover",
                objectPosition: catalogItem.imageZoom
                  ? `${catalogItem.imageZoom.x}% ${catalogItem.imageZoom.y}%`
                  : "center",
                verticalAlign: "middle",
                width:
                  catalogItem.imageMetadata?.width &&
                  catalogItem.imageMetadata?.height
                    ? Math.round(
                        (catalogItem.imageMetadata.width /
                          catalogItem.imageMetadata.height) *
                          imgSize
                      )
                    : undefined,
                maxWidth: "100%",
                height: "100%",
                maxHeight: imgSize,
                borderRadius: "5px",
                opacity: imageInfo.loaded ? 1 : 0,
                transition: "all 0.5s ease 0.5s",
              }}
              height={imgSize}
            />
          </div>
        )
      ) : null}

      <div style={{ marginTop: "max(0.5vw, 0.5em)" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            align="left"
            style={{
              wordBreak: "break-word",
              paddingRight: "0.5em",
              textDecoration: cartItem.unable ? "line-through" : undefined,
            }}
          >
            {catalogItem.name}
          </Typography>

          <div style={{ padding: "12px" }}>
            {cartItem.unable ? (
              <CancelIcon color="secondary" />
            ) : (
              <DoneIcon color="primary" />
            )}
          </div>
        </div>
        <Typography align="left" color="primary">
          {cartItem.count.toLocaleString()}{" "}
          {catalogItem.unit && getUnitName(catalogItem.unit, cartItem.count, t)}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <div style={{ textAlign: "right" }}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {priceLabel}
            </Typography>
          </div>
        </div>
      </div>

      <Typography align="left" color="textSecondary" variant="body2">
        {cartItem.instructions}
      </Typography>

      <div style={{ marginBottom: "max(1vw, 1em)" }}>
        <div>
          {cartItem.optionSelections?.map((selection, index) => {
            const option = cartItem.catalogItem.options.find(
              (option) => option.optionId === selection.optionId
            );

            if (
              selection.type === optionType.NUMBER &&
              option?.type === optionType.NUMBER
            ) {
              return (
                <Typography
                  align="left"
                  variant="body2"
                  color="textSecondary"
                  key={index}
                >
                  {option.name}: {selection.value} {option.unit ?? ""}
                </Typography>
              );
            } else if (
              selection.type === optionType.LIST &&
              option?.type === optionType.LIST
            ) {
              return Object.keys(selection.selectedItems).map((key) => {
                const optionItem = option.items.find(
                  (optionItem) => optionItem.id === key
                );
                if (optionItem) {
                  return (
                    <Typography
                      key={key}
                      align="left"
                      variant="body2"
                      color="textSecondary"
                    >
                      {optionItem.description}
                    </Typography>
                  );
                } else {
                  return null;
                }
              });
            } else {
              return null;
            }
          })}
        </div>
        <div style={{ marginTop: "1em" }} />
      </div>

      <CardActions style={{ justifyContent: "flex-end", padding: 0 }}>
        <IconFavoriteWidget catalogItem={catalogItem} />
      </CardActions>
    </Card>

    // <Card style={{ marginBottom: "1vh" }}>
    //   <List component="div">
    //     <ListItem disableRipple button onClick={(e) => {}}>
    //       <ListItemAvatar>
    //         <Avatar>{cartItem.unable ? <CancelIcon /> : <DoneIcon />}</Avatar>
    //       </ListItemAvatar>

    //       <ListItemText
    //         style={{
    //           overflow: "hidden",
    //           whiteSpace: "normal",
    //           wordBreak: "break-word",
    //         }}
    //         primary={catalogItem.name}
    //       />
    //     </ListItem>
    //   </List>

    //   <Typography
    //     component="div"
    //     variant="caption"
    //     align="left"
    //     style={{ marginLeft: "1em" }}
    //   >
    //     {catalogItem.unit ? (
    //       <>
    //         <strong>{cartItem.count}</strong>&nbsp;
    //         {getUnitName(catalogItem.unit, cartItem.count, t)}
    //       </>
    //     ) : (
    //       <>
    //         Count:&nbsp;<strong>{cartItem.count}</strong>
    //       </>
    //     )}
    //   </Typography>
    //   {cartItem.instructions && cartItem.instructions?.length > 0 && (
    //     <Typography
    //       component="div"
    //       variant="caption"
    //       align="left"
    //       style={{
    //         overflow: "hidden",
    //         textOverflow: "ellipsis",
    //         padding: "0 1em",
    //       }}
    //     >
    //       {t("store.orders.instructions")}: &nbsp;
    //       <div style={{ fontWeight: "bold", padding: "0 0.5em" }}>
    //         {cartItem.instructions}
    //       </div>
    //     </Typography>
    //   )}

    //   {cartItem.optionSelections?.map((selection) => {
    //     const option = catalogItem.options.find(
    //       (option) => option.optionId === selection.optionId
    //     );

    //     if (isListOption(option) && isListOptionSelection(selection)) {
    //       if (Object.keys(selection.selectedItems).length === 0) {
    //         return null;
    //       } else {
    //         return (
    //           <Typography
    //             key={selection.optionId}
    //             component="div"
    //             variant="caption"
    //             align="left"
    //             style={{
    //               overflow: "hidden",
    //               textOverflow: "ellipsis",
    //               padding: "0 1em",
    //             }}
    //           >
    //             <div>
    //               {`${option.name}:`}
    //               &nbsp;
    //             </div>
    //             {Object.keys(selection.selectedItems).map((id) => {
    //               let optionItem = option.items.find((item) => item.id === id);
    //               return (
    //                 optionItem && (
    //                   <div
    //                     key={id}
    //                     style={{
    //                       fontWeight: "bold",
    //                       padding: "0 0.5em",
    //                     }}
    //                   >
    //                     {`${optionItem.description}`}
    //                   </div>
    //                 )
    //               );
    //             })}
    //           </Typography>
    //         );
    //       }
    //     } else if (
    //       isNumberOption(option) &&
    //       isNumberOptionSelection(selection)
    //     ) {
    //       return (
    //         <Typography
    //           key={selection.optionId}
    //           component="div"
    //           variant="caption"
    //           align="left"
    //           style={{
    //             overflow: "hidden",
    //             textOverflow: "ellipsis",
    //             padding: "0 1em",
    //           }}
    //         >
    //           <div>
    //             {`${option.name}:`}
    //             &nbsp;
    //           </div>
    //           <div
    //             style={{
    //               fontWeight: "bold",
    //               padding: "0 0.5em",
    //             }}
    //           >{`${selection.value} ${option.unit || ""}`}</div>
    //         </Typography>
    //       );
    //     }
    //     return null;
    //   })}
    //   <DialogActions>
    //     <IconFavoriteWidget initPath={initPath} catalogItem={catalogItem} />
    //   </DialogActions>
    // </Card>
  );
};

export default TrackingDetailCartItem;
