import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as R from "ramda";
import {
  PageAggregation,
  PageConfig,
  PageSummary,
} from "../../../../../database/page";
import { useSiteFirebase } from "../../../../../Firebase/context";
import { storeKey } from "../../../../../Firebase/siteFirebase";
import { useUnmount } from "../../../../../utilities/useUnmount";
import useSiteUser from "../../../../UserProvider/useSiteUser";
import {
  PageApiContext,
  PageApiValue,
  PageDispatchContext,
  PageDispatchValue,
} from "./pageApiContext";
import { unstable_batchedUpdates } from "react-dom";
import { deleteUndefinedsRecursive } from "../../../../../utilities/deleteUndefineds";
import { defaultNavRow } from "../defaultPage";
import { PageAction } from "./actions";
import { PageState, reducer } from "./reducer";
import { usePublishPages } from "./usePublishPages";
import { useEditPages } from "./useEditPages";
import { useSiteSettings } from "../../../../../customization/siteSettingsContext";
import { UserData } from "../../../../../database/realtimeDb";
import uuid from "uuid/v4";
import { Dialog } from "@material-ui/core";
import Conflict from "./conflict";
import usePrevious from "../../../../../utilities/usePrevious";

interface PageApiProviderProps {}

export interface EditHistory {
  actions: {
    action: PageAction;
    // index of the restored state we were using at the time this action was
    //    dispatched. May be -1 (which indicates there was no restored state)
    startingPoint: number;
  }[];
  // Normally, this points to the end of the action array. Hitting undo
  //   will decrement the index and thus point to an older action
  index: number;
}

/**
 * Manages the state and functions for editing pages. Values are exposed to
 * descendant components via context.
 */
const PageApiProvider: FC<PageApiProviderProps> = ({ children }) => {
  const { user, role } = useSiteUser();
  const siteFirebase = useSiteFirebase();
  const siteSettings = useSiteSettings();

  const [selectedPageId, setSelectedPageId] = useState<string>();
  const [loadingPage, setLoadingPage] = useState(false);

  const selectPage = useCallback((pageId: string) => {
    setSelectedPageId(pageId);
    if (!(pageId in subscriptions.current)) {
      setLoadingPage(true);
    }
  }, []);

  const publishPromise = useRef<Promise<void>>();

  const loadPage = useCallback(
    (pageId: string) => {
      if (pageId in subscriptions.current) {
        // Already loading
        return;
      }
      setLoadingPage(true);
      const unsubscribe = siteFirebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("pages")
        .doc(pageId)
        .onSnapshot(
          async (snapshot) => {
            const data = snapshot.data() as PageConfig | undefined;
            if (publishPromise.current) {
              // We're in the middle of publishing. Wait until that finishes
              //   before applying these changes
              await publishPromise.current;
            }
            unstable_batchedUpdates(() => {
              setLoadingPage(false);
              setPagesInDb((prev) => {
                if (data) {
                  return {
                    ...prev,
                    [pageId]: data,
                  };
                } else {
                  return R.omit([pageId], prev);
                }
              });
            });
          },
          (err) => {
            console.error("error fetching", err);
            unstable_batchedUpdates(() => {
              setLoadingPage(false);
              setPagesInDb((prev) => R.omit([pageId], prev));
            });
          }
        );
      subscriptions.current[pageId] = unsubscribe;
    },
    [siteFirebase.firestore]
  );

  useLayoutEffect(() => {
    if (!selectedPageId || selectedPageId in subscriptions.current) {
      return;
    }
    loadPage(selectedPageId);
  }, [loadPage, selectedPageId, siteFirebase.firestore]);

  const [pageSummariesLoaded, setPageSummariesLoaded] = useState(false);
  const [pageSummariesInDb, setPageSummariesInDb] = useState<PageAggregation>({
    pages: [],
    navigation: {
      rows: [defaultNavRow],
    },
  });
  useEffect(() => {
    if (role) {
      return siteFirebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("aggregations")
        .doc("pages")
        .onSnapshot(
          async (snapshot) => {
            const data = snapshot.data() as PageAggregation | undefined;
            if (data) {
              if (publishPromise.current) {
                // We're in the middle of publishing. Wait until that finishes
                //   before applying these changes
                await publishPromise.current;
              }

              unstable_batchedUpdates(() => {
                setPageSummariesLoaded(true);
                setPageSummariesInDb(data);
                setSelectedPageId((prev) => {
                  if (prev === undefined) {
                    // No page is selected yet, so select one. Use the home page if
                    //    we can, or fall back to the first page.
                    let page = data.pages.find((page) => page.isHomePage);
                    if (!page) {
                      page = data.pages[0] as PageSummary | undefined;
                    }
                    return page?.pageId;
                  }
                  return prev;
                });
              });
            }
          },
          (err) => {
            console.error("error fetching catalog aggregation", err);
          }
        );
    }
  }, [role, siteFirebase.firestore]);

  const [pagesInDb, setPagesInDb] = useState<{ [pageId: string]: PageConfig }>(
    {}
  );
  const subscriptions = useRef<{ [pageId: string]: () => void }>({});
  useUnmount(() => {
    for (const pageId in subscriptions.current) {
      subscriptions.current[pageId]();
    }
  });

  // Store edit histories, for the undo feature. Index of -1 means we
  //   should show the version in the database
  const [editHistory, setEditHistory] = useState<EditHistory>({
    actions: [],
    index: -1,
  });

  const [sessionId] = useState(uuid);

  const [restoredStates, setRestoredStates] = useState<
    (PageState | undefined)[]
  >([]);
  const latestBackup = useRef<PageState>();
  useEffect(() => {
    if (!user?.uid) {
      return;
    }
    let unmounted = false;
    let firstTime = true;
    const ref = siteFirebase.database.ref(`users/${user.uid}`);
    const onValueChange = ref.on(
      "value",
      (snapshot) => {
        if (unmounted) {
          return;
        }
        const wasFirstTime = firstTime;
        firstTime = false;
        const data = snapshot.val() as UserData | undefined;
        const workInProgress = data?.pageWorkInProgress;
        if (!workInProgress) {
          latestBackup.current = undefined;
          return;
        }

        let newState: PageState | undefined;
        if (typeof workInProgress.stateJSON === "string") {
          try {
            newState = JSON.parse(workInProgress.stateJSON);
          } catch (err) {
            console.log("error deserializing builder backup", err);
          }
        }
        latestBackup.current = newState;
        if (!wasFirstTime && workInProgress.sessionId === sessionId) {
          // We just saved this data ourselves. Don't treat it as a starting point, but
          //   remember it so we don't save it again
          return;
        }
        unstable_batchedUpdates(() => {
          setEditHistory((prevEditHistory) => {
            setRestoredStates((prevRestoredStates) => {
              const hasLocalEdits = prevEditHistory.actions.some(
                ({ startingPoint }) =>
                  startingPoint === prevRestoredStates.length - 1
              );
              if (hasLocalEdits) {
                // We have some actions that use the last restored state, so we need to keep
                //   that restored state in case they hit undo. Append to the array.
                return [...prevRestoredStates, newState];
              } else {
                // No local edits have been made with the last restored state, so we can
                //   safely throw it out.
                if (prevRestoredStates.length === 0 && newState) {
                  return [newState];
                } else if (prevRestoredStates.length === 0 && !newState) {
                  // No need to store an explicit undefined; an empty array is fine.
                  return prevRestoredStates;
                } else {
                  // Replace the previous state, since we'll never need it again
                  const temp = [...prevRestoredStates];
                  temp[temp.length - 1] = newState;
                  return temp;
                }
              }
            });

            // Wipe out any future history.
            if (prevEditHistory.index === prevEditHistory.actions.length - 1) {
              return prevEditHistory;
            } else {
              return {
                actions: prevEditHistory.actions.slice(
                  0,
                  prevEditHistory.index
                ),
                index: prevEditHistory.index,
              };
            }
          });

          if (newState) {
            if (wasFirstTime) {
              setBackupStatus("restored");
            }
            Object.keys(newState.pages).forEach((pageId) => loadPage(pageId));
          }
        });
      },
      (error) => {
        console.log("error listening to builder backups", error);
      }
    );

    return () => {
      ref.off("value", onValueChange);
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  const [showConflict, setShowConflict] = useState(false);
  const hasCheckedTimestamps = useRef(false);
  useEffect(() => {
    if (hasCheckedTimestamps.current) {
      // Only want to perform the check once, when all the data is loaded for the first time
      return;
    }

    let restoredState: PageState | undefined =
      restoredStates[restoredStates.length - 1];

    const everythingIsLoaded =
      restoredState &&
      pageSummariesInDb &&
      Object.keys(restoredState.pages).every((pageId) => pageId in pagesInDb);
    if (restoredState && everythingIsLoaded) {
      hasCheckedTimestamps.current = true;
      let databaseIsNewer = false;
      if (
        restoredState.summaries.lastEdit &&
        pageSummariesInDb.lastEdit &&
        restoredState.summaries.lastEdit.timestamp <
          pageSummariesInDb.lastEdit.timestamp
      ) {
        databaseIsNewer = true;
      } else if (
        Object.values(restoredState.pages).some((page) => {
          const pageInDb = pagesInDb[page.pageId];
          return (
            page.lastEdit &&
            pageInDb.lastEdit &&
            page.lastEdit.timestamp < pageInDb.lastEdit.timestamp
          );
        })
      ) {
        databaseIsNewer = true;
      }
      if (databaseIsNewer) {
        setShowConflict(true);
      }
    }
  }, [pageSummariesInDb, pagesInDb, restoredStates]);

  // Look up the current state of editing, based on undo history and index
  const pageState: PageState = useMemo(() => {
    let state: PageState = {
      summaries: pageSummariesInDb,
      pages: pagesInDb,
      customWidgets: siteSettings.customWidgets,
      pageSettings: siteSettings.pageSettings,
    };

    let startingPoint: number;
    let restoredState: PageState | undefined;
    if (editHistory.index === editHistory.actions.length - 1) {
      // They are at the front of the undo history. Use the latest
      //   version, even if another tab created it and we have made
      //   no changes to it
      startingPoint = restoredStates.length - 1;
    } else {
      // They have backed up to a previous undo state. Use the version
      //  corresponding to what they have asked for
      startingPoint =
        editHistory.actions[editHistory.index]?.startingPoint ?? 0;
    }
    restoredState = restoredStates[startingPoint];

    if (restoredState) {
      state.summaries = restoredState.summaries;
      state.pages = {
        ...state.pages,
        ...restoredState.pages,
      };
    }
    if (editHistory.index >= 0) {
      for (let i = 0; i <= editHistory.index; i++) {
        const entry = editHistory.actions[i];
        if (entry.startingPoint === startingPoint) {
          state = reducer(state, entry.action);
        }
      }
    }
    return state;
  }, [
    pageSummariesInDb,
    pagesInDb,
    siteSettings.customWidgets,
    siteSettings.pageSettings,
    editHistory.index,
    editHistory.actions,
    restoredStates,
  ]);

  useEffect(() => {
    if (
      pageSummariesLoaded &&
      !pageState.summaries.pages.find((page) => page.pageId === selectedPageId)
    ) {
      // If they find themselves with no page selected (eg, via deleting a page)
      //   then automatically select the home page
      const homePage = pageState.summaries.pages.find(
        (page) => page.isHomePage
      );
      if (homePage) {
        selectPage(homePage.pageId);
      }
    }
  }, [
    pageState.summaries.pages,
    pageSummariesLoaded,
    selectPage,
    selectedPageId,
  ]);

  const [backupStatus, setBackupStatus] = useState<
    null | "saving" | "saved" | "restored"
  >(null);

  // To eliminate the possibility of infinite loops when multiple tabs are open,
  //   we only want to save their progress if they changed something locally, or
  //   if the published data changed. Changes to their personal backup will update
  //   local state, but should not trigger another backup.
  const canSave = useRef(false);
  const prevPageSummariesInDb = usePrevious(pageSummariesInDb);
  const prevPagesInDb = usePrevious(pagesInDb);
  const prevEditHistory = usePrevious(editHistory);
  const changed =
    pageSummariesInDb !== prevPageSummariesInDb ||
    prevPagesInDb !== pagesInDb ||
    prevEditHistory !== editHistory;
  canSave.current = canSave.current || changed;

  // Save to user settings
  useEffect(() => {
    const uid = user?.uid;
    setBackupStatus(null);
    if (!uid || !canSave.current) {
      return;
    }

    let unmounted = false;
    const timerId = setTimeout(async () => {
      canSave.current = false; // Reset until they make another change
      try {
        let pageWorkInProgress: UserData["pageWorkInProgress"];
        const matchesPublishedData = R.equals(pageState, {
          summaries: pageSummariesInDb,
          pages: pagesInDb,
          customWidgets: siteSettings.customWidgets,
          pageSettings: siteSettings.pageSettings,
        });
        if (matchesPublishedData) {
          if (latestBackup.current === undefined) {
            // They havn't changed anything, so don't save anything
            return;
          }
          pageWorkInProgress = { sessionId };
        } else {
          let areEqual: boolean;
          const valInDatabase = latestBackup.current;
          if (valInDatabase === undefined) {
            areEqual = false;
          } else {
            // Helper function to remove all the lastEdit properties from the state
            const removeTimestamps = R.evolve({
              summaries: R.omit(["lastEdit"]),
              pages: R.mapObjIndexed(R.omit(["lastEdit"])),
            });

            areEqual = R.equals(
              removeTimestamps(valInDatabase),
              removeTimestamps(pageState)
            );
          }

          if (areEqual) {
            // They havn't changed anything, so don't save
            return;
          }

          const lastEdit = {
            userId: uid,
            timestamp: Date.now(),
          };

          // TODO: don't save any page or widget that hasn't changed, and if the summaries havn't
          //   changed then don't update their timestamp.
          const stateToSave = deleteUndefinedsRecursive({
            summaries: {
              ...pageState.summaries,
              lastEdit,
            },
            pages: R.mapObjIndexed(
              (page) => ({
                ...page,
                lastEdit,
              }),
              pageState.pages
            ),
            customWidgets: pageState.customWidgets,
          });

          pageWorkInProgress = {
            sessionId,
            stateJSON: JSON.stringify(stateToSave),
          };
        }

        setBackupStatus("saving");
        // Want to keep the "saving" text up for long enough to be readable
        const minimumDuration = new Promise((resolve) =>
          setTimeout(resolve, 500)
        );
        await Promise.all([
          minimumDuration,
          siteFirebase.database
            .ref(`users/${uid}/pageWorkInProgress`)
            .set(pageWorkInProgress),
        ]);
        if (!unmounted) {
          setBackupStatus("saved");
        }
      } catch (err) {
        console.log(err);
        if (!unmounted) {
          setBackupStatus(null);
        }
      }
    }, 500);
    return () => {
      clearTimeout(timerId);
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  // Create the functions and values related to publishing
  const { dirty, revert, publish } = usePublishPages({
    pageSummariesInDb,
    pagesInDb,
    pageState,
    setEditHistory,
    setRestoredStates,
    setBackupStatus,
    publishPromise,
  });

  // Create the functions and values related to making local edits
  const {
    dispatch,
    replaceAction,
    undo,
    redo,
    undoLength,
    undoIndex,
    setUndoIndex,
  } = useEditPages({
    editHistory,
    setEditHistory,
    setSelectedPageId,
    restoredStates,
  });

  const page = selectedPageId ? pageState.pages[selectedPageId] : undefined;
  const originalPage = selectedPageId ? pagesInDb[selectedPageId] : undefined;

  const value: PageApiValue = useMemo(
    () => ({
      pageState,
      pageSummaries: pageState.summaries,
      page,
      originalPage,
      loadingPage,
      pages: pageState.pages,
      pageSettings: pageState.pageSettings,
      customWidgets: pageState.customWidgets,
      selectPage,
      undo,
      redo,
      setUndoIndex,
      undoLength,
      undoIndex,
      dirty,
      publish,
      revert,
      backupStatus,
    }),
    [
      pageState,
      page,
      originalPage,
      loadingPage,
      selectPage,
      undo,
      redo,
      setUndoIndex,
      undoLength,
      undoIndex,
      dirty,
      publish,
      revert,
      backupStatus,
    ]
  );
  const dispatchValue: PageDispatchValue = useMemo(
    () => ({
      dispatch,
      replaceAction,
    }),
    [dispatch, replaceAction]
  );

  const lastRestoredState = restoredStates[restoredStates.length - 1];

  return (
    <PageApiContext.Provider value={value}>
      <PageDispatchContext.Provider value={dispatchValue}>
        {lastRestoredState && (
          <Dialog
            open={showConflict}
            onClose={() => {
              // They need to deal with this dialog before moving on
            }}
          >
            <Conflict
              pageSummariesInDb={pageSummariesInDb}
              pagesInDb={pagesInDb}
              restoredState={lastRestoredState}
              closeModal={() => setShowConflict(false)}
            />
          </Dialog>
        )}
        {children}
      </PageDispatchContext.Provider>
    </PageApiContext.Provider>
  );
};

export default PageApiProvider;
