// Turns a hex string (eg, '#BBAADD') into an rgb array (eg, [12, 34, 56])
export function hexToRgbArray(hex: string): number[] {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [];
}

// Turns a hex string (eg, '#BBAADD') into an rgb string fragment (eg, '12, 34, 56')
export function hexToRgbString(hex: string) {
  return hexToRgbArray(hex).join(",");
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

/**
 * Picks a font color that maximizes contrast with the given color, either
 * white or black.
 */
export function contrastColor(hex: string) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    console.warn("invalid hex color", hex);
    return "#FFFFFF";
  }
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  // http://stackoverflow.com/a/3943023/112731
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
}

/**
 *
 * @param hex
 * @param lum THE LUMINOSITY FACTOR, E.G. -0.1 IS 10% DARKER, 0.2 IS 20% LIGHTER
 * @returns A DARKER OR LIGHTER RGB COLOR VARIANT FOR A GIVEN HEX
 */
export function colorLuminance(hex: string, lum: number) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getOpacityColor(initial: string, opacity: number) {
  // MIX WITH WHITE BACKGROUND
  const r = Math.floor(
    Number(`0x${initial.substring(1, 3)}`) * opacity + 0xff * (1 - opacity)
  );
  const g = Math.floor(
    Number(`0x${initial.substring(3, 5)}`) * opacity + 0xff * (1 - opacity)
  );
  const b = Math.floor(
    Number(`0x${initial.substring(5, 7)}`) * opacity + 0xff * (1 - opacity)
  );

  return "#" + ((r << 16) | (g << 8) | b).toString(16);
}
