import { Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { forwardRef } from "react";
import * as React from "react";

type SlideInOutProps = Omit<TransitionProps, "direction"> & {
  directionIn?: "right" | "left" | "up" | "down";
  directionOut?: "right" | "left" | "up" | "down";
} & { children?: JSX.Element };

/**
 * Helper component for doing slides in different directions
 */
export const SlideInOut = forwardRef<unknown, SlideInOutProps>(
  ({ directionIn, directionOut, ...rest }, ref) => {
    return (
      <Slide
        ref={ref}
        direction={rest.in ? directionIn : directionOut}
        {...rest}
      />
    );
  }
);

/**
 * Transition that cause the modal to always move to the left. It
 * moves in from the right edge moving leftwards, then exist by
 * continuing to move leftwards
 */
export const UpDown = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return (
    <SlideInOut
      directionIn="up"
      directionOut="up"
      ref={ref}
      {...props}
      mountOnEnter
      unmountOnExit
    />
  );
});

/**
 * Transition that cause the modal to always move to the left. It
 * moves in from the right edge moving leftwards, then exist by
 * continuing to move leftwards
 */
export const DoubleLeft = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return (
    <SlideInOut
      directionIn="left"
      directionOut="right"
      ref={ref}
      {...props}
      mountOnEnter
      unmountOnExit
    />
  );
});

/**
 * Transition that cause the modal to always move to the right. It
 * moves in from the left edge moving rightwards, then exist by
 * continuing to move rightwards
 */
export const DoubleRight = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return (
    <SlideInOut
      directionIn="right"
      directionOut="left"
      ref={ref}
      {...props}
      mountOnEnter
      unmountOnExit
    />
  );
});
