import { FC } from "react";
import { useLocation } from "react-router-dom";
import MessageThread from "./messageThread";
import NewMessage from "./newMessage";

interface MessagesProps {}

const Messages: FC<MessagesProps> = () => {
  const location = useLocation<{ messages?: boolean; threadId?: string }>();
  const threadId = location.state.threadId;

  if (threadId) {
    return <MessageThread threadId={threadId} />;
  } else {
    return <NewMessage orderId={null} />;
  }
};

export default Messages;
