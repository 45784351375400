import { Fragment } from "react";
import { PhoneNumber } from "../../../database/siteSettings";
import { DialogContent, Typography } from "@material-ui/core";
import useAccountInfo from "../../Main/useAccountInfo";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { useTranslation } from "react-i18next";
import CommCheckbox from "../../Dashboard/Account/commCheckbox";
import { useOptimisticUpdate } from "../../../utilities/useOptimisticUpdate";
import DoneIcon from "@material-ui/icons/DoneTwoTone";

type Options = {
  email?: string;
  phone?: PhoneNumber | null;
};
const CommSettings = ({ email, phone }: Options) => {
  const siteSettings = useSiteSettings();
  const { t } = useTranslation();
  const realAccountInfo = useAccountInfo();
  const [accountInfo, setOptimisticUpdate] =
    useOptimisticUpdate(realAccountInfo);

  return (
    <DialogContent className="smartWidthMd">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2" color="textSecondary">
          {t("store.orders.byEmail")}
        </Typography>
        &nbsp;
        {email && <DoneIcon fontSize="small" color="secondary" />}
      </div>

      <Typography color="textPrimary">{email}</Typography>
      {siteSettings.communication.messages.useSMS && (
        <Fragment>
          <div style={{ marginTop: "max(1em, 1vw)" }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color="textSecondary">
              {t("store.orders.bySMS")}
            </Typography>
            &nbsp;
            {accountInfo.notifications.customer.sms &&
              !accountInfo.contactInfo.smsOptOut && (
                <DoneIcon fontSize="small" color="secondary" />
              )}
          </div>

          <CommCheckbox
            accountInfo={accountInfo}
            setOptimisticUpdate={setOptimisticUpdate}
            category="customer"
            commType="sms"
          />
        </Fragment>
      )}
      {siteSettings.communication.messages.usePush && (
        <Fragment>
          <div style={{ marginTop: "max(1em, 1vw)" }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color="textSecondary">
              {t("store.orders.byPush")}
            </Typography>
            &nbsp;
            {accountInfo.notifications.customer.push && (
              <DoneIcon fontSize="small" color="secondary" />
            )}
          </div>

          <CommCheckbox
            accountInfo={accountInfo}
            setOptimisticUpdate={setOptimisticUpdate}
            category="customer"
            commType="push"
          />
        </Fragment>
      )}
    </DialogContent>
  );
};

export default CommSettings;
