import { FC, useEffect } from "react";
import * as React from "react";
import "../css/about.css";
import Dompurify from "dompurify";
import { PageTemplate } from "../../../database/page";
import "react-quill/dist/quill.snow.css";
import Container from "@material-ui/core/Container";
import { getFontsInRichText } from "../../../utilities/getFontsInRichText";
import { loadFonts } from "../../../utilities/fontLoader";

interface RichTextPageProps {
  template: PageTemplate & { type: "richText" };
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const RichTextPage: FC<RichTextPageProps> = ({ template, setMainBgImage }) => {
  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fonts = getFontsInRichText(template.content);
    loadFonts(fonts);
  }, [template.content]);

  return (
    <Container maxWidth="lg" style={{ marginTop: "2vh", textAlign: "left" }}>
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: Dompurify.sanitize(template.content.trim()),
        }}
      ></div>
    </Container>
  );
};

export default RichTextPage;
