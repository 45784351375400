import { optionType } from "./option";
import { CatalogItem_WithDefaults } from "./catalogItem";

export interface CartItemCollection {
  [cartItemId: string]: CartItem;
}

/**
 * An item in the cart to be purchased
 */
export interface CartItem {
  /** Version of the data. Distinguishes it from v1 cart items */
  _version: 2;
  /** id of this cart item */
  cartItemId: string;
  catalogItem: CatalogItem_WithDefaults;
  /**
   * Optional identifier to group this with other cart items as
   * part of a meal or suborder.
   */
  belongsTo?: string;
  /**
   * How many to buy. If some need different options/instructions,
   * then split into multiple cartItems
   */
  count: number;
  /** Added by user to be more specific about their order */
  instructions?: string;
  /** Added by user to be more specific about their order */
  optionSelections?: OptionSelection[];
  /**
   * Added by admin to indicate this is complete. In most cases,
   * this will be 0 or equal to count. A different number indicates
   * that only some of them are complete.
   */
  checked: number;
  /** Indicates that this was created by an admin, not a user */
  byAdmin?: boolean;
}

/**
 * Merges together a CartItem with any additional data found in
 * subsequent Revisions of the order.
 */
export interface CartItemWithRevisions extends CartItem {
  /**
   * The number of items that were unable to be fulfilled. This
   * number should be <= count;
   */
  unable: number;
}

/**
 * Data about what items are selected.
 * */
export type OptionSelection = ListOptionSelection | NumberOptionSelection;

interface BaseOptionSelection {
  optionId: string;
}

export interface ListOptionSelection extends BaseOptionSelection {
  /**
   * This type property used to not exist (as everything was a list), so old
   * database entries have undefined
   */
  type?: optionType.LIST;
  selectedItems: {
    [id: string]: boolean | undefined;
  };
}

export const isListOptionSelection = (
  selection?: OptionSelection
): selection is ListOptionSelection => {
  return !!(
    selection &&
    (selection.type === undefined || selection.type === optionType.LIST)
  );
};

export interface NumberOptionSelection extends BaseOptionSelection {
  type: optionType.NUMBER;
  value: number;
}

export const isNumberOptionSelection = (
  selection?: OptionSelection
): selection is NumberOptionSelection => {
  return !!(selection?.type === optionType.NUMBER);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * Types below this line are deprecated. They were used in previous versions
 * and are kept in the codebase to have proper types for code that needs to
 * migrate from the old data structure to the new.
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Data about what items are selected.
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 *
 * @deprecated
 */
export type DatabaseOptionSelection =
  | DatabaseListOptionSelection
  | DatabaseNumberOptionSelection
  | DatabaseUnitOptionSelection;

/**
 * @deprecated
 */
export interface DatabaseListOptionSelection extends ListOptionSelection {
  /** Sum of all the itemPrices */
  price: number;
  /** Price of the individual items at time of purchase */
  itemPrices: {
    [id: string]: number;
  };
}

/**
 * @deprecated
 */
export interface DatabaseNumberOptionSelection extends NumberOptionSelection {
  /**
   * The amount this changed the total cost
   */
  price: number;
}

/**
 * Group of cart items
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 *
 * @deprecated
 */
export interface DatabaseCartItemCollection {
  [cartItemId: string]: DatabaseCartItem_v1;
}

/**
 * An item in the cart to be purchased
 *
 * This type is used when building the cart. For storing in the
 * database, use DatabaseCartItem
 *
 * @deprecated
 */
export interface CartItem_v1 {
  /** version field was not added until v2 */
  _version?: never;
  /** id to find entry in the cart */
  cartItemId: string;
  /** id to find the corresponding item in store.items */
  itemId: string;
  /** Added by user to be more specific about their order */
  instructions?: string;
  /** Added by user to be more specific about their order */
  optionSelections?: (OptionSelection | UnitOptionSelection)[];
  /** Added by admin to indicate this is complete */
  checked?: boolean;
  /** Added by admin to indicate this cannot be fulfilled */
  unable?: boolean;
  /** Indicates that this was created by an admin, not a user */
  byAdmin?: boolean;
  /**
   * Indicates that we're waiting on the customer to agree to an
   * upcharge payment. If they agree, this flag gets cleared.
   * If they decline, this item gets removed from the order.
   * */
  awaitingUpcharge?: boolean;
}

/**
 * Any item that was purchased
 *
 * This type is stored in the database. When building the
 * cart, use CartItem instead.
 *
 * @deprecated
 */
export interface DatabaseCartItem_v1 extends CartItem_v1 {
  /** Price of the item at the time of purchase */
  basePrice: number;
  /** Price of the item at the time of purchase, taking into account all selected options */
  priceWithOptions: number;
  /** Tax rate that was applied at the time of purchase */
  taxPercentage: number;
  /** Added by user to be more specific about their order */
  optionSelections?: DatabaseOptionSelection[];
}

/**
 * An order is split up into one or more suborders. Stores
 * can toggle a flag to say whether there's just one or if
 * more are allowed.
 *
 * This type is used when building the cart. For storing in
 * the database use DatabaseSuborder
 *
 * @deprecated
 */
export interface Suborder_v1 {
  name: string;
  suborderId: string;
  items: CartItemCollection_v1;
}

/**
 * An order is split up into one or more suborders. Stores
 * can toggle a flag to say whether there's just one or if
 * more are allowed.
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 *
 * @deprecated
 */
export interface DatabaseSuborder extends Suborder_v1 {
  items: DatabaseCartItemCollection;
}

/**
 * Group of suborders.
 *
 * Used client side while assembling the order.
 *
 * @deprecated
 */
export interface SuborderCollection_v1 {
  [suborderId: string]: Suborder_v1;
}

/**
 * Group of suborders
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 *
 * @deprecated
 */
export interface DatabaseSuborderCollection {
  [suborderId: string]: DatabaseSuborder;
}

/**
 * Group of cart items.
 *
 * Used client side while assembling the order.
 *
 * @deprecated
 */
export interface CartItemCollection_v1 {
  [cartItemId: string]: CartItem_v1;
}

/**
 * Units used to be part of the options array, instead of a standalong property
 *
 * @deprecated
 */
export interface UnitOptionSelection extends BaseOptionSelection {
  type: "unit";
  value: number;
}

export interface DatabaseUnitOptionSelection extends UnitOptionSelection {
  /**
   * Base price multiplies every time this many units is selected.
   * total price = (base price / price denominator) * amount
   *
   * For example, if base price is 10 euros, 500 grams are sold, and
   * priceDenominator is 100, then the total cost is 50 euros
   */
  priceDenominator: number;
}
