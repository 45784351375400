import { FC } from "react";
import { useSiteSettings } from "../../../../../customization/siteSettingsContext";
import { FancyText } from "../../../../../database/fancyText";
import NavLinksMenu from "./navLinksMenu";
import SmartMenu from "../../../../Dashboard/Components/smartMenu";
import { ColorConfig } from "../../../../widgets/colorConfig";

/**
 * Props for normal use of the widget
 */
export interface NavLinksWidgetProps {
  fancyText: FancyText;
  elements?: "textOnly" | "iconOnly" | "textAndIcon";
  variant: "tabs" | "menu";
  overflowStyle?: "normal" | "parent";
  indicatorThickness?: number;
  indicatorRadius?: number;
  highlightColor: ColorConfig | undefined;
  scrollType: "multiple" | "single";
  itemsInView: 0 | 1;
}

/**
 * Props used when editing on the dashboard
 */
export interface NavLinksWidgetEditorOverrides {
  pageId?: string | false;
  onTabChanged?: (pageId: string) => void;
}

const NavLinksWidget: FC<NavLinksWidgetProps & NavLinksWidgetEditorOverrides> =
  (props) => {
    const { pages } = useSiteSettings();

    if (pages.length <= 1) {
      return null;
    }

    return props.variant === "tabs" ? (
      // <NavLinksTabs {...props} />
      <SmartMenu {...props} />
    ) : (
      <NavLinksMenu {...props} />
    );
  };

export default NavLinksWidget;
