import { RowWidgetProps } from "./widgets/rowWidget";
import { TextOrIconWidgetProps } from "./widgets/textOrIconWidget";
import { NavLinksWidgetProps } from "./widgets/navLinksWidget/navLinksWidget";
import { WealthyTextWidgetProps } from "./widgets/wealthyTextWidget";
import { ButtonWidgetProps } from "./widgets/buttonWidget/buttonWidget";
import { ImageWidgetProps } from "./widgets/imageWidget";
import { CatalogItemWidgetProps } from "./widgets/catalogItemWidget";
import { CustomWidgetProps } from "./widgets/customWidget";
import { PresetTextWidgetProps } from "./widgets/presetTextWidget";
import { FilterLabelsWidgetProps } from "./widgets/filterLabelsWidget";
import { TextLabelsWidgetProps } from "./widgets/textLabelsWidget";
import { NavDrawerWidgetProps } from "./widgets/navDrawerWidget/navDrawerWidget";
import { EmptySpaceWidgetProps } from "./widgets/emptySpaceWidget";
import { CatalogGridWidgetProps } from "./widgets/catalogGridWidget";
import { WidgetPickerWidgetProps } from "./widgets/widgetPickerWidget";

export enum PageWidgetType {
  row = "row",
  textOrIcon = "logo", //TITLE WIDGET
  presetText = "presetText", //PREFILLED TITLE WIDGET SUCH AS CATALOG ITEM PRICE / NAME
  wealthyText = "wealthyText", //PARAGRAPH WIDGET
  navLinks = "navLinks", //NAVIGATION MENU PREFILLED WITH PAGE NAMES
  button = "button",
  image = "image",
  customWidget = "customWidget",
  catalogItem = "catalogItem",
  catalogGrid = "catalogGrid",
  textLabels = "textLabels", //CATALOG ITEM TEXT LABELS (CREATED IN CATALOG)
  filterLabels = "filterLabels", //CATALOG ITEM SEARCHABLE LABELS (CREATED IN CATALOG)
  navDrawer = "navDrawer",
  cartIcon = "cartIcon",
  favoriteIcon = "favoriteIcon",
  emptySpace = "emptySpace",
  widgetPicker = "widgetPicker",
}

export function cellIs<T extends PageWidgetType>(
  type: T,
  cell: PageCell
): cell is PageCell<T> {
  return cell.type === type;
}

export type PageCell<T extends PageWidgetType = PageWidgetType> = {
  id: string;
  type: T;
  props: PageWidgetPropMap[T];
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
  collapsedByDefault?: boolean;
};

// A mapping of which widget expects which props
export interface PageWidgetPropMap {
  [PageWidgetType.row]: RowWidgetProps;
  [PageWidgetType.textOrIcon]: TextOrIconWidgetProps;
  [PageWidgetType.presetText]: PresetTextWidgetProps;
  [PageWidgetType.wealthyText]: WealthyTextWidgetProps;
  [PageWidgetType.navLinks]: NavLinksWidgetProps;
  [PageWidgetType.button]: ButtonWidgetProps;
  [PageWidgetType.image]: ImageWidgetProps;
  [PageWidgetType.customWidget]: CustomWidgetProps;
  [PageWidgetType.catalogItem]: CatalogItemWidgetProps;
  [PageWidgetType.catalogGrid]: CatalogGridWidgetProps;
  [PageWidgetType.textLabels]: TextLabelsWidgetProps;
  [PageWidgetType.filterLabels]: FilterLabelsWidgetProps;
  [PageWidgetType.navDrawer]: NavDrawerWidgetProps;
  [PageWidgetType.cartIcon]: ButtonWidgetProps;
  [PageWidgetType.favoriteIcon]: ButtonWidgetProps;
  [PageWidgetType.emptySpace]: EmptySpaceWidgetProps;
  [PageWidgetType.widgetPicker]: WidgetPickerWidgetProps;
}
