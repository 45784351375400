import {
  Fragment,
  createContext,
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { DialogActions, Icon, IconButton, Tooltip } from "@material-ui/core";
import { History } from "history";
import { history } from "../../App/history";
import { useTranslation } from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import MUIPopover from "./mui-popover";
import ROUTES from "../../../utilities/constants/routes";
import SignedInIcon from "@material-ui/icons/Person";
import SignedOutIcon from "@material-ui/icons/HowToReg";
import OverflowIcon from "@material-ui/icons/ArrowDropDown";
import DBheaderMenu from "./dBheaderMenu";
import PublishAppBar from "./publishAppBar";
import MenuIcon from "@material-ui/icons/Menu";
import { useGridBashFirebase } from "../../../Firebase/context";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";
import type firebase from "firebase";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import MUIDialog from "./mui-dialog";
import Contact from "../../GridBash/Home/contact";
import useWindowResize from "../../../utilities/useWindowResize";
import WarningIcon from "@material-ui/icons/Warning";
import { useActionNeeded } from "../Overview/actionNeededSummary";
import { useSettingsApi } from "../Settings/settingsApiContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.dark,
    },
    onColor: {
      color: theme.palette.primary.contrastText,
    },
  })
);

/**
 * This context allows setting the zIndex of the header from
 * anywhere in the dashboard
 */
export const DBHeaderContext = createContext<{
  zIndex: number | undefined;
  setZIndex: Dispatch<SetStateAction<number | undefined>>;
}>({
  zIndex: 1,
  setZIndex: () => {},
});

interface DBheaderProps {
  toggleDrawer: () => void;
  user: firebase.User | null;
  showPublish?: boolean;
}

const DBheader: FC<DBheaderProps> = ({
  toggleDrawer,
  user,
  showPublish = true,
}) => {
  const { t } = useTranslation();
  const dimensions = useWindowResize();
  const firebase = useGridBashFirebase();
  const { zIndex } = useContext(DBHeaderContext);

  return (
    <Fragment>
      <div
        className="dBHeaderOuter sticky anim_fadeIn_0503"
        style={{
          color: "white",
          fontWeight: "bold",
          opacity: 0,
          /**
           * ZINDEX SET TO 1 BY DEFAULT TO FORCE OVERVIEW PAGE
           * TO SLIDE BEHIND THIS HEADER
           */
          zIndex,
          backgroundColor: "white",
        }}
      >
        {dimensions.w < 768 ? (
          <MobileHeader
            toggleDrawer={toggleDrawer}
            user={user}
            showPublish={showPublish}
          />
        ) : (
          <DesktopHeader showPublish={showPublish} />
        )}
        <MUIDialog route="" stateMatch={(state) => Boolean(state.support)}>
          <Contact user={user} />
        </MUIDialog>
        <MUIDialog route="" stateMatch={(state) => Boolean(state.signOut)}>
          <DialogTitle className="smartWidthMd">
            {t("store.account.signOut")}
          </DialogTitle>
          <DialogActions>
            <Button
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                history.goBack();
              }}
            >
              {t("store.cancel")}
            </Button>
            <Button
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                firebase.auth.signOut();
                history.replace("");
              }}
            >
              {t("store.confirm")}
            </Button>
          </DialogActions>
        </MUIDialog>
      </div>
    </Fragment>
  );
};

const MobileHeader: FC<DBheaderProps> = ({
  toggleDrawer,
  showPublish,
  user,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const actionNeeded = useActionNeeded();
  const { settingsDirty, privateSettingsDirty } = useSettingsApi();

  const anchor1 = useRef<HTMLDivElement | null>(null);

  return (
    <div
      //  headerBarHeight CLASS IS ALSO USED TO SET HEIGHTS
      // ELSEWHERE. REMOVING THE CLASS HERE WOULD REQUIRE
      // MAKING CHANGES ELSEWHERE
      className={`headerBarHeight dBHeaderInner mobileOnlyFlex ${classes.root} ${classes.onColor}`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "calc(100% - 4em)",
        }}
      >
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            toggleDrawer();
          }}
          className="dBnavBarHdrItem"
        >
          <MenuIcon />
        </IconButton>
        <Typography>{t("common.gridbash")}</Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {actionNeeded && !settingsDirty && !privateSettingsDirty && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              const path = ROUTES.DASHBOARD_OVERVIEW;
              if (!window.location.pathname.includes(path)) {
                history.push(path);
              }
            }}
          >
            <WarningIcon color="error" />
          </IconButton>
        )}
        {showPublish && <PublishAppBar />}
        <div style={{ marginLeft: "1em" }} />
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            const { location } = history as History<{} | undefined>;
            history.push(location.pathname, {
              ...location.state,
              support: true,
            });
          }}
        >
          <ContactSupportIcon />
        </IconButton>
        <div style={{ marginLeft: "1em" }} />
        <div ref={anchor1}>
          <IconButton
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              const { location } = history as History<{} | undefined>;
              history.push(location.pathname, {
                ...location.state,
                signOut: true,
              });
            }}
          >
            {!user ? <SignedInIcon /> : <SignedOutIcon />}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const DesktopHeader: FC<{ showPublish: boolean }> = ({ showPublish }) => {
  const { t } = useTranslation();
  const location = useLocation<{}>();
  const classes = useStyles();
  const actionNeeded = useActionNeeded();
  const { settingsDirty, privateSettingsDirty } = useSettingsApi();
  const dimensions = useWindowResize("dBheader");

  return (
    <div
      // headerBarHeight CLASS IS ALSO USED TO SET HEIGHTS
      // ELSEWHERE. REMOVING THE CLASS HERE WOULD REQUIRE
      // MAKING CHANGES ELSEWHERE
      className="headerBarHeight desktopOnlyBlock"
    >
      <div
        className={
          location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
            ? `dBHeaderDesktop ${classes.root} ${classes.onColor}`
            : "dBHeaderDesktop"
        }
        style={{
          padding: "0.5em",
          position: "relative",
          display: "flex",

          justifyContent: dimensions.w > 1000 ? "center" : "flex-end",
        }}
      >
        <div style={{ position: "absolute", left: "2vw" }}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              history.push(ROUTES.DASHBOARD);
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Icon
              className={
                location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
                  ? classes.onColor
                  : undefined
              }
            >
              dashboard_customize
            </Icon>
            &nbsp;
            <Typography
              variant="h6"
              className={
                location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
                  ? classes.onColor
                  : undefined
              }
              style={{
                whiteSpace: "normal",
                textAlign: "left",
                textTransform: "none",
                wordBreak: "break-word",
                maxWidth: "33vw",
              }}
            >
              {t("common.gridbash")}
            </Typography>
          </div>
        </div>
        <Button
          className={
            location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
              ? classes.onColor
              : undefined
          }
          disableRipple
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            history.push(ROUTES.DASHBOARD_BUILDER);
          }}
          style={{
            backgroundColor: location.pathname.includes(
              ROUTES.DASHBOARD_BUILDER
            )
              ? "rgba(0, 0, 0, 0.04)"
              : undefined,
          }}
        >
          {t("dashboard.navigation.builder")}
        </Button>
        &nbsp;
        <Button
          className={
            location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
              ? classes.onColor
              : undefined
          }
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            history.push(ROUTES.DASHBOARD_CATALOG);
          }}
          style={{
            backgroundColor: location.pathname.includes(
              ROUTES.DASHBOARD_CATALOG
            )
              ? "rgba(0, 0, 0, 0.04)"
              : undefined,
          }}
        >
          {t("dashboard.navigation.catalogs")}
        </Button>
        &nbsp;
        <Button
          className={
            location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
              ? classes.onColor
              : undefined
          }
          disableRipple
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            history.push(ROUTES.DASHBOARD_ORDERS);
          }}
          style={{
            backgroundColor: location.pathname.includes(ROUTES.DASHBOARD_ORDERS)
              ? "rgba(0, 0, 0, 0.04)"
              : undefined,
          }}
        >
          {t("dashboard.navigation.orders")}
        </Button>
        &nbsp;
        <Button
          className={
            location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
              ? classes.onColor
              : undefined
          }
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            history.push(ROUTES.DASHBOARD_MESSAGES);
          }}
          style={{
            backgroundColor: location.pathname.includes(
              ROUTES.DASHBOARD_MESSAGES
            )
              ? "rgba(0, 0, 0, 0.04)"
              : undefined,
          }}
        >
          {t("dashboard.navigation.messages")}
        </Button>
        &nbsp;
        {actionNeeded && !settingsDirty && !privateSettingsDirty && (
          <Tooltip title="Complete Subscription">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                const path = ROUTES.DASHBOARD_OVERVIEW;
                if (!window.location.pathname.includes(path)) {
                  history.push(path);
                }
              }}
            >
              <WarningIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
        {showPublish && <PublishAppBar />}
        <Tooltip title="Get Support">
          <IconButton
            color="inherit"
            className={
              location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
                ? classes.onColor
                : undefined
            }
            onClick={(e) => {
              e.stopPropagation();
              history.push(location.pathname, {
                ...location.state,
                support: true,
              });
            }}
          >
            <ContactSupportIcon />
          </IconButton>
        </Tooltip>
        <OverflowButton />
        <span style={{ paddingRight: "0.25em" }}>&nbsp;</span>
      </div>
    </div>
  );
};

const OverflowButton = () => {
  const classes = useStyles();
  const location = useLocation<{ headerMore?: boolean }>();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <Fragment>
      <IconButton
        disableRipple
        color="inherit"
        className={
          location.pathname.includes(ROUTES.DASHBOARD_OVERVIEW)
            ? classes.onColor
            : undefined
        }
        onClick={(e) => {
          e.stopPropagation();
          if (location.state?.headerMore !== true)
            history.push(location.pathname, {
              ...location.state,
              headerMore: true,
            });
          setAnchor(e.currentTarget);
        }}
      >
        <OverflowIcon />
      </IconButton>
      {anchor && (
        <MUIPopover
          anchorEl={anchor}
          route=""
          stateMatch={(state) => Boolean(state.headerMore)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <DBheaderMenu />
        </MUIPopover>
      )}
    </Fragment>
  );
};

export default memo(DBheader);
