import { createContext } from "react";
import { AccountInfo } from "./useMain";

interface MainContextType {
  accountInfo: AccountInfo;
}

export const MainContext = createContext<MainContextType>(
  {} as MainContextType
);
