import { AppBar, InputAdornment } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
  Fragment,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { history } from "../../App/history";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { SearchLabel } from "./searchLabel";
import { CatalogItem_Database } from "../../../database/catalogItem";
import Dinero from "dinero.js";
import { useItemSearch } from "../../Dashboard/Catalog/useItemSearch";
import { FixedSizeGrid, GridChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useMeasure } from "../../../utilities/useMeasure";
import CancelIcon from "@material-ui/icons/Cancel";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import useCartApi from "../../CartManager/useCartApi";
import useToast from "../../Main/useToast";
import AddToCart from "../Orders/addToCart";
import { convertItemAndAddDefaults } from "../../../utilities/typeConversions";
import ROUTES from "../../../utilities/constants/routes";

const minItemWidth = 160;
const itemGap = 8;

interface SearchProps {
  setMainBgImage: Dispatch<SetStateAction<boolean>>;
}

const Search: FC<SearchProps> = ({ setMainBgImage }) => {
  const { t } = useTranslation();
  const { labels } = useSiteSettings();

  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedLabels = useMemo(() => {
    return Object.values(labels).sort((a, b) =>
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    );
  }, [labels]);

  const [selectedLabels, setSelectedLabels] = useState<Record<string, true>>(
    {}
  );

  const [searchString, setSearchString] = useState("");

  const { items, hasMoreResults, getMoreResults } = useItemSearch({
    searchString,
    selectedLabels,
    pageSize: 20,
    forStore: true,
  });

  const labelDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const labelDivCurrent = labelDiv.current;
    if (labelDivCurrent) {
      labelDivCurrent.scrollLeft = labelDivCurrent.scrollWidth;
      const id = setTimeout(() => {
        labelDivCurrent.scrollTo(0, 0);
      }, 200);
      return () => clearTimeout(id);
    }
  }, []);

  const [containerRef, bounds] = useMeasure();
  const { width, height } = bounds;
  const columnCount = Math.max(1, Math.floor(width / (minItemWidth + itemGap)));
  const columnWidth = width / columnCount;
  const rowCount = Math.ceil(items.length / columnCount);
  const rowHeight = 75;

  return (
    <Fragment>
      <Container
        ref={containerRef}
        maxWidth="lg"
        style={{
          transition: "all 0.3s ease",
          /**
           * 160PX FOR SEARCH SECTION WITH LABELS
           * 24PX FOR MARGINTOP IN SEARCH SECTION WITH LABELS
           * 66PX FOR APPBAR HEADER
           */
          height: "calc(100vh - 160px - 66px - 24px)",
        }}
        className="anim_moveUp_small_0300"
      >
        <div style={{ height: "160px", overflow: "hidden", marginTop: "24px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputBase
              startAdornment={
                <InputAdornment position="start">
                  {searchString.length > 0 ? (
                    <CloseIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchString("");
                      }}
                    />
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              }
              style={{
                flex: 1,
                minWidth: 0,
                borderBottom: "1px solid gray",
              }}
              placeholder={t("store.orders.search")}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </div>
          {sortedLabels.length > 0 && (
            <Fragment>
              <div
                style={{
                  margin: "1em auto 0 auto",
                  textAlign: "center",
                  overflowX: "auto",
                  overflowY: "hidden",
                  scrollBehavior: "smooth",
                }}
              >
                <div
                  ref={labelDiv}
                  style={{
                    display: "inline-flex",
                  }}
                >
                  {sortedLabels.map((label) => {
                    return (
                      <SearchLabel
                        key={label.labelId}
                        label={label}
                        selected={Boolean(selectedLabels[label.labelId])}
                        onClick={() => {
                          setSelectedLabels((prev) => {
                            let next = { ...prev };
                            if (!prev[label.labelId]) {
                              next[label.labelId] = true;
                            } else {
                              delete next[label.labelId];
                            }
                            return next;
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: Object.keys(selectedLabels).length > 0 ? 1 : 0,
                    transition: "all 0.5s ease",
                    width: "100%",
                    marginTop: "12px",
                  }}
                >
                  <Typography style={{ width: "6em" }} align="right">
                    {Object.keys(selectedLabels).length > 9
                      ? "9+ selected"
                      : `${Object.keys(selectedLabels).length} selected`}
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedLabels({});
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </div>
            </Fragment>
          )}
        </div>

        <InfiniteLoader
          isItemLoaded={(index) => index < items.length}
          itemCount={hasMoreResults ? items.length + 1 : items.length}
          loadMoreItems={getMoreResults}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeGrid
              columnCount={columnCount}
              columnWidth={columnWidth}
              height={height}
              width={width}
              rowCount={rowCount}
              rowHeight={rowHeight}
              itemData={{
                items,
                columnCount,
                columnWidth,
              }}
              onItemsRendered={({
                overscanRowStartIndex,
                overscanRowStopIndex,
                visibleRowStartIndex,
                visibleRowStopIndex,
              }) => {
                return onItemsRendered({
                  overscanStartIndex: overscanRowStartIndex * columnCount,
                  overscanStopIndex:
                    (overscanRowStopIndex + 1) * columnCount - 1,
                  visibleStartIndex: visibleRowStartIndex * columnCount,
                  visibleStopIndex: (visibleRowStopIndex + 1) * columnCount - 1,
                });
              }}
              ref={ref}
            >
              {Cell}
            </FixedSizeGrid>
          )}
        </InfiniteLoader>
      </Container>
    </Fragment>
  );
};

interface CellData {
  items: CatalogItem_Database[];
  columnCount: number;
}

const Cell = (props: GridChildComponentProps) => {
  const { t } = useTranslation();
  const cartApi = useCartApi();
  const toast = useToast();
  const { labels, taxPercentage, priceIncludesTax, currency } =
    useSiteSettings();

  const { columnIndex, rowIndex, style } = props;
  const { items, columnCount } = props.data as CellData;
  const index = rowIndex * columnCount + columnIndex;
  const item = items[index] as CatalogItem_Database | undefined;

  const itemWithDefaults = useMemo(() => {
    return (
      item &&
      convertItemAndAddDefaults(
        item,
        labels,
        taxPercentage,
        priceIncludesTax,
        currency
      )
    );
  }, [currency, item, labels, priceIncludesTax, taxPercentage]);

  if (!itemWithDefaults) {
    return null;
  }

  return (
    <Fragment>
      <MUIDialog route={`${ROUTES.SEARCH}/details/${itemWithDefaults.itemId}`}>
        <AddToCart
          catalogItem={itemWithDefaults}
          submitButton={t("store.orders.addToCart")}
          onSubmit={(count, instructions, selectedOptions) => {
            cartApi.addItem(
              itemWithDefaults,
              count,
              instructions,
              selectedOptions
            );
            toast({
              duration: 1200,
              message: t("store.orders.addedToCart"),
              color: "error",
            });
          }}
        />
      </MUIDialog>
      <AppBar
        position="static"
        key={index}
        style={{
          padding: "0.5em",
          borderRadius: "4px",
          ...style,
          left: Number(style.left) + (columnIndex / columnCount) * itemGap,
          width:
            columnCount === 1 ? style.width : Number(style.width) - itemGap,
          height: Number(style.height) - itemGap,
        }}
        className="anim_fadeIn_0300 searchResultItem"
        onClick={() => {
          const path = `${ROUTES.SEARCH}/details/${itemWithDefaults.itemId}`;
          if (!window.location.pathname.includes(path)) {
            history.push(path);
          }
        }}
      >
        <Typography noWrap>{itemWithDefaults.name}</Typography>
        <Typography noWrap variant="caption">
          {Dinero({ amount: itemWithDefaults.price }).toFormat()}
        </Typography>
      </AppBar>
    </Fragment>
  );
};

export default Search;
