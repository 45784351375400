import { memo, FC, useMemo } from "react";
import Store from "../Store/store";
import { MainContext } from "./mainContext";
import { useSiteSettings } from "../../customization/siteSettingsContext";
import CartApiProvider from "../CartManager/cartApiProvider";
import RenderBlocker from "./renderBlocker";
import StoreTheme from "../Store/storeTheme";
import { useMain } from "./useMain";
import { ToastProvider } from "./useToast";

export const srcSetString = (urls: { [key: string]: string } | undefined) => {
  if (!urls) return "";
  let imageString = "";
  Object.entries(urls).forEach((url) => {
    imageString += `${url[1]} ${url[0]}w, `;
  });
  return imageString;
};

const Main: FC = memo(() => {
  const siteSettings = useSiteSettings();
  const { theme } = siteSettings;

  const { accountInfo, dialogs } = useMain();

  const shared = useMemo(() => ({ accountInfo }), [accountInfo]);

  return (
    <StoreTheme>
      <MainContext.Provider value={shared}>
        <ToastProvider>
          <CartApiProvider>
            <RenderBlocker>
              <div
                className="App"
                style={{
                  fontFamily: theme.appFont,
                }}
              >
                <div id="react-portal" />
                <Store />
                {dialogs}
              </div>
            </RenderBlocker>
          </CartApiProvider>
        </ToastProvider>
      </MainContext.Provider>
    </StoreTheme>
  );
});

export default Main;
