import { Typography } from "@material-ui/core";
import { FC } from "react";
import CopyIcon from "@material-ui/icons/FileCopy";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import AddIcon from "@material-ui/icons/AddBox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ImageIcon from "@material-ui/icons/Image";

interface CatalogUIProps {}

const CatalogUI: FC<CatalogUIProps> = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gridTemplateAreas: `
"AppBar AppBar"
"ItemDetails ItemImage"`,
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",

        marginRight: "10px",
        marginLeft: "10px",
        maxHeight: "271px",
        overflow: "hidden",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          gridArea: "AppBar",
          backgroundColor: "#0c343d",
          color: "white",
          display: "flex",
          alignItems: "center",
          padding: "0.5em",
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <CopyIcon style={{ fontSize: "0.8em" }} />
        &nbsp;&nbsp;
        <AddIcon style={{ fontSize: "0.8em" }} />
        &nbsp;&nbsp;
        <VisibilityIcon style={{ fontSize: "0.8em" }} />
        &nbsp;&nbsp;
        <ToggleOffIcon style={{ fontSize: "0.8em" }} />
      </div>
      <div style={{ gridArea: "ItemDetails", padding: "0.5em" }}>
        <Typography align="left">Item</Typography>
        <div style={{ marginLeft: "0.25em" }}>
          <Typography
            align="left"
            style={{ fontSize: "0.7em", marginBottom: "0.5em" }}
          >
            Sunrise Omelette
          </Typography>
          <Typography
            align="left"
            style={{ fontSize: "0.7em", marginBottom: "0.5em" }}
          >
            $8.50 each
          </Typography>
          <Typography
            align="left"
            style={{ fontSize: "0.7em", marginBottom: "0.5em" }}
          >
            4 variations
          </Typography>
          <Typography align="left" style={{ fontSize: "0.8em" }}>
            Searchable
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.25em",
            }}
          >
            <AddIcon style={{ fontSize: "0.8em" }} />
            &nbsp;
            <div
              style={{
                backgroundColor: "#896443",
                padding: "0.25em",
                borderRadius: "4px",
                color: "white",
              }}
            >
              <Typography style={{ fontSize: "0.7em" }}>GF</Typography>
            </div>
            &nbsp;
            <div
              style={{
                backgroundColor: "#524822",
                padding: "0.25em",
                borderRadius: "4px",
                color: "white",
              }}
            >
              <Typography style={{ fontSize: "0.7em" }}>NEW</Typography>
            </div>
          </div>

          <Typography
            align="left"
            style={{ fontSize: "0.8em", marginTop: "1em" }}
          >
            Text Labels
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.25em",
            }}
          >
            <AddIcon style={{ fontSize: "0.8em" }} />
            &nbsp;
            <div
              style={{
                border: "1px solid gray",
                padding: "0.25em",
                borderRadius: "4px",
              }}
            >
              <Typography style={{ fontSize: "0.7em" }}>
                785 Calories
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }}>&nbsp;</div>
        </div>
      </div>
      <div style={{ gridArea: "ItemImage" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "50%",
              height: "40%",
              border: "1px solid lightgray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <ImageIcon style={{ opacity: "0.5" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogUI;
