// These option types should all go in the customOption file, not here.
export enum optionType {
  NONE_YET = "none",
  LIST = "list",
  NUMBER = "number",
}

export interface OptionCollection {
  [optionId: string]: Option | undefined;
}
/**
 * Custom options allow the admin to create variations of an item
 */
export type Option = ListOption | NumberOption;

interface BaseOption {
  name: string;
  type: optionType;
  optionId: string;
  required: boolean;
}

export enum listSelectionType {
  SINGLE = "single",
  MULTIPLE = "multiple",
}
export enum listOptionPriceMode {
  NONE = "none",
  ADD = "add",
}
export interface IncompleteOption extends BaseOption {
  type: optionType.NONE_YET;
}

export enum numberOptionPriceMode {
  ADD = "add",
}

/**
 * A variation where the customer can specify a number of something
 */
export interface NumberOption extends BaseOption {
  type: optionType.NUMBER;
  price: number;
  priceDenominator: number;
  unit?: string;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  increment: number;
}

export const isNumberOption = (
  option?: Option | IncompleteOption
): option is NumberOption => {
  return !!(option?.type === optionType.NUMBER);
};

/**
 * A variation where the customer can select from a predefined list
 */
export interface ListOption extends BaseOption {
  type: optionType.LIST;
  priceMode: listOptionPriceMode;
  selectionType: listSelectionType;
  /**
   * If they're allowed to pick multiple, then this number indicates the max.
   * If it's undefined, then they can pick unlimited
   */
  selectionLimit?: number;
  items: ListOptionItem[];
}

export const isListOption = (
  option?: Option | IncompleteOption
): option is ListOption => {
  return !!(option?.type === optionType.LIST);
};

export interface ListOptionItem {
  description: string;
  price: number;
  selectedByDefault?: boolean;
  id: string;
}

/**
 * Units used to be done as a custom option. They are now embedded
 * as a separate parameter in catalogItems.
 *
 * @deprecated
 */
export interface UnitOption_deprecated {
  name: string;
  optionId: string;
  required: boolean;
  type: "unit";
  priceDenominator: number;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  increment: number;
}
