import { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import useSiteUser from "../components/UserProvider/useSiteUser";
import { MessageThread } from "../database/message";
import { useSiteFirebase } from "../Firebase/context";
import { storeKey } from "../Firebase/siteFirebase";

export const useLatestMessageThread = () => {
  const { user } = useSiteUser();
  const firebase = useSiteFirebase();

  const [loading, setLoading] = useState(Boolean(user));
  const [latestThread, setLatestThread] = useState<MessageThread>();
  useEffect(() => {
    if (!user) {
      setLoading(false);
      setLatestThread(undefined);
      return;
    }
    return firebase.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("messageThreads")
      .where("userId", "==", user.uid)
      .orderBy("latestMessageTimestamp", "desc")
      .limit(1)
      .onSnapshot(
        (snapshot) => {
          unstable_batchedUpdates(() => {
            setLoading(false);
            if (snapshot.docs.length > 0) {
              setLatestThread(
                snapshot.docs[0].data() as MessageThread | undefined
              );
            } else {
              setLatestThread(undefined);
            }
          });
        },
        (err) => {
          console.error("error getting message thread", err);
          setLoading(false);
        }
      );
  }, [firebase.firestore, user]);

  return [latestThread, loading] as const;
};
