import { DialogActions, FormControl, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import { FC, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Order } from "../../../../database/order";

export interface ManualAdjustmentProps {
  order: Order;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  onCancel: () => void;
}

const ManualAdjustment: FC<ManualAdjustmentProps> = ({
  order,
  amount,
  setAmount,
  reason,
  setReason,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [adjustmentAmount, setAdjustmentAmount] = useState(amount);
  const [adjustmentReason, setAdjustmentReason] = useState(reason);
  // const changed =
  //   adjustmentReason.trim() !== reason.trim() ||
  //   adjustmentAmount.trim() !== amount.trim()

  let changed = false;
  if (
    adjustmentReason.trim().length > 0 &&
    adjustmentReason.trim() !== reason.trim() &&
    adjustmentAmount.length > 0
  ) {
    changed = true;
  } else if (
    adjustmentAmount.trim().length > 0 &&
    adjustmentAmount.trim() !== amount.trim() &&
    adjustmentReason.length > 0
  ) {
    changed = true;
  }

  return (
    <React.Fragment>
      <DialogContent className="smartwidthMd">
        <FormControl fullWidth>
          <TextField
            label={t("dashboard.charges.adjustmentReason")}
            type="text"
            value={adjustmentReason}
            onChange={(e) => {
              setAdjustmentReason(e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: "3vh" }}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label={t("dashboard.charges.adjustmentAmount")}
            type="number"
            value={adjustmentAmount}
            onChange={(e) => {
              setAdjustmentAmount(e.target.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          {t("dashboard.cancel")}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (changed && adjustmentAmount && adjustmentReason) {
              setAmount(adjustmentAmount);
              setReason(adjustmentReason);
              onCancel();
            }
          }}
        >
          {t("dashboard.submit")}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default ManualAdjustment;
