import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import { Fragment, Dispatch, SetStateAction, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSiteSettings } from "../../../../../customization/siteSettingsContext";
import { useFetchItem } from "../../../../../customization/useFetchItem";
import { CatalogItem_WithDefaults } from "../../../../../database/catalogItem";
import { PredefinedPageId } from "../../../../../database/page";
import ROUTES from "../../../../../utilities/constants/routes";
import { useToggleFavorite } from "../../../../../utilities/toggleFavorite";
import { history } from "../../../../App/history";
import useCartApi from "../../../../CartManager/useCartApi";
import MUIDialog from "../../../../Dashboard/Components/mui-dialog";
import useAccountInfo from "../../../../Main/useAccountInfo";
import useToast from "../../../../Main/useToast";
import useSiteUser from "../../../../UserProvider/useSiteUser";
import AddToCart from "../../../Orders/addToCart";
import { WidgetGlobalStateType } from "../../widgetGlobalState";
import { CatalogItemWidgetContext } from "../catalogItemWidget";
import { useWidgetEnvironment } from "../../widgetEnvironment";

export type ButtonAction =
  | InternalLinkAction
  | ExternalLinkAction
  | AddToCartAction
  | ToggleFavoriteAction
  | NavMenuAction
  | CollapseAction;

interface InternalLinkAction {
  type: "internalLink";
  pageId: string;
}

interface ExternalLinkAction {
  type: "externalLink";
  url: string;
}

interface AddToCartAction {
  type: "addToCart";
  // Provide either an item id for an explicit item, or a datasource
  //   to get the value from context.
  itemId: string | null;
  dataSource: "catalogItem" | null;
}

interface ToggleFavoriteAction {
  type: "favorite";
  // Provide either an item id for an explicit item, or a datasource
  //   to get the value from context.
  itemId: string | null;
  dataSource: "catalogItem" | null;
}

interface NavMenuAction {
  type: "navMenu";
  widgetId: string | null;
}

interface CollapseAction {
  type: "collapse";
  widgetId: string | null;
}

export const useExecuteAction = (
  action: ButtonAction | null,
  setWidgetGlobalState: Dispatch<SetStateAction<WidgetGlobalStateType>>
) => {
  const { t } = useTranslation();
  const toast = useToast();
  const cartApi = useCartApi();
  const { pages } = useSiteSettings();
  const env = useWidgetEnvironment();

  // Catalog item can come from two possible places: if we're given a
  //  datasource prop, we get it from context. If we're given an itemId
  //  prop, we fetch it ourselves
  const catalogItemFromContext = useContext(CatalogItemWidgetContext);
  const { item: explicitCatalogItem } = useFetchItem(
    action && "itemId" in action ? action.itemId : null
  );
  let catalogItem: CatalogItem_WithDefaults | null | undefined =
    explicitCatalogItem;
  if (
    !catalogItem &&
    action &&
    "dataSource" in action &&
    action.dataSource === "catalogItem"
  ) {
    catalogItem = catalogItemFromContext;
  }

  const { user } = useSiteUser();
  const accountInfo = useAccountInfo();
  const isFavorite =
    catalogItem && accountInfo.favorites2?.includes(catalogItem.itemId);
  const toggleFavorite = useToggleFavorite();

  const location = useLocation<
    { addToCart?: string; unfavorite?: string } | undefined
  >();

  // TODO: split this into separate functions, because the memoization can
  //   rarely work if it needs to handle all depenednecies for all actions
  const execute = useCallback(() => {
    // TODO: figure out which actions can be supported in preview mode
    if (action?.type === "internalLink") {
      if (env === "preview") {
        return;
      }
      const pageId = action.pageId;
      const page = pages.find((p) => p.pageId === pageId);
      let route: string | undefined;
      if (page) {
        route = `${ROUTES.rootPath}${page?.path}`;
      } else if (pageId === PredefinedPageId.order) {
        route = ROUTES.ORDER;
      } else if (pageId === PredefinedPageId.account) {
        route = ROUTES.ACCOUNT;
      } else if (pageId === PredefinedPageId.favorites) {
        route = ROUTES.FAVORITES;
      } else if (pageId === PredefinedPageId.track) {
        route = ROUTES.TRACK;
      } else if (pageId === PredefinedPageId.message) {
        // TODO: may be able to do router state instead of deep link
        route = ROUTES.MESSAGE_DEEPLINK;
      }

      if (route) {
        history.push(route);
      }
    } else if (action?.type === "externalLink") {
      if (env === "preview") {
        return;
      }
      window.location.href = action.url;
    } else if (action?.type === "addToCart") {
      if (env === "preview") {
        return;
      }
      if (!catalogItem) {
        return;
      }
      if (location.state?.addToCart !== catalogItem.itemId) {
        history.push(location.pathname, {
          ...location.state,
          addToCart: catalogItem.itemId,
        });
      }
    } else if (action?.type === "favorite") {
      if (env === "preview") {
        return;
      }
      if (!catalogItem) {
        return;
      }
      if (!user) {
        toast({
          dialog: true,
          message: t("store.orders.startFavoring"),
        });
      }

      if (isFavorite) {
        if (location.state?.unfavorite !== catalogItem.itemId) {
          history.push(location.pathname, {
            ...location.state,
            unfavorite: catalogItem.itemId,
          });
        }
      } else {
        toggleFavorite(catalogItem.itemId);
      }
    } else if (action?.type === "navMenu") {
      if (env === "preview") {
        return;
      }
      console.log("Nav Menu ACTION");
    } else if (action?.type === "collapse") {
      const { widgetId } = action;
      if (widgetId) {
        setWidgetGlobalState((prev) => ({
          ...prev,
          collapsedWidgets: {
            ...prev.collapsedWidgets,
            [widgetId]: !prev.collapsedWidgets[widgetId],
          },
        }));
      }
    }
  }, [
    action,
    catalogItem,
    env,
    isFavorite,
    location.pathname,
    location.state,
    pages,
    setWidgetGlobalState,
    t,
    toast,
    toggleFavorite,
    user,
  ]);

  const elements = (
    <Fragment>
      {catalogItem && (
        <Fragment>
          <MUIDialog
            route={""}
            stateMatch={(state) => state.addToCart === catalogItem?.itemId}
          >
            <AddToCart
              catalogItem={catalogItem}
              submitButton={t("store.orders.addToCart")}
              onSubmit={(count, instructions, selectedOptions) => {
                if (!catalogItem) {
                  return;
                }
                cartApi.addItem(
                  catalogItem,
                  count,
                  instructions,
                  selectedOptions
                );
                toast({
                  duration: 1200,
                  message: t("store.orders.addedToCart"),
                });
              }}
            />
          </MUIDialog>
          <MUIDialog
            route={""}
            stateMatch={(state) => state.unfavorite === catalogItem?.itemId}
          >
            <DialogTitle className="smartWidthMd">
              {t("store.orders.removeFavorite")}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  history.goBack();
                }}
              >
                {t("store.cancel")}
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (!catalogItem) {
                    return;
                  }
                  toggleFavorite(catalogItem.itemId);
                  history.goBack();
                }}
              >
                {t("store.confirm")}
              </Button>
            </DialogActions>
          </MUIDialog>
        </Fragment>
      )}
    </Fragment>
  );
  return { execute, elements };
};
