import { PageColumn, PageRow } from "../../../../../database/page";
import * as R from "ramda";
import {
  cellIs,
  PageCell,
  PageWidgetType,
} from "../../../../Store/PageRenderer/widgetType";
import {
  CellLocator,
  ColumnLocator,
  CompleteLocatorSegment,
  CompletePath,
  IncompletePath,
  RowLocator,
  SectionLocator,
} from "./actions";
import { PageState } from "./reducer";

type Locator = RowLocator | ColumnLocator;

/**
 * Finds the cell or column that it pointed to by a locator
 */
export function resolveLocator(
  state: PageState,
  locator: CellLocator
): PageCell | undefined;
export function resolveLocator(
  state: PageState,
  locator: ColumnLocator
): PageColumn | undefined;
export function resolveLocator(
  state: PageState,
  locator: Locator
): PageCell | PageColumn | undefined {
  if (isColumnLocator(locator)) {
    return getColumn(state, locator);
  } else {
    return getRow(state, locator);
  }
}

/**
 * Checks if a locator is an ancestor of another locator, or is equal to it.
 *
 * @param ancestorCandidate The potential ancestor locator
 * @param descendantCandidate The potential descendant locator
 * @returns true if a and be are equal, or if b is a descendant of a. false otherwise
 */
export const locatorIncludes = (
  ancestorCandidate: Locator,
  descendantCandidate: Locator
): boolean => {
  if (ancestorCandidate.locationType !== descendantCandidate.locationType) {
    return false;
  }
  if (
    "pageId" in ancestorCandidate &&
    "pageId" in descendantCandidate &&
    ancestorCandidate.pageId !== descendantCandidate.pageId
  ) {
    return false;
  }
  if (ancestorCandidate.path.length > descendantCandidate.path.length) {
    return false;
  }
  return ancestorCandidate.path.every((segment, i) => {
    return (
      segment.rowIndex === descendantCandidate.path[i].rowIndex &&
      (segment.columnIndex === null ||
        segment.columnIndex === descendantCandidate.path[i].columnIndex)
    );
  });
};

/**
 * Creates a new locator describing the parent of a given locator
 *
 * For example, if this is a locator for a cell, it will return
 * a locator for the containing column. If it's a locator for a column,
 * it returns the locator for the containing row.
 */
export function parentLocator(
  locator: RowLocator
): ColumnLocator | SectionLocator;
export function parentLocator(locator: ColumnLocator): RowLocator;
export function parentLocator(locator: Locator): Locator;
export function parentLocator(locator: Locator): Locator | SectionLocator {
  if (isIncomplete(locator.path)) {
    if (locator.path.length === 1) {
      const { path, ...rest } = locator;
      const parent: SectionLocator = rest;
      return parent;
    } else {
      const path = locator.path.slice(
        0,
        locator.path.length - 1
      ) as CompletePath;
      const parent: ColumnLocator = {
        ...locator,
        path,
      };
      return parent;
    }
  } else {
    const lastSegment = locator.path[locator.path.length - 1];
    const path: IncompletePath = [
      ...locator.path.slice(0, locator.path.length - 1),
      {
        rowIndex: lastSegment.rowIndex,
        columnIndex: null,
      },
    ];
    const parent: RowLocator = {
      ...locator,
      path,
    };
    return parent;
  }
}

/**
 * Creates a new locator describing the child of a given locator
 */
export function childLocator(locator: RowLocator, index: number): ColumnLocator;
export function childLocator(locator: ColumnLocator, index: number): RowLocator;
export function childLocator(
  locator: SectionLocator,
  index: number
): RowLocator;
export function childLocator(locator: Locator, index: number): Locator;
export function childLocator(
  locator: Locator | SectionLocator,
  index: number
): Locator {
  if (!("path" in locator) || locator.path === undefined) {
    return {
      ...locator,
      path: [{ rowIndex: index, columnIndex: null }],
    };
  } else if (isIncomplete(locator.path)) {
    const child = R.clone(locator);
    child.path[child.path.length - 1].columnIndex = index;
    return child;
  } else {
    return {
      ...locator,
      path: [...locator.path, { rowIndex: index, columnIndex: null }],
    };
  }
}

/**
 * Determine if this locator is pointing to a column
 */
export const isColumnLocator = (
  locator: CellLocator | ColumnLocator
): locator is ColumnLocator => {
  return !isIncomplete(locator.path);
};

/**
 * Determine if this locator is pointing to a cell (or row, since rows
 * are a type of cell)
 */
export const isCellLocator = (
  locator: CellLocator | ColumnLocator
): locator is CellLocator => {
  return isIncomplete(locator.path);
};

const isIncomplete = (
  path: CompletePath | IncompletePath
): path is IncompletePath => {
  return path[path.length - 1].columnIndex === null;
};

/**
 * Helper function to find the row array
 */
export const getTopLevelRows = (
  state: PageState,
  locator: Locator | SectionLocator
): PageRow[] | undefined => {
  let rows: PageRow[] | undefined;
  if (locator.locationType === "navigation") {
    rows = state.summaries.navigation.rows;
  } else if (locator.locationType === "header") {
    rows = state.pages[locator.pageId]?.header?.rows;
  } else if (locator.locationType === "body") {
    rows = state.pages[locator.pageId]?.rows;
  } else {
    rows = state.customWidgets[locator.templateId]?.rows;
  }
  return rows;
};

/**
 * Walk the tree to find the row at the specified location
 */
export const getRow = (
  state: PageState,
  locator: CellLocator | RowLocator
): PageRow | undefined => {
  let rows: PageCell[] | undefined = getTopLevelRows(state, locator);
  let row: PageCell | undefined;
  for (const segment of locator.path) {
    if (!rows) {
      return;
    }
    const { rowIndex, columnIndex } = segment;
    row = rows[rowIndex];
    if (!row || !cellIs(PageWidgetType.row, row)) {
      return;
    }
    if (columnIndex !== null) {
      const column = row.props.columns[columnIndex];
      rows = column?.cells;
    }
  }
  if (row && cellIs(PageWidgetType.row, row)) {
    return row;
  } else {
    return undefined;
  }
};

/**
 * Walk the tree to find the column at the specified location
 */
export function getColumn(
  state: PageState,
  locator: ColumnLocator | SectionLocator
): PageColumn | undefined {
  if (!("path" in locator)) {
    return undefined;
  }
  let rows: PageCell[] | undefined = getTopLevelRows(state, locator);
  let column: PageColumn | undefined;
  for (const segment of locator.path) {
    if (!rows) {
      return;
    }
    const { rowIndex, columnIndex } = segment;
    if (columnIndex === null) {
      return column;
    }
    const row = rows[rowIndex];
    if (!row || !cellIs(PageWidgetType.row, row)) {
      return;
    }
    column = row.props.columns[columnIndex];
    rows = column?.cells;
  }
  return column;
}

/**
 * Helper function to find a widget
 */
export const getCell = (
  state: PageState,
  locator: CellLocator
): PageCell | undefined => {
  const finalSegment = locator.path[locator.path.length - 1];
  if (!finalSegment) {
    return undefined;
  }

  const parent = parentLocator(locator);
  if (!("path" in parent)) {
    const rows = getTopLevelRows(state, parent);
    return rows?.[finalSegment.rowIndex];
  } else {
    const column = getColumn(state, parent);
    return column?.cells[finalSegment.rowIndex];
  }
};

/**
 * Helper function to update the row array
 */
export const setTopLevelRows = (
  state: PageState,
  rowLocator: Locator | SectionLocator,
  newRows: PageRow[]
): PageState => {
  if (rowLocator.locationType === "navigation") {
    return {
      ...state,
      summaries: {
        ...state.summaries,
        navigation: {
          ...state.summaries.navigation,
          rows: newRows,
        },
      },
    };
  } else if (rowLocator.locationType === "header") {
    let existingHeader = state.pages[rowLocator.pageId].header;
    if (!existingHeader) {
      // Doesn't have a header, we can't update it
      return state;
    } else {
      return {
        ...state,
        pages: {
          ...state.pages,
          [rowLocator.pageId]: {
            ...state.pages[rowLocator.pageId],
            header: {
              ...existingHeader,
              rows: newRows,
            },
          },
        },
      };
    }
  } else if (rowLocator.locationType === "body") {
    return {
      ...state,
      pages: {
        ...state.pages,
        [rowLocator.pageId]: {
          ...state.pages[rowLocator.pageId],
          rows: newRows,
        },
      },
    };
  } else {
    return {
      ...state,
      customWidgets: {
        ...state.customWidgets,
        [rowLocator.templateId]: {
          ...state.customWidgets[rowLocator.templateId],
          rows: newRows,
        },
      },
    };
  }
};

/**
 * Update the state to set the row at the specified location
 * If that row already exists, it will be overwritten
 */
export const setRow = (
  state: PageState,
  rowLocator: RowLocator,
  newRow: PageRow
): PageState => {
  return setCell(state, rowLocator, newRow);
};

/**
 * Update the state to set the column at the specified location
 * If that column already exists, it will be overwritten
 */
export const setColumn = (
  state: PageState,
  columnLocator: ColumnLocator | SectionLocator,
  newColumn: PageColumn
): PageState => {
  if (!("path" in columnLocator)) {
    return state;
  }

  const rowLocator = parentLocator(columnLocator);

  const oldRows = getTopLevelRows(state, rowLocator);
  if (!oldRows) {
    return state;
  }

  const newRows = setColumn_recurse(oldRows, columnLocator.path, newColumn);

  if (newRows !== oldRows && allRows(newRows)) {
    return setTopLevelRows(state, rowLocator, newRows);
  } else {
    return state;
  }
};

const setColumn_recurse = (
  rows: PageCell[],
  path: CompleteLocatorSegment[],
  newColumn: PageColumn
): PageCell[] => {
  const [head, ...tail] = path;
  if (!head) {
    return rows;
  }
  const { rowIndex, columnIndex } = head;
  const row = rows[rowIndex];
  if (!row || !cellIs(PageWidgetType.row, row)) {
    return rows;
  }

  if (tail.length === 0) {
    const newColumns = [...row.props.columns];
    newColumns[columnIndex] = newColumn;
    const newRows = [...rows];
    newRows[rowIndex] = {
      ...row,
      props: {
        ...row.props,
        columns: newColumns,
      },
    };
    return newRows;
  } else {
    const column = row.props.columns[columnIndex];
    if (!column) {
      return rows;
    }
    const newColumns = [...row.props.columns];
    newColumns[columnIndex] = {
      ...newColumns[columnIndex],
      cells: setColumn_recurse(column.cells, tail, newColumn),
    };
    const newRows = [...rows];
    newRows[rowIndex] = {
      ...row,
      props: {
        ...row.props,
        columns: newColumns,
      },
    };
    return newRows;
  }
};

/**
 * Update the state to set the cell at the specified location
 * If that cell already exists, it will be overwritten
 */
export const setCell = (
  state: PageState,
  cellLocator: CellLocator,
  newCell: PageCell
): PageState => {
  const rows = getTopLevelRows(state, cellLocator);
  if (!rows) {
    return state;
  }

  const newRows = setCell_recurse(rows, cellLocator.path, newCell);

  if (newRows !== rows && allRows(newRows)) {
    return setTopLevelRows(state, cellLocator, newRows);
  } else {
    return state;
  }
};

//
//
/**
 * Double check that every cell is a row.
 *
 * There may be a way to update the types so this can be verified at compile time
 * and then this can be removed.
 */
const allRows = (rows: PageCell[]): rows is PageCell<PageWidgetType.row>[] => {
  return rows.every((row) => cellIs(PageWidgetType.row, row));
};

function setCell_recurse(
  rows: PageCell[],
  path: IncompletePath,
  newCell: PageCell
): PageCell[] {
  const head = path[0];
  if (!head) {
    return rows;
  }
  const tail = path.slice(1) as IncompletePath;
  const { rowIndex, columnIndex } = head;
  if (columnIndex === null) {
    const newRows = [...rows];
    newRows[rowIndex] = newCell;
    return newRows;
  } else {
    const row = rows[rowIndex];
    if (!row || !cellIs(PageWidgetType.row, row)) {
      return rows;
    }
    const column = row.props.columns[columnIndex];
    if (!column) {
      return rows;
    }
    const newColumns = [...row.props.columns];
    newColumns[columnIndex] = {
      ...column,
      cells: setCell_recurse(column.cells, tail, newCell),
    };
    const newRows = [...rows];
    newRows[rowIndex] = {
      ...row,
      props: {
        ...row.props,
        columns: newColumns,
      },
    };
    return newRows;
  }
}
