import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as R from "ramda";
import { Order, orderStatus } from "../../../database/order";
import useSiteUser from "../../UserProvider/useSiteUser";
import { storeKey } from "../../../Firebase/siteFirebase";
import { useSiteFirebase } from "../../../Firebase/context";
import { notUndefined } from "../../../utilities/notNull";
import type firebase from "firebase";
import { unstable_batchedUpdates } from "react-dom";
import useAccountInfo from "../../Main/useAccountInfo";

interface Options {
  includePending: boolean;
  sort: "asc" | "desc";
  pageSize?: number;
}

interface PageData {
  pageNumber: number;
  after: null | firebase.firestore.DocumentSnapshot;
  before: null | firebase.firestore.DocumentSnapshot;
}

export const useFetchTrack = ({
  includePending,
  sort,
  pageSize = 20,
}: Options) => {
  const { user } = useSiteUser();
  const accountInfo = useAccountInfo();
  const firebase = useSiteFirebase();
  const [documents, setDocuments] =
    useState<firebase.firestore.DocumentSnapshot[]>();

  const [loading, setLoading] = useState(Boolean(user));
  const [pageData, setPageData] = useState<PageData>({
    pageNumber: 0,
    after: null,
    before: null,
  });
  const [orders, hasMoreResults] = useMemo(() => {
    const orders = (documents ?? [])
      .map((doc) => doc.data() as Order | undefined)
      .filter(notUndefined);

    // When moving forward, we fetch one extra document to peek if there's another page
    const movingBackward = pageData.before;
    let hasMoreResults = movingBackward || orders.length > pageSize;
    if (!movingBackward && hasMoreResults) {
      // The extra document did its job; not remove it form the results
      orders.pop();
    }

    return [orders, hasMoreResults] as const;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, pageSize]);

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setPageData((prev) => {
      const newPageData = {
        pageNumber: 0,
        before: null,
        after: null,
      };
      if (R.equals(prev, newPageData)) {
        return prev;
      } else {
        return newPageData;
      }
    });
    // If one of these parameters change, we need to reset to page 0 because the page
    //   boundaries will be different.
  }, [user, pageSize, sort, includePending]);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      setDocuments(undefined);
      return;
    }
    const statuses = [
      orderStatus.inqueue,
      orderStatus.delivered,
      orderStatus.inprogress,
      orderStatus.intransit,
      orderStatus.ready,
    ];
    if (includePending) {
      // in case the webhook hasn't been notified yet, include pending orders
      statuses.push(orderStatus.pending);
    }

    let query = firebase.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("orders")
      .where("recipient.userId", "==", user.uid)
      .where("status", "in", statuses)
      .orderBy("delivery.timeCreated", sort);
    if (pageData.after) {
      // Moving forward to another page. We deliberately fetch one extra
      //   document to figure out if there's another page after
      query = query.startAfter(pageData.after).limit(pageSize + 1);
    } else if (pageData.before) {
      // Moving back a page
      query = query.endBefore(pageData.before).limitToLast(pageSize);
    } else {
      // starting at the beginning. We deliberately fetch one extra
      //   document to figure out if there's another page after
      query = query.limit(pageSize + 1);
    }

    setLoading(true);
    let firstSnapshot = true;
    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        if (firstSnapshot) {
          window.scrollTo(0, 0);
          firstSnapshot = false;
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setDocuments(snapshot.docs);
        });
      },
      (error) => {
        console.error(error);
        unstable_batchedUpdates(() => {
          setLoading(false);
          setDocuments(undefined);
        });
      }
    );

    return unsubscribe;
  }, [firebase.firestore, includePending, pageData, pageSize, sort, user]);

  const nextPage = useCallback(() => {
    setDocuments((prevDocuments) => {
      if (prevDocuments && hasMoreResults) {
        setPageData((prevPageData) => {
          return {
            pageNumber: prevPageData.pageNumber + 1,
            after: prevDocuments[pageSize - 1],
            before: null,
          };
        });
      }
      // Don't want to change the documents; just need to use its latest value
      return prevDocuments;
    });
  }, [hasMoreResults, pageSize]);

  const prevPage = useCallback(() => {
    setDocuments((prevDocuments) => {
      if (prevDocuments && prevDocuments.length > 0) {
        setPageData((prevPageData) => {
          if (prevPageData.pageNumber === 0) {
            // Already on the first page
            return prevPageData;
          } else {
            return {
              pageNumber: prevPageData.pageNumber - 1,
              after: null,
              before: prevDocuments[0],
            };
          }
        });
      }
      // Don't want to change the documents; just need to use its latest value
      return prevDocuments;
    });
  }, []);

  return {
    loading,
    orders,
    page: pageData.pageNumber,
    totalPages: accountInfo?.orderCount
      ? Math.max(0, Math.ceil(accountInfo.orderCount / pageSize))
      : undefined,
    hasMoreResults,
    nextPage,
    prevPage,
  };
};
