import { FC } from "react";
import { ToastProvider } from "../Main/useToast";
import Home from "./Home/home";
import "../App/App.css";

const GridbashMain: FC = () => {
  return (
    <div id="gb-main" className="App">
      <ToastProvider>
        <Home />
      </ToastProvider>
    </div>
  );
};

export default GridbashMain;
