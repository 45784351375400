import { createContext } from "react";
import { AdminGridbashSettings } from "../../database/gridbashSettings";
import { useContextThrowUndefined } from "../../utilities/useContextThrowUndefined";

export const GridbashSettingsContext = createContext<
  AdminGridbashSettings | undefined
>(undefined);

/**
 * Returns information about gridbash, that's accessible to admins. For example,
 * which subscription plans we offer
 */
export const useGridbashSettings = () =>
  useContextThrowUndefined(GridbashSettingsContext);
