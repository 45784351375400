import { useEffect, useState } from "react";
import { SiteUser } from "../database/userData";
import { useSiteFirebase } from "../Firebase/context";
import { storeKey } from "../Firebase/siteFirebase";

/**
 * Gets user data on an individual user
 */
export const useUserData = (userId: string | null | undefined) => {
  const [userData, setUserData] = useState<SiteUser | null>(null);
  const firebase = useSiteFirebase();

  useEffect(() => {
    if (userId) {
      setUserData(null);
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("users")
        .doc(userId)
        .onSnapshot(
          (snapshot) => {
            setUserData(snapshot.data() as SiteUser | null);
          },
          (error) => {
            setUserData(null);
          }
        );
      return unsubscribe;
    } else {
      setUserData(null);
    }
  }, [firebase.firestore, userId]);

  return userId ? userData : null;
};
