import { createContext, FC } from "react";
import { useFetchItem } from "../../../../customization/useFetchItem";
import { CatalogItem_WithDefaults } from "../../../../database/catalogItem";
import CustomWidget from "./customWidget";

export const CatalogItemWidgetContext = createContext<
  CatalogItem_WithDefaults | undefined
>(undefined);

export const catalogItemTemplateId = "catalogItem";

/**
 * These props get stored in the database
 */
export interface CatalogItemWidgetProps {
  templateId: string;
  catalogItemId: string | null;
}

/**
 * Sometimes values are figured out at runtime and passed down as props. Eg, via the
 * catalogGridWidget. These values are not stored in the database.
 */
interface RuntimeOverrides {
  catalogItem?: CatalogItem_WithDefaults;
}

const CatalogItemWidget: FC<CatalogItemWidgetProps & RuntimeOverrides> = ({
  catalogItemId,
  templateId,
  catalogItem,
}) => {
  // Load the item, if we don't have it already
  const { item } = useFetchItem(catalogItem ? null : catalogItemId);
  catalogItem = catalogItem ?? item;
  if (!catalogItem) {
    return null;
  }
  return (
    <CatalogItemWidgetContext.Provider value={catalogItem}>
      <CustomWidget templateId={templateId} />
    </CatalogItemWidgetContext.Provider>
  );
};

export default CatalogItemWidget;
