import * as R from "ramda";
import { DeleteWidgetAction } from "./actions";
import { PageState } from "./reducer";
import { getColumn, parentLocator, setColumn } from "./locatorUtils";

/**
 * Reducer for deleting a widget.
 */
export const deleteWidget = (
  state: PageState,
  action: DeleteWidgetAction
): PageState => {
  const { cellLocator } = action;
  const lastSegment = R.last(cellLocator.path);
  if (!lastSegment) {
    return state;
  }
  const columnLocator = parentLocator(cellLocator);

  const column = getColumn(state, columnLocator);
  if (!column) {
    return state;
  }

  const newColumn = {
    ...column,
    cells: column.cells.filter((_, i) => i !== lastSegment?.rowIndex),
  };

  return setColumn(state, columnLocator, newColumn);
};
