import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FancyText } from "../../../../database/fancyText";
import { createCartItem } from "../../../CartManager/cartApiProvider";
import FancyTextComponent from "../../../Dashboard/Builder/FancyText/fancyTextComponent";
import getItemPriceLabels from "../../Orders/getItemPriceLabels";
import { CatalogItemWidgetContext } from "./catalogItemWidget";

export type PresetTextWidgetProps = {
  datasource?: "name" | "price";
  fancyText: FancyText;
};

const PresetTextWidget: FC<PresetTextWidgetProps> = ({
  datasource,
  fancyText,
}) => {
  const { t } = useTranslation();
  const catalogItem = useContext(CatalogItemWidgetContext);
  const newFancyText = { ...fancyText };
  if ((!fancyText || !fancyText.text) && catalogItem && datasource) {
    if (datasource === "name") {
      newFancyText.text = catalogItem.name;
    } else if (datasource === "price") {
      const { priceLabel, priceEachLabel } = getItemPriceLabels({
        t,
        cartItem: createCartItem({
          catalogItem,
          cartItemId: "fake",
          count: 1,
          optionSelections: [],
        }),
      });

      newFancyText.text = priceEachLabel || priceLabel || "";
    }
  }

  return <FancyTextComponent value={newFancyText} />;
};

export default PresetTextWidget;
