import { FC, useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import useSiteUser from "../../UserProvider/useSiteUser";
import { useTranslation } from "react-i18next";
import useAccountInfo from "../../Main/useAccountInfo";
import SectionSignedOut from "../Components/sectionSignedOut";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ItemFavoriteCard from "./itemFavorite";
import Container from "@material-ui/core/Container";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { IconButton, Typography } from "@material-ui/core";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useOptimisticUpdate } from "../../../utilities/useOptimisticUpdate";
import { SiteUser } from "../../../database/userData";
import { useSiteFirebase } from "../../../Firebase/context";
import { storeKey } from "../../../Firebase/siteFirebase";
import { useFetchFavorites } from "./useFetchFavorites";

export interface FavoritesPageProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const FavoritesPage: FC<FavoritesPageProps> = React.memo(
  ({ setMainBgImage }) => {
    const { t } = useTranslation();
    const { user } = useSiteUser();
    const firebase = useSiteFirebase();

    const [accountInfo, setOptimisticUpdate] = useOptimisticUpdate(
      useAccountInfo()
    );

    const favoriteIds = useMemo(
      () => accountInfo.favorites2 ?? [],
      [accountInfo.favorites2]
    );

    const favorites = useFetchFavorites(favoriteIds);

    const [signedIn, setSignedIn] = useState(false);
    useEffect(() => {
      if (user) {
        const id = setTimeout(() => {
          setSignedIn(true);
          //WE NEED A DELAY FOR THE SIGN IN MODAL TO UNMOUNT
          //AND NOT CAUSE IT TO JUMP
        });
        return () => clearTimeout(id);
      } else {
        setSignedIn(false);
      }
    }, [user]);

    useEffect(() => {
      setMainBgImage(false);
      window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // A lookup table pairing up item ids with an array of the categories it is in.
    const { catalog } = useSiteSettings();
    const categoryLookup = useMemo(() => {
      const table: {
        [itemId: string]: string[];
      } = {};
      catalog.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (!table[item.itemId]) {
            table[item.itemId] = [category.name];
          } else {
            table[item.itemId].push(category.name);
          }
        });
      });
      return table;
    }, [catalog.categories]);

    const [hasRearranged, setHasRearranged] = useState(false);
    const onDragEnd = useCallback(
      async (result: DropResult) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index || !user) {
          return;
        }

        // Not all favorites are necessarily rendered. Some may be hidden.
        const displayedIds = favorites.map((item) => item.itemId);
        const newFavorites = [...displayedIds];
        const [removed] = newFavorites.splice(source.index, 1);
        newFavorites.splice(destination.index, 0, removed);

        // Add back anything that was hidden, at the end (in case they become
        // unhidden in the future)
        const hiddenIds = favoriteIds.filter(
          (id) => !displayedIds.includes(id)
        );
        newFavorites.push(...hiddenIds);

        setHasRearranged(true);
        setOptimisticUpdate({
          ...accountInfo,
          favorites2: newFavorites,
        });
        try {
          const update: Partial<SiteUser> = {
            favorites2: newFavorites,
          };
          await firebase.firestore
            .collection("stores")
            .doc(storeKey)
            .collection("users")
            .doc(user.uid)
            .update(update);
        } catch (error) {
          setOptimisticUpdate(null);
        }
      },
      [
        accountInfo,
        favoriteIds,
        favorites,
        firebase.firestore,
        setOptimisticUpdate,
        user,
      ]
    );

    return (
      <React.Fragment>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="favorites">
            {(provided) => (
              <Container
                ref={provided.innerRef}
                maxWidth="sm"
                {...provided.droppableProps}
                style={{ padding: "max(1.5vw, 1.5em) 1em" }}
              >
                <Typography color="textPrimary" variant="h6">
                  {t("store.orders.yourFavorites")}
                </Typography>
                {favoriteIds.length > 1 && (
                  <Typography color="textPrimary" variant="caption">
                    {t("store.orders.dragToReorder")}
                  </Typography>
                )}
                <br />
                <br />
                {signedIn && favorites.length > 0 ? (
                  favorites.map((item, index) => (
                    <ItemFavoriteCard
                      key={item.itemId}
                      catalogItem={item}
                      categoryNames={categoryLookup[item.itemId] ?? []}
                      index={index}
                      dragDisabled={favoriteIds.length < 2}
                      // Disable the fade in animation after rearranging
                      fadeIn={!hasRearranged}
                    />
                  ))
                ) : (
                  <div>
                    {!signedIn ? (
                      <div
                        className="anim_fadeIn_0503 sTsignedOutSection"
                        style={{ opacity: 0 }}
                      >
                        <SectionSignedOut />
                      </div>
                    ) : (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: "95%",
                          }}
                        >
                          <Typography color="textPrimary">
                            {t("store.favorites.emptyMessage")}
                          </Typography>
                          <IconButton color="secondary">
                            <FavoriteIcon />
                          </IconButton>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
    );
  }
);

export default FavoritesPage;
