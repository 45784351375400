import { Unit } from "../database/catalogItem";
import { TFunction } from "i18next";

/**
 * Calculates the localized name of a unit
 */
export const getUnitName = (
  unit: Unit,
  count: number,
  t: TFunction
): string => {
  let unitName;
  if (unit.isCustomUnit) {
    // Check to see whether this should be plural according to the language rules
    let test = t("common.unit.testForPluralDoNotEdit", { count });
    return test === "true" ? unit.namePlural : unit.name;
  } else {
    unitName = t(`common.unit.${unit.key}`, { count });
  }
  return unitName;
};
