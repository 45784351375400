import {
  DialogTitle,
  Button,
  DialogContent,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useGridBashFirebase } from "../../Firebase/context";
import { useQuery } from "../../utilities/useQuery";
import { history } from "../App/history";
import MUIDialog from "../Dashboard/Components/mui-dialog";
import useToast from "../Main/useToast";
import useSpinner from "../Spinner/useSpinner";
import useGridbashUser from "../UserProvider/useGridbashUser";

interface GridbashAuthProps {
  /**
   * If true, this indicates we got here via a deep link. The user
   * may be automatically logged in, in which case we will redirect
   * as soon as we know that.
   */
  isDeepLink?: boolean;
  initPath: string;
}

const GridbashAuth: FC<GridbashAuthProps> = ({ isDeepLink, initPath }) => {
  const { t } = useTranslation();
  const { user, loading } = useGridbashUser();
  const firebase = useGridBashFirebase();
  const toast = useToast();
  const showSpinner = useSpinner();

  const [email, setEmail] = useState("");
  const emailIsValid = true; //validateEmail(authEmail);
  const [password, setPassword] = useState("");
  const passwordIsValid = password.length === 0 || password.length >= 5;
  const [showPassword, setShowPassword] = useState(false);

  const redirect = useQuery().get("redirect");

  const navigate = () => {
    if (redirect) {
      history.replace(redirect);
    } else {
      history.replace("/dashboard/overview");
    }
  };

  useEffect(() => {
    if (!loading && user && isDeepLink) {
      // Already logged in
      navigate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!emailIsValid) {
      return;
    } else if (!passwordIsValid) {
      return;
    }

    let hideSpinner = showSpinner({ lag: "none" });
    try {
      const { user } = await firebase.auth.signInWithEmailAndPassword(
        email,
        password
      );
      if (!user) {
        throw new Error("null user");
      }

      navigate();
    } catch (error) {
      toast({
        dialog: true,
        color: "error",
        message: error.message,
      });
    } finally {
      hideSpinner();
    }
  };

  return (
    <React.Fragment>
      <DialogTitle className="smartWidthMd">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {t("store.account.signIn")}

          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              history.replace("/applySequence/email");
            }}
          >
            {t("gridbash.home.applyNow2")}
          </Button>
        </div>
      </DialogTitle>

      <DialogContent>
        <form noValidate>
          <FormControl fullWidth>
            <TextField
              id="username"
              autoComplete="username"
              label={t("store.account.enterEmail")}
              type="email"
              fullWidth
              value={email.toLowerCase()}
              onChange={(e) => setEmail(e.currentTarget.value.trim())}
              error={email.length > 0 && !emailIsValid}
              helperText={
                email.length > 0 && !emailIsValid
                  ? t("store.account.badEmail")
                  : `\xa0`
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              id="password"
              autoComplete="password"
              label={t("store.account.enterPassword")}
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              error={!passwordIsValid}
              helperText={
                !passwordIsValid ? t("store.account.badPassword") : "\xa0"
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                />
              }
              label={t("store.account.showPassword")}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            const path = `${initPath}/reset`;
            if (!window.location.pathname.includes(path)) {
              history.push(path);
            }
          }}
        >
          {t("gridbash.home.forgot")}
        </Button>
        <MUIDialog route={`${initPath}/reset`}>
          <PasswordReset initPath={`${initPath}/reset`} />
        </MUIDialog>
        <div style={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          {t("store.cancel")}
        </Button>
        <Button onClick={(e) => handleSubmit(e)}>{t("store.submit")}</Button>
      </DialogActions>
    </React.Fragment>
  );
};

const PasswordReset: FC<{ initPath: string }> = ({ initPath }) => {
  const { t } = useTranslation();
  const showSpinner = useSpinner();
  const toast = useToast();
  const firebase = useGridBashFirebase();

  const [email, setEmail] = useState("");

  return (
    <React.Fragment>
      <DialogTitle className="smartWidthMd" style={{ paddingBottom: 0 }}>
        {t("gridbash.home.sendResetLink")}
      </DialogTitle>
      <DialogContent className="smartWidthMd">
        <Typography variant="body2">{t("gridbash.home.checkSpam")}</Typography>
        <br />
        <TextField
          fullWidth
          label={t("gridbash.home.enterEmail")}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("gridbash.cancel")}
        </Button>
        <Button
          onClick={async (e) => {
            e.stopPropagation();
            const hideSpinner = showSpinner({ lag: "none" });
            try {
              await firebase.auth.sendPasswordResetEmail(email);
              history.go(-2);
              toast({
                color: "normal",
                message: t("store.account.emailSent"),
              });
            } catch (error) {
              toast({
                color: "error",
                message: error.message,
                dialog: true,
              });
            } finally {
              hideSpinner();
            }
          }}
        >
          {t("gridbash.submit")}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default GridbashAuth;
