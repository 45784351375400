import { PhoneNumber } from "../database/siteSettings";

/**
 * Chunk configs define which combination of strings to store in the database
 * for searching. The goal is to balance a good search experience with the
 * data storage overheads.
 *
 * Each element of the array defines a range of string lengths. For example:
 * ```
 * { minLength: 1, maxLength: 5, modulo: 1 },
 * ```
 * This means we should create strings with length 1, 2, 3, 4, and 5.
 *
 * Changing the modulo value will cause it to only create strings in the
 * range which are evenly divisible by that number. For example:
 * ```
 * { minLength: 6, maxLength: 15, modulo: 3 },
 * ```
 * This creates strings with length 6, 9, 12, and 15.
 *
 * ------------------
 *
 * NOTE: If we want to change the configs, we need both a client side change
 * and a migration of the database.
 */
type ChunkConfig = {
  minLength: number;
  maxLength: number;
  modulo: number;
}[];

const defaultConfig: ChunkConfig = [
  { minLength: 1, maxLength: 5, modulo: 1 },
  { minLength: 6, maxLength: 15, modulo: 3 },
  { minLength: 20, maxLength: 100, modulo: 10 },
  // Longer than 100 don't get indexed at all
];

/**
 * Our implementation for searching in firebase is to have an array of partial strings
 * and then do an "array-contains" query. This function produces that array.
 *
 * Searches are allowed to start from the beginning of any word and are case insensitive
 */
export const createSearchStrings = (fullString: string): string[] => {
  fullString = fullString.toLowerCase();
  const allStrings: Record<string, true> = {};
  let wordStart = 0;
  do {
    const truncatedString = fullString.slice(wordStart);
    Object.assign(allStrings, getSubstrings(truncatedString, defaultConfig));
    wordStart = fullString.indexOf(" ", wordStart) + 1;
  } while (wordStart > 0);

  return Object.keys(allStrings);
};

/**
 * This is the other side of searching: the user types in some string, but then we may need
 * to shorten that string so that it will coincide with the values in the search array.
 */
export const truncateSearch = (fullSearch: string): string => {
  return truncate(fullSearch, defaultConfig);
};

const truncate = (fullSearch: string, config: ChunkConfig): string => {
  fullSearch = fullSearch.toLowerCase();
  for (let i = config.length - 1; i >= 0; i--) {
    const currentRange = config[i];
    if (currentRange.minLength <= fullSearch.length) {
      let length = Math.min(fullSearch.length, currentRange.maxLength);
      length = Math.floor(length / currentRange.modulo) * currentRange.modulo;
      return fullSearch.slice(0, length);
    }
  }
  return "";
};

const phoneConfig: ChunkConfig = [
  { minLength: 1, maxLength: Number.MAX_SAFE_INTEGER, modulo: 1 },
];
/**
 * For phones search we do a slightly different strategy. There are no words to
 * break it up on, but we break it at the country code so they can either search
 * with it, or without. Since phone numbers are short, we index strings of all
 * lengths.
 */
export const createPhoneSearchStrings = (
  phone: PhoneNumber | null
): string[] => {
  if (!phone) {
    return [];
  }
  const phoneWithoutCountry = phone.raw.slice(phone.countryCode.length);
  const allStrings: Record<string, true> = {
    ...getSubstrings(phone.raw, phoneConfig),
    ...getSubstrings(phoneWithoutCountry, phoneConfig),
  };
  return Object.keys(allStrings);
};

const getSubstrings = (
  fullString: string,
  chunkConfig: ChunkConfig
): Record<string, true> => {
  const allStrings: Record<string, true> = {};
  let configIndex = 0;
  let currentConfig = chunkConfig[configIndex];
  let length =
    Math.ceil(currentConfig.minLength / currentConfig.modulo) *
    currentConfig.modulo;
  while (length <= fullString.length) {
    allStrings[fullString.slice(0, length)] = true;
    length += currentConfig.modulo;
    if (length > currentConfig.maxLength) {
      configIndex++;
      currentConfig = chunkConfig[configIndex];
      if (!currentConfig) {
        break;
      }
      length =
        Math.ceil(currentConfig.minLength / currentConfig.modulo) *
        currentConfig.modulo;
    }
  }
  return allStrings;
};

export const truncatePhoneSearch = (fullString: string) => {
  return truncate(fullString, phoneConfig);
};
