import * as R from "ramda";
import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { useSiteFirebase } from "../../../../Firebase/context";
import { storeKey } from "../../../../Firebase/siteFirebase";
import {
  truncatePhoneSearch,
  truncateSearch,
} from "../../../../utilities/createSearchStrings";
import type firebase from "firebase";

export type QueryMod = (
  query: firebase.firestore.Query
) => firebase.firestore.Query;

export interface UseSearchOptions<T> {
  /**
   * Text the user has entered into the search field.
   * eg: "nicholas.tow"
   */
  searchString: string;
  /**
   * Which collection to query in the database
   * eg: "users"
   */
  collection: string;
  /**
   * Which property in the database to match with. Can use dot notation to
   * reference a nested property.
   * eg: "contactInfo.emailSearch"
   */
  fieldPath_database: string;
  /**
   * which property to check on the client side for additional filtering
   *
   * If a string is provided, it will look up that property. eg: "contactInfo.email"
   * If a function is provided, you can do custom extraction logic, and can return
   *   an array of strings, allowing matches at different parts of the words.
   *   eg, for phone searches, we return both the number with and without dial code
   */
  fieldPath_client: string | ((value: T) => string[]);
  /**
   * The database only stores a subset of search strings to save space and
   * bandwidth. A truncation function decides which lengths of strings are
   * allowed, and shortens the full search to that length so that we will
   * get hits in the database.
   *
   * See the createSearchStrings file for more information
   */
  truncationFunction?: (searchString: string) => string;
  /**
   * Maximum number to fetch at a time
   */
  pageSize?: number;
  /**
   * Allows customization of the firestore query. For example, this can
   * be used to further filter the query, or to sort it.
   */
  queryModification?: QueryMod;
  /**
   * If true, an empty string will do a search. This is disabled by default
   */
  allowEmptyStringSearch?: boolean;
  /**
   * If this value changes, then all saved data will be cleared
   */
  cacheBust?: number;
}

interface ResultState<T> {
  // Data we've gotten back
  data: T[];
  // If false, there's definitely no more data. If true, there might be more
  hasMoreResults: boolean;
  // Where to pick up for searching more
  after: firebase.firestore.DocumentSnapshot | undefined;
}

/**
 * Queries the database for entries matching a certain term.
 *
 * This only works on database entries which have a search string array.
 * These arrays are created by the code in createSearchStrings
 */
export const useSearch = <T>({
  searchString,
  collection,
  fieldPath_database: fieldPath,
  fieldPath_client,
  truncationFunction,
  queryModification,
  pageSize = 20,
  allowEmptyStringSearch = false,
  cacheBust,
}: UseSearchOptions<T>): {
  results: T[];
  hasMoreResults: boolean;
  getMoreResults: () => Promise<void>;
  loading: boolean;
  error: any;
} => {
  const firebase = useSiteFirebase();

  if (!truncationFunction) {
    // If no truncation function was provided, make an educated guess about which one to use
    truncationFunction = fieldPath.includes("phone")
      ? truncatePhoneSearch
      : truncateSearch;
  }
  const truncatedSearch = truncationFunction(searchString);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  /** Record of all the results we've gotten so far */
  const [allResults, setAllResults] = useState<Record<string, ResultState<T>>>(
    {}
  );
  useEffect(() => {
    setAllResults((prev) => {
      if (Object.keys(prev).length === 0) {
        return prev;
      } else {
        return {};
      }
    });
  }, [cacheBust]);

  /** Results for the current search string */
  const { data, fetchNeeded, hasMoreResults } = useMemo<{
    data: T[];
    fetchNeeded: boolean;
    hasMoreResults: boolean;
  }>(() => {
    // We don't necessarily need results for the exact search string. If we have
    //   results for a smaller substring, we can use those and do client side
    //   filtering to mimic the full search. The following loop finds the results
    //   for the longest substring
    let existingResults: ResultState<T> | undefined;
    let keyUsed = truncatedSearch;
    for (let i = truncatedSearch.length; i >= 0; i--) {
      keyUsed = truncatedSearch.slice(0, i);
      existingResults = allResults[keyUsed];
      if (existingResults) {
        break;
      }
    }
    if (!existingResults) {
      // No results
      return {
        data: [],
        fetchNeeded: true,
        hasMoreResults: false,
      };
    }

    // Do additional client-side filtering, since we may have a more specific
    //  search than the 3-character blocks can find server-side
    let filteredData;
    if (typeof fieldPath_client === "function") {
      filteredData = existingResults.data.filter((result) => {
        const values = fieldPath_client(result);
        return values.some((value) => clientSideMatch(value, searchString));
      });
    } else {
      const pathArray = fieldPath_client.split(".");
      filteredData = existingResults.data.filter((result) => {
        const value = R.path(pathArray, result);
        return clientSideMatch(value, searchString);
      });
    }

    let fetchNeeded;
    if (keyUsed === truncatedSearch) {
      // The results came from the exact search they asked for. No fetch is needed
      fetchNeeded = false;
    } else {
      // The results came from client-side filtering of a shorter search. That's
      //   fine as long as we have all the data, but if there are more pages we
      //   we may be missing data and thus need to refetch.
      fetchNeeded = existingResults.hasMoreResults;
    }

    return {
      data: filteredData,
      fetchNeeded,
      hasMoreResults: existingResults.hasMoreResults,
    };
  }, [allResults, fieldPath_client, searchString, truncatedSearch]);

  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const fetchData = useCallback(
    async (mod?: QueryMod) => {
      try {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection(collection);
        if (truncatedSearch.length > 0) {
          query = query.where(fieldPath, "array-contains", truncatedSearch);
        }
        if (mod) {
          query = mod(query);
        }
        // We fetch 1 extra document to let us know whether there are more results
        query = query.limit(pageSize + 1);

        const snapshot = await query.get();

        if (unmounted.current) {
          return;
        }

        const newData: T[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as T | undefined;
          if (data) {
            newData.push(data);
          }
        });
        let hasMoreResults = false;
        if (newData.length === pageSize + 1) {
          // We fetch 1 more than the page size to give us a peek into the next page.
          //   If that peek was included in our results, then we have more results.
          hasMoreResults = true;
          // However, we don't want to display it to the user, and don't want to
          //   duplicate it if they move to the next page, so remove it.
          newData.pop();
        }
        const secondToLastDoc = snapshot.docs[snapshot.docs.length - 2];

        setAllResults((prev) => {
          const prevResults = prev[truncatedSearch];
          let combinedData = newData;
          if (prevResults) {
            combinedData = [...prevResults.data, ...newData];
          }
          return {
            ...prev,
            [truncatedSearch]: {
              data: combinedData,
              hasMoreResults,
              after: secondToLastDoc,
            },
          };
        });
        setError(null);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.warn("error fetching data", error);
      }
    },
    [collection, fieldPath, firebase.firestore, pageSize, truncatedSearch]
  );

  useEffect(() => {
    if (fetchNeeded && (allowEmptyStringSearch || truncatedSearch.length > 0)) {
      setLoading(true);
      setError(null);
      let id = setTimeout(() => {
        fetchData(queryModification);
      }, 150);

      return () => {
        clearTimeout(id);
      };
    } else {
      setLoading(false);
    }
  }, [
    allResults,
    allowEmptyStringSearch,
    collection,
    fetchData,
    fetchNeeded,
    fieldPath,
    firebase.firestore,
    pageSize,
    queryModification,
    truncatedSearch,
  ]);

  const getMoreResults = useCallback(() => {
    const results = allResults[truncatedSearch];
    if (!results || !results.hasMoreResults) {
      return Promise.resolve();
    }
    setLoading(true);
    setError(null);
    return fetchData((query) => {
      if (queryModification) {
        query = queryModification(query);
      }
      return query.startAfter(results.after);
    });
  }, [allResults, fetchData, queryModification, truncatedSearch]);

  return { results: data, loading, error, hasMoreResults, getMoreResults };
};

export const clientSideMatch = (value: unknown, searchString: string) => {
  if (typeof value === "string") {
    const lowerValue = value.toLowerCase();
    const lowerSearchString = searchString.toLowerCase();
    // We match on the start of every word.
    const allStrings = [];
    let wordStart = 0;
    do {
      allStrings.push(lowerValue.slice(wordStart));
      wordStart = lowerValue.indexOf(" ", wordStart) + 1;
    } while (wordStart > 0);
    return allStrings.some((str) => str.startsWith(lowerSearchString));
  }
  return false;
};
