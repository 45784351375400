import Button from "@material-ui/core/Button";
import { FC } from "react";
import { Label } from "../../../database/catalogItem";

interface SearchLabelProps {
  label: Label;
  selected: boolean;
  onClick: () => void;
}

export const SearchLabel: FC<SearchLabelProps> = ({
  label,
  selected,
  onClick,
}) => {
  return (
    <Button
      variant={selected ? "contained" : undefined}
      color={selected ? "secondary" : "default"}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        opacity: 1,
        minWidth: "auto",
        padding: "0.5em",
        borderRadius: "4px",
        marginRight: "0.5em",
        textAlign: "center",
        transition: "all 0.3s ease",
        whiteSpace: "nowrap",
      }}
    >
      {label.text}
    </Button>
  );
};
