import React, { FC, ReactElement } from "react";

interface SmartButtonProps {
  onClick: (e: any) => void;
  style: React.CSSProperties;
  className: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  animate?: boolean;
}

const SmartButton: FC<SmartButtonProps> = ({
  children,
  onClick,
  style,
  className,
  startIcon,
  endIcon,
  animate = true,
}) => {
  return (
    <button
      onClick={onClick}
      className={animate ? `buttonHover ${className}` : className}
      style={{
        transition: "all 0.1s ease 0s",
        display: "inline-flex",
        alignItems: "center",
        ...style,
      }}
    >
      {startIcon && (
        <div style={{ display: "flex", marginRight: "8px" }}>{startIcon}</div>
      )}
      {children}
      {endIcon && (
        <div style={{ display: "flex", marginLeft: "8px" }}>{endIcon}</div>
      )}
    </button>
  );
};

export default SmartButton;
