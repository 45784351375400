import { Fragment, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import {
  DialogContent,
  TextField,
  FormControl,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";

interface OrderTimingInputSettingsProps {
  initialValue: number;
  handleSubmit: (value: number) => void;
  editing: string;
}

const OrderTimingInputSettings: FC<OrderTimingInputSettingsProps> = ({
  initialValue,
  handleSubmit,
  editing,
}) => {
  const { t } = useTranslation();

  const [daysString, setDaysString] = useState(
    "" + Math.floor(initialValue / 1440)
  );
  const [hoursString, setHoursString] = useState(
    "" + (Math.floor(initialValue / 60) % 24)
  );
  const [minutesString, setMinutesString] = useState("" + (initialValue % 60));
  const daysValue = Number(daysString);
  const hoursValue = Number(hoursString);
  const minutesValue = Number(minutesString);
  const noChange =
    Math.floor(initialValue / 1440) === daysValue &&
    Math.floor(initialValue / 60) % 24 === hoursValue &&
    initialValue % 60 === minutesValue;

  return (
    <Fragment>
      <DialogTitle className="smartWidthMd">
        {(editing = "min" ? "Minimum Time Range" : "Maximum Time Range")}
      </DialogTitle>
      <DialogContent className="smartWidthMd">
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label={t("dashboard.settings.enterDays")}
            type="number"
            value={daysString}
            onChange={(event) => {
              if (Number(event.target.value) >= 0) {
                setDaysString(event.target.value);
              } else {
                setDaysString("0");
              }
            }}
          />
        </FormControl>
        <div style={{ marginTop: "3vh" }}></div>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label={t("dashboard.settings.enterHours")}
            type="number"
            value={hoursString}
            onChange={(event) => {
              if (Number(event.target.value) >= 0) {
                setHoursString(event.target.value);
              } else {
                setHoursString("0");
              }
            }}
          />
        </FormControl>
        <div style={{ marginTop: "3vh" }}></div>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label={t("dashboard.settings.enterMinutes")}
            type="number"
            value={minutesString}
            onChange={(event) => {
              if (Number(event.target.value) >= 0) {
                setMinutesString(event.target.value);
              } else {
                setMinutesString("0");
              }
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("dashboard.cancel")}
        </Button>
        <Button
          disabled={noChange}
          onClick={(e) => {
            e.stopPropagation();
            if (!noChange) {
              handleSubmit(
                minutesValue + hoursValue * 60 + daysValue * 24 * 60
              );
              history.goBack();
            }
          }}
        >
          {t("dashboard.submit")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default OrderTimingInputSettings;
