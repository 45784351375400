import {
  memo,
  ComponentType,
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useRef,
} from "react";
import ButtonWidget from "./widgets/buttonWidget/buttonWidget";
import PresetTextWidget from "./widgets/presetTextWidget";
import RowWidget from "./widgets/rowWidget";
import ImageWidget from "./widgets/imageWidget";
import TextOrIconWidget from "./widgets/textOrIconWidget";
import NavDrawer from "./widgets/navDrawerWidget/navDrawerWidget";
import NavLinksWidget from "./widgets/navLinksWidget/navLinksWidget";
import WealthyTextWidget from "./widgets/wealthyTextWidget";
import { PageCell, PageWidgetType, PageWidgetPropMap } from "./widgetType";
import EmptySpaceWidget from "./widgets/emptySpaceWidget";
import CatalogItemWidget from "./widgets/catalogItemWidget";
import CatalogGridWidget from "./widgets/catalogGridWidget";
import WidgetPickerWidget from "./widgets/widgetPickerWidget";
import {
  useWidgetGlobalState,
  WidgetGlobalStateType,
} from "./widgetGlobalState";
import CustomWidget from "./widgets/customWidget";
import TextLabelsWidget from "./widgets/textLabelsWidget";
import FilterLabelsWidget from "./widgets/filterLabelsWidget";

export interface WidgetRendererProps<T extends PageWidgetType> {
  cell: PageCell<T>;
}

const components: {
  [type in PageWidgetType]: ComponentType<PageWidgetPropMap[type]>;
} = {
  [PageWidgetType.row]: RowWidget,
  [PageWidgetType.textOrIcon]: TextOrIconWidget,
  [PageWidgetType.navLinks]: NavLinksWidget,
  [PageWidgetType.wealthyText]: WealthyTextWidget,
  [PageWidgetType.button]: ButtonWidget,
  [PageWidgetType.cartIcon]: ButtonWidget,
  [PageWidgetType.favoriteIcon]: ButtonWidget,
  [PageWidgetType.image]: ImageWidget,
  [PageWidgetType.presetText]: PresetTextWidget,
  [PageWidgetType.navDrawer]: NavDrawer,
  [PageWidgetType.emptySpace]: EmptySpaceWidget,
  [PageWidgetType.catalogItem]: CatalogItemWidget,
  [PageWidgetType.catalogGrid]: CatalogGridWidget,
  [PageWidgetType.widgetPicker]: WidgetPickerWidget,
  [PageWidgetType.customWidget]: CustomWidget,
  [PageWidgetType.textLabels]: TextLabelsWidget,
  [PageWidgetType.filterLabels]: FilterLabelsWidget,
};

function WidgetRenderer<T extends PageWidgetType>({
  cell,
}: WidgetRendererProps<T>) {
  const { state, setState } = useWidgetGlobalState();
  const collapsed = Boolean(state.collapsedWidgets[cell.id]);

  return (
    <InnerComponent
      cell={cell}
      collapsed={collapsed}
      setGlobalState={setState}
    />
  );
}

// Split out to improve performance. Most global state changes are irrelevant.
const InnerComponent = memo(function <T extends PageWidgetType>({
  cell,
  collapsed,
  setGlobalState,
}: WidgetRendererProps<T> & {
  collapsed: boolean;
  setGlobalState: Dispatch<SetStateAction<WidgetGlobalStateType>>;
}) {
  let firstRender = useRef(true);
  useLayoutEffect(() => {
    if (firstRender.current && cell.collapsedByDefault) {
      setGlobalState((prev) => ({
        ...prev,
        collapsedWidgets: {
          ...prev.collapsedWidgets,
          [cell.id]: true,
        },
      }));
    }
    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Component = components[cell.type] as ComponentType<
    PageWidgetPropMap[T]
  >;
  if (cell.collapsedByDefault) {
    console.log(collapsed, firstRender.current);
  }
  return (
    <div
      style={{
        height:
          collapsed || (cell.collapsedByDefault && firstRender.current)
            ? 0
            : "100%",
        width: "100%",
        transition: "height 0.3s ease",
        /**
         * OVERFLOW HAS BEEN COMMENTED OUT ON OCT 20 9:56AM
         * BECAUSE IT PREVENTS BOX-SHADOWS TO WORK IN PREVIEW
         *
         */
        // overflow: "hidden",
      }}
    >
      <Component {...cell.props} />
    </div>
  );
});

export default WidgetRenderer;
