import { ColorConfig } from "../components/widgets/colorConfig";
import uuid from "uuid/v4";
import { FancyText } from "./fancyText";

export type OrderPageOptions<T extends OrderTemplate> = {
  template: T;
  props: OrderTemplatePropMap[T];
};

export enum OrderTemplate {
  original = "original",
}

export enum OrderWidgetType {
  image = "image",
  fancyText = "fancyText",
  richText = "richText",
  grid = "grid",
  labelText = "labelText",
  labelFilter = "labelFilter",
  iconFavorite = "iconFavorite",
  iconCart = "iconCart",
  emptySpace = "emptySpace",
}

// Defines which props are requires for which template
interface OrderTemplatePropMap {
  [OrderTemplate.original]: OriginalProps;
}

interface OriginalProps {
  breakpointSm: number;
  breakpointLg: number;
  columnsSm: number;
  columnsMd: number;
  columnsLg: number;
  columnWidth: number | "1fr";
  align:
    | "left"
    | "center"
    | "right"
    | "stretch"
    | "space-around"
    | "space-evenly";
  rowUnit: "vw" | "px" | "%" | "em" | "auto";
  rowValue: number;
  columnGap: number;
  rowGap: number;
  backgroundColor?: ColorConfig; // UNDEFINED ALLOWED AS THEN THE ROOT BACKGROUND COLOR WILL BE USED
  masonry: boolean;
  itemProps: OrderGridWidgetProps & {
    border: {
      thickness?: number;
      color?: ColorConfig;
      style:
        | "dotted"
        | "dashed"
        | "solid"
        | "double"
        | "groove"
        | "ridge"
        | "inset"
        | "outset";
      radius: number;
    };
    padding: {
      top: Padding;
      left: Padding;
      right: Padding;
      bottom: Padding;
    };
  };
}

/**
 * Helper factory for checking whether a cell is of a specific type
 * @deprecated
 */
export function orderCellIs<T extends OrderWidgetType>(
  type: T,
  cell: OrderCell
): cell is OrderCell<T> {
  return cell.type === type;
}

export interface OrderWidgetPropMap {
  [OrderWidgetType.image]: Omit<
    ImageWidgetProps,
    "initPath" | "imageInfoIMG" | "setImageInfoIMG"
  >;
  [OrderWidgetType.fancyText]: FancyTextWidgetProps;
  [OrderWidgetType.richText]: RichTextWidgetProps;
  [OrderWidgetType.grid]: OrderGridWidgetProps;
  [OrderWidgetType.labelText]: {};
  [OrderWidgetType.labelFilter]: {};
  [OrderWidgetType.iconFavorite]: IconFavoriteWidgetProps;
  [OrderWidgetType.iconCart]: IconCartWidgetProps;
  [OrderWidgetType.emptySpace]: {};
}

// Adapters for feeding data into the widgets
export interface WidgetDatasourceMap {
  [OrderWidgetType.image]: "itemImage";
  [OrderWidgetType.fancyText]: "itemName" | "itemPrice";
  [OrderWidgetType.richText]: "itemDescription";
  [OrderWidgetType.grid]: never;
  [OrderWidgetType.labelText]: never;
  [OrderWidgetType.labelFilter]: never;
  [OrderWidgetType.iconFavorite]: never;
  [OrderWidgetType.iconCart]: never;
  [OrderWidgetType.emptySpace]: never;
}

export const createOrderCell = <T extends OrderWidgetType>(
  type: T
): OrderCell<T> => {
  const cell: OrderCell<T> = {
    id: uuid(),
    type,
    props: orderWidgetDefaultProps[type],
    elevation: 0,
    height: undefined,
    horizontalAlign: "center",
    verticalAlign: "center",
    overflowX: "hidden",
    overflowY: "hidden",
    // use [0,0] [0,0], unless the widget type specifies something else
    row1: 0,
    row2: 0,
    col1: 0,
    col2: 0,
    ...defaultPosition[type],
    border: {
      style: "solid",
      radius: 0,
    },
    padding: {
      top: { unit: "px", value: 0 },
      bottom: { unit: "px", value: 0 },
      left: { unit: "px", value: 0 },
      right: { unit: "px", value: 0 },
    },
  };
  return cell;
};

const orderWidgetDefaultProps: {
  [key in OrderWidgetType]: OrderWidgetPropMap[key];
} = {
  [OrderWidgetType.image]: {
    imageUrl: "",
    imageUrls: {},
    imageZoom: { x: 0, y: 0 },
    useZoom: false,
    useContain: false,
    imageMetadata: {
      width: 0,
      height: 0,
    },
    alt: "",
    widthValue: 100,
    heightValue: 100,
    heightUnit: "%",
    borderRadius: 4,
    elevation: 4,
    enlarge: false,
  },
  [OrderWidgetType.fancyText]: {
    text: "Placeholder Text",
    overflow: false,
    style: {
      text: "",
      paddingTop: 0.5,
      paddingLeft: 0.5,
      paddingRight: 0.5,
      paddingBottom: 0.5,
    },
  },
  [OrderWidgetType.richText]: {
    richText: "",
    overflow: false,
  },
  [OrderWidgetType.labelText]: {},
  [OrderWidgetType.labelFilter]: {},
  [OrderWidgetType.iconFavorite]: {},
  [OrderWidgetType.iconCart]: {},
  [OrderWidgetType.emptySpace]: {},
  [OrderWidgetType.grid]: {
    columns: 5,
    rows: 5,
    cells: [],
    background: {
      element: "color",
      color: { literal: "#f0f8ff" },
      opacity: 0,
      filter: "0, 0, 0",
      bgImageHeight: true,
    },
  },
};

const defaultPosition: {
  [key in OrderWidgetType]?: {
    row1: number;
    col1: number;
    row2: number;
    col2: number;
  };
} = {
  // [OrderWidgetType.logo]: {
  //   row1: 0,
  //   row2: 3,
  //   col1: 0,
  //   col2: 5,
  // },
  // [OrderWidgetType.wealthyText]: {
  //   row1: 0,
  //   row2: 5,
  //   col1: 0,
  //   col2: 12,
  // },
};

export type OrderCell<T extends OrderWidgetType = OrderWidgetType> = {
  id: string;
  type: T;
  // An optional string used by the item builder. Used to prevent placing
  //   two widgets with the same purpose. For example, there can be any
  //   number of fancy text components, but only one "name"
  duplicationId?: string;
  props: OrderWidgetPropMap[T];
  // Instructions for how to extract data and pass it into the props. Used
  //   to funnel data from the catalog item into the widgets.
  dataSource?: WidgetDatasourceMap[T];
  row1: number;
  col1: number;
  row2: number;
  col2: number;
  height?: number;
  elevation: number;
  horizontalAlign: "flex-start" | "flex-end" | "center";
  verticalAlign: "flex-start" | "center" | "flex-end";
  backgroundColor?: ColorConfig;
  overflowX: "auto" | "hidden" | "visible";
  overflowY: "auto" | "hidden" | "visible";
  border: {
    thickness?: number;
    color?: ColorConfig;
    style:
      | "dotted"
      | "dashed"
      | "solid"
      | "double"
      | "groove"
      | "ridge"
      | "inset"
      | "outset";
    radius: number;
  };
  padding: {
    top: Padding;
    left: Padding;
    right: Padding;
    bottom: Padding;
  };
};

export type FancyTextWidgetProps = {
  text: string;
  overflow: boolean;
  style: FancyText;
};

export type RichTextWidgetProps = {
  overflow: boolean;
  richText: string;
};

export interface ImageWidgetProps {
  imageUrl: string;
  imageUrls: {
    [key: string]: string;
  };
  imageZoom: {
    x: number;
    y: number;
  };
  useZoom: boolean;
  useContain: boolean;
  imageMetadata: {
    width: number;
    height: number;
    orientation?: "LANDSCAPE" | "PORTRAIT" | "SQUARE";
  };
  alt: string;
  widthValue: number;
  heightValue: number;
  heightUnit: "vw" | "px" | "%" | "em";
  borderRadius: number;
  elevation: number;
  enlarge: boolean;
}

export interface Padding {
  unit: "%" | "px";
  value: number;
  minValue?: number;
}

export interface IconFavoriteWidgetProps {}

export interface IconCartWidgetProps {}

export interface OrderGridWidgetProps {
  rows: number;
  columns: number;
  cells: OrderCell[];
  background: {
    element: "image" | "color";
    color?: ColorConfig;
    opacity: number;
    filter: "0, 0, 0" | "255, 255, 255";
    bgImageHeight: boolean;
  };
}
