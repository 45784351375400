import { FC } from "react";

export interface EmptySpaceWidgetProps {
  height: number;
  boxShadowX: number;
  boxShadowY: number;
  boxShadowSpread: number;
  boxShadowBlur: number;
  boxShadowColor: string;
  borderWidth: number;
  borderRadius: number;
  borderStyle:
    | "hidden"
    | "dotted"
    | "dashed"
    | "solid"
    | "double"
    | "groove"
    | "ridge"
    | "inset"
    | "outset"
    | "none"
    | undefined;
  borderColor: string;
  backgroundColor: undefined | string;
  dividerHeight: number;
  dividerRadius: number;
  dividerStyle:
    | "hidden"
    | "dotted"
    | "dashed"
    | "solid"
    | "double"
    | "groove"
    | "ridge"
    | "inset"
    | "outset"
    | "none";
  dividerColor: string;
}

const EmptySpaceWidget: FC<EmptySpaceWidgetProps> = ({
  height,
  backgroundColor,
  borderColor = "#000000",
  borderRadius = 0,
  borderStyle = "solid",
  borderWidth = 0,
  boxShadowBlur = 0,
  boxShadowColor = "#000000",
  boxShadowSpread = 0,
  boxShadowX = 0,
  boxShadowY = 0,
  dividerHeight,
  dividerRadius,
  dividerStyle,
  dividerColor,
}) => {
  const boxShadowUsed: boolean =
    (typeof boxShadowBlur === "number" && boxShadowBlur > 0) ||
    (typeof boxShadowSpread === "number" && boxShadowSpread > 0) ||
    (typeof boxShadowX === "number" && boxShadowX !== 0) ||
    (typeof boxShadowY === "number" && boxShadowY !== 0);

  const marginLeftBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowX && boxShadowX < 0
    ? Math.abs(
        (boxShadowX ?? 0) - (boxShadowSpread ?? 0) - (boxShadowBlur ?? 0)
      )
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);
  const marginRightBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowX && boxShadowX > 0
    ? (boxShadowX ?? 0) + (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0)
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);

  const marginTopBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowY && boxShadowY < 0
    ? Math.abs(
        (boxShadowY ?? 0) - (boxShadowSpread ?? 0) - (boxShadowBlur ?? 0)
      )
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);
  const marginBottomBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowY && boxShadowY >= 0
    ? (boxShadowY ?? 0) + (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0)
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);

  return (
    <div
      className="opacityHover gb-font-13"
      style={{
        height,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "rgba(0,0,0,0.54)",
        fontFamily: "Courier New",
        fontStyle: "italic",
        textAlign: "left",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        backgroundColor,
        borderRadius: borderRadius > 0 ? `${borderRadius}px` : undefined,
        border:
          borderWidth > 0
            ? `${borderWidth}px ${borderStyle} ${borderColor}`
            : undefined,
        boxShadow: boxShadowUsed
          ? `${boxShadowX ?? 0}px ${boxShadowY ?? 0}px ${
              boxShadowBlur ?? 0
            }px ${boxShadowSpread ?? 0}px ${boxShadowColor ?? "black"}`
          : undefined,
        marginTop: !boxShadowUsed ? undefined : `${marginTopBoxShadow}px`,
        marginRight: !boxShadowUsed ? undefined : `${marginRightBoxShadow}px`,
        marginBottom: !boxShadowUsed ? undefined : `${marginBottomBoxShadow}px`,
        marginLeft: !boxShadowUsed ? undefined : `${marginLeftBoxShadow}px`,
      }}
    >
      {dividerHeight > 0 ? (
        <div
          style={{
            width: "100%",
            borderRadius: dividerRadius > 0 ? `${dividerRadius}px` : undefined,
            border: `${dividerHeight}px ${dividerStyle} ${dividerColor}`,
          }}
        />
      ) : !backgroundColor ? (
        "DIVIDER"
      ) : (
        ""
      )}
    </div>
  );
};

export default EmptySpaceWidget;
