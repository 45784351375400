import { memo, Fragment, FC } from "react";
import { useTranslation } from "react-i18next";
import { Order } from "../../../database/order";
import getOrderIdStrings from "../../../utilities/getOrderIdString";
import Invoice from "../../Invoice/invoice";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { history } from "../../App/history";
import Dompurify from "dompurify";

interface STreceiptModalProps {
  order: Order;
}

const STreceiptModal: FC<STreceiptModalProps> = memo(({ order }) => {
  const { t } = useTranslation();
  const orderIdStrings = getOrderIdStrings(order.orderId);
  let finalId: string = "";

  for (const id of `${orderIdStrings.mainId} - ${orderIdStrings.subId}`) {
    if (+`${id}` === 0 && id !== " ") {
      finalId += `&#216;`;
    } else {
      finalId += `${id}`;
    }
  }

  return (
    <Fragment>
      <DialogTitle disableTypography>
        <Typography variant="caption" component="div">
          {t("store.orders.orderId")}
        </Typography>
        <Typography
          noWrap
          component="div"
          align="left"
          style={{
            fontFamily: "Arial",
          }}
        >
          <div
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            dangerouslySetInnerHTML={{
              __html: Dompurify.sanitize(finalId),
            }}
          />
        </Typography>
      </DialogTitle>
      <DialogContent className="smartWidthMd">
        <Invoice order={order} showAdminFeatures={false} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.goBack()}>{t("store.close")}</Button>
      </DialogActions>
    </Fragment>
  );
});

export default STreceiptModal;
