import { createContext, Dispatch, SetStateAction } from "react";
import { useContextThrowUndefined } from "../../../../utilities/useContextThrowUndefined";
import {
  CatalogAggregation,
  LabelAggregation,
} from "../../../../database/aggregations";

export interface CatalogValues {
  catalogSummaries: CatalogAggregation;
  setOptimisticUpdateCatalogSummaries: Dispatch<
    SetStateAction<CatalogAggregation | null>
  >;
  labels: LabelAggregation;
  setOptimisticUpdateLabels: Dispatch<SetStateAction<LabelAggregation | null>>;
}

export const CatalogContext = createContext<CatalogValues | undefined>(
  undefined
);

CatalogContext.displayName = "Catalog";

/**
 * Get the current data for the catalog
 */
export const useCatalogApi = () => useContextThrowUndefined(CatalogContext);
