/**
 * Delete any explict undefineds from an object. This can be useful because
 * Firebase doesn't like explicit undefineds
 */
export function deleteUndefineds<T>(obj: T): T {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * Delete any explict undefineds from an object. This can be useful because
 * Firebase doesn't like explicit undefineds
 *
 * Same as deleteUndefineds, but will recurse to child objects
 */
export function deleteUndefinedsRecursive<T>(obj: T): T {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      deleteUndefinedsRecursive(obj[key]);
    }
  }
  return obj;
}
