import { Typography } from "@material-ui/core";
import { FC } from "react";
import SearchIcon from "@material-ui/icons/Search";
import EmailIcon from "@material-ui/icons/Email";
import SettingsIcon from "@material-ui/icons/Settings";
import SortIcon from "@material-ui/icons/Restore";
import AddIcon from "@material-ui/icons/AddBox";
import QueueIcon from "@material-ui/icons/ViewColumn";
import InProgressIcon from "@material-ui/icons/Update";
import DoneIcon from "@material-ui/icons/Done";
import DriveIcon from "@material-ui/icons/DriveEta";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import WarningIcon from "@material-ui/icons/Warning";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Badge from "@material-ui/core/Badge";
import FlagIcon from "@material-ui/icons/Flag";

interface OrdersUIProps {}

const OrdersUI: FC<OrdersUIProps> = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "30% 70%",
        gridTemplateAreas: `
"StatusBar SearchBar"
"Statuses ItemRows"`,
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        borderRight: "1px solid lightgray",
        marginRight: "10px",
        marginLeft: "10px",
        maxHeight: "271px",
        overflow: "hidden",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          gridArea: "StatusBar",
          backgroundColor: "lightgray",
          padding: "0.5em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography style={{ fontSize: "0.7em" }}>
          <strong>STATUS</strong>
        </Typography>
      </div>
      <div
        style={{
          gridArea: "SearchBar",
          backgroundColor: "#0c343d",
          color: "white",
          display: "flex",
          alignItems: "center",
          padding: "0.5em",
        }}
      >
        <SearchIcon style={{ fontSize: "0.8em" }} />
        &nbsp;
        <Typography style={{ fontSize: "0.7em", opacity: 0.5 }}>
          Search by Email
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <EmailIcon style={{ fontSize: "0.8em" }} />
        &nbsp;&nbsp;
        <SortIcon style={{ fontSize: "0.8em" }} />
        &nbsp;&nbsp;
        <AddIcon style={{ fontSize: "0.8em" }} />
        &nbsp;&nbsp;
        <SettingsIcon style={{ fontSize: "0.8em" }} />
      </div>
      <div style={{ gridArea: "Statuses" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.25em",
            borderLeft: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <QueueIcon
            style={{
              fontSize: "0.8em",
            }}
          />
          &nbsp;
          <Typography
            style={{
              fontSize: "0.7em",
            }}
            noWrap
          >
            Not Started
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 0.25em",
            borderLeft: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            backgroundColor: "lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <InProgressIcon
            style={{
              fontSize: "0.8em",
            }}
          />
          &nbsp;
          <Typography
            style={{
              fontSize: "0.7em",
            }}
            noWrap
          >
            In Progress
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 0.25em",
            borderLeft: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <DoneIcon
            style={{
              fontSize: "0.8em",
            }}
          />
          &nbsp;
          <Typography
            style={{
              fontSize: "0.7em",
            }}
            noWrap
          >
            Ready
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 0.25em",
            borderLeft: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <DriveIcon
            style={{
              fontSize: "0.8em",
            }}
          />
          &nbsp;
          <Typography
            style={{
              fontSize: "0.7em",
            }}
            noWrap
          >
            In Transit
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 0.25em",
            borderLeft: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <DoneAllIcon
            style={{
              fontSize: "0.8em",
            }}
          />
          &nbsp;
          <Typography
            style={{
              fontSize: "0.7em",
            }}
            noWrap
          >
            Delivered
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 0.25em",
            borderLeft: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <Badge badgeContent={4} variant="dot" color="error">
            <AnnouncementIcon
              style={{
                fontSize: "0.8em",
              }}
            />
          </Badge>
          &nbsp;
          <Typography
            style={{
              fontSize: "0.7em",
            }}
            noWrap
          >
            Message
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 0.25em",
            borderLeft: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <WarningIcon
            style={{
              fontSize: "0.8em",
            }}
          />
          &nbsp;
          <Typography
            style={{
              fontSize: "0.7em",
            }}
            noWrap
          >
            Paused
          </Typography>
        </div>
      </div>
      <div style={{ gridArea: "ItemRows" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid lightgray",
          }}
        >
          <div style={{ width: "45%", padding: "0.5em 1em" }}>
            <div
              style={{
                border: "3px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
              }}
            />

            <div
              style={{
                marginTop: "0.5em",
                border: "2px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
                maxWidth: "50%",
              }}
            />
          </div>
          <Typography
            noWrap
            align="left"
            style={{ fontSize: "0.5em", whiteSpace: "nowrap", width: "53px" }}
          >
            In 5 mins
          </Typography>
          &nbsp;
          <div
            style={{
              maxWidth: "3em",
            }}
          >
            <Typography style={{ fontSize: "0.5em" }}>PICKUP</Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          &nbsp;
          <FlagIcon color="secondary" style={{ fontSize: "0.8em" }} />
          &nbsp;
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid lightgray",
          }}
        >
          <div style={{ width: "45%", padding: "0.5em 1em" }}>
            <div
              style={{
                border: "3px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
              }}
            />

            <div
              style={{
                marginTop: "0.5em",
                border: "2px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
                maxWidth: "50%",
              }}
            />
          </div>
          <Typography
            align="left"
            noWrap
            style={{ fontSize: "0.5em", whiteSpace: "nowrap", width: "53px" }}
          >
            In 5 mins
          </Typography>
          &nbsp;
          <div
            style={{
              maxWidth: "3em",
            }}
          >
            <Typography style={{ fontSize: "0.5em" }}>PICKUP</Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          &nbsp;
          <FlagIcon color="secondary" style={{ fontSize: "0.8em" }} />
          &nbsp;
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid lightgray",
          }}
        >
          <div style={{ width: "45%", padding: "0.5em 1em" }}>
            <div
              style={{
                border: "3px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
              }}
            />

            <div
              style={{
                marginTop: "0.5em",
                border: "2px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
                maxWidth: "50%",
              }}
            />
          </div>
          <Typography
            align="left"
            noWrap
            style={{ fontSize: "0.5em", whiteSpace: "nowrap", width: "53px" }}
          >
            In 23 mins
          </Typography>
          &nbsp;
          <div
            style={{
              maxWidth: "3em",
            }}
          >
            <Typography style={{ fontSize: "0.5em" }}>DELIVERY</Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          &nbsp;
          <FlagIcon color="secondary" style={{ fontSize: "0.8em" }} />
          &nbsp;
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid lightgray",
          }}
        >
          <div style={{ width: "45%", padding: "0.5em 1em" }}>
            <div
              style={{
                border: "3px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
              }}
            />

            <div
              style={{
                marginTop: "0.5em",
                border: "2px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
                maxWidth: "50%",
              }}
            />
          </div>
          <Typography
            align="left"
            noWrap
            style={{ fontSize: "0.5em", whiteSpace: "nowrap", width: "53px" }}
          >
            In 15 mins
          </Typography>
          &nbsp;
          <div
            style={{
              maxWidth: "3em",
            }}
          >
            <Typography align="left" style={{ fontSize: "0.5em" }}>
              PICKUP
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          &nbsp;
          <FlagIcon
            color="secondary"
            style={{ fontSize: "0.8em", opacity: 0 }}
          />
          &nbsp;
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid lightgray",
            width: "100%",
          }}
        >
          <div style={{ width: "45%", padding: "0.5em 1em" }}>
            <div
              style={{
                border: "3px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
              }}
            />

            <div
              style={{
                marginTop: "0.5em",
                border: "2px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
                maxWidth: "50%",
              }}
            />
          </div>
          <Typography
            align="left"
            noWrap
            style={{ fontSize: "0.5em", whiteSpace: "nowrap", width: "53px" }}
          >
            In 1 hr
          </Typography>
          &nbsp;
          <div
            style={{
              maxWidth: "3em",
            }}
          >
            <Typography align="left" style={{ fontSize: "0.5em" }}>
              DELIVERY
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          &nbsp;
          <FlagIcon
            color="secondary"
            style={{ fontSize: "0.8em", opacity: 0 }}
          />
          &nbsp;
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid lightgray",
            width: "100%",
          }}
        >
          <div style={{ width: "45%", padding: "0.5em 1em" }}>
            <div
              style={{
                border: "3px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
              }}
            />

            <div
              style={{
                marginTop: "0.5em",
                border: "2px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
                maxWidth: "50%",
              }}
            />
          </div>
          <Typography
            align="left"
            noWrap
            style={{ fontSize: "0.5em", whiteSpace: "nowrap", width: "53px" }}
          >
            In 1 hr
          </Typography>
          &nbsp;
          <div
            style={{
              maxWidth: "3em",
            }}
          >
            <Typography align="left" style={{ fontSize: "0.5em" }}>
              DELIVERY
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          &nbsp;
          <FlagIcon color="secondary" style={{ fontSize: "0.8em" }} />
          &nbsp;
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid lightgray",
            width: "100%",
          }}
        >
          <div style={{ width: "45%", padding: "0.5em 1em" }}>
            <div
              style={{
                border: "3px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
              }}
            />

            <div
              style={{
                marginTop: "0.5em",
                border: "2px solid rgba(0,0,0,0.24)",
                borderRadius: "4px",
                maxWidth: "50%",
              }}
            />
          </div>
          <Typography
            align="left"
            noWrap
            style={{ fontSize: "0.5em", whiteSpace: "nowrap", width: "53px" }}
          >
            In 3.5 hrs
          </Typography>
          &nbsp;
          <div
            style={{
              maxWidth: "3em",
            }}
          >
            <Typography align="left" style={{ fontSize: "0.5em" }}>
              PICKUP
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          &nbsp;
          <FlagIcon
            color="secondary"
            style={{ fontSize: "0.8em", opacity: 0 }}
          />
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export default OrdersUI;
