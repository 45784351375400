import { Fragment, FC } from "react";
import CartItems from "./Orders/cartItems";
import { CartItem, CartItemCollection } from "../../database/cart";

export interface SuborderSummaryProps {
  cartItems: CartItemCollection;
}

const SuborderSummary: FC<SuborderSummaryProps> = ({ cartItems }) => {
  return (
    <Fragment>
      {Object.values(cartItems).map((item: CartItem) => (
        <CartItems
          key={item.cartItemId}
          catalogItem={item.catalogItem}
          cartItem={item}
        />
      ))}
    </Fragment>
  );
};

export default SuborderSummary;
