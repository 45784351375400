import { Fragment, FC } from "react";
import { useSiteSettings } from "../../../../customization/siteSettingsContext";
import { CustomWidgetTemplate } from "../../../../database/page";
import RowWidget from "./rowWidget";

export interface CustomWidgetProps {
  template?: CustomWidgetTemplate;
  templateId?: string;
}

const CustomWidget: FC<CustomWidgetProps> = ({ template, templateId }) => {
  const { customWidgets } = useSiteSettings();
  if (!template && templateId) {
    template = customWidgets[templateId];
  }
  if (!template) {
    return <div>Template missing</div>;
  }
  return (
    <Fragment>
      {template.rows.map((row) => (
        <RowWidget key={row.id} {...row.props} />
      ))}
    </Fragment>
  );
};

export default CustomWidget;
