import { useState, useEffect } from "react";
import useSiteUser from "../components/UserProvider/useSiteUser";
import { useLocationId } from "../customization/siteSettingsContext";
import { PrivateSiteSettings } from "../database/privateSiteSettings";
import { useSiteFirebase } from "../Firebase/context";
import { storeKey } from "../Firebase/siteFirebase";

export const usePrivateSiteSettings = () => {
  const siteFirebase = useSiteFirebase();
  const locationId = useLocationId();
  const { role } = useSiteUser();

  const [privateSiteSettings, setPrivateSiteSettings] =
    useState<PrivateSiteSettings>();
  useEffect(() => {
    if (!role) {
      // Customer
      setPrivateSiteSettings(undefined);
      return;
    }
    return siteFirebase.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("locations")
      .doc(locationId)
      .collection("private")
      .doc("privateSettings")
      .onSnapshot(
        (doc) => {
          setPrivateSiteSettings(doc.data() as PrivateSiteSettings | undefined);
        },
        (err) => {
          console.log("error getting private settings", err);
          setPrivateSiteSettings(undefined);
        }
      );
  }, [locationId, role, siteFirebase.firestore]);

  return privateSiteSettings;
};
