import { FC, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { PhoneNumber, PostalAddress } from "../../../database/siteSettings";
import {
  getNextTimeOpen_fns,
  isOpenAt_fns,
} from "../../../utilities/checkStoreOpen";
import ROUTES from "../../../utilities/constants/routes";
import usePrevious from "../../../utilities/usePrevious";
import {
  deserializeDate,
  serializeDate,
  useStateWithSessionStorage,
} from "../../../utilities/useStateWithStorage";
import useSiteUser from "../../UserProvider/useSiteUser";
import CheckoutOptions from "./checkoutOptions";
import SectionUserSO from "./sectionUserSO";
import Container from "@material-ui/core/Container";
import { add, differenceInMinutes } from "date-fns";
import { history } from "../../App/history";

interface CheckoutMainProps {}

export interface GuestDetails {
  email: string;
  phone: PhoneNumber | null;
  pushToken: string | null;
}

export type DeliveryOption = "pickup" | "delivery"; //| "SHIPPING";

const defaultAddress: PostalAddress = {
  city: "",
  countryCode: "",
  stateCode: "",
  streetLine1: "",
  streetLine2: "",
  streetLine3: "",
  zip: "",
};

const CheckoutMain: FC<CheckoutMainProps> = () => {
  const { user } = useSiteUser();
  const siteSettings = useSiteSettings();
  const { orderIncrement, tips } = siteSettings;

  const [guestDetails, setGuestDetails] =
    useStateWithSessionStorage<GuestDetails>({
      key: "guestDetails",
      defaultValue: { email: "", phone: null, pushToken: null },
    });

  const [deliveryOption, setDeliveryOption] =
    useStateWithSessionStorage<DeliveryOption>({
      key: "deliveryOption",
      defaultValue: "pickup",
    });

  const [addressInfo, setAddressInfo] = useStateWithSessionStorage({
    key: "addressInfo",
    defaultValue: defaultAddress,
  });

  const [requestedTime, setRequestedTime] = useStateWithSessionStorage<Date>({
    key: "requestedTime",
    defaultValue:
      getNextTimeOpen_fns(
        add(new Date(), { minutes: orderIncrement + 1 }),
        siteSettings
      ) || new Date(),
    serialize: serializeDate,
    deserialize: deserializeDate,
  });

  const [tipPercent, setTipPercent] = useStateWithSessionStorage({
    key: "tip",
    defaultValue: tips.defaultPercent,
  });

  const prevUser = usePrevious(user);
  useEffect(() => {
    if (prevUser && !user && window.location.pathname !== ROUTES.CHECKOUT) {
      // User logged out. Clear their info, and go back to first step
      setDeliveryOption("pickup");
      setAddressInfo(defaultAddress);
      setGuestDetails({
        email: "",
        phone: null,
        pushToken: null,
      });
      console.log("User logged out; redirect to first step");
      history.replace(ROUTES.CHECKOUT);
    } else if (
      prevUser === null &&
      user &&
      window.location.pathname === ROUTES.CHECKOUT
    ) {
      console.log("User logged in; redirect to second step");
      history.replace(`${ROUTES.CHECKOUT}/options`);
    }
  }, [
    prevUser,
    setAddressInfo,
    setDeliveryOption,
    setGuestDetails,
    siteSettings,
    user,
  ]);

  useEffect(() => {
    const validateTime = () => {
      setRequestedTime((prev) => {
        const earliest = add(new Date(), {
          minutes: siteSettings.orderIncrement,
        });
        if (prev && differenceInMinutes(prev, earliest) > 0) {
          // Selected time is still ok
          return prev;
        }

        // If they sit on the checkout screen for a while the requested time may get
        //    to close to the current time. When that happens, we bump it forward
        // console.warn("checkoutMain. GOING TO ADJUST ORDER TIME AUTOMATICALLY.");
        if (isOpenAt_fns(earliest, siteSettings)) {
          // IF THE STORE IS OPEN AT THE EARLIEST
          // THEN WE CAN USE IT
          // console.warn(
          //   "checkoutMain. GOING TO ADJUST ORDER TIME TO NEXT INCREMENT.",
          //   earliest
          // );
          return earliest;
        } else {
          // ELSE CHECK FOR THE EARLIEST AVAILABLE TIME SLOT
          const newTime = getNextTimeOpen_fns(earliest, siteSettings);
          if (newTime) {
            // console.warn(
            //   "checkoutMain. GOING TO ADJUST ORDER TIME TO NEXT AVAILABLE SLOT.",
            //   newTime
            // );
            return newTime;
          } else {
            // Store is never open; don't bother changing the time
            return prev;
          }
          // NOT SETTING A TOAST AT THE MOMENT AS IT MIGHT MESS THINGS UP
          // WHEN THE USER HAS A MODAL OPEN
          // toast({
          //   color: "normal",
          //   dialog: true,
          //   message: t("store.orders.adjustedTime"),
          // });
        }
      });
    };

    // Validate immediately, unless we have a weird configuration which would cause
    //   an infinite loop of validate/render/validate/render, etc.
    if (siteSettings.orderIncrement > 0) {
      validateTime();
    }
    // In addition, validate as time passes.
    let id = setInterval(validateTime, 3000);

    return () => clearInterval(id);
  }, [setRequestedTime, siteSettings]);

  return (
    <Switch>
      <Route path={`${ROUTES.CHECKOUT}/options`}>
        <Container maxWidth="sm" style={{ padding: 0 }}>
          <CheckoutOptions
            deliveryOption={deliveryOption}
            setDeliveryOption={setDeliveryOption}
            requestedTime={requestedTime}
            setRequestedTime={setRequestedTime}
            guestDetails={guestDetails}
            setGuestDetails={setGuestDetails}
            tipPercent={tipPercent}
            setTipPercent={setTipPercent}
            addressInfo={addressInfo}
          />
        </Container>
      </Route>
      <Route>
        {user ? (
          // They're signed in, so they bypass the guest/login screen
          <Redirect to={`${ROUTES.CHECKOUT}/options`} />
        ) : (
          <div
            className="anim_fadeIn_0503"
            style={{ opacity: 0, padding: "1em" }}
          >
            <SectionUserSO
              guestDetails={guestDetails}
              setGuestDetails={setGuestDetails}
            />
          </div>
        )}
      </Route>
    </Switch>
  );
};

export default CheckoutMain;
