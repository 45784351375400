import { FC, useMemo } from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import { useSiteSettings } from "../../customization/siteSettingsContext";
import { colorLuminance, getOpacityColor } from "../../utilities/colorUtils";
import { ThemeColor } from "../../database/theme";

const StoreTheme: FC = ({ children }) => {
  const { theme, theme2 } = useSiteSettings();

  /**
   * a primary color,
   * secondary color,
   * surface color,
   * background color,
   * error color.
   *
   * When a color appears “on” top of a primary color,
   * it’s called an “on primary color.” They are labelled using
   * the original color category (such as primary color) with the
   * prefix “on.”
   *
   * “On” colors are primarily applied to text, iconography,
   * and strokes. Sometimes, they are applied to surfaces.
   *
   * The default values for “on” colors are #FFFFFF and #000000.
   */

  const backgroundDark = "#d0d0d0";
  const backgroundLight = "#ffffff";

  /** ADMIN EDITABLE COLORS BEG */
  const primary = theme2.colors[ThemeColor.primary].value;
  const primaryLight = theme2.colors[ThemeColor.primaryLight].value;
  const primaryDark = theme2.colors[ThemeColor.primaryDark].value;
  const onPrimary = theme2.colors[ThemeColor.onPrimary].value; // TEXT / ICON ON PRIMARY
  const secondary = theme2.colors[ThemeColor.accent].value;
  const onSecondary = theme2.colors[ThemeColor.onAccent].value;
  const textPrimary = theme2.colors[ThemeColor.textPrimary].value;
  const textSecondary = theme2.colors[ThemeColor.textSecondary].value;
  /** ADMIN EDITABLE COLORS END */

  const success = "#76ff03";
  const error = "#B22222";

  const dialogBgColor = primaryLight;
  const popoverBgColor = getOpacityColor(primary, 0.1);

  const storeTheme = useMemo(() => {
    const temp = createMuiTheme({
      palette: {
        primary: {
          main: primary,
          dark: primaryDark,
          light: primaryLight,
          contrastText: onPrimary,
        },
        secondary: {
          main: secondary,
          dark: colorLuminance(secondary, -0.2),
          light: colorLuminance(secondary, 0.2),
          contrastText: onSecondary,
        },
        text: {
          primary: textPrimary,
          secondary: textSecondary,
          disabled: textSecondary,
        },
        error: {
          main: error,
        },
        success: {
          main: success,
        },
        background: {
          default: backgroundDark,
          paper: backgroundLight,
        },
      },
      typography: {
        // Use the font selected by the admin, or fall back to material ui's defaults
        fontFamily: `"${theme.appFont}", "Roboto", "Helvetica", "Arial", "sans-serif"`,
      },
      overrides: {
        MuiTypography: {
          colorInherit: { color: textPrimary },
          colorTextSecondary: { color: textSecondary },
        },
        MuiFilledInput: {
          underline: {
            "&:before": { borderBottom: 0 },
          },

          root: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            // backgroundColor: primaryLight,
          },
        },
        MuiDrawer: {
          paper: { backgroundColor: primaryLight },
        },
        MuiDialog: {
          paper: { backgroundColor: dialogBgColor },
        },
        MuiPopover: {
          paper: { backgroundColor: popoverBgColor },
        },
        MuiBackdrop: {
          root: {
            bottom: "-100%",
          },
        },
        MuiCardHeader: {
          root: { textAlign: "left" },
          avatar: {
            // color: "white",
            // backgroundColor: "#395e67",
            width: "40px",
            height: "40px",
            display: "flex",
            overflow: "hidden",
            position: "relative",
            fontSize: "1.25rem",
            alignItems: "center",
            flexShrink: 0,
            // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            lineHeight: "1",
            userSelect: "none",
            borderRadius: "50%",
            justifyContent: "center",
          },
        },
        MuiAppBar: {
          root: {
            opacity: 0,
            boxShadow: "none",
            justifyContent: "center",
            height: "66px",
            maxHeight: "66px",
          },
        },
        MuiInputBase: {
          input: { "&::placeholder": { color: textSecondary, opacity: 1 } },
        },
        MuiAvatar: {
          colorDefault: {
            color: onPrimary,
            backgroundColor: primaryLight,
          },
        },
        MuiListItemText: {
          primary: { color: textPrimary },
        },
        MuiListItemIcon: {
          root: { color: textPrimary },
        },

        MuiIconButton: {
          root: {
            color: primary,
            "&$disabled": {
              color: textSecondary,
            },
          },
        },

        MuiButton: {
          text: { color: primaryDark },
          outlined: { color: primaryDark, border: `1px solid ${primaryDark}` },
          contained: { backgroundColor: primary, color: onPrimary },
        },
      },
      props: {
        MuiButtonBase: {
          disableRipple: true,
        },
        MuiButtonGroup: {
          disableRipple: true,
        },
        MuiIconButton: {
          disableRipple: true,
        },
      },
    });

    return responsiveFontSizes(temp);
  }, [
    primary,
    primaryDark,
    primaryLight,
    onPrimary,
    secondary,
    textPrimary,
    theme.appFont,
    dialogBgColor,
    popoverBgColor,
    onSecondary,
    textSecondary,
  ]);

  return <ThemeProvider theme={storeTheme}>{children}</ThemeProvider>;
};

export default StoreTheme;
