import {
  cellIs,
  PageWidgetType,
} from "../../../../Store/PageRenderer/widgetType";
import { UpdateWidgetPropsAction } from "./actions";
import { PageState } from "./reducer";
import { getCell, setCell } from "./locatorUtils";

/**
 * Reducer for updating a widget.
 */
export const updateWidgetProps = <T extends PageWidgetType>(
  state: PageState,
  action: UpdateWidgetPropsAction<T>
): PageState => {
  const { cellLocator, cellType, partialProps } = action;

  const cell = getCell(state, cellLocator);
  if (!cell || !cellIs(cellType, cell)) {
    return state;
  }

  const newCell = {
    ...cell,
    props: {
      ...cell.props,
      ...partialProps,
    },
  };

  return setCell(state, cellLocator, newCell);
};
