import { memo, Fragment, useState, FC } from "react";
import { validateEmail } from "../../../utilities/validateEmailPassPhone";
import STauthModal from "./sTauth";
import useSpinner from "../../Spinner/useSpinner";
import { useTranslation } from "react-i18next";
import useSiteUser from "../../UserProvider/useSiteUser";
import { history } from "../../App/history";
import { useSiteFirebase } from "../../../Firebase/context";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { createSearchStrings } from "../../../utilities/createSearchStrings";
import { storeKey } from "../../../Firebase/siteFirebase";
import useToast from "../../Main/useToast";
import MUIDialog from "../../Dashboard/Components/mui-dialog";

interface AccountEmailProps {
  accountEmail: string;
  initPath: string;
}

const AccountEmail: FC<AccountEmailProps> = memo(
  ({ accountEmail, initPath }) => {
    const { user } = useSiteUser();
    const showSpinner = useSpinner();
    const { t } = useTranslation();
    const firebase = useSiteFirebase();
    const [newEmail, setNewEmail] = useState(accountEmail);
    const emailIsValid = validateEmail(newEmail);
    const toast = useToast();

    async function changeEmail() {
      if (!user) {
        return;
      } else if (!emailIsValid) {
        toast({
          message: t("store.account.badEmail"),
          color: "error",
          dialog: true,
        });

        return;
      }

      if (newEmail === user.email) {
        history.goBack();
        return;
      }

      const hideSpinner = showSpinner({ lag: "none" });
      try {
        const email = newEmail.trim().toLowerCase();
        await firebase.updateEmail(email);
        await firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("users")
          .doc(user.uid)
          .update({
            "contactInfo.email": email,
            "contactInfo.emailSearch": createSearchStrings(email),
          });

        history.goBack();
        try {
          firebase.sendVerificationEmail();
        } catch (error) {
          console.error("Email verification error", error);
        }
      } catch (error) {
        console.log("Change Email ", error);
        if (
          error.code === "auth/requires-recent-login" ||
          error.code === "auth/user-token-expired"
        ) {
          const path = `${initPath}/reauthenticate`;
          if (window.location.pathname !== path) {
            history.push(path);
          }
        } else {
          // NEEDS TESTING
          toast({
            message: error.message,
            color: "error",
            dialog: true,
          });
        }
      } finally {
        hideSpinner();
      }
    }
    return (
      <Fragment>
        <MUIDialog route={`${initPath}/reauthenticate`}>
          <STauthModal
            message={t("store.account.reauthToChangeEmail")}
            showCreateAccount={false}
            afterSignin={() => {
              history.goBack();
              changeEmail();
            }}
          />
        </MUIDialog>

        <DialogContent className="smartWidthMd">
          <FormControl fullWidth>
            <TextField
              fullWidth
              autoComplete="new-password"
              type="email"
              label={t("store.account.enterEmail")}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value.trim())}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.goBack()}>{t("store.cancel")}</Button>
          <Button onClick={(e) => changeEmail()}>{t("store.submit")}</Button>
        </DialogActions>
      </Fragment>
    );
  }
);

export default AccountEmail;
