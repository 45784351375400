import {
  memo,
  forwardRef,
  Fragment,
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ROUTES from "../../../../utilities/constants/routes";
import "../../css/orders.css";
import { CatalogItem_WithDefaults } from "../../../../database/catalogItem";
import MUIDialog from "../../../Dashboard/Components/mui-dialog";
import {
  OrderPageOptions,
  OrderTemplate,
  orderCellIs,
  OrderWidgetType,
  OrderCell,
} from "../../../../database/orderPage";
import { loadFonts } from "../../../../utilities/fontLoader";
import { getFontsInRichText } from "../../../../utilities/getFontsInRichText";
import GridWidget from "./gridWidget";
import AddToCart from "../addToCart";
import { BuilderContext } from "../../../Dashboard/Builder/OrdersBuilder/builderContext";
import useCartApi from "../../../CartManager/useCartApi";
import useToast from "../../../Main/useToast";
import { useTranslation } from "react-i18next";

export interface ItemProps {
  templateOptions: OrderPageOptions<OrderTemplate.original>;
  catalogItem: CatalogItem_WithDefaults;
  style?: CSSProperties;
  widthCorrection: number;
  rowGapOrig: number;
  columnGapOrig: number;
}

const ItemOrder = memo(
  forwardRef<HTMLDivElement, ItemProps>(
    (
      {
        templateOptions,
        catalogItem,
        style,
        widthCorrection,
        columnGapOrig,
        rowGapOrig,
      },
      ref
    ) => {
      const { t } = useTranslation();
      const toast = useToast();
      const cartApi = useCartApi();
      const builder = useContext(BuilderContext);

      useEffect(() => {
        const descriptions = Array.isArray(catalogItem.description)
          ? catalogItem.description
          : [catalogItem.description];
        const fonts = descriptions.flatMap((description) =>
          getFontsInRichText(description)
        );
        loadFonts(fonts);
      }, [catalogItem.description]);

      const { rowGap, columnGap, rowUnit, rowValue } = templateOptions.props;

      const { cells, border, padding, background } =
        templateOptions.props.itemProps;

      // How many widgets will be trying to render an image. Those widgets will
      //   let us know when they succeed of fail, and once they all report in
      //   we fade in the card.
      const [imageWidgetCount] = useState(() => {
        let count = 0;
        if (background.element === "image") {
          count++;
        }
        const checkCells = (cells: OrderCell[]) => {
          for (const cell of cells) {
            if (orderCellIs(OrderWidgetType.image, cell)) {
              count++;
            } else if (orderCellIs(OrderWidgetType.grid, cell)) {
              if (cell.props.background.element === "image") {
                count++;
              }
              // Recursively check child cells
              checkCells(cell.props.cells);
            }
          }
          return false;
        };

        checkCells(cells);

        return count;
      });
      // As image widgets render, they will let us know when they're done, updating this count
      const [imageReadyCount, setImageReadyCount] = useState(0);
      // If an image widget doesn't have a url, this number will get updated
      const [imageMissingCount, setImageMissingCount] = useState(0);

      const onImageReady = useCallback(
        (result: "loaded" | "error" | "noUrl") => {
          setImageReadyCount((prev) => prev + 1);
          if (result !== "loaded") {
            setImageMissingCount((prev) => prev + 1);
          }
        },
        []
      );

      const animationClassName = useMemo(() => {
        if (rowGap !== rowGapOrig || columnGap !== columnGapOrig) {
          /**
           * SINCE WE UNMOUNT THE COMPONENT PURPOSELY WHEN TWEAKING THE
           * GRID GAPS DUE TO A SAFARI BUG, WE DONT WANT THE ANIMATION
           * TO SHOW
           */
          return "";
        }
        const allImagesReady = imageReadyCount >= imageWidgetCount;
        const allImagesMissing = imageMissingCount >= imageWidgetCount;
        if (imageWidgetCount === 0) {
          /**
           * IF NO IMAGE IS USED SET THE DESIRED CLASSNAME FOR THE GRIDITEM
           * TO MOVE UP AND FADE IN
           */
          return "anim_moveUp_small_0302";
        } else if (allImagesReady && allImagesMissing) {
          /**
           * IF THERE'S AN ERROR OR NO IMAGE UPLOADED
           * THEN SLOW DOWN THE ANIMATION ELSE WE HAVE A JUMP
           */
          return "anim_moveUp_small_0305";
        } else if (allImagesReady) {
          /**
           * ELSE SET THE DESIRED CLASSNAME FOR THE GRIDITEM
           * TO MOVE UP AND FADE IN
           */
          return "anim_moveUp_small_0302";
        }
      }, [
        rowGap,
        rowGapOrig,
        columnGap,
        columnGapOrig,
        imageReadyCount,
        imageWidgetCount,
        imageMissingCount,
      ]);

      return (
        <Fragment>
          <MUIDialog
            route={
              builder
                ? `${ROUTES.DASHBOARD_BUILDER}/orders/details/${catalogItem.itemId}`
                : `${ROUTES.ORDER}/details/${catalogItem.itemId}`
            }
          >
            <AddToCart
              catalogItem={catalogItem}
              submitButton={t("store.orders.addToCart")}
              onSubmit={(count, instructions, selectedOptions) => {
                if (!builder) {
                  cartApi.addItem(
                    catalogItem,
                    count,
                    instructions,
                    selectedOptions
                  );
                  toast({
                    duration: 1200,
                    message: t("store.orders.addedToCart"),
                    color: "error",
                  });
                }
              }}
            />
          </MUIDialog>

          <div
            ref={ref}
            className={animationClassName}
            style={{
              opacity:
                rowGap === rowGapOrig && columnGap === columnGapOrig ? 0 : 1,
              ...style,
            }}
          >
            <GridWidget
              {...templateOptions.props.itemProps}
              catalogItem={catalogItem}
              onImageReady={onImageReady}
              border={border}
              padding={padding}
              rowUnit={rowUnit}
              rowValue={rowValue}
              //WE NEED SOME GRID PARENT PROPS TO CALCULATES THE SIZES
              //VALUE FOR THE IMAGE
              parentPBSm={templateOptions.props.breakpointSm}
              parentPBLg={templateOptions.props.breakpointLg}
              parentColumnSm={templateOptions.props.columnsSm}
              parentColumnMd={templateOptions.props.columnsMd}
              parentColumnLg={templateOptions.props.columnsLg}
              parentColumnGap={templateOptions.props.columnGap}
              widthCorrection={widthCorrection}
            />
          </div>
        </Fragment>
      );
    }
  )
);

export default ItemOrder;
