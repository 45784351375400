import { Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FC, useEffect, useRef, useState } from "react";
import * as React from "react";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { unstable_batchedUpdates } from "react-dom";
import { unsubscribeOrderEmails } from "../../../utilities/httpsCallables/httpsCallables";
import { useSiteFirebase } from "../../../Firebase/context";
import Spinner from "../../Spinner/spinner";
import { useUnmount } from "../../../utilities/useUnmount";

interface UnsubscribeProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Unsubscribe: FC<UnsubscribeProps> = ({ setMainBgImage }) => {
  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  const firebase = useSiteFirebase();
  const { orderId, userId } = useParams<{ orderId: string; userId?: string }>();

  const [loading, setLoading] = useState(Boolean(orderId));
  const [error, setError] = useState<any>(null);
  const [subscribed, setSubscribed] = useState(true);

  const mounted = useRef(true);
  useUnmount(() => (mounted.current = false));
  const changeSubscription = async (resubscribe: boolean) => {
    try {
      setLoading(true);
      await unsubscribeOrderEmails(firebase, {
        orderId,
        userId: userId ?? null,
        resubscribe,
      });
      unstable_batchedUpdates(() => {
        setLoading(false);
        setSubscribed(resubscribe);
      });
    } catch (err) {
      console.error(err);
      unstable_batchedUpdates(() => {
        setLoading(false);
        setError(err);
      });
    }
  };
  useEffect(() => {
    if (orderId) {
      changeSubscription(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Spinner isOpen lag="none" />;
  }

  if (error) {
    return (
      <Container maxWidth="md" style={{ marginTop: "max(1.5em, 1.5vw)" }}>
        <Typography color="textPrimary">
          {t("store.checkout.unsubscribeError")}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" style={{ marginTop: "max(1.5em, 1.5vw)" }}>
      <Typography variant="h5">
        {subscribed
          ? t("store.checkout.resubscribed")
          : t("store.checkout.unsubscribed")}
      </Typography>
      <Typography
        color="textSecondary"
        style={{ visibility: subscribed ? "hidden" : "visible" }}
      >
        {t("store.checkout.noLongerEmails")}
      </Typography>
      <div style={{ marginTop: "max(1em, 1vw)" }} />
      <Typography>
        {subscribed
          ? t("store.checkout.unsubscribe")
          : t("store.checkout.resubscribe")}
      </Typography>
      <div style={{ marginTop: "max(1em, 1vw)" }} />
      <Button
        startIcon={<ArrowRightIcon />}
        onClick={(e) => {
          e.stopPropagation();
          changeSubscription(!subscribed);
        }}
      >
        {subscribed
          ? t("store.checkout.unsubscribeAgain")
          : t("store.checkout.subscribeAgain")}
      </Button>
    </Container>
  );
};

export default Unsubscribe;
