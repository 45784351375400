import { useEffect, useState } from "react";

/**
 * An "optimistic update" is where we assume a write to the database
 * is going to be successful, and thus update the ui immediately.
 * This lets us give the impression that operations are instantaneous
 * even though they can take a bit of time to finish.
 *
 * This hook creates a state for temporarily overriding the db value
 */
export const useOptimisticUpdate = <T>(valueInDb: T) => {
  const [optimisticUpdate, setOptimisticUpdate] =
    useState<NonNullable<T> | null>(null);
  useEffect(() => {
    // When the value in the database changes, remove the override
    setOptimisticUpdate(null);
  }, [valueInDb]);
  const value = optimisticUpdate ?? valueInDb;
  return [value, setOptimisticUpdate] as const;
};
