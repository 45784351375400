import { createContext } from "react";
import { useContextThrowUndefined } from "../utilities/useContextThrowUndefined";
import { GridbashFirebase } from "./gridBashFirebase";
import { SiteFirebase } from "./siteFirebase";

export const GridBashFirebaseContext =
  createContext<GridbashFirebase | undefined>(undefined);

GridBashFirebaseContext.displayName = "GridBash Firebase";

/**
 * Gets the firebase instance for the master gridbash project
 */
export const useGridBashFirebase = () =>
  useContextThrowUndefined(GridBashFirebaseContext);

export const SiteFirebaseContext =
  createContext<SiteFirebase | undefined>(undefined);

SiteFirebaseContext.displayName = "Site Firebase";

/**
 * Gets the firebase instance for the individual site
 */
export const useSiteFirebase = () =>
  useContextThrowUndefined(SiteFirebaseContext);
