import { Fragment, FC } from "react";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import ROUTES from "../../../utilities/constants/routes";
import { history } from "../../App/history";
import PersonIcon from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { GuestDetails } from "./checkoutMain";
import useWindowResize from "../../../utilities/useWindowResize";
import Tooltip from "@material-ui/core/Tooltip";

interface GuestCheckoutProps {
  guestDetails: GuestDetails;
  builder: boolean;
}

const GuestCheckout: FC<GuestCheckoutProps> = ({ guestDetails, builder }) => {
  const dimensions = useWindowResize("guestChecout");
  const siteSettings = useSiteSettings();
  const { t } = useTranslation();

  return (
    <div
      className={!builder ? "checkoutButton" : undefined}
      style={{
        opacity: 1,
        display: "grid",
        gridTemplateColumns: dimensions.w >= 768 ? "12em 1fr" : "auto 1fr",
        gridAutoRows: "4em",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "1em",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (builder) return;
        if (!window.location.pathname.includes("guest")) {
          history.push(`${ROUTES.CHECKOUT}/options/guest`);
        }
      }}
    >
      <Tooltip
        title={
          !builder
            ? ""
            : `${t("dashboard.builder.primaryDark")} - ${t(
                "dashboard.builder.onPrimary"
              )}`
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: dimensions.w >= 768 ? "flex-start" : "center",
            paddingLeft: "1em",
            paddingRight: "1em",
            backgroundColor: siteSettings.theme2.colors.primaryDark.value,
            color: siteSettings.theme2.colors.onPrimary.value,
            height: "100%",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        >
          <PersonIcon />
          {dimensions.w >= 768 && (
            <Fragment>
              &nbsp;&nbsp;
              <Typography>{t("store.orders.guestCheckout")}</Typography>
            </Fragment>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title={
          !builder
            ? ""
            : `${t("dashboard.builder.primaryLight")} - ${t(
                "dashboard.builder.textPrimary"
              )}`
        }
      >
        <div
          style={{
            backgroundColor: siteSettings.theme2.colors.primaryLight.value,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            overflow: "hidden",
            padding: "0 1em",
          }}
        >
          <Typography noWrap color="textPrimary">
            {guestDetails.email}
          </Typography>
          <Typography noWrap variant="body2" color="textPrimary">
            {guestDetails.phone ? guestDetails.phone.formatted : ``}
          </Typography>
        </div>
      </Tooltip>
    </div>
  );
};

export default GuestCheckout;
