import { lazy, Suspense, FC, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Site } from "../../database/site";
import {
  SiteFirebaseContext,
  useGridBashFirebase,
} from "../../Firebase/context";
import { SiteFirebase } from "../../Firebase/siteFirebase";
import ROUTES from "../../utilities/constants/routes";

const App = lazy(() => import("../App/App"));

const SiteLoader: FC = () => {
  const history = useHistory();
  const gridBashFirebase = useGridBashFirebase();
  const [siteFirebase, setSiteFirebase] = useState<SiteFirebase | null>(null);
  const match = useRouteMatch<{ path: string | undefined }>();
  const { path } = match.params;
  useEffect(() => {
    if (!path) {
      history.replace("/");
    } else {
      let unmounted = false;
      setSiteFirebase(null);
      ROUTES.rootPath = `/${path}`;
      const initSiteFirebase = async () => {
        const snapshot = await gridBashFirebase.firestore
          .collection("sites")
          .where("path", "==", path)
          .get();

        if (unmounted) return;

        if (snapshot.docs.length === 0) {
          // site doesn't exist, go to the home page
          history.replace("/");
          return;
        } else if (snapshot.docs.length > 1) {
          // Uh oh, the paths aren't unique. This should never happen and
          //   indicates a bug.
          // TODO: send us an email alerting us to the bug
        }

        const site = snapshot.docs[0].data() as Site;
        const firebaseInstance = SiteFirebase.createInstance({
          config: site.firebaseConfig,
          name: "site" + site.siteId,
          vapidKey: site.vapidKey,
          functionRegion: site.region,
        });
        setSiteFirebase(firebaseInstance);
      };
      initSiteFirebase();
      return () => {
        unmounted = true;
      };
    }
  }, [gridBashFirebase.firestore, history, path]);

  if (!siteFirebase) {
    // still loading
    // TODO: placeholder?
    return null;
  }

  return (
    <SiteFirebaseContext.Provider value={siteFirebase}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </SiteFirebaseContext.Provider>
  );
};

export default SiteLoader;
