import { Fragment, FC, memo, useContext, useState } from "react";
import SignedInIcon from "@material-ui/icons/Person";
import SignedOutIcon from "@material-ui/icons/HowToReg";
import { IconButton } from "@material-ui/core";
import useSiteUser from "../../UserProvider/useSiteUser";
import { history } from "../../App/history";
import { BuilderContext } from "../../Dashboard/Builder/OrdersBuilder/builderContext";
import { useThemeColor } from "../../widgets/useThemeColor";
import { useLocation } from "react-router-dom";
import MUIPopover from "../../Dashboard/Components/mui-popover";
import MyAccountMenu from "./myAccountMenu";
import { useSiteSettings } from "../../../customization/siteSettingsContext";

interface UserButtonProps {}

const UserButton: FC<UserButtonProps> = memo(() => {
  const { user } = useSiteUser();
  const themeColor = useThemeColor();
  const builder = useContext(BuilderContext);
  const location = useLocation<{
    myAccountHeader?: boolean;
    signIn?: boolean;
  }>();
  const { navigationMenu } = useSiteSettings();

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <Fragment>
      <IconButton
        color="inherit"
        onClick={(e) => {
          e.stopPropagation();
          if (builder) {
            return;
          } else if (user) {
            if (location.state?.myAccountHeader !== true)
              history.push(location.pathname, {
                ...location.state,
                myAccountHeader: true,
              });
          } else {
            if (location.state?.signIn !== true)
              history.push(location.pathname, {
                ...location.state,
                signIn: true,
              });
          }
          setAnchor(e.currentTarget);
        }}
      >
        {!user ? (
          <SignedInIcon htmlColor={themeColor(navigationMenu.color)} />
        ) : (
          <SignedOutIcon htmlColor={themeColor(navigationMenu.color)} />
        )}
      </IconButton>

      {anchor && (
        <MUIPopover
          anchorEl={anchor}
          route=""
          stateMatch={(state) => Boolean(state.myAccountHeader)}
        >
          <MyAccountMenu />
        </MUIPopover>
      )}
    </Fragment>
  );
});

export default UserButton;
