import { createContext } from "react";
import { CartApi } from "./cartApiProvider";
import { useContextThrowUndefined } from "../../utilities/useContextThrowUndefined";

export const CartContext = createContext<CartApi | undefined>(undefined);

CartContext.displayName = "Cart";

const useCartApi = () => useContextThrowUndefined(CartContext);
export default useCartApi;
