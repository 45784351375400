import { paymentGateway } from "./siteSettings";

export enum paymentMethod {
  bancontact = "bancontact",
  checkoutStripe = "checkout_stripe",
  creditStripe = "credit_stripe",
  creditXendit = "credit_xendit",
  paypal = "paypal",
  /** The admin said it's done, but we don't have any more information */
  markedAsPaid = "markedAsPaid",
}

/**
 * Information about a payment.
 */
interface BasePayment {
  /** The type of payment */
  method: paymentMethod;
  /** What payment gateway processes the payment */
  gateway: paymentGateway | null;
  amount: number;
  amountAwaitingApproval: number;
  amountRefunded: number;
  /** If true, indicates this was created by the admin attempting to re-charge the card */
  createdByAdmin?: boolean;
}

/**
 * A stripe credit payment, before we added stripe checkout
 */
export interface StripeCreditPayment extends BasePayment {
  method: paymentMethod.creditStripe;
  gateway: paymentGateway.stripe;
  paymentIntentId: string;
  paymentMethodId: string;
  customerId: string;
}

/**
 * A stripe bancontact payment, before we added stripe checkuot
 */
export interface BancontactPayment extends BasePayment {
  method: paymentMethod.bancontact;
  gateway: paymentGateway.stripe;
  sourceId: string;
  chargeId: string;
}

/**
 * A payment using stripe checkout
 */
export interface StripeCheckoutPayment extends BasePayment {
  method: paymentMethod.checkoutStripe;
  gateway: paymentGateway.stripe;
  sessionId: string;
}

/**
 * A credit card payment using xendit
 */
export interface XenditCreditPayment extends BasePayment {
  method: paymentMethod.creditXendit;
  gateway: paymentGateway.xendit;
  tokenId: string;
  authenticationId: string;
  externalId: string;
}

/**
 * A paypal payment
 */
export interface PaypalPayment extends BasePayment {
  method: paymentMethod.paypal;
  gateway: paymentGateway.paypal;
  // They just call it an "orderId", but to avoid confusion with
  //   our orderIds, we call it a "paypalOrderId".
  paypalOrderId: string;
  captureId: string;
}

/**
 * A payment that was not performed in our app, so we have limited
 * info about it
 */
export interface MarkedAsPaidPayment extends BasePayment {
  method: paymentMethod.markedAsPaid;
  notes?: string;
  gateway: null;
}

export type Payment =
  | StripeCreditPayment
  | BancontactPayment
  | XenditCreditPayment
  | MarkedAsPaidPayment
  | StripeCheckoutPayment
  | PaypalPayment;

/**
 * Stored in the "temp" collection, while waiting for confirmation
 * that the payment succeeded.
 */
export interface PendingPayment {
  /**
   * Timestamp of when this was created. A cron job deletes these documents
   * nightly, but will skip any that are from the last hour
   */
  created: number;
  payment: Payment;
  orderId: string;
  status: PendingPaymentStatus;
}

export type PendingPaymentStatus = "pending" | "resolved" | "failed";
