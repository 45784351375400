import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as R from "ramda";
import { CategoryChunk, Category_Database } from "../database/catalog";
import {
  CatalogItem_Database,
  CatalogItem_WithDefaults,
} from "../database/catalogItem";
import { useSiteFirebase } from "../Firebase/context";
import { storeKey } from "../Firebase/siteFirebase";
import { convertItemAndAddDefaults } from "../utilities/typeConversions";
import { useSiteSettings } from "./siteSettingsContext";
import { useUnmount } from "../utilities/useUnmount";
import { unstable_batchedUpdates } from "react-dom";
import useSiteUser from "../components/UserProvider/useSiteUser";
import type firebase from "firebase";

// Firestore "in" queries can get a maximum of 10 results at a time
const MAX_QUERY = 10;

/**
 * Fetches items in a category from the database (if necessary) and applies
 * default values to them. Used on the store side.
 */
export const useFetchCategory = (
  category: Category_Database | undefined,
  pageSize = MAX_QUERY
) => {
  const { role } = useSiteUser();
  const firebase = useSiteFirebase();
  const { labels, taxPercentage, priceIncludesTax, currency } =
    useSiteSettings();

  const [page, setPage] = useState(0);
  const length = (page + 1) * pageSize;

  // First 30 items get loaded with a single read. This covers most use
  //   cases, and thus keeps down database reads.
  const [chunk, setChunk] = useState<CategoryChunk>();
  const [loadingChunk, setLoadingChunk] = useState(
    Boolean(category?.categoryId)
  );
  useEffect(() => {
    if (category?.categoryId) {
      setChunk(undefined);
      setLoadingChunk(true);
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("categoryChunks")
        .doc(category?.categoryId)
        .onSnapshot(
          (snapshot) => {
            unstable_batchedUpdates(() => {
              setLoadingChunk(false);
              setChunk(snapshot.data() as CategoryChunk | undefined);
            });
          },
          (err) => {
            console.log("error getting chunk", err);
            setLoadingChunk(false);
          }
        );
      return unsubscribe;
    }
  }, [category?.categoryId, firebase.firestore]);
  const chunkItemsWithDefaults = useMemo(() => {
    const result: Record<string, CatalogItem_WithDefaults> = {};
    if (chunk) {
      for (const itemId in chunk.items) {
        const item = chunk.items[itemId];
        if (!item) {
          continue;
        }
        result[itemId] = convertItemAndAddDefaults(
          item,
          labels,
          taxPercentage,
          priceIncludesTax,
          currency
        );
      }
    }
    return result;
  }, [chunk, currency, labels, priceIncludesTax, taxPercentage]);

  // If the user scrolls past the items in the chunk, extra items
  //    are loaded individually
  const [items, setItems] = useState<{
    [itemId: string]: CatalogItem_Database;
  }>({});
  const [loading, setLoading] = useState(Boolean(category));

  const subscriptions = useRef<{ [itemId: string]: () => void }>({});
  useEffect(() => {
    if (!category?.items || loadingChunk) {
      return;
    }
    // Find out which item ids we are not yet listening to (if any)
    const newIds: string[] = [];
    for (let i = 0; i < length; i++) {
      const itemId = category.items[i]?.itemId;
      if (itemId && !chunk?.items[itemId] && !subscriptions.current[itemId]) {
        newIds.push(category.items[i].itemId);
      }
    }

    // Firestore "in" queries are limited to 10 values each, so we group the items
    //   into chunks of that size.
    const groups = R.splitEvery(MAX_QUERY, newIds);
    setLoading(groups.length > 0);
    groups.forEach((group) => {
      let query:
        | firebase.firestore.Query<firebase.firestore.DocumentData>
        | firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =
        firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("items");
      if (!role) {
        // Customers only can access the active catalog
        query = query.where("inActiveCatalog", "==", true);
      }
      const unsubscribe = query
        .where("hidden", "==", false)
        .where("itemId", "in", group)
        .onSnapshot(
          (snapshot) => {
            setItems((prev) => {
              const newItems = { ...prev };
              snapshot.docs.forEach((doc) => {
                const data = doc.data() as CatalogItem_Database | undefined;
                if (data) {
                  newItems[data.itemId] = data;
                } else {
                  delete newItems[doc.id];
                }
              });
              return newItems;
            });
            setLoading(false);
          },
          (err) => {
            console.error("error loading items", err);
            setLoading(false);
          }
        );

      group.forEach((itemId) => {
        subscriptions.current[itemId] = unsubscribe;
      });
    });
  }, [
    category?.items,
    chunk?.items,
    firebase.firestore,
    length,
    loadingChunk,
    role,
  ]);

  useUnmount(() => {
    for (const id in subscriptions.current) {
      subscriptions.current[id]();
    }
  });

  const mergedItems: CatalogItem_WithDefaults[] = useMemo(() => {
    if (!category?.items) {
      return [];
    }
    const mergedItems: CatalogItem_WithDefaults[] = [];
    for (let i = page * pageSize; i < (page + 1) * pageSize; i++) {
      const itemRef = category.items[i];
      if (!itemRef) {
        break;
      }
      const { itemId } = itemRef;
      const itemFromChunk = chunkItemsWithDefaults[itemId];
      if (itemFromChunk) {
        mergedItems.push(itemFromChunk);
      } else if (items[itemId]) {
        mergedItems.push(
          convertItemAndAddDefaults(
            items[itemId],
            labels,
            taxPercentage,
            priceIncludesTax,
            currency
          )
        );
      }
    }
    return mergedItems;
  }, [
    category?.items,
    page,
    pageSize,
    chunkItemsWithDefaults,
    items,
    labels,
    taxPercentage,
    priceIncludesTax,
    currency,
  ]);
  const totalPages = Math.ceil((category?.items.length ?? 0) / pageSize);

  const nextPage = useCallback(() => {
    setPage((prev) => Math.min(prev + 1, totalPages - 1));
  }, [totalPages]);

  const prevPage = useCallback(() => {
    setPage((prev) => Math.max(0, prev - 1));
  }, []);

  return {
    items: mergedItems,
    loading,
    page,
    totalPages,
    nextPage,
    prevPage,
  };
};
