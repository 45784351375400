import { lazy, Fragment, FC, Suspense, useEffect, useMemo, useState } from "react";
import {
  RawSiteSettingsContext,
  RawStoreContext,
  SiteSettingsContext,
} from "../../customization/siteSettingsContext";
import { SiteSettings } from "../../database/siteSettings";
import { RawStore } from "../../database/store";
import SiteUserProvider from "../UserProvider/siteUserProvider";
import EditingProvider from "../Dashboard/Settings/editingProvider";
import { MainContext } from "../Main/mainContext";
import RenderBlocker from "../Main/renderBlocker";
import { useMain } from "../Main/useMain";
import { ToastProvider } from "../Main/useToast";
import "../Dashboard/css/overview.css";
import DashboardTheme from "../Dashboard/dashboardTheme";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { useLoadSite } from "../App/useLoadSite";
import { useGridBashFirebase } from "../../Firebase/context";
import { AdminGridbashSettings } from "../../database/gridbashSettings";
import { GridbashSettingsContext } from "./gridbashSettingsContext";
import useGridbashUser from "../UserProvider/useGridbashUser";
import { PrivateSite } from "../../database/site";
import { Redirect } from "react-router-dom";
import { PrivateSiteContext } from "../Dashboard/Overview/usePrivateSite";
import { DBHeaderContext } from "../Dashboard/Components/dBheader";
import { Button, Icon } from "@material-ui/core";

const Dashboard = lazy(() => import("../Dashboard/dashboard"));

const loadingStringKeys = [
  "dashboard.navigation.loadingString1",
  "dashboard.navigation.loadingString2",
  "dashboard.navigation.loadingString3",
  "dashboard.navigation.loadingString4",
  "dashboard.navigation.loadingString5",
  "dashboard.navigation.loadingString6",
  "dashboard.navigation.loadingString7",
  "dashboard.navigation.loadingString8",
  "dashboard.navigation.loadingString9",
  "dashboard.navigation.loadingString10",
  "dashboard.navigation.loadingString11",
  "dashboard.navigation.loadingString12",
  "dashboard.navigation.loadingString13",
  "dashboard.navigation.loadingString14",
  "dashboard.navigation.loadingString15",
  "dashboard.navigation.loadingString16",
  "dashboard.navigation.loadingString17",
  "dashboard.navigation.loadingString18",
  "dashboard.navigation.loadingString19",
  "dashboard.navigation.loadingString20",
];

// We will show a loading bar for at least this long, even if we're done sooner
const minDuration = 500;
// How often (roughly) to update the progress bar
const updateInterval = 450;

const maxLoops = 3;

/**
 * Wraps the dashboard in the various providers it needs to do its job.
 */
const DashboardWrapper: FC = ({ children }) => {
  const { t } = useTranslation();
  const { role, siteId, loading: loadingAuth } = useGridbashUser();
  const gridbashFirebase = useGridBashFirebase();

  const {
    siteSettings,
    rawSiteSettings,
    rawStore,
    assetsPreloaded,
    firebaseReady,
  } = useLoadSite();

  const [gridbashSettings, setGridbashSettings] =
    useState<AdminGridbashSettings>();
  useEffect(() => {
    if (role) {
      return gridbashFirebase.firestore
        .collection("settings")
        .doc("admin")
        .onSnapshot(
          (snapshot) => {
            setGridbashSettings(
              snapshot.data() as AdminGridbashSettings | undefined
            );
          },
          (err) => {
            setGridbashSettings(undefined);
          }
        );
    }
  }, [gridbashFirebase.firestore, role]);

  const [privateSite, setPrivateSite] = useState<PrivateSite>();
  useEffect(() => {
    setPrivateSite(undefined);
    if (siteId) {
      return gridbashFirebase.firestore
        .collection("privateSites")
        .doc(siteId)
        .onSnapshot(
          (snapshot) => {
            setPrivateSite(snapshot.data() as PrivateSite | undefined);
          },
          (err) => {
            setPrivateSite(undefined);
            console.error("error getting private site", err);
          }
        );
    }
  }, [gridbashFirebase.firestore, siteId]);

  const loadingMessage = useMemo(() => {
    const index = Math.floor(Math.random() * loadingStringKeys.length);
    return t(loadingStringKeys[index]);
  }, [t]);

  // Show loading for a minimum of 2 seconds
  const [halt, setHalt] = useState(true);
  useEffect(() => {
    let timerId = setTimeout(() => {
      setHalt(false);
    }, minDuration);
    return () => clearTimeout(timerId);
  }, []);

  const [loadingBar, setLoadingBar] = useState({
    progress: 0,
    timesLooped: 0,
    buffer: 10,
  });
  const takingALongTime = loadingBar.timesLooped >= maxLoops;
  const loading =
    halt ||
    !firebaseReady ||
    !rawStore ||
    !assetsPreloaded ||
    !rawSiteSettings ||
    !siteSettings ||
    !gridbashSettings ||
    !privateSite;
  useEffect(() => {
    if (!loading) {
      return;
    }
    let timerId: number;
    const advanceProgress = () => {
      setLoadingBar((prev) => {
        if (prev.progress >= 100) {
          if (prev.timesLooped === maxLoops - 1) {
            return {
              progress: 100,
              buffer: 100,
              timesLooped: maxLoops,
            };
          } else {
            return {
              progress: 0,
              buffer: 10,
              timesLooped: prev.timesLooped + 1,
            };
          }
        } else {
          // Calculate the exact jump
          const preciseJump = 15;
          // Slow down a bit each time we loop
          const scale = Math.log2(prev.timesLooped + 2);
          // Go a little more or less than the that to make it feel less fake
          const diff = Math.random() * 20 - 10;
          const diff2 = Math.random() * 10;
          const jump = (preciseJump + diff) / scale;

          const newProgress = prev.progress + jump;
          return {
            progress: Math.min(100, newProgress),
            buffer: Math.min(100, newProgress + diff2),
            timesLooped: prev.timesLooped,
          };
        }
      });
      const delay = (Math.random() + 0.5) * updateInterval;
      timerId = window.setTimeout(advanceProgress, delay);
    };

    timerId = window.setTimeout(advanceProgress, updateInterval / 2);
    return () => window.clearTimeout(timerId);
  }, [loading]);

  if (!loadingAuth && role === null) {
    console.log("Dashboard: not an admin, redirecting to gridbash", role);
    return <Redirect to="/" />;
  }

  if (loading) {
    // Render a placeholder while we finish loading
    return (
      <Fragment>
        <div
          className="smartWidthLg anim_fadeOut_1000"
          style={{
            padding: "1vw",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            textAlign: "center",
          }}
        >
          <Typography>Welcome</Typography>
        </div>

        <div
          className="smartWidthLg anim_fadeIn_0507"
          style={{
            padding: "1vw",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            opacity: 0,
          }}
        >
          <Typography
            style={{ padding: "2vw" }}
            // className="anim_fadeIn_0505"
          >
            {loadingMessage}
          </Typography>
          <LinearProgress
            variant="buffer"
            value={loadingBar.progress}
            valueBuffer={loadingBar.buffer}
          />
          <div
            style={{
              opacity: takingALongTime ? 1 : 0,
              padding: "2vw",
              transition: "all 0.3s",
            }}
          >
            <Typography>This is taking longer than expected.</Typography>
            <Typography>
              We'll keep trying automatically, or you can reload to start over.
            </Typography>
            <Button
              style={{ marginTop: "1em" }}
              startIcon={<Icon>arrow_right</Icon>}
              onClick={(e) => {
                e.stopPropagation();
                if (takingALongTime) {
                  window.location.reload();
                }
              }}
            >
              Reload
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    // useMain needs to use the site user, so SiteUserProvider needs to wrap it
    <SiteUserProvider store={rawStore}>
      <InnerComponent
        rawStore={rawStore}
        rawSiteSettings={rawSiteSettings}
        siteSettings={siteSettings}
        gridbashSettings={gridbashSettings}
        privateSite={privateSite}
      >
        {children}
      </InnerComponent>
    </SiteUserProvider>
  );
};

interface InnerComponentProps {
  rawStore: RawStore;
  rawSiteSettings: SiteSettings;
  siteSettings: SiteSettings;
  gridbashSettings: AdminGridbashSettings;
  privateSite: PrivateSite;
}

const InnerComponent: FC<InnerComponentProps> = ({
  rawStore,
  rawSiteSettings,
  siteSettings,
  gridbashSettings,
  privateSite,
}) => {
  const { accountInfo, dialogs } = useMain();

  const [zIndex, setZIndex] = useState<number | undefined>(1);

  const mainContextValue = useMemo(() => ({ accountInfo }), [accountInfo]);
  const headerZindexValue = useMemo(
    () => ({
      zIndex,
      setZIndex,
    }),
    [zIndex]
  );

  const children = useMemo(() => {
    return (
      <Suspense fallback={null}>
        <div className="App">
          <div id="react-portal" />
          <Dashboard />
          {dialogs}
        </div>
      </Suspense>
    );
  }, [dialogs]);

  return (
    <DashboardTheme>
      <ToastProvider>
        <RawStoreContext.Provider value={rawStore}>
          <RawSiteSettingsContext.Provider value={rawSiteSettings}>
            <SiteSettingsContext.Provider value={siteSettings}>
              <GridbashSettingsContext.Provider value={gridbashSettings}>
                <PrivateSiteContext.Provider value={privateSite}>
                  <DBHeaderContext.Provider value={headerZindexValue}>
                    <MainContext.Provider value={mainContextValue}>
                      <EditingProvider>
                        <RenderBlocker>{children}</RenderBlocker>
                      </EditingProvider>
                    </MainContext.Provider>
                  </DBHeaderContext.Provider>
                </PrivateSiteContext.Provider>
              </GridbashSettingsContext.Provider>
            </SiteSettingsContext.Provider>
          </RawSiteSettingsContext.Provider>
        </RawStoreContext.Provider>
      </ToastProvider>
    </DashboardTheme>
  );
};

export default DashboardWrapper;
