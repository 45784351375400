import { useContext, Context } from "react";
/**
 * Similar to useContext, except it will throw an exception if the value is undefined.
 *
 * This allows us to get logging feedback if we forget to wrap a consumer in a
 * provider, and fixes a typescript issue where we would be required to provide a
 * full mock as a default value.
 */

export function useContextThrowUndefined<T>(
  context: Context<T | undefined>
): T {
  const value = useContext(context);
  if (value === undefined) {
    const name = context.displayName ?? "";
    throw new Error(
      `${name} context consumer was used outside of a context provider.
To fix this, make sure the provider is always higher up the component tree than the consumer.

*** Ask nick if you don't know what this means ***

`
    );
  }
  return value;
}
