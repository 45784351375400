import { Fragment, FC } from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { history } from "../../../App/history";
import { useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CheckedIcon from "@material-ui/icons/Check";

interface FeaturesProps {}

const Features: FC<FeaturesProps> = () => {
  const { t } = useTranslation();
  const featuresList = [
    {
      text: "Completely customizable online web application to display, promote, and sell your products on any device. Use your own domain name, or use one of ours.",
    },
    {
      text: "Customers can order as a guest, or sign up to your app. They can favorite items, leave instructions when ordering, chat with you, split the bill, pay in their preferred payment method, keep track of orders in their dashboard, configure notifications ... ",
    },
    {
      text: "If applicable, indoor customers can review your menu with a QR code scan, send orders and payments to you from their phones.",
    },
    {
      text: "Accept payments from all major credit cards and other prominent payment methods such as Paypal, Apple, Android Pay, and many more ... To maximize your sales, we support processing payments in 200+ currencies, allowing you to charge customers in their native currency while receiving funds in yours.",
    },
    {
      text: "Create and manage product catalogs, even for items with complex features. Upload images, set image zoom areas, configure prices per units, create product variations ...",
    },
    {
      text: "Manage incoming orders through a fully-featured taskboard. Set up automated order notifications using your preferred communications channels.",
    },
    {
      text: "Modify existing orders, and/or create new orders.  Send invoices for immediate payment through Email, SMS, and or phone Push notifications. Customers can pay instantly from their device.",
    },
    { text: "Send full or partial refunds." },
    {
      text: "Manage your communications with an instant inbox. Communicate with your customers through instant chat, email (using your domain or ours), SMS and phone push notifications.",
    },
    { text: "Set up employee accounts, and configure roles and permissions." },
    {
      text: "Configure opening hours, holiday hours, minimum and maximum order times...",
    },
    { text: "Send receipts in Email, SMS, and/or phone Push notifications." },
    {
      text: "Your progressive web application has been developed using the latest technologies and reflects updates instantly, offering you and your customers a seamless experience.",
    },
    { text: "And so much more..." },
  ];
  return (
    <Fragment>
      <DialogTitle>BASIC FEATURES</DialogTitle>
      <DialogContent className="smartWidthMd">
        <List>
          {featuresList.map((feature, index) => {
            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <CheckedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={feature.text} />
              </ListItem>
            );
          })}
        </List>

        <div style={{ marginTop: "1em" }} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("gridbash.close")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Features;
