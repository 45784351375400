import { useRef, useState, useLayoutEffect, useCallback } from "react";

export interface Bounds {
  left: number;
  right: number;
  top: number;
  bottom: number;
  width: number;
  height: number;
}

/**
 * Measures the size of a dom element, and listens to changes in that size
 *
 * usage:
 *
 * const [ref, bounds] = useMeasure();
 *
 * return (
 *   <p>
 *     The targetted div is at {bounds.left}, {bounds.top}; with size {bounds.width}, {bounds.height}
 *     <div ref={ref} />
 *   </p>
 * )
 */
export function useMeasure<T extends Element = HTMLDivElement>(): [
  (instance: T | null) => void,
  Bounds
] {
  const latestNonNull = useRef<T | null>(null);
  const element = useRef<T | null>(null);
  const [bounds, setBounds] = useState<Bounds>({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 0,
    height: 0,
  });

  // Sets state, but only if values have changed
  const update = useCallback((bounds: Bounds) => {
    setBounds((prev) => {
      const changed = (
        ["top", "bottom", "left", "right", "width", "height"] as const
      ).some((param) => {
        return prev[param] !== bounds[param];
      });

      if (changed) {
        return bounds;
      } else {
        return prev;
      }
    });
  }, []);

  const [observer] = useState(
    () => new ResizeObserver(([entry]) => update(entry.contentRect))
  );

  const startObserving = useCallback(
    (element: T | null) => {
      if (!element) {
        return;
      }

      // Set state immediately (if it has changed)
      const rect = element.getBoundingClientRect();
      update(rect);

      // Then listen for any future changes
      observer.observe(element);
    },
    [observer, update]
  );

  const stopObserving = useCallback(() => {
    observer.disconnect();
  }, [observer]);

  useLayoutEffect(() => {
    startObserving(element.current);
    return stopObserving;
  }, [observer, startObserving, stopObserving]);

  /**
   * We use a callback ref to handle cases where the element does not exist
   * initially, then gets added to the dom later.
   */
  const ref = useCallback(
    (el: T | null) => {
      const changed = el !== latestNonNull.current;
      element.current = el;
      if (el) {
        latestNonNull.current = el;
      }
      if (changed) {
        startObserving(el);
      }
    },
    [startObserving]
  );

  return [ref, bounds];
}
