import { PageWidgetType } from "../../../../Store/PageRenderer/widgetType";
import { ReplaceWidgetAction } from "./actions";
import { PageState } from "./reducer";
import { getCell, setCell } from "./locatorUtils";

/**
 * Reducer for replacing a widget.
 */
export const replaceWidget = <T extends PageWidgetType>(
  state: PageState,
  action: ReplaceWidgetAction<T>
): PageState => {
  const { cellLocator, newType, newProps } = action;

  const cell = getCell(state, cellLocator);
  if (!cell) {
    return state;
  }

  return setCell(state, cellLocator, {
    ...cell,
    type: newType,
    props: newProps,
  });
};
