import { Dialog } from "@material-ui/core";
import { memo, Fragment, Dispatch, FC, SetStateAction } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Application } from "../../../database/signup";
import { history } from "../../App/history";
import Group1 from "./Questionnaire/group1";
import Group2 from "./Questionnaire/group2";
import Group3 from "./Questionnaire/group3";
import Group4 from "./Questionnaire/group4";
import { DoubleLeft, DoubleRight } from "./transitions";

interface AreYouInCarouselProps {
  application: Application;
  setApplication: Dispatch<SetStateAction<Application>>;
  initPath: string;
}

const AreYouInCarousel: FC<AreYouInCarouselProps> = (props) => {
  const location = useLocation();
  const panel = matchPath<{ panel: string }>(location.pathname, {
    path: `/am-i-in/:panel`,
  })?.params.panel;

  return <InnerComponent {...props} panel={panel} />;
};

const InnerComponent: FC<
  AreYouInCarouselProps & { panel: string | undefined }
> = memo(({ application, setApplication, initPath, panel }) => {
  return (
    <Fragment>
      <Dialog
        open={panel === "1"}
        keepMounted
        onClose={() => {
          history.goBack();
        }}
      >
        <Group1 application={application} setApplication={setApplication} />
      </Dialog>

      <Dialog
        open={panel === "2"}
        TransitionComponent={DoubleLeft}
        keepMounted
        onClose={() => {
          history.go(-2);
        }}
      >
        <Group2 application={application} setApplication={setApplication} />
      </Dialog>

      <Dialog
        open={panel === "3"}
        TransitionComponent={DoubleLeft}
        keepMounted
        onClose={() => {
          history.go(-3);
        }}
      >
        <Group3
          application={application}
          setApplication={setApplication}
          initPath={`${initPath}/am-i-in/3`}
        />
      </Dialog>

      <Dialog
        open={panel === "4"}
        TransitionComponent={DoubleRight}
        keepMounted
        onClose={() => {
          history.go(-4);
        }}
      >
        <Group4 application={application} initPath={initPath} />
      </Dialog>
    </Fragment>
  );
});

export default AreYouInCarousel;
