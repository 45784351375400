import { Button, Icon, IconButton } from "@material-ui/core";
import { FC, useEffect, useRef, useState } from "react";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import {
  NavLinksWidgetEditorOverrides,
  NavLinksWidgetProps,
} from "../../Store/PageRenderer/widgets/navLinksWidget/navLinksWidget";
import FancyTextComponent from "../Builder/FancyText/fancyTextComponent";

const SmartMenu: FC<NavLinksWidgetProps & NavLinksWidgetEditorOverrides> = ({
  fancyText = { text: "" },
  indicatorThickness = 1,
  indicatorRadius = 0,
  highlightColor,
  elements,
  itemsInView = 0, // 0 MEANS AUTO - 1 MEANS ONLY 1 AT A TIME
  scrollType, // SCROLL PER ONE ITEM OR MULTIPLE ITEMS
}) => {
  const { pages } = useSiteSettings();

  //NOT FROM DB AS WE HAVENT NEEDED THESE SETTINGS YET
  const indicatorIcon = undefined;
  const navButtonType = "icon" as "icon" | "text";
  const navButtonTextLeft = "arrow_left";
  const navButtonTextRight = "arrow_right";

  // const menuStrings = [
  //   { name: "Item one", id: "0", icon: "arrow_right" },
  //   { name: "Item two", id: "1", icon: "arrow_right" },
  //   { name: "Item three", id: "2", icon: "arrow_right" },
  //   { name: "Item four", id: "3", icon: "arrow_right" },
  //   { name: "Item five", id: "4", icon: "arrow_right" },
  //   { name: "Item six", id: "5", icon: "arrow_right" },
  //   { name: "Item seven", id: "6", icon: "arrow_right" },
  //   { name: "Item eight", id: "7", icon: "arrow_right" },
  //   { name: "Item nine", id: "8", icon: "arrow_right" },
  //   { name: "Item ten", id: "9", icon: "arrow_right" },
  //   { name: "Item eleven", id: "10", icon: "arrow_right" },
  //   { name: "Item twelve", id: "11", icon: "arrow_right" },
  //   { name: "Item thirteen", id: "12", icon: "arrow_right" },
  //   { name: "Item fourteen", id: "13", icon: "arrow_right" },
  // ];

  const mainDiv = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const navButtonLeft = useRef<HTMLButtonElement | null>(null);
  const navButtonRight = useRef<HTMLButtonElement | null>(null);

  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);

  const [selected, setSelected] = useState<HTMLDivElement | null>(
    itemRefs.current[0]
  );
  const [selectedLeft, setSelectedLeft] = useState(0);

  // ONLY USED FOR WHEN ITEMSINVIEW EQUALS 1
  // (WE ARE ONLY HAVING ONE ITEM IN VIEW)
  const nextElements = useRef<{
    left: HTMLDivElement | null;
    right: HTMLDivElement | null;
  }>({
    left: null,
    right: itemRefs.current[1],
  });

  useEffect(() => {
    const currentMainDiv = mainDiv.current;
    const currentNavButtonLeft = navButtonLeft.current;
    const currentNavButtonRight = navButtonRight.current;
    if (!currentMainDiv || !currentNavButtonLeft || !currentNavButtonRight)
      return;

    const showLeft = currentMainDiv.scrollLeft !== 0;
    const showRight =
      currentMainDiv.scrollLeft !==
      currentMainDiv.scrollWidth - currentMainDiv.clientWidth;

    setShowLeft(showLeft);
    setShowRight(showRight);

    if (!itemRefs.current[0]) return;

    setSelected(itemRefs.current[0]);
    setSelectedLeft(itemRefs.current[0].getBoundingClientRect().left);

    nextElements.current.left = null;
    nextElements.current.right = itemRefs.current[1];
  }, []);

  const timer = useRef(-1);

  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <IconButton
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 1,
          color: "#007d9b",
        }}
        onClick={(e) => {}}
      >
        <Icon style={{ cursor: "pointer" }} fontSize="small">
          highlight_alt
        </Icon>
      </IconButton>
      <div
        ref={mainDiv}
        className="noScrollBars"
        style={{
          display: "flex",
          overflow: "auto hidden",
          width: "100%",
          scrollBehavior: "smooth",
        }}
        onScroll={(e) => {
          const showLeft = e.currentTarget.scrollLeft !== 0;
          const showRight =
            e.currentTarget.scrollLeft !==
            e.currentTarget.scrollWidth - e.currentTarget.clientWidth;

          clearTimeout(timer.current);
          timer.current = window.setTimeout(() => {
            setShowLeft(showLeft);
            setShowRight(showRight);
            if (!selected) return;

            setSelectedLeft(selected.getBoundingClientRect().left);
            if (itemsInView === 1) {
              itemRefs.current.find((element, index) => {
                if (
                  element &&
                  navButtonLeft.current &&
                  navButtonRight.current
                ) {
                  if (
                    element.getBoundingClientRect().left >=
                      navButtonLeft.current.getBoundingClientRect().right &&
                    element.getBoundingClientRect().right <=
                      navButtonRight.current.getBoundingClientRect().left
                  ) {
                    nextElements.current = {
                      left: index > 0 ? itemRefs.current[index - 1] : null,
                      right:
                        index < itemRefs.current.length - 1
                          ? itemRefs.current[index + 1]
                          : itemRefs.current[itemRefs.current.length - 1],
                    };
                    return element;
                  }
                }
                return null;
              });
            }
          }, 100);
        }}
      >
        <Button
          ref={navButtonLeft}
          className={`gb-font-${fancyText.fontSize}`}
          style={{
            minWidth: "auto", // REQUIRED
            position: "sticky",
            left: 0,
            opacity: showLeft ? 1 : 0,
            transition: "all 0.1s ease 0s",
            borderRadius: "0",
            backgroundColor: "white",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!mainDiv.current) return;

            if (itemsInView === 0) {
              const nextElement = itemRefs.current.find((element) => {
                if (element) {
                  if (
                    element.getBoundingClientRect().left <
                      e.currentTarget.getBoundingClientRect().right &&
                    element.getBoundingClientRect().right >=
                      e.currentTarget.getBoundingClientRect().right
                  ) {
                    return element;
                  }
                }
                return null;
              });

              if (nextElement && navButtonRight.current) {
                if (scrollType === "single") {
                  mainDiv.current.scrollTo(
                    mainDiv.current.scrollLeft -
                      (e.currentTarget.getBoundingClientRect().right -
                        nextElement.getBoundingClientRect().left +
                        1),
                    0
                  );
                } else {
                  mainDiv.current.scrollTo(
                    mainDiv.current.scrollLeft -
                      (navButtonRight.current.getBoundingClientRect().left -
                        nextElement.getBoundingClientRect().right +
                        1),
                    0
                  );
                }
              }
            } else {
              if (!nextElements.current.left || !navButtonRight.current) return;
              mainDiv.current.scrollTo(
                mainDiv.current.scrollLeft -
                  (navButtonRight.current.getBoundingClientRect().left -
                    nextElements.current.left.getBoundingClientRect().right),
                0
              );
            }
          }}
        >
          {navButtonType === "icon" ? (
            <Icon className={`gb-font-${fancyText.fontSize}`}>
              {navButtonTextLeft}
            </Icon>
          ) : (
            navButtonTextLeft
          )}
        </Button>

        {pages.map((item, index) => (
          <div
            ref={(e) => (itemRefs.current[index] = e)}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              setSelected(e.currentTarget);
              if (
                !navButtonLeft.current ||
                !navButtonRight.current ||
                !mainDiv.current
              )
                return;
              /** IF THE SELECTED ITEM IS STILL PARTLY OUT OF VIEW
               * MOVE IT SLIGHTLY TO SHOW IT FULLY
               *
               * IF ITEM.LEFT < LEFTBUTTON.RIGHT
               * SUBTRACT DIFFERENCE FROM SCROLL POSITION
               *
               * IF ITEM.RIGHT > RIGHT.BUTTON
               * ADD DIFFERENCE TO SCROLL POSITION
               *
               * */
              let correctScroll = e.currentTarget.getBoundingClientRect().left;
              if (
                e.currentTarget.getBoundingClientRect().left <
                navButtonLeft.current.getBoundingClientRect().right
              ) {
                correctScroll =
                  navButtonLeft.current.getBoundingClientRect().right -
                  e.currentTarget.getBoundingClientRect().left +
                  1;
                mainDiv.current.scrollTo(
                  mainDiv.current.scrollLeft - correctScroll,
                  0
                );
              } else if (
                e.currentTarget.getBoundingClientRect().right >
                navButtonRight.current.getBoundingClientRect().left
              ) {
                correctScroll =
                  e.currentTarget.getBoundingClientRect().right -
                  navButtonRight.current.getBoundingClientRect().left +
                  1;

                mainDiv.current.scrollTo(
                  mainDiv.current.scrollLeft + correctScroll,
                  0
                );
              } else {
                setSelectedLeft(correctScroll);
              }
            }}
            style={{
              minWidth:
                itemsInView === 1
                  ? mainDiv.current &&
                    navButtonLeft.current &&
                    navButtonRight.current
                    ? mainDiv.current.clientWidth -
                      navButtonLeft.current.clientWidth -
                      navButtonRight.current.clientWidth
                    : undefined
                  : undefined,
            }}
          >
            <FancyTextComponent
              value={{
                ...fancyText,
                text: elements === "iconOnly" ? "" : item.name,
                icon: elements === "textOnly" ? undefined : item.icon,
                textColor:
                  itemRefs.current.indexOf(selected) === index
                    ? highlightColor?.literal
                    : fancyText.textColor,
              }}
              button={true}
              style={{
                whiteSpace: "nowrap",
              }}
            />
          </div>
        ))}

        <Button
          ref={navButtonRight}
          className={`gb-font-${fancyText.fontSize}`}
          style={{
            minWidth: "auto", // REQUIRED
            opacity: showRight ? 1 : 0,
            transition: "all 0.1s ease 0s",
            position: "sticky",
            right: 0,
            backgroundColor: "white",
            borderRadius: "0",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!mainDiv.current) return;
            if (itemsInView === 0) {
              const nextElement = itemRefs.current.find((element) => {
                if (element) {
                  if (
                    element.getBoundingClientRect().right >
                      e.currentTarget.getBoundingClientRect().left &&
                    element.getBoundingClientRect().left <=
                      e.currentTarget.getBoundingClientRect().left
                  ) {
                    return element;
                  }
                }
                return null;
              });

              if (nextElement && navButtonLeft.current) {
                if (scrollType === "single") {
                  mainDiv.current.scrollTo(
                    mainDiv.current.scrollLeft +
                      (nextElement.getBoundingClientRect().right -
                        e.currentTarget.getBoundingClientRect().left +
                        1),
                    0
                  );
                } else {
                  mainDiv.current.scrollTo(
                    mainDiv.current.scrollLeft +
                      (nextElement.getBoundingClientRect().left -
                        navButtonLeft.current?.getBoundingClientRect().right +
                        1),
                    0
                  );
                }
              }
            } else {
              if (!nextElements.current.right || !navButtonLeft.current) return;
              mainDiv.current.scrollTo(
                mainDiv.current.scrollLeft +
                  (nextElements.current.right.getBoundingClientRect().left -
                    navButtonLeft.current.getBoundingClientRect().right),
                0
              );
            }
          }}
        >
          {navButtonType === "icon" ? (
            <Icon className={`gb-font-${fancyText.fontSize}`}>
              {navButtonTextRight}
            </Icon>
          ) : (
            navButtonTextRight
          )}
        </Button>
      </div>
      <div
        style={{
          backgroundColor: highlightColor?.literal,
          borderRadius: `${indicatorRadius}px`,
          height: indicatorIcon ? undefined : `${indicatorThickness}px`,
          width: selected ? `${selected?.getBoundingClientRect().width}px` : 0,
          transition: "all 0.5s cubic-bezier(0.4,0,0.2,1) 0s",
          //HIDE THE INDICATOR BEHIND BUTTONS
          marginTop: `${-indicatorThickness}px`,
          marginLeft:
            mainDiv.current && selected
              ? selectedLeft - mainDiv.current?.getBoundingClientRect().left
              : 0,
        }}
      >
        {indicatorIcon && (
          <Icon style={{ color: "black" }}>{indicatorIcon}</Icon>
        )}
      </div>
    </div>
  );
};

export default SmartMenu;
