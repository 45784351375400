import { Fragment, FC, useState } from "react";
import { Typography } from "@material-ui/core";
import { srcSetString } from "../../Main/main";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import ImageIcon from "@material-ui/icons/Image";
import IconFavoriteWidget from "../Orders/Templates/IconFavoriteWidget";
import ROUTES from "../../../utilities/constants/routes";
import { Draggable } from "react-beautiful-dnd";
import { history } from "../../App/history";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import { useTranslation } from "react-i18next";
import AddToCart from "../Orders/addToCart";
import useToast from "../../Main/useToast";
import useCartApi from "../../CartManager/useCartApi";
import { CatalogItem_WithDefaults } from "../../../database/catalogItem";

interface ItemFavoriteCardProps {
  catalogItem: CatalogItem_WithDefaults;
  categoryNames: string[];
  index: number;
  fadeIn?: boolean;
  dragDisabled?: boolean;
}

const ItemFavoriteCard: FC<ItemFavoriteCardProps> = ({
  catalogItem,
  categoryNames,
  index,
  fadeIn = true,
  dragDisabled = false,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const cartApi = useCartApi();
  const { theme2 } = useSiteSettings();

  const [imageInfo, setImageInfo] = useState<{
    loaded: boolean;
    error: boolean;
  }>({
    loaded: !catalogItem.imageUrl,
    error: false,
  });

  if (!catalogItem) {
    return null;
  }

  const categoryString = categoryNames.join(`\xa0\xa0\xa0`);

  return (
    <Fragment>
      <MUIDialog
        route={`${ROUTES.FAVORITES}/add-to-cart/${catalogItem.itemId}`}
      >
        <AddToCart
          catalogItem={catalogItem}
          submitButton={t("store.orders.addToCart")}
          onSubmit={(count, instructions, selectedOptions) => {
            cartApi.addItem(catalogItem, count, instructions, selectedOptions);
            toast({
              duration: 1200,
              message: t("store.orders.addedToCart"),
              color: "error",
            });
          }}
        />
      </MUIDialog>
      <Draggable
        draggableId={catalogItem.itemId}
        index={index}
        isDragDisabled={dragDisabled}
      >
        {(provided) =>
          catalogItem.hidden ? (
            <Fragment />
          ) : (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={(e) => {
                e.stopPropagation();
                const path = `${ROUTES.FAVORITES}/add-to-cart/${catalogItem.itemId}`;
                if (!window.location.pathname.includes(path)) {
                  history.push(path);
                }
              }}
            >
              <div
                className={
                  imageInfo.loaded && fadeIn
                    ? "anim_moveUp_small_0303 boxShadow3"
                    : ""
                }
                style={{
                  display: "grid",
                  gridTemplateColumns: catalogItem.imageUrl
                    ? "min(25vw, 7em) 1fr"
                    : "1fr",
                  gap: "1em",
                  gridTemplateRows: catalogItem.imageUrl
                    ? "min(25vw, 7em)"
                    : "1fr",
                  marginBottom: "1em",
                  padding: "1em",
                  borderRadius: "4px",
                  backgroundColor: theme2.colors.primaryLight.value,
                  opacity: fadeIn ? 0 : 1,
                }}
              >
                {catalogItem.imageUrl ? (
                  imageInfo.error ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: "0.5",
                      }}
                    >
                      <ImageIcon fontSize="large" />
                    </div>
                  ) : (
                    <img
                      className="boxShadow2"
                      src={catalogItem.imageUrl}
                      /**
                       * SRCSET EXPLAINED
                       *
                       * IN DEV TOOLS
                       * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
                       * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
                       * ACTUALLY TAKING UP.
                       *
                       * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
                       * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
                       */
                      srcSet={srcSetString(catalogItem.imageUrls)}
                      /**
                       * SIZES EXPLAINED
                       *
                       * WE ARE SETTING THE SIZE TO THE WIDTH SET IN THE STYLE
                       * FOR THE LEFT GRID COLUMN ABOVE
                       */
                      sizes="min(25vw, 7em)"
                      alt={catalogItem.name}
                      onLoad={() => {
                        setImageInfo({ loaded: true, error: false });
                      }}
                      onError={() => {
                        setImageInfo({ loaded: true, error: true });
                      }}
                      style={{
                        objectFit: "cover",
                        objectPosition: catalogItem.imageZoom
                          ? `${catalogItem.imageZoom.x}% ${catalogItem.imageZoom.y}%`
                          : "center",
                        verticalAlign: "middle",
                        maxWidth: "100%",
                        height: "100%",
                        borderRadius: "5px",
                      }}
                    />
                  )
                ) : null}
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="textPrimary" noWrap align="left">
                      {catalogItem.name}
                    </Typography>
                    <Typography
                      noWrap
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {categoryString}
                    </Typography>
                  </div>

                  <div style={{ textAlign: "right" }}>
                    <IconFavoriteWidget catalogItem={catalogItem} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Draggable>
    </Fragment>
  );
};

export default ItemFavoriteCard;
