import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Fragment, FC } from "react";
import ArrowRightIcon from "@material-ui/icons/ArrowForward";
import { history } from "../../../App/history";
import { Application } from "../../../../database/signup";

interface Group4Props {
  application: Application;
  initPath: string;
}

const Group4: FC<Group4Props> = ({ application, initPath }) => {
  const agreed =
    application.question1 !== "disagree" &&
    application.question2 !== "disagree";

  return (
    <Fragment>
      <DialogContent>
        {agreed ? (
          <Fragment>
            <Typography variant="h5">Glad you're onboard!</Typography>
            <br />
            <Typography align="left">
              If you are a local business owner (including locally owned
              franchises), apply now and safeguard your margins.
            </Typography>
            <br />
            <Typography>
              <strong>
                It's free! There's no obligation, and no monthly or hidden fees.
                The only thing you have to lose, is potential extra sales!
              </strong>
            </Typography>
            <br />
            <Typography align="center" variant="h6">
              Let's advance together!
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant="h5">Not quite, but close?</Typography>
            <br />
            <Typography align="left">
              Even though you don't agree on all statements, you can still apply
              if you are a local business owner (including locally owned
              franchises).
            </Typography>
            <br />
            <Typography>
              <strong>
                It's free! There's no obligation, and no monthly or hidden fees.
                The only thing you have to lose, is potential extra sales!
              </strong>
            </Typography>
            <br />
            <Typography align="center" variant="h6">
              Let's advance together!
            </Typography>
          </Fragment>
        )}

        <div style={{ marginTop: "1em" }} />

        <div style={{ marginTop: "1em" }} />
      </DialogContent>
      <DialogActions
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Button
          startIcon={<ArrowRightIcon />}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            history.replace(`${initPath}/apply`);
          }}
        >
          Apply Now
        </Button>
        <br />
      </DialogActions>
    </Fragment>
  );
};

export default Group4;
