import memoize from "memoize-one";
import { DayIndex, SiteSettings } from "../database/siteSettings";
import { format, add, startOfDay, isWithinInterval, subDays } from "date-fns";

type TimeRange = { from: string; to: string };

export const isBetween_fns = memoize(
  (selectedDate: Date, timeRanges: TimeRange[], dayOffset = 0) => {
    // console.log(
    //   "isBetween_fns. SELECTEDMOMENT:",
    //   selectedMoment
    // );
    if (!timeRanges) return false;
    return timeRanges.some(({ from, to }) => {
      const [fromHours, fromMinutes] = from.split(":");
      const [toHours, toMinutes] = to.split(":");

      let fromDate = add(startOfDay(selectedDate), {
        hours: +fromHours,
        minutes: +fromMinutes,
        days: dayOffset,
      });

      let toDate = add(startOfDay(selectedDate), {
        hours: +toHours,
        minutes: +toMinutes,
        days: dayOffset,
      });

      if (toDate <= fromDate) {
        // console.log(
        //   "isBetween_fns. PAST MIDNIGHT CORRECTION. ADDING A DAY IN TOMOMENT"
        // );
        toDate = add(toDate, { days: 1 });
      }

      return isWithinInterval(selectedDate, {
        start: fromDate,
        end: toDate,
      });
    });
  }
);

export const getTimeRanges_fns = (
  date: Date,
  siteSettings: SiteSettings
): TimeRange[] => {
  // console.log("getTimeRanges_fns. GETTING TIME RANGES FOR", date);
  const { dailyHours, holidayHours } = siteSettings;
  // REGULAR HOURS ARE STORED IN THE DB WITH A NUMBER KEY FROM 0-6
  // WHERE 0 REPRESENTS SUNDAY (!!!YET DATE-FNS USES 1 TO 7 WHERE
  // 1 IS A MONDAY!!!)

  // E.G. DAILYHOURS[0] WILL RETURN SUNDAY'S HOURS AS E.G. [{from: "10:00", to: "14:00"}, {from: "15:00", to: "17:00"}]
  let dayOfWeek = format(date, "i"); //1 - 7
  if (dayOfWeek === "7") dayOfWeek = "0";
  // HOLIDAY HOURS ARE STORED IN THE DB WITH A STRING KEY E.G. 2021-02-09
  let holidayKey = format(date, "yyyy-MM-dd");
  // console.log(
  //   "getTimeRanges_fns. KEY LOOKS LIKE",
  //   holidayKey,
  //   "OR",
  //   Number(dayOfWeek)
  // );
  //CHECK IF THERE'S A HOLIDAY STORE FIRST
  //IF NOT, RETURN THE RANGE FOR THE REGULAR DAY
  const ranges =
    holidayHours[holidayKey] || dailyHours[Number(dayOfWeek) as DayIndex];
  // console.log("getTimeRanges_fns. RETRIEVED RANGES:", ranges);
  return ranges;
};

/**
 * Returns true if the store is open at the selected time
 */
export const isOpenAt_fns = memoize(
  (selectedDate: Date, siteSettings: SiteSettings): boolean => {
    let timeRanges = getTimeRanges_fns(selectedDate, siteSettings);
    // NOW THAT WE HAVE THE TIME RANGES, WE NEED TO LOOP THROUGH THEM
    // AND FIGURE OUT IF OUR SELECTED MOMENT FALLS WITHIN THOSE RANGES
    // console.log("isOpenAt_fns. TIME RANGES FOR", selectedDate, ":", timeRanges);
    let isOpen = isBetween_fns(selectedDate, timeRanges);
    // console.log("isOpenAt_fns. IS STORE OPEN AT THIS MOMENT:", isOpen);

    if (!isOpen) {
      // console.log(
      //   "isOpenAt_fns. STORE NOT OPEN RIGHT NOW. CHECKING YESTERDAYS TIME RANGES FOR OVERLAP."
      // );
      // Need to check yesterday's hours too, in case it's the
      // early morning and they're open past midnight
      const yesterday = startOfDay(subDays(selectedDate, 1));
      // console.log("isOpenAt_fns. YESTERDAY'S DATE:", yesterday);
      let timeRanges = getTimeRanges_fns(yesterday, siteSettings);
      // console.log("isOpenAt_fns. YESTERDAY'S TIME RANGES:", timeRanges);
      //PASS IN -1 TO CHECK YESTERDAY'S RANGES
      isOpen = isBetween_fns(selectedDate, timeRanges, -1);
      // console.log(
      //   "isOpenAt_fns. STORE OPEN BASED ON YESTERDAY'S OVERLAPPING TIME RANGE:",
      //   isOpen
      // );
    }
    return isOpen;
  }
);

export const getNextTimeOpen_fns = (
  earliest: Date,
  siteSettings: SiteSettings
) => {
  // console.log("getNextTimeOpen_fns. GETTING NEXT OPENING TIME FOR", earliest);
  if (isOpenAt_fns(earliest, siteSettings)) {
    return earliest;
  }
  // console.log(
  //   "getNextTimeOpen_fns. NOT OPEN AT NEXT STANDARD STORE INCREMENT (CALCULATED FROM RIGHT NOW)."
  // );
  // console.log("getNextTimeOpen_fns. GOING TO CHECK LATER IN THE DAY:");
  const dayStart = startOfDay(earliest);
  const timeRanges = getTimeRanges_fns(earliest, siteSettings);
  for (const { from } of timeRanges) {
    const [fromHours, fromMinutes] = from.split(":");
    const fromDate = add(dayStart, {
      hours: +fromHours,
      minutes: +fromMinutes,
    });

    if (fromDate > earliest) {
      // console.log("getNextTimeOpen_fns. FOUND AN OPENING:", fromDate);
      return fromDate;
    }
  }
  // console.log("getNextTimeOpen_fns. NOTHING FOUND LATER IN THE DAY.");

  // Else, we need to find the next holiday or regular day. Let's start with the holidays
  // console.log("getNextTimeOpen_fns. GOING TO CHECK FUTURE HOLIDAYS.");
  const candidateHolidays: Date[] = [];
  Object.entries(siteSettings.holidayHours).forEach(
    ([dateString, timeRanges]) => {
      const holidayStart = startOfDay(new Date(dateString));

      // We only care if this day is in the future and has open hours
      if (holidayStart > dayStart && timeRanges && timeRanges[0]) {
        const { from } = timeRanges[0];
        const [fromHours, fromMinutes] = from.split(":");
        const fromfromDate = add(holidayStart, {
          hours: +fromHours,
          minutes: +fromMinutes,
        });
        candidateHolidays.push(fromfromDate);
      }
    }
  );

  candidateHolidays.sort((a, b) => a.getTime() - b.getTime());
  const nextHoliday: Date | undefined = candidateHolidays[0];
  // console.log("getNextTimeOpen_fns. NEXT HOLIDAY FOUND:", nextHoliday);

  // And now the regular days
  // console.log("getNextTimeOpen_fns. GOING TO CHECK FUTURE REGULAR DAYS.");
  let nextRegularDay: Date | undefined;
  const hasRegularDays = Object.values(siteSettings.dailyHours).some(
    (timerange) => timerange.length !== 0
  );
  if (hasRegularDays) {
    // It is extremely unlikely we would run through 1000. For that to happen, the next
    // 1000 days would have to have holidays marking them as closed, even though daily
    // hours would show at least some of them as open. Really, the cap of 1000 is just
    // because the alternative would be `while (true)`, which makes me nervous.
    for (let i = 1; i <= 1000; i++) {
      const dayStart2 = add(dayStart, { days: i });
      // console.log("getNextTimeOpen_fns. CHECKING REGULAR DATE:", dayStart2);

      let timeRanges = getTimeRanges_fns(dayStart2, siteSettings);
      if (timeRanges.length > 0) {
        const [fromHours, fromMinutes] = timeRanges[0].from.split(":");
        nextRegularDay = add(dayStart2, {
          hours: +fromHours,
          minutes: +fromMinutes,
        });

        break;
      }
    }
  }
  // console.log("getNextTimeOpen_fns. NEXT REGULAR DAY FOUND:", nextRegularDay);
  // console.log("getNextTimeOpen_fns. GOING TO CHECK WHICH ONE TO RETURN.");
  if (nextHoliday && nextRegularDay) {
    // There are both holidays and regular days coming up. Return the nearest one.
    if (nextHoliday > nextRegularDay) {
      // console.log(
      //   "getNextTimeOpen_fns. RETURNING NEXT REGULAR DAY:",
      //   nextRegularDay
      // );
      return nextRegularDay;
    } else {
      // console.log("getNextTimeOpen_fns. RETURNING NEXT HOLIDAY:", nextHoliday);
      return nextHoliday;
    }
  } else if (nextRegularDay) {
    // There are no holidays coming up, just regular days
    // console.log(
    //   "getNextTimeOpen_fns. RETURNING NEXT REGULAR DAY:",
    //   nextRegularDay
    // );
    return nextRegularDay;
  } else if (nextHoliday) {
    // There are no regular days coming up, just holidays.
    // console.log("getNextTimeOpen_fns. RETURNING NEXT HOLIDAY:", nextRegularDay);
    return nextHoliday;
  } else {
    // They are never open
    return null;
  }
};
