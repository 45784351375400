import { Fragment, useState, FC, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import STorderInstructions from "../Modals/sTorderInstructions";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import InstructionsIcon from "@material-ui/icons/PostAdd";
import DoneIcon from "@material-ui/icons/Done";
import { CatalogItem_WithDefaults } from "../../../database/catalogItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelectedOptions } from "../../../utilities/defaultSelections";
import ItemCounter from "./itemCounter";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import ImageIcon from "@material-ui/icons/Image";
import { srcSetString } from "../../Main/main";
import DOMPurify from "dompurify";
import CloseIcon from "@material-ui/icons/Cancel";
import ItemOption from "../Components/itemOption";
import { calculateCartItemPrice } from "../../../utilities/orderProcessing";
import { createCartItem } from "../../CartManager/cartApiProvider";
import { optionType } from "../../../database/option";
import { CartItem, OptionSelection } from "../../../database/cart";
import IconFavoriteWidget from "./Templates/IconFavoriteWidget";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import EnlargedImgModal from "./enlargedImgModal";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router";

export interface AddToCartProps {
  catalogItem: CatalogItem_WithDefaults;
  cartItem?: CartItem;
  submitButton: string;
  onSubmit: (
    count: number,
    instructions: string,
    selectedOptions: OptionSelection[]
  ) => void;
  hideBack?: boolean;
  hideImage?: boolean;
  hideDescription?: boolean;
  hideFavorites?: boolean;
}

const AddToCart: FC<AddToCartProps> = ({
  catalogItem,
  cartItem,
  submitButton,
  onSubmit,
  hideBack = false,
  hideImage = false,
  hideDescription = false,
  hideFavorites = false,
}) => {
  const { t } = useTranslation();
  const { chargesEnabled } = useSiteSettings();

  const location = useLocation<
    { imageDetail?: boolean; instructions?: boolean } | undefined
  >();

  const [selectedOptions, setSelectedOptions] = useSelectedOptions(
    catalogItem,
    cartItem
  );

  const [instructions, setInstructions] = useState(
    cartItem?.instructions ?? ""
  );
  const [count, setCount] = useState(
    cartItem?.count ?? catalogItem.unit?.defaultValue ?? 1
  );

  const totalPrice = useMemo(() => {
    return calculateCartItemPrice(
      createCartItem({
        catalogItem,
        cartItemId: "fake",
        count,
        optionSelections: selectedOptions,
      })
    ).total.price;
  }, [catalogItem, count, selectedOptions]);

  const [imageInfo, setImageInfo] = useState({
    loaded: hideImage,
    error: false,
  });

  const description = Array.isArray(catalogItem.description)
    ? catalogItem.description[0] ?? ""
    : catalogItem.description;

  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  return (
    <Fragment>
      <MUIDialog
        route={""}
        stateMatch={(state) => state.imageDetail === true}
        PaperProps={{ style: { backgroundColor: "transparent" } }}
      >
        <EnlargedImgModal catalogItem={catalogItem} />
      </MUIDialog>

      <DialogContent
        className="smartWidthLg"
        style={{
          padding: 0,
          opacity: imageInfo.loaded ? 1 : 0,
          transition: "opacity .75s ease 0.3s",
        }}
      >
        {!hideBack && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              // style={{ color: "white", backgroundColor: "rgba(0,0,0,0.55)" }}
              onClick={(e) => {
                e.stopPropagation();
                history.goBack();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {!hideImage && (
          <Fragment>
            {catalogItem.imageUrl && imageInfo.error ? (
              <div
                style={{
                  textAlign: "center",
                  padding: "max(3vw, 3em) 0",
                  opacity: "0.75",
                }}
              >
                <ImageIcon fontSize="large" />
              </div>
            ) : !catalogItem.imageUrl ? (
              <span></span>
            ) : (
              <div
                style={{
                  height: catalogItem.imageMetadata
                    ? `max(${catalogItem.imageMetadata.height}px, calc(25vh + 32px))`
                    : "calc(25vh + 32px)",
                  maxHeight: "calc(25vh + 32px)",
                  textAlign: "center",
                  overflow: "hidden",
                  padding: "16px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    imageInfo.loaded &&
                    location.state?.imageDetail !== true
                  ) {
                    history.push(location.pathname, {
                      ...location.state,
                      imageDetail: true,
                    });
                  }
                }}
              >
                <img
                  src={catalogItem.imageUrl}
                  /**
                   * SRCSET EXPLAINED
                   *
                   * IN DEV TOOLS
                   * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
                   * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
                   * ACTUALLY TAKING UP.
                   *
                   * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
                   * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
                   */
                  srcSet={srcSetString(catalogItem.imageUrls)}
                  /**
                   * SIZES EXPLAINED
                   *
                   * THIS MODAL WILL NOT GROW WIDER THAN 675PX
                   * BECAUSE OF CSS CLASS SMARTWIDTHLG
                   *
                   * FOR VIEWPORT WIDTHS SMALLER THAN 675, USE
                   * 100VW - 64PX BECAUSE THE MUIDIALOG HAS 32PX
                   * MARGINS LEFT AND RIGHT - 32PX FOR PADDING OF 16PX LEFT AND RIGHT
                   */
                  sizes="(min-width: 675px) calc(675px - 32px), calc(100vw - 64px - 32px)"
                  alt={catalogItem.name}
                  onLoad={() => {
                    setImageInfo({ loaded: true, error: false });
                  }}
                  onError={() => {
                    setImageInfo({ loaded: true, error: true });
                  }}
                  className="boxShadow4"
                  style={{
                    objectFit: "contain",
                    objectPosition: catalogItem.imageZoom
                      ? `${catalogItem.imageZoom.x}% ${catalogItem.imageZoom.y}%`
                      : "center",
                    verticalAlign: "middle",
                    // width: "100%",
                    maxWidth: "100%",
                    maxHeight: "25vh",
                    borderRadius: "5px",
                  }}
                />
              </div>
            )}
          </Fragment>
        )}
        <div>
          <div style={{ padding: "8px 12px" }}>
            <Typography variant="h6">{catalogItem.name}</Typography>
          </div>
          {!hideDescription && (
            <Fragment>
              <div
                className="ql-editor"
                style={{
                  /** NEED TO OVERRIDE THE MIN-HEIGHT AND PADDING USED DURING EDITING */
                  minHeight: 0,
                  padding: "8px 12px",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description.trim()),
                }}
              />

              <div style={{ marginTop: "1em" }} />
            </Fragment>
          )}

          {chargesEnabled && (
            <ListItem
              style={{ padding: "8px 12px" }}
              disableRipple
              button
              onClick={(e) => {
                e.stopPropagation();
                if (!catalogItem) {
                  return;
                }
                if (location.state?.instructions !== true) {
                  history.push(location.pathname, {
                    ...location.state,
                    instructions: true,
                  });
                }
              }}
            >
              {instructions && instructions.length > 0 ? (
                <DoneIcon />
              ) : (
                <InstructionsIcon />
              )}
              &nbsp;
              <ListItemText
                primaryTypographyProps={{
                  noWrap: true,
                }}
                secondary={t("dashboard.catalog.instructions")}
                primary={instructions || t("store.orders.none")}
              />
              {!hideFavorites && (
                <IconFavoriteWidget catalogItem={catalogItem} />
              )}
            </ListItem>
          )}
          <MUIDialog
            route={""}
            stateMatch={(state) => state.instructions === true}
          >
            <STorderInstructions
              initialInstructions={instructions}
              onSubmit={setInstructions}
            />
          </MUIDialog>

          <div>
            {catalogItem?.options?.map((option, index) => {
              const selection = selectedOptions.find(
                (selection) => selection.optionId === option.optionId
              );
              if (!selection) {
                // Can briefly happen if a publish occurs, but then we will update
                //   our selections to the defaults.
                return null;
              }
              return (
                <div
                  key={index}
                  ref={(element) => {
                    optionRefs.current[index] = element;
                  }}
                  style={{
                    marginBottom: "1em",
                    backgroundColor: "rgba(0,0,0,0.075)",
                    padding: "8px 12px",
                  }}
                >
                  <ItemOption
                    option={option}
                    selection={selection}
                    setSelection={(newSelection) => {
                      setSelectedOptions((prev) => {
                        const i = prev.findIndex(
                          (selection) => selection.optionId === option.optionId
                        );
                        if (i < 0) {
                          return [...prev, newSelection];
                        } else {
                          const newSelectedOptions = [...prev];
                          newSelectedOptions[i] = newSelection;
                          return newSelectedOptions;
                        }
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>

      <DialogActions style={{ display: "block", textAlign: "center" }}>
        {chargesEnabled && (
          <Fragment>
            <div style={{ padding: "0 min(1vw, 1em)" }}>
              <ItemCounter
                catalogItem={catalogItem}
                count={count}
                setCount={setCount}
              />
            </div>

            <div style={{ marginTop: "1em" }} />
            <Button
              endIcon={
                <div
                  style={{
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: "0.25em",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="body2">
                    {totalPrice.toFormat()}
                  </Typography>
                </div>
              }
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                const i = catalogItem.options.findIndex((option) => {
                  const selection = selectedOptions.find(
                    (selection) => selection.optionId === option.optionId
                  );
                  const incomplete =
                    selection &&
                    selection.type === optionType.LIST &&
                    option.required &&
                    Object.values(selection.selectedItems).filter((val) => val)
                      .length < 1;
                  return incomplete;
                });

                if (i >= 0) {
                  optionRefs.current[i]?.scrollIntoView({ behavior: "smooth" });
                } else {
                  onSubmit(count, instructions, selectedOptions);
                  history.goBack();
                }
              }}
            >
              {submitButton}
            </Button>
          </Fragment>
        )}
      </DialogActions>
    </Fragment>
  );
};

export default AddToCart;
