import { createContext } from "react";
import { SiteSettings } from "../../../database/siteSettings";
import { PrivateSiteSettings } from "../../../database/privateSiteSettings";
import { useContextThrowUndefined } from "../../../utilities/useContextThrowUndefined";

export interface SettingsApi {
  /**
   * The copy of the store location which is being edited
   */
  settings: SiteSettings;
  /**
   * The store location currently in the database
   */
  settingsInDb: SiteSettings;
  editSettings: (newSettings: Partial<SiteSettings>) => void;
  settingsDirty: boolean;
  publish: () => void;
  revert: () => void;
  /**
   * The copy of private settings which is being edited. Null indicates
   * it has not yet been downloaded
   */
  privateSettings: PrivateSiteSettings | null;
  /**
   * The private settings currently in the database. Null indicates
   * it has not yet been downloaded
   */
  editPrivateSettings: (
    newPrivateSettings: Partial<PrivateSiteSettings>
  ) => void;
  privateSettingsDirty: boolean;
  privateSettingsInDb: PrivateSiteSettings | null;
  valid: boolean;
  /**
   * A collection of errors with the current data. The key of this object
   * should be a url path to a page that can address the error.
   *
   * Currently the value is just a string, but if we add more complex
   * validation it may need to be something with more details that the
   * page can use to display the correct error information.
   */
  validationErrors: Record<string, string>;
  setValidationErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
}

export const SettingsApiContext =
  createContext<SettingsApi | undefined>(undefined);

SettingsApiContext.displayName = "SettingsApi";

export const useSettingsApi = () =>
  useContextThrowUndefined(SettingsApiContext);
