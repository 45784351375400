import { memo, Fragment, FC, useEffect, useState } from "react";
import { srcSetString } from "../../../Main/main";
import ImageIcon from "@material-ui/icons/Image";
import { ImageWidgetProps } from "../../../../database/orderPage";

interface Props extends ImageWidgetProps {
  initPath: string;
  onImageReady: (result: "loaded" | "error" | "noUrl") => void;
  sizesString: string | undefined;
}

const ImageWidget: FC<Props> = memo(
  ({
    imageUrl,
    imageUrls,
    imageZoom,
    alt,
    widthValue,
    heightValue,
    heightUnit,
    borderRadius,
    elevation,
    enlarge,
    initPath,
    onImageReady,
    sizesString,
  }) => {
    const [imageInfo, setImageInfo] = useState<{
      loaded: boolean;
      error: boolean;
    }>({ loaded: false, error: false });
    useEffect(() => {
      if (!imageUrl) {
        onImageReady("noUrl");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Fragment>
        <div style={{ position: "relative" }}>
          {imageUrl && imageInfo.error ? (
            <ImageIcon fontSize="large" htmlColor="lightgray" />
          ) : !imageUrl ? (
            <span></span>
          ) : (
            <img
              className={`boxShadow${elevation}`}
              src={imageUrl}
              /**
               * SRCSET EXPLAINED
               *
               * IN DEV TOOLS
               * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
               * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
               * ACTUALLY TAKING UP.
               *
               * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
               * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
               */
              srcSet={srcSetString(imageUrls)}
              /**
               * TECHNICALLY WE COULD BE MORE PRECISE HERE FOR SIZES
               * BUT CALCULATING THE IMAGE WIDTH BASED UPON HOW MANY CELLS
               * THE IMAGES TAKES UP SEEMS A BIT TOO MUCH
               *
               * FOR THAT REASON WE ARE JUST GOING TO USE THE SIZES FROM
               * THE CONTAINER GRID ITEM AND CALL IT GOOD
               */
              sizes={sizesString}
              alt={alt}
              onLoad={() => {
                setImageInfo({ loaded: true, error: false });
                onImageReady("loaded");
              }}
              onError={() => {
                setImageInfo({ loaded: true, error: true });
                onImageReady("error");
              }}
              style={{
                objectFit: "cover",
                objectPosition: imageZoom
                  ? `${imageZoom.x}% ${imageZoom.y}%`
                  : "center",
                verticalAlign: "middle",
                width: widthValue ? `${widthValue}%` : "100%",
                maxWidth: widthValue ? `${widthValue}%` : "100%",
                height: heightValue ? `${heightValue}${heightUnit}` : "100px",
                maxHeight: heightValue
                  ? `${heightValue}${heightUnit}`
                  : "100px",
                borderRadius: `${borderRadius}px`,
              }}
            />
          )}
        </div>
      </Fragment>
    );
  }
);

export default ImageWidget;
