import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Fragment, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSubscription } from "../Settings/subscription/useSubscription";
import WarningIcon from "@material-ui/icons/Warning";
import Stripe from "stripe";
import { useGridBashFirebase } from "../../../Firebase/context";
import { stripeAccount } from "../../../utilities/httpsCallables/httpsCallables";
import { unstable_batchedUpdates } from "react-dom";
import { loadStripe } from "../../PaymentProvider/loadStripe";
import useToast from "../../Main/useToast";
import ROUTES from "../../../utilities/constants/routes";
import { history } from "../../App/history";
import { ArrowRight } from "@material-ui/icons";
import { useRawStore } from "../../../customization/siteSettingsContext";

interface ActionNeededProps {}

export const useActionNeeded = () => {
  const { gridbashSubscription } = useSubscription();
  return gridbashSubscription?.status === "incomplete";
};

const ActionNeededSummary: FC<ActionNeededProps> = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { siteId } = useRawStore();
  const actionNeeded = useActionNeeded();
  const gridbashFirebase = useGridBashFirebase();
  const { gridbashSubscription } = useSubscription();

  const [loading, setLoading] = useState(
    Boolean(gridbashSubscription?.status === "incomplete")
  );
  const [invoice, setInvoice] = useState<
    Stripe.Invoice & {
      payment_intent: Stripe.PaymentIntent | null;
    }
  >();
  useEffect(() => {
    const invoiceId: string | null =
      typeof gridbashSubscription?.latest_invoice === "string"
        ? gridbashSubscription?.latest_invoice
        : gridbashSubscription?.latest_invoice?.id ?? null;

    if (
      !siteId ||
      gridbashSubscription?.status !== "incomplete" ||
      !invoiceId
    ) {
      setLoading(false);
      setInvoice(undefined);
      return;
    }

    let unmounted = false;
    const fetchInvoice = async () => {
      setLoading(true);
      const invoice = await stripeAccount(gridbashFirebase, {
        type: "getInvoice",
        siteId,
        invoiceId,
      });
      if (!unmounted) {
        unstable_batchedUpdates(() => {
          setLoading(false);
          setInvoice(invoice);
        });
      }
    };
    fetchInvoice();

    return () => {
      unmounted = true;
    };
  }, [gridbashFirebase, gridbashSubscription, siteId]);

  if (!actionNeeded) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        title={t("dashboard.overview.actionNeeded")}
        action={
          <IconButton>
            <WarningIcon color="error" />
          </IconButton>
        }
      />
      <hr />
      <CardContent style={{ textAlign: "left" }}>
        {loading && (
          <Typography color="textSecondary">
            {t("common.loading")}...
          </Typography>
        )}
        {invoice?.payment_intent?.status === "requires_action" && (
          <Fragment>
            <Typography>
              {t("dashboard.overview.paymentConfirmationNeeded")}
            </Typography>
            <Button
              startIcon={<ArrowRight />}
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  const stripe = await loadStripe(null);
                  const result = await stripe.confirmCardPayment(
                    invoice?.payment_intent?.client_secret ?? ""
                  );
                  if (result.error) {
                    // The card was declined (i.e. insufficient funds, card has expired, etc).
                    throw result;
                  } else {
                    toast({
                      message: t("dashboard.overview.paymentConfirmed"),
                    });
                  }
                } catch (err) {
                  console.error(err);
                  toast({
                    color: "error",
                    dialog: true,
                    message: t("dashboard.settings.unknownError"),
                  });
                }
              }}
            >
              {t("dashboard.overview.confirmPayment")}
            </Button>
          </Fragment>
        )}
        {invoice?.payment_intent?.status === "requires_payment_method" && (
          <Fragment>
            <Typography>{t("dashboard.overview.paymentFailed")}</Typography>
            <Button
              startIcon={<ArrowRight />}
              onClick={async (e) => {
                e.stopPropagation();
                history.push(`${ROUTES.DASHBOARD_SETTINGS}/subscription`);
                setTimeout(() => {
                  history.push(
                    `${ROUTES.DASHBOARD_SETTINGS}/subscription/manage`,
                    { section: "payment" }
                  );
                }, 200);
              }}
            >
              {t("dashboard.settings.communication.manageSubscription")}
            </Button>
          </Fragment>
        )}
      </CardContent>
    </Card>
  );
};

export default ActionNeededSummary;
