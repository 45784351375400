import DOMPurify from "dompurify";
import { FC } from "react";
import cleanString from "../../../../utilities/cleanString";
import "react-quill/dist/quill.snow.css";
import { Typography } from "@material-ui/core";
import { RichTextWidgetProps } from "../../../../database/orderPage";

const RichTextWidget: FC<RichTextWidgetProps> = ({
  overflow,
  richText: value,
}) => {
  if (overflow) {
    return (
      <div
        className="ql-editor"
        style={{
          /** NEED TO OVERRIDE THE MIN-HEIGHT USED DURING EDITING */
          minHeight: 0,
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(value.trim()),
        }}
      />
    );
  } else {
    return (
      <div
        style={{
          minHeight: "3em",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {cleanString(value) || `\xa0`}
        </Typography>
      </div>
    );
  }
};

export default RichTextWidget;
