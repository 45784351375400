import { FC, useState } from "react";
import * as React from "react";
import { AppBar, Icon, IconProps, ListItemIcon } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AccountIcon from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSiteSettings } from "../../../../customization/siteSettingsContext";
import useAccountInfo from "../../../Main/useAccountInfo";
import {
  NavigationItem,
  WellKnownPageId,
} from "../../../../database/siteSettings";
import { history } from "../../../App/history";
import ROUTES from "../../../../utilities/constants/routes";
import { Message } from "../../../../database/message";
import { useTranslation } from "react-i18next";
import type firebase from "firebase";
import Button from "@material-ui/core/Button";
import SignedInIcon from "@material-ui/icons/HowToReg";
import { useLatestMessageThread } from "../../../../utilities/useLatestMessageThread";
import { useLocation } from "react-router-dom";
import MUIPopover from "../../../Dashboard/Components/mui-popover";
import MyAccountMenu from "../myAccountMenu";

interface ContentProps {
  user: firebase.User | null;
  builder: boolean;
}

const Content: FC<ContentProps> = ({ user, builder }) => {
  const { navigationMenu, extraPageOptions } = useSiteSettings();
  const { contactInfo } = useAccountInfo();
  const location =
    useLocation<{ myAccountDrawer?: boolean; signIn?: boolean }>();
  const { t } = useTranslation();

  const [latestThread] = useLatestMessageThread();

  const onNavItemClicked = (navItem: NavigationItem) => {
    if (builder) return;
    if (navItem.type === "standard") {
      switch (navItem.pageId) {
        case WellKnownPageId.home:
          history.replace(ROUTES.STORE);
          break;
        case WellKnownPageId.order:
          history.replace(ROUTES.ORDER);
          break;
        case WellKnownPageId.favorites:
          history.replace(ROUTES.FAVORITES);
          break;
        case WellKnownPageId.track:
          history.replace(ROUTES.TRACK);
          break;
        case WellKnownPageId.account:
          history.replace(ROUTES.ACCOUNT);
          break;
        case WellKnownPageId.message:
          history.replace(location.pathname, {
            ...location.state,
            messages: true,
            threadId:
              latestThread && !latestThread.orderId
                ? latestThread.threadId
                : undefined,
          });
          break;
      }
    } else if (navItem.type === "custom") {
      const extraPage = extraPageOptions.pages.find((extraPage) => {
        return extraPage.pageId === navItem.pageId;
      });
      if (extraPage) {
        history.replace(extraPage.path);
      }
    }
  };

  const hasMessage =
    latestThread &&
    !latestThread.orderId &&
    Object.values(latestThread.messages).some(
      (message: Message) => message.from === "admin" && !message.read
    );

  const handleSignInOut = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (builder) {
      return;
    } else if (user) {
      if (location.state?.myAccountDrawer !== true)
        history.push(location.pathname, {
          ...location.state,
          myAccountDrawer: true,
        });
    } else {
      if (location.state?.signIn !== true)
        history.push(location.pathname, {
          ...location.state,
          signIn: true,
        });
    }
    setAnchor(e.currentTarget);
  };

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <div>
      <AppBar
        color={user ? "secondary" : undefined}
        position="sticky"
        style={{
          opacity: 1,
          padding: "0 1em",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          transition: "all 0.5s ease",
        }}
      >
        {user ? (
          <React.Fragment>
            <AccountIcon />
            &nbsp; &nbsp;
            <Typography noWrap variant="body2">
              {contactInfo.name.toUpperCase() ||
                contactInfo.email.toUpperCase()}
            </Typography>
            <Button
              startIcon={<Icon>logout</Icon>}
              onClick={(e) => {
                e.stopPropagation();
                handleSignInOut(e);
              }}
            >
              Sign Out
            </Button>
          </React.Fragment>
        ) : (
          <Button
            color="inherit"
            fullWidth
            startIcon={<SignedInIcon />}
            onClick={(e) => {
              e.stopPropagation();
              handleSignInOut(e);
            }}
          >
            {t("store.account.signIn")}
          </Button>
        )}
      </AppBar>
      {anchor && (
        <MUIPopover
          anchorEl={anchor}
          route=""
          stateMatch={(state) => Boolean(state.myAccountDrawer)}
        >
          <MyAccountMenu />
        </MUIPopover>
      )}
      <div
        style={{
          marginTop: "0.5em",
          marginBottom: "0.5em",
        }}
      >
        {navigationMenu.items.map((navigationItem) => {
          let iconFill: IconProps["color"] = "inherit";
          // let textColor: TypographyProps["color"] = "inherit";
          if (navigationItem.pageId === WellKnownPageId.order) {
            iconFill = "secondary";
            // textColor = "secondary";
          } else if (
            navigationItem.pageId === WellKnownPageId.message &&
            hasMessage
          ) {
            iconFill = "secondary";
          }

          return (
            <MenuItem
              divider
              button
              key={navigationItem.navItemId}
              onClick={(e) => {
                e.stopPropagation();
                onNavItemClicked(navigationItem);
              }}
              style={{ paddingLeft: "1em", paddingRight: "2em" }}
            >
              <ListItemIcon>
                <Icon color={iconFill}>{navigationItem.iconSettings.icon}</Icon>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={
                  navigationItem.textSettings.text ||
                  t(navigationItem.textSettings.textKey ?? "")
                }
              />
            </MenuItem>
          );
        })}
      </div>
    </div>
  );
};

export default Content;
