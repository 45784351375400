import { FC } from "react";
import { PageColumn } from "../../../../database/page";
import { ColorConfig } from "../../../widgets/colorConfig";
import WidgetRenderer from "../widgetRenderer";

export interface RowWidgetProps {
  columns: PageColumn[];
  backgroundImage?: {
    url: string;
    urls: { [key: string]: string };
    anchorHorizontal: "left" | "center" | "right";
    anchorVertical: "top" | "center" | "bottom";
    opacity: number;
  };
  backgroundColor?: ColorConfig;
}

const RowWidget: FC<RowWidgetProps> = ({ columns }) => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      {columns.map((column, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            alignItems: column.cellAlign,
            width: `${column.width}%`,
            paddingLeft: column.paddingLeft,
            paddingRight: column.paddingRight,
            paddingTop: column.paddingTop,
            paddingBottom: column.paddingBottom,
          }}
        >
          {column.cells.map((cell) => (
            <WidgetRenderer key={cell.id} cell={cell} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default RowWidget;
