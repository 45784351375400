import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { FC, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AccountInfo } from "../../Main/useMain";
import AccountAddress from "../../Store/Modals/accountAddress";
import AddressIcon from "@material-ui/icons/Business";
import { history } from "../../App/history";
import MUIDialog from "../Components/mui-dialog";

interface AddressListItemProps {
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
  initPath: string;
}

const AddressListItem: FC<AddressListItemProps> = ({
  accountInfo,
  setOptimisticUpdate,
  initPath,
}) => {
  const { t } = useTranslation();

  const { address } = accountInfo.contactInfo;
  const popoverPath = `${initPath}/address`;

  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  return (
    <React.Fragment>
      <ListItem
        disableRipple
        button
        onClick={(e) => {
          e.stopPropagation();
          if (!window.location.pathname.includes(popoverPath))
            history.push(popoverPath);
          setAnchor(e.currentTarget);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <AddressIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={t("store.account.accountAddress")}
          secondary={
            address.streetLine1.length > 0 ? (
              <React.Fragment>
                <span>{address.streetLine1}</span>&nbsp;
                {address.streetLine2 && (
                  <React.Fragment>
                    <span>{address.streetLine2}</span>&nbsp;
                  </React.Fragment>
                )}
                {address.streetLine3 && (
                  <React.Fragment>
                    <span>{address.streetLine3}</span>&nbsp;
                  </React.Fragment>
                )}
                <span style={{ display: "block" }}>{address.city}</span>
                {address.stateCode && (
                  <React.Fragment>
                    <span>{t(`store.account.state.${address.stateCode}`)}</span>
                    &nbsp;
                  </React.Fragment>
                )}
                <span>{address.zip}</span>
                <span style={{ display: "block" }}>
                  {address.countryCode &&
                    t(`store.account.country.${address.countryCode}`)}
                </span>
              </React.Fragment>
            ) : (
              t("store.account.noAddressButton")
            )
          }
        />
      </ListItem>

      {anchor && (
        <MUIDialog route={popoverPath}>
          <AccountAddress
            initPath={popoverPath}
            accountInfo={accountInfo}
            setOptimisticUpdate={setOptimisticUpdate}
          />
        </MUIDialog>
      )}
    </React.Fragment>
  );
};

export default AddressListItem;
