import { Dispatch, SetStateAction } from "react";
import * as React from "react";

export interface BuilderValue {
  expanded: string | false;
  setExpanded: Dispatch<SetStateAction<string | false>>;
  handleAccordion: (
    key: string
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
}

export const BuilderContext = React.createContext<BuilderValue | undefined>(
  undefined
);
