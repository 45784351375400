import { Chip } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Revision, revisionStatus } from "../../database/order";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";

export interface RevisionStatusIconProps {
  revision: Revision;
  showAdminFeatures: boolean;
}

const RevisionStatusIcon: FC<RevisionStatusIconProps> = ({
  revision,
  showAdminFeatures,
}) => {
  const { status } = revision;
  const { t } = useTranslation();

  if (!showAdminFeatures && status === revisionStatus.incomplete) {
    // Don't show the "unresolved changes" icon
    return null;
  }

  let statusMessage: string;
  let statusColor: "primary" | "secondary";
  let statusIcon: JSX.Element;

  switch (status) {
    case revisionStatus.complete:
    case revisionStatus.complete_accepted:
      if (revision.priceAdjustment.total > 0) {
        statusMessage = t("dashboard.charges.paid");
      } else if (revision.priceAdjustment.total < 0) {
        statusMessage = t("dashboard.charges.refunded");
      } else {
        statusMessage = t("dashboard.charges.resolved");
      }
      statusColor = "primary";
      statusIcon = <DoneIcon />;
      break;
    case revisionStatus.complete_declined:
      statusMessage = t("dashboard.charges.customerDeclinedShort");
      statusColor = "secondary";
      statusIcon = <ErrorIcon />;
      break;
    case revisionStatus.authrequired:
    case revisionStatus.pendingauth:
    case revisionStatus.lockedForPayment:
      statusMessage = t("dashboard.charges.requiresAuthenticationShort");
      statusColor = "secondary";
      statusIcon = <WarningIcon />;
      break;
    case revisionStatus.incomplete:
      statusMessage = t("dashboard.charges.unresolvedChanges");
      statusColor = "secondary";
      statusIcon = <WarningIcon />;

    // No default case, because we've covered them all. If ever a
    //   new status is added, typescript will warn us we're using
    //   the variables before they're initialized
  }

  return (
    <Chip
      color={statusColor}
      style={{
        margin: "1vh 0.5em",
      }}
      variant="default"
      label={statusMessage}
      icon={statusIcon}
    />
  );
};

export default RevisionStatusIcon;
