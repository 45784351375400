import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/firebase-storage";
import "firebase/functions";
import "firebase/firebase-messaging";
import { SiteUser } from "../database/userData";
import { Order, orderStatus } from "../database/order";
import { setChecked } from "../utilities/orderProcessing";
import { GridbashFirebase } from "./gridBashFirebase";

// This can be swapped out to read from a different part of the
//    database for development purposes.
export const storeKey = "store";

const firebaseInstances: Record<string, SiteFirebase> = {};

interface Options {
  config: Object;
  name: string;
  vapidKey: string;
  functionRegion: string;
  gridbashFirebase?: GridbashFirebase;
  /**
   * This is only required if a superuser wants to sign in to random
   * sites. For normal users, the authentication process will figure
   * out the site id from their custom claims.
   */
  siteId?: string;
}

/**
 * Firebase instance for individual sites
 */
export class SiteFirebase {
  FieldValue = firebase.firestore.FieldValue;
  Timestamp = firebase.firestore.Timestamp;
  auth: firebase.auth.Auth;
  firestore: firebase.firestore.Firestore;
  database: firebase.database.Database;
  functions: firebase.functions.Functions;
  pushSupported = false;
  messaging: firebase.messaging.Messaging | undefined;
  storage: firebase.storage.Storage;
  vapidKey: string;

  private gridbashFirebase: GridbashFirebase | undefined;
  private app: firebase.app.App;
  private readyPromise: Promise<firebase.User | null>;
  private siteId?: string;

  public static createInstance(options: Options) {
    if (!firebaseInstances[options.name]) {
      firebaseInstances[options.name] = new SiteFirebase(options);
    } else {
      // Don't need to construct a brand new one, but we do need to make sure
      //   its logged in/out as appropriate
      firebaseInstances[options.name].readyPromise =
        firebaseInstances[options.name].initUser();
    }
    return firebaseInstances[options.name];
  }

  public static getInstance(
    name: string | null | undefined
  ): SiteFirebase | undefined {
    if (!name) {
      return undefined;
    }
    return firebaseInstances[name];
  }

  private constructor({
    config,
    name,
    vapidKey,
    functionRegion,
    gridbashFirebase,
    siteId,
  }: Options) {
    this.siteId = siteId;
    let firstTime = true;
    this.vapidKey = vapidKey;
    try {
      // In case it's already created, try to get it
      this.app = firebase.app(name);
      firstTime = false;
    } catch (err) {
      // Doesn't exist, because it's our first time. This is completely normal.
      this.app = firebase.initializeApp(config, name);
    }
    this.auth = this.app.auth();

    this.firestore = this.app.firestore();
    this.functions = this.app.functions(functionRegion);
    this.storage = this.app.storage();
    this.database = this.app.database();

    try {
      this.messaging = this.app.messaging();
      if (firstTime) {
        // Trying to initialize the vapid key multiple times throws an error.
        // this.messaging.usePublicVapidKey(vapidKey);
      }

      this.pushSupported = true;

      // Tell the service worker that we've connected to this firebase app.
      //   It will do the same, and thus receive push notifications.
      if ("navigator" in window) {
        window.navigator.serviceWorker.ready.then((registration) => {
          registration.active?.postMessage({
            eventType: "firebaseApp",
            data: config,
          });
        });
      }
    } catch (error) {
      console.log("PUSH NOTIFICATIONS NOT SUPPORTED", error);
    }

    this.gridbashFirebase = gridbashFirebase;
    this.readyPromise = this.initUser();

    //THE FOLLOWING LINE MUST BE COMMENTED OUT WHEN DEPLOYING
    //TO PRODUCTION //NPM RUN SERVE
    // this.functions.useFunctionsEmulator("http://localhost:5000");
  }

  async initUser(): Promise<firebase.User | null> {
    // Wait to see if the user is automatically logged in from persistent data
    const user = await new Promise<firebase.User | null>((resolve) => {
      const unsubscribe = this.auth.onAuthStateChanged((user) => {
        resolve(user);
        unsubscribe();
      });
    });
    if (!this.gridbashFirebase) {
      return user;
    }

    const gridbashUser = this.gridbashFirebase.auth.currentUser;
    if (!gridbashUser && user) {
      // We're signed into the site, but not into gridbash. Sign out of the site
      await this.auth.signOut();
      return null;
    } else if (gridbashUser && !user) {
      // We're signed into the gridbash firebase, but not to the site firebase. Sign in to site.
      const idToken = await gridbashUser.getIdToken();
      const customToken = await this.gridbashFirebase.getCustomToken(
        idToken,
        this.siteId
      );
      const credential = await this.auth.signInWithCustomToken(customToken);
      return credential.user;
    } else {
      // Gridbash and site are in agreement
      return gridbashUser;
    }
  }

  signIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  signOut() {
    if (this.gridbashFirebase) {
      this.gridbashFirebase.auth.signOut();
    }
    return this.auth.signOut();
  }
  createAccount(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }
  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }
  updatePassword(password: string) {
    return this.auth.currentUser?.updatePassword(password);
  }
  sendVerificationEmail() {
    return this.auth.currentUser?.sendEmailVerification();
  }
  updateEmail(email: string) {
    return this.auth.currentUser?.updateEmail(email);
  }
  isReady() {
    return this.readyPromise;
  }

  async addPushToken(userId: string): Promise<void> {
    if (!this.pushSupported || !this.messaging) {
      throw new Error("incognito");
    }
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      throw new Error("denied");
    }

    const pushToken = await this.messaging.getToken({
      vapidKey: this.vapidKey,
    });
    let errorMessage: string | undefined;
    await this.firestore.runTransaction(async (transaction) => {
      const ref = this.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("users")
        .doc(userId);
      const doc = await transaction.get(ref);
      const data = doc.data() as SiteUser | undefined;
      if (!data) {
        errorMessage = "notFound";
        return;
      }

      if (data.notifications.pushTokens.includes(pushToken)) {
        console.log("No need to add push token to database.");
        return;
      }

      console.log("Adding push token to database.");

      data.notifications.pushTokens.push(pushToken);

      transaction.set(ref, data);
    });
    if (errorMessage) {
      throw new Error(errorMessage);
    }
  }

  async deletePushTokens(userId: string) {
    return this.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("users")
      .doc(userId)
      .update({
        "notifications.pushTokens": [],
      });
  }

  async setOrderStatus(order: Order, newValue: orderStatus) {
    let update: firebase.firestore.UpdateData = {};
    if (
      newValue === orderStatus.ready ||
      newValue === orderStatus.delivered ||
      newValue === orderStatus.intransit
    ) {
      // Mark all remaining items as complete
      let updatedOrder = order;
      Object.values(order.cumulativeCartItems).forEach((cartItem) => {
        updatedOrder = setChecked(
          updatedOrder,
          cartItem,
          cartItem.count - cartItem.unable
        );
      });
      update = {
        status: newValue,
        "delivery.timeCompleted": new Date(),
        revisions: updatedOrder.revisions,
        cumulativeCartItems: updatedOrder.cumulativeCartItems,
        // No need to include cumulative price, because we're not changing the unable count
        //   and so the price can't change
      };
    } else {
      //WHEN CHANGING FROM A READY STATUS TO AN IN PROGRESS STATUS
      //RESET COMPLETION DATE/TIME TO A "RESET" DATE/TIME
      update = {
        status: newValue,
        "delivery.timeCompleted": new Date(2000, 0, 1, 0, 0, 0, 0),
      };
    }

    // This update is allowed even if the order is locked for payment, since
    //   it can't plausibly interfere with the payment
    return this.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("orders")
      .doc(order.orderId)
      .update(update);
  }

  async setOrderDueTime(orderId: string, newValue: Date) {
    const update: firebase.firestore.UpdateData = {
      "delivery.timePlanned": newValue,
    };
    return this.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("orders")
      .doc(orderId)
      .update(update);
  }
}
