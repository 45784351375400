import { FC } from "react";
import * as React from "react";
import * as R from "ramda";
import useSiteUser from "../../UserProvider/useSiteUser";
import { history } from "../../App/history";
import { useSiteFirebase } from "../../../Firebase/context";
import { storeKey } from "../../../Firebase/siteFirebase";
import AddressForm from "../Components/addressForm";
import { deleteUndefineds } from "../../../utilities/deleteUndefineds";
import { PostalAddress } from "../../../database/siteSettings";
import { AccountInfo } from "../../Main/useMain";
import { useTranslation } from "react-i18next";
import useToast from "../../Main/useToast";

interface AccountAddressProps {
  initPath: string;
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
}

const AccountAddress: FC<AccountAddressProps> = React.memo(
  ({ initPath, accountInfo, setOptimisticUpdate }) => {
    const { t } = useTranslation();
    const { user } = useSiteUser();
    const firebase = useSiteFirebase();
    const toast = useToast();

    const changeAddress = async (newAddress: PostalAddress) => {
      if (!user) {
        return;
      }
      const value = deleteUndefineds({
        ...newAddress,
        streetLine1: newAddress.streetLine1.trim(),
        streetLine2: newAddress.streetLine2?.trim(),
        streetLine3: newAddress.streetLine2?.trim(),
        city: newAddress.city.trim(),
        zip: newAddress.zip.trim(),
      });
      if (R.equals(value, accountInfo.contactInfo.address)) {
        history.goBack();
        return;
      }

      try {
        setOptimisticUpdate({
          ...accountInfo,
          contactInfo: {
            ...accountInfo.contactInfo,
            address: value,
          },
        });

        history.goBack();

        await firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("users")
          .doc(user.uid)
          .update({
            "contactInfo.address": value,
          });
      } catch (error) {
        console.log("sTaccAddress.js", error);

        toast({
          dialog: true,
          color: "error",
          message: t("dashboard.account.errorChangingSetting"),
        });
      }
    };

    return (
      <AddressForm
        initialAddress={accountInfo.contactInfo.address}
        onSubmit={changeAddress}
        initPath={initPath}
      />
    );
  }
);

export default AccountAddress;
