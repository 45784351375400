import { memo, FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import AddToCartIcon from "@material-ui/icons/AddShoppingCart";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { IconCartWidgetProps } from "../../../../database/orderPage";
import { BuilderContext } from "../../../Dashboard/Builder/OrdersBuilder/builderContext";
import PauseIcon from "@material-ui/icons/Pause";
import { CatalogItem_WithDefaults } from "../../../../database/catalogItem";
import ROUTES from "../../../../utilities/constants/routes";
import useCartApi from "../../../CartManager/useCartApi";
import useToast from "../../../Main/useToast";
import { history } from "../../../App/history";

export interface Props extends IconCartWidgetProps {
  catalogItem: CatalogItem_WithDefaults;
}

const IconCartWidget: FC<Props> = memo(({ catalogItem }) => {
  const { t } = useTranslation();
  const builder = useContext(BuilderContext);
  const toast = useToast();
  const cartApi = useCartApi();
  const isInCart = Object.values(cartApi.cartItems).some(
    (cartItem) => cartItem.catalogItem.itemId === catalogItem.itemId
  );

  return (
    <div style={{ padding: "4px" }}>
      {catalogItem.unavailable ? (
        <IconButton
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            if (builder) {
              return;
            }
            toast({
              dialog: true,
              color: "normal",
              message: t("store.orders.itemUnavailable"),
            });
          }}
        >
          <PauseIcon />
        </IconButton>
      ) : (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();

            const path = builder
              ? `${ROUTES.DASHBOARD_BUILDER}/orders/details/${catalogItem.itemId}`
              : `${ROUTES.ORDER}/details/${catalogItem.itemId}`;
            if (!window.location.pathname.includes(path)) {
              history.push(path);
            }
          }}
        >
          {isInCart ? <ShoppingCartIcon /> : <AddToCartIcon />}
        </IconButton>
      )}
    </div>
  );
});

export default IconCartWidget;
