export const validateEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const validatePassword = (password: string) => {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password);
};

const validateEmailPass = (input: HTMLInputElement) => {
  if (input.name === "email") {
    return validateEmail(input.value.trim().toLowerCase());
  } else if (input.name === "password" || input.name === "newpassword") {
    return validatePassword(input.value.trim());
  }
};

export default validateEmailPass;
