import memoize from "memoize-one";

const getTimeString = memoize(
  ({
    request,
    currentTime,
    timePlanned,
    timePlannedOrig,
    timeCreated,
    timeCompleted,
    orderIncrement,
    text,
    t,
  }) => {
    const timeObject = {
      withinDue: false,
      inThePast: false,
      diffDetail: "",
      diffNoDetail: "",
      timeCreated: "",
      complDetail: "",
      complNoDetail: "",
      complLate: false,
    };
    if (!t) {
      console.warn(
        "NO TRANSLATE FUNCTION PASSED. Pass the 't' function to get a valid time string."
      );
      return timeObject;
    }
    if (request === "all" || request === "completed") {
      //CONSTRUCT COMPLETE TIME STRINGS
      const completedDiffMin = (timePlannedOrig - timeCompleted) / 60 / 1000;
      const diffM = Math.round(Math.abs(completedDiffMin));
      if (diffM === 0) {
        timeObject.complDetail = t("dashboard.orders.rightOnTime");
        timeObject.complNoDetail = `${diffM} ${t("dashboard.orders.m")}`;
      } else if (completedDiffMin < 0) {
        timeObject.complLate = true;
        timeObject.complDetail = `${diffM} ${
          diffM === 1 ? t("dashboard.orders.min") : t("dashboard.orders.mins")
        } ${t("dashboard.orders.late")}`;
        timeObject.complNoDetail = `${diffM} ${t("dashboard.orders.m")}`;
        if (diffM >= 1440) {
          const diffD = Math.round((diffM / 60 / 24) * 10) / 10;
          if (diffD > 365) {
            timeObject.complDetail = t("dashboard.orders.1+yearLate");
            timeObject.complNoDetail = t("dashboard.orders.1+y");
          } else {
            timeObject.complDetail = `${diffD} ${
              diffD === 1
                ? t("dashboard.orders.day")
                : t("dashboard.orders.days")
            } ${t("dashboard.orders.late")}`;
            timeObject.complNoDetail = `${diffD} ${t("dashboard.orders.d")}`;
          }
        } else if (diffM >= 60) {
          const diffH = Math.round((diffM / 60) * 10) / 10;
          timeObject.complDetail = `${diffH} ${
            diffH <= 1
              ? t("dashboard.orders.hour")
              : t("dashboard.orders.hours")
          } ${t("dashboard.orders.late")}`;
          timeObject.complNoDetail = `${diffH} ${t("dashboard.orders.h")}`;
        }
      } else {
        timeObject.complDetail = `${diffM} ${
          diffM === 1 ? t("dashboard.orders.min") : t("dashboard.orders.mins")
        } ${t("dashboard.orders.early")}`;
        timeObject.complNoDetail = `${diffM} ${t("dashboard.orders.m")}`;
        if (diffM >= 1440) {
          const diffD = Math.round((diffM / 60 / 24) * 10) / 10;
          if (diffD > 365) {
            timeObject.complDetail = t("dashboard.orders.1+yearEarly");
            timeObject.complNoDetail = t("dashboard.orders.1+y");
          } else {
            timeObject.complDetail = `${diffD} ${
              diffD === 1
                ? t("dashboard.orders.day")
                : t("dashboard.orders.days")
            } ${t("dashboard.orders.early")}`;
            timeObject.complNoDetail = `${diffD} ${t("dashboard.orders.d")}`;
          }
        } else if (diffM >= 60) {
          const diffH = Math.round((diffM / 60) * 10) / 10;
          timeObject.complDetail = `${diffH} ${
            diffH <= 1
              ? t("dashboard.orders.hour")
              : t("dashboard.orders.hours")
          } ${t("dashboard.orders.early")}`;
          timeObject.complNoDetail = `${diffH} ${t("dashboard.orders.h")}`;
        }
      }
    }
    if (request === "all" || request === "created") {
      //CONSTRUCT CREATE TIME STRINGS
      const timeCreatedM = Math.round(
        Math.abs((timeCreated - currentTime) / 60 / 1000)
      );
      if (timeCreatedM === 0) {
        if (request === "created") {
          timeObject.timeCreated = "";
        } else {
          timeObject.timeCreated = `${text} ${t("dashboard.orders.0minsAgo")}`;
        }
      } else if (timeCreatedM > 0) {
        timeObject.timeCreated = `${text} ${timeCreatedM} ${
          timeCreatedM === 1
            ? t("dashboard.orders.min")
            : t("dashboard.orders.mins")
        } ${t("dashboard.orders.ago")}`;
        if (timeCreatedM >= 1440) {
          const diffD = Math.round((timeCreatedM / 60 / 24) * 10) / 10;
          if (diffD > 365) {
            timeObject.timeCreated = `${text} ${t(
              "dashboard.orders.1+yearAgo"
            )}`;
          } else {
            timeObject.timeCreated = `${text} ${diffD} ${
              diffD === 1
                ? t("dashboard.orders.day")
                : t("dashboard.orders.days")
            } ${t("dashboard.orders.ago")}`;
          }
        } else if (timeCreatedM >= 60) {
          const diffH = Math.round((timeCreatedM / 60) * 10) / 10;
          timeObject.timeCreated = `${text} ${diffH} ${
            diffH <= 1
              ? t("dashboard.orders.hour")
              : t("dashboard.orders.hours")
          } ${t("dashboard.orders.ago")}`;
        }
      }
    }
    if (request === "all") {
      //CREATE DUE TIME STRINGS
      const timeDiffMin = (timePlanned - currentTime) / 60 / 1000;
      const diffM = Math.round(Math.abs(timeDiffMin));
      if (timeDiffMin <= orderIncrement) {
        timeObject.withinDue = true;
      }
      if (diffM === 0) {
        timeObject.inThePast = true;
        timeObject.diffDetail = t("dashboard.orders.dueNow");
        timeObject.diffNoDetail = `${diffM} ${t("dashboard.orders.m")}`;
      } else if (timeDiffMin < 0) {
        timeObject.inThePast = true;
        timeObject.diffDetail = `${t("dashboard.orders.due")} ${diffM} ${
          diffM === 1 ? t("dashboard.orders.min") : t("dashboard.orders.mins")
        } ${t("dashboard.orders.ago")}`;
        timeObject.diffNoDetail = `${diffM} ${t("dashboard.orders.m")}`;
        if (diffM >= 1440) {
          const diffD = Math.round((diffM / 60 / 24) * 10) / 10;
          if (diffD > 365) {
            timeObject.diffDetail = `${t("dashboard.orders.due")} ${t(
              "dashboard.orders.1+yearAgo"
            )}`;
            timeObject.diffNoDetail = t("dashboard.orders.1+y");
          } else {
            timeObject.diffDetail = `${t("dashboard.orders.due")} ${diffD} ${
              diffD === 1
                ? t("dashboard.orders.day")
                : t("dashboard.orders.days")
            } ${t("dashboard.orders.ago")}`;
            timeObject.diffNoDetail = `${diffD} ${t("dashboard.orders.d")}`;
          }
        } else if (diffM >= 60) {
          const diffH = Math.round((diffM / 60) * 10) / 10;
          timeObject.diffDetail = `${t("dashboard.orders.due")} ${diffH} ${
            diffH <= 1
              ? t("dashboard.orders.hour")
              : t("dashboard.orders.hours")
          } ${t("dashboard.orders.ago")}`;
          timeObject.diffNoDetail = `${diffH} ${t("dashboard.orders.h")}`;
        }
      } else {
        timeObject.diffDetail = `${t("dashboard.orders.dueIn")} ${diffM} ${
          diffM === 1 ? t("dashboard.orders.min") : t("dashboard.orders.mins")
        }`;
        timeObject.diffNoDetail = `${diffM} ${t("dashboard.orders.m")}`;
        if (diffM >= 1440) {
          const diffD = Math.round((diffM / 60 / 24) * 10) / 10;
          if (diffD > 365) {
            timeObject.diffDetail = `${t("dashboard.orders.dueIn")} ${t(
              "dashboard.orders.1+year"
            )}`;
            timeObject.diffNoDetail = t("dashboard.orders.1+y");
          } else {
            timeObject.diffDetail = `${t("dashboard.orders.dueIn")} ${diffD} ${
              diffD === 1
                ? t("dashboard.orders.day")
                : t("dashboard.orders.days")
            }`;
            timeObject.diffNoDetail = `${diffD} ${t("dashboard.orders.d")}`;
          }
        } else if (diffM >= 60) {
          const diffH = Math.round((diffM / 60) * 10) / 10;
          timeObject.diffDetail = `${t("dashboard.orders.dueIn")} ${diffH} ${
            diffH <= 1
              ? t("dashboard.orders.hour")
              : t("dashboard.orders.hours")
          }`;
          timeObject.diffNoDetail = `${diffH} ${t("dashboard.orders.h")}`;
        }
      }
    }
    return timeObject;
  }
);

export default getTimeString;
