import { useState, FC, useEffect } from "react";
import * as React from "react";
import { matchPath } from "react-router-dom";
import ROUTES from "../../../utilities/constants/routes";
import { validateEmail } from "../../../utilities/validateEmailPassPhone";
import useSpinner from "../../Spinner/useSpinner";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../utilities/useQuery";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { history } from "../../App/history";
import useSiteUser from "../../UserProvider/useSiteUser";
import { useSiteFirebase } from "../../../Firebase/context";
import useToast from "../../Main/useToast";

/**
 * Returns a function which redirects to the appropriate page
 * after a sign in occurs.
 */
export const useAfterSignin = () => {
  const query = useQuery();
  const redirect = query.get("redirect");
  return () => {
    if (redirect) {
      // If there's an explicit redirect, use it
      history.replace(redirect);
    } else if (
      matchPath(window.location.pathname, {
        path: `${ROUTES.rootPath}${ROUTES.ML_SIGNIN}`,
      }) ||
      matchPath(window.location.pathname, { path: ROUTES.REGISTER })
    ) {
      // If we're on the home page, send them to track their orders
      history.replace(ROUTES.TRACK);
    } else {
      // Otherwise, just close the modal
      history.goBack();
    }
  };
};

interface STauthProps {
  /**
   * Normally, we use a standardized function for routing once
   * sign in is complete. If this function is provided, that
   * behavior will be overridden.
   */
  afterSignin?: () => void;
  showCreateAccount?: boolean;
  message?: string;
  /**
   * If true, this indicates we got here via a deep link. The user
   * may be automatically logged in, in which case we will redirect
   * as soon as we know that.
   */
  isDeepLink?: boolean;
}

const STauth: FC<STauthProps> = React.memo(
  ({
    afterSignin: afterSigninProp,
    showCreateAccount = true,
    message,
    isDeepLink = false,
  }) => {
    const defaultAfterSignin = useAfterSignin();
    const afterSignin = afterSigninProp || defaultAfterSignin;

    const firebase = useSiteFirebase();
    const toast = useToast();
    const showSpinner = useSpinner();
    const { t } = useTranslation();
    const [authEmail, setAuthEmail] = useState("");
    const emailIsValid = validateEmail(authEmail);
    const [authPassword, setAuthPassword] = useState("");
    const isPasswordForLoginValid =
      authPassword.length === 0 || authPassword.length >= 5;
    const [showPassword, setShowPassword] = useState(false);

    const { user, loading } = useSiteUser();
    useEffect(() => {
      if (!loading && user && isDeepLink) {
        // Already logged in
        afterSignin();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    async function signIn(email: string, password: string) {
      const hideSpinner = showSpinner({ lag: "none" });
      try {
        await firebase.signIn(email, password);
        afterSignin();
      } catch (error) {
        let message;
        if (error.code === "auth/wrong-password") {
          message = t("toast.wrongPassword");
        } else if (error.code === "auth/user-disabled") {
          message = t("toast.disabledAccount");
        } else if (error.code === "auth/user-not-found") {
          message = t("toast.emailNotFound");
        } else if (error.code === "auth/network-request-failed") {
          message = t("toast.noNetwork");
        } else {
          console.log("Sign In Error", error);
          message = t("toast.systemError");
        }

        toast({
          dialog: true,
          color: "error",
          message,
        });
      } finally {
        hideSpinner();
      }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (!emailIsValid) {
        return;
      } else if (!isPasswordForLoginValid) {
        return;
      } else {
        signIn(authEmail, authPassword);
      }
    };

    if (loading) {
      return null;
    }

    return (
      <React.Fragment>
        <DialogTitle className="smartWidthMd">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {message || t("store.account.signIn")}
            {showCreateAccount && (
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (isDeepLink) {
                      // If they deep linked, then there's nothing for the register page to
                      // goBack to. We need to put the the home page into, and then go
                      history.replace(ROUTES.STORE);
                      history.push(ROUTES.REGISTER);
                    } else {
                      history.replace(ROUTES.REGISTER);
                    }
                  }}
                  size="small"
                >
                  <div>
                    {t("store.account.createAccount")}
                    <Typography
                      color="textPrimary"
                      component="div"
                      variant="caption"
                    >
                      {t("store.orders.in5seconds")}
                    </Typography>
                  </div>
                </Button>
              </div>
            )}
          </div>
        </DialogTitle>

        <DialogContent className="smartWidthMd">
          <form noValidate>
            <FormControl style={{ marginBottom: 0 }} fullWidth>
              <TextField
                id="username"
                autoComplete="username"
                label={t("store.account.enterEmail")}
                type="email"
                fullWidth
                value={authEmail.toLowerCase()}
                onChange={(e) => setAuthEmail(e.currentTarget.value.trim())}
                error={authEmail.length > 0 && !emailIsValid}
                helperText={
                  authEmail.length > 0 && !emailIsValid
                    ? t("store.account.badEmail")
                    : `\xa0`
                }
              />
            </FormControl>

            <FormControl style={{ marginTop: 0 }} fullWidth>
              <TextField
                id="password"
                autoComplete="password"
                label={t("store.account.enterPassword")}
                type={showPassword ? "text" : "password"}
                fullWidth
                value={authPassword}
                onChange={(e) => setAuthPassword(e.currentTarget.value)}
                error={!isPasswordForLoginValid}
                helperText={
                  !isPasswordForLoginValid
                    ? t("store.account.badPassword")
                    : "\xa0"
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                }
                label={t("store.account.showPassword")}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          <Button
            onClick={() => {
              if (isDeepLink) {
                // If they deep linked, then there's nothing for the reset page to
                // goBack to. We need to put the the home page into, and then go
                history.replace(ROUTES.STORE);
                history.push(ROUTES.RESET);
              } else {
                history.replace(ROUTES.RESET);
              }
            }}
            size="small"
          >
            {t("store.account.forgot")}
          </Button>
          <span style={{ flexGrow: 1 }}></span>
          <Button
            onClick={() => {
              if (isDeepLink) {
                // If they deep linked, then doing goBack would leave our site.
                history.replace(ROUTES.STORE);
              } else {
                history.goBack();
              }
            }}
          >
            {t("store.cancel")}
          </Button>
          <Button onClick={(e) => handleSubmit(e)}>{t("store.submit")}</Button>
        </DialogActions>
      </React.Fragment>
    );
  }
);

export default STauth;
