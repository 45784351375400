// import { Category } from "./category";
import { SiteTheme_v1, SiteTheme_v2, TextOptions } from "./theme";
import { Catalog_Database } from "./catalog";
import { Label } from "./catalogItem";
import Dinero from "dinero.js";
import {
  CustomWidgetTemplate,
  PageConfig,
  PageConfig_v1,
  PageRow,
} from "./page";
import { TextConfig } from "../components/widgets/textConfig";
import { IconConfig } from "../components/widgets/iconConfig";
import { OrderPageOptions, OrderTemplate } from "./orderPage";
import { ColorConfig } from "../components/widgets/colorConfig";

/**
 * Settings for a site. This is downloaded by the customer and controls the
 * appearance and behavior of the site.
 *
 * These are stored in /stores/store/locations/{locationId}
 */
export interface SiteSettings {
  _bootstrapComplete: boolean;
  _id: string;
  _name: string;
  /**
   * If true, indicates that the website is in maintenance mode and thus should
   * only show an information screen.
   */
  _maintenance?: boolean;
  address: PostalAddress & {
    googleMaps: string;
  };
  storeEmail?: string;
  features: {
    /**
     * If true, the catalog can be displayed to the customer
     */
    showCatalog: boolean;
    /**
     * If true, the customer can add items to the cart and place an order.
     */
    placeOrders: boolean;
    /**
     * If true, customers can create accounts
     */
    customerAccounts: boolean;
    /**
     * If true, customers can send messages via the website
     */
    customerMessages: boolean;
  };
  /**
   * Public phone number for the store
   */
  phone: PhoneNumber | null;
  /**
   * The phone number that will be used for sms messages
   */
  smsPhone: PhoneNumber | null;
  allowMeals: boolean;
  /**
   * Top level data about the items for sale. Includes the category names, and
   * the ids for their items
   */
  catalog: Catalog_Database;
  /**
   * A list of the labels available for searching
   */
  labels: { [labelId: string]: Label };
  communication: Communication;
  currency: CurrencyOptions;
  /**
   * The hours that the store is usually open
   */
  dailyHours: DailyHours;
  /**
   * Exceptions to the normal hours for holidays
   */
  holidayHours: HolidayHours;
  delivery: SiteDeliveryOptions;
  giftCards: boolean;
  invoiceCode: string;
  /**
   * minimum time in minutes needed to prepare an order
   */
  orderIncrement: number;
  /**
   * maximum time in the future that an order can be placed
   */
  orderMaximumTime: number;
  paymentGateway: paymentGateway;
  promos: any; // Not sure the shape of this is known yet.
  rewardsProgram: boolean;
  taxPercentage: number;
  taxRounding: TaxRounding;
  priceIncludesTax: boolean;
  theme: SiteTheme_v1;
  /**
   * This is only in a separate property during development. Eventually it
   * will replace the old theme.
   */
  theme2: SiteTheme_v2;
  /**
   * What links to display in the navigation menu
   * TODO: remove this once it's replaced by the new one
   */
  navigationMenu: NavigationMenu;
  /**
   * Details for rendering navigation
   */
  navigation: {
    rows: PageRow[];
  };
  /**
   * What pages exist on the site
   */
  pages: {
    pageId: string;
    name: string;
    icon?: string;
    path: string;
  }[];
  /**
   * Settings which apply to all pages
   */
  pageSettings: {
    maxWidth: "full" | "xlarge" | "large" | "medium" | "small";
    padding: "xlarge" | "large" | "medium" | "small" | "none";
  };
  customWidgets: {
    [templateId: string]: CustomWidgetTemplate;
  };
  /**
   * Preloaded home page config (so it doesn't need to be a separate read)
   */
  homePage: PageConfig;
  /**
   * Configuration for the home page
   */
  homePageOptions?: HomePageOptions;
  /**
   * Configuration for the order page
   */
  orderPageOptions: OrderPageOptions<OrderTemplate>;
  /**
   * Configuration for custom pages (pre-gridbuilder)
   */
  extraPageOptions: {
    pages: PageConfig_v1[];
  };
  urls: string[];
  /**
   * Where this site is hosted. For example, it might be a subpath of gridbash
   * like gridbash.com/tacohut, or it might be a custom url like www.tacohut.com.
   */
  hosting: HostInfo;
  /**
   * It's possible to host the site at multiple urls. If we didn't have this
   * capability, then it would be very painful for someone to decide to move
   * from hosting on gridbash to hosting on a custom url, because their old
   * url would suddenly break and they may lose existing customers.
   *
   * Note that when we construct a url (eg, to send a push message) we always
   * use the primary hosting.
   */
  secondaryHosting?: HostInfo[];
  /**
   * The type of payment methods to show when checking out
   */
  paymentMethods: AllowedPaymentMethods[];
  /**
   * Settings for customer tips
   */
  tips: {
    /**
     * If true, a tip field will be shown to customers
     */
    enabled: boolean;
    /**
     * Values to display as buttons, for quick entry of normal tips.
     * For example, there may be a button for 15%, 18%, 20%.
     *
     * A value of 15 equals a 15% tip
     */
    shortcutPercents: number[];
    /**
     * What number to preselect when the checkout page loads
     *
     * A value of 15 equals a 15% tip
     */
    defaultPercent: number;
    /**
     * Optionally, the admin can set a cutoff that if the order exceeds
     * a certain amount, then a tip must be applied
     */
    mandatoryTip: {
      enabled: boolean;
      /**
       * If the order costs at least this much, then the mandatory tip
       * kicks in.
       */
      cutoff: number;
      /**
       * If the cutoff is reached, then the tip cannot be lowered below
       * this percentage.
       *
       * A value of 15 equals a 15% tip
       */
      minimumPercent: number;
    };
  };
  vapidKey: string;
  functionRegion: string;
  /**
   * Stripe connected account id
   */
  stripeAccountId: string | null;
  xenditKey: string | null;
  googleFontsKey: string;
  paypalClientId?: string | null;
  /**
   * What type of receipt emails to use
   */
  receipt: {
    type: "stripe" | "gridbash";
    /** For custom receipts, whether to display the store's phone number */
    showPhone: boolean;
    /** For custom receipts, whether to display the store's address */
    showAddress: boolean;
    templateId: string;
  };
  /**
   * Whether charges are enabled. Derived from the stripe account
   * in the private site.
   */
  chargesEnabled: boolean;
}

export type AllowedPaymentMethods = "card" | "bancontact" | "paypal";

export type HostInfo = CustomHosting | GridbashHosting;

interface CustomHosting {
  type: "custom";
  /**
   * eg: "https:"
   */
  protocol: string;
  /**
   * eg: "www.tacohut.com"
   */
  host: string;
}

interface GridbashHosting {
  type: "gridbash";
  /**
   * eg: "tacohut", which corresponds to "gridbash.com/tacohut"
   */
  path: string;
}

export interface NavigationMenu {
  /**
   * Flag for toggling the feature, but we don't currently support
   * turning it off
   */
  enabled: boolean;
  backgroundOpacity: number;
  backgroundColor?: ColorConfig;
  color?: ColorConfig;
  accentColor?: ColorConfig;
  items: NavigationItem[];
}

interface BaseNavigationItem {
  type: string;
  /**
   * Unique identifier of this navigation item
   */
  navItemId: string;
}

/**
 * Breaks the menu into sections. No action when clicked
 */
// export interface Divider extends BaseNavigationItem {
//   type: "divider";
// }

/**
 * The most common menu item. Links to a page
 * */
export interface StandardNavigationItem extends BaseNavigationItem {
  type: "standard";
  /**
   * Which page this is linked to
   * */
  pageId: WellKnownPageId;
  /**
   * If true, the page is shown in a popup
   */
  popup?: boolean;
  iconSettings: IconConfig;
  textSettings: TextConfig;
}

export interface CustomNavigationItem extends BaseNavigationItem {
  type: "custom";
  /**
   * Which page this is linked to
   * */
  pageId: string;
  /**
   * If true, the page is shown in a popup
   */
  popup?: boolean;
  iconSettings: IconConfig;
  textSettings: TextConfig;
}

/**
 * These ids are used to point to unique predefined pages, as opposed to the
 * custom ones the admin can create for themselves.
 */
export enum WellKnownPageId {
  home = "home",
  order = "order",
  track = "track",
  favorites = "favorites",
  account = "account",
  message = "message",
}

/**
 * Special menu item for logging in and out
 */
// export interface LoginNavigationItem extends BaseNavigationItem {
//   type: "login";
//   /**
//    * What to display if the user is logged in
//    */
//   loggedOut: {
//     iconSettings: IconConfig;
//     textSettings: TextConfig;
//   };
//   /**
//    * What to display if the user is logged out
//    */
//   loggedIn: {
//     iconSettings: IconConfig;
//     textSettings: TextConfig;
//   };
// }

export type NavigationItem = StandardNavigationItem | CustomNavigationItem;
// | Divider
// | LoginNavigationItem;

export interface HomePageOptions {
  mainButton?: TextOptions;
}

export type TaxRounding = "up" | "down" | "round" | "none";

export enum paymentGateway {
  xendit = "xendit",
  stripe = "stripe",
  paypal = "paypal",
}

export interface Communication {
  /**
   * Which methods of communication the user can opt into for notifications
   * about their order.
   */
  orders: {
    useEmail: boolean;
    useSMS: boolean;
    usePush: boolean;
  };
  /**
   * Which methods of communication the user can opt into for notifications
   * about their messages
   */
  messages: {
    useEmail: boolean;
    useSMS: boolean;
    usePush: boolean;
  };
}

/**
 * @deprecated
 */
export interface OldLocationAddress {
  city: string;
  country: string;
  googleMaps: string;
  phone: string;
  state: string;
  street: string;
  suite: string;
  zip: string;
}

export interface PostalAddress {
  city: string;
  countryCode: string;
  /** state is for US only */
  stateCode?: string;
  streetLine1: string;
  streetLine2?: string;
  streetLine3?: string;
  /**
   * Zipcode in US, postal code in other countries
   */
  zip: string;
}

export interface PhoneNumber {
  /**
   * The basic number with no formatting or punctuation.
   *
   * eg: 17201234567
   */
  raw: string;
  /**
   * The characters from the raw number which form the country code
   *
   * eg: 1
   */
  countryCode: string;
  /**
   * The phone number formatted for display
   *
   * eg: +1 (720) 123-4567
   */
  formatted: string;
}

export interface CurrencyOptions {
  currency: Dinero.Currency;
  roundingMode?: Dinero.RoundingMode;
  precision?: number;
  format?: string;
  locale: string;
}

export const defaultRoundingMode: Dinero.RoundingMode = "HALF_AWAY_FROM_ZERO";

export interface TimeRange {
  from: string;
  to: string;
}

// 0 is Sunday, 6 is Saturday
export type DayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type DailyHours = Record<DayIndex, TimeRange[]>;

export interface HolidayHours {
  [key: string]: TimeRange[] | undefined;
}

export interface SiteDeliveryOptions {
  /**
   * boolean version is deprecated
   */
  delivery:
    | boolean
    | {
        enabled: boolean;
        flatPrice?: number;
        percentPrice?: number;
      };
  shipping?: {
    enabled: boolean;
    provider: "dhl";
    /**
     * If the order is this amount or higher, shipping is free to the
     * customer (ie, paid for by the store)
     */
    freeThreshold?: number;
  };
  international: boolean;
  pickup: boolean;
}
