import { memo, Fragment } from "react";

/**
 * Just does a react.memo to halt any rerenders. This lets us have
 * global components like the global popover which are accessible
 * via context anywhere in the app, but don't force the entire
 * component tree to rerender.
 */
const RenderBlocker = memo(({ children }) => {
  return <Fragment>{children}</Fragment>;
});

export default RenderBlocker;
