import { Button, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import getSymbolFromCurrency from "currency-symbol-map";
import { FC, useMemo, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import NumberInput from "../../Dashboard/Components/numberInput";

interface TipSelectProps {
  tipPercent: number;
  setTipPercent: React.Dispatch<React.SetStateAction<number>>;
  priceBeforeTax: Dinero.Dinero;
  builder: boolean;
}

const TipSelect: FC<TipSelectProps> = ({
  tipPercent,
  setTipPercent,
  priceBeforeTax,
  builder,
}) => {
  const { t } = useTranslation();
  const { tips, currency } = useSiteSettings();

  // If there are no quick tip buttons, show the number input immediately
  const [showOther, setShowOther] = useState(
    tips.shortcutPercents.length === 0
  );

  const sortedPercents = useMemo(() => {
    return tips.shortcutPercents.sort((a, b) => a - b);
  }, [tips.shortcutPercents]);

  return (
    <React.Fragment>
      <Tooltip title={!builder ? "" : `${t("dashboard.builder.primaryDark")}`}>
        <Button
          size="large"
          startIcon={<Icon color="inherit">volunteer_activism</Icon>}
        >
          {t("store.orders.gratuity")}
        </Button>
      </Tooltip>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "8px",
        }}
      >
        <React.Fragment>
          <div style={{ marginBottom: "max(0.75vw, 12px)" }}>
            <Tooltip
              title={!builder ? "" : `${t("dashboard.builder.textPrimary")}`}
            >
              <Typography color="textPrimary">
                {Math.round(Math.max(0, tipPercent) * 10) / 10}% (
                {priceBeforeTax
                  .multiply(Math.max(0, tipPercent / 100))
                  .toFormat()}
                )
              </Typography>
            </Tooltip>
          </div>
          <Tooltip
            title={
              !builder
                ? ""
                : `${t("dashboard.builder.primaryDark")} - ${t(
                    "dashboard.builder.accent"
                  )} - ${t("dashboard.builder.onAccent")} - 
                  ${t("dashboard.builder.textSecondary")}
                  `
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {sortedPercents.map((value) => (
                <Button
                  variant={tipPercent === value ? "contained" : "outlined"}
                  color={tipPercent === value ? "secondary" : undefined}
                  key={value}
                  style={{ margin: "8px 0.5em" }}
                  onClick={() => setTipPercent(value)}
                >
                  {value}%
                </Button>
              ))}
              {showOther && (
                <div
                  className="anim_fadeIn_0503"
                  style={{ margin: "max(1vw, 1em) 0.5vw", opacity: 0 }}
                >
                  <NumberInput
                    value={priceBeforeTax
                      .multiply(Math.max(0, tipPercent / 100))
                      .toUnit()}
                    startAdornment={
                      <InputAdornment position="start">
                        {getSymbolFromCurrency(currency.currency)}
                      </InputAdornment>
                    }
                    onChange={(e, newValue) => {
                      setTipPercent(100 * (newValue / priceBeforeTax.toUnit()));
                    }}
                    onBlur={() => setTipPercent((prev) => Math.max(0, prev))}
                  />
                </div>
              )}
              {!showOther && (
                <Button
                  variant="outlined"
                  style={{ margin: "0 0.5em" }}
                  onClick={() => setShowOther(true)}
                >
                  {t("store.checkout.otherTip")}
                </Button>
              )}
            </div>
          </Tooltip>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default TipSelect;
