import { useState, useEffect, FC, useRef } from "react";
import * as React from "react";
import "../css/sTtrack.css";
import ROUTES from "../../../utilities/constants/routes";
import useSiteUser from "../../UserProvider/useSiteUser";
import { orderStatus } from "../../../database/order";
import { useTranslation } from "react-i18next";
import SectionSignedOut from "../Components/sectionSignedOut";
import {
  Button,
  Container,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { history } from "../../App/history";
import DialogActions from "@material-ui/core/DialogActions";
import { useQuery } from "../../../utilities/useQuery";
import ItemTrackCard from "./itemTrack";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import QueueIcon from "@material-ui/icons/ViewColumn";
import InProgressIcon from "@material-ui/icons/Update";
import DoneIcon from "@material-ui/icons/Done";
import DriveIcon from "@material-ui/icons/DriveEta";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PauseIcon from "@material-ui/icons/Pause";
import WarningIcon from "@material-ui/icons/Warning";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import { useFetchTrack } from "./useFetchTrack";
import RightArrowIcon from "@material-ui/icons/ArrowRight";
import LeftArrowIcon from "@material-ui/icons/ArrowLeft";
import RestoreIcon from "@material-ui/icons/Restore";
import UpdateIcon from "@material-ui/icons/Update";

export const trackingStatusIcons: Record<
  orderStatus,
  OverridableComponent<SvgIconTypeMap<{}, "svg">>
> = {
  [orderStatus.inprogress]: InProgressIcon,
  [orderStatus.inqueue]: QueueIcon,
  [orderStatus.intransit]: DriveIcon,
  [orderStatus.delivered]: DoneIcon,
  [orderStatus.ready]: DoneAllIcon,
  [orderStatus.pending]: PauseIcon,
  [orderStatus.failed]: WarningIcon,
};

export const trackingStatusStrings: Record<orderStatus, string> = {
  [orderStatus.inprogress]: "store.track.inKitchenStatus",
  [orderStatus.inqueue]: "store.track.inQueueStatus",
  [orderStatus.intransit]: "store.track.inTransitStatus",
  [orderStatus.delivered]: "store.track.deliveredStatus",
  [orderStatus.ready]: "store.track.readyStatus",
  // Deliberately reusing the inQueueStatus string for pending. Pending orders
  //   only show up when the webhook is being slow, and if that happens we want
  //   to give the illusion it is in queue, since that will be true momentarily
  [orderStatus.pending]: "store.track.inQueueStatus",
  [orderStatus.failed]: "store.track.failedStatus",
};

interface TrackProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Track: FC<TrackProps> = React.memo(({ setMainBgImage }) => {
  const { t } = useTranslation();
  const { user } = useSiteUser();
  const query = useQuery();

  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    if (user) {
      const id = setTimeout(() => {
        setSignedIn(true);
        //WE NEED A DELAY FOR THE SIGN IN MODAL TO UNMOUNT
        //AND NOT CAUSE IT TO JUMP
      });
      return () => clearTimeout(id);
    } else {
      setSignedIn(false);
    }
  }, [user]);

  const [justPaid] = useState(() => query.get("success") === "true");
  const [sort, setSort] = useState<"asc" | "desc">("desc");

  const {
    orders,
    loading,
    hasMoreResults,
    page,
    totalPages,
    nextPage,
    prevPage,
  } = useFetchTrack({
    includePending: justPaid,
    sort,
  });

  // Lock in the value at the time the page loads
  const successRedirectHandled = useRef(false);
  useEffect(() => {
    if (
      justPaid &&
      orders &&
      orders.length > 0 &&
      !successRedirectHandled.current
    ) {
      // The first time we get data after returning from successful checkout,
      //   redirect them to the details page for their new order.
      const path = `${ROUTES.TRACK}/${orders[0].orderId}`;
      if (!window.location.pathname.includes(path)) {
        history.push(path);
      }
      successRedirectHandled.current = true;
    }
  }, [justPaid, orders, t]);

  const [showItem, setShowItem] = useState(true);

  useEffect(() => {
    setShowItem(!loading);
  }, [loading]);

  return (
    <React.Fragment>
      <MUIDialog route={`${ROUTES.TRACK}/paid`}>
        <DialogTitle className="smartWidthMd">
          {t("store.orders.thankYou")}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => history.goBack()}>{t("store.close")}</Button>
        </DialogActions>
      </MUIDialog>
      <Container maxWidth="sm" style={{ padding: "max(1.5vw, 1.5em) 1em" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography color="textPrimary" variant="h6">
            {t("store.orders.myOrders")}
          </Typography>
          &nbsp;&nbsp;
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setShowItem(false);
              setSort(sort === "asc" ? "desc" : "asc");
            }}
          >
            {sort === "asc" ? <UpdateIcon /> : <RestoreIcon />}
          </IconButton>
        </div>

        <div style={{ marginTop: "max(1vw, 1em)" }} />
        {signedIn ? (
          showItem ? (
            <React.Fragment>
              {orders.map((order) => (
                <ItemTrackCard key={order.orderId} order={order} />
              ))}
              {(page > 0 || hasMoreResults) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={page === 0 || loading}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (page > 0 && !loading) {
                        prevPage();
                      }
                    }}
                  >
                    <LeftArrowIcon />
                  </IconButton>
                  <Typography>
                    {totalPages !== undefined
                      ? t("common.pageXofY", {
                          count: page + 1,
                          totalPages: totalPages,
                        })
                      : t("common.pageX", { count: page + 1 })}
                  </Typography>
                  <IconButton
                    disabled={!hasMoreResults || loading}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (hasMoreResults && !loading) {
                        nextPage();
                      }
                    }}
                  >
                    <RightArrowIcon />
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div>Loading...</div>
          )
        ) : (
          <div
            className="anim_fadeIn_0503 sTsignedOutSection"
            style={{ opacity: 0, marginBottom: "1em" }}
          >
            <SectionSignedOut />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
});

export default Track;
