import {
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Fragment, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGridBashFirebase } from "../../../Firebase/context";
import {
  INPUT_MAX_EMAIL,
  INPUT_MAX_LENGTH,
} from "../../../utilities/constants/appConstants";
import { useStateWithSessionStorage } from "../../../utilities/useStateWithStorage";
import { validateEmail } from "../../../utilities/validateEmailPassPhone";
import { history } from "../../App/history";
import useToast from "../../Main/useToast";
import useSpinner from "../../Spinner/useSpinner";
import type firebase from "firebase";
import { CustomerInquiry } from "../../../database/signup";

interface ContactProps {
  user: firebase.User | null;
}

const Contact: FC<ContactProps> = ({ user }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const gridbashFirebase = useGridBashFirebase();
  const showSpinner = useSpinner();

  const [email, setEmail, clearEmail] = useStateWithSessionStorage({
    key: "gb.contact.email",
    defaultValue: user?.email ?? "",
  });
  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [setEmail, user?.email]);

  const [msg, setMsg, clearMsg] = useStateWithSessionStorage({
    key: "gb.contact.msg",
    defaultValue: "",
  });

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      toast({
        color: "error",
        dialog: true,
        message: t("gridbash.home.provideEmail"),
      });
    } else if (msg.length < 5) {
      toast({
        color: "error",
        dialog: true,
        message: t("gridbash.home.moreDetails"),
      });
    } else {
      const hideSpinner = showSpinner({ lag: "none" });
      try {
        const inquiry: CustomerInquiry = {
          timestamp: Date.now(),
          email,
          userId: user?.uid ?? null,
          message: msg,
        };
        await gridbashFirebase.firestore
          .collection("customerInquiries")
          .doc()
          .set(inquiry);
        clearEmail();
        clearMsg();
        history.goBack();
        toast({
          message: t("gridbash.home.messageSent"),
        });
      } catch (error) {
        toast({
          color: "normal",
          dialog: true,
          message: t("gridbash.home.emailDirectly"),
          onUnmount: () => {
            clearEmail();
            clearMsg();
            history.goBack();
          },
        });

        console.error("error sending customer inquiry");
      } finally {
        hideSpinner();
      }
    }
  };

  return (
    <Fragment>
      <DialogTitle className="smartWidthMd">
        <Fragment></Fragment>
        {t("gridbash.home.howCanWeHelp")}
        <Typography variant="body2" color="textSecondary">
          {t("gridbash.home.messageReply")}
        </Typography>
      </DialogTitle>
      <DialogContent className="smartWidthMd">
        <FormControl fullWidth>
          <TextField
            required
            fullWidth
            type="email"
            label={t("gridbash.home.email")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.substring(0, INPUT_MAX_EMAIL));
            }}
            onBlur={(e) => {
              setEmail(e.target.value.trim().substring(0, INPUT_MAX_EMAIL));
            }}
          />

          <div style={{ marginTop: "1em" }} />
          <TextField
            fullWidth
            variant="filled"
            label="Enter message"
            multiline
            rows={4}
            value={msg}
            onChange={(e) => {
              setMsg(e.target.value.substring(0, INPUT_MAX_LENGTH));
            }}
            onBlur={(e) => {
              setMsg(e.target.value.trim().substring(0, INPUT_MAX_LENGTH));
            }}
          />
          <Typography variant="caption">
            {t("common.charactersLeft", {
              count: INPUT_MAX_LENGTH - msg.trim().length,
            })}
          </Typography>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          {t("gridbash.close")}
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("gridbash.submit")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Contact;
