import * as R from "ramda";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { CatalogItem_Database } from "../../../database/catalogItem";
import { useSiteFirebase } from "../../../Firebase/context";
import { storeKey } from "../../../Firebase/siteFirebase";
import { notUndefined } from "../../../utilities/notNull";
import { convertItemAndAddDefaults } from "../../../utilities/typeConversions";
import { useUnmount } from "../../../utilities/useUnmount";

const CHUNK_SIZE = 10;

/**
 * Fetches favorites from the database, excluding items that are not visible
 * to the customer.
 */
export const useFetchFavorites = (itemIds: string[]) => {
  const { labels, taxPercentage, priceIncludesTax, currency } =
    useSiteSettings();
  const firebase = useSiteFirebase();
  const subscriptions = useRef<{ [itemId: string]: () => void }>({});
  const [items, setItems] = useState<{
    [itemId: string]: CatalogItem_Database;
  }>({});

  useEffect(() => {
    // Find out which item ids we are not yet listening to (if any)
    const newIds = itemIds.filter((id) => !subscriptions.current[id]);

    // Firestore "in" queries are limited to 10 values each, so we group the items
    //   into chunks of that size.
    const chunks = R.splitEvery(CHUNK_SIZE, newIds);
    chunks.forEach((chunk) => {
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("items")
        .where("hidden", "==", false)
        .where("inActiveCatalog", "==", true)
        .where("itemId", "in", chunk)
        .onSnapshot(
          (snapshot) => {
            setItems((prev) => {
              const newItems = { ...prev };
              snapshot.docs.forEach((doc) => {
                const data = doc.data() as CatalogItem_Database | undefined;
                if (data) {
                  newItems[data.itemId] = data;
                } else {
                  delete newItems[doc.id];
                }
              });
              return newItems;
            });
          },
          (err) => {
            console.error("error fetching item", err);
          }
        );

      chunk.forEach((itemId) => {
        subscriptions.current[itemId] = unsubscribe;
      });
    });
  }, [firebase.firestore, itemIds]);

  useUnmount(() => {
    for (const id in subscriptions.current) {
      subscriptions.current[id]();
    }
  });

  // Fill in default values to make it easier to work with
  const itemsWithDefaults = useMemo(() => {
    return itemIds
      .map((id) => items[id])
      .filter(notUndefined)
      .map((item) =>
        convertItemAndAddDefaults(
          item,
          labels,
          taxPercentage,
          priceIncludesTax,
          currency
        )
      );
  }, [currency, itemIds, items, labels, priceIncludesTax, taxPercentage]);

  return itemsWithDefaults;
};
