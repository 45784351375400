import {
  Button,
  Checkbox,
  Container,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { lazy, Fragment, FC, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { PhoneNumber } from "../../../database/siteSettings";
import { useSiteFirebase } from "../../../Firebase/context";
import { history } from "../../App/history";
import MUIPopover from "../../Dashboard/Components/mui-popover";
import DoneIcon from "@material-ui/icons/Done";
import { useLocation } from "react-router-dom";
import { phoneIsBlocked } from "../../../utilities/httpsCallables/httpsCallables";
import useToast from "../../Main/useToast";

const PhoneInput = lazy(() => import("./phoneInput/phoneInput"));

interface GuestCommSettingsProps {
  email: string;
  phone: PhoneNumber | null;
  onPhoneChanged: (value: PhoneNumber | null) => void;
  pushToken: string | null;
  onPushTokenChanged: (value: string | null) => void;
  showEmail?: boolean;
  communicationType: "orders" | "messages";
}

const GuestCommSettings: FC<GuestCommSettingsProps> = ({
  email,
  phone,
  onPhoneChanged,
  pushToken,
  onPushTokenChanged,
  showEmail = true,
  communicationType,
}) => {
  const firebase = useSiteFirebase();
  const { t } = useTranslation();
  const location = useLocation<{}>();
  const siteSettings = useSiteSettings();
  const toast = useToast();
  const [anchor, setAnchor] = useState<Element>();

  const userWantsSms = Boolean(phone);
  const userWantsPush = Boolean(pushToken);

  const [smsBlocked, setSmsBlocked] = useState(false);
  const rawPhone = phone?.raw;
  useEffect(() => {
    setSmsBlocked(false);
    if (!rawPhone) {
      return;
    }
    let unmounted = false;
    (async () => {
      const blocked = await phoneIsBlocked(firebase, { phone: rawPhone });
      if (!unmounted) {
        setSmsBlocked(blocked);
      }
      if (blocked) {
        toast({
          color: "error",
          message: siteSettings.smsPhone
            ? t(`dashboard.account.smsOptedOutNumber`, {
                phoneNumber: siteSettings.smsPhone.formatted,
              })
            : t(`dashboard.account.smsOptedOutReply`),
        });
      }
    })();

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawPhone]);

  const pushBlocked =
    !firebase.messaging ||
    !firebase.pushSupported ||
    Notification.permission === "denied";

  return (
    <Fragment>
      {anchor && (
        <MUIPopover
          anchorEl={anchor}
          route=""
          stateMatch={(state) => Boolean(state.guestPhone)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <PhonePopover
            defaultCountryCode={siteSettings.phone?.countryCode}
            initialValue={phone}
            onSubmit={onPhoneChanged}
          />
        </MUIPopover>
      )}
      <DialogContent className={showEmail ? "smartWidthMd" : undefined}>
        {showEmail && (
          <Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="textSecondary">
                {t("store.orders.byEmail")}
              </Typography>
              &nbsp;
              {email && <DoneIcon fontSize="small" color="secondary" />}
            </div>

            {email ? (
              <Typography color="textPrimary">{email}</Typography>
            ) : (
              <Typography variant="caption" color="secondary">
                {t("store.account.provideEmail")}
              </Typography>
            )}
          </Fragment>
        )}

        <div style={{ marginTop: "max(1em, 1vw)" }} />
        {siteSettings.communication[communicationType].useSMS && showEmail && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color="textSecondary">
              {t("store.orders.bySMS")}
            </Typography>
            &nbsp;
            {userWantsSms && <DoneIcon fontSize="small" color="secondary" />}
          </div>
        )}
        {siteSettings.communication[communicationType].useSMS && (
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userWantsSms}
                  onChange={(e, newValue) => {
                    e.stopPropagation();
                    if (newValue) {
                      history.push(location.pathname, {
                        ...location.state,
                        guestPhone: true,
                      });
                      setAnchor(e.currentTarget);
                    } else {
                      onPhoneChanged(null);
                    }
                  }}
                />
              }
              label={t(`dashboard.account.smsNotifications`)}
            />
            {smsBlocked && (
              <Typography variant="caption" component="div" color="secondary">
                {siteSettings.smsPhone
                  ? t(`dashboard.account.smsOptedOutNumber`, {
                      phoneNumber: siteSettings.smsPhone.formatted,
                    })
                  : t(`dashboard.account.smsOptedOutReply`)}
              </Typography>
            )}
            {phone && (
              <Button
                style={{ marginBottom: "0.5em" }}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(location.pathname, {
                    ...location.state,
                    guestPhone: true,
                  });
                  setAnchor(e.currentTarget);
                }}
              >
                {phone.formatted}
              </Button>
            )}
          </FormControl>
        )}

        {siteSettings.communication[communicationType].usePush && (
          <div style={{ marginBottom: "1em" }}>
            <div style={{ marginTop: "max(1em, 1vw)" }} />
            {showEmail && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" color="textSecondary">
                  {t("store.orders.byPush")}
                </Typography>
                &nbsp;
                {userWantsPush && !pushBlocked && (
                  <DoneIcon fontSize="small" color="secondary" />
                )}
              </div>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  disabled={pushBlocked}
                  checked={userWantsPush && !pushBlocked}
                  onChange={async (e, checked) => {
                    if (!firebase.messaging || !firebase.pushSupported) {
                      return;
                    }
                    if (checked) {
                      const permission = await Notification.requestPermission();
                      if (permission !== "granted") {
                        return;
                      }
                      const token = await firebase.messaging.getToken();
                      onPushTokenChanged(token);
                    } else {
                      onPushTokenChanged(null);
                    }
                  }}
                />
              }
              label={t(`dashboard.account.pushNotifications`)}
            />
            {pushBlocked && (
              <Typography variant="caption" component="div" color="secondary">
                {t(`dashboard.account.pushDisabledByBrowser`)}
              </Typography>
            )}
          </div>
        )}
      </DialogContent>
    </Fragment>
  );
};

const PhonePopover = ({
  initialValue,
  onSubmit,
  defaultCountryCode,
}: {
  initialValue: PhoneNumber | null;
  onSubmit: (newValue: PhoneNumber | null) => void;
  defaultCountryCode: string | undefined;
}) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState(initialValue);

  return (
    <Container maxWidth="md">
      <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Suspense fallback={null}>
          <PhoneInput
            siteCountryCode={defaultCountryCode}
            value={phone}
            onChange={setPhone}
            className="smartWidthMd"
          />
        </Suspense>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("store.cancel")}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onSubmit(phone);
            history.goBack();
          }}
        >
          {t("store.submit")}
        </Button>
      </DialogActions>
    </Container>
  );
};

export default GuestCommSettings;
