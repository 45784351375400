// Adapted from https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts

export const stripeMinimumCharges: { [key: string]: number | undefined } = {
  USD: 50,
  AED: 200,
  AUD: 50,
  BGN: 100,
  BRL: 50,
  CAD: 50,
  CHF: 50,
  CZK: 1500,
  DKK: 250,
  EUR: 50,
  GBP: 30,
  HKD: 400,
  HUF: 17500,
  INR: 50,
  JPY: 50,
  MXN: 10,
  MYR: 2,
  NOK: 300,
  MZD: 50,
  PLN: 200,
  RON: 200,
  SEK: 300,
  SGD: 50,
};
