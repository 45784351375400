import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Fragment, FC } from "react";
import { history } from "../../../App/history";

interface PaymentFeesProps {}

const PaymentFees: FC<PaymentFeesProps> = () => {
  return (
    <Fragment>
      <DialogContent className="smartWidthMd">
        <Typography>
          Generally, there are three parties involved in credit card processing,
          and the resulting fees: the card issuer, the card network, and the
          payments processor.
        </Typography>
        <br />
        <Typography>
          Many factors determine payment processing fees. Some of these are
          PCI-compliance fees, merchant fees, interchange fees, assessment (or
          service) fees, annual account fees, and chargeback fees.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default PaymentFees;
