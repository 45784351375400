import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import { FormHelperText } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { AccountInfo } from "../../Main/useMain";
import CommCheckbox from "../../Dashboard/Account/commCheckbox";

interface AccountNotificationsProps {
  initPath: string;
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
}

const AccountNotifications: FC<AccountNotificationsProps> = React.memo(
  ({ initPath, accountInfo, setOptimisticUpdate }) => {
    const { t } = useTranslation();

    return (
      <React.Fragment>
        <DialogContent className="smartWidthMd">
          <CommCheckbox
            accountInfo={accountInfo}
            setOptimisticUpdate={setOptimisticUpdate}
            category="customer"
            commType="email"
          />
          <FormHelperText>{t("store.account.receiveEmail")}</FormHelperText>
          <br />
          <CommCheckbox
            accountInfo={accountInfo}
            setOptimisticUpdate={setOptimisticUpdate}
            category="customer"
            commType="push"
          />
          <FormHelperText>{t("store.account.receivePush")}</FormHelperText>
          <br />
          <CommCheckbox
            accountInfo={accountInfo}
            setOptimisticUpdate={setOptimisticUpdate}
            category="customer"
            commType="sms"
          />
          <FormHelperText>{t("store.account.receiveSms")}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.goBack()}>{t("store.close")}</Button>
        </DialogActions>
      </React.Fragment>
    );
  }
);

export default AccountNotifications;
