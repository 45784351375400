import { Fragment, FC } from "react";
import STauthModal from "../Modals/sTauth";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/HowToReg";
import CardMemberIcon from "@material-ui/icons/CardMembership";
import CheckedIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import { history } from "../../App/history";
import { useLocation } from "react-router-dom";

export interface SectionSOProps {}

const SectionSO: FC<SectionSOProps> = () => {
  const { t } = useTranslation();
  const location = useLocation<{}>();

  return (
    <Fragment>
      <div style={{ marginTop: "max(1em, 1vw)" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton>
            <CardMemberIcon fontSize="large" />
          </IconButton>
          &nbsp;&nbsp;
          <Typography color="textPrimary" variant="h5" align="center">
            {t("store.checkout.signInPrompt")}
          </Typography>
        </div>
        <div
          style={{
            display: "inline-block",
            marginTop: "max(1em, 1vw)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography color="textPrimary" variant="body2" align="left">
              {t("store.checkout.signInBenefitTrack")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography color="textPrimary" variant="body2" align="left">
              {t("store.checkout.signInBenefitPromotions")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography color="textPrimary" variant="body2" align="left">
              {t("store.checkout.signInBenefitChat")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography color="textPrimary" variant="body2" align="left">
              {t("store.checkout.signInBenefitFavorites")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography color="textPrimary" variant="body2" align="left">
              {t("store.checkout.signInBenefitsTransactions")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography color="textPrimary" variant="body2" align="left">
              {t("store.checkout.signInBenefitsSettings")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography color="textPrimary" variant="body2" align="left">
              {t("store.checkout.signInBenefitsMore")}
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ margin: "max(1em, 1vw) 0 0 1em" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PersonIcon />}
          onClick={(e) => {
            e.stopPropagation();
            history.push(location.pathname, {
              ...location.state,
              signInSection: true,
            });
          }}
        >
          {t("store.orders.yestobenefits")}
        </Button>
        <MUIDialog
          route=""
          stateMatch={(state) => Boolean(state.signInSection)}
        >
          <STauthModal />
        </MUIDialog>
      </div>
    </Fragment>
  );
};

export default SectionSO;
