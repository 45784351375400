import { ListItem, ListItemText } from "@material-ui/core";
import { Fragment, FC } from "react";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import InputIcon from "@material-ui/icons/Input";
import DevicesIcon from "@material-ui/icons/Devices";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ImageIcon from "@material-ui/icons/Image";

interface StoreUIProps {}

const StoreUI: FC<StoreUIProps> = () => {
  const grid: JSX.Element[] = [];
  for (let x = 0; x < 9; x++) {
    grid.push(gridItem);
  }
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "25% 75%",
        gridTemplateAreas: `
"ControlBar AppBar"
"Controls Preview"`,
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        borderRight: "1px solid lightgray",
        marginRight: "10px",
        marginLeft: "10px",
        maxHeight: "271px",
        overflow: "hidden",
        borderRadius: "5px",
      }}
    >
      <div style={{ gridArea: "ControlBar", border: "1px solid lightgray" }}>
        <ListItem style={{ padding: "0.18em 0.25em" }}>
          <ListItemText
            style={{ margin: 0 }}
            primary="Orders"
            secondary="Page"
            primaryTypographyProps={{
              style: { fontSize: "0.6em", margin: 0 },
              noWrap: true,
            }}
            secondaryTypographyProps={{
              style: { fontSize: "0.5em", margin: 0 },
              noWrap: true,
            }}
          />
          <ArrowDownIcon style={{ fontSize: "0.8em" }} />
        </ListItem>
      </div>
      <div
        style={{
          gridArea: "AppBar",
          backgroundColor: "#0c343d",
          color: "white",
          display: "flex",
          alignItems: "center",
          padding: "0.5em",
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <InputIcon style={{ fontSize: "0.8em" }} />
        &nbsp;&nbsp;
        <DevicesIcon style={{ fontSize: "0.8em" }} />
      </div>
      <div style={{ gridArea: "Controls", borderRight: "1px solid lightgray" }}>
        <div
          style={{
            minHeight: 0,
            maxHeight: "2em",
            display: "flex",
            justifyContent: "flex-start",
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.25em",
              width: "100%",
              backgroundColor: "#e7e5e6",
            }}
          >
            <Typography noWrap style={{ fontSize: "0.7em" }}>
              Template
            </Typography>

            <ExpandMoreIcon style={{ fontSize: "0.8em" }} />
          </div>
        </div>
        <div style={{ marginTop: "1em" }} />
        <div
          style={{
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%",
          }}
        >
          <div style={{ backgroundColor: "#e7e5e6", padding: "0.25em" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography noWrap style={{ fontSize: "0.7em" }}>
                Grid
              </Typography>

              <ExpandLessIcon style={{ fontSize: "0.8em" }} />
            </div>
            <div>
              <Typography
                align="left"
                noWrap
                style={{ fontSize: "0.5em" }}
                color="textSecondary"
              >
                Breakpoint Sm
              </Typography>
              <div
                style={{
                  margin: "0.5em 0",
                  position: "relative",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-1px",
                    width: "25%",
                    border: "1px solid black",
                  }}
                />
              </div>
            </div>
            <div>
              <Typography
                align="left"
                noWrap
                style={{ fontSize: "0.5em" }}
                color="textSecondary"
              >
                Breakpoint Md
              </Typography>
              <div
                style={{
                  margin: "0.5em 0",
                  position: "relative",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-1px",
                    width: "50%",
                    border: "1px solid black",
                  }}
                />
              </div>
            </div>
            <div>
              <Typography
                align="left"
                noWrap
                style={{ fontSize: "0.5em" }}
                color="textSecondary"
              >
                Breakpoint Lg
              </Typography>
              <div
                style={{
                  margin: "0.5em 0",
                  position: "relative",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-1px",
                    width: "75%",
                    border: "1px solid black",
                  }}
                />
              </div>
            </div>
            <div>
              <Typography
                align="left"
                noWrap
                style={{ fontSize: "0.5em" }}
                color="textSecondary"
              >
                Columns Sm
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "0 0.5em",
                  borderBottom: "1px solid lightgray",
                }}
              >
                <Typography align="left" style={{ fontSize: "0.6em" }}>
                  2
                </Typography>
                <ArrowDownIcon style={{ fontSize: "0.8em" }} />
              </div>
            </div>
            <div style={{ marginTop: "0.5em" }}>
              <Typography
                align="left"
                noWrap
                style={{ fontSize: "0.5em" }}
                color="textSecondary"
              >
                Columns Md
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "0 0.5em",
                  borderBottom: "1px solid lightgray",
                }}
              >
                <Typography align="left" style={{ fontSize: "0.6em" }}>
                  3
                </Typography>
                <ArrowDownIcon style={{ fontSize: "0.8em" }} />
              </div>
              <div style={{ marginTop: "1em" }} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ gridArea: "Preview" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,minmax(25%, 1fr))",
            gridTemplateRows: "60px",
            gap: "8px",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "0.5em",
            height: "100%",
          }}
        >
          {grid.map((gridItem, index) => {
            return <Fragment key={index}>{gridItem}</Fragment>;
          })}
        </div>
      </div>
    </div>
  );
};

const gridItem = (
  <div
    style={{
      boxShadow:
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
      textAlign: "center",
      position: "relative",
    }}
  >
    <div
      style={{
        border: "3px solid lightgray",
        width: "75%",
        margin: "3px auto",
      }}
    />
    <div
      style={{
        border: "1px solid lightgray",
        width: "50%",
        margin: "1px auto",
      }}
    />
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-30%)",
      }}
    >
      <ImageIcon style={{ fontSize: "1em", opacity: "0.5" }} />
    </div>
    <div
      style={{
        position: "absolute",
        border: "2px solid lightgray",
        bottom: "0.25em",
        left: "0.25em",
        width: "1em",
      }}
    ></div>
    <div
      style={{
        position: "absolute",
        border: "2px solid lightgray",
        bottom: "0.25em",
        right: "0.25em",
        width: "1em",
      }}
    ></div>
  </div>
);

export default StoreUI;
