import { Fragment, FC, useState, useEffect, useMemo } from "react";
import useCartApi from "../CartManager/useCartApi";
import { getTotalsForItems } from "../../utilities/orderProcessing";
import ROUTES from "../../utilities/constants/routes";
import { useSiteSettings } from "../../customization/siteSettingsContext";
import { IconButton, Typography } from "@material-ui/core";
import CheckoutIcon from "@material-ui/icons/CreditCard";
import CartIcon from "@material-ui/icons/ShoppingCart";
import { history } from "../App/history";
import { useLocation } from "react-router-dom";
import { useThemeColor } from "../widgets/useThemeColor";
import { stripeMinimumCharges } from "../Store/Checkout/stripeMinimumCharges";

export interface CartIndicatorProps {
  useAccentColor?: boolean;
}

const CartIndicator: FC<CartIndicatorProps> = ({ useAccentColor = false }) => {
  const { cartItems } = useCartApi();
  const siteSettings = useSiteSettings();
  const themeColor = useThemeColor();
  const location = useLocation();
  const { navigationMenu } = siteSettings;
  const minimumCharge =
    stripeMinimumCharges[siteSettings.currency.currency] || 0;
  const cartTotals = useMemo(() => getTotalsForItems(cartItems), [cartItems]);
  const [cartChange, setCartChange] = useState<number>(0);
  useEffect(() => {
    setCartChange((cartChange) => cartChange + 1);
  }, [cartTotals]);

  return cartTotals.itemCount === 0 ? null : (
    <IconButton
      disabled={
        location.pathname.startsWith(ROUTES.CART) &&
        cartTotals.total.getAmount() < minimumCharge
      }
      onClick={() => {
        if (!location.pathname.startsWith(ROUTES.CART)) {
          history.push(ROUTES.CART);
        } else if (
          cartTotals.itemCount !== 0 &&
          cartTotals.total.getAmount() >= minimumCharge
        ) {
          history.push(ROUTES.CHECKOUT);
        }
      }}
      color={
        useAccentColor && navigationMenu.accentColor ? "secondary" : "inherit"
      }
      style={{
        opacity:
          location.pathname.startsWith(ROUTES.CART) &&
          cartTotals.total.getAmount() < minimumCharge
            ? 0
            : 1,
        color: useAccentColor
          ? themeColor(navigationMenu.accentColor)
          : undefined,
        transition: "all 0.3s linear 0s",
      }}
    >
      {location.pathname.startsWith(ROUTES.CART) ? (
        <CheckoutIcon />
      ) : (
        <Fragment>
          <CartIcon
            color="inherit"
            style={{
              transform: cartChange % 2 ? "rotateZ(360deg)" : "rotateZ(720deg)",
              transition: "all 0.3s ease-out 0s",
            }}
          />
          <div
            style={{
              color: useAccentColor
                ? themeColor(navigationMenu.accentColor)
                : undefined,
              transition: "all 0.3s linear 0s",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "4em",
            }}
          >
            {location.pathname.startsWith(ROUTES.CART) ? (
              <span>&nbsp;</span>
            ) : cartTotals.itemCount > 9 ? (
              <Typography color="inherit" variant="caption">
                9+
              </Typography>
            ) : (
              // <Typography color="inherit" variant="caption">
              <div style={{ fontSize: "0.75em", fontFamily: "Arial" }}>
                {cartTotals.itemCount}
              </div>
              // </Typography>
            )}
          </div>
        </Fragment>
      )}
    </IconButton>
  );
};

export default CartIndicator;
