import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Fragment, FC } from "react";
import { useTranslation } from "react-i18next";
import ROUTES from "../../../utilities/constants/routes";
import { History } from "history";
import { history } from "../../App/history";
import useSiteUser from "../../UserProvider/useSiteUser";
import List from "@material-ui/core/List";

interface DBheaderMenuProps {}

const DBheaderMenu: FC<DBheaderMenuProps> = () => {
  const { t } = useTranslation();
  const { user } = useSiteUser();

  return (
    <Fragment>
      <div className="smartWidthXs">
        <List>
          <ListItem
            button
            onClick={(e) => {
              e.stopPropagation();
              history.replace(`${ROUTES.DASHBOARD_SETTINGS}`);
            }}
          >
            <ListItemText primary={t("dashboard.navigation.settings")} />
          </ListItem>

          <ListItem
            button
            onClick={(e) => {
              e.stopPropagation();
              history.replace(ROUTES.DASHBOARD_ACCOUNT);
            }}
          >
            <ListItemText primary={t("dashboard.navigation.account")} />
          </ListItem>

          <ListItem
            button
            onClick={(e) => {
              e.stopPropagation();
              history.replace(ROUTES.STORE);
            }}
          >
            <ListItemText primary={t("dashboard.navigation.portal")} />
          </ListItem>
          <ListItem
            button
            onClick={(e) => {
              e.stopPropagation();
              const { location } = history as History<{} | undefined>;
              history.push(location.pathname, {
                ...location.state,
                signOut: true,
              });
            }}
          >
            <ListItemText
              primary={
                user
                  ? t("dashboard.account.signOut")
                  : t("dashboard.account.signIn")
              }
            />
          </ListItem>
        </List>
      </div>
    </Fragment>
  );
};

export default DBheaderMenu;
