// This file was adapted from the data found here:
// https://raw.githubusercontent.com/bl00mber/react-phone-input-2/master/src/rawCountries.js

export interface Country {
  nameKey: string;
  regions: string[];
  iso2Code: string;
  dialCode: string;
  // Not available in all cases
  format?: string;
  // If >1 country with the same dial code
  priority?: number;
  // If >1 country with the same dial code
  areaCodes?: string[];
}

export const countries: Country[] = [
  { nameKey: "AF", regions: ["asia"], iso2Code: "af", dialCode: "93" },
  { nameKey: "AL", regions: ["europe"], iso2Code: "al", dialCode: "355" },
  {
    nameKey: "DZ",
    regions: ["africa", "north-africa"],
    iso2Code: "dz",
    dialCode: "213",
  },
  { nameKey: "AD", regions: ["europe"], iso2Code: "ad", dialCode: "376" },
  { nameKey: "AO", regions: ["africa"], iso2Code: "ao", dialCode: "244" },
  {
    nameKey: "AG",
    regions: ["america", "carribean"],
    iso2Code: "ag",
    dialCode: "1268",
  },
  {
    nameKey: "AR",
    regions: ["america", "south-america"],
    iso2Code: "ar",
    dialCode: "54",
    format: "(..) ........",
    areaCodes: [
      "11",
      "221",
      "223",
      "261",
      "264",
      "2652",
      "280",
      "2905",
      "291",
      "2920",
      "2966",
      "299",
      "341",
      "342",
      "343",
      "351",
      "376",
      "379",
      "381",
      "3833",
      "385",
      "387",
      "388",
    ],
  },
  {
    nameKey: "AM",
    regions: ["asia", "ex-ussr"],
    iso2Code: "am",
    dialCode: "374",
  },
  {
    nameKey: "AW",
    regions: ["america", "carribean"],
    iso2Code: "aw",
    dialCode: "297",
  },
  {
    nameKey: "AU",
    regions: ["oceania"],
    iso2Code: "au",
    dialCode: "61",
    format: "(..) .... ....",
    areaCodes: ["2", "3", "4", "7", "8", "02", "03", "04", "07", "08"],
  },
  {
    nameKey: "AT",
    regions: ["europe", "eu-union"],
    iso2Code: "at",
    dialCode: "43",
  },
  {
    nameKey: "AZ",
    regions: ["asia", "ex-ussr"],
    iso2Code: "az",
    dialCode: "994",
  },
  {
    nameKey: "BS",
    regions: ["america", "carribean"],
    iso2Code: "bs",
    dialCode: "1242",
  },
  { nameKey: "BH", regions: ["middle-east"], iso2Code: "bh", dialCode: "973" },
  { nameKey: "BD", regions: ["asia"], iso2Code: "bd", dialCode: "880" },
  {
    nameKey: "BB",
    regions: ["america", "carribean"],
    iso2Code: "bb",
    dialCode: "1246",
  },
  {
    nameKey: "BY",
    regions: ["europe", "ex-ussr"],
    iso2Code: "by",
    dialCode: "375",
    format: "(..) ... .. ..",
  },
  {
    nameKey: "BE",
    regions: ["europe", "eu-union"],
    iso2Code: "be",
    dialCode: "32",
    format: "... .. .. ..",
  },
  {
    nameKey: "BZ",
    regions: ["america", "central-america"],
    iso2Code: "bz",
    dialCode: "501",
  },
  { nameKey: "BJ", regions: ["africa"], iso2Code: "bj", dialCode: "229" },
  { nameKey: "BT", regions: ["asia"], iso2Code: "bt", dialCode: "975" },
  {
    nameKey: "BO",
    regions: ["america", "south-america"],
    iso2Code: "bo",
    dialCode: "591",
  },
  {
    nameKey: "BA",
    regions: ["europe", "ex-yugos"],
    iso2Code: "ba",
    dialCode: "387",
  },
  { nameKey: "BW", regions: ["africa"], iso2Code: "bw", dialCode: "267" },
  {
    nameKey: "BR",
    regions: ["america", "south-america"],
    iso2Code: "br",
    dialCode: "55",
    format: "(..) .........",
  },
  { nameKey: "IO", regions: ["asia"], iso2Code: "io", dialCode: "246" },
  { nameKey: "BN", regions: ["asia"], iso2Code: "bn", dialCode: "673" },
  {
    nameKey: "BG",
    regions: ["europe", "eu-union"],
    iso2Code: "bg",
    dialCode: "359",
  },
  { nameKey: "BF", regions: ["africa"], iso2Code: "bf", dialCode: "226" },
  { nameKey: "BI", regions: ["africa"], iso2Code: "bi", dialCode: "257" },
  { nameKey: "KH", regions: ["asia"], iso2Code: "kh", dialCode: "855" },
  { nameKey: "CM", regions: ["africa"], iso2Code: "cm", dialCode: "237" },
  {
    nameKey: "CA",
    regions: ["america", "north-america"],
    iso2Code: "ca",
    dialCode: "1",
    format: "(...) ...-....",
    priority: 1,
    areaCodes: [
      "204",
      "226",
      "236",
      "249",
      "250",
      "289",
      "306",
      "343",
      "365",
      "387",
      "403",
      "416",
      "418",
      "431",
      "437",
      "438",
      "450",
      "506",
      "514",
      "519",
      "548",
      "579",
      "581",
      "587",
      "604",
      "613",
      "639",
      "647",
      "672",
      "705",
      "709",
      "742",
      "778",
      "780",
      "782",
      "807",
      "819",
      "825",
      "867",
      "873",
      "902",
      "905",
    ],
  },
  { nameKey: "CV", regions: ["africa"], iso2Code: "cv", dialCode: "238" },
  {
    nameKey: "BQ",
    regions: ["america", "carribean"],
    iso2Code: "bq",
    dialCode: "599",
    priority: 1,
  },
  { nameKey: "CF", regions: ["africa"], iso2Code: "cf", dialCode: "236" },
  { nameKey: "TD", regions: ["africa"], iso2Code: "td", dialCode: "235" },
  {
    nameKey: "CL",
    regions: ["america", "south-america"],
    iso2Code: "cl",
    dialCode: "56",
  },
  {
    nameKey: "CN",
    regions: ["asia"],
    iso2Code: "cn",
    dialCode: "86",
    format: "..-.........",
  },
  {
    nameKey: "CO",
    regions: ["america", "south-america"],
    iso2Code: "co",
    dialCode: "57",
  },
  { nameKey: "KM", regions: ["africa"], iso2Code: "km", dialCode: "269" },
  { nameKey: "CG", regions: ["africa"], iso2Code: "cd", dialCode: "243" },
  { nameKey: "CG", regions: ["africa"], iso2Code: "cg", dialCode: "242" },
  {
    nameKey: "CR",
    regions: ["america", "central-america"],
    iso2Code: "cr",
    dialCode: "506",
    format: "....-....",
  },
  {
    nameKey: "CI",
    regions: ["africa"],
    iso2Code: "ci",
    dialCode: "225",
    format: ".. .. .. ..",
  },
  {
    nameKey: "HR",
    regions: ["europe", "eu-union", "ex-yugos"],
    iso2Code: "hr",
    dialCode: "385",
  },
  {
    nameKey: "CU",
    regions: ["america", "carribean"],
    iso2Code: "cu",
    dialCode: "53",
  },
  {
    nameKey: "CW",
    regions: ["america", "carribean"],
    iso2Code: "cw",
    dialCode: "599",
  },
  {
    nameKey: "CY",
    regions: ["europe", "eu-union"],
    iso2Code: "cy",
    dialCode: "357",
    format: ".. ......",
  },
  {
    nameKey: "CZ",
    regions: ["europe", "eu-union"],
    iso2Code: "cz",
    dialCode: "420",
  },
  {
    nameKey: "DK",
    regions: ["europe", "eu-union", "baltic"],
    iso2Code: "dk",
    dialCode: "45",
    format: ".. .. .. ..",
  },
  { nameKey: "DJ", regions: ["africa"], iso2Code: "dj", dialCode: "253" },
  {
    nameKey: "DM",
    regions: ["america", "carribean"],
    iso2Code: "dm",
    dialCode: "1767",
  },
  {
    nameKey: "DO",
    regions: ["america", "carribean"],
    iso2Code: "do",
    dialCode: "1",
    priority: 2,
    areaCodes: ["809", "829", "849"],
  },
  {
    nameKey: "EC",
    regions: ["america", "south-america"],
    iso2Code: "ec",
    dialCode: "593",
  },
  {
    nameKey: "EG",
    regions: ["africa", "north-africa"],
    iso2Code: "eg",
    dialCode: "20",
  },
  {
    nameKey: "SV",
    regions: ["america", "central-america"],
    iso2Code: "sv",
    dialCode: "503",
    format: "....-....",
  },
  { nameKey: "GQ", regions: ["africa"], iso2Code: "gq", dialCode: "240" },
  { nameKey: "ER", regions: ["africa"], iso2Code: "er", dialCode: "291" },
  {
    nameKey: "EE",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2Code: "ee",
    dialCode: "372",
    format: ".... ......",
  },
  { nameKey: "ET", regions: ["africa"], iso2Code: "et", dialCode: "251" },
  { nameKey: "FJ", regions: ["oceania"], iso2Code: "fj", dialCode: "679" },
  {
    nameKey: "FI",
    regions: ["europe", "eu-union", "baltic"],
    iso2Code: "fi",
    dialCode: "358",
    format: ".. ... .. ..",
  },
  {
    nameKey: "FR",
    regions: ["europe", "eu-union"],
    iso2Code: "fr",
    dialCode: "33",
    format: ". .. .. .. ..",
  },
  {
    nameKey: "GF",
    regions: ["america", "south-america"],
    iso2Code: "gf",
    dialCode: "594",
  },
  { nameKey: "PF", regions: ["oceania"], iso2Code: "pf", dialCode: "689" },
  { nameKey: "GA", regions: ["africa"], iso2Code: "ga", dialCode: "241" },
  { nameKey: "GM", regions: ["africa"], iso2Code: "gm", dialCode: "220" },
  {
    nameKey: "GE",
    regions: ["asia", "ex-ussr"],
    iso2Code: "ge",
    dialCode: "995",
  },
  {
    nameKey: "DE",
    regions: ["europe", "eu-union", "baltic"],
    iso2Code: "de",
    dialCode: "49",
    format: ".... ........",
  },
  { nameKey: "GH", regions: ["africa"], iso2Code: "gh", dialCode: "233" },
  {
    nameKey: "GR",
    regions: ["europe", "eu-union"],
    iso2Code: "gr",
    dialCode: "30",
  },
  {
    nameKey: "GD",
    regions: ["america", "carribean"],
    iso2Code: "gd",
    dialCode: "1473",
  },
  {
    nameKey: "GP",
    regions: ["america", "carribean"],
    iso2Code: "gp",
    dialCode: "590",
  },
  { nameKey: "GU", regions: ["oceania"], iso2Code: "gu", dialCode: "1671" },
  {
    nameKey: "GT",
    regions: ["america", "central-america"],
    iso2Code: "gt",
    dialCode: "502",
    format: "....-....",
  },
  { nameKey: "GN", regions: ["africa"], iso2Code: "gn", dialCode: "224" },
  { nameKey: "GW", regions: ["africa"], iso2Code: "gw", dialCode: "245" },
  {
    nameKey: "GY",
    regions: ["america", "south-america"],
    iso2Code: "gy",
    dialCode: "592",
  },
  {
    nameKey: "HT",
    regions: ["america", "carribean"],
    iso2Code: "ht",
    dialCode: "509",
    format: "....-....",
  },
  {
    nameKey: "HN",
    regions: ["america", "central-america"],
    iso2Code: "hn",
    dialCode: "504",
  },
  {
    nameKey: "HK",
    regions: ["asia"],
    iso2Code: "hk",
    dialCode: "852",
    format: ".... ....",
  },
  {
    nameKey: "HU",
    regions: ["europe", "eu-union"],
    iso2Code: "hu",
    dialCode: "36",
  },
  {
    nameKey: "IS",
    regions: ["europe"],
    iso2Code: "is",
    dialCode: "354",
    format: "... ....",
  },
  {
    nameKey: "IN",
    regions: ["asia"],
    iso2Code: "in",
    dialCode: "91",
    format: ".....-.....",
  },
  { nameKey: "ID", regions: ["asia"], iso2Code: "id", dialCode: "62" },
  {
    nameKey: "IR",
    regions: ["middle-east"],
    iso2Code: "ir",
    dialCode: "98",
    format: "... ... ....",
  },
  { nameKey: "IQ", regions: ["middle-east"], iso2Code: "iq", dialCode: "964" },
  {
    nameKey: "IE",
    regions: ["europe", "eu-union"],
    iso2Code: "ie",
    dialCode: "353",
    format: ".. .......",
  },
  {
    nameKey: "IL",
    regions: ["middle-east"],
    iso2Code: "il",
    dialCode: "972",
    format: "... ... ....",
  },
  {
    nameKey: "IT",
    regions: ["europe", "eu-union"],
    iso2Code: "it",
    dialCode: "39",
    format: "... .......",
  },
  {
    nameKey: "JM",
    regions: ["america", "carribean"],
    iso2Code: "jm",
    dialCode: "1876",
  },
  {
    nameKey: "JP",
    regions: ["asia"],
    iso2Code: "jp",
    dialCode: "81",
    format: ".. .... ....",
  },
  { nameKey: "JO", regions: ["middle-east"], iso2Code: "jo", dialCode: "962" },
  {
    nameKey: "KZ",
    regions: ["asia", "ex-ussr"],
    iso2Code: "kz",
    dialCode: "7",
    format: "... ...-..-..",
    priority: 1,
    areaCodes: [
      "310",
      "311",
      "312",
      "313",
      "315",
      "318",
      "321",
      "324",
      "325",
      "326",
      "327",
      "336",
      "7172",
      "73622",
    ],
  },
  { nameKey: "KE", regions: ["africa"], iso2Code: "ke", dialCode: "254" },
  { nameKey: "KI", regions: ["oceania"], iso2Code: "ki", dialCode: "686" },
  {
    nameKey: "XK",
    regions: ["europe", "ex-yugos"],
    iso2Code: "xk",
    dialCode: "383",
  },
  { nameKey: "KW", regions: ["middle-east"], iso2Code: "kw", dialCode: "965" },
  {
    nameKey: "KG",
    regions: ["asia", "ex-ussr"],
    iso2Code: "kg",
    dialCode: "996",
  },
  { nameKey: "LA", regions: ["asia"], iso2Code: "la", dialCode: "856" },
  {
    nameKey: "LV",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2Code: "lv",
    dialCode: "371",
  },
  { nameKey: "LB", regions: ["middle-east"], iso2Code: "lb", dialCode: "961" },
  { nameKey: "LS", regions: ["africa"], iso2Code: "ls", dialCode: "266" },
  { nameKey: "LR", regions: ["africa"], iso2Code: "lr", dialCode: "231" },
  {
    nameKey: "LY",
    regions: ["africa", "north-africa"],
    iso2Code: "ly",
    dialCode: "218",
  },
  { nameKey: "LI", regions: ["europe"], iso2Code: "li", dialCode: "423" },
  {
    nameKey: "LT",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2Code: "lt",
    dialCode: "370",
  },
  {
    nameKey: "LU",
    regions: ["europe", "eu-union"],
    iso2Code: "lu",
    dialCode: "352",
  },
  { nameKey: "MO", regions: ["asia"], iso2Code: "mo", dialCode: "853" },
  {
    nameKey: "MK",
    regions: ["europe", "ex-yugos"],
    iso2Code: "mk",
    dialCode: "389",
  },
  { nameKey: "MG", regions: ["africa"], iso2Code: "mg", dialCode: "261" },
  { nameKey: "MW", regions: ["africa"], iso2Code: "mw", dialCode: "265" },
  {
    nameKey: "MY",
    regions: ["asia"],
    iso2Code: "my",
    dialCode: "60",
    format: "..-....-....",
  },
  { nameKey: "MV", regions: ["asia"], iso2Code: "mv", dialCode: "960" },
  { nameKey: "ML", regions: ["africa"], iso2Code: "ml", dialCode: "223" },
  {
    nameKey: "MT",
    regions: ["europe", "eu-union"],
    iso2Code: "mt",
    dialCode: "356",
  },
  { nameKey: "MH", regions: ["oceania"], iso2Code: "mh", dialCode: "692" },
  {
    nameKey: "MQ",
    regions: ["america", "carribean"],
    iso2Code: "mq",
    dialCode: "596",
  },
  { nameKey: "MR", regions: ["africa"], iso2Code: "mr", dialCode: "222" },
  { nameKey: "MU", regions: ["africa"], iso2Code: "mu", dialCode: "230" },
  {
    nameKey: "MX",
    regions: ["america", "central-america"],
    iso2Code: "mx",
    dialCode: "52",
    format: "... ... ....",
    areaCodes: ["55", "81", "33", "656", "664", "998", "774", "229"],
  },
  {
    nameKey: "FM",
    regions: ["oceania"],
    iso2Code: "fm",
    dialCode: "691",
  },
  {
    nameKey: "MD",
    regions: ["europe"],
    iso2Code: "md",
    dialCode: "373",
    format: "(..) ..-..-..",
  },
  { nameKey: "MC", regions: ["europe"], iso2Code: "mc", dialCode: "377" },
  { nameKey: "MN", regions: ["asia"], iso2Code: "mn", dialCode: "976" },
  {
    nameKey: "ME",
    regions: ["europe", "ex-yugos"],
    iso2Code: "me",
    dialCode: "382",
  },
  {
    nameKey: "MA",
    regions: ["africa", "north-africa"],
    iso2Code: "ma",
    dialCode: "212",
  },
  { nameKey: "MZ", regions: ["africa"], iso2Code: "mz", dialCode: "258" },
  { nameKey: "MM", regions: ["asia"], iso2Code: "mm", dialCode: "95" },
  { nameKey: "NA", regions: ["africa"], iso2Code: "na", dialCode: "264" },
  { nameKey: "NR", regions: ["africa"], iso2Code: "nr", dialCode: "674" },
  { nameKey: "NP", regions: ["asia"], iso2Code: "np", dialCode: "977" },
  {
    nameKey: "NL",
    regions: ["europe", "eu-union"],
    iso2Code: "nl",
    dialCode: "31",
    format: ".. ........",
  },
  { nameKey: "NC", regions: ["oceania"], iso2Code: "nc", dialCode: "687" },
  {
    nameKey: "NZ",
    regions: ["oceania"],
    iso2Code: "nz",
    dialCode: "64",
    format: "...-...-....",
  },
  {
    nameKey: "NI",
    regions: ["america", "central-america"],
    iso2Code: "ni",
    dialCode: "505",
  },
  { nameKey: "NE", regions: ["africa"], iso2Code: "ne", dialCode: "227" },
  { nameKey: "NG", regions: ["africa"], iso2Code: "ng", dialCode: "234" },
  {
    nameKey: "KP",
    regions: ["asia"],
    iso2Code: "kp",
    dialCode: "850",
  },
  {
    nameKey: "NO",
    regions: ["europe", "baltic"],
    iso2Code: "no",
    dialCode: "47",
    format: "... .. ...",
  },
  { nameKey: "OM", regions: ["middle-east"], iso2Code: "om", dialCode: "968" },
  {
    nameKey: "PK",
    regions: ["asia"],
    iso2Code: "pk",
    dialCode: "92",
    format: "...-.......",
  },
  { nameKey: "PW", regions: ["oceania"], iso2Code: "pw", dialCode: "680" },
  {
    nameKey: "PS",
    regions: ["middle-east"],
    iso2Code: "ps",
    dialCode: "970",
  },
  {
    nameKey: "PA",
    regions: ["america", "central-america"],
    iso2Code: "pa",
    dialCode: "507",
  },
  { nameKey: "PG", regions: ["oceania"], iso2Code: "pg", dialCode: "675" },
  {
    nameKey: "PY",
    regions: ["america", "south-america"],
    iso2Code: "py",
    dialCode: "595",
  },
  {
    nameKey: "PE",
    regions: ["america", "south-america"],
    iso2Code: "pe",
    dialCode: "51",
  },
  {
    nameKey: "PH",
    regions: ["asia"],
    iso2Code: "ph",
    dialCode: "63",
    format: ".... .......",
  },
  {
    nameKey: "PL",
    regions: ["europe", "eu-union", "baltic"],
    iso2Code: "pl",
    dialCode: "48",
    format: "...-...-...",
  },
  {
    nameKey: "PT",
    regions: ["europe", "eu-union"],
    iso2Code: "pt",
    dialCode: "351",
  },
  {
    nameKey: "PR",
    regions: ["america", "carribean"],
    iso2Code: "pr",
    dialCode: "1",
    priority: 3,
    areaCodes: ["787", "939"],
  },
  { nameKey: "QA", regions: ["middle-east"], iso2Code: "qa", dialCode: "974" },
  { nameKey: "RE", regions: ["africa"], iso2Code: "re", dialCode: "262" },
  {
    nameKey: "RO",
    regions: ["europe", "eu-union"],
    iso2Code: "ro",
    dialCode: "40",
  },
  {
    nameKey: "RU",
    regions: ["europe", "asia", "ex-ussr", "baltic"],
    iso2Code: "ru",
    dialCode: "7",
    format: "(...) ...-..-..",
  },
  { nameKey: "RW", regions: ["africa"], iso2Code: "rw", dialCode: "250" },
  {
    nameKey: "KN",
    regions: ["america", "carribean"],
    iso2Code: "kn",
    dialCode: "1869",
  },
  {
    nameKey: "LC",
    regions: ["america", "carribean"],
    iso2Code: "lc",
    dialCode: "1758",
  },
  {
    nameKey: "VC",
    regions: ["america", "carribean"],
    iso2Code: "vc",
    dialCode: "1784",
  },
  { nameKey: "WS", regions: ["oceania"], iso2Code: "ws", dialCode: "685" },
  { nameKey: "SM", regions: ["europe"], iso2Code: "sm", dialCode: "378" },
  {
    nameKey: "ST",
    regions: ["africa"],
    iso2Code: "st",
    dialCode: "239",
  },
  { nameKey: "SA", regions: ["middle-east"], iso2Code: "sa", dialCode: "966" },
  { nameKey: "SN", regions: ["africa"], iso2Code: "sn", dialCode: "221" },
  {
    nameKey: "RS",
    regions: ["europe", "ex-yugos"],
    iso2Code: "rs",
    dialCode: "381",
  },
  { nameKey: "SC", regions: ["africa"], iso2Code: "sc", dialCode: "248" },
  { nameKey: "SL", regions: ["africa"], iso2Code: "sl", dialCode: "232" },
  {
    nameKey: "SG",
    regions: ["asia"],
    iso2Code: "sg",
    dialCode: "65",
    format: "....-....",
  },
  {
    nameKey: "SK",
    regions: ["europe", "eu-union"],
    iso2Code: "sk",
    dialCode: "421",
  },
  {
    nameKey: "SI",
    regions: ["europe", "eu-union", "ex-yugos"],
    iso2Code: "si",
    dialCode: "386",
  },
  { nameKey: "SB", regions: ["oceania"], iso2Code: "sb", dialCode: "677" },
  { nameKey: "SO", regions: ["africa"], iso2Code: "so", dialCode: "252" },
  { nameKey: "ZA", regions: ["africa"], iso2Code: "za", dialCode: "27" },
  {
    nameKey: "KR",
    regions: ["asia"],
    iso2Code: "kr",
    dialCode: "82",
    format: "... .... ....",
  },
  {
    nameKey: "SS",
    regions: ["africa", "north-africa"],
    iso2Code: "ss",
    dialCode: "211",
  },
  {
    nameKey: "ES",
    regions: ["europe", "eu-union"],
    iso2Code: "es",
    dialCode: "34",
    format: "... ... ...",
  },
  { nameKey: "LK", regions: ["asia"], iso2Code: "lk", dialCode: "94" },
  { nameKey: "SD", regions: ["africa"], iso2Code: "sd", dialCode: "249" },
  {
    nameKey: "SR",
    regions: ["america", "south-america"],
    iso2Code: "sr",
    dialCode: "597",
  },
  { nameKey: "SZ", regions: ["africa"], iso2Code: "sz", dialCode: "268" },
  {
    nameKey: "SE",
    regions: ["europe", "eu-union", "baltic"],
    iso2Code: "se",
    dialCode: "46",
    format: "(...) ...-...",
  },
  {
    nameKey: "CH",
    regions: ["europe"],
    iso2Code: "ch",
    dialCode: "41",
    format: ".. ... .. ..",
  },
  {
    nameKey: "SY",
    regions: ["middle-east"],
    iso2Code: "sy",
    dialCode: "963",
  },
  { nameKey: "TW", regions: ["asia"], iso2Code: "tw", dialCode: "886" },
  {
    nameKey: "TJ",
    regions: ["asia", "ex-ussr"],
    iso2Code: "tj",
    dialCode: "992",
  },
  { nameKey: "TZ", regions: ["africa"], iso2Code: "tz", dialCode: "255" },
  { nameKey: "TH", regions: ["asia"], iso2Code: "th", dialCode: "66" },
  { nameKey: "TL", regions: ["asia"], iso2Code: "tl", dialCode: "670" },
  { nameKey: "TG", regions: ["africa"], iso2Code: "tg", dialCode: "228" },
  { nameKey: "TO", regions: ["oceania"], iso2Code: "to", dialCode: "676" },
  {
    nameKey: "TT",
    regions: ["america", "carribean"],
    iso2Code: "tt",
    dialCode: "1868",
  },
  {
    nameKey: "TN",
    regions: ["africa", "north-africa"],
    iso2Code: "tn",
    dialCode: "216",
  },
  {
    nameKey: "TR",
    regions: ["europe"],
    iso2Code: "tr",
    dialCode: "90",
    format: "... ... .. ..",
  },
  {
    nameKey: "TM",
    regions: ["asia", "ex-ussr"],
    iso2Code: "tm",
    dialCode: "993",
  },
  { nameKey: "TV", regions: ["asia"], iso2Code: "tv", dialCode: "688" },
  { nameKey: "UG", regions: ["africa"], iso2Code: "ug", dialCode: "256" },
  {
    nameKey: "UA",
    regions: ["europe", "ex-ussr"],
    iso2Code: "ua",
    dialCode: "380",
    format: "(..) ... .. ..",
  },
  { nameKey: "AE", regions: ["middle-east"], iso2Code: "ae", dialCode: "971" },
  {
    nameKey: "GB",
    regions: ["europe", "eu-union"],
    iso2Code: "gb",
    dialCode: "44",
    format: ".... ......",
  },
  {
    nameKey: "US",
    regions: ["america", "north-america"],
    iso2Code: "us",
    dialCode: "1",
    format: "(...) ...-....",
    areaCodes: [
      "907",
      "205",
      "251",
      "256",
      "334",
      "479",
      "501",
      "870",
      "480",
      "520",
      "602",
      "623",
      "928",
      "209",
      "213",
      "310",
      "323",
      "408",
      "415",
      "510",
      "530",
      "559",
      "562",
      "619",
      "626",
      "650",
      "661",
      "707",
      "714",
      "760",
      "805",
      "818",
      "831",
      "858",
      "909",
      "916",
      "925",
      "949",
      "951",
      "303",
      "719",
      "970",
      "203",
      "860",
      "202",
      "302",
      "239",
      "305",
      "321",
      "352",
      "386",
      "407",
      "561",
      "727",
      "772",
      "813",
      "850",
      "863",
      "904",
      "941",
      "954",
      "229",
      "404",
      "478",
      "706",
      "770",
      "912",
      "808",
      "319",
      "515",
      "563",
      "641",
      "712",
      "208",
      "217",
      "309",
      "312",
      "618",
      "630",
      "708",
      "773",
      "815",
      "847",
      "219",
      "260",
      "317",
      "574",
      "765",
      "812",
      "316",
      "620",
      "785",
      "913",
      "270",
      "502",
      "606",
      "859",
      "225",
      "318",
      "337",
      "504",
      "985",
      "413",
      "508",
      "617",
      "781",
      "978",
      "301",
      "410",
      "207",
      "231",
      "248",
      "269",
      "313",
      "517",
      "586",
      "616",
      "734",
      "810",
      "906",
      "989",
      "218",
      "320",
      "507",
      "612",
      "651",
      "763",
      "952",
      "314",
      "417",
      "573",
      "636",
      "660",
      "816",
      "228",
      "601",
      "662",
      "406",
      "252",
      "336",
      "704",
      "828",
      "910",
      "919",
      "701",
      "308",
      "402",
      "603",
      "201",
      "609",
      "732",
      "856",
      "908",
      "973",
      "505",
      "575",
      "702",
      "775",
      "212",
      "315",
      "516",
      "518",
      "585",
      "607",
      "631",
      "716",
      "718",
      "845",
      "914",
      "216",
      "330",
      "419",
      "440",
      "513",
      "614",
      "740",
      "937",
      "405",
      "580",
      "918",
      "503",
      "541",
      "215",
      "412",
      "570",
      "610",
      "717",
      "724",
      "814",
      "401",
      "803",
      "843",
      "864",
      "605",
      "423",
      "615",
      "731",
      "865",
      "901",
      "931",
      "210",
      "214",
      "254",
      "281",
      "325",
      "361",
      "409",
      "432",
      "512",
      "713",
      "806",
      "817",
      "830",
      "903",
      "915",
      "936",
      "940",
      "956",
      "972",
      "979",
      "435",
      "801",
      "276",
      "434",
      "540",
      "703",
      "757",
      "804",
      "802",
      "206",
      "253",
      "360",
      "425",
      "509",
      "262",
      "414",
      "608",
      "715",
      "920",
      "304",
      "307",
    ],
  },
  {
    nameKey: "UY",
    regions: ["america", "south-america"],
    iso2Code: "uy",
    dialCode: "598",
  },
  {
    nameKey: "UZ",
    regions: ["asia", "ex-ussr"],
    iso2Code: "uz",
    dialCode: "998",
  },
  { nameKey: "VU", regions: ["oceania"], iso2Code: "vu", dialCode: "678" },
  {
    nameKey: "VA",
    regions: ["europe"],
    iso2Code: "va",
    dialCode: "39",
    format: ".. .... ....",
    priority: 1,
  },
  {
    nameKey: "VE",
    regions: ["america", "south-america"],
    iso2Code: "ve",
    dialCode: "58",
  },
  { nameKey: "VN", regions: ["asia"], iso2Code: "vn", dialCode: "84" },
  { nameKey: "YE", regions: ["middle-east"], iso2Code: "ye", dialCode: "967" },
  { nameKey: "ZM", regions: ["africa"], iso2Code: "zm", dialCode: "260" },
  { nameKey: "ZW", regions: ["africa"], iso2Code: "zw", dialCode: "263" },
];
