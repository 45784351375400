import { FC } from "react";
import FlagIcon from "@material-ui/icons/Flag";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import PaymentIcon from "@material-ui/icons/Payment";
import MessageIcon from "@material-ui/icons/Message";
import { Typography } from "@material-ui/core";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

interface PaymentUIProps {}

const PaymentUI: FC<PaymentUIProps> = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "100%",
        gridTemplateRows: "34px 1fr",
        gridTemplateAreas: `
"AppBar"
"OrderSection"`,
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",

        marginRight: "10px",
        marginLeft: "10px",
        height: "271px",
        maxHeight: "271px",
        overflow: "hidden",
        borderRadius: "5px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#f5f5f5",
          padding: "0.5em",
          borderRadius: "4px",
          boxShadow:
            "0px 6px 9px -2px rgb(0 0 0 / 20%), 0px 19px 33px 0px rgb(0 0 0 / 14%), 0px 5px 41px 3px rgb(0 0 0 / 12%)",
          textAlign: "left",
          zIndex: 1,
          width: "60%",
        }}
      >
        <Typography style={{ fontSize: "0.8em" }}>New Charge</Typography>

        <div style={{ padding: "0.25em", textAlign: "center" }}>
          <strong>
            <Typography style={{ fontSize: "0.8em" }}>$4.35</Typography>
          </strong>
          <Typography style={{ fontSize: "0.5em", marginTop: "0.25em" }}>
            This charge requires customer payment.
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "0.25em",
            }}
          >
            <RadioButtonCheckedIcon
              color="secondary"
              style={{
                fontSize: "0.8em",
              }}
            />
            &nbsp;
            <Typography
              style={{
                fontSize: "0.6em",
              }}
            >
              Send Charge
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RadioButtonUncheckedIcon style={{ fontSize: "0.8em" }} />
            &nbsp;
            <Typography style={{ fontSize: "0.6em" }}>Mark As Paid</Typography>
          </div>
          <div
            style={{
              marginTop: "0.5em",
              backgroundColor: "#0c343d",
              color: "white",
              padding: "0.25em",
              borderRadius: "2px",
              display: "inline-block",
            }}
          >
            <Typography style={{ fontSize: "0.5em" }}>SEND CHARGE</Typography>
          </div>
          <div
            style={{
              marginTop: "0.5em",
              backgroundColor: "rgba(0,0,0,0.12)",
              borderRadius: "3px",
              paddingBottom: "0.5em",
            }}
          >
            <Typography style={{ fontSize: "0.6em" }}>
              Or send link manually ...
            </Typography>
            <div
              style={{
                border: "2px solid rgba(0,0,0,0.2)",
                marginTop: "0.25em",
                maxWidth: "75%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <div
              style={{
                border: "2px solid rgba(0,0,0,0.2)",
                marginTop: "0.25em",
                maxWidth: "25%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <div
              style={{
                border: "1px solid gray",
                padding: "0.15em 0.25em",
                borderRadius: "2px",
                display: "inline-block",
                marginTop: "0.5em",
              }}
            >
              <Typography style={{ fontSize: "0.5em" }}>
                COPY TO CLIPBOARD
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div style={{ gridArea: "AppBar" }}>
        <div
          style={{
            gridArea: "AppBar",
            backgroundColor: "#0c343d",
            color: "white",
            display: "flex",
            alignItems: "center",
            padding: "0.5em",
          }}
        >
          <div style={{ flexGrow: 1 }} />
          <MessageIcon style={{ fontSize: "0.8em" }} />
          &nbsp;&nbsp;
          <PaymentIcon color="secondary" style={{ fontSize: "0.8em" }} />
          &nbsp;&nbsp;
          <FlagIcon style={{ fontSize: "0.8em" }} />
          &nbsp;&nbsp;
          <MoreIcon style={{ fontSize: "0.8em" }} />
        </div>
      </div>
      <div
        style={{
          gridArea: "OrderSection",
          padding: "0.5em",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div style={{ textAlign: "left" }}>
            <Typography style={{ fontSize: "0.7em" }}>BNY1D</Typography>
            <Typography
              noWrap
              color="textSecondary"
              style={{ fontSize: "0.7em" }}
            >
              Customer - 26 mins ago
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "0.7em" }}>
              Pickup
            </Typography>
          </div>
          <div>
            <div
              style={{
                backgroundColor: "#0c343d",
                border: "1px solid #0c343d",
                color: "white",
                padding: "0.15em",
                borderRadius: "2px",
              }}
            >
              <Typography style={{ fontSize: "0.7em" }}>IN PROGRESS</Typography>
            </div>
            <div
              style={{
                marginTop: "5px",
                border: "1px solid gray",
                padding: "0.15em",
                borderRadius: "2px",
              }}
            >
              <Typography style={{ fontSize: "0.7em" }}>
                DUE IN 2 HRS
              </Typography>
            </div>
            <div
              style={{
                marginTop: "5px",
                border: "1px solid gray",
                padding: "0.15em",
                borderRadius: "2px",
              }}
            >
              <Typography style={{ fontSize: "0.7em" }}>ADD ITEMS</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentUI;
