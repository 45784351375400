import { memo, Fragment, useState, FC } from "react";
import { validatePassword } from "../../../utilities/validateEmailPassPhone";
import useSpinner from "../../Spinner/useSpinner";
import STauthModal from "./sTauth";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import { useSiteFirebase } from "../../../Firebase/context";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DoneIcon from "@material-ui/icons/Done";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useToast from "../../Main/useToast";
import MUIDialog from "../../Dashboard/Components/mui-dialog";

interface AccountPasswordProps {
  initPath: string;
}

const AccountPassword: FC<AccountPasswordProps> = memo(({ initPath }) => {
  const showSpinner = useSpinner();
  const { t } = useTranslation();
  const firebase = useSiteFirebase();

  const [newPassword, setNewPassword] = useState("");
  const passwordIsValid = validatePassword(newPassword);
  const [showPassword, setShowPassword] = useState(false);

  const toast = useToast();

  async function changePassword() {
    if (!passwordIsValid) {
      toast({
        dialog: true,
        color: "error",
        message: t("store.toast.badPswd"),
      });
      return;
    }
    const hideSpinner = showSpinner({ lag: "none" });
    try {
      await firebase.updatePassword(newPassword);
      history.goBack();
    } catch (error) {
      console.log("Change Password ", error);
      if (
        error.code === "auth/requires-recent-login" ||
        error.code === "auth/user-token-expired"
      ) {
        const path = `${initPath}/reauthenticate`;
        if (window.location.pathname !== path) {
          history.push(path);
        }
      } else {
        toast({
          dialog: true,
          color: "error",
          message: error.message,
        });
      }
    } finally {
      hideSpinner();
    }
  }
  return (
    <Fragment>
      <MUIDialog route={`${initPath}/reauthenticate`}>
        <STauthModal
          message={t("store.account.reauthToChangePassword")}
          showCreateAccount={false}
          afterSignin={() => {
            history.goBack();
            changePassword();
          }}
        />
      </MUIDialog>

      <DialogContent className="smartWidthMd">
        <FormControl fullWidth>
          <TextField
            autoComplete="new-password"
            fullWidth
            type={!showPassword ? "password" : "text"}
            label={t("store.account.enterPassword")}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
            }
            label={t("store.account.showPassword")}
          />

          <FormHelperText>{t("store.account.accountPassword")}</FormHelperText>
          <div style={{ textAlign: "left", margin: "0.25em 0" }}>
            <Chip
              style={{ border: "none", backgroundColor: "transparent" }}
              variant="default"
              label={t("store.account.oneLowerCase")}
              disabled={/[a-z]/.test(newPassword)}
              icon={
                /[a-z]/.test(newPassword) ? <DoneIcon /> : <ArrowForwardIcon />
              }
            />
          </div>
          <div style={{ textAlign: "left", margin: "0.25em 0" }}>
            <Chip
              style={{ border: "none", backgroundColor: "transparent" }}
              variant="default"
              label={t("store.account.oneUpperCase")}
              disabled={/[A-Z]/.test(newPassword)}
              icon={
                /[A-Z]/.test(newPassword) ? <DoneIcon /> : <ArrowForwardIcon />
              }
            />
          </div>
          <div style={{ textAlign: "left", margin: "0.25em 0" }}>
            <Chip
              style={{ border: "none", backgroundColor: "transparent" }}
              variant="default"
              label={t("store.account.oneNumber")}
              disabled={/\d/.test(newPassword)}
              icon={
                /\d/.test(newPassword) ? <DoneIcon /> : <ArrowForwardIcon />
              }
            />
          </div>
          <div style={{ textAlign: "left", margin: "0.25em 0" }}>
            <Chip
              style={{
                whiteSpace: "normal",
                border: "none",
                backgroundColor: "transparent",
              }}
              variant="default"
              label={t("store.account.eightCharacters")}
              disabled={newPassword.length >= 8}
              icon={
                newPassword.length >= 8 ? <DoneIcon /> : <ArrowForwardIcon />
              }
            />
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.goBack()}>{t("store.cancel")}</Button>
        <Button onClick={(e) => changePassword()}>{t("store.submit")}</Button>
      </DialogActions>
    </Fragment>
  );
});

export default AccountPassword;
