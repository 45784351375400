import { FC } from "react";
import * as React from "react";
import RichTextPage from "./richTextPage";
import { PageTemplate } from "../../../database/page";

export interface PageTemplateLoaderProps {
  template: PageTemplate;
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const PageTemplateLoader: FC<PageTemplateLoaderProps> = React.memo(
  ({ template, setMainBgImage }) => {
    // In the future there will be multiple templates, but at the moment there's only one.
    return <RichTextPage template={template} setMainBgImage={setMainBgImage} />;
  }
);

export default PageTemplateLoader;
