import { createContext } from "react";
import { SiteSettings } from "../database/siteSettings";
import { useContextThrowUndefined } from "../utilities/useContextThrowUndefined";
import { RawStore } from "../database/store";

export const SiteSettingsContext = createContext<SiteSettings | undefined>(
  undefined
);

SiteSettingsContext.displayName = "Site Settings";

/**
 * Returns information about the store. For example, the theme, hours, address
 */
export const useSiteSettings = () =>
  useContextThrowUndefined(SiteSettingsContext);

export const RawSiteSettingsContext = createContext<SiteSettings | undefined>(
  undefined
);

RawSiteSettingsContext.displayName = "Raw Site Settings";

/**
 * The site settings, straight from the database with no tweaks.
 */
export const useRawSiteSettings = () =>
  useContextThrowUndefined(SiteSettingsContext);

export const RawStoreContext = createContext<RawStore | undefined>(undefined);

RawStoreContext.displayName = "Raw Store";

/**
 * Returns information from the /stores/store document
 */
export const useRawStore = () => useContextThrowUndefined(RawStoreContext);

/**
 * The site id
 */
export const useSiteId = () => useRawStore().siteId;

/**
 * Returns the store location id. We currently don't support multiple locations
 */
export const useLocationId = () => useRawStore().locations[0].id;

// export const CatalogContext = createContext<
//   | {
//       catalog: Catalog_WithDefaults;
//       /**
//        * Lookup table listing all items in the catalog by catalog id
//        */
//       itemLookup: Record<string, CatalogItem_WithDefaults>;
//     }
//   | undefined
// >(undefined);

// /**
//  * Returns information about the location's catalog (ie, the items for sale)
//  */
// export const useCatalog = () => useContextThrowUndefined(CatalogContext);

// In some places, we need the settings data on its own.
//   RawSiteSettingsContext makes that available.
// export const RawSiteSettingsContext = createContext<SiteSettings>(
//   {} as SiteSettings
// );
