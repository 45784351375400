import { DialogActions, DialogTitle, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Dispatch, FC, SetStateAction } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { history } from "../../../App/history";
import MUIDialog from "../../../Dashboard/Components/mui-dialog";
import Terms from "../terms";
import { matchPath, useLocation } from "react-router";
import { useGridBashFirebase } from "../../../../Firebase/context";
import {
  Application,
  ApplicationInDatabase,
} from "../../../../database/signup";
import { DoubleLeft, DoubleRight } from "../transitions";
import { unstable_batchedUpdates } from "react-dom";
import { blankApplication } from "../home";
import useToast from "../../../Main/useToast";
import useSpinner from "../../../Spinner/useSpinner";
import { validateEmail } from "../../../../utilities/validateEmailPassPhone";

interface ApplyCarouselProps {
  application: Application;
  setApplication: Dispatch<SetStateAction<Application>>;
  docId: string;
  setDocId: Dispatch<SetStateAction<string>>;
  initPath: string;
}

const ApplyCarousel: FC<ApplyCarouselProps> = (props) => {
  const location = useLocation();
  const applyStep = matchPath<{ applyStep: string }>(location.pathname, {
    path: `${props.initPath}/applySequence/:applyStep`,
  })?.params.applyStep;
  return <InnerComponent {...props} applyStep={applyStep} />;
};

type InnerProps = ApplyCarouselProps & { applyStep: string | undefined };

// Split into a separate component to improve performance
const InnerComponent: FC<InnerProps> = React.memo(
  ({ application, setApplication, applyStep, docId, setDocId, initPath }) => {
    const firebase = useGridBashFirebase();
    const { t } = useTranslation();
    const showSpinner = useSpinner();
    const toast = useToast();

    const { email, name, phone, companyName, companyZip, termsAccepted } =
      application;

    const finishStep1 = () => {
      if (!validateEmail(application.email)) {
        toast({
          dialog: true,
          color: "error",
          message: "Please provide a valid email.",
        });
      } else {
        history.push(`${initPath}/applySequence/name_phone`);
      }
    };

    const finishStep2 = () => {
      if (!name || !phone) {
        return;
      }
      history.push(`${initPath}/applySequence/company_zip`);
    };

    const finishStep3 = async () => {
      if (!companyName || !companyZip || !termsAccepted) {
        return;
      }

      const databaseEntry: ApplicationInDatabase = {
        timestamp: Date.now(),
        ...application,
      };
      const hideSpinner = showSpinner();
      try {
        await firebase.firestore
          .collection("applications")
          .doc(docId)
          .set(databaseEntry);

        unstable_batchedUpdates(() => {
          setDocId(firebase.firestore.collection("applications").doc().id);
          setApplication(blankApplication);
        });

        history.push(`${initPath}/applySequence/complete`);
      } catch (err) {
        toast({
          message: "An error occurred while submitting your application",
          color: "error",
          dialog: true,
        });
      } finally {
        hideSpinner();
      }
    };

    return (
      <React.Fragment>
        <Dialog
          open={applyStep === "email"}
          TransitionComponent={DoubleLeft}
          keepMounted
          onClose={() => {
            history.goBack();
          }}
        >
          <DialogTitle>Let's get you equipped!</DialogTitle>
          <DialogContent className="smartWidthMd">
            <FormControl fullWidth>
              <TextField
                fullWidth
                type="email"
                label={t("gridbash.home.enterEmail")}
                value={email}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setApplication((prev) => ({
                    ...prev,
                    email: value,
                  }));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    finishStep1();
                  }
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={email.trim().length === 0}
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                finishStep1();
              }}
            >
              {t("gridbash.next")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={applyStep === "name_phone"}
          TransitionComponent={DoubleLeft}
          keepMounted
          onClose={() => {
            history.go(-2);
          }}
        >
          <DialogTitle>Almost there...</DialogTitle>
          <DialogContent className="smartWidthMd">
            <FormControl fullWidth>
              <TextField
                type="text"
                label={t("gridbash.home.yourName")}
                value={name}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setApplication((prev) => ({
                    ...prev,
                    name: value,
                  }));
                }}
              />
              <div style={{ marginTop: "1em" }} />
              <TextField
                type="text"
                label={t("gridbash.home.phone")}
                value={phone}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setApplication((prev) => ({
                    ...prev,
                    phone: value,
                  }));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    finishStep2();
                  }
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.goBack();
              }}
            >
              {t("gridbash.home.previous")}
            </Button>
            <Typography color="textSecondary">2 / 3</Typography>
            <div style={{ marginRight: "max(1em, 2vw)" }} />
            <Button
              variant="outlined"
              disabled={!name || !phone}
              onClick={(e) => {
                e.stopPropagation();
                finishStep2();
              }}
            >
              {t("gridbash.next")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={applyStep === "company_zip"}
          TransitionComponent={DoubleLeft}
          keepMounted
          onClose={() => {
            history.go(-3);
          }}
        >
          <DialogTitle>Final step...</DialogTitle>
          <DialogContent className="smartWidthMd">
            <FormControl fullWidth>
              <TextField
                type="text"
                label={t("gridbash.home.companyName")}
                value={companyName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setApplication((prev) => ({
                    ...prev,
                    companyName: value,
                  }));
                }}
              />
              <div style={{ marginTop: "1em" }} />
              <TextField
                type="text"
                label={t("gridbash.home.zipCode")}
                InputLabelProps={{
                  style: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  },
                }}
                value={companyZip}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setApplication((prev) => ({
                    ...prev,
                    companyZip: value,
                  }));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    finishStep3();
                  }
                }}
              />
            </FormControl>
            <div style={{ marginTop: "1em" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setApplication((prev) => ({
                    ...prev,
                    termsAccepted: !prev.termsAccepted,
                  }));
                }}
                color={termsAccepted ? "secondary" : undefined}
              >
                {termsAccepted ? <CheckedIcon /> : <UncheckedIcon />}
              </IconButton>
              <Typography>
                I agree to the&nbsp;
                <Link
                  variant="button"
                  color="textSecondary"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    const path = `${initPath}/applySequence/company_zip/terms-of-service`;
                    if (!window.location.pathname.includes(path)) {
                      history.push(path);
                    }
                  }}
                >
                  Terms of Service
                </Link>
              </Typography>
              <MUIDialog
                route={`${initPath}/applySequence/company_zip/terms-of-service`}
              >
                <Terms />
              </MUIDialog>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.goBack();
              }}
            >
              {t("gridbash.home.previous")}
            </Button>
            <Typography color="textSecondary">3 / 3</Typography>
            <div style={{ marginRight: "max(1em, 2vw)" }} />
            <Button
              disabled={!companyName || !companyZip || !termsAccepted}
              onClick={(e) => {
                e.stopPropagation();
                finishStep3();
              }}
            >
              {t("gridbash.next")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={applyStep === "complete"}
          TransitionComponent={DoubleRight}
          keepMounted
          onClose={() => {
            history.replace("");
          }}
        >
          <DialogTitle>Application Submitted!</DialogTitle>
          <DialogContent className="smartWidthMd">
            <Typography>
              Thank you. We will get in touch with you shortly!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.replace("");
              }}
            >
              {t("gridbash.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
);

export default ApplyCarousel;
