import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { FC } from "react";
import * as React from "react";
import { useSiteSettings } from "../../../../customization/siteSettingsContext";
import { Category_Database } from "../../../../database/catalog";
import { ThemeColor } from "../../../../database/theme";
import { useThemeColor } from "../../../widgets/useThemeColor";

interface CatTabsProps {
  headerHeight: number;
  tabId: number;
  setTabId: React.Dispatch<React.SetStateAction<number>>;
  filteredCategories: Category_Database[];
  setCategoryId: React.Dispatch<React.SetStateAction<string>>;
  scrolling: number;
}

const CatTabs: FC<CatTabsProps> = ({
  headerHeight,
  tabId,
  setTabId,
  filteredCategories,
  setCategoryId,
  scrolling,
}) => {
  const { theme2, navigationMenu } = useSiteSettings();
  const themeColor = useThemeColor();

  return (
    <div
      className="headerBarHeight sticky anim_fadeIn_0510"
      style={{
        top: scrolling > 0 ? 0 : headerHeight,
        display: "block",
        width: "auto",
        maxWidth: "100%",
        marginBottom: "1vw",
        backgroundColor:
          scrolling < 0
            ? themeColor(navigationMenu.backgroundColor)
            : undefined,
        transition: "all 0.5s ease 0s",
        zIndex: 1,
        opacity: 0,
      }}
    >
      <div
        style={{
          display: "inline-block",
          maxWidth: "100%",
        }}
      >
        <Tabs
          style={{
            paddingTop: "0.75vw",
            color:
              scrolling < 0
                ? theme2.colors[ThemeColor.onPrimary].value
                : undefined,
          }}
          value={tabId}
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            setTabId(newValue);
          }}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
        >
          {filteredCategories.map((category) => {
            return (
              <Tab
                key={category.categoryId}
                // fullWidth
                label={category.name}
                onClick={(e) => {
                  e.stopPropagation();
                  setCategoryId(category.categoryId);
                }}
              />
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default CatTabs;
