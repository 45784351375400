import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import { FC } from "react";
import UserAccountIcon from "@material-ui/icons/AccountBox";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

interface RolesUIProps {}

const RolesUI: FC<RolesUIProps> = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "100%",
        gridTemplateAreas: `
"AppBar"
"UsersSection"`,
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",

        marginRight: "10px",
        marginLeft: "10px",
        maxHeight: "271px",
        overflow: "hidden",
        borderRadius: "5px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#f5f5f5",
          padding: "0.5em",
          borderRadius: "4px",
          boxShadow:
            "0px 6px 9px -2px rgb(0 0 0 / 20%), 0px 19px 33px 0px rgb(0 0 0 / 14%), 0px 5px 41px 3px rgb(0 0 0 / 12%)",
          textAlign: "center",
          zIndex: 1,
          width: "60%",
        }}
      >
        <Typography style={{ fontSize: "0.8em" }}>
          name@yourcompany.com
        </Typography>

        <div
          style={{
            display: "inline-block",
            padding: "0.25em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.25em",
            }}
          >
            <RadioButtonUncheckedIcon
              style={{
                fontSize: "0.8em",
              }}
            />
            &nbsp;
            <Typography
              style={{
                fontSize: "0.6em",
              }}
            >
              Owner
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RadioButtonCheckedIcon
              color="secondary"
              style={{ fontSize: "0.8em" }}
            />
            &nbsp;
            <Typography style={{ fontSize: "0.6em" }}>Admin</Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RadioButtonUncheckedIcon
              style={{
                fontSize: "0.8em",
              }}
            />
            &nbsp;
            <Typography
              style={{
                fontSize: "0.6em",
              }}
            >
              Register
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RadioButtonUncheckedIcon
              style={{
                fontSize: "0.8em",
              }}
            />
            &nbsp;
            <Typography
              style={{
                fontSize: "0.6em",
              }}
            >
              Warehouse
            </Typography>
          </div>
        </div>
        <Typography
          align="right"
          style={{ fontSize: "0.5em", marginRight: "0.25em" }}
        >
          SUBMIT
        </Typography>
      </div>
      <div
        style={{
          gridArea: "AppBar",
          backgroundColor: "#0c343d",
          color: "white",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          padding: "0.5em 1em",
        }}
      >
        <Typography noWrap style={{ fontSize: "0.7em" }}>
          LOCATION & TIMING
        </Typography>
        &nbsp;
        <div style={{ flexGrow: 1 }} />
        <Typography noWrap style={{ fontSize: "0.7em" }}>
          PAYMENTS
        </Typography>
        &nbsp;
        <div style={{ flexGrow: 1 }} />
        <Typography noWrap style={{ fontSize: "0.7em" }}>
          COMMUNICATION
        </Typography>
        &nbsp;
        <div style={{ flexGrow: 1 }} />
        <div style={{ position: "relative" }}>
          <Typography noWrap style={{ fontSize: "0.7em" }}>
            USERS
          </Typography>
          <div
            style={{
              position: "absolute",
              border: "1px solid cyan",
              bottom: "-9px",
              width: "100%",
            }}
          />
        </div>
      </div>
      <div style={{ gridArea: "UsersSection", padding: "0.5em" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginTop: "0.5em",
              backgroundColor: "#0c343d",
              border: "1px solid #0c343d",
              color: "white",
              padding: "0.20em",
              borderRadius: "2px",
              display: "inline-block",
            }}
          >
            <Typography style={{ fontSize: "0.7em" }}>
              SEARCH BY EMAIL
            </Typography>
          </div>
          <div
            style={{
              marginTop: "0.5em",
              border: "1px solid gray",

              padding: "0.20em",
              borderRadius: "2px",
              display: "inline-block",
            }}
          >
            <Typography style={{ fontSize: "0.7em" }}>
              SEARCH BY PHONE
            </Typography>
          </div>
        </div>
        <div
          style={{
            marginTop: "1em",
            borderBottom: "1px solid lightgray",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Typography
            align="left"
            style={{
              fontSize: "0.7em",
            }}
            color="textSecondary"
          >
            Enter Email
          </Typography>
        </div>
        <div style={{ marginTop: "0.5em" }}>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <UserAccountIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <div style={{ width: "100%" }}>
              <div style={{ border: "2px solid lightgray", width: "65%" }} />
              <div
                style={{
                  border: "2px solid lightgray",
                  width: "35%",
                  marginTop: "5px",
                }}
              />
            </div>
          </ListItem>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <UserAccountIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <div style={{ width: "100%" }}>
              <div style={{ border: "2px solid lightgray", width: "65%" }} />
              <div
                style={{
                  border: "2px solid lightgray",
                  width: "35%",
                  marginTop: "5px",
                }}
              />
            </div>
          </ListItem>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <UserAccountIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <div style={{ width: "100%" }}>
              <div style={{ border: "2px solid lightgray", width: "65%" }} />
              <div
                style={{
                  border: "2px solid lightgray",
                  width: "35%",
                  marginTop: "5px",
                }}
              />
            </div>
          </ListItem>
          <ListItem divider>
            <ListItemAvatar style={{ minWidth: 0, marginRight: "10px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                <UserAccountIcon style={{ fontSize: "0.7em" }} />
              </Avatar>
            </ListItemAvatar>
            <div style={{ width: "100%" }}>
              <div style={{ border: "2px solid lightgray", width: "65%" }} />
              <div
                style={{
                  border: "2px solid lightgray",
                  width: "35%",
                  marginTop: "5px",
                }}
              />
            </div>
          </ListItem>
        </div>
      </div>
    </div>
  );
};

export default RolesUI;
