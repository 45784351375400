import {
  PageCell,
  PageWidgetType,
} from "../../../Store/PageRenderer/widgetType";

type UpdateFunction<T extends PageWidgetType> = (
  callback: (cells: PageCell[]) => PageCell[],
  cell: PageCell<T>
) => PageCell;

/**
 * Defines how to update child cells inside of a cell. Most cells have no children
 * and can just use undefined. If the cell has nested components, then it needs a
 * custom implementation to step through those children.
 */
const childUpdateFunctions: {
  [key in PageWidgetType]: UpdateFunction<key> | undefined;
} = {
  [PageWidgetType.textOrIcon]: undefined,
  [PageWidgetType.presetText]: undefined,
  [PageWidgetType.wealthyText]: undefined,
  [PageWidgetType.navLinks]: undefined,
  [PageWidgetType.button]: undefined,
  [PageWidgetType.image]: undefined,
  [PageWidgetType.catalogItem]: undefined,
  [PageWidgetType.catalogGrid]: undefined,
  [PageWidgetType.textLabels]: undefined,
  [PageWidgetType.filterLabels]: undefined,
  [PageWidgetType.navDrawer]: undefined,
  [PageWidgetType.cartIcon]: undefined,
  [PageWidgetType.favoriteIcon]: undefined,
  [PageWidgetType.emptySpace]: undefined,
  [PageWidgetType.widgetPicker]: undefined,
  [PageWidgetType.row]: (callback, cell) => {
    let columnChanged = false;
    const newColumns = cell.props.columns.map((column) => {
      const newCells = callback(column.cells);
      if (newCells !== column.cells) {
        columnChanged = true;
        return {
          ...column,
          cells: newCells,
        };
      } else {
        return column;
      }
    });

    if (columnChanged) {
      return {
        ...cell,
        props: {
          ...cell.props,
          columns: newColumns,
        },
      };
    } else {
      return cell;
    }
  },
  [PageWidgetType.customWidget]: (callback, cell) => {
    if (!cell.props.template) {
      return cell;
    }
    let rowChanged = false;
    const newRows = cell.props.template.rows.map((row) => {
      let columnChanged = false;
      const newColumns = row.props.columns.map((column) => {
        const newCells = callback(column.cells);
        if (newCells !== column.cells) {
          columnChanged = true;
          return {
            ...column,
            cells: newCells,
          };
        } else {
          return column;
        }
      });
      if (columnChanged) {
        rowChanged = true;
        return {
          ...row,
          props: {
            columns: newColumns,
          },
        };
      } else {
        return row;
      }
    });
    return rowChanged
      ? {
          ...cell,
          props: {
            ...cell.props,
            template: {
              ...cell.props.template,
              rows: newRows,
            },
          },
        }
      : cell;
  },
};

/**
 * Steps through every cell, including child cells, and creates a
 * structure with the same shape as the original, but with the
 * values returned by the callback function. Similar to array.map
 * except it knows how to walk the nested data structure of cells
 *
 * @example
 * ```typescript
 * const section: PageSection = // some section object
 *
 * const newCells = mapCells((cell: PageCell) => {
 *   if (cell.id === "replaceMe") {
 *     return {
 *       id: 'new',
 *       type: PageWidgetType.emptySpace,
 *       props: {}
 *     }
 *   } else {
 *     return cell;
 *   }
 * }, section.gridProps.cells);
 *
 * @param callback - function that takes a cell and returns a new cell
 * @param cells - array of cells to step through
 * ```
 */
export function mapCells(
  callback: <T extends PageWidgetType>(cell: PageCell<T>) => PageCell<T>,
  cells: PageCell<PageWidgetType.row>[]
): PageCell<PageWidgetType.row>[];
export function mapCells(
  callback: <T extends PageWidgetType>(cell: PageCell<T>) => PageCell,
  cells: PageCell[]
): PageCell<PageWidgetType.row>[];
export function mapCells(
  callback: <T extends PageWidgetType>(cell: PageCell<T>) => PageCell,
  cells: PageCell[]
): PageCell[];
export function mapCells(
  callback: <T extends PageWidgetType>(cell: PageCell<T>) => PageCell,
  cells: PageCell[]
): PageCell[] {
  const recurse = (childCells: PageCell[]) => mapCells(callback, childCells);
  let changed = false;

  const newCells: PageCell[] = cells.map(
    <T extends PageWidgetType>(cell: PageCell<T>) => {
      let newCell = callback(cell);
      // Some cells have children and need to run custom logic
      const childUpdateFunction = childUpdateFunctions[cell.type];
      if (childUpdateFunction) {
        newCell = (childUpdateFunction as UpdateFunction<T>)(recurse, cell);
      }
      changed = changed || newCell !== cell;
      return newCell;
    }
  );
  return changed ? newCells : cells;
}

/**
 * Steps through every cell, including child cells, and filters them
 * in or out based on the return value of the callback function.
 * Similar to array.filter, except it knows how to walk the nested
 * data structure of cells.
 *
 * @example
 * ```typescript
 * const section: PageSection = // some section object
 *
 * const newCells = filterCells((cell: PageCell) => {
 *   return cell.id !== "deleteMe";
 * }, section.gridProps.cells);
 * ```
 */
export function filterCells(
  predicate: (cell: PageCell) => unknown,
  cells: PageCell<PageWidgetType.row>[]
): PageCell<PageWidgetType.row>[];
export function filterCells(
  predicate: (cell: PageCell) => unknown,
  cells: PageCell[]
): PageCell[];
export function filterCells(
  predicate: (cell: PageCell) => unknown,
  cells: PageCell[]
): PageCell[] {
  let changed = false;
  const recurse = (childCells: PageCell[]) =>
    filterCells(predicate, childCells);

  let newCells: PageCell[] = [];
  for (const cell of cells) {
    const keep = predicate(cell);
    if (!keep) {
      // Filter it out
      changed = true;
      continue;
    } else {
      // Keep it, but its children may need to be filtered
      const childUpdateFunction = childUpdateFunctions[cell.type];
      let newCell = cell;
      if (childUpdateFunction) {
        newCell = (childUpdateFunction as UpdateFunction<PageWidgetType>)(
          recurse,
          cell
        );
      }
      changed = changed || newCell !== cell;
      newCells.push(newCell);
    }
  }

  return changed ? newCells : cells;
}

export function findCells<T extends PageWidgetType>(
  predicate: (cell: PageCell) => cell is PageCell<T>,
  cells: PageCell[]
): PageCell<T> | undefined;
export function findCells(
  predicate: (cell: PageCell) => unknown,
  cells: PageCell[]
): PageCell | undefined;
export function findCells<T extends PageWidgetType>(
  predicate:
    | ((cell: PageCell) => unknown)
    | ((cell: PageCell) => cell is PageCell<T>),
  cells: PageCell[]
): PageCell | undefined {
  let result: PageCell | undefined;
  // Map does do a bit of extra work since it won't terminate early, but
  //    it lets us use a unified implementation, and so any bug can be
  //    fixed in one place.
  mapCells((cell: PageCell) => {
    if (!result) {
      result = predicate(cell) ? cell : undefined;
    }
    return cell;
  }, cells);
  return result;
}

/**
 * Steps through every cell, including child cells, and
 * calls the callback for each. Similar to array.forEach, but
 * it knows how to walk the nested data structure of cells
 *
 * @example
 * ```typescript
 * const section: PageSection = // some section object
 * let cellCount = 0;
 * forEachCells((cell: PageCell) => {
 *   cellCount++;
 * }, section.gridProps.cells)
 * console.log('There are', cellCount, 'total cells');
 * ```
 */
export const forEachCells = (
  callback: (cell: PageCell) => void,
  cells: PageCell[]
) => {
  mapCells((cell: PageCell) => {
    callback(cell);
    return cell;
  }, cells);
  return undefined;
};
