import { Dialog, DialogProps } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { FC, useEffect } from "react";
import { Route, useLocation } from "react-router";
import { applyBodyStyle } from "../../../utilities/bodyStyleCoordinator";
import { useUnmount } from "../../../utilities/useUnmount";
import useWindowResize from "../../../utilities/useWindowResize";
import { history } from "../../App/history";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      /**
       * THERE"S THE SUPER ANNOYING SIDE EFFECT OF SOFT KEYBOARDS
       * DEMOLISHING THE VIEWPORT HEIGHT ON ANDROID PHONES.
       *
       * FOR THAT REASON WE ALWAYS TRY TO PLACE MODALS AT THE TOP
       * FOR WHEN THEY HAVE INPUT FIELDS WHICH WILL LAUNCH THE KEYBOARD
       *
       * IF THE INPUT FIELD SITS AT A HIGHER POSITION THAN THE TOP OF
       * THE KEYBOARD, NO JUMPS OCCUR.
       *
       * THIS IS ONE OF THE FEW CASES IPHONE SAFARI HAS A BIG WIN OVER
       * GOOGLE ANDROID CHROME
       *
       */
      alignItems: "flex-start",
    },
    root: { margin: 0 },
  })
);
/**
 * Accepts all the same props as Dialog, except you pass in
 * route instead of open (open will be calculated from the route)
 */
export type MUIDialogProps = Omit<DialogProps, "open"> & {
  route: string | string[];
  /**
   * Optional function to only show the dialog if the location contains
   * a certain state.
   */
  stateMatch?: (locationState: { [key: string]: unknown }) => boolean;
  onUnmount?: () => void;
  keepPosition?: boolean;
};

/**
 * A material-ui dialog, but tied to a url
 */
const MUIDialog: FC<MUIDialogProps> = ({
  route,
  stateMatch = () => true,
  keepPosition = false,
  ...rest
}) => {
  const location = useLocation();
  const state = (location.state ?? {}) as { [key: string]: unknown };
  return (
    <Route path={route}>
      {stateMatch(state) && <InnerComponent {...rest} keepPosition />}
    </Route>
  );
};

const InnerComponent: FC<Omit<MUIDialogProps, "open" | "route">> = ({
  onClose,
  onUnmount,
  keepPosition,
  ...rest
}) => {
  useUnmount(onUnmount);

  const dimensions = useWindowResize("mui-dialog");
  const classes = useStyles();

  useEffect(() => {
    if (dimensions.touch) {
      /**
       * WHEN WE OPEN A MODAL WE WANT TO PREVENT THE BODY
       * ELEMENT FROM SCROLLING AS ON MOBILE DEVICES THE URL
       * BAR MOVES UP DURING BODY SCROLL, WHICH MAKES THE MODAL
       * JUMP UP
       */
      const scrollPos = window.scrollY;
      const cleanup = () => {
        window.scrollTo({ top: scrollPos });
      };
      return applyBodyStyle((style) => {
        style.position = "fixed";
        style.width = "100vw";
        style.maxWidth = "100vw";
        if (scrollPos !== 0) {
          style.top = `-${scrollPos}px`;
        }
      }, cleanup);
    }
  }, [dimensions]);

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      onClick={(e) => e.stopPropagation()}
      onBackdropClick={(e) => {
        e.stopPropagation();
      }}
      classes={{
        container: keepPosition
          ? undefined
          : dimensions.touch
          ? classes.container
          : undefined,
      }}
      onClose={(event, reason) => {
        // Call the prop's onClose function (if any)
        onClose?.(event, reason);
        // Then go back, thus closing the dialog
        history.goBack();
      }}
      {...rest}
    />
  );
};

export default MUIDialog;
