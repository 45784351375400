import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { memo, FC, useState } from "react";
import useWindowResize from "../../../utilities/useWindowResize";
import { history } from "../../App/history";
import advance_together from "./img/advance_together.jpeg";

interface AboutProps {}

const About: FC<AboutProps> = memo(() => {
  const dimensions = useWindowResize("about");
  const [imageInfo, setImageInfo] = useState({
    w: 0,
    h: 0,
    loaded: false,
  });
  return (
    <div
      style={{
        paddingBottom: "max(2em, 2vw)",
      }}
    >
      <div style={{ paddingTop: "1em", paddingBottom: "1em" }}>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <img
              src={advance_together}
              alt="GridBash Advance Together"
              style={{
                filter: "blur(144px)",
                width: `${dimensions.w}px`,
              }}
            />
          </div>
          <img
            className={imageInfo.loaded ? "anim_fadeIn_0503" : ""}
            src={advance_together}
            alt="Smiling Business Owner"
            loading="lazy"
            onLoad={(e) => {
              setImageInfo({
                w: e.currentTarget.clientWidth,
                h: e.currentTarget.clientHeight,
                loaded: true,
              });
            }}
            style={{
              opacity: 0,
              verticalAlign: "middle",
              objectFit: "cover",
              maxWidth: dimensions.w >= 768 ? "600px" : "100%",
            }}
          />
        </div>
      </div>
      <div>
        <Typography align="center" variant="h4">
          There has to be a way to advance together
        </Typography>
        <br />
        <div
          style={{
            maxWidth: "1280px",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: "1em",
            paddingRight: "1em",
          }}
        >
          <Typography align="left">
            The year 2020 marked a major turning point for our society.
            Lifestyles, workstyles... the whole social system underwent major
            changes. What started as a health crisis evolved into a financial
            and social crisis, as racial inequalities moved front and center
            into the spotlight.
            <br />
            <br />
            With uncertainty rising to an all-time high, we are challenged as
            never before to do better and to be more. Successfully pulling
            through, and rising up will require tons of courage and compassion,
            but most of all, collective action. It’s vital, now more than ever,
            that all of us take part in using the opportunities available to us
            to make a difference for ourselves and for those around us.
            <br />
            <br />
            Even though the power of digital technologies have proven to be
            critical during the crisis, there’s a lot left to be desired. Local
            businesses, built by your friends, family, and neighbors, already
            heavily under siege by the rise of giant online retailing, were
            driven to survival mode. Those who were hanging by a thread,
            eventually went under by on-demand delivery technologies squeezing
            thin profit margins even further. I was shocked and in total
            disbelief when I witnessed the demise of the local community. How,
            in this day and age, could this happen? How could these digital
            delivery applications drive up bankruptcy even more? Was it greed?
            Was it inefficiency? Or would it turn out to be nothing of the kind,
            and technology is just expensive?
            <br />
            <br />
            This led to GridBash. As true technologists at heart, co-founder
            Nick and I, started with searching for answers to these questions.
            The answers turned out to be a bit more complex. It is our belief
            that some of the prominent technology companies do put forward their
            best, and foster good values in attempting to enhance society. What
            we do detect however, is a soaring inefficiency in developing their
            platforms.
            <br />
            <br />
            Highly funded tech companies easily fall into an abyss of poor
            resource management and loss of creativity, which negatively impacts
            their culture. As a result, all of us end up with a product for
            which both user experience and price does not live up to the maximum
            potential of what today’s technology can truly do to support
            society. In a few words:{" "}
            <strong>the vision to be better together is lacking today. </strong>
            <br />
            <br />I want to reemphasize that no one in particular is at blame
            here. Again, many notable companies are putting in an honest effort.
            In addition, high-funding is certainly not the culprit. There are
            many examples of where these companies thrive and offer tremendous
            benefits to us all. But it’s obvious that in some areas of digital
            technology, more particular on-demand and delivery applications,
            there’s much room for improvement.
            <br />
            <br />
            Technology serves, or so it should, as a means to advance humanity,
            and the developments of the past several years allow us to better
            deal with an uncertain future than at any point in our history. But
            in order for technology to rise to a level of being truly supportive
            to local communities, it needs to be coupled with ethics, integrity
            and strong commitments around privacy and security.
            <br />
            <br />
            At GridBash, we believe that digital technology has the power to
            create an unbiased, fair and free world. It is our honest commitment
            and aspiration to realize this by building a state-of-the-art
            technology platform which allows local communities to operate on
            thin margins in a volatile reality.
            <br />
            <br />
            We are a company of passionate overachieving technologists. Let it
            be clear that we are equally passionate about how we do our work. We
            are convinced that success and growth are the result of
            unimpeachable business ethics, and ensuring that every decision is
            in line with a commitment to create advancement for all.
            <br />
            <br />
            Are you a local business owner? Let's advance together!
          </Typography>
        </div>
        <div style={{ marginTop: "1em" }} />
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            history.push("/applySequence/email");
          }}
        >
          Apply Now
        </Button>
      </div>
    </div>
  );
});

export default About;
