import {
  CustomWidgetTemplate,
  PageColumn,
  PageConfig,
  PageHeader,
} from "../../../../../database/page";
import { SiteSettings } from "../../../../../database/siteSettings";
import {
  PageCell,
  PageWidgetPropMap,
  PageWidgetType,
} from "../../../../Store/PageRenderer/widgetType";

export enum PageActionType {
  createPage = "createPage",
  clonePage = "clonePage",
  renamePage = "renamePage",
  changePageIcon = "changePageIcon",
  reorderPages = "reorderPages",
  setHomePage = "setHomePage",
  setPageHidden = "setPageHidden",
  deletePage = "deletePage",
  updatePageSettings = "updatePageSettings",
  addHeader = "addHeader",
  removeHeader = "removeHeader",
  updateHeader = "updateHeader",
  createTopLevelRow = "createTopLevelRow",
  deleteTopLevelRow = "deleteTopLevelRow",
  cloneRow = "cloneRow",
  reorderRows = "reorderRows",
  addColumnToRow = "addColumnToRow",
  deleteColumn = "deleteColumn",
  moveColumn = "moveColumn",
  moveColumnToNewRow = "moveColumnToNewRow",
  addCellToColumn = "addCellToColumn",
  resizeColumnsByDrag = "resizeColumnsByDrag",
  resizeColumnsByInput = "resizeColumnsByInput",
  reorderColumns = "reorderColumns",
  updateColumn = "updateColumn",
  updateWidgetProps = "updateWidgetProps",
  replaceWidget = "replaceWidget",
  convertWidgetToRow = "convertWidgetToRow",
  reorderWidgets = "reorderWidgets",
  moveWidget = "moveWidget",
  moveWidgetToNewRow = "moveWidgetToNewRow",
  deleteWidget = "deleteWidget",
  updateTemplate = "updateTemplate",
  placeholder = "placeholder",
  bulkAction = "bulkAction",
}

export type PageAction =
  | CreatePageAction
  | ClonePageAction
  | RenamePageAction
  | ChangePageIconAction
  | ReorderPagesAction
  | SetHomePageAction
  | SetPageHiddenAction
  | DeletePageAction
  | UpdatePageSettingsAction
  | AddHeaderAction
  | RemoveHeaderAction
  | UpdateHeaderAction
  | CreateTopLevelRowAction
  | CloneRowAction
  | ReorderRowsAction
  | AddColumnToRowAction
  | DeleteColumnAction
  | MoveColumnAction
  | MoveColumnToNewRowAction
  | AddCellToColumnAction
  | ResizeColumnsByDragAction
  | ResizeColumnsByInputAction
  | ReorderColumnsAction
  | UpdateColumnAction
  | DeleteTopLevelRowAction
  | UpdateWidgetPropsAction
  | ReplaceWidgetAction
  | ConvertWidgetToRowAction
  | DeleteWidgetAction
  | ReorderWidgetsAction
  | MoveWidgetAction
  | MoveWidgetToNewRowAction
  | UpdateTemplateAction
  | PlaceholderAction
  | BulkAction;

interface BaseAction {
  type: PageActionType;
  /**
   * Used for deciding whether to combine multiple entires of the
   * undo history into one BulkAction
   */
  mergeId?: string;
}

interface CreatePageAction extends BaseAction {
  type: PageActionType.createPage;
  name: string;
  pageId: string;
}
interface ClonePageAction extends BaseAction {
  type: PageActionType.clonePage;
  originalPage: PageConfig;
  name: string;
  hideFromNavigation: boolean;
  uuid: string;
}
interface RenamePageAction extends BaseAction {
  type: PageActionType.renamePage;
  pageId: string;
  name: string;
}
interface ChangePageIconAction extends BaseAction {
  type: PageActionType.changePageIcon;
  pageId: string;
  icon: string | undefined;
}
interface ReorderPagesAction extends BaseAction {
  type: PageActionType.reorderPages;
  from: number;
  to: number;
}
interface SetHomePageAction extends BaseAction {
  type: PageActionType.setHomePage;
  pageId: string;
}
interface SetPageHiddenAction extends BaseAction {
  type: PageActionType.setPageHidden;
  pageId: string;
  hidden: boolean;
}
interface DeletePageAction extends BaseAction {
  type: PageActionType.deletePage;
  pageId: string;
}
interface UpdatePageSettingsAction extends BaseAction {
  type: PageActionType.updatePageSettings;
  newSettings: SiteSettings["pageSettings"];
}
interface AddHeaderAction extends BaseAction {
  type: PageActionType.addHeader;
  pageId: string;
  uuid: string;
}
interface RemoveHeaderAction extends BaseAction {
  type: PageActionType.removeHeader;
  pageId: string;
}
interface UpdateHeaderAction extends BaseAction {
  type: PageActionType.updateHeader;
  pageId: string;
  partialHeader: Partial<PageHeader>;
}
interface CreateTopLevelRowAction extends BaseAction {
  type: PageActionType.createTopLevelRow;
  columnLocator: ColumnLocator | SectionLocator;
  uuid: string;
  columns?: PageColumn[];
}
interface CloneRowAction extends BaseAction {
  type: PageActionType.cloneRow;
  rowLocator: RowLocator;
  uuid: string;
}

interface NavigationLocator {
  locationType: "navigation";
}

interface HeaderLocator {
  locationType: "header";
  pageId: string;
}

interface BodyLocator {
  locationType: "body";
  pageId: string;
}

interface CustomWidgetLocator {
  locationType: "customWidget";
  templateId: string;
}

/**
 * Description of where a section is found
 */
export type SectionLocator =
  | NavigationLocator
  | HeaderLocator
  | BodyLocator
  | CustomWidgetLocator;

/**
 * Description of where a column is found. This is used primarily by PageAction objects
 * to know which part of the state to read/write.
 *
 * @example
 * ```typescript
 * const example = {
 *   locationType: 'body',
 *   pageId: 'foo',
 *   path: [{ rowId: 0, columnId: 1}]
 * }
 * ```
 * The above object says to look on the 'foo' page in the body. Then go to row 0, column 1
 *
 * The path array can have more than one entry if targetting a nested row
 */
export type ColumnLocator = SectionLocator & {
  path: CompletePath;
};

/**
 * Description of where a cell is found. This is used primarily by PageAction objects
 * to know which part of the state to read/write.
 *
 * @example
 * ```typescript
 * const example = {
 *   locationType: 'body',
 *   pageId: 'foo',
 *   path: [{ rowId: 0, columnId: null}]
 * }
 * ```
 * The above object says to look on the 'foo' page in the body, and look at row 0.
 *
 * The path array can have more than one entry if targetting a nested row. In that
 * case, the last entry will have columnId null, but the rest will all have columnId
 */
export type CellLocator = SectionLocator & {
  path: [...CompleteLocatorSegment[], IncompleteLocatorSegment];
};

// Rows are just a special type of cell, so the same locator works for both
export type RowLocator = CellLocator;

export interface LocatorSegment {
  rowIndex: number;
  columnIndex: number | null;
}

export interface CompleteLocatorSegment extends LocatorSegment {
  columnIndex: number;
}

export interface IncompleteLocatorSegment extends LocatorSegment {
  columnIndex: null;
}

export type CompletePath = [
  ...CompleteLocatorSegment[],
  CompleteLocatorSegment
];
export type IncompletePath = [
  ...CompleteLocatorSegment[],
  IncompleteLocatorSegment
];

interface ReorderRowsAction extends BaseAction {
  type: PageActionType.reorderRows;
  columnLocator: ColumnLocator | SectionLocator;
  from: number;
  to: number;
}

interface AddColumnToRowAction extends BaseAction {
  type: PageActionType.addColumnToRow;
  rowLocator: RowLocator;
  columnIndex: number;
  partialColumn?: Partial<PageColumn>;
}
interface DeleteColumnAction extends BaseAction {
  type: PageActionType.deleteColumn;
  columnLocator: ColumnLocator;
}
export interface MoveColumnAction extends BaseAction {
  type: PageActionType.moveColumn;
  from: {
    columnLocator: ColumnLocator;
  };
  to: {
    columnLocator: ColumnLocator;
  };
}
interface MoveColumnToNewRowAction extends BaseAction {
  type: PageActionType.moveColumnToNewRow;
  from: ColumnLocator;
  to: BodyLocator;
  uuid: string;
}
export interface AddCellToColumnAction extends BaseAction {
  type: PageActionType.addCellToColumn;
  columnLocator: ColumnLocator;
  cellIndex: number;
  cell: PageCell;
}
/**
 * Resize columns by dragging the divider between them. This will
 * blindly accept whatever width values are provided, so it is
 * up to the caller to make sure they sum to 100%
 */
interface ResizeColumnsByDragAction extends BaseAction {
  type: PageActionType.resizeColumnsByDrag;
  rowLocator: RowLocator;
  widths: (number | undefined)[];
}
/**
 * Resize a column by typing into an input field. Neighboring
 * columns will be automatically adjusted to keep the total
 * sum to 100%
 */
interface ResizeColumnsByInputAction extends BaseAction {
  type: PageActionType.resizeColumnsByInput;
  columnLocator: ColumnLocator;
  width: number;
  widthUnit: "percent" | "px";
}
interface ReorderColumnsAction extends BaseAction {
  type: PageActionType.reorderColumns;
  rowLocator: RowLocator;
  from: number;
  to: number;
}
interface UpdateColumnAction extends BaseAction {
  type: PageActionType.updateColumn;
  columnLocator: ColumnLocator;
  partialColumn: Partial<PageColumn>;
}
interface DeleteTopLevelRowAction extends BaseAction {
  type: PageActionType.deleteTopLevelRow;
  rowLocator: RowLocator;
}
export interface UpdateWidgetPropsAction<
  T extends PageWidgetType = PageWidgetType
> extends BaseAction {
  type: PageActionType.updateWidgetProps;
  cellLocator: RowLocator;
  cellType: T;
  partialProps: Partial<PageWidgetPropMap[T]>;
}
export interface ReplaceWidgetAction<T extends PageWidgetType = PageWidgetType>
  extends BaseAction {
  type: PageActionType.replaceWidget;
  cellLocator: RowLocator;
  newType: T;
  newProps: PageWidgetPropMap[T];
}
/**
 * Inserts a placeholder entry into the undo history, while we perform
 * asynchronous work. Later, this action will be replaced with the real
 * action.
 */
export interface PlaceholderAction extends BaseAction {
  type: PageActionType.placeholder;
  actionId: string;
  temporaryAction?: PageAction;
}
export interface DeleteWidgetAction extends BaseAction {
  type: PageActionType.deleteWidget;
  cellLocator: RowLocator;
}
interface ConvertWidgetToRowAction extends BaseAction {
  type: PageActionType.convertWidgetToRow;
  cellToConvertLocator: CellLocator;
  cellToMoveLocator: CellLocator;
  insertLocation: "left" | "right";
  uuid: string;
}
interface ReorderWidgetsAction extends BaseAction {
  type: PageActionType.reorderWidgets;
  columnLocator: ColumnLocator;
  from: number;
  to: number;
}
export interface MoveWidgetAction extends BaseAction {
  type: PageActionType.moveWidget;
  from: {
    cellLocator: RowLocator;
  };
  to: {
    columnLocator: ColumnLocator;
    cellIndex: number;
    // If true, a new column will be inserted at the index, pushing others to the right
    // If false, the widget will be added to the existing column
    newColumn?: boolean;
  };
}
interface MoveWidgetToNewRowAction extends BaseAction {
  type: PageActionType.moveWidgetToNewRow;
  from: CellLocator;
  to: BodyLocator;
  uuid: string;
}
/**
 * Updates the top level of a custom widget template. To update
 * a row or widget inside the template, use UpdateWidgetAction
 * or UpdateRowAction instead.
 */
interface UpdateTemplateAction extends BaseAction {
  type: PageActionType.updateTemplate;
  newTemplate: CustomWidgetTemplate;
}
/**
 * Does multiple actions in a single action. This lets us undo
 * multiple things in a single step
 */
interface BulkAction extends BaseAction {
  type: PageActionType.bulkAction;
  actions: PageAction[];
}
