import { Fragment, FC } from "react";
import { PageAggregation, PageConfig } from "../../../../../database/page";
import { notUndefined } from "../../../../../utilities/notNull";
import { PageState } from "./reducer";
import { format } from "date-fns";
import {
  DialogTitle,
  DialogContent,
  Typography,
  ListItem,
  ListItemText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useUserData } from "../../../../../utilities/useUserData";
import { usePageApi } from "./pageApiContext";

interface ConflictProps {
  pageSummariesInDb: PageAggregation;
  pagesInDb: {
    [pageId: string]: PageConfig;
  };
  restoredState: PageState;
  closeModal: () => void;
}

const Conflict: FC<ConflictProps> = ({
  pageSummariesInDb,
  pagesInDb,
  restoredState,
  closeModal,
}) => {
  const { revert } = usePageApi();
  const myTimestamps = [
    restoredState.summaries.lastEdit?.timestamp,
    ...Object.values(restoredState.pages).map(
      (page) => page.lastEdit?.timestamp
    ),
  ].filter(notUndefined);
  const myFirstTimestamp = Math.min(...myTimestamps);

  const theirEdits = [
    pageSummariesInDb.lastEdit,
    ...Object.values(pagesInDb).map((page) => page.lastEdit),
  ]
    .filter(notUndefined)
    .sort((a, b) => a.timestamp - b.timestamp);
  const theirLatestEdit = theirEdits[0];

  const userData = useUserData(theirLatestEdit.userId);

  return (
    <Fragment>
      <DialogTitle className="smartWidthMd">Conflict</DialogTitle>
      <DialogContent className="smartWidthMd">
        <Typography>
          Someone has published changes to pages which conflict with your saved
          changes. Who's changes would you like to keep?
        </Typography>
        <div style={{ marginTop: "1em" }} />
        <Typography>You made changes on:</Typography>
        <ListItem>
          <ListItemText primary={format(new Date(myFirstTimestamp), "PPp")} />
        </ListItem>
        <Typography>
          {userData ? userData.contactInfo.email : theirLatestEdit.userId}{" "}
          published changes on:
        </Typography>
        <ListItem>
          <ListItemText
            primary={format(new Date(theirLatestEdit.timestamp), "PPp")}
          />
        </ListItem>
        <Typography>
          <b>Publishing your changes will permanently overwrite theirs.</b>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          Keep Mine
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            revert();
            closeModal();
          }}
        >
          Keep Theirs
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Conflict;
