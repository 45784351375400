import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// import "firebase/firebase-storage";
import "firebase/functions";
import {
  AcceptInvitationPayload,
  InvitationExistsPayload,
  InviteEmployeePayload,
  SetRolePayload,
} from "./gridbashFirebaseTypes";
// import "firebase/firebase-messaging";

interface Options {
  config: Object;
  functionRegion: string;
  vapidKey: string;
}

/**
 * Firebase instance for the master gridbash project
 */
export class GridbashFirebase {
  auth: firebase.auth.Auth;
  firestore: firebase.firestore.Firestore;
  functions: firebase.functions.Functions;
  messaging: firebase.messaging.Messaging | undefined;
  storage: firebase.storage.Storage;

  private app: firebase.app.App;
  private readyPromise: Promise<firebase.User | null>;

  constructor({ config, functionRegion, vapidKey }: Options) {
    let firstTime = true;
    try {
      // In case it's already created, try to get it
      this.app = firebase.app();
      firstTime = false;
    } catch (err) {
      // Doesn't exist, because it's our first time. This is completely normal.
      this.app = firebase.initializeApp(config);
    }
    this.auth = this.app.auth();
    this.readyPromise = new Promise<firebase.User | null>((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
    this.firestore = this.app.firestore();
    this.functions = this.app.functions(functionRegion);
    this.storage = this.app.storage();

    try {
      // this.messaging = this.app.messaging();
      if (firstTime) {
        // Trying to initialize the vapid key multiple times throws an error.
        // console.log(vapidKey);
        //this.messaging.usePublicVapidKey(vapidKey);
      }
    } catch (error) {
      console.log("gridbash messaging error", error);
    }
  }

  isReady() {
    return this.readyPromise;
  }

  /**
   * Requests a custom authentication token, which can then be used
   * to sign in the dashboard.
   */
  async getCustomToken(idToken: string, siteId?: string): Promise<string> {
    const response = await this.functions.httpsCallable("authenticate")({
      idToken,
      siteId,
    });
    return response.data;
  }

  /**
   * Used by an admin to invite an employee to join their site
   */
  async inviteEmployee(payload: InviteEmployeePayload) {
    return this.functions.httpsCallable("inviteEmployee")(payload);
  }

  /**
   * Queries whether the given email address has been invited
   *
   * We don't allow full access to the invitation collection, but this
   * amount of limited access is needed by the registration page to
   * know whether to let them create an account.
   */
  async invitationExists(payload: InvitationExistsPayload): Promise<boolean> {
    const result = await this.functions.httpsCallable("invitationExists")(
      payload
    );
    return result.data as boolean;
  }

  /**
   * Called by an employee to join the site
   */
  async acceptInvitation() {
    const payload: AcceptInvitationPayload = {};
    return this.functions.httpsCallable("acceptInvitation")(payload);
  }

  /**
   * Sets the role of an employee/admin/owner/customer
   */
  async setRole(payload: SetRolePayload) {
    return this.functions.httpsCallable("setRole")(payload);
  }
}
