import { FC } from "react";
import { useWidgetEnvironment } from "../widgetEnvironment";

export interface WidgetPickerWidgetProps {
  title: string;
}

const WidgetPickerWidget: FC<WidgetPickerWidgetProps> = () => {
  // Widget picker is a temporary widget during construction and is not meant to be
  //   deployed to the site side. If one does end up on the site, we render null.
  const env = useWidgetEnvironment();
  if (env === "preview") {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#f1f3f4",
          outline: "1px dashed rgba(0, 0, 0, 0.3)",
          padding: "8px",
        }}
      >
        Return to the editor to fill out this content
      </div>
    );
  } else {
    return null;
  }
};

export default WidgetPickerWidget;
