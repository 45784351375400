import { Fragment, FC } from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import { useSiteFirebase } from "../../../Firebase/context";

interface ConfirmSignOutProps {}

const ConfirmSignOut: FC<ConfirmSignOutProps> = () => {
  const { t } = useTranslation();
  const firebase = useSiteFirebase();

  return (
    <Fragment>
      <DialogTitle className="smartWidthMd">
        {t("store.account.signOut")}
      </DialogTitle>

      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("store.cancel")}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            firebase.signOut();
            history.go(-2);
          }}
        >
          {t("store.confirm")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default ConfirmSignOut;
