import { Ref, useMemo } from "react";

/**
 * When two pieces of code both need to pass a ref to the same element, this
 * hook will combine those refs together, so they both get what they need
 *
 * example:
 * ```typescript
 * const myRef = useRef<HTMLElement>(null);
 * const [measureRef, bounds] = useMeasure()
 * const combinedRef = useForkRef(myRef, measureRef);
 *
 * return (
 *   <div ref={combinedRef} />
 * )
 * ```
 */
export default function useForkRef(refA: null, refB: null): null;
export default function useForkRef<T>(
  refA: NonNullable<Ref<T>>,
  refB: Ref<T>
): (refValue: T) => void;
export default function useForkRef<T>(
  refA: Ref<T>,
  refB: NonNullable<Ref<T>>
): (refValue: T) => void;
export default function useForkRef<T>(refA: Ref<T>, refB: Ref<T>) {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue: T) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}

/**
 * Helper method for setting refs, when we don't know the type
 */
function setRef<T>(
  ref:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null
): void {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}
