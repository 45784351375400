import type firebase from "firebase";
import { createContext } from "react";
import { GridbashRole } from "../../database/userData";

export interface SiteUserState {
  user: firebase.User | null;
  role: GridbashRole | null;
  siteId: string | null;
  loading: boolean;
}

const SiteUserContext = createContext<SiteUserState | undefined>(undefined);

SiteUserContext.displayName = "Site User";

export default SiteUserContext;
