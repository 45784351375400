import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { FC, useState } from "react";
import * as React from "react";
import { UpDown } from "./transitions";
import { history } from "../../App/history";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogTitle from "@material-ui/core/DialogTitle";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useWindowResize from "../../../utilities/useWindowResize";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ForwardIcon from "@material-ui/icons/ArrowRight";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import Pricing from "./AppDetails/pricing";
import { Icon } from "@material-ui/core";

interface WhyGridBashProps {
  initPath: string;
  aboutRef: React.MutableRefObject<HTMLElement | null>;
  executeScroll: (ref: React.MutableRefObject<HTMLElement | null>) => void;
}

const WhyGridBash: FC<WhyGridBashProps> = (props) => {
  const location = useLocation();
  const isOpen = location.pathname.includes(props.initPath);
  return <InnerComponent {...props} isOpen={isOpen} />;
};

type InnerProps = WhyGridBashProps & { isOpen: boolean | undefined };

// Split into a separate component to improve performance
const InnerComponent: FC<InnerProps> = React.memo(
  ({ initPath, isOpen, executeScroll, aboutRef }) => {
    const [expanded, setExpanded] = useState("");
    const dimensions = useWindowResize("whyGridBash");
    const handleAccordion = (element: string) => {
      if (element === "mission") {
        if (expanded !== "mission") setExpanded("mission");
        else setExpanded("");
      } else if (element === "pricing") {
        if (expanded !== "pricing") setExpanded("pricing");
        else setExpanded("");
      } else if (element === "vision") {
        if (expanded !== "vision") setExpanded("vision");
        else setExpanded("");
      }
    };
    return (
      <React.Fragment>
        <MUIDialog route={`${initPath}/costs`}>
          <Pricing initPath={`${initPath}/costs`} />
        </MUIDialog>
        <Dialog
          fullWidth={dimensions.w >= 768 ? true : false}
          fullScreen={dimensions.w >= 768 ? false : true}
          open={isOpen ? true : false}
          TransitionComponent={UpDown}
          onClose={() => {
            history.goBack();
          }}
        >
          <DialogTitle
            disableTypography
            style={{ display: "flex", alignItems: "center" }}
          >
            <Icon>dashboard_customize</Icon>
            &nbsp;
            <Typography variant="h6" noWrap color="primary">
              Why GridBash?
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Accordion
              square
              expanded={expanded === "mission"}
              onChange={() => handleAccordion("mission")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  <Typography>Our Mission</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Reinforce Local Businesses
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Tahoma", fontWeight: "bold" }}
                  color="primary"
                >
                  Equip local businesses with a point-of-sale, including a
                  revolutionary website builder and online ordering platform
                </Typography>

                <Typography>
                  Provide local businesses with a free-of-charge sales
                  application that allows presenting, promoting, and selling
                  products online and/or in person. The website and online
                  ordering system are completely customizable without needing
                  technical knowledge, and can be placed on the Internet by
                  using their domain name, or one of ours.
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Tahoma", fontWeight: "bold" }}
                  color="primary"
                >
                  Include all features
                </Typography>
                <Typography>
                  Include all additional features within the sales application
                  without needing to install third-party components. For
                  instance, easily turn on or off communication features such as
                  SMS and/or Push by a simple toggle button.
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Tahoma", fontWeight: "bold" }}
                  color="primary"
                >
                  A commission-free marketplace
                </Typography>
                <Typography>
                  Offer a commission-free local-community marketplace which
                  lists all these businesses, maximizing their sales footprint
                  at no additional cost.
                  <br />
                  <br />
                  In the marketplace, customers can easily browse and find
                  products through its intuitive search, filter, sort and
                  favoring functionality.
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Tahoma", fontWeight: "bold" }}
                  color="primary"
                >
                  A commission-free delivery platform
                </Typography>

                <Typography>
                  Connect drivers and businesses through a delivery platform,
                  where drivers control earnings and businesses control delivery
                  costs. Businesses can list deliverables using our Lightning
                  Bid&trade; system, allowing economic principles of supply and
                  demand to set the price. Or, businesses and drivers can
                  connect directly, and collaborate by the terms they set. We
                  provide the technology without collecting commission.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "pricing"}
              onChange={() => handleAccordion("pricing")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  <Typography>Our Pricing</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Transparent and Rebellious
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                <Typography>
                  We offer the most transparent, rebellious price, period!
                  Compare our price to any existing Point-of-Sale software
                  and/or website service, and be totally blown away! Be aware,
                  most other services seem to offer competitive pricing at first
                  glance, but for every small additional feature, you pay extra,
                  resulting in a much higher total charge.
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Tahoma", fontWeight: "bold" }}
                  color="primary"
                >
                  Service Fee ($0 - $9.75)
                </Typography>
                <Typography>
                  Get rewarded for every sale you make! You receive a $0.05
                  credit for every sale towards a pre-charged $9.75 service fee.
                  That means upon reaching 195 sales, you get a full refund and
                  your application is ultimately <u>free of charge!</u> That
                  equates to only 6-7 sales per day.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  startIcon={<ForwardIcon />}
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const path = `${initPath}/costs`;
                    if (!window.location.pathname.includes(path))
                      history.push(path);
                  }}
                >
                  Determine If It's Free
                </Button>
                <br />
                <br />
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Tahoma", fontWeight: "bold" }}
                  color="primary"
                >
                  Communication Fees (optional)
                </Typography>
                <Typography>
                  Push notifications: <u>unlimited for free</u>.
                </Typography>
                <Typography>
                  SMS: only <u>$0.01 per SMS</u>.
                </Typography>
                <Typography>Email:</Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <Typography>
                      <u>Unlimited free</u> standard receipt emails.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <u>1000 free</u> custom-domain emails (use them for
                      receipts or any other business communication). Pay only{" "}
                      <u>$0.0005 per email</u> after that.
                    </Typography>
                  </li>
                </ul>
                <br />
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Tahoma", fontWeight: "bold" }}
                  color="primary"
                >
                  Domain Name Fee (optional)
                </Typography>
                <Typography>
                  Your website is always delivered securely to the Internet
                  using a Google certified SSL certificate <u>for free</u>.
                </Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <Typography>
                      Bring your existing domain hosted anywhere you desire,{" "}
                      <u>free of charge</u>. You take care of the domain’s
                      annual service fee on your end. Both your website and
                      emails will use this domain name.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Or, acquire a new domain through us. Non-primary domains{" "}
                      <u>typically cost $12 per year</u>. Both your website and
                      emails will use this domain name.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Or, use our domain <u>for free</u>. You can choose the
                      desired suffix, for example
                      https://gridbash.com/yourbizzname.
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "vision"}
              onChange={() => handleAccordion("vision")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  <Typography>Our Vision</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Advance Together
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                <Typography>
                  To accomplish our mission of aiding local businesses in
                  becoming and remaining competitive in today's volatile
                  reality, we continuously strive to develop our application in
                  the most efficient way possible, while keeping quality at the
                  core of everything we build.
                </Typography>

                <Button
                  startIcon={<ForwardIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.goBack();
                    setTimeout(() => {
                      executeScroll(aboutRef);
                    }, 300);
                  }}
                >
                  Read Founders' note
                </Button>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.goBack();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
);

export default WhyGridBash;
