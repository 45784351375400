import { Icon, Typography, TypographyProps } from "@material-ui/core";
import { forwardRef, useEffect } from "react";
import { useSiteSettings } from "../../../../customization/siteSettingsContext";
import { FancyText, fancyTextDefaults } from "../../../../database/fancyText";
import { loadFonts } from "../../../../utilities/fontLoader";

interface FancyTextComponentProps extends TypographyProps {
  value: FancyText;
  button?: boolean;
}

export const flexHorizAlign = new Map<string, string>();
flexHorizAlign.set("left", "flex-start");
flexHorizAlign.set("right", "flex-end");
flexHorizAlign.set("justify", "space-between");
flexHorizAlign.set("center", "center");

const FancyTextComponent = forwardRef<HTMLDivElement, FancyTextComponentProps>(
  ({ value, button }, ref) => {
    const {
      text,
      textColor = fancyTextDefaults.textColor,
      backgroundColor = fancyTextDefaults.backgroundColor,
      fontFamily,
      fontSize = fancyTextDefaults.fontSize,
      bold,
      italic,
      strikeThrough,
      underline,
      borderWidth = fancyTextDefaults.borderWidth,
      borderStyle = fancyTextDefaults.borderStyle,
      borderColor = fancyTextDefaults.borderColor,
      borderTopLeftRadius = fancyTextDefaults.borderTopLeftRadius,
      borderTopRightRadius = fancyTextDefaults.borderTopRightRadius,
      borderBottomLeftRadius = fancyTextDefaults.borderBottomLeftRadius,
      borderBottomRightRadius = fancyTextDefaults.borderBottomRightRadius,
      paddingLeft = fancyTextDefaults.paddingLeft,
      paddingRight = fancyTextDefaults.paddingRight,
      paddingTop = fancyTextDefaults.paddingTop,
      paddingBottom = fancyTextDefaults.paddingBottom,
      fullWidth,
      align,
      icon,
      boxShadowX,
      boxShadowY,
      boxShadowBlur,
      boxShadowColor,
      boxShadowSpread,
      textShadowBlur,
      textShadowColor,
      textShadowX,
      textShadowY,
      marginRight,
    } = value;

    useEffect(() => {
      if (fontFamily) {
        // Ideally, the font is in the site settings and we've already loaded it,
        // but in case we missed it, load it now.
        loadFonts(fontFamily);
      }
    }, [fontFamily]);

    const { theme } = useSiteSettings();

    const boxShadowUsed: boolean =
      (typeof boxShadowBlur === "number" && boxShadowBlur > 0) ||
      (typeof boxShadowSpread === "number" && boxShadowSpread > 0) ||
      (typeof boxShadowX === "number" && boxShadowX !== 0) ||
      (typeof boxShadowY === "number" && boxShadowY !== 0);

    const textShadowUsed: boolean =
      (typeof textShadowBlur === "number" && textShadowBlur > 0) ||
      (typeof textShadowX === "number" && textShadowX !== 0) ||
      (typeof textShadowY === "number" && textShadowY !== 0);

    let textDecoration: string | undefined;
    if (strikeThrough && underline) {
      textDecoration = "line-through underline";
    } else if (strikeThrough) {
      textDecoration = "line-through";
    } else if (underline) {
      textDecoration = "underline";
    }

    // console.log("fancyTextComponent", align, icon, text);

    return icon && !text ? (
      <div
        ref={ref}
        style={{
          display: fullWidth ? "flex" : "inline-flex",
          width: fullWidth ? "100%" : undefined,
          alignItems: "center",
          justifyContent: align ? flexHorizAlign.get(align) : undefined,
          border: `${borderWidth ?? 1}px ${borderStyle ?? "solid"} ${
            borderColor ?? "#000000"
          }`,
          borderTopLeftRadius: `${borderTopLeftRadius}px`,
          borderTopRightRadius: `${borderTopRightRadius}px`,
          borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
          borderBottomRightRadius: `${borderBottomRightRadius}px`,
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
          paddingTop: `${paddingTop}px`,
          paddingBottom: `${paddingBottom}px`,
          backgroundColor: backgroundColor,
          boxShadow: boxShadowUsed
            ? `${boxShadowX ?? 0}px ${boxShadowY ?? 0}px ${
                boxShadowBlur ?? 0
              }px ${boxShadowSpread ?? 0}px ${boxShadowColor ?? "black"}`
            : undefined,
        }}
      >
        <Icon
          className={`gb-font-${fontSize}`}
          style={{
            color: textColor,
            textShadow: textShadowUsed
              ? `${textShadowX ?? 0}px ${textShadowY ?? 0}px ${
                  textShadowBlur ?? 0
                }px ${textShadowColor ?? "black"}`
              : undefined,
          }}
        >
          {icon}
        </Icon>
      </div>
    ) : (
      <div
        ref={ref}
        className={button ? "buttonHover" : undefined}
        style={{
          display: value.icon
            ? !fullWidth
              ? "inline-flex"
              : "flex"
            : !fullWidth
            ? "inline-block"
            : "block",

          alignItems: "center",
          justifyContent: value.align && flexHorizAlign.get(value.align),
          border: `${borderWidth ?? 1}px ${borderStyle ?? "solid"} ${
            borderColor ?? "#000000"
          }`,
          borderTopLeftRadius: `${borderTopLeftRadius}px`,
          borderTopRightRadius: `${borderTopRightRadius}px`,
          borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
          borderBottomRightRadius: `${borderBottomRightRadius}px`,
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
          paddingTop: `${paddingTop}px`,
          paddingBottom: `${paddingBottom}px`,
          marginRight: `${marginRight}px`,
          userSelect: "none",
          maxWidth: "100%",
          width: fullWidth ? "100%" : undefined,
          color: textColor,
          backgroundColor: backgroundColor,
          boxShadow: boxShadowUsed
            ? `${boxShadowX ?? 0}px ${boxShadowY ?? 0}px ${
                boxShadowBlur ?? 0
              }px ${boxShadowSpread ?? 0}px ${boxShadowColor ?? "black"}`
            : undefined,
        }}
      >
        {value.icon && value.iconPosition === "left" && (
          <Icon
            className={`gb-font-${fontSize}`}
            style={{
              marginRight: "0.5em",
              textShadow: textShadowUsed
                ? `${textShadowX ?? 0}px ${textShadowY ?? 0}px ${
                    textShadowBlur ?? 0
                  }px ${textShadowColor ?? "black"}`
                : undefined,
            }}
          >
            {value.icon}
          </Icon>
        )}
        <Typography
          className={`gb-font-${fontSize}`}
          noWrap
          align={align}
          style={{
            fontFamily: fontFamily ?? theme.appFont,
            fontWeight: bold ? "bold" : "normal",
            fontStyle: italic ? "italic" : "normal",
            textShadow: textShadowUsed
              ? `${textShadowX ?? 0}px ${textShadowY ?? 0}px ${
                  textShadowBlur ?? 0
                }px ${textShadowColor ?? "black"}`
              : undefined,
            textDecoration,
          }}
        >
          {text}
        </Typography>

        {value.icon && value.iconPosition === "right" && (
          <Icon
            className={`gb-font-${fontSize}`}
            style={{
              marginLeft: "0.5em",
              textShadow: textShadowUsed
                ? `${textShadowX ?? 0}px ${textShadowY ?? 0}px ${
                    textShadowBlur ?? 0
                  }px ${textShadowColor ?? "black"}`
                : undefined,
            }}
          >
            {value.icon}
          </Icon>
        )}
      </div>
    );
  }
);

export default FancyTextComponent;
