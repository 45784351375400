import { Fragment, FC, useMemo, useState } from "react";

import IconButton from "@material-ui/core/IconButton";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Order, orderStatus, revisionStatus } from "../../../database/order";
import Dompurify from "dompurify";
import MessageIcon from "@material-ui/icons/Message";
import { useMessageThread } from "../../../utilities/useMessages";
import { Message } from "../../../database/message";
import MessageUnreadIcon from "@material-ui/icons/Announcement";
import { history } from "../../App/history";
import MUIDialog, {
  MUIDialogProps,
} from "../../Dashboard/Components/mui-dialog";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Timeline from "./timeline";
import CheckoutIcon from "@material-ui/icons/CreditCard";
import ROUTES from "../../../utilities/constants/routes";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { addressToString } from "../../../utilities/addressToString";
import HomeIcon from "@material-ui/icons/Home";
import BizzIcon from "@material-ui/icons/Business";
import NavigateIcon from "@material-ui/icons/Directions";
import TrackingDetailCartItem from "./trackingDetailCartItem";
import InfoIcon from "@material-ui/icons/Info";
import useToast from "../../Main/useToast";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

interface DetailsProps {
  order: Order;
  orderIdString: string;
  isPast: boolean;
  initPath: string;
  orderAgain: () => Promise<void>;
}

const Details: FC<DetailsProps> = ({
  isPast,
  order,
  orderIdString,
  initPath,
  orderAgain,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { thread } = useMessageThread(order.messageThreadId);
  const newMessage = thread
    ? Object.values(thread.messages).some(
        (message: Message) => message.from === "admin" && !message.read
      )
    : false;

  const sortedItems = useMemo(() => {
    // Don't really care what order they're in, just that it's consistent
    //   if we get updated data from the server.
    return Object.values(order.cumulativeCartItems).sort((a, b) =>
      a.cartItemId.localeCompare(b.cartItemId)
    );
  }, [order.cumulativeCartItems]);

  const [dialogProps, setDialogProps] = useState<MUIDialogProps>();

  const latestRevision = order.revisions[order.revisions.length - 1];
  const needsPaymentAuth =
    latestRevision.status === revisionStatus.pendingauth ||
    latestRevision.status === revisionStatus.lockedForPayment;
  const needsAddress = order.delivery.delivery && !order.recipient.address;

  const { address } = useSiteSettings();
  return (
    <Fragment>
      {dialogProps && <MUIDialog {...dialogProps} />}
      <DialogTitle className="smartWidthLg" style={{ paddingBottom: 0 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <div style={{ minWidth: 0 }}>
            <Typography
              noWrap
              variant="body2"
              component="div"
              align="left"
              style={{
                fontFamily: "Arial",
              }}
            >
              <div
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                dangerouslySetInnerHTML={{
                  __html: Dompurify.sanitize(orderIdString),
                }}
              />
            </Typography>
            {needsPaymentAuth ? (
              <Typography variant="caption" component="div" color="secondary">
                {t("store.track.newCharge")}
              </Typography>
            ) : (
              newMessage && (
                <Typography variant="caption" component="div" color="secondary">
                  {t("store.orders.newMessage")}
                </Typography>
              )
            )}
          </div>
          <IconButton
            color={newMessage ? "secondary" : "primary"}
            onClick={async (e) => {
              e.stopPropagation();
              history.push(window.location.pathname, {
                messages: true,
                threadId: order.messageThreadId,
              });
            }}
          >
            {thread && newMessage ? <MessageUnreadIcon /> : <MessageIcon />}
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent
        className="smartWidthLg"
        style={{
          paddingLeft: "max(1.5vw, 1em)",
          paddingRight: "max(1.5vw, 1em)",
        }}
      >
        <div style={{ marginBottom: "max(1vw, 1em)" }}>
          {(needsPaymentAuth || needsAddress) && (
            <div>
              <Typography color="secondary">
                {t("store.orders.yourAttention")}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                startIcon={
                  needsPaymentAuth ? <CheckoutIcon /> : <SettingsIcon />
                }
                onClick={() => {
                  history.push(`${ROUTES.PAYMENTAUTH}/${order.orderId}`, {
                    initialOrder: order,
                    successUrl: `${window.location.origin}${initPath}?stripe=true`,
                    goBackOnCancel: true,
                  });
                }}
              >
                {needsPaymentAuth
                  ? t("store.orders.reviewAndAuthorize")
                  : t("store.orders.chooseDeliveryOptions")}
              </Button>
            </div>
          )}
        </div>
        <Timeline order={order} isPast={isPast} />
        {order.status !== orderStatus.delivered && !needsAddress && (
          <div style={{ padding: "0.5em", marginTop: "max(1em, 1vw)" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {order.delivery.delivery ? (
                <HomeIcon color="primary" />
              ) : (
                <BizzIcon color="primary" />
              )}
              &nbsp;&nbsp;
              <Typography color="primary">
                {order.delivery.delivery
                  ? t("store.orders.deliveryAddress")
                  : t("store.orders.pickupAddress")}
              </Typography>
            </div>
            <div
              style={{
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordBreak: "break-word",
              }}
            >
              {!order.delivery.delivery ? (
                <Fragment>
                  <Typography>
                    <span>{address.streetLine1.toUpperCase()}</span>
                    {address.streetLine2 && (
                      <span>&nbsp;{address.streetLine2.toUpperCase()}</span>
                    )}
                    {address.streetLine3 && (
                      <span>&nbsp;{address.streetLine3.toUpperCase()}</span>
                    )}
                  </Typography>
                  <Typography>
                    <span>{address.city.toUpperCase()}</span>
                    {address.stateCode && (
                      <span>
                        &nbsp;
                        {t(
                          `store.account.state.${address.stateCode}`
                        ).toUpperCase()}
                      </span>
                    )}
                    <span>&nbsp;{address.zip}</span>
                  </Typography>
                </Fragment>
              ) : (
                <Typography>
                  {addressToString(order.recipient.address, t)}
                </Typography>
              )}
            </div>
            <Button
              startIcon={<NavigateIcon />}
              color="primary"
              onClick={(e) => {
                if (!window.location.pathname.includes("navigate")) {
                  history.push(`${initPath}/navigate`);
                }
                setDialogProps({
                  route: `${initPath}/navigate`,

                  children: (
                    <Fragment>
                      <DialogTitle className="smartWidthMd">
                        {t("store.orders.openGoogleMaps")}
                      </DialogTitle>
                      <DialogActions className="smartWidthMd">
                        <Button onClick={() => history.goBack()}>
                          {t("store.cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            history.goBack();
                            if (!order.delivery.delivery) {
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${address.googleMaps}`
                              );
                            } else {
                              if (!order.recipient.address) return;
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${encodeURI(
                                  addressToString(order.recipient.address, t)
                                )}`
                              );
                            }
                          }}
                        >
                          {t("store.proceed")}
                        </Button>
                      </DialogActions>
                    </Fragment>
                  ),
                });
              }}
            >
              {order.delivery.delivery
                ? t("store.orders.verify")
                : t("store.orders.navigate")}
            </Button>
            &nbsp;
            <IconButton
              onClick={(e) => {
                e.stopPropagation();

                toast({
                  dialog: true,
                  message: t("store.orders.avoidDelays"),
                  color: "normal",
                });
              }}
            >
              <InfoIcon />
            </IconButton>
          </div>
        )}
        <div style={{ marginTop: "max(1em, 1vw)" }} />
        {sortedItems.map((cartItem, index) => (
          <TrackingDetailCartItem
            key={cartItem.cartItemId}
            index={index}
            cartItem={cartItem}
            initPath={initPath}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            orderAgain();
          }}
          startIcon={<ArrowRightIcon />}
        >
          {t("store.orders.reOrder")}
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("store.close")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Details;
