import { useState, useEffect } from "react";
import { SiteSettings } from "../../database/siteSettings";
import { RawStore } from "../../database/store";
import { useSiteFirebase } from "../../Firebase/context";
import { storeKey } from "../../Firebase/siteFirebase";
import { initDinero } from "../../utilities/orderProcessing";
import { WEBFONTS } from "../../utilities/constants/appConstants";
import { loadFonts } from "../../utilities/fontLoader";
import { ensureUniqueRoutes } from "../../utilities/constants/routes";
import { OrderCell } from "../../database/orderPage";
import uuid from "uuid/v4";
import { unstable_batchedUpdates } from "react-dom";

/**
 * Loads and initializes the relevant data for displaying the site
 *
 * Specifically:
 * * fetches and listens to site settings
 * * initializes Dinero.js, using the currency settings
 * * preloads any fonts used in the site settings
 * * Waits for the signal from SiteFirebase that it's ready
 * */
export const useLoadSite = () => {
  const [siteSettings, setSiteSettings] = useState<SiteSettings>();
  const [rawSiteSettings, setRawSiteSettings] = useState<SiteSettings>();
  const [assetsPreloaded, setAssetsPreloaded] = useState(false);
  const [rawStore, setRawStore] = useState<RawStore>();
  const [firebaseReady, setFirebaseReady] = useState(false);
  const siteFirebase = useSiteFirebase();

  useEffect(() => {
    let unmounted = false;
    siteFirebase.isReady().then(() => {
      if (!unmounted) {
        setFirebaseReady(true);
      }
    });
    return () => {
      unmounted = true;
    };
  }, [siteFirebase]);

  useEffect(() => {
    let unmounted = false;
    let unsubscribeLocation: (() => void) | undefined;
    const unsubscribeStore = siteFirebase.firestore
      .collection("stores")
      .doc(storeKey)
      .onSnapshot(async (storeDoc) => {
        if (storeDoc.exists) {
          let storeData = storeDoc.data() as RawStore;
          setRawStore(storeData);
          if (storeData.locations.length === 1) {
            const locationId = storeData.locations[0].id;
            //IF ONLY ONE LOCATION, RETRIEVE ALL DATA
            unsubscribeLocation = siteFirebase.firestore
              .collection("stores")
              .doc(storeKey)
              .collection("locations")
              .doc(locationId)
              .onSnapshot(
                async (locationDoc) => {
                  let siteSettings = locationDoc.data() as SiteSettings;
                  unstable_batchedUpdates(() => {
                    setRawSiteSettings(siteSettings);
                    siteSettings = ensureUniqueRoutes(siteSettings);
                    initDinero(siteSettings);

                    // TODO: remove this code. It's only here because the database
                    //    hasn't yet been updated to follow the newest data format
                    siteSettings = {
                      ...siteSettings,
                      orderPageOptions: {
                        ...siteSettings.orderPageOptions,
                        props: {
                          ...siteSettings.orderPageOptions.props,
                          itemProps: {
                            ...siteSettings.orderPageOptions.props.itemProps,
                            cells:
                              siteSettings.orderPageOptions.props.itemProps.cells.map(
                                (cell) => {
                                  const newCell: OrderCell = {
                                    //@ts-ignore
                                    id: uuid(),
                                    //@ts-ignore
                                    border: {
                                      thickness: 1,
                                      style: "solid",
                                      radius: 4,
                                      color: { literal: "#000000" },
                                    },
                                    ...cell,
                                  };

                                  return newCell;
                                }
                              ),
                          },
                        },
                      },
                    };

                    setSiteSettings(siteSettings);
                  });

                  try {
                    await loadSiteFonts(siteSettings);
                  } catch (ex) {
                    console.error("error preloading", ex);
                  } finally {
                    if (!unmounted) {
                      setAssetsPreloaded(true);
                    }
                  }
                },
                (err) => {
                  console.error("Location data could not be loaded.", err);
                }
              );
          } else {
            //IF MULTIPLE LOCATIONS, PROMPT USER
            //TO PICK LOCATION DURING ORDER OR MENU CLICK
          }
        } else {
          console.error("Restaurant data could not be loaded.");
        }
      });
    return () => {
      unsubscribeStore();
      unsubscribeLocation?.();
      unmounted = true;
    };
  }, [siteFirebase]);

  return {
    siteSettings,
    rawSiteSettings,
    rawStore,
    assetsPreloaded,
    firebaseReady,
  };
};

/**
 * Load all necessary fonts that are found in the theme
 */
const loadSiteFonts = (siteSettings: SiteSettings) => {
  const googleFontFamilies = findFontFamilies(siteSettings).filter(
    (family) => !WEBFONTS.includes(family)
  );

  if (googleFontFamilies.length === 0) {
    return Promise.resolve();
  }

  return loadFonts(googleFontFamilies);
};

/**
 * Recursively look through the location for any properties named
 * appFont or fontFamily and aggregate them into an array.
 */
const findFontFamilies = (
  candidate: any,
  families: string[] = []
): string[] => {
  if (typeof candidate === "object" && candidate !== null) {
    Object.entries(candidate).forEach(([key, val]) => {
      if (
        (key === "appFont" || key === "fontFamily") &&
        typeof val === "string"
      ) {
        families.push(val);
      } else {
        findFontFamilies(val, families);
      }
    });
  }
  return families;
};
