import IconButton from "@material-ui/core/IconButton";
import { FC, useEffect, useState } from "react";
import * as React from "react";

import { history } from "../../App/history";
import ContactIcon from "@material-ui/icons/ContactSupport";
import useGridbashUser from "../../UserProvider/useGridbashUser";
import GridbashAuth from "../gridbashAuth";
import ROUTES from "../../../utilities/constants/routes";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Typography from "@material-ui/core/Typography";
import Contact from "./contact";
import MUIDialog, {
  MUIDialogProps,
} from "../../Dashboard/Components/mui-dialog";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SignInIcon from "@material-ui/icons/HowToReg";
import Icon from "@material-ui/core/Icon";

interface headerProps {
  headerRef: React.MutableRefObject<HTMLDivElement | null>;
}

const Header: FC<headerProps> = ({ headerRef }) => {
  const { user } = useGridbashUser();
  const [dialogProps, setDialogProps] = useState<MUIDialogProps>();

  const [shadow, setShadow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setShadow(window.scrollY !== 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <React.Fragment>
      {dialogProps && <MUIDialog {...dialogProps} />}
      <MUIDialog route={ROUTES.ML_CONTACT}>
        <Contact user={user} />
      </MUIDialog>
      <div
        ref={headerRef}
        className="sticky"
        style={{
          top: 0,
          padding: "max(0.5em,1.5vw)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 100,
          backgroundColor: "white",
          transition: "all 0.3s ease",
          boxShadow: shadow
            ? "0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3"
            : undefined,
        }}
      >
        <div
          onClick={
            user
              ? (e) => {
                  e.stopPropagation();
                  history.push(ROUTES.DASHBOARD);
                }
              : (e) => {
                  e.stopPropagation();
                }
          }
          style={{ display: "flex", alignItems: "center" }}
        >
          <Icon>dashboard_customize</Icon>
          &nbsp;
          <Typography variant="h6" noWrap={true}>
            GridBash
          </Typography>
        </div>
        <div style={{ flexGrow: 1 }} />
        {process.env.NODE_ENV === "development" && (
          <React.Fragment>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                history.push("/project6");
              }}
            >
              <ExitToAppIcon />
            </IconButton>
            <div style={{ marginRight: "1vw" }} />
          </React.Fragment>
        )}
        <IconButton
          onClick={() => {
            if (!window.location.pathname.includes("message")) {
              history.push(ROUTES.ML_CONTACT);
            }
          }}
        >
          <ContactIcon />
        </IconButton>
        <div style={{ marginRight: "1vw" }} />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (user) {
              history.push(ROUTES.DASHBOARD_OVERVIEW);
            } else {
              const path = "/auth";
              if (!window.location.pathname.includes(path))
                history.push("/auth");

              setDialogProps({
                route: path,
                children: <GridbashAuth initPath="/auth" />,
              });
            }
          }}
        >
          {user ? <DashboardIcon /> : <SignInIcon />}
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default Header;
