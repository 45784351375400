import { FC, useState, useEffect, useRef } from "react";
import * as React from "react";
import * as R from "ramda";
import "./global.css";
import Header from "./header";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import AreYouInCarousel from "./areYouInCarousel";
import { loadFonts } from "../../../utilities/fontLoader";
import ApplicationForm from "./Apply/applicationForm";
import ApplyCarousel from "./Apply/applyCarousel";
import HowItWorks from "./howItWorks";
import FeaturesItems from "./AppDetails/featuresItems";
import Hardware from "./AppDetails/hardware";
import About from "./about";
import Hero from "./hero";
import { Typography } from "@material-ui/core";
import { useGridBashFirebase } from "../../../Firebase/context";
import { Application, ApplicationInDatabase } from "../../../database/signup";
import Pricing from "./AppDetails/pricing";
import WhyGridbash from "./whyGridbash";

interface HomeProps {}

export const blankApplication: Application = {
  email: "",
  name: "",
  phone: "",
  companyName: "",
  companyZip: "",
  termsAccepted: false,
  question1: null,
  feedback1: "",
  question2: null,
  feedback2: "",
  question3: null,
  feedback3: "",
};

const Home: FC<HomeProps> = React.memo(() => {
  const firebase = useGridBashFirebase();
  // const [assetsPreloaded, setAssetsPreloaded] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        await loadFonts(["Roboto", "Roboto:700"]);
      } catch (ex) {
        console.error("error preloading", ex);
      }
      // finally {
      //   setAssetsPreloaded(true);
      // }
    })();
  }, []);

  const headerRef = useRef<HTMLDivElement | null>(null);

  const [application, setApplication] = useState<Application>(blankApplication);
  const [docId, setDocId] = useState(
    () => firebase.firestore.collection("applications").doc().id
  );

  const aboutRef = useRef<HTMLElement | null>(null);
  const executeScroll = (ref: React.MutableRefObject<HTMLElement | null>) => {
    const target = ref.current;
    if (!target) return;
    console.log("home", target);
    target.scrollIntoView();
  };

  useEffect(() => {
    if (!R.equals(application, blankApplication)) {
      // When they change their feedback, write it to the database after a debounce
      const timerId = setTimeout(async () => {
        const databaseEntry: ApplicationInDatabase = {
          timestamp: Date.now(),
          ...application,
        };
        try {
          await firebase.firestore
            .collection("applications")
            .doc(docId)
            .set(databaseEntry);
        } catch (err) {}
      }, 3000);
      return () => clearTimeout(timerId);
    }
  }, [application, docId, firebase.firestore]);

  return (
    <React.Fragment>
      <MUIDialog route="/costs">
        <Pricing initPath="/costs" />
      </MUIDialog>
      <MUIDialog route="/apply">
        <ApplicationForm
          application={application}
          setApplication={setApplication}
          docId={docId}
          setDocId={setDocId}
          initPath="/apply"
        />
      </MUIDialog>
      <WhyGridbash
        initPath={"/why-gridbash"}
        executeScroll={executeScroll}
        aboutRef={aboutRef}
      />
      <AreYouInCarousel
        application={application}
        setApplication={setApplication}
        initPath={""}
      />
      <ApplyCarousel
        application={application}
        setApplication={setApplication}
        docId={docId}
        setDocId={setDocId}
        initPath=""
      />
      <Header headerRef={headerRef} />

      <main role="main">
        <section>
          <Hero />
        </section>
        <section
          style={{
            marginTop: "60px",
            marginBottom: "80px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            overflow: "hidden",
            maxWidth: "1280px",
            position: "relative",
          }}
        >
          <HowItWorks initPath="" />
        </section>
        <section
          style={{
            marginTop: "80px",
            marginBottom: "80px",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <FeaturesItems />
        </section>
        <section>
          <Hardware />
        </section>
        <section ref={aboutRef} style={{ paddingTop: "70px" }}>
          <About />
        </section>
        <Typography variant="caption">
          GridBash Inc. {new Date().getFullYear()}
        </Typography>
      </main>
    </React.Fragment>
  );
});

export default Home;
