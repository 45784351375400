import { useEffect, useMemo, useState } from "react";
import { CatalogItem_Database } from "../database/catalogItem";
import { useSiteFirebase } from "../Firebase/context";
import { storeKey } from "../Firebase/siteFirebase";
import { convertItemAndAddDefaults } from "../utilities/typeConversions";
import { useSiteSettings } from "./siteSettingsContext";

/**
 * Fetches a catalog item from the database (if necessary), and
 * applies default values to it. Used on the store side.
 */
export const useFetchItem = (
  itemId: string | null,
  initialValue?: CatalogItem_Database
) => {
  const firebase = useSiteFirebase();
  const { labels, taxPercentage, priceIncludesTax, currency } =
    useSiteSettings();

  const [itemInDb, setItemInDb] = useState<CatalogItem_Database | undefined>(
    initialValue
  );
  const [loading, setLoading] = useState(Boolean(itemId));
  useEffect(() => {
    if (!itemId) {
      return;
    }
    const unsubscribe = firebase.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("items")
      .doc(itemId)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data() as CatalogItem_Database | undefined;
          setItemInDb(data);
          setLoading(false);
        },
        (err) => {
          if (err.code === "permission-denied") {
            // This is uncommon, but a valid case. The item has been hidden or removed from
            //   the catalog, and so the customer is not allowed to access it.
          } else {
            console.error("error fetching item", err);
          }
          setItemInDb(undefined);
          setLoading(false);
        }
      );
    return unsubscribe;
  }, [firebase.firestore, itemId]);

  // Fill in default values to make it easier to work with
  const itemWithDefaults = useMemo(() => {
    return (
      itemInDb &&
      convertItemAndAddDefaults(
        itemInDb,
        labels,
        taxPercentage,
        priceIncludesTax,
        currency
      )
    );
  }, [currency, itemInDb, priceIncludesTax, labels, taxPercentage]);

  return {
    item: itemWithDefaults,
    loading,
  };
};
