import * as R from "ramda";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import {
  useLocationId,
  useSiteSettings,
} from "../../../../../customization/siteSettingsContext";
import { PageAggregation, PageConfig } from "../../../../../database/page";
import { SiteSettings } from "../../../../../database/siteSettings";
import { useSiteFirebase } from "../../../../../Firebase/context";
import { storeKey } from "../../../../../Firebase/siteFirebase";
import { TOASTDURATION_ERROR } from "../../../../../utilities/constants/appConstants";
import { deleteUndefinedsRecursive } from "../../../../../utilities/deleteUndefineds";
import useToast from "../../../../Main/useToast";
import useSiteUser from "../../../../UserProvider/useSiteUser";
import { EditHistory } from "./pageApiProvider";
import { PageState } from "./reducer";

interface Options {
  pageSummariesInDb: PageAggregation;
  pagesInDb: { [pageId: string]: PageConfig };
  pageState: PageState;
  setEditHistory: Dispatch<SetStateAction<EditHistory>>;
  setRestoredStates: Dispatch<SetStateAction<(PageState | undefined)[]>>;
  setBackupStatus: Dispatch<
    SetStateAction<"saving" | "saved" | "restored" | null>
  >;
  // During a publish, this receives a promise that will resolve once the publish is done
  publishPromise: MutableRefObject<Promise<void> | undefined>;
}

/**
 * Helper hook to split up the functionality of pageApiProvider.
 * This implements the functions for publishing or reverting the changes.
 */
export const usePublishPages = ({
  pageSummariesInDb,
  pagesInDb,
  pageState,
  setEditHistory,
  setRestoredStates,
  setBackupStatus,
  publishPromise,
}: Options) => {
  const { user } = useSiteUser();
  const { customWidgets: customWidgetsInDb, pageSettings: pageSettingsInDb } =
    useSiteSettings();
  const siteFirebase = useSiteFirebase();
  const locationId = useLocationId();
  const { t } = useTranslation();
  const toast = useToast();

  const dirty = useMemo(() => {
    return (
      !R.equals(pageSettingsInDb, pageState.pageSettings) ||
      !R.equals(pageSummariesInDb, pageState.summaries) ||
      Object.entries(pageState.customWidgets).some(
        ([id, widget]) => !R.equals(widget, customWidgetsInDb[id])
      ) ||
      Object.values(pageState.pages).some(
        (page) => !R.equals(page, pagesInDb[page.pageId])
      )
    );
  }, [
    customWidgetsInDb,
    pageSettingsInDb,
    pageState,
    pageSummariesInDb,
    pagesInDb,
  ]);

  const revert = useCallback(() => {
    unstable_batchedUpdates(() => {
      setEditHistory({
        actions: [],
        index: -1,
      });
      setRestoredStates([]);
      setBackupStatus(null);
    });
    if (user?.uid) {
      siteFirebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("users")
        .doc(user?.uid)
        .update({
          pageWorkInProgress: siteFirebase.FieldValue.delete(),
        })
        .catch((err) => {
          console.log("error clearing user data", err);
        });
    }
  }, [
    setBackupStatus,
    setEditHistory,
    setRestoredStates,
    siteFirebase.FieldValue,
    siteFirebase.firestore,
    user?.uid,
  ]);

  const publish = useCallback(async () => {
    if (!dirty) {
      return;
    }
    let resolve: () => void = () => {};
    // This promise is used to temporarily halt loading new data in pageApiProvider
    publishPromise.current = new Promise((r) => (resolve = r));
    try {
      const lastEdit = {
        userId: user?.uid ?? "",
        timestamp: Date.now(),
      };

      const batch = siteFirebase.firestore.batch();
      Object.values(pageState.pages).forEach((page) => {
        if (!R.equals(page, pagesInDb[page.pageId])) {
          batch.set(
            siteFirebase.firestore
              .collection("stores")
              .doc(storeKey)
              .collection("pages")
              .doc(page.pageId),
            deleteUndefinedsRecursive({
              ...page,
              lastEdit,
            })
          );
        }
      });
      Object.keys(pagesInDb).forEach((pageId) => {
        if (!pageState.pages[pageId]) {
          // The page has been deleted locally
          batch.delete(
            siteFirebase.firestore
              .collection("stores")
              .doc(storeKey)
              .collection("pages")
              .doc(pageId)
          );
        }
      });
      let newSiteSettings: Partial<SiteSettings> = {};
      if (!R.equals(pageState.customWidgets, customWidgetsInDb)) {
        newSiteSettings.customWidgets = pageState.customWidgets;
        newSiteSettings = deleteUndefinedsRecursive(newSiteSettings);
      }
      if (!R.equals(pageState.pageSettings, pageSettingsInDb)) {
        newSiteSettings.pageSettings = deleteUndefinedsRecursive(
          pageState.pageSettings
        );
      }

      if (!R.equals(pageState.summaries, pageSummariesInDb)) {
        const newSummaries: PageAggregation = deleteUndefinedsRecursive({
          ...pageState.summaries,
          lastEdit,
        });
        batch.set(
          siteFirebase.firestore
            .collection("stores")
            .doc(storeKey)
            .collection("aggregations")
            .doc("pages"),
          newSummaries
        );
        newSiteSettings = deleteUndefinedsRecursive({
          ...newSiteSettings,
          navigation: pageState.summaries.navigation,
          pages: pageState.summaries.pages
            .filter((page) => !page.hideFromNavigation)
            .map((page) => {
              const path =
                "/" + page.name.toLowerCase().replace(/[^a-z0-9]/gi, "");
              return {
                pageId: page.pageId,
                name: page.name,
                icon: page.icon,
                path,
              };
            }),
        });
      }

      if (Object.keys(newSiteSettings).length > 0) {
        batch.update(
          siteFirebase.firestore
            .collection("stores")
            .doc(storeKey)
            .collection("locations")
            .doc(locationId),
          newSiteSettings
        );
      }

      await batch.commit();
      revert();

      toast({
        message: t("dashboard.toast.successPublish"),
        duration: 2000,
      });
    } catch (err) {
      if (err.code === "permission-denied") {
        toast({
          dialog: true,
          color: "error",
          message: t("dashboard.settings.permissionDenied"),
          duration: TOASTDURATION_ERROR,
        });
      } else {
        console.log("error publishing settings", err);
        toast({
          dialog: true,
          color: "error",
          message: t("toast.systemError"),
          duration: TOASTDURATION_ERROR,
        });
      }
    } finally {
      resolve();
      publishPromise.current = undefined;
    }
  }, [
    customWidgetsInDb,
    dirty,
    locationId,
    pageSettingsInDb,
    pageState,
    pageSummariesInDb,
    pagesInDb,
    publishPromise,
    revert,
    siteFirebase.firestore,
    t,
    toast,
    user?.uid,
  ]);
  return { dirty, revert, publish };
};
