import { TextField, TextFieldProps } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import * as React from "react";

type NumberTextFieldProps = Omit<TextFieldProps, "onChange"> & {
  value: number;
  onChange?: (e: React.ChangeEvent, value: number) => void;
};

/**
 * This is the same as Material Ui's TextField component, except it
 * focuses on inputs for numbers. In particular, it solves an
 * annoyance we've had where passing in a value prop as a number
 * makes it impossible to clear the field, and converting between
 * string and number is tedious.
 *
 * With this component, you just pass in a number value, and it will
 * handle the string/number conversions for you
 */
const NumberTextField: FC<NumberTextFieldProps> = ({ value, ...rest }) => {
  const [valueStr, setValueStr] = useState("" + value);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    // If the prop changes, update our local state to match
    setValueStr((prev) => {
      if (!isFocused) {
        return "" + value;
      }
      // If they're in the middle of typing, we don't want to force an update just
      // because some parent component updated its state. So if the numeric values
      // are equal, we keep or state. Problem is, floating point finickyness and
      // conversions between $ and cents can give some error, so we can't check
      // exact equality
      const approximatelyEqual = Math.abs(value - Number(prev)) < 0.001;
      if (approximatelyEqual) {
        return prev;
      } else {
        return "" + value;
      }
    });
  }, [isFocused, value]);

  return (
    <TextField
      {...rest}
      value={valueStr}
      type="number"
      onChange={(e) => {
        setValueStr(e.currentTarget.value);
        const valueNum = Number(e.currentTarget.value);
        if (e.currentTarget.value === "" || Number.isNaN(valueNum)) {
          // Not a valid number. They can keep editing it, but we don't tell the
          //   parent component about the change.
          return;
        }

        rest.onChange?.(e, valueNum);
      }}
      onFocus={(e) => {
        setIsFocused(true);
        rest.onFocus?.(e);
      }}
      onBlur={(e) => {
        setIsFocused(false);
        const valueNum = Number(e.currentTarget.value);
        if (e.currentTarget.value === "" || Number.isNaN(valueNum)) {
          // Blurred while still not valid. Reset to the prop.
          setValueStr("" + value);
        }

        rest.onBlur?.(e);
      }}
    />
  );
};

export default NumberTextField;
