/// <reference lib="dom" />
// The firebase functions pull in these type definitions. And since they
//   run in node, they are not aware of the dom references that firebase
//   makes. So the above reference allows functions to compile
import type firebase from "firebase";
import {
  CurrencyOptions,
  paymentGateway,
  PhoneNumber,
  PostalAddress,
} from "./siteSettings";
import {
  DatabaseSuborderCollection,
  CartItemCollection,
  CartItemWithRevisions,
} from "./cart";
import Dinero from "dinero.js";
import * as R from "ramda";
import { Payment, paymentMethod } from "./payment";

/**
 * Data about an order.
 */
export interface Order {
  _version: 2;
  /**
   * Flag indicating that this was created by migration code. There are
   * some parts of the migration which have to make approximations and
   * best guesses, so if we see discrepencies in the data, this flag
   * can give us a hint that it was due to the migration
   */
  migratedFromV1?: boolean;
  orderId: string;
  orderIdSearch: string[];
  locationId: string;
  invoiceCode: string;
  recipient: Participant;
  delivery: OrderDeliveryOptions;
  currency: CurrencyOptions;
  /**
   * Changes which have been made to the order. Revisions can add items,
   * mark them as unavailable, or do arbitrary price adjustment.
   *
   * Note: The initial set of items ordered by the user are put into
   * a revision object. This makes the code simpler, since that initial
   * purchase doesn't need to be a special case. But i'll admit it's a
   * bit weird to call those a "revision".
   */
  revisions: Revision[];
  /**
   * Aggregation of the cartItems across all revisions.
   */
  cumulativeCartItems: {
    [cartItemId: string]: CartItemWithRevisions;
  };
  /**
   * Aggregation of the price across all revisions
   */
  cumulativePrice: PriceInfo;
  status: orderStatus;
  /**
   * The message thread associated with this order
   */
  messageThreadId?: string | null;
  /**
   * Whether the message thread has unresolved messages.
   *
   * This flag enables one of the filters on the orders page.
   */
  hasUnresolvedMessages?: boolean;
  /**
   * Whether the order needs someone to take an action before the order
   * can move forward. For example, if the admin needs to send the payment
   * to the customer, or the customer needs to fill in delivery address
   *
   * This flag enables one of the filters on the orders page.
   */
  hasActionNeeded?: boolean;
  pushTokens?: string[];
  createdByAdmin?: boolean;
  /**
   * Record of when various events happened to this order. It's optional because
   * old orders did not have this property. New ones should all have it
   */
  timeline?: TimelineEvent[];
}

export enum TimelineEventType {
  created = "created",
  inqueue = "inqueue",
  inprogress = "inprogress",
  ready = "ready",
  intransit = "intransit",
  delivered = "delivered",
  /** Order is set to delivery, but doesn't have an address */
  addressNeeded = "addressNeeded",
  /** Customer has filled in the missing address */
  addressAdded = "addressAdded",
  /** Admin sent the upcharge to the customer */
  upchargeSentToCustomer = "upchargeSentToCustomer",
  /** Customer paid the upcharge */
  upchargeAccepted = "upchargeAccepted",
  /** Customer rejected the upcharge */
  upchargeRejected = "upchargeRejected",
  /** Admin made additional changes, which invalidated the upcharge */
  upchargeAbandoned = "upchargeAbandoned",
}

export interface BaseTimelineEvent {
  /**
   * What event happened
   */
  type: TimelineEventType;
  /**
   * When it happened
   */
  timestamp: number;
}

export type TimelineEvent = BaseTimelineEvent;

/**
 * A person involved in the transaction. Currently only used for
 * the customer, but in principle we could have transactions between
 * multiple participants in the future.
 */
export interface Participant {
  userId: string | null;
  address: PostalAddress | null;
  email: string;
  emailSearch: string[];
  /**
   * If true, the user has explicitly asked us not to send them updates
   * for status/due time. We will still send them their receipt.
   */
  statusEmailOptOut?: boolean;
  /**
   * If true, the user has explicitly asked us not to send them sms.
   */
  smsOptOut?: boolean;
  /**
   * Flag indicating whether we've already sent an sms messages with the reminder:
   * "Reply STOP to unsubscribe". We need to send it once, but not on every message.
   */
  unsubscribeReminderSent?: boolean;
  phone: PhoneNumber | null;
  phoneSearch: string[];
  pushTokens?: string[];
}

/**
 * Various aggregations of the price
 */
export interface PriceInfo {
  beforeTax: number;
  tax: number;
  /**
   * Tax amounts split out by their tax rate. These will sum to the value in .tax
   */
  taxTable: { [taxRate: string]: number };
  /**
   * Tip provided by the customer. Like other values, this is the price in cents.
   * Eg, 20 = 20 cents. It is not 20%.
   */
  tip?: number;
  /**
   * Sum of beforeTax, tax, and tip
   */
  total: number;
}

/**
 * Same as PriceInfo, but values are in Dinero objects
 */
export interface PriceInfoDinero {
  beforeTax: Dinero.Dinero;
  tax: Dinero.Dinero;
  taxTable: { [taxRate: string]: Dinero.Dinero };
  tip?: Dinero.Dinero;
  total: Dinero.Dinero;
}

/**
 * Converts between PriceInfoDinero and PriceInfo
 */
export const priceInfoDineroToPriceInfo = (
  value: PriceInfoDinero
): PriceInfo => {
  const result: PriceInfo = {
    beforeTax: value.beforeTax.getAmount(),
    tax: value.tax.getAmount(),
    total: value.total.getAmount(),
    taxTable: R.map((tax) => tax.getAmount(), value.taxTable),
  };
  if (value.tip) {
    result.tip = value.tip.getAmount();
  }

  return result;
};

/**
 * Given two PriceInfo objects, adds them together to produce a third
 */
export const mergePriceInfo = (a: PriceInfo, b: PriceInfo): PriceInfo => {
  const merged: PriceInfo = {
    beforeTax: a.beforeTax + b.beforeTax,
    tax: a.tax + b.tax,
    total: a.total + b.total,
    taxTable: {},
  };
  if (a.tip || b.tip) {
    merged.tip = (a.tip ?? 0) + (b.tip ?? 0);
  }
  Object.entries(a.taxTable).forEach(([taxRate, taxA]) => {
    const taxB = b.taxTable[taxRate];
    if (taxB) {
      merged.taxTable[taxRate] = taxA + taxB;
    } else {
      merged.taxTable[taxRate] = taxA;
    }
  });
  Object.entries(b.taxTable).forEach(([taxRate, taxB]) => {
    if (!(taxRate in merged.taxTable)) {
      merged.taxTable[taxRate] = taxB;
    }
    // else, we already took care of it
  });
  return merged;
};

/**
 * Given two PriceInfoDinero objects, adds them together to produce a third
 */
export const mergePriceInfoDinero = (
  a: PriceInfoDinero,
  b: PriceInfoDinero
): PriceInfoDinero => {
  const merged: PriceInfoDinero = {
    beforeTax: a.beforeTax.add(b.beforeTax),
    tax: a.tax.add(b.tax),
    total: a.total.add(b.total),
    taxTable: {},
  };
  if (a.tip && b.tip) {
    merged.tip = a.tip.add(b.tip);
  } else if (a.tip) {
    merged.tip = a.tip;
  } else if (b.tip) {
    merged.tip = b.tip;
  }
  Object.entries(a.taxTable).forEach(([taxRate, taxA]) => {
    const taxB = b.taxTable[taxRate];
    if (taxB) {
      merged.taxTable[taxRate] = taxA.add(taxB);
    } else {
      merged.taxTable[taxRate] = taxA;
    }
  });
  Object.entries(b.taxTable).forEach(([taxRate, taxB]) => {
    if (!merged.taxTable[taxRate]) {
      merged.taxTable[taxRate] = taxB;
    }
    // else, we already took care of it
  });
  return merged;
};

export enum revisionStatus {
  /**
   * The revision has been created by the admin, but nothing has been
   * sent to the user. More changes can be made while in this state.
   * */
  incomplete = "incomplete",
  /**
   * The admin tried charging the credit card but reauth is needed.
   */
  authrequired = "authrequired",
  /**
   * The admin sent the reauth link to the customer and we are awaiting
   * their approval/disapproval.
   */
  pendingauth = "pendingauth",
  /**
   * The customer is in the middle of payment. No changes are allowed that might
   * affect the payment until it is complete.
   */
  lockedForPayment = "lockedForPayment",
  /**
   * The revision has been finalized, with no customer confirmation needed
   */
  complete = "complete",
  /**
   * The customer declined the extra charge.
   */
  complete_declined = "complete_declined",
  /**
   * The customer accepted the extra charge.
   */
  complete_accepted = "complete_accepted",
}

/**
 * Revisions contain information about changes to an order. The initial purchase
 * is considered a revision as well so that code written to work with revisions
 * will work out of the box, rather than needing special cases.
 */
export interface Revision {
  /**
   * Collection of items added to the order
   */
  itemsAdded: CartItemCollection;
  /**
   * Collection of items that are not able to be fulfilled
   *
   * Important: The cartItemIds of these items must match up with
   * ids in previous revisions, and the `count` field must be used
   * to say how many are unable. This differs from how we used to
   * do things, with an `unable` field. By using `count` we can use
   * the normal price calculation functions, then multiply by -1.
   */
  itemsUnable: CartItemCollection;
  /**
   * The tip, if any. Like other values in the order, this value is the
   * price in cents. Eg, 20 = 20 cents. It is not 20%.
   */
  tip?: number;
  /**
   * An additional amount, beyond what would be calculated from just
   * the additions and unabled's.
   */
  manualAdjustment: number;
  /**
   * Sum of all the changes in this revision.
   */
  priceAdjustment: PriceInfo;
  /**
   * Description of why this revision was made.
   */
  reason: string;
  status: revisionStatus;
  /**
   * Time when a message was sent to the user telling them they needed to
   * authorize an upcharge.
   */
  timeSentToCustomer?: number;
  /**
   * The payment associated with this revision.
   * */
  payment: Payment | null;
}

export const revisionIsComplete = (revision: Revision): boolean => {
  return (
    revision.status === revisionStatus.complete ||
    revision.status === revisionStatus.complete_declined ||
    revision.status === revisionStatus.complete_accepted
  );
};

export const revisionIsEmpty = (revision: Revision): boolean => {
  return (
    !revisionIsComplete(revision) &&
    revision.reason === "" &&
    revision.manualAdjustment === 0 &&
    Object.keys(revision.itemsAdded).length === 0 &&
    Object.values(revision.itemsUnable).every((item) => item.count === 0)
  );
};

export type paymentAuthSent = firebase.firestore.Timestamp;

export enum paymentRechargeStatus {
  /** THE REFUND/UPCHARGE HAS BEEN FINALIZED */
  complete = "complete",
  /** THE REFUND/UPCHARGE HAS NOT BEEN FINALIZED */
  incomplete = "incomplete",
  /** THE ADMIN TRIED CHARGING THE CREDIT CARD BUT REAUTH IS NEEDED **/
  authrequired = "authrequired",
  /** THE ADMIN SENT THE REAUTH LINK TO THE CUSTOMER **/
  pendingauth = "pendingauth",
  /** THE CUSTOMER DECLINED THE EXTRA CHARGE
   * HANDLED THE SAME AS COMPLETE BUT INDICATED TO THE ADMIN WHAT HAPPENED */
  complete_declined = "complete_declined",
}
export enum orderStatus {
  /** Order has been created by the customer, but can't be worked on until payment finishes */
  pending = "pending",
  /** Order can be worked on */
  inqueue = "inqueue",
  /** Order is being worked on*/
  inprogress = "inprogress",
  /** Order has been prepared for the customer */
  ready = "ready",
  /** Order is being delivered to the customer */
  intransit = "intransit",
  /** Order has been delivered to the customer */
  delivered = "delivered",
  /** Currently unused */
  failed = "failed",
}

export interface OrderDeliveryOptions {
  delivery: boolean;
  timeCompleted: firebase.firestore.Timestamp | null;
  timeCreated: firebase.firestore.Timestamp;
  timeCreated_locale: string;
  timeHandedoff: firebase.firestore.Timestamp | null;
  timePlanned: firebase.firestore.Timestamp;
  timePlannedOrig: firebase.firestore.Timestamp;
  timeRequested: "ASAP" | firebase.firestore.Timestamp;
  // (string & {}) is a little trick to let 'ASAP' be shown in
  //   intellisense, even though any string is allowed.
  timeRequested_locale: "ASAP" | (string & {});
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * Types below this line are deprecated. They were used in previous versions
 * and are kept in the codebase to have proper types for code that needs to
 * migrate from the old data structure to the new.
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * @deprecated
 */
export interface OrderCollection_v1 {
  [id: string]: Order_v1;
}

/**
 * Data about an order.
 *
 * @deprecated
 */
export interface Order_v1 {
  _version?: never; // version field didn't come about until v2
  orderId: string;
  currency: CurrencyOptions;
  customerAddress: string;
  customerEmail: string;
  customerPhone: string;
  delivery: OrderDeliveryOptions;
  invoiceCode: string;
  locationId: string;
  suborders: DatabaseSuborderCollection;
  paymentChargeToken: string;
  paymentGateway: paymentGateway;
  paymentToken: string;
  paymentMethod: paymentMethod;
  paymentCustomer: string;
  paymentRechargeStatus?: paymentRechargeStatus;
  status: orderStatus;
  paymentAuthSent?: paymentAuthSent;
  statusCompleted: boolean;
  tax: number;
  taxTable: Record<string, number>;
  total: number;
  userId: string;
  messageThreadId?: string | null;
  pushTokens?: string[];
  totalAdjustmentReason?: string;
  totalAdjustmentAmount?: number;
  //HOLDS THE LAST ADJUSTMENT WHEN THE ORDER WAS FINALIZED
  //WE NEED IT FOR WHEN AN ORDER UPCHARGE GETS REVERSED BY A CUSTOMER
  totalAdjustmentLast?: number;
}
