import { Fragment, FC } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import EmailIcon from "@material-ui/icons/Email";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import AccountEmail from "../../Store/Modals/accountEmail";
import useSiteUser from "../../UserProvider/useSiteUser";
import MUIDialog from "../Components/mui-dialog";

interface EmailListItemProps {
  initPath: string;
}

const EmailListItem: FC<EmailListItemProps> = ({ initPath }) => {
  const { t } = useTranslation();

  const { user } = useSiteUser();

  const popoverPath = `${initPath}/email`;

  return (
    <Fragment>
      <ListItem
        disableRipple
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(popoverPath);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <EmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={t("store.account.accountEmail")}
          secondary={user?.email ?? ""}
        />
      </ListItem>

      <MUIDialog route={popoverPath}>
        <AccountEmail initPath={popoverPath} accountEmail={user?.email ?? ""} />
      </MUIDialog>
    </Fragment>
  );
};

export default EmailListItem;
