import Icon from "@material-ui/core/Icon";
import { FC } from "react";
import {
  FancyText,
  fancyTextDefaults,
} from "../../../../../database/fancyText";
import { flexHorizAlign } from "../../../../Dashboard/Builder/FancyText/fancyTextComponent";
import SmartButton from "../../../../Dashboard/Components/smartButton";

interface ButtonProps {
  fancyText: FancyText;
  onClick: (e: any) => void;
  animate?: boolean;
}

const Button: FC<ButtonProps> = ({ fancyText, onClick, animate = true }) => {
  const textShadowUsed: boolean =
    (typeof fancyText.textShadowBlur === "number" &&
      fancyText.textShadowBlur > 0) ||
    (typeof fancyText.textShadowX === "number" &&
      fancyText.textShadowX !== 0) ||
    (typeof fancyText.textShadowY === "number" && fancyText.textShadowY !== 0);

  const boxShadowUsed: boolean =
    (typeof fancyText.boxShadowBlur === "number" &&
      fancyText.boxShadowBlur > 0) ||
    (typeof fancyText.boxShadowSpread === "number" &&
      fancyText.boxShadowSpread > 0) ||
    (typeof fancyText.boxShadowX === "number" && fancyText.boxShadowX !== 0) ||
    (typeof fancyText.boxShadowY === "number" && fancyText.boxShadowY !== 0);

  const fontSize = fancyText.fontSize ?? fancyTextDefaults.fontSize;

  return (
    <SmartButton
      animate={animate}
      startIcon={
        fancyText.text &&
        fancyText.icon &&
        fancyText.iconPosition === "left" ? (
          <Icon className={`gb-font-${fontSize}`}>{fancyText.icon}</Icon>
        ) : undefined
      }
      endIcon={
        fancyText.text &&
        fancyText.icon &&
        fancyText.iconPosition === "right" ? (
          <Icon className={`gb-font-${fontSize}`}>{fancyText.icon}</Icon>
        ) : undefined
      }
      style={{
        justifyContent: fancyText.align && flexHorizAlign.get(fancyText.align),
        width: fancyText.fullWidth ? "100%" : undefined,
        backgroundColor: fancyText.backgroundColor ?? "transparent",
        color: fancyText.textColor,
        fontFamily: fancyText.fontFamily,
        textShadow: textShadowUsed
          ? `${fancyText.textShadowX}px ${fancyText.textShadowY}px ${fancyText.textShadowBlur}px ${fancyText.textShadowColor}`
          : undefined,
        boxShadow: boxShadowUsed
          ? `${fancyText.boxShadowX ?? 0}px ${fancyText.boxShadowY ?? 0}px ${
              fancyText.boxShadowBlur ?? 0
            }px ${fancyText.boxShadowSpread ?? 0}px ${
              fancyText.boxShadowColor ?? "black"
            }`
          : undefined,
        border:
          fancyText.borderWidth &&
          fancyText.borderStyle &&
          fancyText.borderColor &&
          fancyText.borderWidth >= 0
            ? `${fancyText.borderWidth}px ${fancyText.borderStyle} ${fancyText.borderColor}`
            : "none",
        borderRadius:
          fancyText.borderTopLeftRadius && fancyText.borderTopLeftRadius > 0
            ? `${fancyText.borderTopLeftRadius}px`
            : "0px",
        fontStyle: fancyText.italic ? "italic" : undefined,
        paddingLeft: fancyText.paddingLeft
          ? `${fancyText.paddingLeft}px`
          : "0px",
        paddingRight: fancyText.paddingLeft
          ? `${fancyText.paddingLeft}px`
          : "0px",
        paddingTop: fancyText.paddingTop ? `${fancyText.paddingTop}px` : "0px",
        paddingBottom: fancyText.paddingTop
          ? `${fancyText.paddingTop}px`
          : "0px",
        marginLeft: !boxShadowUsed
          ? 0
          : fancyText.boxShadowX && fancyText.boxShadowX < 0
          ? `${Math.abs(
              (fancyText.boxShadowX ?? 0) -
                (fancyText.boxShadowSpread ?? 0) -
                (fancyText.boxShadowBlur ?? 0)
            )}px`
          : `${
              (fancyText.boxShadowSpread ?? 0) + (fancyText.boxShadowBlur ?? 0)
            }px`,
        marginRight: !boxShadowUsed
          ? 0
          : fancyText.boxShadowX && fancyText.boxShadowX > 0
          ? `${
              (fancyText.boxShadowX ?? 0) +
              (fancyText.boxShadowSpread ?? 0) +
              (fancyText.boxShadowBlur ?? 0)
            }px`
          : `${
              (fancyText.boxShadowSpread ?? 0) + (fancyText.boxShadowBlur ?? 0)
            }px`,
        marginTop: !boxShadowUsed
          ? undefined
          : fancyText.boxShadowY && fancyText.boxShadowY < 0
          ? `${Math.abs(
              (fancyText.boxShadowY ?? 0) -
                (fancyText.boxShadowSpread ?? 0) -
                (fancyText.boxShadowBlur ?? 0)
            )}px`
          : `${
              (fancyText.boxShadowSpread ?? 0) + (fancyText.boxShadowBlur ?? 0)
            }px`,
        marginBottom: !boxShadowUsed
          ? undefined
          : fancyText.boxShadowY && fancyText.boxShadowY >= 0
          ? `${
              (fancyText.boxShadowY ?? 0) +
              (fancyText.boxShadowSpread ?? 0) +
              (fancyText.boxShadowBlur ?? 0)
            }px`
          : `${
              (fancyText.boxShadowSpread ?? 0) + (fancyText.boxShadowBlur ?? 0)
            }px`,
      }}
      className={`gb-font-${fontSize}`}
      onClick={
        onClick
        //     (e) => {
        //     if (env === "editor") {
        //       // Deliberately not consuming the event.
        //       // The click will fall through and focus the widget
        //     } else {
        //       e.stopPropagation();
        //       execute();
        //     }
        //   }
      }
    >
      {fancyText.text || (!fancyText.text && !fancyText.icon) ? (
        <div
          style={{
            maxWidth: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textDecoration:
              fancyText.strikeThrough && fancyText.underline
                ? "underline line-through"
                : fancyText.strikeThrough
                ? "line-through"
                : fancyText.underline
                ? "underline"
                : undefined,
            fontWeight: fancyText.bold ? "bold" : undefined,
          }}
        >
          {!fancyText.text && !fancyText.icon ? "button" : fancyText.text}
        </div>
      ) : (
        <Icon
          className={`gb-font-${fontSize}`}
          style={{
            display: "flex",
            textShadow: textShadowUsed
              ? `${fancyText.textShadowX}px ${fancyText.textShadowY}px ${fancyText.textShadowBlur}px ${fancyText.textShadowColor}`
              : undefined,
          }}
        >
          {fancyText.icon}
        </Icon>
      )}
    </SmartButton>
  );
};

export default Button;
