import { Fragment, FC } from "react";
import FancyTextComponent from "../../../Dashboard/Builder/FancyText/fancyTextComponent";
import { FancyText } from "../../../../database/fancyText";

export interface TextOrIconWidgetProps {
  fancyText: FancyText;
}

const TextOrIconWidget: FC<TextOrIconWidgetProps> = ({ fancyText }) => {
  let defaultFancyText: FancyText = fancyText ? { ...fancyText } : { text: "" };
  if (!fancyText || (fancyText && !fancyText.text && !fancyText.icon)) {
    defaultFancyText = {
      text: "title",
      fontSize: 13,
      textColor: "rgba(0, 0, 0, 0.54)",
      align: "left",
      fullWidth: true,
      fontFamily: "Courier New",
      italic: true,
    };
  }

  const boxShadowUsed: boolean =
    (typeof defaultFancyText.boxShadowBlur === "number" &&
      defaultFancyText.boxShadowBlur > 0) ||
    (typeof defaultFancyText.boxShadowSpread === "number" &&
      defaultFancyText.boxShadowSpread > 0) ||
    (typeof defaultFancyText.boxShadowX === "number" &&
      defaultFancyText.boxShadowX !== 0) ||
    (typeof defaultFancyText.boxShadowY === "number" &&
      defaultFancyText.boxShadowY !== 0);

  return (
    <Fragment>
      <div
        style={{
          width: fancyText.fullWidth ? "100%" : undefined,
          marginLeft: !boxShadowUsed
            ? 0
            : defaultFancyText.boxShadowX && defaultFancyText.boxShadowX < 0
            ? `${Math.abs(
                (defaultFancyText.boxShadowX ?? 0) -
                  (defaultFancyText.boxShadowSpread ?? 0) -
                  (defaultFancyText.boxShadowBlur ?? 0)
              )}px`
            : `${
                (defaultFancyText.boxShadowSpread ?? 0) +
                (defaultFancyText.boxShadowBlur ?? 0)
              }px`,
          marginRight: !boxShadowUsed
            ? 0
            : defaultFancyText.boxShadowX && defaultFancyText.boxShadowX > 0
            ? `${
                (defaultFancyText.boxShadowX ?? 0) +
                (defaultFancyText.boxShadowSpread ?? 0) +
                (defaultFancyText.boxShadowBlur ?? 0)
              }px`
            : `${
                (defaultFancyText.boxShadowSpread ?? 0) +
                (defaultFancyText.boxShadowBlur ?? 0)
              }px`,
          marginTop: !boxShadowUsed
            ? undefined
            : defaultFancyText.boxShadowY && defaultFancyText.boxShadowY < 0
            ? `${Math.abs(
                (defaultFancyText.boxShadowY ?? 0) -
                  (defaultFancyText.boxShadowSpread ?? 0) -
                  (defaultFancyText.boxShadowBlur ?? 0)
              )}px`
            : `${
                (defaultFancyText.boxShadowSpread ?? 0) +
                (defaultFancyText.boxShadowBlur ?? 0)
              }px`,
          marginBottom: !boxShadowUsed
            ? undefined
            : defaultFancyText.boxShadowY && defaultFancyText.boxShadowY >= 0
            ? `${
                (defaultFancyText.boxShadowY ?? 0) +
                (defaultFancyText.boxShadowSpread ?? 0) +
                (defaultFancyText.boxShadowBlur ?? 0)
              }px`
            : `${
                (defaultFancyText.boxShadowSpread ?? 0) +
                (defaultFancyText.boxShadowBlur ?? 0)
              }px`,
        }}
      >
        <FancyTextComponent value={defaultFancyText} />
      </div>
    </Fragment>
  );
};

export default TextOrIconWidget;
