import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Dispatch, FC, SetStateAction } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MUIDialog from "../../../Dashboard/Components/mui-dialog";
import Terms from "../terms";
import { history } from "../../../App/history";
import {
  Application,
  ApplicationInDatabase,
} from "../../../../database/signup";
import { useGridBashFirebase } from "../../../../Firebase/context";
import useToast from "../../../Main/useToast";
import useSpinner from "../../../Spinner/useSpinner";
import { unstable_batchedUpdates } from "react-dom";
import { blankApplication } from "../home";
import { validateEmail } from "../../../../utilities/validateEmailPassPhone";

interface ApplicationFormProps {
  application: Application;
  setApplication: Dispatch<SetStateAction<Application>>;
  docId: string;
  setDocId: Dispatch<SetStateAction<string>>;
  initPath: string;
}

const ApplicationForm: FC<ApplicationFormProps> = ({
  application,
  setApplication,
  docId,
  setDocId,
  initPath,
}) => {
  const firebase = useGridBashFirebase();
  const { t } = useTranslation();
  const showSpinner = useSpinner();
  const toast = useToast();

  const { email, phone, name, companyName, companyZip, termsAccepted } =
    application;

  const incomplete =
    !validateEmail(email) ||
    !name ||
    !phone ||
    !companyName ||
    !companyZip ||
    !termsAccepted;

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Typography variant="h5">Let's get you equipped!</Typography>
      </div>

      <DialogContent className="smartWidthMd">
        <FormControl fullWidth>
          <TextField
            type="text"
            label={t("gridbash.home.yourName")}
            value={name}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setApplication((prev) => ({
                ...prev,
                name: value,
              }));
            }}
          />
          <div style={{ marginTop: "1em" }} />
          <TextField
            type="email"
            label={t("gridbash.home.email")}
            value={email}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setApplication((prev) => ({
                ...prev,
                email: value,
              }));
            }}
          />
          <div style={{ marginTop: "1em" }} />
          <TextField
            type="text"
            label={t("gridbash.home.phone")}
            value={phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setApplication((prev) => ({
                ...prev,
                phone: value,
              }));
            }}
          />
          <div style={{ marginTop: "1em" }} />
          <TextField
            type="text"
            label={t("gridbash.home.companyName")}
            value={companyName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setApplication((prev) => ({
                ...prev,
                companyName: value,
              }));
            }}
          />
          <div style={{ marginTop: "1em" }} />
          <TextField
            type="text"
            label={t("gridbash.home.zipCode")}
            InputLabelProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              },
            }}
            value={companyZip}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setApplication((prev) => ({
                ...prev,
                companyZip: value,
              }));
            }}
          />
        </FormControl>
        <div style={{ marginTop: "1em" }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setApplication((prev) => ({
                ...prev,
                termsAccepted: !prev.termsAccepted,
              }));
            }}
            color={termsAccepted ? "secondary" : undefined}
          >
            {termsAccepted ? <CheckedIcon /> : <UncheckedIcon />}
          </IconButton>
          <Typography>
            I agree to the&nbsp;
            <Link
              variant="button"
              color="textSecondary"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                const path = `${initPath}/terms-of-service`;
                if (!window.location.pathname.includes(path)) {
                  history.push(path);
                }
              }}
            >
              Terms of Service
            </Link>
          </Typography>
          <MUIDialog route={`${initPath}/terms-of-service`}>
            <Terms />
          </MUIDialog>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={async (e) => {
            e.stopPropagation();
            if (incomplete) {
              /**
               * !email || !name || !phone || !companyName || !companyZip || !termsAccepted;
               */
              let message = "";
              if (!validateEmail(email)) {
                message = "Please provide a valid email address.";
              } else if (!name) {
                message = "Please provide your name.";
              } else if (!phone) {
                message = "Please provide your phone number.";
              } else if (!companyName) {
                message = "Please provide your company name.";
              } else if (!companyZip) {
                message = "Please provide your company operating zip code.";
              } else if (!termsAccepted) {
                message = "Please accept the Terms and Conditions.";
              }
              toast({
                dialog: true,
                message,
                color: "error",
              });
            } else {
              const databaseEntry: ApplicationInDatabase = {
                timestamp: Date.now(),
                ...application,
              };
              const hideSpinner = showSpinner();
              try {
                await firebase.firestore
                  .collection("applications")
                  .doc(docId)
                  .set(databaseEntry);

                unstable_batchedUpdates(() => {
                  setDocId(
                    firebase.firestore.collection("applications").doc().id
                  );
                  setApplication(blankApplication);
                });

                history.push("/applySequence/complete");
              } catch (err) {
                toast({
                  message:
                    "An error occurred while submitting your application",
                  color: "error",
                  dialog: true,
                });
              } finally {
                hideSpinner();
              }
            }
          }}
        >
          {t("gridbash.submit")}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default ApplicationForm;
