import { Dispatch, SetStateAction, useCallback } from "react";
import { PageAction, PageActionType } from "./actions";
import { EditHistory } from "./pageApiProvider";
import { PageState } from "./reducer";

interface Options {
  editHistory: EditHistory;
  setEditHistory: Dispatch<SetStateAction<EditHistory>>;
  setSelectedPageId: Dispatch<SetStateAction<string | undefined>>;
  restoredStates: (PageState | undefined)[];
}

export interface DispatchOptions {
  /**
   * Look at the previous entry in the history, and consider merging with it
   * as one BulkAction. If a string is provided, the merge will only happen if
   * the last action has the same mergeId. If true is provided, the merge will
   * always happen.
   */
  merge?: string | boolean;
  /**
   * If mergeLimit is provided, then the merge will be skipped if the previous
   * action is a BulkAction with that many actions already in it.
   */
  mergeLimit?: number;
  /**
   * Look at the previous entry in the history and consider replacing it with
   * this new one. The replacement will only happen if the string matches the
   * merge id of the previous action
   */
  replace?: string;
}

/**
 * Helper hook to split up the functionality of pageApiProvider.
 * This implements the functions for editing pages
 */
export const useEditPages = ({
  editHistory,
  setEditHistory,
  setSelectedPageId,
  restoredStates,
}: Options) => {
  const dispatch = useCallback(
    (action: PageAction, options?: DispatchOptions) => {
      const {
        merge = false,
        mergeLimit = Number.MAX_SAFE_INTEGER,
        replace,
      } = options ?? {};
      setEditHistory((prev) => {
        const lastEntry =
          prev.index > -1 ? prev.actions[prev.index] : undefined;
        let shouldReplace =
          lastEntry &&
          typeof replace === "string" &&
          replace === lastEntry.action.mergeId;
        let shouldMerge =
          lastEntry &&
          (merge === true ||
            (typeof merge === "string" && merge === lastEntry.action.mergeId));
        if (shouldReplace) {
          const newActions = [...prev.actions];
          newActions[prev.index] = {
            action,
            startingPoint:
              lastEntry?.startingPoint ?? restoredStates.length - 1,
          };
          return {
            actions: newActions,
            index: prev.index,
          };
        } else if (
          shouldMerge &&
          lastEntry?.action.type === PageActionType.bulkAction
        ) {
          // Last action is already a combined action. See if it can take more
          if (lastEntry.action.actions.length < mergeLimit) {
            const newActions = [...prev.actions];
            newActions[prev.index] = {
              ...lastEntry,
              action: {
                ...lastEntry.action,
                actions: [...lastEntry.action.actions, action],
              },
            };
            return {
              actions: newActions,
              index: prev.index,
            };
          }
          // else, we've hit the limit and should create a new action
        } else if (
          shouldMerge &&
          lastEntry?.action.type !== PageActionType.bulkAction
        ) {
          // The action gets combined with the previous one. A single undo will
          //   get rid of both.
          const newActions = [...prev.actions.slice(0, prev.index + 1)];
          newActions[prev.index] = {
            action: {
              type: PageActionType.bulkAction,
              actions: [prev.actions[prev.index].action, action],
              mergeId: action.mergeId,
            },
            startingPoint:
              lastEntry?.startingPoint ?? restoredStates.length - 1,
          };
          return {
            actions: newActions,
            index: prev.index,
          };
        }

        // If it didn't merge for any reason, then add a new entry
        return {
          actions: [
            ...prev.actions.slice(0, prev.index + 1),
            {
              action,
              startingPoint: restoredStates.length - 1,
            },
          ],
          index: prev.index + 1,
        };
      });
    },
    [restoredStates.length, setEditHistory]
  );

  const replaceAction = useCallback(
    (actionId: string, action: PageAction) => {
      setEditHistory((prev) => {
        return {
          ...prev,
          actions: prev.actions.map((prevAction) => {
            if (
              prevAction.action.type === PageActionType.placeholder &&
              prevAction.action.actionId === actionId
            ) {
              return {
                startingPoint: prevAction.startingPoint,
                action,
              };
            } else {
              return prevAction;
            }
          }),
        };
      });
    },
    [setEditHistory]
  );

  const undo = useCallback(() => {
    setEditHistory((prev) => {
      const newIndex = Math.max(-1, prev.index - 1);

      const actionBeingUndone = prev.actions[prev.index]?.action as
        | PageAction
        | undefined;
      // If the action is associated with a page (except deleting that page),
      //    then display it.
      if (
        newIndex !== prev.index &&
        actionBeingUndone &&
        "pageId" in actionBeingUndone &&
        typeof actionBeingUndone.pageId === "string" &&
        actionBeingUndone.type !== PageActionType.deletePage
      ) {
        setSelectedPageId(actionBeingUndone.pageId);
      }
      return {
        actions: prev.actions,
        index: newIndex,
      };
    });
  }, [setEditHistory, setSelectedPageId]);

  const redo = useCallback(() => {
    setEditHistory((prev) => {
      const newIndex = Math.min(prev.actions.length - 1, prev.index + 1);

      const actionBeingRedone = prev.actions[newIndex]?.action as
        | PageAction
        | undefined;
      // If the action is associated with a page (except deleting that page),
      //    then display it.
      if (
        newIndex !== prev.index &&
        actionBeingRedone &&
        "pageId" in actionBeingRedone &&
        typeof actionBeingRedone.pageId === "string" &&
        actionBeingRedone.type !== PageActionType.deletePage
      ) {
        setSelectedPageId(actionBeingRedone.pageId);
      }
      return {
        actions: prev.actions,
        index: newIndex,
      };
    });
  }, [setEditHistory, setSelectedPageId]);

  const setUndoIndex = useCallback(
    (index: number) => {
      setEditHistory((prev) => {
        index = Math.min(prev.actions.length - 1, index);
        index = Math.max(-1, index);
        return {
          actions: prev.actions,
          index,
        };
      });
    },
    [setEditHistory]
  );

  return {
    dispatch,
    replaceAction,
    undo,
    redo,
    undoLength: editHistory.actions.length,
    undoIndex: editHistory.index,
    setUndoIndex,
  };
};
