import { FC } from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";

/**
 * a primary color,
 * secondary color,
 * surface color,
 * background color,
 * error color.
 *
 * When a color appears “on” top of a primary color,
 * it’s called an “on primary color.” They are labelled using
 * the original color category (such as primary color) with the
 * prefix “on.”
 *
 * “On” colors are primarily applied to text, iconography,
 * and strokes. Sometimes, they are applied to surfaces.
 *
 * The default values for “on” colors are #FFFFFF and #000000.
 */

const backgroundDark = "#d0d0d0";
const backgroundLight = "#e7e5e6";
const primary = "#0c343d";
const primaryLight = "#395e67";
const primaryDark = "#000e17";
const onPrimary = "#ffffff";
const secondary = "#3caccc";
const secondaryDark = "#007d9b";
const secondaryLight = "#77deff";
const success = "#76ff03";
const error = "#B22222";

const gridbashTheme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      dark: primaryDark,
      light: primaryLight,
    },
    secondary: {
      main: secondary,
      dark: secondaryDark,
      light: secondaryLight,
    },
    error: {
      main: error,
    },
    success: {
      main: success,
    },
    background: {
      default: backgroundDark,
      paper: backgroundLight,
    },
  },
  typography: {
    h3: { fontWeight: 700 },
    h4: { fontWeight: 700 },
  },
  overrides: {
    MuiDialog: {
      paper: { backgroundColor: "snow" },
    },
    MuiPopover: {
      paper: { backgroundColor: "#f2f2f2" },
    },
    MuiBackdrop: {
      root: {
        bottom: "-100%",
      },
    },
    MuiCardHeader: {
      root: { textAlign: "left" },
      avatar: {
        color: "white",
        backgroundColor: "#395e67",
        width: "40px",
        height: "40px",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        fontSize: "1.25rem",
        alignItems: "center",
        flexShrink: 0,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        lineHeight: "1",
        userSelect: "none",
        borderRadius: "50%",
        justifyContent: "center",
      },
    },
    MuiAppBar: {
      root: {
        opacity: 0,
        boxShadow: "none",
        justifyContent: "center",
        height: "66px",
        maxHeight: "66px",
      },
    },
    MuiAvatar: {
      colorDefault: {
        color: onPrimary,
        backgroundColor: primaryLight,
      },
    },
    MuiListItemIcon: {
      root: { color: primary },
    },
    MuiIconButton: { root: { color: primary } },
    MuiButton: { contained: { backgroundColor: primary, color: onPrimary } },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

const theme = responsiveFontSizes(gridbashTheme);

const GridbashTheme: FC = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GridbashTheme;
