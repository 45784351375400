import InvoiceItem from "./invoiceItem";
import Dinero from "dinero.js";
import {
  Order,
  Revision,
  revisionIsComplete,
  revisionStatus,
} from "../../database/order";
import { FC } from "react";
import * as React from "react";
import InvoicePriceSummary from "./invoicePriceSummary";
import { useTranslation } from "react-i18next";
import RevisionStatusIcon from "./revisionStatusIcon";
import { Card, CardHeader, IconButton, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Delete";
import TimeLineIcon from "@material-ui/icons/Timeline";
import EditIcon from "@material-ui/icons/Edit";

interface InvoiceRevisionProps {
  revision: Revision;
  order: Order;
  index: number;
  setReason?: React.Dispatch<React.SetStateAction<string>>;
  setManualAdjustmentStr?: React.Dispatch<React.SetStateAction<string>>;
  openAdjustmentModal: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  showAdminFeatures: boolean;
}

const InvoiceRevision: FC<InvoiceRevisionProps> = ({
  revision,
  order,
  index,
  setReason,
  setManualAdjustmentStr,
  openAdjustmentModal,
  showAdminFeatures,
}) => {
  const { t } = useTranslation();
  const complete = revisionIsComplete(revision);

  return (
    <Card
      variant="outlined"
      style={{
        marginBottom: "max(1em, 1vw)",
        backgroundColor:
          revision.status === revisionStatus.complete_declined
            ? "rgb(255, 179, 179)"
            : undefined,
        padding: 0,
      }}
    >
      {order.revisions.length > 1 && (
        <CardHeader
          avatar={<TimeLineIcon />}
          title={
            index === 0
              ? t("dashboard.charges.originalCharge").toUpperCase()
              : revision.status === revisionStatus.incomplete
              ? t("dashboard.charges.newRevision").toUpperCase()
              : t("dashboard.charges.revision").toUpperCase()
          }
        />
      )}
      {Object.values(revision.itemsAdded).map((cartItem, i) => (
        <React.Fragment key={i}>
          <InvoiceItem
            cartItem={cartItem}
            verb={index === 0 ? "" : t("dashboard.charges.added")}
            negative={false}
          />
        </React.Fragment>
      ))}
      {Object.values(revision.itemsUnable)
        .filter((cartItem) => cartItem.count > 0)
        .map((cartItem, i) => (
          <InvoiceItem
            key={i}
            cartItem={cartItem}
            verb={t("dashboard.charges.unable")}
            negative
          />
        ))}
      {Object.values(revision.itemsUnable)
        .filter((cartItem) => cartItem.count < 0)
        .map((cartItem, i) => (
          <InvoiceItem
            key={i}
            cartItem={cartItem}
            verb={t("dashboard.charges.readded")}
            negative
          />
        ))}
      {Boolean(revision.tip) && (
        <div style={{ padding: "0 0.5em" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 0.5em",
              borderRadius: "4px",
              backgroundColor: "rgba(0,0,0,0.075)",
            }}
          >
            <Typography>{t("dashboard.orders.tip").toUpperCase()}</Typography>

            <Typography noWrap>
              {Dinero({ amount: revision.tip }).toFormat()}
            </Typography>
          </div>
        </div>
      )}
      {(revision.manualAdjustment !== 0 || revision.reason !== "") && (
        <div
          className="anim_fadeIn_0503"
          style={{
            opacity: 0,
            marginBottom: "max(1em, 1vw)",
            padding: "0.5em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography color="secondary" noWrap>
              {t("dashboard.orders.adjustment").toUpperCase()}
            </Typography>
            {!complete && setReason && setManualAdjustmentStr && (
              <div>
                <IconButton
                  onClick={() => {
                    setReason("");
                    setManualAdjustmentStr("");
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    openAdjustmentModal(e);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              backgroundColor: "rgba(0,0,0,0.075)",
              padding: "0.5em",
              borderRadius: "4px",
              marginTop: "max(1vw, 1em)",
            }}
          >
            <Typography>{revision.reason ?? ""}</Typography>
            <Typography
              color={revision.manualAdjustment < 0 ? "error" : undefined}
              style={{ wordBreak: "break-word" }}
            >
              {Dinero({ amount: revision.manualAdjustment }).toFormat()}
            </Typography>
          </div>
        </div>
      )}
      {order.revisions.length > 1 && (
        <React.Fragment>
          <div style={{ marginTop: "max(1em, 1vw)" }} />
          <InvoicePriceSummary
            source="subtotal"
            priceInfo={revision.priceAdjustment}
          />
        </React.Fragment>
      )}
      <div
        style={{
          textAlign: "right",
          // backgroundColor: "rgba(0,0,0,0.2)",
        }}
      >
        <RevisionStatusIcon
          revision={revision}
          showAdminFeatures={showAdminFeatures}
        />
      </div>
    </Card>
  );
};

export default InvoiceRevision;
