export interface FancyText {
  text: string;
  textColor?: string;
  backgroundColor?: string;
  backgroundOpacity?: number;
  fontFamily?: string;
  fontSize?: number;
  bold?: boolean;
  italic?: boolean;
  strikeThrough?: boolean;
  underline?: boolean;
  borderWidth?: number;
  borderStyle?:
    | "dotted"
    | "dashed"
    | "solid"
    | "double"
    | "groove"
    | "ridge"
    | "inset"
    | "outset"
    | "none"
    | "hidden";
  borderColor?: string;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  marginLeft?: number;
  marginRight?: number;
  fullWidth?: boolean;
  boxShadowColor?: string;
  boxShadowX?: number;
  boxShadowY?: number;
  boxShadowBlur?: number;
  boxShadowSpread?: number;
  textShadowColor?: string;
  textShadowX?: number;
  textShadowY?: number;
  textShadowBlur?: number;
  align?: "left" | "center" | "justify" | "right";
  icon?: undefined | string; // UNDEFINED -> USE TEXT ELSE USE STRING AS ICON
  iconPosition?: "left" | "right";
}

export const fancyTextDefaults = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor: "transparent",
  backgroundOpacity: 0,
  textColor: "#000000",
  fontSize: 16,
  style: 0,
  borderWidth: 0,
  borderStyle: "solid",
  borderColor: "#000000",
  iconPosition: "left",
} as const;
