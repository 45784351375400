export const TOASTDURATION_ERROR = 8000;
export const INPUT_MAX_LENGTH = 1000;
export const INPUT_MAX_DESCRIPTION = 1500;
export const INPUT_MAX_NAME = 100;
export const INPUT_MAX_LABEL = 100;
export const INPUT_MAX_EMAIL = 100;
export const INPUT_MAX_SMS = 160;

export const THEME_FONTS = "Arial, Verdana, Geneva, Tahoma, sans-serif";
export const WEBFONTS = [
  //WEB SAFE FONTS
  "Arial",
  "Courier New",
  "Times New Roman",
  "Georgia",
  "Impact",
  "Comic Sans MS",
  "Trebuchet MS",
  "Helvetica",
  "Arial-black",
  "Garamond",
  "Verdana",
  "Bookman Old Style",
  "Palatino",
  "Times",
  "Courier",
];
