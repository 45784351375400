import * as R from "ramda";
import {
  CustomWidgetTemplate,
  PageAggregation,
  PageColumn,
  PageConfig,
  PageHeader,
  PageRow,
} from "../../../../../database/page";
import { createPage } from "../defaultPage";
import { PageAction, PageActionType } from "./actions";
import {
  PageCell,
  PageWidgetType,
} from "../../../../Store/PageRenderer/widgetType";
import { updateWidgetProps } from "./updateWidgetProps";
import { deleteWidget } from "./deleteWidget";
import { SiteSettings } from "../../../../../database/siteSettings";
import { replaceWidget } from "./replaceWidget";
import { filterCells, mapCells } from "../iterators";
import { createCell } from "../widgetEditors/createCell";
import {
  childLocator,
  getCell,
  getColumn,
  getRow,
  getTopLevelRows,
  parentLocator,
  setCell,
  setColumn,
  setRow,
  setTopLevelRows,
} from "./locatorUtils";
import { minColumnPercent, minColumnPx } from "../constants";

export interface PageState {
  summaries: PageAggregation;
  pages: {
    [pageId: string]: PageConfig;
  };
  customWidgets: {
    [widgetId: string]: CustomWidgetTemplate;
  };
  pageSettings: SiteSettings["pageSettings"];
}

/**
 * Clone a section, giving it new ids
 */
const cloneRow = (row: PageRow, uuidIterator: () => string): PageRow => ({
  ...row,
  id: uuidIterator(),
  props: {
    ...row.props,
    columns: row.props.columns.map((column) => ({
      ...column,
      cells: mapCells(
        (cell: PageCell) => ({
          ...cell,
          id: uuidIterator(),
        }),
        column.cells
      ),
    })),
  },
});

export const defaultRowBackground = {
  element: "color",
  opacity: 0,
  filter: "0, 0, 0",
  bgImageHeight: false,
} as const;

export const reducer = (state: PageState, action: PageAction): PageState => {
  switch (action.type) {
    case PageActionType.createPage: {
      const { name, pageId } = action;
      const newPage = createPage(pageId);
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: [
            ...state.summaries.pages,
            {
              pageId: newPage.pageId,
              name,
              isHomePage: false,
              hideFromNavigation: false,
            },
          ],
        },
        pages: { ...state.pages, [pageId]: newPage },
      };
    }
    case PageActionType.clonePage: {
      const { originalPage, name, hideFromNavigation, uuid } = action;
      let suffix = 0;
      // Generates a repeatable sequence of uuids. This is needed because
      //   the reducer needs to be a pure function. Ie, if it's passed the
      //   same state and same action, it must always return the same result.
      const uuidIterator = () => {
        suffix++;
        return uuid + suffix;
      };
      const pageId = uuidIterator();
      const clone: PageConfig = {
        ...originalPage,
        pageId,
        path: null,
        rows: mapCells(
          (cell) => ({
            ...cell,
            uuid: uuidIterator,
          }),
          originalPage.rows
        ),
      };
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: [
            ...state.summaries.pages,
            {
              pageId,
              name,
              isHomePage: false,
              hideFromNavigation,
            },
          ],
        },
        pages: {
          ...state.pages,
          [pageId]: clone,
        },
      };
    }
    case PageActionType.renamePage: {
      const { pageId, name } = action;
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: state.summaries.pages.map((page) => {
            if (page.pageId === pageId) {
              return {
                ...page,
                name,
              };
            } else {
              return page;
            }
          }),
        },
      };
    }
    case PageActionType.changePageIcon: {
      const { pageId, icon } = action;
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: state.summaries.pages.map((page) => {
            if (page.pageId === pageId) {
              return {
                ...page,
                icon,
              };
            } else {
              return page;
            }
          }),
        },
      };
    }
    case PageActionType.reorderPages: {
      const { from, to } = action;
      const newPages = [...state.summaries.pages];
      const [removed] = newPages.splice(from, 1);
      newPages.splice(to, 0, removed);
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: newPages,
        },
      };
    }
    case PageActionType.setHomePage: {
      const { pageId } = action;
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: state.summaries.pages.map((summary) => {
            if (summary.pageId === pageId) {
              return {
                ...summary,
                isHomePage: true,
                hideFromNavigation: false,
              };
            } else if (summary.isHomePage) {
              return {
                ...summary,
                isHomePage: false,
              };
            } else {
              return summary;
            }
          }),
        },
      };
    }
    case PageActionType.setPageHidden: {
      const { pageId, hidden } = action;
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: state.summaries.pages.map((summary) => {
            if (summary.pageId === pageId) {
              return {
                ...summary,
                hideFromNavigation: hidden,
              };
            } else {
              return summary;
            }
          }),
        },
      };
    }
    case PageActionType.deletePage: {
      return {
        ...state,
        summaries: {
          ...state.summaries,
          pages: state.summaries.pages.filter(
            (page) => page.pageId !== action.pageId
          ),
        },
        pages: R.omit([action.pageId], state.pages),
      };
    }
    case PageActionType.updatePageSettings: {
      return {
        ...state,
        pageSettings: action.newSettings,
      };
    }
    case PageActionType.addHeader: {
      const { pageId, uuid } = action;
      if (!state.pages[pageId]) {
        return state;
      }
      const textWidget = createCell(PageWidgetType.wealthyText);
      const header: PageHeader = {
        height: "large",
        includeNavigation: true,
        rows: [
          {
            id: uuid,
            type: PageWidgetType.row,
            props: {
              columns: [
                {
                  width: 100,
                  cellAlign: "top",
                  cells: [textWidget],
                },
              ],
            },
          },
        ],
      };

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageId]: {
            ...state.pages[pageId],
            header,
          },
        },
      };
    }
    case PageActionType.removeHeader: {
      const { pageId } = action;
      if (!state.pages[pageId]) {
        return state;
      }
      return {
        ...state,
        pages: {
          ...state.pages,
          [pageId]: {
            ...state.pages[pageId],
            header: undefined,
          },
        },
      };
    }
    case PageActionType.updateHeader: {
      const { pageId, partialHeader } = action;
      const prevPage = state.pages[pageId];
      if (!prevPage) {
        return state;
      }
      const prevHeader = prevPage.header;
      if (!prevHeader) {
        // Update action requires there to already be a header.
        return state;
      }
      return {
        ...state,
        pages: {
          ...state.pages,
          [pageId]: {
            ...prevPage,
            header: {
              ...prevHeader,
              ...partialHeader,
            },
          },
        },
      };
    }
    case PageActionType.createTopLevelRow: {
      const { columnLocator, columns, uuid } = action;

      const rows = getTopLevelRows(state, columnLocator);
      if (!rows) {
        return state;
      }

      const newRow: PageRow = {
        id: uuid,
        type: PageWidgetType.row,
        props: {
          columns: columns ?? [],
        },
      };

      const alreadyHasRow = rows.some((r) => r.id === newRow.id);
      if (alreadyHasRow) {
        // The new row is already in the state. This can happen during hot
        //    reloading, but is otherwise not really expected.
        return state;
      }

      const newRows = [...rows, newRow];
      return setTopLevelRows(state, columnLocator, newRows);
    }
    case PageActionType.cloneRow: {
      const { rowLocator, uuid } = action;
      const row = getRow(state, rowLocator);
      if (!row) {
        return state;
      }
      // TODO: support making a nested row
      const rows = getTopLevelRows(state, rowLocator);
      if (!rows) {
        return state;
      }

      let suffix = 0;
      // Generates a repeatable sequence of uuids. This is needed because
      //   the reducer needs to be a pure function. Ie, if it's passed the
      //   same state and same action, it must always return the same result.
      const uuidIterator = () => {
        suffix++;
        return uuid + suffix;
      };
      const newRow = cloneRow(row, uuidIterator);

      const alreadyHasRow = rows.some((r) => r.id === newRow.id);
      if (alreadyHasRow) {
        // The new row is already in the state. This can happen during hot
        //    reloading, but is otherwise not really expected.
        return state;
      }

      const newRows = [...rows];
      newRows.splice(
        rowLocator.path[rowLocator.path.length - 1].rowIndex,
        0,
        newRow
      );

      return setTopLevelRows(state, rowLocator, newRows);
    }
    case PageActionType.reorderRows: {
      const { columnLocator, from, to } = action;

      const rows = getTopLevelRows(state, columnLocator);
      if (!rows) {
        return state;
      }

      const newRows = [...rows];
      const [removed] = newRows.splice(from, 1);
      newRows.splice(to, 0, removed);

      return setTopLevelRows(state, columnLocator, newRows);
    }
    case PageActionType.addColumnToRow: {
      const { rowLocator, columnIndex, partialColumn } = action;

      const row = getRow(state, rowLocator);
      if (!row) {
        return state;
      }

      let newColumns: PageColumn[];
      if (
        partialColumn &&
        partialColumn.width !== undefined &&
        partialColumn.widthUnit === "px"
      ) {
        // No adjusting of column widths required
        newColumns = [...row.props.columns];
        newColumns.splice(columnIndex, 0, {
          cellAlign: "top",
          cells: [],
          width: 0,
          widthUnit: "px",
          ...partialColumn,
        });
      } else {
        let flexibleColumnCount = 1;
        row.props.columns.forEach((c) => {
          if (c.widthUnit !== "px") {
            flexibleColumnCount++;
          }
        });
        const newWidth = 100 / flexibleColumnCount;
        newColumns = row.props.columns.map((column) => {
          if (column.widthUnit !== "px") {
            return {
              ...column,
              width: newWidth,
            };
          } else {
            return column;
          }
        });
        newColumns.splice(columnIndex, 0, {
          cellAlign: "top",
          cells: [],
          ...partialColumn,
          width: newWidth,
        });
      }

      return setRow(state, rowLocator, {
        ...row,
        props: {
          ...row.props,
          columns: newColumns,
        },
      });
    }
    case PageActionType.deleteColumn: {
      const { columnLocator } = action;
      const columnIndex =
        columnLocator.path[columnLocator.path.length - 1].columnIndex;
      const rowLocator = parentLocator(columnLocator);

      const row = getRow(state, rowLocator);
      if (!row) {
        return state;
      }

      const newWidth = 100 / (row.props.columns.length - 1);
      let columns = row.props.columns
        .filter((_, i) => i !== columnIndex)
        .map((column) => ({
          ...column,
          width: newWidth,
        }));
      if (columns.length === 0) {
        columns = [
          {
            cellAlign:
              rowLocator.locationType === "navigation"
                ? ("center" as const)
                : ("top" as const),
            width: 100,
            cells: [],
          },
        ];
      }

      let newRow = {
        ...row,
        props: {
          columns,
        },
      };

      return setRow(state, rowLocator, newRow);
    }
    case PageActionType.moveColumn: {
      const { from, to } = action;
      const column = getColumn(state, from.columnLocator);
      if (!column) {
        return state;
      }

      let newState = reducer(state, {
        type: PageActionType.deleteColumn,
        columnLocator: from.columnLocator,
      });

      // TODO: there may be a bug where the to locator doesn't point to the right thing
      //   due to the delete. Check if that's the case.
      return reducer(newState, {
        type: PageActionType.addColumnToRow,
        rowLocator: parentLocator(to.columnLocator),
        columnIndex:
          to.columnLocator.path[to.columnLocator.path.length - 1].columnIndex,
        partialColumn: column,
      });
    }
    case PageActionType.moveColumnToNewRow: {
      const { from, to, uuid } = action;

      const newState = reducer(state, {
        type: PageActionType.createTopLevelRow,
        columnLocator: to,
        uuid,
      });

      const rows = getTopLevelRows(newState, to);
      if (!rows) {
        return state;
      }

      const newRowLocator = childLocator(to, rows.length - 1);
      const newColumnLocator = childLocator(newRowLocator, 0);
      return reducer(newState, {
        type: PageActionType.moveColumn,
        from: { columnLocator: from },
        to: { columnLocator: newColumnLocator },
      });
    }
    case PageActionType.addCellToColumn: {
      const { columnLocator, cellIndex, cell } = action;

      const column = getColumn(state, columnLocator);
      if (!column) {
        return state;
      }

      const newCells = [...column.cells];
      newCells.splice(cellIndex, 0, cell);
      const newColumn = {
        ...column,
        cells: newCells,
      };

      return setColumn(state, columnLocator, newColumn);
    }
    case PageActionType.resizeColumnsByDrag: {
      const { rowLocator, widths } = action;

      const row = getRow(state, rowLocator);
      if (!row) {
        return state;
      }

      let newColumns = row.props.columns.map((column, i) => {
        const newWidth = widths[i];
        if (newWidth !== undefined) {
          return {
            ...column,
            width: newWidth,
          };
        } else {
          return column;
        }
      });

      // Scale up the other columns so that the percents add to 100%
      let scalableWidthBefore = 0;
      let scalableWidthAfter = 100;
      newColumns.forEach((column, i) => {
        if (column.widthUnit !== "px") {
          if (widths[i] === undefined) {
            scalableWidthBefore += column.width;
          } else {
            scalableWidthAfter -= column.width;
          }
        }
      });
      if (scalableWidthBefore !== scalableWidthAfter) {
        newColumns = newColumns.map((column, i) => {
          if (column.widthUnit !== "px" && widths[i] === undefined) {
            return {
              ...column,
              width: (column.width / scalableWidthBefore) * scalableWidthAfter,
            };
          } else {
            return column;
          }
        });
      }

      return setRow(state, rowLocator, {
        ...row,
        props: {
          ...row.props,
          columns: newColumns,
        },
      });
    }
    case PageActionType.resizeColumnsByInput: {
      const { columnLocator, width, widthUnit } = action;
      const rowLocator = parentLocator(columnLocator);
      const columnIndex =
        columnLocator.path[columnLocator.path.length - 1].columnIndex;
      const row = getRow(state, rowLocator);
      if (!row) {
        return state;
      }

      const newColumns = row.props.columns.map((column, i) => {
        if (i === columnIndex) {
          return {
            ...column,
            width:
              widthUnit === "px"
                ? Math.max(minColumnPx, width)
                : Math.max(minColumnPercent, width),
            widthUnit,
          };
        }
        return column;
      });

      let totalWidth = 0;
      newColumns.forEach((column) => {
        if (column.widthUnit !== "px") {
          totalWidth += column.width;
        }
      });

      // Shrink or grow neighbors so that the sum of the widths is 100.
      let step = 1;
      while (totalWidth !== 100) {
        let leftNeighbor = newColumns[columnIndex - step] as
          | PageColumn
          | undefined;
        let rightNeighbor = newColumns[columnIndex + step] as
          | PageColumn
          | undefined;
        const shrinkingNeeded = totalWidth - 100;
        if (!leftNeighbor && !rightNeighbor) {
          // Ran out of neighboring columns to adjust.
          if (widthUnit === "percent") {
            // Adjust the main column. This happens when all the other columns
            //   are forced to their smallest size and there's still not enough room.
            newColumns[columnIndex] = {
              ...newColumns[columnIndex],
              width: newColumns[columnIndex].width - shrinkingNeeded,
            };
          }
          // else, every column is pixel based and there's nothing to sum to 100

          break;
        }

        let newLeftWidth = leftNeighbor?.width ?? 0;
        let newRightWidth = rightNeighbor?.width ?? 0;
        // Try to split the shrinking evenly between the two neighbors
        if (leftNeighbor && leftNeighbor.widthUnit !== "px") {
          newLeftWidth = leftNeighbor.width - shrinkingNeeded / 2;
          newLeftWidth = Math.max(minColumnPercent, newLeftWidth);
        }
        if (rightNeighbor && rightNeighbor.widthUnit !== "px") {
          newRightWidth = rightNeighbor.width - shrinkingNeeded / 2;
          newRightWidth = Math.max(minColumnPercent, newRightWidth);
        }
        let leftShrink = (leftNeighbor?.width ?? 0) - newLeftWidth;
        let rightShrink = (rightNeighbor?.width ?? 0) - newRightWidth;

        // If we weren't able to shrink evenly, shrink unevenly
        if (leftShrink !== shrinkingNeeded / 2) {
          newRightWidth = newRightWidth - shrinkingNeeded / 2 + leftShrink;
          newRightWidth = Math.max(minColumnPercent, newRightWidth);
        }
        if (rightShrink !== shrinkingNeeded / 2) {
          newLeftWidth = newLeftWidth - shrinkingNeeded / 2 + rightShrink;
          newLeftWidth = Math.max(minColumnPercent, newLeftWidth);
        }
        leftShrink = (leftNeighbor?.width ?? 0) - newLeftWidth;
        rightShrink = (rightNeighbor?.width ?? 0) - newRightWidth;

        // Apply the changes
        if (leftNeighbor && leftNeighbor.widthUnit !== "px") {
          newColumns[columnIndex - step] = {
            ...leftNeighbor,
            width: newLeftWidth,
            widthUnit: "percent",
          };
        }
        if (rightNeighbor && rightNeighbor.widthUnit !== "px") {
          newColumns[columnIndex + step] = {
            ...rightNeighbor,
            width: newRightWidth,
            widthUnit: "percent",
          };
        }
        totalWidth = totalWidth - leftShrink - rightShrink;

        // If we were unable to shrink enough, the loop will repeat and try the
        //   next pair of neighbors
        step++;
      }

      return setRow(state, rowLocator, {
        ...row,
        props: {
          ...row.props,
          columns: newColumns,
        },
      });
    }
    case PageActionType.reorderColumns: {
      const { rowLocator, from, to } = action;

      const row = getRow(state, rowLocator);
      if (!row) {
        return state;
      }

      const newColumns = [...row.props.columns];
      const [removed] = newColumns.splice(from, 1);
      newColumns.splice(to, 0, removed);
      const newRow = {
        ...row,
        props: {
          ...row.props,
          columns: newColumns,
        },
      };

      return setRow(state, rowLocator, newRow);
    }
    case PageActionType.updateColumn: {
      const { columnLocator, partialColumn } = action;
      const column = getColumn(state, columnLocator);
      if (!column) {
        return state;
      }
      return setColumn(state, columnLocator, {
        ...column,
        ...partialColumn,
      });
    }
    case PageActionType.deleteTopLevelRow: {
      const { rowLocator } = action;

      const rows = getTopLevelRows(state, rowLocator);
      if (!rows) {
        return state;
      }

      if (rowLocator.locationType === "header" && rows.length <= 1) {
        return reducer(state, {
          type: PageActionType.removeHeader,
          pageId: rowLocator.pageId,
        });
      } else {
        const index = rowLocator.path[0].rowIndex;

        const newRows = rows.filter((row, i) => i !== index);

        return setTopLevelRows(state, rowLocator, newRows);
      }
    }
    case PageActionType.updateWidgetProps:
      return updateWidgetProps(state, action);
    case PageActionType.replaceWidget:
      return replaceWidget(state, action);
    case PageActionType.deleteWidget:
      return deleteWidget(state, action);
    case PageActionType.convertWidgetToRow:
      const { cellToConvertLocator, cellToMoveLocator, insertLocation, uuid } =
        action;

      const cellToConvert = getCell(state, cellToConvertLocator);
      const cellToMove = getCell(state, cellToMoveLocator);
      if (!cellToConvert || !cellToMove) {
        return state;
      }

      const isSameCell = R.equals(cellToConvertLocator, cellToMoveLocator);
      const leftCells: PageCell[] = [];
      if (insertLocation === "left") {
        leftCells.push(cellToMove);
      } else if (!isSameCell) {
        leftCells.push(cellToConvert);
      }
      const rightCells: PageCell[] = [];
      if (insertLocation === "right") {
        rightCells.push(cellToMove);
      } else if (!isSameCell) {
        rightCells.push(cellToConvert);
      }

      const newRow: PageRow = {
        id: uuid,
        type: PageWidgetType.row,
        props: {
          columns: [
            {
              width: 50,
              cellAlign:
                cellToConvertLocator.locationType === "navigation"
                  ? "center"
                  : "top",
              cells: leftCells,
            },
            {
              width: 50,
              cellAlign:
                cellToConvertLocator.locationType === "navigation"
                  ? "center"
                  : "top",
              cells: rightCells,
            },
          ],
        },
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5,
        marginBottom: 5,
      };

      let newState = reducer(state, {
        type: PageActionType.deleteWidget,
        cellLocator: cellToMoveLocator,
      });

      return setCell(newState, cellToConvertLocator, newRow);
    case PageActionType.reorderWidgets: {
      const { columnLocator, from, to } = action;

      const column = getColumn(state, columnLocator);
      if (!column) {
        return state;
      }

      const newCells = [...column.cells];
      const [removed] = newCells.splice(from, 1);
      newCells.splice(to, 0, removed);
      const newColumn = {
        ...column,
        cells: newCells,
      };

      return setColumn(state, columnLocator, newColumn);
    }
    case PageActionType.moveWidget: {
      const { from, to } = action;

      const originalCell = getCell(state, from.cellLocator);
      if (!originalCell) {
        return state;
      }

      // Must insert a clone, so we can tell which one is the original to remove it
      const clone = R.clone(originalCell);
      let newState: PageState;

      if (to.newColumn) {
        const columnIndex =
          to.columnLocator.path[to.columnLocator.path.length - 1].columnIndex;
        const rowLocator = parentLocator(to.columnLocator);
        newState = reducer(state, {
          type: PageActionType.addColumnToRow,
          rowLocator,
          columnIndex: columnIndex,
          partialColumn: {
            cells: [clone],
          },
        });
      } else {
        newState = reducer(state, {
          type: PageActionType.addCellToColumn,
          columnLocator: to.columnLocator,
          cellIndex: to.cellIndex,
          cell: clone,
        });
      }

      // Adding the new widget may have changed indexes, and thus we cannot rely on
      //   the fromLocator to point to the old widget. Get the rows and then filter
      //   through all of them to find the old widget.
      const rows = getTopLevelRows(newState, from.cellLocator);
      if (!rows) {
        return newState;
      }
      const newRows = rows.map((row) => ({
        ...row,
        props: {
          ...row.props,
          columns: row.props.columns.map((column) => {
            const newCells = filterCells(
              (c) => c !== originalCell,
              column.cells
            );
            if (newCells !== column.cells) {
              return {
                ...column,
                cells: newCells,
              };
            } else {
              return column;
            }
          }),
        },
      }));
      return setTopLevelRows(newState, from.cellLocator, newRows);
    }
    case PageActionType.moveWidgetToNewRow: {
      const { from, to, uuid } = action;

      const newState = reducer(state, {
        type: PageActionType.createTopLevelRow,
        columnLocator: to,
        uuid,
      });

      const rows = getTopLevelRows(newState, to);
      if (!rows) {
        return state;
      }

      const newRowLocator = childLocator(to, rows.length - 1);
      const newColumnLocator = childLocator(newRowLocator, 0);
      return reducer(newState, {
        type: PageActionType.moveWidget,
        from: { cellLocator: from },
        to: { columnLocator: newColumnLocator, cellIndex: 0, newColumn: true },
      });
    }
    case PageActionType.updateTemplate:
      const { newTemplate } = action;
      return {
        ...state,
        customWidgets: {
          ...state.customWidgets,
          [newTemplate.templateId]: newTemplate,
        },
      };
    case PageActionType.bulkAction: {
      return action.actions.reduce(reducer, state);
    }
    case PageActionType.placeholder: {
      const { temporaryAction } = action;
      return temporaryAction ? reducer(state, temporaryAction) : state;
    }
    default:
      return state;
  }
};
