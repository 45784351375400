import Typography from "@material-ui/core/Typography";
import { Fragment, FC, useMemo } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import {
  ListOption,
  listSelectionType,
  NumberOption,
  optionType,
} from "../../../database/option";
import AppBar from "@material-ui/core/AppBar";
import {
  ListOptionSelection,
  NumberOptionSelection,
  OptionSelection,
} from "../../../database/cart";
import { Option } from "../../../database/option";
import { useTranslation } from "react-i18next";
import DoneIcon from "@material-ui/icons/Done";
import Dinero from "dinero.js";
import NumberInput from "../../Dashboard/Components/numberInput";
import useToast from "../../Main/useToast";
import NumberTextField from "../../Dashboard/Components/numberTextField";
import useWindowResize from "../../../utilities/useWindowResize";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { history } from "../../App/history";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import SubtractIcon from "@material-ui/icons/IndeterminateCheckBox";
import AddIcon from "@material-ui/icons/AddBox";
import { useLocation } from "react-router";

interface ItemOptionProps {
  option: Option;
  selection: OptionSelection;
  setSelection: (newSelection: OptionSelection) => void;
}

const ItemOption: FC<ItemOptionProps> = ({
  option,
  selection,
  setSelection,
}) => {
  const { t } = useTranslation();

  const requirementMet = useMemo(() => {
    if (
      option.required &&
      option.type === optionType.LIST &&
      selection.type === optionType.LIST
    ) {
      const selectedItems = Object.values(selection.selectedItems).filter(
        (val) => Boolean(val)
      ).length;
      return selectedItems >= (option.selectionLimit ?? 1);
    }
    return true;
  }, [option, selection]);

  return (
    <Fragment>
      <Typography variant="h6">{option.name}</Typography>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "0.5em" }}
      >
        {option.type === optionType.LIST &&
          option.selectionType === listSelectionType.MULTIPLE &&
          option.selectionLimit &&
          option.selectionLimit > 1 &&
          option.selectionLimit < option.items.length && (
            <Typography style={{ marginRight: "0.5em" }}>
              {t("store.orders.chooseUpToN", { count: option.selectionLimit })}
            </Typography>
          )}
        {!requirementMet ? (
          <Fragment>
            <WarningIcon fontSize="small" color="error" />
            &nbsp;
            <Typography variant="caption" color="error">
              {t("store.orders.required")}
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <DoneIcon fontSize="small" color="primary" />
            &nbsp;
            <Typography variant="caption" color="primary">
              {t("store.orders.completed")}
            </Typography>
          </Fragment>
        )}
      </div>
      <div>
        {option.type === optionType.LIST &&
        selection?.type === optionType.LIST ? (
          <ListPicker
            option={option}
            selection={selection}
            setSelection={setSelection}
          />
        ) : option.type === optionType.NUMBER &&
          selection?.type === optionType.NUMBER ? (
          <NumberPicker
            option={option}
            selection={selection}
            setSelection={setSelection}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

interface ListPickerProps {
  option: ListOption;
  selection: ListOptionSelection;
  setSelection: (newSelection: OptionSelection) => void;
}

const ListPicker: FC<ListPickerProps> = ({
  option,
  selection,
  setSelection,
}) => {
  return (
    <Fragment>
      {option.items.map((optionItem, index) => {
        const selected = selection.selectedItems[optionItem.id];
        return (
          <AppBar
            key={index}
            position="static"
            color={selected ? "primary" : "default"}
            style={{
              opacity: 1,
              padding: "0.5em",
              borderRadius: "5px",
              height: "auto",
              marginBottom: "0.25em",
              transition: "all 0.5s ease 0s",
              flexDirection: "row",
            }}
            onClick={(e) => {
              e.stopPropagation();
              const newSelection = {
                ...selection,
                selectedItems: {
                  ...selection.selectedItems,
                  [optionItem.id]: !selected,
                },
              };

              const limit =
                option.selectionType === listSelectionType.SINGLE
                  ? 1
                  : option.selectionLimit ?? Number.MAX_SAFE_INTEGER;

              const selectedCount = Object.values(
                newSelection.selectedItems
              ).filter((val) => val).length;
              if (selectedCount > limit) {
                // Limit reached. Flip one of the others to false
                for (const id in newSelection.selectedItems) {
                  if (id !== optionItem.id && newSelection.selectedItems[id]) {
                    newSelection.selectedItems[id] = false;
                    break;
                  }
                }
              }

              setSelection(newSelection);
            }}
          >
            <Typography>
              {optionItem.description}{" "}
              {optionItem.price
                ? optionItem.price <= 0
                  ? `(${Dinero({ amount: optionItem.price }).toFormat()})`
                  : `(+${Dinero({ amount: optionItem.price }).toFormat()})`
                : ""}
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <DoneIcon style={{ opacity: selected ? 1 : 0 }} />
          </AppBar>
        );
      })}
    </Fragment>
  );
};

interface NumberPickerProps {
  option: NumberOption;
  selection: NumberOptionSelection;
  setSelection: (newSelection: OptionSelection) => void;
}

const NumberPicker: FC<NumberPickerProps> = ({
  option,
  selection,
  setSelection,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dimensions = useWindowResize("itemOption");
  const location = useLocation<{ optionCount?: boolean } | undefined>();

  return (
    <Fragment>
      <Typography color="primary">
        {Boolean(option.price) &&
          `${Dinero({ amount: option.price }).toFormat()} ${
            option.unit
              ? ` ${t("store.orders.per")} ${option.priceDenominator} ${
                  option.unit
                }`
              : ` ${t("store.orders.each")}`
          }`}
      </Typography>
      {dimensions.touch ? (
        <Fragment>
          <MUIDialog
            route={""}
            stateMatch={(state) => state.optionCount === true}
          >
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel>{t("store.orders.quantity")}</InputLabel>
                <NumberInput
                  fullWidth
                  onBlur={() => {
                    const rounded =
                      Math.round(selection.value / option.increment) *
                      option.increment;

                    let message: string | undefined;
                    if (selection.value < option.minValue) {
                      message = t("store.orders.amountToSmall", {
                        amount: option.minValue,
                        unit: "",
                      });
                    } else if (selection.value > option.maxValue) {
                      message = t("store.orders.amountToBig", {
                        amount: option.maxValue,
                        unit: "",
                      });
                    } else if (selection.value !== rounded) {
                      message = t("store.orders.amountNeededRounding", {
                        amount: option.increment,
                        unit: "",
                      });
                    }

                    if (message) {
                      toast({
                        message,
                        color: "normal",
                      });
                    }
                    setSelection({
                      ...selection,
                      value: Math.max(
                        option.minValue,
                        Math.min(option.maxValue, rounded)
                      ),
                    });
                  }}
                  onChange={(e, newValue) => {
                    setSelection({
                      ...selection,
                      value: newValue,
                    });
                  }}
                  value={selection.value}
                />
              </FormControl>
            </DialogContent>
          </MUIDialog>
          <div style={{ display: "inline-block" }}>
            <div
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.stopPropagation();
                if (location.state?.optionCount !== true) {
                  history.push(location.pathname, {
                    ...location.state,
                    optionCount: true,
                  });
                }
              }}
            >
              <Typography variant="h6">{selection.value}</Typography>
            </div>
            <div
              style={{
                marginTop: "0.5em",
                marginBottom: "max(2em, 2vw)",
              }}
            >
              <ButtonGroup variant="contained">
                <Button
                  color="primary"
                  disabled={selection.value <= option.minValue}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newCount = selection.value - (option.increment ?? 1);
                    const minValue = option.minValue ?? 1;
                    setSelection({
                      ...selection,
                      value: Math.max(
                        minValue,
                        Math.min(option.maxValue, newCount)
                      ),
                    });
                  }}
                >
                  <SubtractIcon />
                </Button>
                <Button
                  color="primary"
                  disabled={
                    selection.value >=
                    (option.maxValue ?? Number.MAX_SAFE_INTEGER)
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    const newCount = selection.value + (option.increment ?? 1);

                    setSelection({
                      ...selection,
                      value: Math.max(1, Math.min(option.maxValue, newCount)),
                    });
                  }}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </Fragment>
      ) : (
        <NumberTextField
          fullWidth
          variant="filled"
          inputMode="numeric"
          value={selection.value}
          onChange={(e, newValue) => {
            setSelection({
              ...selection,
              value: newValue,
            });
          }}
          onBlur={() => {
            const rounded =
              Math.round(selection.value / option.increment) * option.increment;

            let message: string | undefined;
            if (selection.value < option.minValue) {
              message = t("store.orders.amountToSmall", {
                amount: option.minValue,
                unit: "",
              });
            } else if (selection.value > option.maxValue) {
              message = t("store.orders.amountToBig", {
                amount: option.maxValue,
                unit: "",
              });
            } else if (selection.value !== rounded) {
              message = t("store.orders.amountNeededRounding", {
                amount: option.increment,
                unit: "",
              });
            }

            if (message) {
              toast({
                message,
                color: "normal",
              });
            }
            setSelection({
              ...selection,
              value: Math.max(
                option.minValue,
                Math.min(option.maxValue, rounded)
              ),
            });
          }}
        />
      )}
    </Fragment>
  );
};

export default ItemOption;
