import { memo, Fragment, useState, FC, Suspense } from "react";
import { validateEmail } from "../../../utilities/validateEmailPassPhone";
import useSpinner from "../../Spinner/useSpinner";
import { useTranslation } from "react-i18next";
import ROUTES from "../../../utilities/constants/routes";
import useSiteUser from "../../UserProvider/useSiteUser";
import useAccountInfo from "../../Main/useAccountInfo";
import uuid from "uuid/v4";
import { FirestoreUpdate } from "../../../utilities/firestoreUpdateType";
import { Message, MessageThread } from "../../../database/message";
import { addMessageThreadToOrder } from "../../../utilities/httpsCallables/httpsCallables";
import { history } from "../../App/history";
import CommSettings from "../Components/commSettings";
import { storeKey } from "../../../Firebase/siteFirebase";
import {
  useLocationId,
  useSiteSettings,
} from "../../../customization/siteSettingsContext";
import { PhoneNumber } from "../../../database/siteSettings";
import { createPhoneSearchStrings } from "../../../utilities/createSearchStrings";
import { useSiteFirebase } from "../../../Firebase/context";
import MUIPopover from "../../Dashboard/Components/mui-popover";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { FormControl, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { INPUT_MAX_LENGTH } from "../../../utilities/constants/appConstants";
import FormHelperText from "@material-ui/core/FormHelperText";
import PhoneInput from "../Components/phoneInput/phoneInput";
import GuestCommSettings from "../Components/guestCommSettings";
import useToast from "../../Main/useToast";
import HelpIcon from "@material-ui/icons/Help";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useLocation } from "react-router-dom";

export interface NewMessageProps {
  orderId: string | null;
}

const minLength = 1;
const maxLength = INPUT_MAX_LENGTH;

const NewMessage: FC<NewMessageProps> = memo(({ orderId }) => {
  const location = useLocation<{ commSettings?: boolean }>();
  const { user } = useSiteUser();
  const accountInfo = useAccountInfo();
  const showSpinner = useSpinner();
  const { t } = useTranslation();
  const firebase = useSiteFirebase();
  const siteSettings = useSiteSettings();
  const locationId = useLocationId();
  const [phone, setPhone] = useState<PhoneNumber | null>(
    accountInfo.contactInfo.phone
  );
  const [email, setEmail] = useState(user?.email ?? "");
  const emailIsValid = validateEmail(email);
  const [guestPushToken, setGuestPushToken] = useState<string | null>(null);

  const [message, setMessage] = useState("");
  const messageIsValid =
    message.trim().length > minLength && message.trim().length < maxLength;

  const toast = useToast();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const onSubmit = async () => {
    if (!emailIsValid) {
      toast({
        dialog: true,
        message: t("toast.badEmail"),
        color: "error",
      });
      return;
    } else if (!messageIsValid) {
      toast({
        dialog: true,
        message: t("toast.moreDetails"),
        color: "error",
      });
      return;
    }

    const hideSpinner = showSpinner({ lag: "none" });
    const messageId = uuid();
    const now = new Date();
    const newMessage: FirestoreUpdate<Message> = {
      messageId,
      from: "customer",
      message: message.trim(),
      timeSent: now,
      resolved: false,
      read: false,
    };
    try {
      const doc = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("messageThreads")
        .doc();
      const threadId = doc.id;
      const thread: FirestoreUpdate<MessageThread> = {
        threadId: threadId,
        locationId: locationId,
        userId: user?.uid ?? null,
        email: email.trim(),
        phone,
        phoneSearch: createPhoneSearchStrings(phone),
        pushTokens: !user && guestPushToken ? [guestPushToken] : [],
        orderId,
        read: false,
        resolved: false,
        messages: {
          [messageId]: newMessage,
        },
        latestMessageTimestamp: now,
        url: `${window.location.protocol}//${window.location.host}`,
      };
      const promises: Promise<any>[] = [];
      promises.push(doc.set(thread));
      if (orderId) {
        promises.push(
          addMessageThreadToOrder(firebase, {
            orderId,
            threadId,
          })
        );
      }

      await Promise.all(promises);

      if (user) {
        // Swap to the message thread modal
        history.replace(location.pathname, {
          ...location.state,
          messages: true,
          threadId: threadId,
        });
      } else {
        history.goBack();
      }
    } catch (error) {
      if (error.code === "permission-denied") {
        // NEEDS TESTING
        toast({
          dialog: true,
          message: t("store.messages.permissionDenied"),
          color: "error",
        });
      } else {
        console.log("error sending messages", error);
        // NEEDS TESTING
        toast({
          dialog: true,
          message: t("toast.systemErrorCall"),
          color: "error",
        });
      }
    } finally {
      hideSpinner();
    }
  };

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Fragment>
      {anchor && (
        <MUIPopover
          anchorEl={anchor}
          route={""}
          stateMatch={(state) => Boolean(state.commSettings)}
        >
          {user ? (
            <CommSettings email={email} phone={phone} />
          ) : (
            <GuestCommSettings
              communicationType="messages"
              email={email}
              phone={phone}
              onPhoneChanged={setPhone}
              pushToken={guestPushToken}
              onPushTokenChanged={setGuestPushToken}
            />
          )}
        </MUIPopover>
      )}
      <DialogContent className="smartWidthMd">
        {siteSettings.phone && (
          <Typography color="textPrimary" align="center">
            {t("store.contactUs.ratherCall")}&nbsp;
            <a
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                color: siteSettings.theme2.colors.textPrimary.value,
              }}
              href={`tel:+${siteSettings.phone.raw}`}
            >
              {siteSettings.phone.formatted}
            </a>
          </Typography>
        )}

        {user && !orderId && (
          <div style={{ marginTop: "max(1em, 1vw)", textAlign: "center" }}>
            <Accordion
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
            >
              <AccordionSummary style={{ textAlign: "center" }}>
                <Button startIcon={<HelpIcon />} fullWidth>
                  <Typography variant="button">
                    {t("store.messages.orderQuestion")}
                  </Typography>
                </Button>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                <Typography component="div" align="left">
                  {t("store.orders.contactOrderRelated")}
                </Typography>
                <Button
                  style={{ marginTop: "0.25em" }}
                  onClick={() => {
                    window.location.pathname.includes(ROUTES.TRACK)
                      ? history.go(-1)
                      : history.replace(ROUTES.TRACK);
                  }}
                  variant="outlined"
                >
                  {t("store.orders.goToDashboard")}
                </Button>
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        {!user && (
          <Fragment>
            <FormControl fullWidth style={{ marginTop: "max(1em, 1vw)" }}>
              <TextField
                label={t("store.messages.enterEmail")}
                type="email"
                fullWidth={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <br />
            <br />
            <Suspense fallback={null}>
              <PhoneInput
                siteCountryCode={siteSettings.phone?.countryCode}
                value={phone}
                onChange={setPhone}
                className="smartWidthMd"
              />
            </Suspense>
            <br />
          </Fragment>
        )}
        <FormControl fullWidth style={{ marginTop: "1em" }}>
          <TextField
            label={t("store.messages.enterMessage")}
            multiline
            rowsMax={8}
            fullWidth={true}
            value={message}
            onChange={(e) => setMessage(e.target.value.substring(0, maxLength))}
          />
          {maxLength - message.length < 31 && (
            <FormHelperText
              className="anim_fadeIn_0503"
              style={{ opacity: 0, paddingLeft: "0.25em" }}
            >
              {t("store.orders.charactersLeft", {
                count: maxLength - message.length,
              })}
            </FormHelperText>
          )}
        </FormControl>
        <div style={{ marginTop: "1em", textAlign: "center" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => {
              if (location.state?.commSettings !== true)
                history.push(location.pathname, {
                  ...location.state,
                  commSettings: true,
                });
              setAnchor(e.currentTarget);
            }}
          >
            {t("store.orders.commSettings")}
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <span style={{ flexGrow: 1 }}></span>
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          {t("store.cancel")}
        </Button>
        <Button onClick={onSubmit}>{t("store.send")}</Button>
      </DialogActions>
    </Fragment>
  );
});

export default NewMessage;
