import { FC, useEffect, useMemo, useRef } from "react";
import * as React from "react";
import ROUTES from "../../../utilities/constants/routes";
import { useTranslation } from "react-i18next";
import STguestInfoModal from "../Modals/sTguestInfo";
import useSiteUser from "../../UserProvider/useSiteUser";
import { useHistory } from "react-router-dom";
import {
  useLocationId,
  useSiteSettings,
} from "../../../customization/siteSettingsContext";
import { DeliveryOption, GuestDetails } from "./checkoutMain";
import DeliveryOptionSelect from "./deliveryOptionSelect";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import useSpinner from "../../Spinner/useSpinner";
import {
  capturePaypalTransaction,
  createCheckoutSession,
  createPaypalTransaction,
  unlockOrder,
} from "../../../utilities/httpsCallables/httpsCallables";
import { useSiteFirebase } from "../../../Firebase/context";
import { OrderRequest } from "../../../utilities/httpsCallables/types";
import {
  paymentGateway as paymentGatewayEnum,
  PostalAddress,
} from "../../../database/siteSettings";
import ContinueIcon from "@material-ui/icons/ArrowRight";
import useToast from "../../Main/useToast";
import useCartApi from "../../CartManager/useCartApi";
import TipSelect from "./tipSelect";
import { getTotalsForItems } from "../../../utilities/orderProcessing";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { loadStripe } from "../../PaymentProvider/loadStripe";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import useAccountInfo from "../../Main/useAccountInfo";
import Crumbs from "./crumbs";
import GuestCheckout from "./guestCheckout";
import TimePicker from "./timePicker";
import { priceInfoDineroToPriceInfo } from "../../../database/order";
import {
  createPhoneSearchStrings,
  createSearchStrings,
} from "../../../utilities/createSearchStrings";

export interface CheckoutOptionsProps {
  deliveryOption: DeliveryOption;
  setDeliveryOption: React.Dispatch<React.SetStateAction<DeliveryOption>>;
  requestedTime: Date;
  setRequestedTime: React.Dispatch<React.SetStateAction<Date>>;
  guestDetails: GuestDetails;
  setGuestDetails: React.Dispatch<React.SetStateAction<GuestDetails>>;
  tipPercent: number;
  setTipPercent: React.Dispatch<React.SetStateAction<number>>;
  addressInfo: PostalAddress;
}

const CheckoutOptions: FC<CheckoutOptionsProps> = ({
  deliveryOption,
  setDeliveryOption,
  requestedTime,
  setRequestedTime,
  guestDetails,
  setGuestDetails,
  tipPercent,
  setTipPercent,
  addressInfo,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useSiteUser();
  const accountInfo = useAccountInfo();
  const {
    currency,
    invoiceCode,
    paymentGateway,
    stripeAccountId,
    paypalClientId,
    tips,
  } = useSiteSettings();
  const locationId = useLocationId();
  const { clearCart, cartItems, orderId } = useCartApi();
  const cartTotals = useMemo(() => getTotalsForItems(cartItems), [cartItems]);
  const toast = useToast();
  const showSpinner = useSpinner();
  const firebase = useSiteFirebase();

  const orderRequest = useMemo(() => {
    const phone = user ? accountInfo.contactInfo.phone : guestDetails.phone;
    const email = user ? accountInfo.contactInfo.email : guestDetails.email;
    if (!email) {
      // When refreshing the page, we can briefly have neither a user, nor guest details.
      //   We can't submit the order without the email.
      return undefined;
    }
    let pushTokens: string[] = [];
    if (user) {
      pushTokens = accountInfo.notifications.pushTokens;
    } else if (guestDetails.pushToken) {
      pushTokens = [guestDetails.pushToken];
    }
    const orderRequest: OrderRequest = {
      orderId,
      invoiceCode,
      currency,
      locationId,
      payment: null,
      cartItems,
      tip: tips.enabled
        ? cartTotals.beforeTax.multiply(tipPercent / 100).getAmount()
        : 0,
      recipient: {
        userId: user ? user.uid : null,
        phone,
        phoneSearch: createPhoneSearchStrings(phone),
        email,
        emailSearch: createSearchStrings(email),
        address: deliveryOption === "delivery" ? addressInfo : null,
        pushTokens,
      },
      delivery: {
        timeCompleted: null,
        timePlanned: requestedTime.toString(),
        //-----INFO----
        //WE DONT CREATE A TIMECREATED OBJECT HERE AS WE WANT
        //TO STORE IT AS A TIMESTAMP IN THE DATABASE AND NEED
        //TO DO IT IN FUNCTIONS
        timeCreated_locale: new Date().toLocaleDateString(currency.locale, {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }),
        timeRequested: requestedTime.toString(),
        timeRequested_locale: requestedTime.toLocaleDateString(
          currency.locale,
          {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }
        ),
        timeHandedoff: null,
        delivery: deliveryOption === "delivery",
      },
      price: priceInfoDineroToPriceInfo(cartTotals),
      createdByAdmin: false,
    };
    return orderRequest;
  }, [
    accountInfo.contactInfo.email,
    accountInfo.contactInfo.phone,
    accountInfo.notifications.pushTokens,
    addressInfo,
    cartItems,
    cartTotals,
    currency,
    deliveryOption,
    guestDetails.email,
    guestDetails.phone,
    guestDetails.pushToken,
    invoiceCode,
    locationId,
    orderId,
    requestedTime,
    tipPercent,
    tips.enabled,
    user,
  ]);

  const warmedUp = useRef(false);
  useEffect(() => {
    if (
      warmedUp.current ||
      !orderRequest ||
      paymentGateway !== paymentGatewayEnum.stripe
    ) {
      return;
    }
    // To improve performance of the checkout, we give the function a kick to
    // wake it up and get it started loading data.
    createCheckoutSession(firebase, { type: "warmup", orderRequest }).catch(
      console.error
    );
    warmedUp.current = true;
  }, [firebase, orderRequest, paymentGateway]);

  const startStripeCheckout = async () => {
    if (!stripeAccountId || !orderRequest) {
      return;
    }

    const stripe = await loadStripe(stripeAccountId);
    const hideSpinner = showSpinner({ lag: "none" });
    try {
      let rootUrl = `${window.location.origin}${ROUTES.rootPath}`;
      const response = await createCheckoutSession(firebase, {
        type: "new",
        orderRequest,
        rootUrl,
      });
      const id = response.id;

      const result = await stripe.redirectToCheckout({
        sessionId: id,
      });

      if (result.error) {
        console.error("result.error during checkout", result.error);
        toast({
          dialog: true,
          color: "error",
          message: t("store.orders.unknownError"),
        });
      }
    } catch (error) {
      console.error("error doing checkout", error);
      if (error.message.includes("already exists")) {
        clearCart();
        toast({
          dialog: true,
          color: "error",
          message: t("store.orders.orderExists"),
        });
      } else if (error.message.includes("charges not enabled")) {
        toast({
          dialog: true,
          color: "error",
          message: t("store.orders.chargesNotEnabled"),
        });
      } else {
        toast({
          dialog: true,
          color: "error",
          message: t("store.orders.unknownError"),
        });
      }
    } finally {
      hideSpinner();
    }
  };

  const transaction = useRef<{ orderId: string; paypalOrderId: string } | null>(
    null
  );

  const forceRerender = useMemo(() => [tipPercent], [tipPercent]);

  return (
    <React.Fragment>
      <MUIDialog route={`${ROUTES.CHECKOUT}/options/guest`}>
        <STguestInfoModal
          initialGuestDetails={guestDetails}
          onSubmit={(guestDetails: GuestDetails) => {
            setGuestDetails(guestDetails);
            history.replace(`${ROUTES.CHECKOUT}/options`);
          }}
          initPath={`${ROUTES.CHECKOUT}/options/guest`}
        />
      </MUIDialog>

      <div
        style={{
          marginTop: "max(1.5em, 1.5vw)",
          marginBottom: "1em",
          paddingLeft: "1em",
          paddingRight: "1em",
        }}
      >
        <div className="anim_moveLeft_small_0100">
          <Crumbs startStripeCheckout={startStripeCheckout} builder={false} />
        </div>

        <div
          className="anim_moveRight_small_0100"
          style={{ marginTop: "min(5vw, 5em)" }}
        >
          {!user && (
            <GuestCheckout guestDetails={guestDetails} builder={false} />
          )}

          <DeliveryOptionSelect
            deliveryOption={deliveryOption}
            setDeliveryOption={setDeliveryOption}
            builder={false}
          />
          <TimePicker
            requestedTime={requestedTime}
            setRequestedTime={setRequestedTime}
            deliveryOption={deliveryOption}
            initPath={ROUTES.CHECKOUT}
            builder={false}
          />

          {tips.enabled && (
            <TipSelect
              tipPercent={tipPercent}
              setTipPercent={setTipPercent}
              priceBeforeTax={cartTotals.beforeTax}
              builder={false}
            />
          )}

          <div style={{ marginTop: "max(2vw, 2em)" }}>
            {paymentGateway === paymentGatewayEnum.stripe ? (
              <Button
                variant="contained"
                color="primary"
                startIcon={<ContinueIcon />}
                onClick={startStripeCheckout}
              >
                {t("store.orders.continue")}
              </Button>
            ) : paymentGateway === paymentGatewayEnum.paypal &&
              paypalClientId ? (
              <PayPalScriptProvider
                options={{
                  "client-id": paypalClientId,
                  currency: currency.currency,
                }}
              >
                <Container maxWidth="xs">
                  <PayPalButtons
                    style={{ color: "silver" }}
                    // If they change the tip, then remount the buttons, thus starting over
                    forceReRender={forceRerender}
                    createOrder={
                      orderRequest &&
                      (async (data, actions) => {
                        const paypalOrderId = await createPaypalTransaction(
                          firebase,
                          {
                            type: "new",
                            orderRequest,
                          }
                        );
                        // Remember what we're working on, so it can be used in onApprove
                        transaction.current = {
                          orderId: orderRequest.orderId,
                          paypalOrderId,
                        };
                        return paypalOrderId;
                      })
                    }
                    onApprove={async (data, actions) => {
                      if (!transaction.current) {
                        // I don't think this can happen
                        console.log(
                          "somehow got approval before creating order"
                        );
                        return;
                      }

                      clearCart();
                      history.push(`${ROUTES.TRACK}?success=true`);
                      await capturePaypalTransaction(
                        firebase,
                        transaction.current
                      );
                    }}
                    onCancel={async () => {
                      const orderId = transaction.current?.orderId;
                      transaction.current = null;
                      if (orderId) {
                        try {
                          await unlockOrder(firebase, { orderId });
                        } catch {}
                      }
                    }}
                  />
                </Container>
              </PayPalScriptProvider>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CheckoutOptions;
