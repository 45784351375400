import { FC, useContext } from "react";
import { FancyText } from "../../../../database/fancyText";
import FancyTextComponent from "../../../Dashboard/Builder/FancyText/fancyTextComponent";
import { CatalogItemWidgetContext } from "./catalogItemWidget";

export interface FilterLabelsWidgetProps {
  overrides: Partial<FancyText>;
}

const FilterLabelsWidget: FC<FilterLabelsWidgetProps> = ({ overrides }) => {
  const catalogItem = useContext(CatalogItemWidgetContext);
  if (!catalogItem) {
    return null;
  }

  return (
    <div
      style={{
        display: overrides.fullWidth ? undefined : "flex",
        textAlign: overrides.fullWidth ? overrides.align : undefined,
        alignItems: "center",
        overflow: "hidden",
        overflowX: "auto",
        width: "100%",
      }}
    >
      {catalogItem.labels.length === 0 ? (
        //RENDER EMPTY (HIDDEN) SEARCH LABEL
        //HERE AS TO MAKE SPACING THE SAME
        <FancyTextComponent value={{ text: "\u00A0" }} />
      ) : (
        catalogItem.labels.map((label) => (
          <FancyTextComponent
            key={label.labelId}
            value={{
              ...label,
              ...overrides,
            }}
          />
        ))
      )}
    </div>
  );
};

export default FilterLabelsWidget;
