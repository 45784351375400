import { memo, FC, useContext, useEffect, useState } from "react";
import { CatalogItem_WithDefaults } from "../../../../database/catalogItem";
import { OrderGridWidgetProps, Padding } from "../../../../database/orderPage";
import ROUTES from "../../../../utilities/constants/routes";
import { BuilderContext } from "../../../Dashboard/Builder/OrdersBuilder/builderContext";
import { srcSetString } from "../../../Main/main";
import { ColorConfig } from "../../../widgets/colorConfig";
import { useThemeColor } from "../../../widgets/useThemeColor";
import OrderCellRenderer from "./cellRenderer";
import { history } from "../../../App/history";
import useWindowResize from "../../../../utilities/useWindowResize";

interface Props extends OrderGridWidgetProps {
  catalogItem: CatalogItem_WithDefaults;
  border?: {
    thickness?: number;
    color?: ColorConfig;
    style:
      | "dotted"
      | "dashed"
      | "solid"
      | "double"
      | "groove"
      | "ridge"
      | "inset"
      | "outset";
    radius: number;
  };
  padding?: {
    top: Padding;
    left: Padding;
    right: Padding;
    bottom: Padding;
  };
  rowUnit?: "vw" | "px" | "%" | "em" | "auto";
  rowValue?: number;
  onImageReady: (result: "loaded" | "error" | "noUrl") => void;
  parentPBSm: number | null;
  parentPBLg: number | null;
  parentColumnSm: number | null;
  parentColumnMd: number | null;
  parentColumnLg: number | null;
  parentColumnGap: number | null;
  widthCorrection: number;
}
const GridWidget: FC<Props> = memo(
  ({
    columns,
    rows,
    cells,
    background,
    catalogItem,
    border,
    padding,
    rowUnit,
    rowValue,
    onImageReady,
    parentPBSm,
    parentPBLg,
    parentColumnSm,
    parentColumnMd,
    parentColumnLg,
    parentColumnGap,
    widthCorrection,
  }) => {
    // console.log("gridWidget");
    const builder = useContext(BuilderContext);
    const themeColor = useThemeColor();
    const dimensions = useWindowResize("gridWidget");

    const [imageInfo, setImageInfo] = useState<{
      loaded: boolean;
      error: boolean;
    }>({ loaded: false, error: false });
    useEffect(() => {
      if (!catalogItem.imageUrl) {
        onImageReady("noUrl");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const templateAreasArray = Array(rows)
      .fill(null)
      .map((_, rowIndex) =>
        Array(columns)
          .fill(null)
          .map((_, colIndex) => `spacer_${rowIndex}_${colIndex}`)
      );
    cells.forEach((cell) => {
      for (let i = cell.row1; i <= cell.row2; i++) {
        for (let j = cell.col1; j <= cell.col2; j++) {
          templateAreasArray[i][j] = "cell" + cell.id;
        }
      }
    });

    const templateAreasString = templateAreasArray
      .map((row) => `"${row.join(" ")}"`)
      .join("\n");

    const sizesSmall =
      parentColumnSm && parentColumnGap
        ? Math.ceil(
            (dimensions.w -
              widthCorrection -
              (parentColumnSm - 1) *
                Math.round(
                  ((dimensions.w - widthCorrection) / 100) * parentColumnGap
                )) /
              parentColumnSm
          )
        : 0;
    const sizesMedium =
      parentColumnMd && parentColumnGap
        ? Math.ceil(
            (dimensions.w -
              widthCorrection -
              (parentColumnMd - 1) *
                Math.round(
                  ((dimensions.w - widthCorrection) / 100) * parentColumnGap
                )) /
              parentColumnMd
          )
        : 0;
    const sizesLarge =
      parentColumnLg && parentColumnGap
        ? Math.ceil(
            (dimensions.w -
              widthCorrection -
              (parentColumnLg - 1) *
                Math.round(
                  ((dimensions.w - widthCorrection) / 100) * parentColumnGap
                )) /
              parentColumnLg
          )
        : 0;
    /**
     * SIZES EXPLAINED
     *
     * WE START FROM THE BREAKPOINT WIDTH AS SET BY THE ADMIN
     * FOR THAT WIDTH WE THEN LOOK AT HOW MANY COLUMNS HAVE BEEN SET
     * AND DIVIDE THE TOTAL (WITHOUT GAPS) BY THE NUMBER OF COLUMNS
     *
     * WE HAVE AS MANY GAPS AS WE HAVE (COLUMNS - 1)
     */
    const sizesString =
      parentPBSm && parentPBLg && sizesSmall && sizesMedium
        ? `(min-width: ${
            parentPBLg + widthCorrection
          }px) ${sizesLarge}px, (min-width: ${
            parentPBSm + widthCorrection
          }px) ${sizesMedium}px, ${sizesSmall}px`
        : undefined;

    const gridCells = cells.map((cell, i) => (
      <OrderCellRenderer
        key={i}
        cell={cell}
        catalogItem={catalogItem}
        onImageReady={onImageReady}
        initPath={
          builder ? `${ROUTES.DASHBOARD_BUILDER}/orders` : `ROUTES.ORDER`
        }
        sizesString={sizesString}
      />
    ));

    // Add in spacers for positions that don't have a widget
    // templateAreasArray.forEach((row, rowIndex) => {
    //   row.forEach((column, columnIndex) => {
    //     if (column.startsWith("spacer")) {
    //       gridCells.push(
    //         <div
    //           key={`${rowIndex}_${columnIndex}`}
    //           style={{ gridArea: column }}
    //         >
    //           &nbsp;
    //         </div>
    //       );
    //     }
    //   });
    // });

    return (
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          height: rowUnit === "auto" ? undefined : `${rowValue}px`,
          borderRadius: border?.radius,
          minHeight: cells.length === 0 ? "2em" : undefined,
        }}
        onClick={(e) => {
          e.stopPropagation();
          console.log("gridItem_grid", "hellos");
          const path = builder
            ? `${ROUTES.DASHBOARD_BUILDER}/orders/details/${catalogItem.itemId}`
            : `${ROUTES.ORDER}/details/${catalogItem.itemId}`;
          if (!window.location.pathname.includes(path)) {
            history.push(path);
          }
        }}
      >
        {background.bgImageHeight && catalogItem.imageUrl && !imageInfo.error && (
          <div
            /**
             * WE NEED THIS WRAPPING DIV WITH ABSOLUTE POSITIONING TO
             * ACCOMODATE SAFARI. IN SAFARI, THE GRID WILL NOT SPAN THE
             * HEIGHT OF THE IMAGE WHEN USING ABSOLUTE POSITIONING AND
             * SETTING INSET:0
             */
            style={{
              minHeight: "100%",
              height: "100%",
              position: background.element === "image" ? "absolute" : undefined,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <div
              style={{
                backgroundColor:
                  background.element === "image" &&
                  !imageInfo.error &&
                  catalogItem.imageUrl
                    ? `rgba(${background.filter} ,${1 - background.opacity})`
                    : themeColor(background.color) ?? "white",
                border: border?.thickness
                  ? `${border.thickness}px ${border.style} ${themeColor(
                      border.color
                    )}`
                  : undefined,
                borderRadius: border?.radius,
                padding: padding?.top.minValue
                  ? `max(${padding.top.value}vw, ${padding.top.minValue}px`
                  : padding?.top.unit === "px"
                  ? `${padding.top.value}px`
                  : `${padding?.top.value ?? 0}vw`,
                height: rowUnit === "auto" ? "100%" : `${rowValue}px`,
                display: "grid",
                gridTemplateColumns: Array(columns).fill("1fr").join(" "),
                gridTemplateAreas: templateAreasString,
              }}
            >
              {gridCells}
            </div>
          </div>
        )}
        {background.element === "image" &&
          catalogItem.imageUrl &&
          !imageInfo.error && (
            <div
              className="dBctlgViewItemBGImg"
              style={{
                position: background.bgImageHeight ? undefined : "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                zIndex: -1,
                borderRadius: border?.radius,
              }}
            >
              <img
                src={catalogItem.imageUrl}
                /**
                 * SRCSET EXPLAINED
                 *
                 * IN DEV TOOLS
                 * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
                 * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
                 * ACTUALLY TAKING UP.
                 *
                 * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
                 * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
                 */
                srcSet={srcSetString(catalogItem.imageUrls)}
                sizes={sizesString}
                // sizes={
                //   parentPBSm && parentPBLg && sizesSmall && sizesMedium
                //     ? `(min-width: ${
                //         parentPBLg + widthCorrection
                //       }px) ${sizesLarge}px, (min-width: ${
                //         parentPBSm + widthCorrection
                //       }px) ${sizesMedium}px, ${sizesSmall}px`
                //     : undefined
                // }
                alt={catalogItem.name}
                onLoad={() => {
                  setImageInfo({ loaded: true, error: false });
                  onImageReady("loaded");
                }}
                onError={() => {
                  setImageInfo({ loaded: true, error: true });
                  onImageReady("error");
                }}
                style={{
                  objectFit: "cover",
                  objectPosition: catalogItem.imageZoom
                    ? `${catalogItem.imageZoom.x}% ${catalogItem.imageZoom.y}%`
                    : "center",
                  verticalAlign: "middle",
                  minHeight: "100%",
                  minWidth: "100%",
                  height: "100%",
                  width: "100%",
                  /**
                   * NO NEED FOR FADE IN BECAUSE WE HAVE THE
                   * MOVE UP CLASSNAME SET ON THE WHOLE GRID
                   * ITEM WHICH MOVES THE CELL UP AND FADES IT IN
                   */
                  // opacity: imageInfo.loaded ? 1 : 0,
                  // transition: "opacity 0.3s ease 0.3s",
                  borderRadius: border?.radius,
                }}
              />
            </div>
          )}
        {(!background.bgImageHeight ||
          !catalogItem.imageUrl ||
          imageInfo.error) && (
          <div
            style={{
              backgroundColor:
                background.element === "image" &&
                !imageInfo.error &&
                catalogItem.imageUrl
                  ? `rgba(${background.filter} ,${1 - background.opacity})`
                  : themeColor(background.color) ?? "white",
              border: border?.thickness
                ? `${border.thickness}px ${border.style} ${themeColor(
                    border.color
                  )}`
                : undefined,
              borderRadius: border?.radius,
              padding: padding?.top.minValue
                ? `max(${padding.top.value}vw, ${padding.top.minValue}px`
                : padding?.top.unit === "px"
                ? `${padding.top.value}px`
                : `${padding?.top.value ?? 0}vw`,
              height: rowUnit === "auto" ? undefined : `${rowValue}px`,
              display: "grid",
              gridTemplateColumns: Array(columns).fill("1fr").join(" "),
              gridTemplateAreas: templateAreasString,
            }}
          >
            {gridCells}
          </div>
        )}
      </div>
    );
  }
);

export default GridWidget;
