import { memo, useRef, Suspense, FC, useState, useEffect } from "react";
import "./css/global.css";
import { Switch, Route, useLocation } from "react-router-dom";
import STauthModal from "./Modals/sTauth";
import useSiteUser from "../UserProvider/useSiteUser";
import Spinner from "../Spinner/spinner";
import { useSiteSettings } from "../../customization/siteSettingsContext";
import { srcSetString } from "../Main/main";
import ROUTES from "../../utilities/constants/routes";
import useWindowResize from "../../utilities/useWindowResize";
import STDrawer from "./Components/Drawer/sTdrawer";
import MUIDialog from "../Dashboard/Components/mui-dialog";
import OrderPage from "./Orders/orderPage";
import Track from "./Track/track";
import FavoritesPage from "./Favorites/favoritesPage";
import Cart from "./Pages/cart";
import CheckoutPage from "./Checkout/checkoutPage";
import PaymentAuth from "./PaymentAuth/paymentAuth";
import Register from "./Pages/register";
import Reset from "./Pages/reset";
import PageTemplateLoader from "./Templates/pageTemplateLoader";
import Home from "./Pages/home";
import Account from "./Account/account";
import { useThemeColor } from "../widgets/useThemeColor";
import STauth from "./Modals/sTauth";
import ConfirmSignOut from "./Components/confirmSignOut";
import Search from "./Search/search";
import Messages from "./Messages/messages";
import { MessagesDeepLink } from "./Messages/messagesDeepLink";
import Unsubscribe from "./Pages/unsubscribe";
// import STHeader2 from "./Components/sTheader2";
import STheader from "./Components/sTheader";

interface StoreProps {}

const Store: FC<StoreProps> = memo(() => {
  const { user } = useSiteUser();

  const siteSettings = useSiteSettings();
  const { theme, extraPageOptions, orderPageOptions } = siteSettings;
  const themeColor = useThemeColor();
  const [imageInfo, setImageInfo] = useState<{
    loaded: boolean;
    error: boolean;
  }>({ loaded: false, error: false });

  const [mainBgImage, setMainBgImage] = useState(true);

  const location = useLocation<{ storeDrawer?: boolean }>();
  const showDrawer = Boolean(location.state?.storeDrawer);

  const appDimensions = useWindowResize("store");
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = headerEl.current;
    if (el) {
      setHeaderHeight(el.clientHeight);
    }
  }, [headerEl.current?.clientHeight, appDimensions]);

  const { backgroundColor } = orderPageOptions.props;

  return (
    <div id="store">
      <STDrawer
        paperProps={{
          style: {
            minWidth: "50vw",
            maxWidth: "75vw",
          },
        }}
        user={user}
        open={showDrawer}
      />

      <MUIDialog route={ROUTES.LOGIN_DEEPLINK}>
        <STauthModal isDeepLink />
      </MUIDialog>

      <MUIDialog route={`${ROUTES.MESSAGE_DEEPLINK}/:threadId`}>
        <MessagesDeepLink />
      </MUIDialog>

      <MUIDialog route="" stateMatch={(state) => Boolean(state.messages)}>
        <Messages />
      </MUIDialog>

      {/* <STHeader2 /> */}
      <STheader
        headerEl={headerEl}
        hideTabs={location.pathname !== ROUTES.STORE}
      />

      <MUIDialog route="" stateMatch={(state) => Boolean(state.signIn)}>
        <STauth />
      </MUIDialog>

      <MUIDialog route="" stateMatch={(state) => Boolean(state.signOut)}>
        <ConfirmSignOut />
      </MUIDialog>

      <div
        style={{
          position: "fixed",
          zIndex: -100,
          top: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          backgroundColor: window.location.pathname.includes(`${ROUTES.ORDER}`)
            ? themeColor(backgroundColor)
            : undefined,
        }}
      >
        {
          /**
           * THE ADVANTAGE OF KEEPING THE IMAGE IN STORE.TSX
           * IS THAT WE ONLY RENDER IT ONCE AND HAVE IT AVAILABLE
           * IN ALL PAGES IF DESIRED
           */
          <img
            src={`${theme.backgroundImage}`}
            /**
             * SRCSET EXPLAINED
             *
             * IN DEV TOOLS
             * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
             * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
             * ACTUALLY TAKING UP.
             *
             * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
             * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
             */
            srcSet={srcSetString(theme.backgroundImageUrls)}
            /**
             * SIZES EXPLAINED
             *
             * THIS IMAGES ALWAYS SPANS THE WHOLE WIDHT OF THE
             * VIEWPORT
             */
            sizes="100vw"
            alt={siteSettings._name}
            onLoad={() => {
              setImageInfo({ loaded: true, error: false });
            }}
            onError={() => {
              setImageInfo({ loaded: true, error: true });
            }}
            style={{
              objectFit: "cover",
              objectPosition: theme.backgroundImageZoom
                ? `${theme.backgroundImageZoom.x}% center`
                : "center",
              verticalAlign: "middle",
              minHeight: "100%",
              minWidth: "100%",
              width: "100%",
              opacity: imageInfo.loaded ? (mainBgImage ? 1 : 0) : 0,
              transition: "all 1s ease 0s",
            }}
          />
        }
      </div>

      <Suspense fallback={<Spinner isOpen />}>
        <Switch>
          <Route path={`${ROUTES.UNSUBSCRIBE}/:orderId/:userId?`}>
            <Unsubscribe setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.ORDER}>
            <OrderPage
              setMainBgImage={setMainBgImage}
              headerHeight={headerHeight}
            />
          </Route>
          <Route path={ROUTES.SEARCH}>
            <Search setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.TRACK}>
            <Track setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.FAVORITES}>
            <FavoritesPage setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.CART}>
            <Cart setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.CHECKOUT}>
            <CheckoutPage setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={`${ROUTES.PAYMENTAUTH}/:orderId`}>
            <PaymentAuth setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.ACCOUNT}>
            <Account setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.REGISTER}>
            <Register setMainBgImage={setMainBgImage} />
          </Route>
          <Route path={ROUTES.RESET}>
            <Reset setMainBgImage={setMainBgImage} />
          </Route>
          {extraPageOptions.pages.map((extraPage) => (
            <Route key={extraPage.pageId} path={extraPage.path}>
              <PageTemplateLoader
                setMainBgImage={setMainBgImage}
                template={extraPage.template}
              />
            </Route>
          ))}
          <Route path={ROUTES.STORE}>
            <Home setMainBgImage={setMainBgImage} />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
});

export default Store;
