import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../utilities/constants/routes";

export interface EmptyCartNotificationProps {}

const EmptyCartNotification: FC<EmptyCartNotificationProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      className="anim_fadeIn_0505"
      style={{
        margin: "auto",
        color: "black",
        opacity: 0,
      }}
    >
      <Typography>{t("store.cart.emptyCart")}</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          history.replace(ROUTES.ORDER);
        }}
      >
        {t("store.orders.startNewOrder")}
      </Button>
    </div>
  );
};

export default EmptyCartNotification;
