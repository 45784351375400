import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FC, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import MUIPopover, { MUIPopoverProps } from "../Components/mui-popover";
import { useSiteFirebase } from "../../../Firebase/context";
import { storeKey } from "../../../Firebase/siteFirebase";
import { History } from "history";
import { history } from "../../App/history";
import { Button, FormControl, FormHelperText } from "@material-ui/core";
import AccountPhone from "../../Store/Modals/accountPhone";
import { AccountInfo } from "../../Main/useMain";
import useToast from "../../Main/useToast";
import { useSiteSettings } from "../../../customization/siteSettingsContext";

interface CommCheckboxProps {
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
  category: "customer" | "newOrder" | "newMessage";
  commType: "email" | "sms" | "push";
}

/**
 * Checkbox for editing a communication setting
 */
const CommCheckbox: FC<CommCheckboxProps> = ({
  category,
  commType,
  accountInfo,
  setOptimisticUpdate,
}) => {
  const { t } = useTranslation();
  const siteSettings = useSiteSettings();
  const firebase = useSiteFirebase();

  const checked = accountInfo.notifications[category][commType];

  const toast = useToast();
  const [popoverProps, setPopoverProps] = useState<MUIPopoverProps>();

  const onChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    e.stopPropagation();
    if (
      commType === "sms" &&
      newValue &&
      (!accountInfo.contactInfo.phone ||
        accountInfo.contactInfo.phone.raw.length < 5)
    ) {
      const { location } = history as History<{ phone?: boolean } | undefined>;
      if (location.state?.phone !== true)
        history.push(location.pathname, {
          ...location.state,
          phone: true,
        });
      setPopoverProps({
        anchorEl: e.currentTarget,
        route: "",
        stateMatch: (state) => Boolean(state.phone),
        anchorOrigin: { vertical: "top", horizontal: "center" },
        transformOrigin: { vertical: "top", horizontal: "center" },
        children: (
          <AccountPhone
            accountInfo={accountInfo}
            setOptimisticUpdate={setOptimisticUpdate}
            onSubmit={() => updateValue(newValue)}
          />
        ),
      });
    } else {
      updateValue(newValue);
    }
  };

  const updateValue = async (newValue: boolean) => {
    if (!accountInfo.uid) {
      return;
    }
    setOptimisticUpdate({
      ...accountInfo,
      notifications: {
        ...accountInfo.notifications,
        [category]: {
          ...accountInfo.notifications[category],
          [commType]: newValue,
        },
      },
    });
    try {
      await firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("users")
        .doc(accountInfo.uid)
        .update({
          [`notifications.${category}.${commType}`]: newValue,
        });
    } catch (error) {
      console.log(error);
      setOptimisticUpdate(null);
      toast({
        dialog: true,
        message: t("dashboard.account.errorChangingSetting"),
        color: "error",
      });
    }
  };

  const noPush =
    !firebase.messaging ||
    !firebase.pushSupported ||
    Notification.permission === "denied";

  const smsOptOut = commType === "sms" && accountInfo.contactInfo.smsOptOut;

  return (
    <FormControl error={(commType === "push" && noPush) || smsOptOut}>
      {popoverProps && <MUIPopover {...popoverProps} />}
      <FormControlLabel
        control={
          <Checkbox
            checked={checked && !smsOptOut}
            disabled={smsOptOut}
            onChange={onChange}
          />
        }
        label={t(`dashboard.account.${commType}Notifications`)}
      />

      {commType === "sms" && checked && !smsOptOut && (
        <Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            const { location } = history as History<
              { phone?: boolean } | undefined
            >;
            if (location.state?.phone !== true)
              history.push(location.pathname, {
                ...location.state,
                phone: true,
              });
            setPopoverProps({
              anchorEl: e.currentTarget,
              route: "",
              stateMatch: (state) => Boolean(state.phone),
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              children: (
                <AccountPhone
                  accountInfo={accountInfo}
                  setOptimisticUpdate={setOptimisticUpdate}
                />
              ),
            });
          }}
        >
          {!accountInfo.contactInfo.phone ||
          (accountInfo.contactInfo.phone &&
            accountInfo.contactInfo.phone.raw.length < 5)
            ? t("dashboard.account.setPhone")
            : accountInfo.contactInfo.phone.formatted}
        </Button>
      )}
      {smsOptOut && (
        <FormHelperText>
          {siteSettings.smsPhone
            ? t(`dashboard.account.smsOptedOutNumber`, {
                phoneNumber: siteSettings.smsPhone.formatted,
              })
            : t(`dashboard.account.smsOptedOutReply`)}
        </FormHelperText>
      )}
      {commType === "push" && checked && noPush && (
        <FormHelperText>
          {t(`dashboard.account.pushDisabledByBrowserOtherDevices`)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CommCheckbox;
