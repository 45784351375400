import { FC, useState } from "react";
import * as React from "react";
import { CatalogItem_WithDefaults } from "../../../database/catalogItem";
import { Button, DialogActions, IconButton } from "@material-ui/core";
import { history } from "../../App/history";
import ROUTES from "../../../utilities/constants/routes";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useCartApi from "../../CartManager/useCartApi";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CartItem } from "../../../database/cart";
import DeleteIcon from "@material-ui/icons/Delete";
import getItemPriceLabels from "./getItemPriceLabels";
import MUIDialog, {
  MUIDialogProps,
} from "../../Dashboard/Components/mui-dialog";
import { optionType } from "../../../database/option";
import AddToCart from "./addToCart";
import { getUnitName } from "../../../utilities/getUnitName";

interface CartItemsProps {
  catalogItem: CatalogItem_WithDefaults;
  cartItem: CartItem;
}

const CartItems: FC<CartItemsProps> = ({ catalogItem, cartItem }) => {
  const { t } = useTranslation();
  const cartApi = useCartApi();

  const { priceLabel } = getItemPriceLabels({
    t,
    cartItem,
  });

  const [dialogProps, setDialogProps] = useState<MUIDialogProps>();
  const removeConfirm = (e: React.SyntheticEvent, previousValue?: number) => {
    if (!window.location.pathname.includes("remove")) {
      history.push(`${ROUTES.CART}/${cartItem.cartItemId}/remove`);
    }
    setDialogProps({
      route: `${ROUTES.CART}/${cartItem.cartItemId}/remove`,
      children: (
        <React.Fragment>
          <DialogTitle className="smartWidthMd">
            {t("store.orders.removeFromCart")}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                if (previousValue !== undefined) {
                  cartApi.setItemCount(cartItem.cartItemId, previousValue);
                }

                history.goBack();
              }}
            >
              {t("store.cancel")}
            </Button>
            <Button
              onClick={() => {
                removeFromCart();
                history.goBack();
              }}
            >
              {t("store.confirm")}
            </Button>
          </DialogActions>
        </React.Fragment>
      ),
    });
  };
  const removeFromCart = () => {
    if (!catalogItem || catalogItem.unavailable) return;

    if (cartItem) {
      cartApi.removeItem(cartItem.cartItemId);
    }
  };

  return (
    <React.Fragment>
      {dialogProps && <MUIDialog {...dialogProps} />}

      <div
        className="checkoutButton"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <MUIDialog route={`${ROUTES.CART}/${catalogItem.itemId}/edit`}>
          <AddToCart
            catalogItem={catalogItem}
            cartItem={cartItem}
            submitButton={t("store.orders.updateOrder")}
            onSubmit={(count, instructions, selectedOptions) => {
              cartApi.setItemCount(cartItem.cartItemId, count);
              cartApi.setItemInstructions(cartItem.cartItemId, instructions);
              cartApi.setItemOptionSelections(
                cartItem.cartItemId,
                selectedOptions
              );
            }}
          />
        </MUIDialog>
        <div
          style={{ flexGrow: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            const path = `${ROUTES.CART}/${catalogItem.itemId}/edit`;
            if (!window.location.pathname.includes(path)) {
              history.push(path);
            }
          }}
        >
          <div>
            <Typography
              color="textPrimary"
              align="left"
              style={{
                wordBreak: "break-word",
                paddingRight: "0.5em",
              }}
              variant="h6"
            >
              {catalogItem.name}
            </Typography>
            <Typography align="left" color="primary">
              {cartItem.count.toLocaleString()}{" "}
              {catalogItem.unit &&
                getUnitName(catalogItem.unit, cartItem.count, t)}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div style={{ textAlign: "right" }}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  style={{ fontWeight: "bold" }}
                >
                  {priceLabel}
                </Typography>
              </div>
            </div>
          </div>

          <Typography align="left" color="textSecondary" variant="body2">
            {cartItem.instructions}
          </Typography>

          <div style={{ marginBottom: "max(1vw, 1em)" }}>
            <div>
              {cartItem.optionSelections?.map((selection, index) => {
                const option = cartItem.catalogItem.options.find(
                  (option) => option.optionId === selection.optionId
                );

                if (
                  selection.type === optionType.NUMBER &&
                  option?.type === optionType.NUMBER
                ) {
                  return (
                    <Typography
                      align="left"
                      variant="body2"
                      color="textSecondary"
                      key={index}
                    >
                      {option.name}: {selection.value} {option.unit ?? ""}
                    </Typography>
                  );
                } else if (
                  selection.type === optionType.LIST &&
                  option?.type === optionType.LIST
                ) {
                  return Object.keys(selection.selectedItems).map((key) => {
                    const optionItem = option.items.find(
                      (optionItem) => optionItem.id === key
                    );
                    if (optionItem) {
                      return (
                        <Typography
                          key={key}
                          align="left"
                          variant="body2"
                          color="textSecondary"
                        >
                          {optionItem.description}
                        </Typography>
                      );
                    } else {
                      return null;
                    }
                  });
                } else {
                  return null;
                }
              })}
            </div>
            <div style={{ marginTop: "1em" }} />
            <hr />
          </div>
        </div>

        <IconButton onClick={(e) => removeConfirm(e)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default CartItems;
