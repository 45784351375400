import { useCallback, useEffect, useState } from "react";

const useWindowResize = (source?: string, onResize?: () => void) => {
  const getOrientation = useCallback(() => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const touch = window.matchMedia("(hover: none)").matches;
    // console.log(
    //   "useWindowResize.ts. Invoked by:",
    //   source,
    //   "W:",
    //   width,
    //   "H:",
    //   height,
    //   "ORIENTATION:",
    //   height > width ? "p" : height === width ? "s" : "l",
    //   "TOUCH DEVICE:",
    //   touch
    // );
    // console.log(
    //   "search",
    //   window.innerHeight,
    //   window.screen.availHeight,
    //   window.screen.height,
    //   window.screen.orientation
    // );
    return height > width
      ? { o: "portrait", w: width, h: height, touch }
      : height === width
      ? { o: "square", w: width, h: height, touch }
      : { o: "landscape", w: width, h: height, touch };
  }, []);

  const [windowDims, setWindowDims] = useState(getOrientation);

  useEffect(() => {
    const handleResize = () => {
      //console.log("useWindowResize", "RESIZE DETECTED");
      setWindowDims(getOrientation());
      if (onResize) {
        onResize();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getOrientation, onResize]);
  return windowDims;
};

export default useWindowResize;
