import ReactDOM from "react-dom";
import { registerServiceWorker } from "./components/App/serviceWorker";
import GridbashApp from "./components/GridBash/gridbashApp";
import { GridBashFirebaseContext } from "./Firebase/context";
import { GridbashFirebase } from "./Firebase/gridBashFirebase";
import GridBashTheme from "./components/GridBash/gridBashTheme";
import SpinnerProvider from "./components/Spinner/spinnerProvider";

registerServiceWorker();

/**
 * The environment variables are populated as part of the deploy project script
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FRBS_API_KEY,
  authDomain: process.env.REACT_APP_FRBS_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FRBS_DATABASE_URL,
  projectId: process.env.REACT_APP_FRBS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FRBS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FRBS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FRBS_APP_ID,
};

const firebaseInstance = new GridbashFirebase({
  config: firebaseConfig,
  functionRegion: process.env.REACT_APP_FRBS_REGION ?? "",
  vapidKey: process.env.REACT_APP_FRBS_VAPID_KEY ?? "",
});

ReactDOM.render(
  <GridBashFirebaseContext.Provider value={firebaseInstance}>
    <GridBashTheme>
      <SpinnerProvider>
        <GridbashApp />
      </SpinnerProvider>
    </GridBashTheme>
  </GridBashFirebaseContext.Provider>,
  document.getElementById("root")
);
