import { memo, FC, useState } from "react";
import useWindowResize from "../../../utilities/useWindowResize";
import hero from "./img/bizz-owner2.jpeg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import WhyIcon from "@material-ui/icons/ArrowRight";

interface HeroProps {}

const Hero: FC<HeroProps> = memo(() => {
  const dimensions = useWindowResize();
  const { t } = useTranslation();
  const [imageInfo, setImageInfo] = useState({
    w: 0,
    h: 0,
    loaded: false,
  });

  return (
    <div
      style={{
        padding: dimensions.w >= 768 ? "64px 0" : "3vh 0",
        // display: "flex",
        position: "relative",
        width: "100%",
        overflow: "hidden",
        minHeight: "400px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          overflow: "hidden",
          backgroundColor: "rgba(243, 243, 245,0.5)",
        }}
      >
        <img
          className={imageInfo.loaded ? "anim_fadeIn_0503" : ""}
          src={hero}
          alt="happy business owner displaying open now sign"
          loading="lazy"
          style={{
            opacity: 0,
            verticalAlign: "middle",
            objectFit: "cover",
            filter: "blur(42px)",
            minWidth: "100%",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform:
            dimensions.w >= imageInfo.w
              ? "translate(-50%, 0%)"
              : /**
                 * I CALCULATED THESE BASED ON WHAT LOOKED LIKE A GOOD
                 * POSITION ON A PIXEL2 DEVICE (WIDTH 411):
                 *   translate(-73%, -20%)
                 *
                 * ($$$) NO ADJUSTMENT IS NEEDED WHEN THE VIEWPORT WIDTH
                 * IS EQUAL OR GREATER THAN THE IMAGE WIDTH
                 *
                 * 1068 -> WIDTH OF IMAGE BEING USED
                 * 657  -> 1068 - 411 = 657
                 * 50   -> REFERS TO THE VALUE WHEN NO ADJUSTMENT IS NEEDED ($$$)
                 * 23   -> 73 - 50
                 * 20   -> 20 - 0 (WHERE 0 IS THE *VALUE WHEN ADJUSTMENT IS NEEDED ($$$))
                 */
                `translate(-${50 + (23 / 657) * (1068 - dimensions.w)}%, -${
                  (20 / 657) * (1068 - dimensions.w)
                }%)`,
        }}
      >
        <img
          className={imageInfo.loaded ? "anim_fadeIn_0503" : ""}
          src={hero}
          alt="happy business owner displaying open now sign"
          loading="lazy"
          onLoad={(e) => {
            setImageInfo({
              w: e.currentTarget.clientWidth,
              h: e.currentTarget.clientHeight,
              loaded: true,
            });
          }}
          style={{
            opacity: 0,
            verticalAlign: "middle",
            objectFit: "cover",
            backgroundColor: "rgba(243, 243, 245,0.5)",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(243, 243, 245,0.5)",
          top: 0,
        }}
      ></div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            minWidth: "320px",
            width: "100%",
            maxWidth: "1280px",
            margin: "0px auto",
            paddingRight: "8px",
            paddingLeft: dimensions.w < 768 ? "16px" : "24px",
          }}
        >
          <div
            style={{
              width: dimensions.w >= 768 ? "8.3333%" : 0,
              paddingRight: dimensions.w >= 768 ? "16px" : 0,
            }}
          />
          <div
            style={{
              width: dimensions.w >= 768 ? "75%" : "100%",
              paddingRight: dimensions.w >= 768 ? "16px" : "8px",
              zIndex: 1,
            }}
          >
            <div
              className="anim_fadeIn_0510"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
                opacity: 0,
              }}
            >
              <Typography align="left" variant="h3">
                {t("gridbash.home.mainTitle")}
              </Typography>
              <div style={{ marginTop: "2em" }}>
                <Typography variant="h4" style={{ fontWeight: "normal" }}>
                  {t("gridbash.home.subTitle1")}
                </Typography>
              </div>
              <div
                style={{
                  marginTop: dimensions.w >= 768 ? "40px" : "16px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      const path = "/am-i-in/1";
                      if (!window.location.pathname.includes(path)) {
                        history.push(path);
                      }
                    }}
                  >
                    {t("gridbash.home.subTitle2")}
                  </Button>
                  <div style={{ width: "max(1em, 2vw)" }} />

                  <Button
                    className="anim_background"
                    startIcon={<ArrowForwardIcon />}
                    style={{ backgroundColor: "rgba(0,0,0,0.12)" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push("/applySequence/email");
                    }}
                  >
                    {t("gridbash.home.applyNow2")}
                  </Button>
                </div>
                <div style={{ flexGrow: 1 }} />
                <div></div>
              </div>
            </div>
            <div
              className="anim_moveRight_medium_0110"
              style={{ textAlign: "left", opacity: 0 }}
            >
              <Button
                startIcon={<WhyIcon />}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  const path = "/why-gridbash";
                  if (!window.location.pathname.includes(path)) {
                    history.push(path);
                  }
                }}
              >
                More Info
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Hero;
