import { Slider, SliderProps } from "@material-ui/core";
import { FC } from "react";
import { useDebounceState } from "../../../utilities/useDebounceState";

type DebouncedSliderProps = Omit<
  SliderProps,
  "value" | "defaultValue" | "onChange"
> & {
  debounce?: number;
  value: number;
  onChange: (newValue: number) => void;
};

const DebouncedSlider: FC<DebouncedSliderProps> = ({
  debounce = 100,
  value,
  onChange,
  ...rest
}) => {
  const [valueState, setValueState] = useDebounceState(
    value,
    onChange,
    debounce
  );

  return (
    <Slider
      value={valueState}
      onChange={(e, newValue) => {
        setValueState(newValue as number);
      }}
      {...rest}
    />
  );
};

export default DebouncedSlider;
