import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { memo, FC } from "react";
import { history } from "../../../../App/history";
import { History } from "history";
import { useWidgetEnvironment } from "../../widgetEnvironment";

export interface NavDrawerWidgetProps {
  anchor?: "top" | "bottom" | "left" | "right";
}

const NavDrawerWidget: FC<NavDrawerWidgetProps> = memo(() => {
  // This widget is just responsible for rendering the button. The actual drawer
  //   can be found in SiteDrawer
  const env = useWidgetEnvironment();

  return (
    <IconButton
      onClick={(e) => {
        if (env === "editor") {
          // Deliberately not consuming the event.
          // The click will fall through and focus the widget
        } else {
          e.stopPropagation();
          const { location } = history as History<
            { siteDrawer?: boolean } | undefined
          >;
          if (location.state?.siteDrawer !== true) {
            history.push(location.pathname, {
              ...location.state,
              siteDrawer: true,
            });
          }
        }
      }}
    >
      <MenuIcon />
    </IconButton>
  );
});

export default NavDrawerWidget;
