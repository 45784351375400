import { FC, memo, useContext } from "react";
import * as React from "react";
import * as R from "ramda";
import CartIndicator from "../../CartIndicator/cartIndicator";
import { IconButton, Typography } from "@material-ui/core";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { history } from "../../App/history";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";
import ROUTES from "../../../utilities/constants/routes";
import { hexToRgbString } from "../../../utilities/colorUtils";
import { matchPath, useLocation } from "react-router-dom";
import ContactIcon from "@material-ui/icons/Message";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GridIcon from "@material-ui/icons/ViewComfy";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import useWindowResize from "../../../utilities/useWindowResize";
import Badge from "@material-ui/core/Badge";
import { WellKnownPageId } from "../../../database/siteSettings";
import { BuilderContext } from "../../Dashboard/Builder/OrdersBuilder/builderContext";
import { useThemeColor } from "../../widgets/useThemeColor";
import { ThemeColor } from "../../../database/theme";
import UserButton from "./userButton";
import { useLatestMessageThread } from "../../../utilities/useLatestMessageThread";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accentColor: {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      opacity: 1,
    },
  })
);

interface STheaderProps {
  headerEl: React.MutableRefObject<HTMLDivElement | null>;
  hideTabs: boolean;
  widthCorrection?: number; // USED FOR builder PANEL CORRECTION
  overrideBreakPoint?: boolean; // IF TRUE BREAKPOINT WILL BE OVERRIDED AND SET TO MOBILE WIDTH
  opacityOverride?: boolean; // IF TRUE OPACITY WILL BE SET TO TRUE REGARDLESS OF ROUTE
  stickyOverride?: boolean; //IF TRUE SET HEADER TO BE POSITIONED STATIC
}

const STheader: FC<STheaderProps> = ({
  headerEl,
  hideTabs,
  widthCorrection,
  overrideBreakPoint,
  opacityOverride,
  stickyOverride,
}) => {
  const { t } = useTranslation();
  const { theme, theme2, _name, navigationMenu, extraPageOptions } =
    useSiteSettings();
  const themeColor = useThemeColor();
  const location = useLocation<{}>();

  const builder = useContext(BuilderContext);

  const match = matchPath<{ page: string }>(location.pathname, {
    path: `${ROUTES.rootPath}/:page?`,
  });
  let page: string | false = match?.params.page ?? false;

  // If they're on a page that's not in the menu, then no tab is selected
  if (
    !page ||
    (!["order"].includes(page) &&
      !extraPageOptions.pages.find((extraPage) => {
        const value = R.last(extraPage.path.split("/"));
        return value === page;
      }))
  ) {
    page = false;
  }

  const onHomePage = !builder
    ? !match ||
      !match.params ||
      !match.params.page ||
      match.params.page === ROUTES.ML_CONTACT.substring(1) ||
      match.params.page === ROUTES.ML_MYACCOUNT.substring(1) ||
      match.params.page === ROUTES.ML_DRAWER.substring(1)
    : false;

  const [latestThread] = useLatestMessageThread();
  const showMessageBadge =
    latestThread &&
    !latestThread.orderId &&
    Object.values(latestThread.messages).some(
      (message) => message.from === "admin" && !message.read
    );

  const dimensions = useWindowResize("sTheader");

  const breakPoint = widthCorrection ? 768 + widthCorrection : 768;

  const themedBackgroundColor = themeColor(navigationMenu.backgroundColor);

  const classes = useStyles();

  return (
    <React.Fragment>
      <div
        ref={headerEl}
        className={
          stickyOverride ? "sTstoreHeaderOuter" : "sTstoreHeaderOuter sticky"
        }
        style={{
          transition: "all 0.5s linear 0.3s",
          fontWeight: "bold",
        }}
      >
        {/* BEGIN MOBILE HEADER */}
        <div
          className="headerBarHeight headerInner"
          style={{
            display: overrideBreakPoint
              ? "flex"
              : dimensions.w >= breakPoint
              ? "none"
              : "flex",
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
            backgroundColor:
              themedBackgroundColor && navigationMenu.backgroundOpacity
                ? opacityOverride || onHomePage
                  ? `rgba(${hexToRgbString(themedBackgroundColor)},${
                      navigationMenu.backgroundOpacity / 100
                    })`
                  : `rgba(${hexToRgbString(themedBackgroundColor)},1)`
                : undefined,
            color: theme2.colors[ThemeColor.onPrimary].value,
            transition: "all 0.5s ease 0s",
          }}
        >
          <IconButton
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              if (builder) {
                return;
              }
              history.push(location.pathname, {
                ...location.state,
                storeDrawer: true,
              });
            }}
          >
            <MenuIcon htmlColor={themeColor(navigationMenu.color)} />
          </IconButton>
          <Button
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              if (builder) {
                return;
              }
              history.replace(ROUTES.STORE);
            }}
          >
            <Typography
              noWrap
              style={{
                fontFamily:
                  theme.appName && theme.appName.fontFamily
                    ? theme.appName.fontFamily
                    : theme.appFont,
                textTransform: "none",
                color: themeColor(navigationMenu.color),
              }}
            >
              {_name}
            </Typography>
          </Button>

          <div
            style={{
              transition: "all 0.3s ease 0s",
              flexGrow: 1,
            }}
          />
          {!builder && <CartIndicator useAccentColor />}
          <IconButton
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              if (builder) {
                return;
              }
              if (!window.location.pathname.includes(ROUTES.SEARCH)) {
                history.push(ROUTES.SEARCH);
              }
            }}
          >
            <SearchIcon htmlColor={themeColor(navigationMenu.color)} />
          </IconButton>
        </div>
        {/* END MOBILE HEADER */}

        {/* BEGIN DESKTOP HEADER */}
        <div
          className={
            !builder ? "sticky anim_fadeIn_0300" : "sticky anim_fadeIn_0310"
          }
          style={{
            display: overrideBreakPoint
              ? "none"
              : dimensions.w >= breakPoint
              ? "block"
              : "none",
            top: 0,
            color: theme2.colors[ThemeColor.onPrimary].value,
            transition: "all 0.5s ease 0s",
            opacity: 0,
            backgroundColor:
              themedBackgroundColor && navigationMenu.backgroundOpacity
                ? opacityOverride || onHomePage
                  ? `rgba(${hexToRgbString(themedBackgroundColor)},${
                      navigationMenu.backgroundOpacity / 100
                    })`
                  : `rgba(${hexToRgbString(themedBackgroundColor)},1)`
                : undefined,
          }}
        >
          {process.env.NODE_ENV === "development" && (
            <React.Fragment>
              <IconButton
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  if (builder) {
                    return;
                  }
                  history.replace("/");
                }}
              >
                <GridIcon htmlColor={themeColor(navigationMenu.color)} />
              </IconButton>
              &nbsp;
            </React.Fragment>
          )}
          <UserButton />
          &nbsp;
          <IconButton
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              history.push(location.pathname, {
                ...location.state,
                messages: true,
                threadId:
                  latestThread && !latestThread.orderId
                    ? latestThread.threadId
                    : undefined,
              });
            }}
            style={{
              marginBottom: "-0.25em",
              color: themeColor(navigationMenu.color),
            }}
          >
            <Badge
              color="secondary"
              variant="dot"
              invisible={!showMessageBadge}
            >
              <ContactIcon />
            </Badge>
          </IconButton>
          {!builder && (
            <React.Fragment>
              &nbsp;
              <CartIndicator useAccentColor />
            </React.Fragment>
          )}
          &nbsp;
          <IconButton
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              if (builder) {
                return;
              }
              if (!window.location.pathname.includes(ROUTES.SEARCH)) {
                history.push(ROUTES.SEARCH);
              }
            }}
          >
            <SearchIcon htmlColor={themeColor(navigationMenu.color)} />
          </IconButton>
          <Toolbar style={{ justifyContent: "center", minHeight: 0 }}>
            <Typography
              align="center"
              style={{
                marginTop: theme.appName.marginTop
                  ? `${theme.appName.marginTop}px`
                  : undefined,
                fontFamily: theme.appName.fontFamily ?? theme.appFont,
                fontSize: `clamp(1.2rem, 4vw, ${theme.appName.fontSize}rem)`,
                color: themeColor(navigationMenu.color),
                textAlign: "left",
                lineHeight: "1",
                textTransform: "none",
                whiteSpace: "normal",
                wordBreak: "break-word",
                overflow: "hidden",
              }}
            >
              {_name}
            </Typography>
          </Toolbar>
          <div
            style={{
              display: "inline-block",
              maxWidth: "100vw",
              color: "white",
            }}
          >
            <Tabs
              value={page}
              indicatorColor="secondary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              style={{ minHeight: 0 }}
            >
              {navigationMenu.items.map((navItem) => {
                if (
                  (onHomePage && navItem.pageId === WellKnownPageId.home) ||
                  navItem.pageId === WellKnownPageId.message ||
                  navItem.pageId === WellKnownPageId.track ||
                  navItem.pageId === WellKnownPageId.favorites ||
                  navItem.pageId === WellKnownPageId.account
                ) {
                  // These are shown higher in the page and do not need to be repeated
                  return null;
                }

                let label = navItem.textSettings.text;
                if (!label && navItem.textSettings.textKey) {
                  label = t(navItem.textSettings.textKey);
                }

                let value = navItem.pageId;
                if (navItem.type === "custom") {
                  const extraPage = extraPageOptions.pages.find((extraPage) => {
                    return extraPage.pageId === navItem.pageId;
                  });
                  if (extraPage) {
                    value = R.last(extraPage.path.split("/")) ?? navItem.pageId;
                  }
                }

                return (
                  <Tab
                    key={navItem.navItemId}
                    fullWidth
                    value={value}
                    className={
                      navItem.pageId === WellKnownPageId.order
                        ? classes.accentColor
                        : undefined
                    }
                    style={{
                      color:
                        navItem.pageId === WellKnownPageId.order
                          ? undefined
                          : page === navItem.pageId
                          ? undefined
                          : themeColor(navigationMenu.color),
                      whiteSpace: "nowrap",
                      minWidth: "10em",
                      opacity:
                        navItem.pageId === WellKnownPageId.order
                          ? undefined
                          : 0.8,
                      fontFamily:
                        navItem.pageId === WellKnownPageId.order
                          ? "Arial"
                          : undefined,
                    }}
                    label={label}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (builder) {
                        return;
                      }
                      if (navItem.type === "standard") {
                        switch (navItem.pageId) {
                          case WellKnownPageId.home:
                            history.push(ROUTES.STORE);
                            break;
                          case WellKnownPageId.order:
                            history.push(ROUTES.ORDER);
                            break;
                          case WellKnownPageId.track:
                            history.push(ROUTES.TRACK);
                            break;
                          case WellKnownPageId.favorites:
                            history.push(ROUTES.FAVORITES);
                            break;
                          case WellKnownPageId.account:
                            history.push(ROUTES.ACCOUNT);
                            break;
                          case WellKnownPageId.message:
                            history.push(location.pathname, {
                              ...location.state,
                              messages: true,
                              threadId:
                                latestThread && !latestThread.orderId
                                  ? latestThread.threadId
                                  : undefined,
                            });
                            break;
                        }
                      } else if (navItem.type === "custom") {
                        const extraPage = extraPageOptions.pages.find(
                          (extraPage) => {
                            return extraPage.pageId === navItem.pageId;
                          }
                        );
                        if (extraPage) {
                          history.replace(extraPage.path);
                        }
                      }
                    }}
                  />
                );
              })}
            </Tabs>
          </div>
        </div>
        {/* END DESKTOP HEADER */}
      </div>
    </React.Fragment>
  );
};

export default memo(STheader);
