import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { applyBodyStyle } from "../../utilities/bodyStyleCoordinator";

export type SpinnerLag = "none" | "short" | "long";

export interface SpinnerProps {
  isOpen: boolean;
  lag?: SpinnerLag;
  text?: string;
  icon?: ReactElement;
  animationClass?: string;
}

const Spinner: FC<SpinnerProps> = ({
  isOpen,
  lag = "long",
  text,
  icon,
  animationClass = "anim_spinnerMove",
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (isOpen) {
      return applyBodyStyle((style) => {
        style.overflow = "hidden";
      });
    }
  }, [isOpen]);

  return (
    <div
      className={
        lag === "none"
          ? "spinnerDiv anim_fadeIn_0300 zIndex-spinner"
          : lag === "short"
          ? "spinnerDiv anim_fadeIn_0303 zIndex-spinner"
          : "spinnerDiv anim_fadeIn_0307 zIndex-spinner"
      }
      style={{
        opacity: 0,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: isOpen ? "flex" : "none",
      }}
    >
      <div
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={animationClass}>{icon ?? <SwapVertIcon />}</div>
        <Typography>{text ?? t("common.loading")}</Typography>
      </div>
    </div>
  );
};

export default Spinner;
