import { FC } from "react";
import { useTranslation } from "react-i18next";
import Dinero from "dinero.js";
import { PriceInfo } from "../../database/order";
import { Typography } from "@material-ui/core";

export interface InvoiceSummaryProps {
  priceInfo: PriceInfo;
  source: "subtotal" | "total";
}

const InvoicePriceSummary: FC<InvoiceSummaryProps> = ({
  priceInfo,
  source,
}) => {
  const { t } = useTranslation();
  return (
    <div
      // className={source === "total" ? classes.mainColor : undefined}
      style={{
        padding: "0.5em",
        borderRadius: source === "total" ? "4px" : undefined,
        // backgroundColor: source === "subtotal" ? "rgba(0,0,0,0.2)" : undefined,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography>{t("dashboard.orders.subtotal")}</Typography>
        <Typography color={priceInfo.beforeTax < 0 ? "error" : undefined}>
          {Dinero({ amount: priceInfo.beforeTax }).toFormat()}
        </Typography>
      </div>
      {Object.entries(priceInfo.taxTable).map(
        ([taxRate, tax]) =>
          tax !== 0 && (
            <div
              key={taxRate}
              style={{
                display: "flex",
                justifyContent: "space-between",

                flexWrap: "wrap",
              }}
            >
              <Typography variant="body2">Tax at {taxRate}%</Typography>
              <Typography
                variant="body2"
                color={priceInfo.tax < 0 ? "error" : undefined}
              >
                {Dinero({ amount: tax }).toFormat()}
              </Typography>
            </div>
          )
      )}
      {priceInfo.tip && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="body2">{t("dashboard.orders.tip")}</Typography>
          <Typography variant="body2">
            {Dinero({ amount: priceInfo.tip }).toFormat()}
          </Typography>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          flexWrap: "wrap",
        }}
      >
        <Typography variant="h6">{t("dashboard.orders.total")}</Typography>
        <Typography
          variant="h6"
          color={priceInfo.total < 0 ? "error" : undefined}
        >
          {Dinero({ amount: priceInfo.total }).toFormat()}
        </Typography>
      </div>
    </div>
  );
};

export default InvoicePriceSummary;
