import { useRouteMatch } from "react-router-dom";
import ROUTES from "../../../utilities/constants/routes";
import { history } from "../../App/history";
import MessageThread from "./messageThread";

/**
 * Wraps the STMessageThread in a component which will get the thread info
 * from the route. Used for message deeplinks.
 */
export const MessagesDeepLink = () => {
  const match = useRouteMatch<{ threadId: string }>();
  const threadId = match.params.threadId;

  return (
    <MessageThread
      threadId={threadId}
      onCancel={() => {
        // Since we deep linked in, going back will take us out of the app. Instead, send them home
        history.replace(ROUTES.STORE);
      }}
    />
  );
};
