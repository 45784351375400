import { FC, useEffect, useMemo } from "react";
import * as React from "react";
import "../css/checkout.css";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { getTotalsForItems } from "../../../utilities/orderProcessing";
import useCartApi from "../../CartManager/useCartApi";
import EmptyCartNotification from "./emptyCartNotification";
import ClosedNotification from "./closedNotification";
import { getNextTimeOpen_fns } from "../../../utilities/checkStoreOpen";
import CheckoutMain from "./checkoutMain";
import { add, differenceInMinutes } from "date-fns";

export interface CheckoutPageProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckoutPage: FC<CheckoutPageProps> = React.memo(({ setMainBgImage }) => {
  const siteSettings = useSiteSettings();
  const { cartItems } = useCartApi();
  const cartTotals = useMemo(() => getTotalsForItems(cartItems), [cartItems]);

  const nextTimeOpen = useMemo(() => {
    const earliest = add(new Date(), {
      //RETURNS DATETIME OF: NOW PLUS STORE INCREMENT + 1 MINUTE BUFFER
      minutes: siteSettings.orderIncrement + 1,
    });
    return getNextTimeOpen_fns(earliest, siteSettings);
  }, [siteSettings]);

  const notAcceptingOrders =
    !nextTimeOpen ||
    differenceInMinutes(new Date(), nextTimeOpen) >
      siteSettings.orderMaximumTime;

  useEffect(() => {
    setMainBgImage(false);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMainBgImage]);

  return (
    <div>
      {cartTotals.itemCount === 0 ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <EmptyCartNotification />
        </div>
      ) : notAcceptingOrders ? (
        <ClosedNotification nextTimeOpen={nextTimeOpen} />
      ) : (
        <CheckoutMain />
      )}
    </div>
  );
});

export default CheckoutPage;
