import { CSSProperties } from "react";
import { FC, useEffect, useState } from "react";
import { useFetchCategory } from "../../../../customization/useFetchCategory";
import { Catalog_Database } from "../../../../database/catalog";
import { useSiteFirebase } from "../../../../Firebase/context";
import { storeKey } from "../../../../Firebase/siteFirebase";
import useWindowResize from "../../../../utilities/useWindowResize";
import CatalogItemWidget from "./catalogItemWidget";

/**
 * These props get stored in the database
 */
export interface CatalogGridWidgetProps {
  itemTemplateId: string;
  catalogId: string | null;
  breakpointMd: number;
  breakpointLg: number;
  columnsSm: number;
  columnsMd: number;
  columnsLg: number;
}

export interface EditorOverrides {
  style?: CSSProperties;
}

const CatalogGridWidget: FC<CatalogGridWidgetProps & EditorOverrides> = ({
  itemTemplateId,
  catalogId,
  breakpointMd,
  breakpointLg,
  columnsSm,
  columnsMd,
  columnsLg,
  style,
  children,
}) => {
  const firebase = useSiteFirebase();
  const [catalog, setCatalog] = useState<Catalog_Database>();
  useEffect(() => {
    if (catalogId) {
      setCatalog(undefined);
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("catalogs")
        .doc(catalogId)
        .onSnapshot(
          (snapshot) => {
            const data = snapshot.data() as Catalog_Database | undefined;
            setCatalog(data);
          },
          (err) => {
            setCatalog(undefined);
            console.error("error fetching catalog", err);
          }
        );
      return unsubscribe;
    }
  }, [catalogId, firebase.firestore]);

  const { items /*, page, totalPages, nextPage, prevPage*/ } = useFetchCategory(
    catalog?.categories[0]
  );

  const dimensions = useWindowResize();
  let columns = columnsSm;
  if (dimensions.w >= breakpointLg) {
    columns = columnsLg;
  } else if (dimensions.w >= breakpointMd) {
    columns = columnsMd;
  }
  columns = Math.max(1, columns);
  if (Number.isNaN(columns)) {
    columns = 1;
  }

  return (
    <div
      style={{
        minHeight: "4em",
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, minmax(0px, 1fr))`,
        ...style,
      }}
    >
      {items.map((item, i) => (
        <CatalogItemWidget
          key={item.itemId}
          templateId={itemTemplateId}
          catalogItemId={null}
          catalogItem={item}
        />
      ))}
      {children}
    </div>
  );
};

export default CatalogGridWidget;
