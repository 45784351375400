import Typography from "@material-ui/core/Typography";
import { FC, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import useWindowResize from "../../../utilities/useWindowResize";
import { history } from "../../App/history";
import MUIDialog, {
  MUIDialogProps,
} from "../../Dashboard/Components/mui-dialog";
import STorderDateModal from "../Modals/sTorderDate";
import TimingIcon from "@material-ui/icons/Timer";
import { DeliveryOption } from "./checkoutMain";
import { Tooltip } from "@material-ui/core";

interface TimePickerProps {
  deliveryOption: DeliveryOption;
  setRequestedTime: React.Dispatch<React.SetStateAction<Date>>;
  requestedTime: Date;
  initPath: string;
  builder: boolean;
}

const TimePicker: FC<TimePickerProps> = ({
  requestedTime,
  setRequestedTime,
  deliveryOption,
  initPath,
  builder,
}) => {
  const dimensions = useWindowResize("timePicker");
  const [dialogProps, setDialogProps] = useState<MUIDialogProps>();
  const siteSettings = useSiteSettings();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {dialogProps && <MUIDialog {...dialogProps} />}
      <div
        className={!builder ? "checkoutButton" : undefined}
        style={{
          opacity: 1,
          display: "grid",
          gridTemplateColumns: dimensions.w >= 768 ? "12em 1fr" : "auto 1fr",
          gridAutoRows: "4em",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
        onClick={(e) => {
          if (builder) return;
          if (!window.location.pathname.includes("time")) {
            history.push(`${initPath}/options/time`);
          }
          setDialogProps({
            route: `${initPath}/options/time`,

            children: (
              <STorderDateModal
                requestedTime={requestedTime}
                setRequestedTime={setRequestedTime}
                initPath={`${initPath}/options/time`}
                delivery={deliveryOption === "delivery"}
              />
            ),
          });
        }}
      >
        <Tooltip
          title={
            !builder
              ? ""
              : `${t("dashboard.builder.primaryDark")} - ${t(
                  "dashboard.builder.onPrimary"
                )}`
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: dimensions.w >= 768 ? "flex-start" : "center",
              paddingLeft: "1em",
              paddingRight: "1em",
              backgroundColor: siteSettings.theme2.colors.primaryDark.value,
              color: siteSettings.theme2.colors.onPrimary.value,
              height: "100%",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          >
            <TimingIcon />
            {dimensions.w >= 768 && (
              <React.Fragment>
                &nbsp;&nbsp;<Typography>{t("store.orders.when")}</Typography>
              </React.Fragment>
            )}
          </div>
        </Tooltip>

        <Tooltip
          title={
            !builder
              ? ""
              : `${t("dashboard.builder.primaryLight")} - ${t(
                  "dashboard.builder.textSecondary"
                )} - ${t("dashboard.builder.textPrimary")}`
          }
        >
          <div
            style={{
              backgroundColor: siteSettings.theme2.colors.primaryLight.value,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              overflow: "hidden",
              padding: "0 1em",
            }}
          >
            <Typography noWrap color="textSecondary">
              {deliveryOption === "delivery"
                ? t("store.track.estimatedTransit")
                : t("store.track.estimatedPickup")}
            </Typography>
            <Typography noWrap variant="body2" color="textPrimary">
              {requestedTime.toLocaleDateString(siteSettings.currency.locale, {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </div>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default TimePicker;
