import Typography from "@material-ui/core/Typography";
import { memo, Fragment, FC } from "react";
import CheckedIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import OrdersUI from "./ordersUI";
import StoreUI from "./storeUI";
import CatalogUI from "./catalogUI";
import CommUI from "./commUI";
import useWindowResize from "../../../../utilities/useWindowResize";
import PaymentUI from "./paymentUI";
import RolesUI from "./rolesUI";

interface FeaturesItemsProps {}

const FeaturesItems: FC<FeaturesItemsProps> = memo(() => {
  const dimensions = useWindowResize("featuresItems");
  return (
    <Fragment>
      <div
        style={{
          marginBottom: "max(1em, 2vw)",
          padding: "2em",
          backgroundColor: "rgba(240, 248, 255,1)",
        }}
      >
        <Typography variant="h4">
          Features to help your business succeed
        </Typography>
        <div style={{ display: "inline-block", marginTop: "max(1em, 2vw)" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Use your business domain name for your store and emails, or one of
              ours
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Offer scheduled pickup and delivery times
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Send bills to your customer's device for immediate payment
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Accept payments through most payment methods
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Accept payments in over 20 currencies
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Allow tipping and bill splitting
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Issue partial or full refunds and upcharges
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Enable customers to create an account with extra benefits
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Automate order communications using Email, SMS, and phone Push
              notifcations
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Offer customer support through instant chat
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Allow indoor customers to scan your QR code and submit waiterless
              orders
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Create product variations with ease
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              Manage your store from any device
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <CheckedIcon />
            </IconButton>
            <Typography variant="body2" align="left">
              And so much more!
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns:
            dimensions.w < 650
              ? "1fr"
              : dimensions.w >= 1134
              ? "1fr 1fr 1fr"
              : "1fr 1fr",
          gridTemplateRows: "324px",
          gap: "max(1em, 2vw)",
          maxWidth: "1280px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          style={{
            overflow: "hidden",
            maxWidth: "100%",
          }}
        >
          <StoreUI />

          <div style={{ marginTop: "1em" }} />
          <Typography>User Experience Management</Typography>
        </div>
        <div
          style={{ overflow: "hidden", maxWidth: "100%", borderRadius: "5px" }}
        >
          <OrdersUI />
          <div style={{ marginTop: "1em" }} />
          <Typography>Orders Management</Typography>
        </div>
        <div style={{ overflow: "hidden", maxWidth: "100%" }}>
          <CatalogUI />
          <div style={{ marginTop: "1em" }} />
          <Typography>Catalog / Inventory Management</Typography>
        </div>
        <div style={{ overflow: "hidden", maxWidth: "100%" }}>
          <CommUI />
          <div style={{ marginTop: "1em" }} />
          <Typography>Communications Management</Typography>
        </div>
        <div style={{ overflow: "hidden", maxWidth: "100%" }}>
          <RolesUI />
          <div style={{ marginTop: "1em" }} />
          <Typography>Employee Role Management</Typography>
        </div>

        <div style={{ overflow: "hidden", maxWidth: "100%" }}>
          <PaymentUI />
          <div style={{ marginTop: "1em" }} />
          <Typography>Payments / Invoice Management</Typography>
        </div>
      </div>
    </Fragment>
  );
});

export default FeaturesItems;
