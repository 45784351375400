import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import MUIDialog from "./mui-dialog";
import { history } from "../../App/history";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import MUIPopover from "./mui-popover";
import IconButton from "@material-ui/core/IconButton";
import { useMeasure } from "../../../utilities/useMeasure";
import { useTheme } from "@material-ui/core";

interface SmartSelectProps<T> {
  initPath: string;
  /**
   * WE SHOULD USE A DIALOG FOR SELECTS HAVING A LONG
   * LIST OF OPTIONS AS A CLOSE BUTTON IS PROVIDED
   */
  modalType: "dialog" | "popover";
  label?: string;
  optionValues: readonly T[];
  optionStrings: string[];
  disabledOptions?: boolean[];
  currentSelection: T | undefined;
  handleClick: (selected: T) => void;
  /**
   * WE SHOULD SET DELAYRENDER TO TRUE FOR SELECTS HAVING A LONG
   * LIST OF OPTIONS AS THE MODAL OPENS FAST WITH A SIMPLE LOADING STRING
   * AND THEN FADES INTO ALL OPTIONS
   */
  delayRender?: boolean;
  disabled?: boolean;
  icon?: { icon: string; onClick: () => void };
  //IF TRUE THE SMARTSELECT DOESN'T REALLY DO ANYTHING INTERNALLY
  //IT ONLY SERVES UP A UI OF A SELECT WITH A VALUE. THE ACTUAL PICKING
  //LOGIC HAPPENS OUTSIDE
  onlyLooksOnClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onlyLooksValue?: string;
  color?: "secondary";
}

function SmartSelect<T>({
  initPath,
  modalType,
  label,
  optionValues,
  optionStrings,
  disabledOptions,
  currentSelection,
  handleClick,
  delayRender,
  disabled = false,
  icon,
  onlyLooksOnClick,
  onlyLooksValue,
  color,
}: SmartSelectProps<T>) {
  const DELAY = 500;
  const currentRoute = window.location.pathname;
  const elementSelected = useRef<HTMLLIElement | null>(null);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (!currentRoute.includes(initPath)) {
      setAnchor(null);
      if (delayRender) setShowContents(false);
    }
    if (anchor) {
      setTimeout(
        () => {
          const current = elementSelected.current;
          if (!current) return;
          current.scrollIntoView();
        },

        DELAY + 1
      );
    }
  }, [anchor, elementSelected, initPath, currentRoute, setAnchor, delayRender]);

  const [showContents, setShowContents] = useState(!delayRender);
  useEffect(() => {
    if (anchor && delayRender) {
      const timer = setTimeout(() => {
        setShowContents(true);
      }, DELAY);
      return () => clearTimeout(timer);
    }
  }, [anchor, delayRender]);

  const [measureRef, bounds] = useMeasure();

  return (
    <FormControl fullWidth>
      {anchor && (
        <React.Fragment>
          {modalType === "dialog" ? (
            <MUIDialog route={initPath} keepPosition={true}>
              {!showContents ? (
                <DialogContent className="smartWidthMd">
                  <Typography align="center" color="textSecondary">
                    Loading...
                  </Typography>
                </DialogContent>
              ) : (
                <DialogContent
                  className={
                    delayRender
                      ? "smartWidthMd anim_fadeIn_0503"
                      : "smartWidthMd"
                  }
                  style={{
                    padding: 0,
                    opacity: delayRender ? 0 : 1,
                  }}
                >
                  <List>
                    {optionValues.map((option, index) => (
                      <MenuItem
                        ref={
                          option === currentSelection ? elementSelected : null
                        }
                        selected={option === currentSelection}
                        style={{ maxWidth: "100%" }}
                        button
                        key={`${option}_${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(option);
                        }}
                      >
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {optionStrings ? optionStrings[index] : option}\
                        </div>
                      </MenuItem>
                    ))}
                  </List>
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    history.goBack();
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </MUIDialog>
          ) : (
            <MUIPopover
              route={initPath}
              anchorEl={anchor}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <div style={{ width: bounds.width }}>
                {optionValues.map((option, index) => (
                  <MenuItem
                    ref={option === currentSelection ? elementSelected : null}
                    disabled={Boolean(disabledOptions?.[index])}
                    selected={option === currentSelection}
                    style={{ maxWidth: "100%" }}
                    button
                    key={`${option}_${index}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(option);
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {optionStrings ? optionStrings[index] : option}
                    </div>
                  </MenuItem>
                ))}
              </div>
            </MUIPopover>
          )}
        </React.Fragment>
      )}
      <div
        ref={measureRef}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          color: color ? theme.palette.secondary.main : undefined,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (onlyLooksOnClick) {
            onlyLooksOnClick(e);
          } else {
            if (!disabled) {
              if (!window.location.pathname.includes(initPath))
                history.push(initPath, history.location.state);

              setAnchor(e.currentTarget);
            }
          }
        }}
      >
        <div
          className={disabled ? undefined : "selectHover"}
          style={{
            paddingRight: "30px",
            borderBottom: "1px solid rgba(0,0,0,0.42)",
            width: "100%",
            paddingBottom: "4px",
            opacity: !disabled ? 1 : "0.2",
            transition: "0.3s ease 0s",
          }}
        >
          <Typography
            align="left"
            style={{
              wordBreak: "break-word",
            }}
          >
            {onlyLooksValue
              ? onlyLooksValue
              : currentSelection !== undefined
              ? optionStrings[optionValues.indexOf(currentSelection)] ?? `\xa0`
              : `\xa0`}
          </Typography>
        </div>
        <div
          style={{
            marginLeft: "-30px",
            position: "absolute",
            right: 0,
            opacity: !disabled ? 1 : "0.2",
          }}
        >
          <Icon>arrow_drop_down</Icon>
        </div>
      </div>
      <div style={{ marginTop: "4px" }} />
      {label && (
        <div
          style={{
            display: icon ? "flex" : undefined,
            alignItems: icon ? "center" : undefined,
            justifyContent: icon ? "space-between" : undefined,
            marginTop: icon ? "-12px" : undefined,
          }}
        >
          <FormHelperText
            style={{
              color: color ? theme.palette.primary.contrastText : undefined,
            }}
          >
            {label}
          </FormHelperText>
          {icon && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                icon.onClick();
              }}
            >
              <Icon>{icon.icon}</Icon>
            </IconButton>
          )}
        </div>
      )}
    </FormControl>
  );
}

export default SmartSelect;
