import { Message, MessageThread } from "../database/message";
import { useState, useEffect, useMemo } from "react";
import usePrevious from "./usePrevious";
import { storeKey } from "../Firebase/siteFirebase";
import { useSiteFirebase } from "../Firebase/context";
import type firebase from "firebase";

export const byTimeSent = (a: Message, b: Message) =>
  a.timeSent.toMillis() - b.timeSent.toMillis();

export const useMessageThread = (
  threadId?: string | null,
  initialThread?: MessageThread
) => {
  const firebase = useSiteFirebase();
  const [thread, setThread] = useState(initialThread);
  const [error, setError] = useState<firebase.firestore.FirestoreError | null>(
    null
  );
  useEffect(() => {
    if (threadId) {
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("messageThreads")
        .doc(threadId)
        .onSnapshot(
          (orderDoc) => {
            const thread = orderDoc.data() as MessageThread | undefined;
            setError(null);
            setThread(thread);
          },
          (error) => {
            setError((prev) => {
              if (prev && prev.code === error.code) {
                return prev;
              }
              return error;
            });
            setThread(undefined);
          }
        );
      return unsubscribe;
    } else {
      setThread(undefined);
      return;
    }
  }, [firebase.firestore, threadId]);

  const value = useMemo(() => {
    const messages = thread
      ? Object.values(thread.messages).sort(byTimeSent)
      : [];
    return {
      thread,
      messages,
      error,
    };
  }, [thread, error]);

  return value;
};

/**
 * Listens for changes to the messages, and marks as read any messages from the
 * other participant.
 */
export const useMarkReadMessages = (
  threadId: string | undefined | null,
  messages: Message[],
  reader: "admin" | "customer"
) => {
  const firebase = useSiteFirebase();

  const otherParticipant = reader === "admin" ? "customer" : "admin";
  const unreadMessages = useMemo(
    () => messages.filter((m) => m.from === otherParticipant && !m.read),
    [messages, otherParticipant]
  );

  const prevMessages = usePrevious(messages);
  useEffect(() => {
    if (threadId && messages !== prevMessages && unreadMessages.length > 0) {
      console.log("UPDATING MESSAGES TO READ.");
      const update: firebase.firestore.UpdateData = {};
      if (reader === "admin") {
        // Mark the entire thread as read
        update.read = true;
      }
      // Mark the messages as read
      unreadMessages.forEach((message) => {
        update[`messages.${message.messageId}.read`] = true;
      });
      firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("messageThreads")
        .doc(threadId)
        .update(update)
        .catch((error) => {
          console.log("ERROR UPDATING MESSAGES TO READ:", error);
        });
    }
  }, [
    prevMessages,
    messages,
    unreadMessages,
    firebase.firestore,
    threadId,
    reader,
  ]);

  return unreadMessages;
};
