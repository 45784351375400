import { useRef, FC, useEffect, useMemo } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../utilities/constants/routes";
import "../css/cart.css";
import useCartApi from "../../CartManager/useCartApi";
import { Redirect } from "react-router-dom";
import CartSummary from "../cartSummary";
import SuborderSummary from "../suborderSummary";
import { useTranslation } from "react-i18next";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { Button, Container, Typography } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EmptyCartNotification from "../Checkout/emptyCartNotification";
import { stripeMinimumCharges } from "../Checkout/stripeMinimumCharges";
import Dinero from "dinero.js";
import AddIcon from "@material-ui/icons/AddBox";
import useWindowResize from "../../../utilities/useWindowResize";
import { getTotalsForItems } from "../../../utilities/orderProcessing";

export interface CartProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Cart: FC<CartProps> = React.memo(({ setMainBgImage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { cartItems } = useCartApi();
  const cartTotals = useMemo(() => getTotalsForItems(cartItems), [cartItems]);
  const initialItems = useRef(cartItems);
  const initialCartTotals = useMemo(
    () => getTotalsForItems(initialItems.current),
    []
  );
  const siteSettings = useSiteSettings();
  const dimensions = useWindowResize("cart");
  const minimumCharge =
    stripeMinimumCharges[siteSettings.currency.currency] || 0;

  useEffect(() => {
    setMainBgImage(false);
    window.scrollTo(0, 0);
  }, [setMainBgImage]);

  if (initialCartTotals.itemCount === 0) {
    return <Redirect to={ROUTES.ORDER} />;
  }

  return (
    <Container
      className="anim_moveUp_small_0300"
      maxWidth="md"
      style={{
        marginTop: "max(1.5vw, 1.5em)",
      }}
    >
      <div
        style={{
          marginBottom: "1em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography color="textPrimary" variant="h4" align="left">
          {t("store.orders.shoppingCart")}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            history.replace(ROUTES.ORDER);
          }}
        >
          {t("store.orders.addItem")}
        </Button>
      </div>
      <SuborderSummary cartItems={cartItems} />

      {Object.keys(cartItems).length === 0 && <EmptyCartNotification />}
      <CartSummary />
      <div style={{ marginTop: "1em" }} />
      {Object.keys(cartItems).length > 0 && (
        <div style={{ bottom: "1em" }} className="sticky">
          {cartTotals.total.getAmount() < minimumCharge && (
            <Typography color="secondary">
              {t("store.orders.orderTooSmall", {
                minimum: Dinero({ amount: minimumCharge }).toFormat(),
              })}
            </Typography>
          )}
          <div style={{ marginTop: "1em" }} />
          <ButtonGroup>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              style={{
                backgroundColor: "white",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              onClick={(e) => {
                e.stopPropagation();
                history.replace(ROUTES.ORDER);
              }}
            >
              {t("store.orders.add")}
            </Button>

            <Button
              endIcon={
                (dimensions.w <= 333 &&
                  cartTotals.total.getAmount() < 10000000) ||
                (dimensions.w <= 342 &&
                  cartTotals.total.getAmount() < 100000000) ||
                dimensions.w > 342 ? (
                  <div
                    style={{
                      backgroundColor:
                        siteSettings.theme2.colors.primaryDark.value,
                      padding: "0.25em",
                      borderRadius: "4px",
                      minWidth: 0,
                    }}
                  >
                    <Typography variant="body2" noWrap>
                      {cartTotals.total.toFormat()}
                    </Typography>
                  </div>
                ) : undefined
              }
              disabled={
                cartTotals.total.getAmount() <
                (stripeMinimumCharges[siteSettings.currency.currency] || 0)
              }
              variant="contained"
              color="primary"
              onClick={async (e) => {
                e.stopPropagation();
                history.push(ROUTES.CHECKOUT);
              }}
            >
              {t("store.orders.checkout")}
            </Button>
          </ButtonGroup>
        </div>
      )}
    </Container>
  );
});

export default Cart;
