import { FC, useState } from "react";
import { useMeasure } from "../../../../utilities/useMeasure";
import { srcSetString } from "../../../Main/main";

export interface ImageWidgetProps {
  datasource?: "item";
  url: string;
  urls: { [key: string]: string };
  alt?: string;
  widthWeight: number;
  topWeight: number;
  leftWeight: number;
  /**
   * aspect ratio = width / height.
   *
   * When rendering, the width is determined by the # of columns and the window width.
   * The height then is determined by the width and aspect ratio. In other words:
   * changing the window width leads to a change in widget width, leads to a change in
   * widget height.
   */
  aspectRatio: number;
  boxShadowX: number;
  boxShadowY: number;
  boxShadowSpread: number;
  boxShadowBlur: number;
  boxShadowColor: string;
  borderWidth: number;
  borderRadius: number;
  borderStyle:
    | "hidden"
    | "dotted"
    | "dashed"
    | "solid"
    | "double"
    | "groove"
    | "ridge"
    | "inset"
    | "outset"
    | "none"
    | undefined;
  borderColor: string;
}

const ImageWidget: FC<ImageWidgetProps> = ({
  url,
  alt,
  urls,
  widthWeight,
  topWeight,
  leftWeight,
  aspectRatio,
  datasource,
  borderWidth = 0,
  borderColor = "#000000",
  borderRadius = 0,
  borderStyle = "solid",
  boxShadowX = 0,
  boxShadowY = 0,
  boxShadowBlur = 0,
  boxShadowSpread = 0,
  boxShadowColor = "#000000",
}) => {
  const [imageInfo, setImageInfo] = useState<{
    loaded: boolean;
    error: boolean;
    widthNatural: number;
    aspectRatio: number;
  }>({
    loaded: false,
    error: false,
    widthNatural: 0,
    aspectRatio: 0,
  });

  const [mainDiv, bounds] = useMeasure();

  const boxShadowUsed: boolean =
    (typeof boxShadowBlur === "number" && boxShadowBlur > 0) ||
    (typeof boxShadowSpread === "number" && boxShadowSpread > 0) ||
    (typeof boxShadowX === "number" && boxShadowX !== 0) ||
    (typeof boxShadowY === "number" && boxShadowY !== 0);

  const marginLeftBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowX && boxShadowX < 0
    ? Math.abs(
        (boxShadowX ?? 0) - (boxShadowSpread ?? 0) - (boxShadowBlur ?? 0)
      )
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);
  const marginRightBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowX && boxShadowX > 0
    ? (boxShadowX ?? 0) + (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0)
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);

  const marginTopBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowY && boxShadowY < 0
    ? Math.abs(
        (boxShadowY ?? 0) - (boxShadowSpread ?? 0) - (boxShadowBlur ?? 0)
      )
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);
  const marginBottomBoxShadow = !boxShadowUsed
    ? 0
    : boxShadowY && boxShadowY >= 0
    ? (boxShadowY ?? 0) + (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0)
    : (boxShadowSpread ?? 0) + (boxShadowBlur ?? 0);

  const containerHeight =
    bounds.width / (aspectRatio || 1) +
    marginTopBoxShadow +
    marginBottomBoxShadow;

  //SET IMAGE SIZE
  const imageWidth = bounds.width * widthWeight;
  const imageHeight = imageWidth / (imageInfo.aspectRatio || 1);

  //SET IMAGE POSITION
  const imageLeft = (imageWidth - bounds.width) * leftWeight;
  const imageTop = (imageHeight - containerHeight) * topWeight;

  return (
    <div
      ref={mainDiv}
      className="anim_fadeIn_0303"
      style={{
        position: "relative",
        width: "100%",
        height: containerHeight,
        display: imageInfo.loaded ? undefined : "none",
        opacity: 0,
        margin: `0 ${marginRightBoxShadow}px 0 ${marginLeftBoxShadow}px`,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          boxShadow: boxShadowUsed
            ? `${boxShadowX ?? 0}px ${boxShadowY ?? 0}px ${
                boxShadowBlur ?? 0
              }px ${boxShadowSpread ?? 0}px ${boxShadowColor ?? "black"}`
            : undefined,
          borderRadius: borderRadius ? `${borderRadius}px` : undefined,
          border: `${borderWidth}px ${borderStyle} ${borderColor}`,
        }}
      >
        <img
          alt={alt}
          src={url}
          srcSet={srcSetString(urls)}
          style={{
            verticalAlign: "middle",
            minHeight: "100%",
            minWidth: "100%",
            width: imageInfo.loaded ? imageWidth : undefined,
            height: imageInfo.loaded ? imageHeight : undefined,
            marginLeft: -imageLeft,
            marginTop: -imageTop,
          }}
          onLoad={(e) => {
            setImageInfo({
              loaded: true,
              error: false,
              widthNatural: e.currentTarget.naturalWidth,
              aspectRatio:
                e.currentTarget.naturalWidth / e.currentTarget.naturalHeight,
            });
          }}
        />
      </div>
    </div>
  );
};

export default ImageWidget;
