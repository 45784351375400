import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CartItem,
  isListOptionSelection,
  isNumberOptionSelection,
} from "../../database/cart";
import { getUnitName } from "../../utilities/getUnitName";
import { isListOption, isNumberOption } from "../../database/option";
import { Typography } from "@material-ui/core";
import Dinero from "dinero.js";
import PriceSnippet from "./priceSnippet";

interface InvoiceItemProps {
  cartItem: CartItem;
  /**
   * Description of why this card is in the invoice.
   * Eg: "Added:"
   */
  verb: string;
  /**
   * If true, the price values will be inverted. Used for refunds
   */
  negative: boolean;
}

const InvoiceItem: FC<InvoiceItemProps> = ({ cartItem, verb, negative }) => {
  const catalogItem = cartItem.catalogItem;
  const { t } = useTranslation();

  const multiplier = negative ? -1 : 1;

  const price = useMemo(() => {
    let priceEach = Dinero({ amount: catalogItem.price });
    let denominator = catalogItem.unit?.priceDenominator ?? 1;
    return priceEach.multiply(multiplier * (cartItem.count / denominator));
  }, [
    cartItem.count,
    catalogItem.price,
    catalogItem.unit?.priceDenominator,
    multiplier,
  ]);

  return (
    <div style={{ padding: "0 0.5em" }}>
      {verb && <Typography color="secondary">{verb.toUpperCase()}</Typography>}

      <div
        style={{
          textAlign: "left",
          marginTop: "0.5em",
        }}
      >
        <div
          style={{
            padding: "0.5em",
            backgroundColor: "rgba(0,0,0,0.075)",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
          }}
        >
          <Typography style={{ wordBreak: "break-word" }}>
            {catalogItem.unit
              ? `${catalogItem.name} ${cartItem.count} ${getUnitName(
                  catalogItem.unit,
                  cartItem.count,
                  t
                )}`
              : cartItem.count > 1
              ? `${catalogItem.name} x${cartItem.count}`
              : catalogItem.name}
          </Typography>
          <PriceSnippet price={price} catalogItem={catalogItem} />
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          padding: "0.5em 0.5em 0.5em",
          backgroundColor: "rgba(0,0,0,0.075)",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          marginBottom: "1em",
        }}
      >
        <div>
          {cartItem.optionSelections?.map((selection) => {
            const option = catalogItem.options.find(
              (option) => option.optionId === selection.optionId
            );
            if (isListOption(option) && isListOptionSelection(selection)) {
              return (
                <div key={option.optionId}>
                  <Typography variant="subtitle1">{option.name}</Typography>
                  {option.items.map((item) => {
                    const isSelected = selection.selectedItems[item.id];
                    if (!isSelected) {
                      return null;
                    }
                    return (
                      <div key={item.id} style={{ paddingRight: "0.5em" }}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          key={item.id}
                          style={{ padding: "0 0.25em" }}
                        >
                          {item.description}
                        </Typography>

                        <PriceSnippet
                          price={Dinero({ amount: item.price }).multiply(
                            multiplier * cartItem.count
                          )}
                          catalogItem={catalogItem}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            } else if (
              isNumberOption(option) &&
              isNumberOptionSelection(selection)
            ) {
              return (
                <div key={option.optionId} style={{ paddingRight: "0.5em" }}>
                  <Typography variant="subtitle1">{option.name}</Typography>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ padding: "0 0.25em" }}
                  >
                    {selection.value} {option.unit ? option.unit : ""}
                  </Typography>
                  <PriceSnippet
                    price={Dinero({
                      amount:
                        (option.price / option.priceDenominator) *
                        selection.value *
                        cartItem.count,
                    }).multiply(multiplier)}
                    catalogItem={catalogItem}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default InvoiceItem;
