import { useState, FC, useRef } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";

import useRefreshInterval from "../../../utilities/useRefreshInterval";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import useToast from "../../Main/useToast";
import { Container, DialogTitle, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { history } from "../../App/history";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  add,
  format,
  isMatch,
  startOfDay,
  isPast,
  differenceInMinutes,
} from "date-fns";
import {
  getNextTimeOpen_fns,
  isOpenAt_fns,
} from "../../../utilities/checkStoreOpen";
import MUIPopover, {
  MUIPopoverProps,
} from "../../Dashboard/Components/mui-popover";
import { defaultOrderIncrement } from "../../Dashboard/Settings/orderTiming";
import WarningIcon from "@material-ui/icons/Warning";

interface STorderDateModalProps {
  requestedTime: Date;
  setRequestedTime: React.Dispatch<React.SetStateAction<Date>>;
  initPath: string;
  delivery: boolean;
}

const STorderDateModal: FC<STorderDateModalProps> = React.memo(
  ({ requestedTime, setRequestedTime, initPath, delivery }) => {
    const anchor1 = useRef(null);
    const toast = useToast();
    const { t } = useTranslation();
    const siteSettings = useSiteSettings();
    const { orderIncrement } = siteSettings;

    const increment = orderIncrement || defaultOrderIncrement;

    const [newDate, setNewDate] = useState(requestedTime);

    const [dateKey, setDateKey] = useState(
      format(newDate, "yyyy-MM-ddTHH:mm:ss")
    );
    const [timeKey, setTimeKey] = useState(
      format(newDate, "yyyy-MM-ddTHH:mm:ss")
    );
    const [selectedDay, setSelectedDay] = useState(
      format(newDate, "yyyy-MM-dd")
    );
    const [dateValid, setDateValid] = useState(
      isMatch(selectedDay, "yyyy-MM-dd")
    );
    const regexDate = RegExp(
      /^([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
    );
    let year: string = "";
    let month: string = "";
    let day: string = "";

    const regexTime = RegExp(
      /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]) ?([AaPp][Mm])?$/
    );
    const [hours, setHours] = useState<string>(format(newDate, "HH"));
    const [minutes, setMinutes] = useState(format(newDate, "mm"));
    const [timeValid, setTimeValid] = useState(
      isMatch(hours, "HH") && isMatch(minutes, "mm")
    );
    const [popoverProps, setPopoverProps] = useState<MUIPopoverProps>();

    useRefreshInterval(10000);
    return (
      <React.Fragment>
        {popoverProps && <MUIPopover {...popoverProps} />}
        <DialogTitle disableTypography ref={anchor1} className="smartWidthMd">
          <Typography variant="h6">
            {delivery
              ? t("store.orders.provideTimeTransit")
              : t("store.orders.provideTimePickup")}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningIcon color="secondary" />
            &nbsp;&nbsp;
            <Typography variant="body2" color="secondary">
              {t("store.orders.estimateReminder")}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent className="smartWidthMd" style={{ textAlign: "center" }}>
          <FormControl fullWidth>
            <TextField
              key={dateKey}
              label={t("dashboard.settings.enterDate")}
              helperText={
                !dateValid ? t("dashboard.settings.pickDateOrType") : undefined
              }
              error={!dateValid}
              inputProps={{
                min: format(startOfDay(new Date()), "yyyy-MM-dd"),
              }}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={selectedDay}
              onChange={(e) => {
                const replace = /\//gi;
                const date = e.currentTarget.value.replace(replace, "-");
                const result = regexDate.exec(date);
                if (!result) {
                  setDateValid(false);
                } else {
                  setDateValid(true);
                  year = result[1];
                  month = result[2];
                  day = result[3];
                  setSelectedDay(`${year}-${month}-${day}`);
                }
              }}
              onBlur={() => {
                if (dateValid && timeValid) {
                  if (isPast(new Date(`${selectedDay}T${23}:${59}:00`))) {
                    setSelectedDay(format(new Date(), "yyyy-MM-dd"));
                    setNewDate(
                      new Date(`${selectedDay}T${hours}:${minutes}:00`)
                    );
                    setDateKey(selectedDay);
                    toast({
                      dialog: true,
                      message: t("store.orders.dateInThePast"),
                      color: "normal",
                    });
                  } else {
                    setNewDate(
                      new Date(`${selectedDay}T${hours}:${minutes}:00`)
                    );
                  }
                }
              }}
            />
            {dateValid && (
              <FormHelperText>
                {new Date(`${selectedDay}T08:00:00`).toLocaleDateString([], {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </FormHelperText>
            )}
          </FormControl>
          <br /> <br />
          <FormControl fullWidth>
            <TextField
              key={timeKey}
              InputLabelProps={{
                shrink: true,
              }}
              label={t("store.orders.enterTime")}
              helperText={
                !timeValid ? t("dashboard.settings.pickTimeOrType") : undefined
              }
              error={!timeValid}
              type="time"
              defaultValue={format(newDate, "HH:mm")}
              onChange={(e) => {
                const result = regexTime.exec(e.target.value);
                let hours: string | number = "";
                let minutes = "";
                if (!result) {
                  setTimeValid(false);
                } else {
                  hours = result[1];
                  minutes = result[2];
                  if (result[3]) {
                    //SAFARI ONLY
                    /**
                     * WITH A WORKING NATIVE TIME PICKER AM/PM IS NOT
                     * PART OF e.target.value
                     */
                    hours = parseInt(result[1]);
                    if (hours > 11) {
                      //NO NEED TO DO ANYTHING WE JUST USE THE PROVIDED HOURS
                    } else {
                      if (result[3].toUpperCase() === "PM") {
                        hours = hours + 12;
                      } else {
                        /** CONVERT SINGLE DIGIT BACK TO TWO DIGIT */
                        hours = `0${hours}`;
                      }
                    }
                  }
                  setHours("" + hours);
                  setMinutes(minutes);
                  setTimeValid(true);
                }
              }}
              onBlur={() => {
                if (dateValid && timeValid) {
                  setNewDate(new Date(`${selectedDay}T${hours}:${minutes}:00`));
                }
              }}
            />

            {timeValid && (
              <FormHelperText>
                {new Date(
                  `2020-11-25T${hours}:${minutes}:00`
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </FormHelperText>
            )}
          </FormControl>
          <br />
          <br />
          <Button
            startIcon={<ArrowRightIcon />}
            variant="outlined"
            onClick={() => {
              const newTime = getNextTimeOpen_fns(
                add(new Date(), { minutes: increment + 1 }),
                siteSettings
              );
              if (newTime) {
                setNewDate(newTime);
                setSelectedDay(format(newTime, "yyyy-MM-dd"));
                setDateKey(format(new Date(), "yyyy-MM-ddTHH:mm:ss"));
                setTimeKey(format(new Date(), "yyyy-MM-ddTHH:mm:ss"));
              }
            }}
          >
            {t("store.checkout.setTimeToNext")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.goBack()}>{t("store.cancel")}</Button>
          <Button
            onClick={() => {
              console.log("sTorderDate. REQUESTED DATE TIME:", newDate);
              const earliest =
                getNextTimeOpen_fns(
                  add(new Date(), { minutes: increment + 1 }),
                  siteSettings
                ) || add(new Date(), { minutes: increment });
              console.log(
                "sTorderDate. EARLIEST AVAILABLE DATE TIME:",
                earliest
              );
              if (
                differenceInMinutes(newDate, earliest) >= 0 &&
                isOpenAt_fns(newDate, siteSettings)
              ) {
                // REQUESTING A DELIVERY DATE THATS PAST THE EARLIEST ALLOWED
                // AND THE STORE IS OPEN THEN
                setRequestedTime(newDate);
                history.goBack();
              } else {
                const momentToRequest = newDate < earliest ? earliest : newDate;

                if (!window.location.pathname.includes("next")) {
                  history.push(`${initPath}/next`);
                }
                setPopoverProps({
                  anchorEl: anchor1.current,
                  route: `${initPath}/next`,
                  anchorOrigin: { vertical: "top", horizontal: "center" },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  children: (
                    <Container maxWidth="md">
                      <DialogTitle>{t("store.orders.setToNext")}</DialogTitle>
                      <DialogContent>
                        <Typography>
                          {t("store.orders.slotNotAvailable", {
                            next: getNextTimeOpen_fns(
                              momentToRequest,
                              siteSettings
                            )?.toLocaleDateString([], {
                              weekday: "long",
                              day: "numeric",
                              year: "numeric",
                              month: "long",
                              hour: "2-digit",
                              minute: "2-digit",
                            }),
                          })}
                        </Typography>
                        <br />
                        <Typography>
                          {t("store.orders.proceedWithTime")}
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => history.goBack()}>
                          {t("store.cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            const newTime = getNextTimeOpen_fns(
                              momentToRequest,
                              siteSettings
                            );
                            if (newTime) setRequestedTime(newTime);
                            history.go(-2);
                          }}
                        >
                          {t("store.proceed")}
                        </Button>
                      </DialogActions>
                    </Container>
                  ),
                });
              }
            }}
          >
            {t("store.submit")}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
);

export default STorderDateModal;
