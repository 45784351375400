import { Typography } from "@material-ui/core";
import { Fragment, FC } from "react";
import { CatalogItem_WithDefaults } from "../../database/catalogItem";

interface PriceSnippetProps {
  price: Dinero.Dinero;
  catalogItem: CatalogItem_WithDefaults;
}

/**
 * Displays a price and its tax component
 */
const PriceSnippet: FC<PriceSnippetProps> = ({ price, catalogItem }) => {
  if (price.getAmount() === 0) {
    return null;
  }

  let tax;
  if (catalogItem.priceIncludesTax) {
    tax = price
      .multiply(catalogItem.taxPercentage / 100)
      .divide(1 + catalogItem.taxPercentage / 100);
  } else {
    tax = price.multiply(catalogItem.taxPercentage / 100);
  }

  return (
    <Fragment>
      <Typography
        align="right"
        color={price.getAmount() < 0 ? "error" : undefined}
        style={{ whiteSpace: "nowrap" }}
      >
        {price.toFormat()}
      </Typography>
      <Typography variant="caption" component="div" align="right">
        {tax.toFormat()} tax at {catalogItem.taxPercentage}%
      </Typography>
    </Fragment>
  );
};

export default PriceSnippet;
