import { useEffect, useState } from "react";
import { useItemLabelSearch } from "./useItemLabelSearch";
import { useItemNameSearch } from "./useItemNameSearch";

interface Options {
  searchString: string;
  selectedLabels: Record<string, true>;
  pageSize?: number;
  // If true, only get items to display on the store (ie, active catalog, non-hidden)
  forStore?: boolean;
}

let callbacks: (() => void)[] = [];

export const clearItemCache = () => {
  callbacks.forEach((callback) => callback());
};

/**
 * Combines the functionality of useItemLabelSearch and useItemNameSearch.
 * If labels are provided, a label search will be returned. Otherwise, a
 * name search will be returned.
 */
export const useItemSearch = (options: Options) => {
  const [cacheBust, setCacheBust] = useState(0);
  const nameValue = useItemNameSearch({
    ...options,
    cacheBust,
  });
  const labelValue = useItemLabelSearch({
    ...options,
    cacheBust,
  });

  useEffect(() => {
    let callback = () => setCacheBust((prev) => prev + 1);
    callbacks.push(callback);
    return () => {
      callbacks.filter((val) => val !== callback);
    };
  }, []);

  if (Object.keys(options.selectedLabels).length > 0) {
    return labelValue;
  } else {
    return nameValue;
  }
};
