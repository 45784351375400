import { FC, useEffect, useMemo, useState } from "react";
import { CatalogContext } from "./catalogApiContext";
import { storeKey } from "../../../../Firebase/siteFirebase";
import { useSiteFirebase } from "../../../../Firebase/context";
import {
  CatalogAggregation,
  LabelAggregation,
} from "../../../../database/aggregations";
import useSiteUser from "../../../UserProvider/useSiteUser";
import { useOptimisticUpdate } from "../../../../utilities/useOptimisticUpdate";

/**
 * Manages the data for editing the catalog, and makes it available via context
 */
const CatalogApiProvider: FC = ({ children }) => {
  const { role } = useSiteUser();
  const firebase = useSiteFirebase();

  const [
    catalogSummariesInDb,
    setCatalogSummariesInDb,
  ] = useState<CatalogAggregation>({});
  useEffect(() => {
    if (role) {
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("aggregations")
        .doc("catalogs")
        .onSnapshot(
          (snapshot) => {
            const data = snapshot.data() as CatalogAggregation | undefined;
            if (data) {
              setCatalogSummariesInDb(data);
            }
          },
          (err) => {
            console.error("error fetching catalog aggregation", err);
          }
        );
      return unsubscribe;
    }
  }, [firebase.firestore, role]);
  const [
    catalogSummaries,
    setOptimisticUpdateCatalogSummaries,
  ] = useOptimisticUpdate(catalogSummariesInDb);

  const [labelsInDb, setLabelsInDb] = useState<LabelAggregation>({});
  useEffect(() => {
    if (role) {
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc(storeKey)
        .collection("aggregations")
        .doc("labels")
        .onSnapshot(
          async (doc) => {
            const data = doc.data() as LabelAggregation | undefined;
            if (data) {
              setLabelsInDb(data);
            } else {
              setLabelsInDb({});
            }
          },
          (error) => {
            console.log(error);
          }
        );
      return unsubscribe;
    }
  }, [firebase.firestore, role]);
  const [labels, setOptimisticUpdateLabels] = useOptimisticUpdate(labelsInDb);

  const value = useMemo(
    () => ({
      catalogSummaries,
      labels,
      setOptimisticUpdateCatalogSummaries,
      setOptimisticUpdateLabels,
    }),
    [
      catalogSummaries,
      labels,
      setOptimisticUpdateCatalogSummaries,
      setOptimisticUpdateLabels,
    ]
  );

  return (
    <CatalogContext.Provider value={value}>{children}</CatalogContext.Provider>
  );
};

export default CatalogApiProvider;
