import { CartItem } from "../../../database/cart";
import { calculateCartItemPrice } from "../../../utilities/orderProcessing";
import { getUnitName } from "../../../utilities/getUnitName";
import { TFunction } from "i18next";

interface Options {
  cartItem: CartItem;
  t: TFunction;
}

/**
 * Helper method for standardizing the way that item prices are displayed.
 * This way all the item components can have similar formatting
 */
const getItemPriceLabels = ({ cartItem, t }: Options) => {
  const { perUnit, total } = calculateCartItemPrice(cartItem);
  const { unit } = cartItem.catalogItem;

  const priceLabel = t("store.orders.priceAndTax", {
    price: total.price.toFormat(),
    tax: total.tax.toFormat(),
  });

  let priceEachLabel = "";
  if (!perUnit.price.equalsTo(total.price)) {
    if (unit) {
      priceEachLabel = t("store.orders.priceAndTaxPerUnit", {
        price: perUnit.price.toFormat(),
        tax: perUnit.tax.toFormat(),
        amount: unit.priceDenominator,
        unit: getUnitName(unit, unit.priceDenominator, t),
      });
    } else {
      priceEachLabel = t("store.orders.priceAndTaxEach", {
        price: perUnit.price.toFormat(),
        tax: perUnit.tax.toFormat(),
      });
    }
  }

  return {
    priceLabel,
    priceEachLabel,
  };
};

export default getItemPriceLabels;
