import { useEffect, useRef, useState } from "react";

/**
 * Like useState, but also tracks when it changes and starts a deboune
 * timer, after which point a callback is called. This can be used
 * to update an input field or other ui immediately, but after the
 * debounce elapses do something
 *
 * @example
 * ```typescript
 * const onDebouncedChange = (newName: string) => {
 *   // Insert something expensive with the name here
 * }
 * const [name, setName] = useDebounceState('Nick', onDebouncedChange);
 *
 * return <input value={name} />
 * ````
 */
export const useDebounceState = <T>(
  value: T,
  onDebouncedChange: (value: T) => void,
  duration = 100
) => {
  const [valueState, setValueState] = useState(value);
  useEffect(() => {
    // The prop is the source of truth, so if it changes, update the state
    setValueState(value);
  }, [value]);

  let timerId = useRef(-1);
  let firstRender = useRef(true);
  // Save the latest callback function, so that when the timeout
  //   expires we can call the latest one, even if it changed from
  //   when the timer was started.
  const callback = useRef(onDebouncedChange);
  callback.current = onDebouncedChange;
  const latestProp = useRef(value);
  latestProp.current = value;
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (duration <= 0) {
      return;
    }

    if (timerId.current === -1) {
      timerId.current = window.setTimeout(() => {
        timerId.current = -1;
        setValueState((prev) => {
          setTimeout(() => {
            if (latestProp.current !== prev) {
              callback.current(prev);
            }
          });
          return prev;
        });
      }, duration);
    }
  }, [valueState, duration]);

  return [valueState, setValueState] as const;
};
