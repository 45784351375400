import { Country } from "./countries";

export const formatNumber = (text?: string, country?: Country) => {
  if (!text) return "";
  if (!country) return text;

  const { format } = country;

  // If the text is very short or the country has no format, return the text as is
  if (text.length <= 2 || !format) {
    return text;
  }

  const formattedObject = format.split("").reduce(
    (acc, character) => {
      if (acc.remainingText.length === 0) {
        return acc;
      }

      if (character !== ".") {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText,
        };
      }

      const [head, ...tail] = acc.remainingText;

      return {
        formattedText: acc.formattedText + head,
        remainingText: tail,
      };
    },
    {
      formattedText: "",
      remainingText: text.split(""),
    }
  );

  let formattedNumber = formattedObject.formattedText;

  // Always close brackets
  if (formattedNumber.includes("(") && !formattedNumber.includes(")"))
    formattedNumber += ")";
  return formattedNumber;
};
