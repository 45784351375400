import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ROUTES from "../../../utilities/constants/routes";
import { AccountInfo } from "../../Main/useMain";
import SMSIcon from "@material-ui/icons/Sms";
import { history } from "../../App/history";
import AccountNotifications from "../Modals/accountNotifications";
import MUIDialog from "../../Dashboard/Components/mui-dialog";

interface NotificationsListItemProps {
  initPath: string;
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
}

const NotificationsListItem: FC<NotificationsListItemProps> = ({
  initPath,
  accountInfo,
  setOptimisticUpdate,
}) => {
  const { t } = useTranslation();

  const popoverPath = `${initPath}/notifications`;

  const notificationTypes: string[] = [];
  if (accountInfo.notifications.customer.email) {
    notificationTypes.push(t("store.account.email"));
  }
  if (accountInfo.notifications.customer.push) {
    notificationTypes.push(t("store.account.push"));
  }
  if (
    accountInfo.notifications.customer.sms &&
    !accountInfo.contactInfo.smsOptOut
  ) {
    notificationTypes.push(t("store.account.sms"));
  }

  return (
    <React.Fragment>
      <ListItem
        disableRipple
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(popoverPath);
        }}
      >
        <ListItemIcon>
          <SMSIcon />
        </ListItemIcon>
        <ListItemText
          primary={t("store.account.notifications")}
          secondary={
            <span style={{ display: "flex", flexWrap: "wrap" }}>
              {notificationTypes.length === 0
                ? t("store.account.noNotifications")
                : t("store.account.notificationsBy", {
                    types: notificationTypes.join(", "),
                  })}
            </span>
          }
        />
      </ListItem>

      <MUIDialog route={popoverPath}>
        <AccountNotifications
          accountInfo={accountInfo}
          setOptimisticUpdate={setOptimisticUpdate}
          initPath={`${ROUTES.ACCOUNT}/notifications`}
        />
      </MUIDialog>
    </React.Fragment>
  );
};

export default React.memo(NotificationsListItem);
