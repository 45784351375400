import { FC } from "react";
import * as React from "react";
import ROUTES from "../../../utilities/constants/routes";
import STguestInfoModal from "../Modals/sTguestInfo";
import useSiteUser from "../../UserProvider/useSiteUser";
import { useTranslation } from "react-i18next";
import SectionSignedOut from "../Components/sectionSignedOut";
import { useHistory } from "react-router-dom";
import { GuestDetails } from "./checkoutMain";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import MUIDialog from "../../Dashboard/Components/mui-dialog";

export interface SectionUserSOProps {
  guestDetails: GuestDetails;
  setGuestDetails: React.Dispatch<React.SetStateAction<GuestDetails>>;
}

const SectionUserSO: FC<SectionUserSOProps> = ({
  guestDetails,
  setGuestDetails,
}) => {
  const { user } = useSiteUser();
  const { t } = useTranslation();
  const history = useHistory();

  if (user) {
    return null;
  }
  return (
    <React.Fragment>
      <MUIDialog route={`${ROUTES.CHECKOUT}/guest`}>
        <STguestInfoModal
          initialGuestDetails={guestDetails}
          onSubmit={(guestDetails: GuestDetails) => {
            setGuestDetails(guestDetails);
            history.replace(`${ROUTES.CHECKOUT}/options`);
          }}
          initPath={`${ROUTES.CHECKOUT}/guest`}
        />
      </MUIDialog>

      <div style={{ marginBottom: "1em" }}>
        <SectionSignedOut />
        <br />
        <Button
          startIcon={<PersonIcon />}
          onClick={(e) => {
            e.stopPropagation();
            if (!window.location.pathname.includes("guest")) {
              history.push(`${ROUTES.CHECKOUT}/guest`);
            }
          }}
        >
          {t("store.orders.guestCheckout")}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SectionUserSO;
