import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import { AccountInfo } from "../../Main/useMain";
import PhoneIcon from "@material-ui/icons/Phone";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import AccountPhone from "../../Store/Modals/accountPhone";
import MUIDialog from "../Components/mui-dialog";

interface PhoneListItemProps {
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
  initPath: string;
}

const PhoneListItem: FC<PhoneListItemProps> = ({
  accountInfo,
  setOptimisticUpdate,
  initPath,
}) => {
  const { t } = useTranslation();

  const popoverPath = `${initPath}/phone`;

  return (
    <React.Fragment>
      <ListItem
        disableRipple
        button
        onClick={(e) => {
          e.stopPropagation();
          history.push(popoverPath);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <PhoneIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={t("store.account.accountPhone")}
          secondary={
            !accountInfo.contactInfo.phone ||
            (accountInfo.contactInfo.phone &&
              accountInfo.contactInfo.phone.raw.length < 5)
              ? t("store.account.noPhoneButton")
              : accountInfo.contactInfo.phone.formatted
          }
        />
      </ListItem>

      <MUIDialog route={popoverPath}>
        <AccountPhone
          accountInfo={accountInfo}
          setOptimisticUpdate={setOptimisticUpdate}
        />
      </MUIDialog>
    </React.Fragment>
  );
};

export default PhoneListItem;
