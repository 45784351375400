import {
  FC,
  useMemo,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { useContextThrowUndefined } from "../../../utilities/useContextThrowUndefined";

export interface WidgetGlobalStateType {
  collapsedWidgets: { [cellId: string]: boolean };
}

const WidgetGlobalStateContext = createContext<
  | {
      state: WidgetGlobalStateType;
      setState: Dispatch<SetStateAction<WidgetGlobalStateType>>;
    }
  | undefined
>(undefined);

export const useWidgetGlobalState = () =>
  useContextThrowUndefined(WidgetGlobalStateContext);

interface WidgetGlobalStateProviderProps {}

export const WidgetGlobalStateProvider: FC<WidgetGlobalStateProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<WidgetGlobalStateType>({
    collapsedWidgets: {},
  });
  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  return (
    <WidgetGlobalStateContext.Provider value={value}>
      {children}
    </WidgetGlobalStateContext.Provider>
  );
};
