import { DialogActions, DialogContent, TextField } from "@material-ui/core";
import { memo, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import { INPUT_MAX_LENGTH } from "../../../utilities/constants/appConstants";
import Button from "@material-ui/core/Button";

export interface InstructionsModalProps {
  initialInstructions: string;
  onSubmit: (instructions: string) => void;
}

const InstructionsModal: FC<InstructionsModalProps> = memo(
  ({ initialInstructions, onSubmit }) => {
    const { t } = useTranslation();
    const [instructions, setInstructions] = useState(initialInstructions);

    return (
      <div className="smartWidthMd">
        <DialogContent>
          <TextField
            multiline
            label="Instructions"
            placeholder="Enter text"
            type="text"
            fullWidth
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            onBlur={(e) =>
              setInstructions(
                e.target.value.trim().substring(0, INPUT_MAX_LENGTH)
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setInstructions("");
            }}
          >
            Clear
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button onClick={() => history.goBack()}>{t("store.cancel")}</Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onSubmit(instructions.trim().substring(0, INPUT_MAX_LENGTH));
              history.goBack();
            }}
          >
            {t("store.submit")}
          </Button>
        </DialogActions>
      </div>
    );
  }
);

export default InstructionsModal;
