import { FancyText } from "./fancyText";

export interface TextOptions {
  text: string;
  fontFamily?: string;
  fontSize?: number;
  marginTop?: number;
}

export interface SiteTheme_v1 {
  /**
   * WE HAVE A NEW IMAGE IMPLEMENTATION
   * OLD: - ZO ZOOM COORDINATES - IMAGE WOULD BE AUTOZOOMED WITH BACKGROUNDSIZE SET TO COVER
   * NEW: - backgroundImageZoom HOLDS ZOOM COORDINATES FOR LANDSCAPE IMAGES
   */
  backgroundImage: string; //OLD: IMAGE URL / NEW: HIGHTEST WIDTH IMAGE URL
  backgroundImageUrls?: { [key: string]: string };
  backgroundImageZoom: { x: number; y: number }; //NEW: ZOOM COORDINATES FOR LANDSCAPE IMAGE TO BE USED IN PORTRAIT
  backgroundImageUseZoom?: boolean;
  backgroundImageUseContain?: boolean;
  backgroundImageMetadata: {
    width: number;
    height: number;
    orientation?: "LANDSCAPE" | "PORTRAIT" | "SQUARE";
  };
  iconImage: string;
  appFont: string;
  appName: TextOptions;
  slogan: TextOptions;
  sloganCenter?: TextOptions;
  template: {
    category: CategoryTemplate;
    item: ItemTemplate;
    itemProps?: {
      size: "small" | "medium" | "large";
      imageType: TemplateImageType;
      useZoom?: boolean;
    };
  };
}
type CategoryTemplate =
  | "noImgM_noImgL" //SHOW NO IMAGES IN MOBILE AND NO IMAGES IN LANDSCAPE
  | "imgM_imgL" //SHOW IMAGES IN MOBILE AND IMAGES IN LANDSCAPE
  | "noImgM_imgL" //SHOW NO IMAGE IN MOBILE BUT DO SHOW IMAGES IN LANDSCAPE
  | "imgM_noImgL"; //SHOW IMAGES IN MOBILE BUT DO NOT SHOW IMAGES IN LANDSCAPE

type ItemTemplate =
  | "noImgOrder" //SHOW NO IMAGES BUT SHOW ORDER BUTTONS
  | "imgOrder" //SHOW IMAGES AND SHOW ORDER BUTTONS
  | "noImgNoOrder" //SHOW NO IMAGES AND NO ORDER BUTTONS
  | "imgNoOrder"; //SHOW IMAGES BUT DO NOT SHOW ORDER BUTTONS

export enum TemplateImageType {
  small = "fixed_sm",
  medium = "fixed",
  large = "fixed_lg",
  grow = "grow",
}

export enum ThemeColor {
  primary = "primary" /** MATERIAL-UI PRIMARY  */,
  primaryLight = "primaryLight" /** MATERIAL-UI PRIMARY.LIGHT */,
  primaryDark = "primaryDark" /** MATERIAL-UI PRIMARY.DARK  */,
  onPrimary = "onPrimary" /** MATERIAL-UI PRIMARY.CONTRASTTEXT  */,
  accent = "accent" /** MATERIAL-UI SECONDARY  */,
  onAccent = "onAccent" /** MATERIAL-UI SECONDARY.CONTRASTTEXT  */,
  textPrimary = "textPrimary" /** MATERIAL-UI TEXT.PRIMARY  */,
  textSecondary = "textSecondary" /** MATERIAL-UI TEXT.SECONDARY */,
}

export interface SiteTheme_v2 {
  colors: {
    [key: string]: {
      name: string;
      value: string;
    };
  };
  fontSizes: string[];
  button: FancyText;
  fontFamily: string;
}
