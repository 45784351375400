import {
  Container,
  Button,
  DialogContent,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  Chip,
  Typography,
} from "@material-ui/core";
import { Fragment, FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useGridBashFirebase } from "../../Firebase/context";
import { useQuery } from "../../utilities/useQuery";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DoneIcon from "@material-ui/icons/Done";
import { history } from "../App/history";
import { validatePassword } from "../../utilities/validateEmailPassPhone";
import useSpinner from "../Spinner/useSpinner";
import useToast from "../Main/useToast";
import { setForceRefresh } from "./gridbashApp";
import GridBashLogo from "@material-ui/icons/ViewComfy";
import Spinner from "../Spinner/spinner";

interface GridbashRegisterProps {}

const GridbashRegister: FC<GridbashRegisterProps> = (props) => {
  const { t } = useTranslation();
  const showSpinner = useSpinner();
  const toast = useToast();
  const gridbashFirebase = useGridBashFirebase();
  const query = useQuery();
  const [email] = useState(query.get("email") ?? "");

  const [password, setPassword] = useState("");
  const passwordIsValid = validatePassword(password);
  const [showPassword, setShowPassword] = useState(false);

  const [hasInvitation, setHasInvitation] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const exists = await gridbashFirebase.invitationExists({ email });
      setLoaded(true);
      setHasInvitation(exists);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUp = async () => {
    if (!hasInvitation) {
      return;
    }
    if (!passwordIsValid) {
      toast({
        dialog: true,
        color: "error",
        message: t("gridbash.register.weakPassword"),
      });
      return;
    }

    const hideSpinner = showSpinner();
    try {
      await gridbashFirebase.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await gridbashFirebase.acceptInvitation();
      // Need to re-log to pick up their new claims
      setForceRefresh();
      await gridbashFirebase.auth.signOut();
      await gridbashFirebase.auth.signInWithEmailAndPassword(email, password);
      history.replace("/dashboard");
    } catch (error) {
      let message;
      if (error.code === "auth/weak-password") {
        message = t("gridbash.register.weakPassword");
      } else if (error.code === "auth/email-already-in-use") {
        message = t("toast.emailInUse");
      } else if (error.code === "auth/invalid-email") {
        message = t("toast.badEmail");
      } else {
        message = t("toast.systemFailure");
      }
      toast({
        dialog: true,
        color: "error",
        message,
      });
    } finally {
      hideSpinner();
    }
  };

  const anchor = useRef<HTMLDivElement>(null);

  if (!email) {
    // Currently, you can only register if you have an invitation link
    return <Redirect to="/" />;
  }

  if (!loaded) {
    return (
      <Spinner
        isOpen
        lag="none"
        text={t("dashboard.settings.users.loadingInvitation")}
      />
    );
  }

  return (
    <Fragment>
      <div style={{ marginLeft: "2vw", marginTop: "20px" }}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            history.push("/");
          }}
          style={{ display: "flex", alignItems: "center" }}
        >
          <GridBashLogo />
          &nbsp;
          <Typography
            variant="h6"
            style={{
              whiteSpace: "normal",
              textAlign: "left",
              lineHeight: "1",
              textTransform: "none",
              wordBreak: "break-word",
              maxWidth: "33vw",
            }}
          >
            GridBash
          </Typography>
        </div>
      </div>

      <Container
        ref={anchor}
        maxWidth="sm"
        style={{ marginTop: "max(1.5em, 1.5vw)" }}
      >
        {hasInvitation ? (
          <Fragment>
            <DialogContent>
              <Typography variant="h6" style={{ wordBreak: "break-word" }}>
                {email}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("gridbash.register.changeEmailHelperText")}
              </Typography>

              <div style={{ marginTop: "max(1.5em, 1.5vw)" }}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="new-password"
                    label={t("store.account.enterPassword")}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    error={password.length > 0 && !passwordIsValid}
                    helperText={
                      password.length > 0 && !passwordIsValid
                        ? t("store.account.badPassword")
                        : undefined
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showPassword}
                        onChange={() => setShowPassword(!showPassword)}
                      />
                    }
                    label={t("store.account.showPassword")}
                  />
                  <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                    <Chip
                      style={{ border: "none", backgroundColor: "transparent" }}
                      variant="default"
                      label={t("store.account.oneLowerCase")}
                      disabled={/[a-z]/.test(password)}
                      icon={
                        /[a-z]/.test(password) ? (
                          <DoneIcon />
                        ) : (
                          <ArrowForwardIcon />
                        )
                      }
                    />
                  </div>
                  <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                    <Chip
                      style={{ border: "none", backgroundColor: "transparent" }}
                      variant="default"
                      label={t("store.account.oneUpperCase")}
                      disabled={/[A-Z]/.test(password)}
                      icon={
                        /[A-Z]/.test(password) ? (
                          <DoneIcon />
                        ) : (
                          <ArrowForwardIcon />
                        )
                      }
                    />
                  </div>
                  <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                    <Chip
                      style={{ border: "none", backgroundColor: "transparent" }}
                      variant="default"
                      label={t("store.account.oneNumber")}
                      disabled={/\d/.test(password)}
                      icon={
                        /\d/.test(password) ? (
                          <DoneIcon />
                        ) : (
                          <ArrowForwardIcon />
                        )
                      }
                    />
                  </div>
                  <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                    <Chip
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        whiteSpace: "normal",
                      }}
                      variant="default"
                      label={t("store.account.eightCharacters")}
                      disabled={password.length >= 8}
                      icon={
                        password.length >= 8 ? (
                          <DoneIcon />
                        ) : (
                          <ArrowForwardIcon />
                        )
                      }
                    />
                  </div>
                </FormControl>
              </div>
            </DialogContent>
            <div
              style={{
                // display: "inline-block",
                textAlign: "center",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
            >
              <div style={{ marginTop: "max(1.5em, 1.5vw)" }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    signUp();
                  }}
                >
                  {t("store.submit")}
                </Button>
              </div>
              <div style={{ marginTop: "1em" }}>
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {t("store.cancel")}
                </Button>
              </div>
            </div>
          </Fragment>
        ) : (
          <div style={{ marginTop: "max(1em, 1vw)" }}>
            <Typography align="center">
              {t("gridbash.register.noInvitationFound")}
            </Typography>
            <Typography align="center" variant="h6" color="textSecondary">
              {email}
            </Typography>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default GridbashRegister;
