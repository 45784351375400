import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Dispatch, FC, SetStateAction, useRef } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { INPUT_MAX_EMAIL } from "../../../../utilities/constants/appConstants";
import MUIDialog from "../../../Dashboard/Components/mui-dialog";
import { history } from "../../../App/history";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Pricing from "../AppDetails/pricing";
import Features from "../AppDetails/features";
import { DialogActions, DialogContent } from "@material-ui/core";
import { Application } from "../../../../database/signup";

interface Group3Props {
  application: Application;
  setApplication: Dispatch<SetStateAction<Application>>;
  initPath: string;
}

const Group3: FC<Group3Props> = ({ application, setApplication, initPath }) => {
  const { t } = useTranslation();
  const { question3, feedback3 } = application;
  const nextButton = useRef<HTMLButtonElement | null>(null);

  return (
    <React.Fragment>
      <MUIDialog route={`${initPath}/costs`}>
        <Pricing initPath={`${initPath}/costs`} />
      </MUIDialog>
      <MUIDialog route={`${initPath}/features`}>
        <Features />
      </MUIDialog>
      <DialogContent>
        <Typography align="left" color="primary">
          {t("gridbash.home.group3_st2")}
        </Typography>

        <div style={{ marginTop: "1em" }} />
        <ButtonGroup variant="contained" fullWidth color="primary">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              const path = `${initPath}/costs`;
              if (!window.location.pathname.includes(path)) {
                history.push(path);
              }
            }}
          >
            {t("gridbash.home.group3_st4")}
          </Button>

          <Button
            onClick={(e) => {
              e.stopPropagation();
              const path = `${initPath}/features`;
              if (!window.location.pathname.includes(path)) {
                history.push(path);
              }
            }}
          >
            {t("gridbash.home.group3_st3")}
          </Button>
        </ButtonGroup>

        <div style={{ marginTop: "1em" }} />
        <Typography color="textPrimary" variant="h5">
          How do you intend to use our sales application?
        </Typography>
        <div style={{ marginTop: "1em" }} />

        <FormControl fullWidth>
          <RadioGroup
            value={question3}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value as
                | "standalone"
                | "as_pos"
                | "other";
              setApplication((prev) => ({
                ...prev,
                question3: value,
              }));
            }}
          >
            <FormControlLabel
              value="standalone"
              control={<Radio />}
              label="As my standalone sales application"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <FormControlLabel
              value="as_pos"
              control={<Radio />}
              label="Alongside other tools I already use"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="Other"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </RadioGroup>
          <div
            style={{
              height: question3 === "other" ? "10em" : "0em",
              overflow: "hidden",
              transition: "all 0.3s ease",
            }}
          >
            <div style={{ marginTop: "1em" }} />
            <Typography align="left" variant="body2">
              {t("gridbash.home.weWantToLearn")}
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              label="Enter feedback"
              multiline
              rows={4}
              value={feedback3}
              onChange={(e) => {
                const value = e.target.value.substring(0, INPUT_MAX_EMAIL);
                setApplication((prev) => ({
                  ...prev,
                  feedback3: value,
                }));
              }}
              onBlur={(e) => {
                const value = e.target.value
                  .trim()
                  .substring(0, INPUT_MAX_EMAIL);
                setApplication((prev) => ({
                  ...prev,
                  feedback3: value,
                }));
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  const value = e.target.value
                    .trim()
                    .substring(0, INPUT_MAX_EMAIL);
                  setApplication((prev) => ({
                    ...prev,
                    feedback3: value,
                  }));
                  nextButton.current?.click();
                }
              }}
            />
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <div style={{ flexGrow: 1 }} />
        <Typography variant="body2" color="textSecondary">
          3&nbsp;/&nbsp;3
        </Typography>
        <Button
          ref={nextButton}
          fullWidth={false}
          disabled={!question3}
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            history.push("/am-i-in/4");
          }}
        >
          {t("gridbash.complete")}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default Group3;
