/**
 * Determines which fonts are being used by a piece of rich text
 */
export const getFontsInRichText = (text: string) => {
  const regex = /font-family: (&quot;)*.+?(&quot;)*;/gm;
  const matches = text.match(regex) ?? [];
  const fonts: string[] = [];
  matches.forEach((match) => {
    // Match starts off as something like "font-family: Impact;" or
    //   "font-family: &quot;Allerta Stencil$quot;;"
    // Turn it into just "Impact" or "Allerta Stencil"
    let font = match.substring(13, match.length - 1);
    font = font.replace(/&quot;/g, "");
    if (!fonts.includes(font)) {
      fonts.push(font);
    }
  });
  return fonts;
};
