import { loadStripe as loadStripeImport } from "@stripe/stripe-js";

/**
 * @param stripeAccount Which subaccount to use, or null to use gridbash
 */
export const loadStripe = async (stripeAccount: string | null) => {
  let stripe;
  if (stripeAccount) {
    stripe = await loadStripeImport(
      process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "",
      {
        stripeAccount,
      }
    );
  } else {
    stripe = await loadStripeImport(
      process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ""
    );
  }

  // Asserting that it's not null. It can be null if the code runs in a server environment
  //   but we run it client side.
  return stripe!;
};
