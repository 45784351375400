import * as R from "ramda";
import { Fragment, createContext, FC, useCallback, useMemo, useRef, useState } from "react";
import Spinner, { SpinnerProps } from "./spinner";

/**
 * Helper utility to create a function which will only be run once.
 */
const once = (fn: (...args: any[]) => any) => {
  let called = false;
  return (...args: any[]): any => {
    if (!called) {
      called = true;
      return fn(...args);
    }
  };
};

/**
 * Optional values for augmenting the global spinner.
 * isOpen and dBnavMenu are not needed, because main.tsx handles thos
 */
export type ShowSpinnerProps = Omit<SpinnerProps, "isOpen" | "dBnavMenuFull">;
/**
 * Function for displaying the global spinner
 */
export type ShowSpinner = (props?: ShowSpinnerProps) => () => void;

export const SpinnerContext = createContext<ShowSpinner | undefined>(undefined);

const SpinnerProvider: FC = ({ children }) => {
  const [spinners, setSpinners] = useState<
    Record<string, ShowSpinnerProps | undefined>
  >({});
  const spinnerArray = useMemo(() => Object.values(spinners), [spinners]);

  let spinnerId = useRef(0);

  const showSpinner: ShowSpinner = useCallback(
    (props?: ShowSpinnerProps): (() => void) => {
      const id = spinnerId.current;
      spinnerId.current++;
      setSpinners((prev) => ({
        ...prev,
        [id]: props,
      }));

      return once(() => setSpinners(R.omit(["" + id])));
    },
    []
  );

  return (
    <Fragment>
      {spinnerArray.length > 0 && (
        <Spinner
          isOpen={true}
          // If there are multiple spinners, use the props from the most recent one
          {...spinnerArray[spinnerArray.length - 1]}
          // Except for the lag; for the lag, use the shortest delay.
          lag={
            spinnerArray.find((props) => props?.lag === "none")
              ? "none"
              : spinnerArray.find((props) => props?.lag === "short")
              ? "short"
              : "long"
          }
        />
      )}
      <SpinnerContext.Provider value={showSpinner}>
        {children}
      </SpinnerContext.Provider>
    </Fragment>
  );
};

export default SpinnerProvider;
