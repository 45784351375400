import { useMemo, Fragment, useState, useEffect } from "react";
import { Button, IconButton, Snackbar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SiteUser } from "../../database/userData";
import { useSiteFirebase } from "../../Firebase/context";
import { storeKey } from "../../Firebase/siteFirebase";
import useSiteUser from "../UserProvider/useSiteUser";
import { history } from "../App/history";
import useSpinner from "../Spinner/useSpinner";
import { PushMessageData } from "../../utilities/httpsCallables/types";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

// Same as UserData, except uid might be null
export type AccountInfo = Omit<SiteUser, "uid"> & { uid: string | null };

const defaultAccountInfo: AccountInfo = {
  contactInfo: {
    email: "",
    emailSearch: [],
    name: "",
    phone: null,
    phoneSearch: [],
    address: {
      city: "",
      countryCode: "",
      streetLine1: "",
      zip: "",
    },
  },
  favorites2: [],
  notifications: {
    customer: {
      email: true,
      push: false,
      sms: false,
    },
    newOrder: {
      email: false,
      push: false,
      sms: false,
    },
    newMessage: {
      email: false,
      push: false,
      sms: false,
    },
    pushTokens: [],
  },
  claims: {
    role: null,
  },
  uid: null,
  orderCount: 0,
};

/**
 * This code was extracted from main.tsx so it could be reused in
 * DashboardWrapper. Once gridbash is the default starting point,
 * main may go away, and we can consider where this code should live
 */
export const useMain = () => {
  const { t } = useTranslation();
  const firebase = useSiteFirebase();
  const { user } = useSiteUser();
  const showSpinner = useSpinner();

  const [accountInfo, setAccountInfo] = useState(defaultAccountInfo);

  /**
   * User Data
   */
  useEffect(() => {
    if (!user) {
      setAccountInfo(defaultAccountInfo);
      return;
    }
    const hideSpinner = showSpinner();
    const unsubscribe = firebase.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("users")
      .doc(user.uid)
      .onSnapshot(
        async (userDataDoc) => {
          hideSpinner();
          const userData = userDataDoc.data() as SiteUser | undefined;
          if (!userData) {
            setAccountInfo(defaultAccountInfo);
            return;
          }

          setAccountInfo(userData);
        },
        (error) => {
          console.log("main.js UseEffect(). GETTING USER DATA ERROR", error);
          hideSpinner();
        }
      );

    return () => {
      unsubscribe();
      hideSpinner();
    };
  }, [user]); //eslint-disable-line react-hooks/exhaustive-deps

  const pushDesired =
    accountInfo.notifications.customer.push ||
    accountInfo.notifications.newMessage.push ||
    accountInfo.notifications.newOrder.push;

  const [pushError, setPushError] = useState<string>();

  useEffect(() => {
    const updatePushTokens = async () => {
      // - PUSH TOKENS ARE CREATED WHEN THE USER SETS PREFERENCE IN ACCOUNT
      //   SETTINGS TO TRUE
      // - KEEP IN MIND THAT IF A USER HAS TWO BROWSER SESSIONS OPEN AND ONE SESSION
      //   IS IN INCOGNITO, THE SETTING WILL ALWAYS REVERT BACK TO TURNED OFF,
      //   EVEN IN THE NON-INCOGNITO SESSION

      // - !!! THIS FOLLOWING BULLET POINT NEEDS TO BE UPDATED!!!
      //   WHEN THE USER TURNS OFF PUSH SETTINGS, THE TOKENS ARE DELETED. THIS MEANS
      //   THAT WITH THE WAY THINGS ARE SETUP, WE TIE THE ACCOUNT PUSH SETTINGS TO
      //   ALL DEVICES, AND NOT JUST THE CURRENT DEVICE. MEANING, IF THEY TURN OFF
      //   PUSH ALERTS IN THEIR ACCOUNT, NO DEVICE WILL RECEIVE IT. SOME SITES DO
      //   THIS DIFFERENTLY AND SET ONE TOKEN ONLY. BUT THAT WOULD MEAN THAT IF A
      //   USER WAS VISITING THE APP LAST USING DESKTOP, THEY WILL ONLY GET NOTIFIED
      //   THERE AND NOT IN MOBILE. THE DOWNSIDE IS THAT IF USERS SHARE BROWSERS
      //   THEY WILL BE RECEIVING ALERTS FOR ALL SHARED USERS.
      // - IN MAIN.TSX, WE ALWAYS CHECK FOR PUSHTOKENS AGAIN:
      //   --> IF USER PUSH PREFERENCE EQUALS TRUE, THEN CHECK IF BROWSER ALLOWS
      //       PUSH. IF NOT, ASK USER AGAIN, AND ADD THE NEW TOKEN TO THE
      //       EXISTING TOKENS ARRAY FOR THIS USER
      //   (BELOW IS WHEN WE START DOING THAT)

      //CORRECT PUSH SETTINGS
      //IF THE USER ONCE SET THE PUSH NOTIFICATIONS TO TRUE
      //BUT THE TOKEN GOT LOST DUE TO E.G. BROWSER HISTORY WIPE
      //GET A NEW TOKEN FROM THEM

      //DOES THE USER ALLOW PUSH ALERTS?
      if (!pushDesired || !firebase.pushSupported || !user) {
        return;
      }

      // Add this device's push token to the database if necessary
      try {
        await firebase.addPushToken(user.uid);
      } catch (error) {
        let message: string;
        if (error.message === "incognito") {
          message = t("store.orders.notSupportIncognito");
        } else if (error.message === "denied") {
          message = t("store.orders.pushNotificationDenied");
        } else {
          message = t("toast.systemError");
        }
        if (message) {
          setPushError(message);
        }
      }
    };

    updatePushTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushDesired, firebase.pushSupported]);

  const [receivedMessage, setReceivedMessage] = useState<PushMessageData>();

  useEffect(() => {
    //HANDLE INCOMING PUSH MESSAGES WHEN THE APP IS OPEN AND IN FOCUS
    if (firebase.pushSupported && firebase.messaging) {
      const unsubscribe = firebase.messaging.onMessage((payload) => {
        console.log("Foreground PUSH message received! ", payload);
        const data = payload.data as PushMessageData;

        if (
          !data.pathname ||
          !window.location.pathname.includes(data.pathname)
        ) {
          // Show a snackbar which lets them route to the page if desired
          setReceivedMessage(data);
        }
      });
      return unsubscribe;
    }
  }, [firebase.messaging, firebase.pushSupported]);

  useEffect(() => {
    // Handle incoming push messages when the app is in the background
    if (firebase.pushSupported && firebase.messaging) {
      let unmounted = false;
      let serviceWorkerMessageHandler = (e: MessageEvent) => {
        if (e.data?.eventType === "backgroundMessage") {
          // The service worker received a background push message and has forwarded it to the
          //   main thread. See firebase-messaging-sw.js for where this is sent
          const data = e.data.messageData as PushMessageData;

          if (
            !data.pathname ||
            !window.location.pathname.includes(data.pathname)
          ) {
            // Show a snackbar which lets them route to the page if desired
            setReceivedMessage(data);
          }
        }
        // Don't care about any other messages, though they do exist (eg, firebase does some)
      };

      navigator.serviceWorker.ready.then(() => {
        if (!unmounted) {
          navigator.serviceWorker.addEventListener(
            "message",
            serviceWorkerMessageHandler
          );
        }
      });

      return () => {
        unmounted = true;
        navigator.serviceWorker.ready.then(() => {
          navigator.serviceWorker.removeEventListener(
            "message",
            serviceWorkerMessageHandler
          );
        });
      };
    }
  }, [firebase.messaging, firebase.pushSupported]);

  const dialogs = useMemo(() => {
    return (
      <Fragment>
        {pushError && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open
            autoHideDuration={20000}
            onClose={() => setPushError(undefined)}
          >
            <Alert onClose={() => setPushError(undefined)} severity="warning">
              {pushError}
            </Alert>
          </Snackbar>
        )}
        {receivedMessage && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open
            onClose={(event, reason) => {
              if (reason === "timeout") {
                setReceivedMessage(undefined);
              }
            }}
            message={receivedMessage.title}
            action={
              <Fragment>
                {receivedMessage.pathname && (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setReceivedMessage(undefined);
                      if (
                        receivedMessage.pathname &&
                        !window.location.pathname.includes(
                          receivedMessage.pathname
                        )
                      ) {
                        history.push(receivedMessage.pathname);
                      }
                    }}
                  >
                    {t("store.view")}
                  </Button>
                )}
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setReceivedMessage(undefined);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Fragment>
            }
          />
        )}
      </Fragment>
    );
  }, [pushError, receivedMessage, t]);

  return {
    accountInfo,
    dialogs,
  };
};
