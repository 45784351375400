import { Fragment, FC, useEffect, useState } from "react";
import { PostalAddress } from "../../../database/siteSettings";
import { stateCodes } from "../../Store/Components/stateCodes";
import { countryCodes } from "../../Store/Components/countryCodes";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import { INPUT_MAX_LENGTH } from "../../../utilities/constants/appConstants";
import {
  Button,
  DialogContent,
  FormControl,
  Icon,
  IconButton,
  TextField,
} from "@material-ui/core";
import DirectionsIcon from "@material-ui/icons/ArrowRight";
import DialogActions from "@material-ui/core/DialogActions";
import useToast from "../../Main/useToast";
import { TFunction } from "i18next";
import SmartSelect from "../../Dashboard/Components/smartSelect";
import { useSiteSettings } from "../../../customization/siteSettingsContext";

interface STAddressFormProps {
  initialAddress: PostalAddress;
  onSubmit: (address: PostalAddress) => void;
  includeGoogleLocation?: boolean;
  showSubmit?: boolean;
  initPath: string;
}

export const validateAddress = (address: PostalAddress, t: TFunction) => {
  if (address.streetLine1.trim().length < 1) {
    return t("dashboard.orders.provideStreetAddress");
  } else if (address.city.trim().length < 1) {
    return t("dashboard.orders.provideCity");
  } else if (address.zip.trim().length < 1) {
    return t("dashboard.orders.provideZip");
  } else if (address.countryCode.trim().length < 1) {
    return t("dashboard.orders.provideCountry");
  }
  return null;
};

const STAddressForm: FC<STAddressFormProps> = ({
  initialAddress,
  onSubmit,
  includeGoogleLocation = false,
  showSubmit = true,
  initPath,
}) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<PostalAddress>(initialAddress);

  const { address: storeAddress } = useSiteSettings();

  const createGoogleUrl = () => {
    const googleURL =
      address.countryCode === "US"
        ? encodeURI(
            `${address.streetLine1} ${address.streetLine2 || ""} ${
              address.streetLine3 || ""
            } ${address.city} ${address.zip} ${address.stateCode} ${
              address.countryCode
            }`
          )
        : encodeURI(
            `${address.streetLine1} ${address.streetLine2 || ""} ${
              address.streetLine3 || ""
            } ${address.city} ${address.zip} ${address.countryCode}`
          );

    return googleURL;
  };

  const toast = useToast();

  useEffect(
    () => {
      if (showSubmit === false) {
        //NEEDS TESTING
        toast({
          message: t("store.account.addressUpdateSuccess"),
          dialog: true,
          color: "normal",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSubmit]
  );

  return (
    <Fragment>
      <DialogContent className="smartWidthMd">
        <FormControl fullWidth>
          <TextField
            disabled={!showSubmit}
            label={t("dashboard.orders.streetAddress1")}
            placeholder={t("store.account.streetPlaceholder")}
            type="text"
            fullWidth
            value={address.streetLine1}
            onBlur={(e) =>
              setAddress({
                ...address,
                streetLine1: e.target.value.trim(),
              })
            }
            onChange={(e) =>
              setAddress({
                ...address,
                streetLine1: e.target.value.substring(0, INPUT_MAX_LENGTH),
              })
            }
          />
        </FormControl>
        <div style={{ marginTop: "1em" }} />
        <FormControl fullWidth>
          <TextField
            disabled={!showSubmit}
            label={t("dashboard.orders.streetAddress2")}
            placeholder={t("store.account.streetPlaceholder")}
            type="text"
            fullWidth
            value={address.streetLine2 || ""}
            onBlur={(e) =>
              setAddress({
                ...address,
                streetLine2: e.target.value.trim(),
              })
            }
            onChange={(e) =>
              setAddress({
                ...address,
                streetLine2: e.target.value.substring(0, INPUT_MAX_LENGTH),
              })
            }
          />
        </FormControl>
        <div style={{ marginTop: "1em" }} />
        <FormControl fullWidth>
          <TextField
            disabled={!showSubmit}
            label={t("dashboard.orders.streetAddress3")}
            placeholder={t("store.account.streetPlaceholder")}
            type="text"
            fullWidth
            value={address.streetLine3 || ""}
            onBlur={(e) =>
              setAddress({
                ...address,
                streetLine3: e.target.value.trim(),
              })
            }
            onChange={(e) =>
              setAddress({
                ...address,
                streetLine3: e.target.value.substring(0, INPUT_MAX_LENGTH),
              })
            }
          />
        </FormControl>
        <div style={{ marginTop: "1em" }} />
        <FormControl fullWidth>
          <TextField
            disabled={!showSubmit}
            label={t("dashboard.orders.city")}
            placeholder={t("store.account.cityPlaceholder")}
            type="text"
            fullWidth
            value={address.city}
            onBlur={(e) =>
              setAddress({
                ...address,
                city: e.target.value.trim(),
              })
            }
            onChange={(e) =>
              setAddress({
                ...address,
                city: e.target.value.substring(0, INPUT_MAX_LENGTH),
              })
            }
          />
        </FormControl>
        <div style={{ marginTop: "1em" }} />
        <FormControl fullWidth>
          <TextField
            disabled={!showSubmit}
            label={t("dashboard.orders.zipCode")}
            placeholder={t("store.account.zipPlaceholder")}
            type="text"
            fullWidth
            value={address.zip}
            onBlur={(e) =>
              setAddress({
                ...address,
                zip: e.target.value.trim(),
              })
            }
            onChange={(e) =>
              setAddress({
                ...address,
                zip: e.target.value.substring(0, INPUT_MAX_LENGTH),
              })
            }
          />
        </FormControl>
        <div style={{ marginTop: "2em" }} />

        <SmartSelect
          initPath={`${initPath}/countries`}
          modalType="dialog"
          label={t("dashboard.orders.country")}
          optionValues={countryCodes}
          optionStrings={countryCodes.map((value) =>
            t(`store.account.country.${value}`)
          )}
          currentSelection={
            address.countryCode || storeAddress.countryCode || "US"
          }
          handleClick={(selected) => {
            setAddress({
              ...address,
              countryCode: selected.substring(0, INPUT_MAX_LENGTH),
            });
            history.goBack();
          }}
          delayRender={true}
        />

        {address.countryCode === "US" && (
          <Fragment>
            <div style={{ marginTop: "1em" }} />
            <SmartSelect
              initPath={`${initPath}/states`}
              modalType="dialog"
              label={t("dashboard.orders.state")}
              optionValues={stateCodes}
              optionStrings={stateCodes.map((value) =>
                t(`store.account.state.${value}`)
              )}
              currentSelection={address.stateCode || "AL"}
              handleClick={(selected) => {
                setAddress({
                  ...address,
                  stateCode: selected.substring(0, INPUT_MAX_LENGTH),
                });
                history.goBack();
              }}
              delayRender={true}
            />
          </Fragment>
        )}

        <div
          style={{
            marginTop: "1em",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<DirectionsIcon />}
            onClick={(e) => {
              e.stopPropagation();
              const errorString = validateAddress(address, t);
              if (errorString) {
                toast({
                  message: errorString,
                  dialog: true,
                  color: "normal",
                });
              } else {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${createGoogleUrl()}`,
                  "_blank"
                );
              }
            }}
          >
            {t("dashboard.orders.testDirections")}
          </Button>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toast({
                color: "normal",
                dialog: true,
                message:
                  "Avoid delivery delays by testing directions to your address. If directions in Google Maps don't seem to be accurate, let us know during your next order. Thank you.",
              });
            }}
          >
            <Icon>help</Icon>
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setAddress({
              city: "",
              countryCode: "US",
              stateCode: "",
              streetLine1: "",
              streetLine2: "",
              streetLine3: "",
              zip: "",
            });
          }}
        >
          {t("store.clear")}
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {showSubmit ? t("store.cancel") : t("store.close")}
        </Button>
        {showSubmit && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onSubmit(address);
            }}
          >
            {t("store.submit")}
          </Button>
        )}
      </DialogActions>
    </Fragment>
  );
};

export default STAddressForm;
