import { useState, FC, Suspense } from "react";
import * as React from "react";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import useSiteUser from "../../UserProvider/useSiteUser";
import { storeKey } from "../../../Firebase/siteFirebase";
import { useSiteFirebase } from "../../../Firebase/context";
import { history } from "../../App/history";
import { Button, DialogContent } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { createPhoneSearchStrings } from "../../../utilities/createSearchStrings";
import { AccountInfo } from "../../Main/useMain";
import useToast from "../../Main/useToast";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import useSpinner from "../../Spinner/useSpinner";
import { phoneIsBlocked } from "../../../utilities/httpsCallables/httpsCallables";

const PhoneInput = React.lazy(
  () => import("../Components/phoneInput/phoneInput")
);

interface AccountPhoneProps {
  accountInfo: AccountInfo;
  setOptimisticUpdate: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
  onSubmit?: () => void;
}

const AccountPhone: FC<AccountPhoneProps> = React.memo(
  ({ accountInfo, setOptimisticUpdate, onSubmit }) => {
    const { user } = useSiteUser();
    const { t } = useTranslation();
    const firebase = useSiteFirebase();
    const siteSettings = useSiteSettings();
    const toast = useToast();
    const showSpinner = useSpinner();

    const [phone, setPhone] = useState(accountInfo.contactInfo.phone);

    const changePhone = async () => {
      if (!user) {
        return;
      }

      if (R.equals(phone, accountInfo.contactInfo.phone)) {
        history.goBack();
        return;
      }
      try {
        let blocked = false;
        if (phone) {
          const hideSpinner = showSpinner({ lag: "short" });
          try {
            blocked = await phoneIsBlocked(firebase, { phone: phone.raw });
          } catch (err) {
            console.log("error checking if phone is blocked");
          } finally {
            hideSpinner();
          }
        }

        const searchStrings = createPhoneSearchStrings(phone);
        setOptimisticUpdate({
          ...accountInfo,
          contactInfo: {
            ...accountInfo.contactInfo,
            phone,
            phoneSearch: searchStrings,
            smsOptOut: blocked,
          },
        });

        if (onSubmit) {
          onSubmit();
        }
        history.goBack();

        await firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("users")
          .doc(user.uid)
          .update({
            "contactInfo.phone": phone,
            "contactInfo.phoneSearch": searchStrings,
            "contactInfo.smsOptOut": blocked,
          });
      } catch (error) {
        toast({
          dialog: true,
          color: "error",
          message: t("store.account.errorChangingSetting"),
        });
      }
    };

    return (
      <React.Fragment>
        <DialogContent className="smartWidthMd">
          <Suspense fallback={null}>
            <PhoneInput
              siteCountryCode={siteSettings.phone?.countryCode}
              value={phone}
              onChange={setPhone}
              className="smartWidthMd"
            />
          </Suspense>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.goBack()}>{t("store.cancel")}</Button>
          <Button onClick={(e) => changePhone()}>{t("store.submit")}</Button>
        </DialogActions>
      </React.Fragment>
    );
  }
);

export default AccountPhone;
