import {
  DialogContent,
  Typography,
  IconButton,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Fragment, FC, useState } from "react";
import { history } from "../../../App/history";
import InfoIcon from "@material-ui/icons/Info";
import MUIDialog, {
  MUIDialogProps,
} from "../../../Dashboard/Components/mui-dialog";
import { useTranslation } from "react-i18next";
import PaymentFees from "./paymentFees";
import DebouncedSlider from "../../../Dashboard/Settings/debouncedSlider";

interface PricingProps {
  initPath: string;
}

const Pricing: FC<PricingProps> = ({ initPath }) => {
  const [dialogProps, setDialogProps] = useState<MUIDialogProps>();
  const { t } = useTranslation();
  const [sales, setSales] = useState(195);

  const calculateFee = () => {
    if (sales > 195) return 0;
    else return 9.75 - sales * 0.05;
  };

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 195,
      label: "195 sales",
    },
    {
      value: 300,
      label: "300+",
    },
  ];

  return (
    <Fragment>
      {dialogProps && <MUIDialog {...dialogProps} />}
      <DialogTitle disableTypography className="smartWidthMd">
        <Typography variant="h6">IS IT REALLY FREE?</Typography>
        <Typography color="textSecondary" variant="body2">
          Get rewarded for every sale!
        </Typography>
      </DialogTitle>
      <DialogContent className="smartWidthMd">
        <Typography component="span" variant="body2">
          It depends... Set the slider below to your estimated monthly sales
          count (amount of times you make a sale) to see the associated service
          fee.
        </Typography>
        <div style={{ margin: "max(1em, 1vw) 0.5em" }}>
          <DebouncedSlider
            onClick={(e) => e.stopPropagation()}
            orientation="horizontal"
            value={sales}
            onChange={(newValue) => {
              setSales(newValue);
            }}
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={300}
            marks={marks}
            onChangeCommitted={(e) => e.stopPropagation()}
          />
        </div>

        <Typography variant="h4" align="center">
          <strong>
            {calculateFee().toLocaleString(`en-US`, {
              style: "currency",
              currency: "USD",
            })}
          </strong>
        </Typography>
        <div
          style={{
            margin: "1em 0",
            minHeight: "96px",
            padding: "1em",
            backgroundColor: "#dae7e7",
            borderRadius: "4px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {sales >= 195 ? (
            <Fragment>
              <Typography variant="h5">
                <strong>It's free!</strong>
              </Typography>
              <Typography variant="body2">
                If you make 195+ sales per month (about 6-7 sales a day), you
                will receive a full refund for the service fee!
              </Typography>
            </Fragment>
          ) : sales === 0 ? (
            <Fragment>
              <Typography variant="body2">No sales?</Typography>
              <Typography
                variant="body2"
                component="div"
                style={{ margin: "auto" }}
              >
                <strong>
                  Pay only $9.75 a month for an all-inclusive website solution!
                  No code required, publish edits in a flash!
                </strong>
              </Typography>
            </Fragment>
          ) : (
            <Typography variant="body2" style={{ margin: "auto" }}>
              You're only paying{" "}
              {calculateFee().toLocaleString(`en-US`, {
                style: "currency",
                currency: "USD",
              })}{" "}
              for this month's service fee!
            </Typography>
          )}
        </div>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Our Payment Processing Fees</Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              const path = `${initPath}/fees`;
              history.push(path);
              setDialogProps({
                route: path,
                children: <PaymentFees />,
              });
            }}
          >
            <InfoIcon />
          </IconButton>
        </div>

        <ListItem
          style={{
            backgroundColor: "rgba(0,0,0,0.12)",
            borderRadius: "5px",
          }}
        >
          <ListItemText
            style={{ textAlign: "center" }}
            primary="2.9% + 35c"
            secondary="Per online transaction"
          />
          <ListItemText
            style={{ textAlign: "center" }}
            primary="2.7% + 10c"
            secondary="Per in-person transaction"
          />
        </ListItem>
        <Typography variant="caption" component="div" color="textSecondary">
          You are already subjected to processing fees if you are accepting
          cards through other tools.
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary">
          Processing fees decrease with sales volume, as it enables us to
          receive and relay discounts.
        </Typography>
        <div style={{ marginTop: "1em" }} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("gridbash.close")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Pricing;
