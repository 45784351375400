import { storeKey } from "../Firebase/siteFirebase";
import type firebase from "firebase";
import { useSiteFirebase } from "../Firebase/context";
import { useCallback } from "react";
import useAccountInfo from "../components/Main/useAccountInfo";
import useSiteUser from "../components/UserProvider/useSiteUser";
import useSpinner from "../components/Spinner/useSpinner";
import useToast from "../components/Main/useToast";
import { useTranslation } from "react-i18next";

const maxFavorites = 100;

export const useToggleFavorite = () => {
  const { t } = useTranslation();
  const firebase = useSiteFirebase();
  const toast = useToast();
  const showSpinner = useSpinner();
  const accountInfo = useAccountInfo();
  const { user } = useSiteUser();

  const toggleFavorite = useCallback(
    async (itemId: string) => {
      if (!user) {
        // Can't set favorites if not logged in
        return;
      }
      const isFavorite = accountInfo.favorites2?.includes(itemId);
      const favoriteCount = accountInfo.favorites2
        ? Object.keys(accountInfo.favorites2).length
        : 0;

      if (!isFavorite && favoriteCount >= maxFavorites) {
        toast({
          message: t("store.orders.maximumFavorites", { count: maxFavorites }),
          color: "error",
          dialog: true,
        });
        return;
      }

      let update: firebase.firestore.UpdateData;
      if (isFavorite) {
        update = {
          favorites2: firebase.FieldValue.arrayRemove(itemId),
        };
      } else {
        update = {
          favorites2: firebase.FieldValue.arrayUnion(itemId),
        };
      }

      const hideSpinner = showSpinner();
      try {
        await firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("users")
          .doc(user.uid)
          .update(update);
        console.log("FAVORITES UPDATE WORKED");
      } catch (error) {
        console.log("FAVORITES UPDATE DIDNT WORK", error);
      } finally {
        hideSpinner();
      }
    },
    [
      accountInfo.favorites2,
      firebase.FieldValue,
      firebase.firestore,
      showSpinner,
      t,
      toast,
      user,
    ]
  );
  return toggleFavorite;
};
