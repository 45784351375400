import { useMemo } from "react";
import Stripe from "stripe";
import { useGridbashSettings } from "../../../GridBash/gridbashSettingsContext";
import { usePrivateSite } from "../../Overview/usePrivateSite";

const getProductId = (item: Stripe.SubscriptionItem): string => {
  return typeof item.price.product === "string"
    ? item.price.product
    : item.price.product.id;
};

/**
 * Helper hook for extracting useful information from their subscriptions.
 */
export const useSubscription = () => {
  const { emailPlan, smsPlan, gridbashPlan } = useGridbashSettings();
  const privateSite = usePrivateSite();

  // Find out which subscription objects contain which information.
  //   Typically, one subscription will have the gridbash data, while
  //   another will have both the email and sms data.
  return useMemo(() => {
    const gridbashSubscription =
      privateSite?.stripe?.gridbashSubscription?.subscription;
    const communicationSubscription =
      privateSite?.stripe?.communicationSubscription?.subscription;
    let gridbashItem: Stripe.SubscriptionItem | undefined;
    let emailItem: Stripe.SubscriptionItem | undefined;
    let smsItem: Stripe.SubscriptionItem | undefined;

    if (gridbashSubscription) {
      for (let item of gridbashSubscription.items.data) {
        if (getProductId(item) === gridbashPlan.productId) {
          gridbashItem = item;
        }
      }
    }

    if (communicationSubscription) {
      for (let item of communicationSubscription.items.data) {
        if (getProductId(item) === emailPlan.productId) {
          emailItem = item;
        } else if (getProductId(item) === smsPlan.productId) {
          smsItem = item;
        }
      }
    }

    return {
      gridbashSubscription,
      gridbashPlan,
      communicationSubscription,
      gridbashItem,
      emailItem,
      emailPlan,
      smsItem,
      smsPlan,
    };
  }, [
    privateSite?.stripe?.gridbashSubscription?.subscription,
    privateSite?.stripe?.communicationSubscription?.subscription,
    gridbashPlan,
    emailPlan,
    smsPlan,
  ]);
};
