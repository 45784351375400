import MobileStepper from "@material-ui/core/MobileStepper";
import { FC, useState } from "react";
import * as React from "react";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowRightIcon from "@material-ui/icons/ArrowForward";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InfoIcon from "@material-ui/icons/Info";
import MUIDialog, {
  MUIDialogProps,
} from "../../Dashboard/Components/mui-dialog";
import PaymentFees from "./AppDetails/paymentFees";
import { history } from "../../App/history";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/ArrowRight";

interface HowItWorksProps {
  initPath: string;
}

const HowItWorks: FC<HowItWorksProps> = React.memo(({ initPath }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [dialogProps, setDialogProps] = useState<MUIDialogProps>();
  return (
    <React.Fragment>
      {dialogProps && <MUIDialog {...dialogProps} />}
      <Typography variant="h4">How it works</Typography>
      {/* <div style={{ marginTop: "max(1em, 2vw)" }} /> */}

      <div
        style={{
          display: "grid",
          gridTemplateAreas: `"area1 area2 area3 area4 area5"`,
          gridTemplateColumns: "100% 100% 100% 100% 100%",
          width: "100%",
          transform: `translateX(-${activeStep * 100}%)`,
          transition:
            "transform 300ms cubic-bezier(0.600, -0.280, 0.735, 0.045)",
        }}
      >
        <div
          style={{
            gridArea: "area1",
            paddingLeft: "max(1em, 2vw)",
            paddingRight: "max(1em, 2vw)",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <Typography variant="h5">
              1. Get your free local-community sales software
            </Typography>
            <br />
            <Typography>
              Get equipped with your <strong>free</strong> state-of-the-art
              sales application, to sell online and/or in-person.
            </Typography>
            <br />
            <div
              className={activeStep === 0 ? "anim_fadeIn_0503" : ""}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "5px",
                padding: "max(0.5em, 1vw)",
                opacity: 0,
              }}
            >
              <div style={{ marginRight: "1em" }}>
                <ArrowRightIcon />
              </div>
              &nbsp;
              <Typography align="left">
                Use it as your standalone point-of-sale, or alongside the tools
                you already love.
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            gridArea: "area2",
            paddingLeft: "max(1em, 2vw)",
            paddingRight: "max(1em, 2vw)",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <Typography variant="h5">
              2. Configure your sales application
            </Typography>
            <br />
            <Typography>
              Use your domain name, or one of ours. Set up your catalog,
              automate communication (Email, SMS, Push), personalize your online
              experience ...
            </Typography>
            <br />
            <div
              className={activeStep === 1 ? "anim_fadeIn_0503" : ""}
              style={{
                opacity: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "5px",
                padding: "max(0.5em, 1vw)",
              }}
            >
              <div style={{ marginRight: "1em" }}>
                <ArrowRightIcon />
              </div>
              <Typography>
                Accept every payment method: credit cards, Apple Pay, Google
                Pay, Paypal and many more.
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            gridArea: "area3",
            paddingLeft: "max(1em, 2vw)",
            paddingRight: "max(1em, 2vw)",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <Typography variant="h5">
              3. Plug into the Community Grid
            </Typography>
            <br />
            <Typography>
              By using our sales application, you will be listed for free on the
              local-community marketplace.
            </Typography>
            <br />
            <div
              className={activeStep === 2 ? "anim_fadeIn_0503" : ""}
              style={{
                opacity: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "5px",
                padding: "max(0.5em, 1vw)",
              }}
            >
              <div style={{ marginRight: "1em" }}>
                <ArrowRightIcon />
              </div>
              <Typography>
                Locals, and anyone else really, can easily find you and your
                products, boosting your sales potential!
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            gridArea: "area4",
            paddingLeft: "max(1em, 2vw)",
            paddingRight: "max(1em, 2vw)",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <Typography variant="h5">
              4. Offer delivery without depleting your hard-earned margins
            </Typography>
            <br />
            <Typography>
              Control delivery costs by listing your deliverables in our{" "}
              <strong>commision-free</strong> Lightning Bid&trade; system.
            </Typography>
            <br />
            <div
              className={activeStep === 3 ? "anim_fadeIn_0503" : ""}
              style={{
                opacity: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "5px",
                padding: "max(0.5em, 1vw)",
              }}
            >
              <div style={{ marginRight: "1em" }}>
                <ArrowRightIcon />
              </div>
              <Typography>
                Drivers earn much more than with other delivery services!
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            gridArea: "area5",
            paddingLeft: "max(1em, 2vw)",
            paddingRight: "max(1em, 2vw)",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <Typography variant="h5">Is it really free?</Typography>
            <br />

            <Button
              startIcon={<PlayArrowIcon />}
              variant="contained"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                const path = "/costs";
                if (!window.location.pathname.includes(path))
                  history.push(path);
              }}
            >
              Determine if it's free
            </Button>
            <div style={{ marginTop: "1em" }} />
            <div
              className={activeStep === 4 ? "anim_fadeIn_0503" : ""}
              style={{
                opacity: 0,
                display: "flex",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "5px",
                padding: "max(0.5em, 1vw)",
              }}
            >
              <div>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    const path = `${initPath}/fees`;
                    history.push(path);
                    setDialogProps({
                      route: path,
                      children: <PaymentFees />,
                    });
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </div>
              <ListItem
                style={{
                  backgroundColor: "rgba(0,0,0,0.12)",
                  borderRadius: "5px",
                  padding: 0,
                }}
              >
                <ListItemText
                  style={{ textAlign: "center", padding: 0 }}
                  primary="2.9% + 35c"
                  secondary="Per online transaction"
                />
                <ListItemText
                  style={{ textAlign: "center" }}
                  primary="2.7% + 10c"
                  secondary="Per in-person transaction"
                />
              </ListItem>
            </div>
            <Typography variant="caption">
              You are already subjected to processing fees if you are accepting
              cards through other tools.
            </Typography>
          </div>
        </div>
      </div>
      <MobileStepper
        style={{
          backgroundColor: "transparent",
          display: "inline-flex",
        }}
        variant="dots"
        steps={5}
        position="static"
        activeStep={activeStep}
        nextButton={
          <div style={{ marginLeft: "max(1em, 2vw)" }}>
            <IconButton onClick={handleNext} disabled={activeStep === 4}>
              <KeyboardArrowRight />
            </IconButton>
          </div>
        }
        backButton={
          <div style={{ marginRight: "max(1em, 2vw)" }}>
            <IconButton onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
            </IconButton>
          </div>
        }
      />
    </React.Fragment>
  );
});

export default HowItWorks;
