import Button from "@material-ui/core/Button";
import { Fragment, FC } from "react";
import PayIcon from "@material-ui/icons/CreditCard";
import OptionsIcon from "@material-ui/icons/Settings";
import CartIcon from "@material-ui/icons/ShoppingCart";
import ROUTES from "../../../utilities/constants/routes";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import Tooltip from "@material-ui/core/Tooltip";

interface CrumbsProps {
  startStripeCheckout: () => void;
  builder: boolean;
}

const Crumbs: FC<CrumbsProps> = ({ startStripeCheckout, builder }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Tooltip title={builder ? `${t("dashboard.builder.primaryDark")}` : ""}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (builder) return;
            history.replace(`${ROUTES.CART}`);
          }}
          startIcon={<CartIcon />}
        >
          {t("store.orders.order")}
        </Button>
      </Tooltip>
      /
      <Tooltip title={builder ? `${t("dashboard.builder.accent")}` : ""}>
        <Button color="secondary" startIcon={<OptionsIcon />}>
          {t("store.orders.checkout")}
        </Button>
      </Tooltip>
      /
      <Tooltip title={builder ? `${t("dashboard.builder.primaryDark")}` : ""}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (builder) return;
            startStripeCheckout();
          }}
          startIcon={<PayIcon />}
        >
          {t("store.orders.payment")}
        </Button>
      </Tooltip>
    </Fragment>
  );
};

export default Crumbs;
