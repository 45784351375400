import { useState, FC } from "react";
import * as React from "react";
import { validateEmail } from "../../../utilities/validateEmailPassPhone";
import { useTranslation } from "react-i18next";
import { GuestDetails } from "../Checkout/checkoutMain";
import useToast from "../../Main/useToast";
import { DialogTitle, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { history } from "../../App/history";
import GuestCommSettings from "../Components/guestCommSettings";
import BenefitsIcon from "@material-ui/icons/Loyalty";
import STauth from "./sTauth";
import MUIDialog from "../../Dashboard/Components/mui-dialog";

interface STguestInfoModalProps {
  initialGuestDetails: GuestDetails;
  onSubmit: (guestDetails: GuestDetails) => void;
  initPath: string;
}

const STguestInfoModal: FC<STguestInfoModalProps> = React.memo(
  ({ initialGuestDetails, onSubmit, initPath }) => {
    const { t } = useTranslation();
    const [guestInfo, setGuestInfo] =
      useState<GuestDetails>(initialGuestDetails);
    const emailIsValid = validateEmail(guestInfo.email.trim());
    const phoneIsValid = true;
    const toast = useToast();

    const handleSubmit = async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      e.preventDefault();
      if (emailIsValid && phoneIsValid) {
        onSubmit({
          email: guestInfo.email.trim(),
          phone: guestInfo.phone,
          pushToken: guestInfo.pushToken,
        });
      } else {
        if (!emailIsValid) {
          toast({
            dialog: true,
            color: "error",
            message: t("store.orders.provideValidEmail"),
          });
        } else if (!phoneIsValid) {
          toast({
            dialog: true,
            color: "error",
            message: t("store.orders.provideValidPhone"),
          });
        }
      }
    };

    return (
      <React.Fragment>
        <MUIDialog route={`${initPath}/auth`}>
          <STauth afterSignin={() => history.go(-2)} />
        </MUIDialog>
        <DialogTitle disableTypography className="smartWidthMd">
          <Typography variant="h5">
            {t("store.checkout.reachCustomer")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t("guestCheckout.getintouchMessage")}
          </Typography>
        </DialogTitle>

        <DialogContent className="smartWidthMd" style={{ textAlign: "center" }}>
          <FormControl fullWidth style={{ marginBottom: "3vh" }}>
            <TextField
              label={t("store.messages.enterEmail")}
              type="email"
              fullWidth={true}
              value={guestInfo.email}
              onChange={(e) => {
                const newEmail = e.target.value;
                setGuestInfo((prev) => ({
                  ...prev,
                  email: newEmail,
                }));
              }}
            />
          </FormControl>
          <GuestCommSettings
            showEmail={false}
            communicationType="orders"
            email={guestInfo.email}
            phone={guestInfo.phone}
            onPhoneChanged={(newPhone) => {
              setGuestInfo((prev) => ({
                ...prev,
                phone: newPhone,
              }));
            }}
            pushToken={guestInfo.pushToken}
            onPushTokenChanged={(newToken) => {
              setGuestInfo((prev) => ({
                ...prev,
                pushToken: newToken,
              }));
            }}
          />
          <br />
          <br />
          <Typography>
            <strong>{t("store.orders.signInBenefits")}</strong>
          </Typography>
          <Button
            startIcon={<BenefitsIcon />}
            onClick={(e) => {
              e.stopPropagation();
              if (!window.location.pathname.includes("auth")) {
                history.push(`${initPath}/auth`);
              }
            }}
          >
            {t("store.orders.signInOrCreate")}
          </Button>
        </DialogContent>
        <br />
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              history.goBack();
            }}
          >
            {t("store.cancel")}
          </Button>
          <Button onClick={handleSubmit}>{t("store.submit")}</Button>
        </DialogActions>
      </React.Fragment>
    );
  }
);

export default STguestInfoModal;
