import { FC, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../App/history";
import {
  validateEmail,
  validatePassword,
} from "../../../utilities/validateEmailPassPhone";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DoneIcon from "@material-ui/icons/Done";
import STauthModal, { useAfterSignin } from "../Modals/sTauth";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import useSpinner from "../../Spinner/useSpinner";
import { useSiteFirebase } from "../../../Firebase/context";
import MUIPopover, {
  MUIPopoverProps,
} from "../../Dashboard/Components/mui-popover";
import useToast from "../../Main/useToast";
import ROUTES from "../../../utilities/constants/routes";

const useStyles = makeStyles(() => ({
  chipLabel: { whiteSpace: "normal" },
}));

interface RegisterProps {
  setMainBgImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Register: FC<RegisterProps> = ({ setMainBgImage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const showSpinner = useSpinner();
  const firebase = useSiteFirebase();

  const [authEmail, setAuthEmail] = useState("");
  const emailIsValid = validateEmail(authEmail);
  const [newPassword, setNewPassword] = useState("");
  const passwordIsValid = validatePassword(newPassword);
  const toast = useToast();
  const anchor1 = useRef<HTMLDivElement | null>(null);
  const [popoverProps, setPopoverProps] = useState<MUIPopoverProps>();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // console.log("authModal.js", authEmail, newPassword);
    if (!emailIsValid) {
      return;
    } else if (!passwordIsValid) {
      return;
    } else {
      signUp(authEmail, newPassword);
    }
  };

  const afterSignIn = useAfterSignin();

  async function signUp(email: string, password: string) {
    console.log("Attempting Sign Up...");
    const hideSpinner = showSpinner();

    try {
      await firebase.createAccount(email, password);
      afterSignIn();

      try {
        firebase.sendVerificationEmail();
      } catch (error) {
        console.log("Email verification error ", error);
      }
    } catch (error) {
      console.log("Create account error ", error);
      let message;
      if (error.code === "auth/email-already-in-use") {
        message = t("toast.emailInUse");
      } else if (error.code === "auth/invalid-email") {
        message = t("toast.badEmail");
      } else {
        message = t("toast.systemFailure");
      }

      toast({
        message,
        color: "error",
        dialog: true,
      });
    } finally {
      hideSpinner();
    }
  }

  useEffect(() => {
    setMainBgImage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {popoverProps && <MUIPopover {...popoverProps} />}
      <Container ref={anchor1} maxWidth="sm">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("store.account.createAccount")}
            <Button
              onClick={(e) => {
                e.stopPropagation();
                const path = `${ROUTES.REGISTER}/auth`;
                if (!window.location.pathname.includes(path)) {
                  history.push(path);
                }
                setPopoverProps({
                  anchorEl: e.currentTarget,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                  transformOrigin: { vertical: "top", horizontal: "right" },
                  route: path,
                  children: <STauthModal />,
                });
              }}
              size="small"
            >
              {t("store.account.signIn")}
            </Button>
          </div>
        </DialogTitle>

        <DialogContent>
          <form noValidate>
            <FormControl fullWidth>
              <TextField
                autoComplete="new-password"
                label={t("store.account.enterEmail")}
                type="email"
                fullWidth
                value={authEmail.toLowerCase()}
                onChange={(e) => setAuthEmail(e.currentTarget.value.trim())}
                error={authEmail.length > 0 && !emailIsValid}
                helperText={
                  authEmail.length > 0 && !emailIsValid
                    ? t("store.account.badEmail")
                    : undefined
                }
              />
            </FormControl>
          </form>
          <div>
            <FormControl fullWidth>
              <TextField
                autoComplete="new-password"
                label={t("store.account.enterPassword")}
                type={showPassword ? "text" : "password"}
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.currentTarget.value)}
                error={newPassword.length > 0 && !passwordIsValid}
                helperText={
                  newPassword.length > 0 && !passwordIsValid
                    ? t("store.account.badPassword")
                    : undefined
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                }
                label={t("store.account.showPassword")}
              />
              <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                <Chip
                  style={{ border: "none", backgroundColor: "transparent" }}
                  variant="default"
                  label={t("store.account.oneLowerCase")}
                  disabled={/[a-z]/.test(newPassword) ? true : false}
                  icon={
                    /[a-z]/.test(newPassword) ? (
                      <DoneIcon />
                    ) : (
                      <ArrowForwardIcon />
                    )
                  }
                />
              </div>
              <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                <Chip
                  style={{ border: "none", backgroundColor: "transparent" }}
                  variant="default"
                  label={t("store.account.oneUpperCase")}
                  disabled={/[A-Z]/.test(newPassword) ? true : false}
                  icon={
                    /[A-Z]/.test(newPassword) ? (
                      <DoneIcon />
                    ) : (
                      <ArrowForwardIcon />
                    )
                  }
                />
              </div>
              <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                <Chip
                  style={{ border: "none", backgroundColor: "transparent" }}
                  variant="default"
                  label={t("store.account.oneNumber")}
                  disabled={/\d/.test(newPassword) ? true : false}
                  icon={
                    /\d/.test(newPassword) ? <DoneIcon /> : <ArrowForwardIcon />
                  }
                />
              </div>
              <div style={{ textAlign: "left", margin: "0.25em 0" }}>
                <Chip
                  classes={{ label: classes.chipLabel }}
                  style={{ border: "none", backgroundColor: "transparent" }}
                  variant="default"
                  label={t("store.account.eightCharacters")}
                  disabled={newPassword.length >= 8 ? true : false}
                  icon={
                    newPassword.length >= 8 ? (
                      <DoneIcon />
                    ) : (
                      <ArrowForwardIcon />
                    )
                  }
                />
              </div>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: "1em" }}>
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            {t("store.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {t("store.submit")}
          </Button>
        </DialogActions>
      </Container>
    </React.Fragment>
  );
};

export default Register;
