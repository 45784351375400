import { useCallback, useEffect } from "react";
import { CatalogItem_Database } from "../../../database/catalogItem";
import { useSearch } from "../Components/autocomplete/useSearch";
import type firebase from "firebase";
import { itemCache } from "./CatalogApiProvider/itemCache";

interface Options {
  searchString: string;
  pageSize?: number;
  // If true, only get items to display on the store (ie, active catalog, non-hidden)
  forStore?: boolean;
  cacheBust?: number;
}

/**
 * Searches the database for catalog items, and merges the results with
 * any edits that have been made locally but not published.
 */
export const useItemNameSearch = ({
  searchString,
  pageSize = 20,
  forStore = false,
  cacheBust,
}: Options) => {
  const queryModification = useCallback(
    (query: firebase.firestore.Query) => {
      if (forStore) {
        return query
          .where("inActiveCatalog", "==", true)
          .where("hidden", "==", false)
          .orderBy("name");
      } else {
        return query.orderBy("name");
      }
    },
    [forStore]
  );

  const { results, loading, hasMoreResults, getMoreResults } =
    useSearch<CatalogItem_Database>({
      searchString,
      collection: "items",
      fieldPath_database: "nameSearch",
      fieldPath_client: "name",
      pageSize,
      allowEmptyStringSearch: true,
      queryModification,
      cacheBust,
    });

  useEffect(() => {
    // Save the results to the item cache, so other components can load quicker
    results.forEach((item) => {
      itemCache[item.itemId] = item;
    });
  }, [results]);

  return { items: results, loading, hasMoreResults, getMoreResults } as const;
};
