import { memo, Fragment, FC, useEffect, useRef, useState } from "react";
import ROUTES from "../../../utilities/constants/routes";
import Dinero from "dinero.js";
import { useTranslation } from "react-i18next";
import useSiteUser from "../../UserProvider/useSiteUser";
import { Order, revisionIsComplete } from "../../../database/order";
import { paymentMethod } from "../../../database/payment";
import STreceiptModal from "../Modals/sTreceipt";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import {
  capturePaypalTransaction,
  createCheckoutSession,
  createPaypalTransaction,
  getStripePaymentIntent,
  lockOrder,
  setDeliveryOptions,
  unlockOrder,
} from "../../../utilities/httpsCallables/httpsCallables";
import useSpinner from "../../Spinner/useSpinner";
import DeliveryOptionSelect from "../Checkout/deliveryOptionSelect";
import { DeliveryOption } from "../Checkout/checkoutMain";
import {
  paymentGateway as paymentGatewayEnum,
  PostalAddress,
} from "../../../database/siteSettings";
import { useSiteFirebase } from "../../../Firebase/context";
import { history } from "../../App/history";
import Container from "@material-ui/core/Container";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { declineRecharge } from "../../../utilities/httpsCallables/httpsCallables";
import useToast from "../../Main/useToast";
import LockIcon from "@material-ui/icons/Lock";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TipSelect from "../Checkout/tipSelect";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { loadStripe } from "../../PaymentProvider/loadStripe";
import { Stripe } from "@stripe/stripe-js";
import MUIDialog, {
  MUIDialogProps,
} from "../../Dashboard/Components/mui-dialog";
import { CreateCheckoutSessionPayload } from "../../../utilities/httpsCallables/types";
import STAddressForm, { validateAddress } from "../Components/addressForm";

interface PaymentAuthMainProps {
  order: Order;
  successUrl?: string;
  goBackOnCancel?: boolean;
}

const PaymentAuthMain: FC<PaymentAuthMainProps> = memo(
  ({ order, successUrl, goBackOnCancel }) => {
    const { t } = useTranslation();
    const siteSettings = useSiteSettings();
    const { tips, paymentGateway, stripeAccountId, paypalClientId, currency } =
      siteSettings;
    const { user } = useSiteUser();
    const toast = useToast();
    const showSpinner = useSpinner();
    const firebase = useSiteFirebase();
    const [dialogProps, setDialogProps] = useState<MUIDialogProps>();

    const [deliveryOption, setDeliveryOption] =
      useState<DeliveryOption>("pickup");
    const [tipPercent, setTipPercent] = useState(tips.defaultPercent);
    const [address, setAddress] = useState<PostalAddress>({
      city: "",
      countryCode: "",
      stateCode: "",
      streetLine1: "",
      streetLine2: "",
      streetLine3: "",
      zip: "",
    });
    useEffect(() => {
      if (deliveryOption === "delivery") {
        const path = `${ROUTES.PAYMENTAUTH}/${order.orderId}/address`;
        if (!window.location.pathname.includes(path)) {
          history.push(path);
        }
      }
    }, [deliveryOption, order.orderId]);

    const warmedUp = useRef(false);
    useEffect(() => {
      if (warmedUp.current || paymentGateway !== paymentGatewayEnum.stripe) {
        return;
      }
      // To improve performance of the checkout, we give the function a kick to
      // wake it up and get it started loading data.
      createCheckoutSession(firebase, {
        type: "warmup",
        orderRequest: null,
      }).catch(console.error);
      warmedUp.current = true;
    }, [firebase, paymentGateway]);

    const latestRevision = order.revisions[order.revisions.length - 1];
    const amount = latestRevision.priceAdjustment.total;
    const payment = latestRevision.payment;
    const tipAmount = tips.enabled
      ? Math.round(
          latestRevision.priceAdjustment.beforeTax * (tipPercent / 100)
        )
      : 0;

    const redirect = () => {
      console.log("paymentAuthMain.tsx GOING TO REDIRECT");
      if (user) {
        console.log("Redirecting user to track page.");
        history.replace(ROUTES.TRACK);
      } else {
        console.log("Redirecting user to home page.");
        history.replace(ROUTES.STORE);
      }
    };

    const paymentInProgress = useRef(false);

    const confirmUpcharge = async () => {
      if (paymentInProgress.current) {
        return;
      }

      if (!payment || payment.method !== paymentMethod.creditStripe) {
        // This would only happen if we write code that calls confirmUpcharge when there's nothing
        //   to confirm. Ie, this should never happen.
        return;
      }

      let stripe: Stripe;
      try {
        stripe = await loadStripe(stripeAccountId);
      } catch (error) {
        toast({
          dialog: true,
          color: "error",
          message: t("store.toast.paymentSystemDown"),
        });
        return;
      }

      paymentInProgress.current = true;
      const hideSpinner = showSpinner({ lag: "none" });

      try {
        const [existingPaymentIntent] = await Promise.all([
          getStripePaymentIntent(firebase, {
            action: "retrieve",
            paymentIntentId: payment.paymentIntentId,
            amount: latestRevision.priceAdjustment.total,
          }),
          lockOrder(firebase, { orderId: order.orderId }),
        ]);

        try {
          //WANT TO CATCH PAYMENT ERROR SEPARATELY
          const paymentIntentResponse = await stripe.confirmCardPayment(
            existingPaymentIntent?.clientSecret || "",
            {
              payment_method: existingPaymentIntent?.id,
            }
          );
          if (paymentIntentResponse.paymentIntent?.status === "succeeded") {
            // TESTED 2/3/2021
            if (!window.location.pathname.includes("success")) {
              history.push(`${ROUTES.PAYMENTAUTH}/${order.orderId}/success`);
            }
            setDialogProps({
              route: `${ROUTES.PAYMENTAUTH}/${order.orderId}/success`,
              children: (
                <Container maxWidth="lg">
                  <DialogTitle>
                    {t("store.checkout.payment.success")}
                  </DialogTitle>
                  <DialogContent>
                    <Typography>{t("store.orders.thankYou")}</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        redirect();
                      }}
                    >
                      {t("store.close")}
                    </Button>
                  </DialogActions>
                </Container>
              ),
            });
          } else {
            console.log("PAYMENT ERROR:", paymentIntentResponse.error);
            // TESTED 2/3/2021
            toast({
              dialog: true,
              color: "error",
              message:
                paymentIntentResponse.error?.message || t("toast.systemError"),
            });
          }
        } catch (error) {
          console.log("PAYMENT ERROR:", error);
          // TESTED 2/3/2021
          toast({
            dialog: true,
            color: "error",
            message: error,
          });
        }
      } catch (error) {
        let message: string = t("store.toast.paymentSystemDown");
        if (
          error.code === "failed-precondition" &&
          (error.message === "Price mismatch" ||
            error.message === "Wrong state")
        ) {
          message = t("store.paymentAuth.orderChanged");
        }
        //TESTED 2/3/2021
        toast({
          dialog: true,
          color: "error",
          message,
        });
      } finally {
        hideSpinner();
        paymentInProgress.current = false;
      }
    };

    const deliveryAddressNeeded =
      order.delivery.delivery && !order.recipient.address;
    const paymentNeeded = !revisionIsComplete(latestRevision) && amount > 0;
    const isNewOrder = order.revisions.length === 1;

    const handleCancelCharge = async () => {
      const hideSpinner = showSpinner({ lag: "none" });
      try {
        await declineRecharge(firebase, {
          orderId: order.orderId,
          url: `${window.location.protocol}//${window.location.host}`,
        });
        if (goBackOnCancel) {
          history.go(-2);
        } else {
          redirect();
        }
      } catch (error) {
        /** TESTED FEB 3 2021 */
        toast({
          dialog: true,
          color: "error",
          message: t("toast.systemError"),
        });

        console.log("ERROR UPDATING ORDER", error);
      } finally {
        hideSpinner();
      }
    };

    const transaction = useRef<{
      orderId: string;
      paypalOrderId: string;
    } | null>(null);

    if (!deliveryAddressNeeded && !paymentNeeded) {
      // No action is needed on this order
      return <div>{t("store.paymentAuth.noActionNeeded")}</div>;
    }

    return (
      <Fragment>
        {dialogProps && <MUIDialog {...dialogProps} />}
        <div style={{ margin: "max(1.5em, 1.5vw) 0" }}>
          {paymentNeeded
            ? isNewOrder
              ? t("store.paymentAuth.reviewAndAuthorize")
              : t("store.paymentAuth.newCharge")
            : t("store.paymentAuth.chooseDeliveryOptions")}
        </div>

        <Button
          fullWidth
          startIcon={<ReceiptIcon />}
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            const path = `${ROUTES.PAYMENTAUTH}/${order.orderId}/receipt`;
            if (window.location.pathname !== path) {
              history.push(path);
            }
            setDialogProps({
              route: path,
              children: <STreceiptModal order={order} />,
            });
          }}
        >
          {t("store.paymentAuth.reviewOrder")}
        </Button>

        <br />
        <br />

        <div>
          {deliveryAddressNeeded && (
            <Fragment>
              <DeliveryOptionSelect
                deliveryOption={deliveryOption}
                setDeliveryOption={setDeliveryOption}
                builder={false}
                onClick={(e) => {
                  e.stopPropagation();
                  if (deliveryOption === "delivery") {
                    const path = `${ROUTES.PAYMENTAUTH}/${order.orderId}/address`;
                    if (!window.location.pathname.includes(path)) {
                      history.push(path);
                    }
                  }
                }}
              />
              <MUIDialog
                route={`${ROUTES.PAYMENTAUTH}/${order.orderId}/address`}
              >
                <STAddressForm
                  initialAddress={address}
                  onSubmit={(newAddress) => {
                    setAddress(newAddress);
                    history.goBack();
                  }}
                  initPath={`${ROUTES.PAYMENTAUTH}/${order.orderId}/address`}
                />
              </MUIDialog>
            </Fragment>
          )}
          {/** Can only add a tip for the full send-payment functionality, not for an upcharge confirmation */}
          {tips.enabled && !payment && (
            <TipSelect
              tipPercent={tipPercent}
              setTipPercent={setTipPercent}
              priceBeforeTax={Dinero({
                amount: latestRevision.priceAdjustment.beforeTax,
              })}
              builder={false}
            />
          )}
        </div>

        {paymentNeeded && paymentGateway === paymentGatewayEnum.stripe ? (
          // User is confirming an upcharge
          <Button
            variant="contained"
            color="primary"
            startIcon={<LockIcon />}
            onClick={async (e) => {
              e.stopPropagation();
              if (deliveryAddressNeeded && deliveryOption === "delivery") {
                const errorMessage = validateAddress(address, t);
                if (errorMessage) {
                  toast({
                    dialog: true,
                    color: "error",
                    message: t("store.paymentAuth.provideAddress"),
                  });
                  return;
                }
              }

              if (payment) {
                confirmUpcharge();
              } else {
                let stripe: Stripe;
                try {
                  stripe = await loadStripe(stripeAccountId);
                } catch (error) {
                  toast({
                    dialog: true,
                    color: "error",
                    message: t("store.toast.paymentSystemDown"),
                  });
                  return;
                }

                const hideSpinner = showSpinner({ lag: "none" });
                try {
                  let rootUrl = `${window.location.origin}${ROUTES.rootPath}`;

                  const payload: CreateCheckoutSessionPayload = {
                    type: "existing",
                    orderId: order.orderId,
                    rootUrl,
                    tip: tips.enabled ? tipAmount : 0,
                  };
                  if (successUrl) {
                    payload.successUrl = successUrl;
                  }
                  const { id } = await createCheckoutSession(firebase, payload);
                  const result = await stripe.redirectToCheckout({
                    sessionId: id,
                  });
                  // history.replace(ROUTES.CHECKOUT);
                  if (result.error) {
                    console.error("result.error during checkout", result.error);
                    toast({
                      dialog: true,
                      color: "error",
                      message: t("store.orders.unknownError"),
                    });
                  }
                } catch (error) {
                  console.error("error doing checkout", error);
                  if (error.message.includes("charges not enabled")) {
                    toast({
                      dialog: true,
                      color: "error",
                      message: t("store.orders.chargesNotEnabled"),
                    });
                  } else {
                    toast({
                      dialog: true,
                      color: "error",
                      message: t("store.orders.unknownError"),
                    });
                  }
                  return;
                } finally {
                  hideSpinner();
                }
              }
            }}
          >
            {`${t("store.checkout.payButton")} ${Dinero({
              amount: amount + (tips.enabled ? tipAmount : 0),
            }).toFormat()}`}
          </Button>
        ) : paymentNeeded &&
          paymentGateway === paymentGatewayEnum.paypal &&
          paypalClientId ? (
          <PayPalScriptProvider
            options={{
              "client-id": paypalClientId,
              currency: currency.currency,
            }}
          >
            <Container maxWidth="xs">
              <PayPalButtons
                style={{ color: "silver" }}
                createOrder={async (data, actions) => {
                  const paypalOrderId = await createPaypalTransaction(
                    firebase,
                    {
                      type: "existing",
                      orderId: order.orderId,
                      tip: tips.enabled ? tipAmount : 0,
                    }
                  );
                  // Remember what we're working on, so it can be used in onApprove
                  transaction.current = {
                    orderId: order.orderId,
                    paypalOrderId,
                  };
                  return paypalOrderId;
                }}
                onApprove={async (data, actions) => {
                  if (!transaction.current) {
                    // I don't think this can happen
                    console.log("somehow got approval before creating order");
                    return;
                  }

                  history.push(`${ROUTES.TRACK}?success=true`);
                  await capturePaypalTransaction(firebase, transaction.current);
                }}
                onCancel={async () => {
                  const orderId = transaction.current?.orderId;
                  transaction.current = null;
                  if (orderId) {
                    try {
                      await unlockOrder(firebase, { orderId });
                    } catch {}
                  }
                }}
              />
            </Container>
          </PayPalScriptProvider>
        ) : (
          // The user is just filling in delivery information

          <Button
            variant="contained"
            color="primary"
            startIcon={<LockIcon />}
            onClick={async (e) => {
              e.stopPropagation();
              if (deliveryAddressNeeded && deliveryOption === "delivery") {
                const errorMessage = validateAddress(address, t);
                if (errorMessage) {
                  toast({
                    dialog: true,
                    color: "error",
                    message: t("store.paymentAuth.provideAddress"),
                  });
                  return;
                }
              }
              const hideSpinner = showSpinner();
              try {
                await setDeliveryOptions(firebase, {
                  orderId: order.orderId,
                  delivery: deliveryOption === "delivery",
                  deliveryAddress:
                    deliveryOption === "delivery" ? address : null,
                });
                redirect();
              } catch (err) {
                toast({
                  dialog: true,
                  color: "error",
                  message: t("store.toast.somethingWrong"),
                });
              } finally {
                hideSpinner();
              }
            }}
          >
            {t("store.submit")}
          </Button>
        )}
        <br />
        <br />
        {paymentNeeded && (
          <Button
            size="small"
            startIcon={<DeleteIcon />}
            onClick={(e) => {
              e.stopPropagation();
              if (!window.location.pathname.includes("cancel")) {
                history.push(`${ROUTES.PAYMENTAUTH}/${order.orderId}/cancel`);
              }
              setDialogProps({
                route: `${ROUTES.PAYMENTAUTH}/${order.orderId}/cancel`,

                children: (
                  <Fragment>
                    <DialogTitle className="smartWidthMd">
                      {isNewOrder
                        ? t("store.paymentAuth.cancelOrder")
                        : t("store.paymentAuth.cancelCharge")}
                    </DialogTitle>
                    <DialogContent className="smartWidthMd">
                      <Typography>
                        {isNewOrder
                          ? t("store.paymentAuth.confirmCancelOrderText")
                          : t("store.paymentAuth.confirmCancelUpchargeText")}
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          history.goBack();
                        }}
                      >
                        {t("store.cancel")}
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCancelCharge();
                        }}
                      >
                        {t("store.confirm")}
                      </Button>
                    </DialogActions>
                  </Fragment>
                ),
              });
            }}
          >
            {isNewOrder
              ? t("store.paymentAuth.cancelOrder")
              : t("store.paymentAuth.cancelCharge")}
          </Button>
        )}
      </Fragment>
    );
  }
);

export default PaymentAuthMain;
