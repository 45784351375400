import { TFunction } from "i18next";
import { CatalogItem_WithDefaults } from "../../database/catalogItem";
import { getUnitName } from "../../utilities/getUnitName";

interface Options {
  catalogItem: CatalogItem_WithDefaults;
  count: number;
  t: TFunction;
}

export const checkUnitRules = ({
  catalogItem,
  count,
  t,
}: Options): undefined | { message: string; adjustedCount: number } => {
  let { unit } = catalogItem;

  if (!unit) {
    unit = {
      isCustomUnit: true,
      name: "",
      namePlural: "",
      minValue: 1,
      maxValue: Number.MAX_SAFE_INTEGER,
      increment: 1,
      priceDenominator: 1,
      defaultValue: 1,
    };
  }

  const rounded = Math.round(count / unit.increment) * unit.increment;
  if (count < unit.minValue) {
    return {
      message: t("store.orders.amountToSmall", {
        amount: unit.minValue,
        unit: getUnitName(unit, unit.minValue, t),
      }),
      adjustedCount: unit.minValue,
    };
  } else if (count > unit.maxValue) {
    return {
      message: t("store.orders.amountToBig", {
        amount: unit.maxValue,
        unit: getUnitName(unit, unit.maxValue, t),
      }),
      adjustedCount: unit.maxValue,
    };
  } else if (count !== rounded) {
    return {
      message: t("store.orders.amountNeededRounding", {
        amount: unit.increment,
        unit: getUnitName(unit, unit.increment, t),
      }),
      adjustedCount: rounded,
    };
  }
};
