import { memo, Fragment, Dispatch, FC, SetStateAction, useEffect } from "react";
import { FancyText } from "../../../../../database/fancyText";
import { loadFonts } from "../../../../../utilities/fontLoader";
import {
  useWidgetGlobalState,
  WidgetGlobalStateType,
} from "../../widgetGlobalState";
import Button from "./button";
import { ButtonAction, useExecuteAction } from "./buttonActions";

export interface ButtonWidgetProps {
  fancyText: FancyText;
  secondaryText?: FancyText["text"];
  secondaryIcon?: FancyText["icon"];
  variant: "contained" | "outlined" | "text";
  action: ButtonAction | null;
  animate: boolean;
}

const ButtonWidget: FC<ButtonWidgetProps> = (props) => {
  const { state, setState } = useWidgetGlobalState();
  const collapsed =
    props.action?.type === "collapse" && props.action.widgetId
      ? Boolean(state.collapsedWidgets[props.action.widgetId])
      : false;
  return (
    <InnerComponent
      {...props}
      collapsed={collapsed}
      setWidgetGlobalState={setState}
    />
  );
};

type InnerProps = ButtonWidgetProps & {
  collapsed: boolean;
  setWidgetGlobalState: Dispatch<SetStateAction<WidgetGlobalStateType>>;
};

const InnerComponent: FC<InnerProps> = memo(
  ({
    fancyText = { text: "BUTTON", fontSize: 14 },
    secondaryText,
    secondaryIcon,
    action,
    collapsed,
    setWidgetGlobalState,
    animate,
  }) => {
    if (collapsed && (secondaryText || secondaryIcon)) {
      fancyText = { ...fancyText };
      if (secondaryText) {
        fancyText.text = secondaryText;
      }
      if (secondaryIcon) {
        fancyText.icon = secondaryIcon;
      }
    }

    const { execute, elements } = useExecuteAction(
      action,
      setWidgetGlobalState
    );

    useEffect(() => {
      if (fancyText.fontFamily) {
        // Ideally, the font is in the site settings and we've already loaded it,
        // but in case we missed it, load it now.
        loadFonts(fancyText.fontFamily);
      }
    }, [fancyText.fontFamily]);

    return (
      <Fragment>
        {elements}
        <Button fancyText={fancyText} onClick={execute} animate={animate} />
      </Fragment>
    );
  }
);

export default ButtonWidget;
