import { FC, useContext } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import FavoriteIcon from "@material-ui/icons/Favorite";
import UnfavoriteIcon from "@material-ui/icons/FavoriteBorder";
import { IconFavoriteWidgetProps } from "../../../../database/orderPage";
import { CatalogItem_WithDefaults } from "../../../../database/catalogItem";
import {
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { BuilderContext } from "../../../Dashboard/Builder/OrdersBuilder/builderContext";
import useAccountInfo from "../../../Main/useAccountInfo";
import useToast from "../../../Main/useToast";
import useSiteUser from "../../../UserProvider/useSiteUser";
import MUIDialog from "../../../Dashboard/Components/mui-dialog";
import { history } from "../../../App/history";
import { useToggleFavorite } from "../../../../utilities/toggleFavorite";
import { useLocation } from "react-router";

export interface Props extends IconFavoriteWidgetProps {
  catalogItem: CatalogItem_WithDefaults;
}

const IconFavoriteWidget: FC<Props> = React.memo(({ catalogItem }) => {
  const { t } = useTranslation();
  const { user } = useSiteUser();
  const accountInfo = useAccountInfo();
  const toast = useToast();
  const builder = useContext(BuilderContext);

  const location = useLocation<{ unfavorite?: string } | undefined>();

  const isFavorite = accountInfo.favorites2?.includes(catalogItem.itemId);

  const toggleFavorite = useToggleFavorite();

  const onFavoriteClicked = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (builder) {
      return;
    } else if (!user) {
      toast({
        dialog: true,
        color: "normal",
        message: t("store.orders.startFavoring"),
      });
      return;
    }

    if (isFavorite) {
      if (location.state?.unfavorite !== catalogItem.itemId) {
        history.push(location.pathname, {
          ...location.state,
          unfavorite: catalogItem.itemId,
        });
      }
    } else {
      toggleFavorite(catalogItem.itemId);
    }
  };

  return (
    <React.Fragment>
      <MUIDialog
        route={""}
        stateMatch={(state) => state.unfavorite === catalogItem.itemId}
      >
        <DialogTitle className="smartWidthMd">
          {t("store.orders.removeFavorite")}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              history.goBack();
            }}
          >
            {t("store.cancel")}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              toggleFavorite(catalogItem.itemId);
              history.goBack();
            }}
          >
            {t("store.confirm")}
          </Button>
        </DialogActions>
      </MUIDialog>
      <IconButton
        color="secondary"
        onClick={(e) => {
          e.stopPropagation();
          onFavoriteClicked(e);
        }}
      >
        {isFavorite ? <FavoriteIcon /> : <UnfavoriteIcon />}
      </IconButton>
    </React.Fragment>
  );
});

export default IconFavoriteWidget;
