import { FC, useContext, useState } from "react";
import { CatalogItem_WithDefaults } from "../../../../database/catalogItem";
import {
  OrderPageOptions,
  OrderTemplate,
} from "../../../../database/orderPage";
import useWindowResize from "../../../../utilities/useWindowResize";
import { BuilderContext } from "../../../Dashboard/Builder/OrdersBuilder/builderContext";
import ItemGrid from "./gridItem_grid";

interface Grid_1Props {
  templateOptions: OrderPageOptions<OrderTemplate.original>;
  heightCorrection: number | undefined;
  headerHeight: number;
  items: CatalogItem_WithDefaults[];
  categoryId: string;
  widthCorrection?: number;
}

const Grid_1: FC<Grid_1Props> = ({
  templateOptions,
  heightCorrection,
  headerHeight,
  items,
  categoryId,
  widthCorrection = 0,
}) => {
  const dimensions = useWindowResize("grid");

  const builder = useContext(BuilderContext);

  const {
    breakpointSm,
    breakpointLg,
    columnsSm,
    columnsMd,
    columnsLg,
    columnGap,
    columnWidth,
    rowGap,
    masonry,
    align,
  } = templateOptions.props;

  const [rowGapOrig] = useState(rowGap);
  const [columnGapOrig] = useState(columnGap);

  const columnWidthFinal =
    columnWidth === "1fr" ? columnWidth : `${columnWidth}px`;

  let gridColumns = columnsLg;

  if (dimensions.w - widthCorrection < breakpointSm) {
    gridColumns = columnsSm;
  } else if (dimensions.w - widthCorrection < breakpointLg) {
    gridColumns = columnsMd;
  }

  return (
    <div
      id="gridOuter"
      style={{
        width: "100%",
        maxHeight: builder
          ? /** -1EM MARGIN IN CATEGORY BAR */
            `calc(100% - ${heightCorrection}px - ${headerHeight}px - 1em)`
          : undefined,
        position: "relative",
        paddingBottom: builder ? 0 : "1em",
        breakInside: "avoid",
      }}
    >
      <div
        id="gridParent"
        key={categoryId + columnGap + rowGap}
        style={{
          display: masonry ? undefined : "grid",
          columns: masonry ? gridColumns : undefined,

          //WE CALCULATE OUR OWN WIDTH PERCENTAGES BASED ON PIXELS
          //AS OPPOSED TO USING VW UNITS AS THE LATTER WILL NOT
          //WORK CORRECTLY DURING PREVIEW

          columnGap: `${Math.round(
            ((dimensions.w - widthCorrection) / 100) * columnGap
          )}px`,
          rowGap: `${Math.round(
            ((dimensions.w - widthCorrection) / 100) * rowGap
          )}px`,
          padding: "0 max(1vw, 1em)",
          // opacity: 0,
          /**
           * minmax(0, 1fr) allows the grid tracks to be as small
           * as 0 but as large as 1fr, creating columns that will
           * stay equal. But, be aware that this will cause overflows
           * if the content is bigger than the column or cannot be
           * wrapped.
           */
          gridTemplateColumns: `repeat(${gridColumns}, minmax(0, 
            ${columnWidthFinal}))`,
          justifyContent: align,
        }}
      >
        {items.map(
          (item) =>
            !item.hidden && (
              <ItemGrid
                // TODO: may get keying problems when switching categories or using a search
                key={
                  item.itemId /*categories[catIndex].categoryId + "-" + item.itemId*/
                }
                templateOptions={templateOptions}
                // TODO: category name, when relevant
                // categoryName={categories[catIndex].name}
                catalogItem={item}
                widthCorrection={widthCorrection}
                rowGapOrig={rowGapOrig}
                columnGapOrig={columnGapOrig}
              />
            )
        )}
      </div>
    </div>
  );
};

export default Grid_1;
