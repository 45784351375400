import { FC, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import { history } from "../../../App/history";
import type firebase from "firebase";
import useWindowResize from "../../../../utilities/useWindowResize";
import { applyBodyStyle } from "../../../../utilities/bodyStyleCoordinator";
import { PaperProps } from "@material-ui/core/Paper";
import { CSSProperties } from "@material-ui/styles";
import Content from "./content";

interface STDrawerProps {
  open: boolean;
  user: firebase.User | null;
  paperProps?: Partial<PaperProps>;
  hideBackDrop?: boolean;
  disableBackdropClick?: boolean;
  position?: "left" | "top" | "right" | "bottom";
  style?: CSSProperties;
}

const STDrawer: FC<STDrawerProps> = ({
  open,
  user,
  paperProps,
  hideBackDrop = false,
  disableBackdropClick = false,
  position = "left",
  style,
}) => {
  const dimensions = useWindowResize("sTdrawer");

  useEffect(() => {
    if (dimensions.touch) {
      if (open && dimensions.touch) {
        /**
         * WHEN WE OPEN A DRAWER WE WANT TO PREVENT THE BODY
         * ELEMENT FROM SCROLLING AS ON MOBILE DEVICES THE URL
         * BAR MOVES UP DURING BODY SCROLL, WHICH MAKES THE DRAWER
         * JUMP UP
         */

        const scrollPos = window.scrollY;
        const cleanup = () => {
          window.scrollTo({ top: scrollPos });
        };
        return applyBodyStyle((style) => {
          style.position = "fixed";
          style.width = "100vw";
          style.maxWidth = "100vw";
          style.height = "100%";
          style.maxHeight = "100%";
          if (scrollPos !== 0) {
            style.top = `-${scrollPos}px`;
          }
        }, cleanup);
      }
    }
  }, [dimensions, open]);

  return (
    <Drawer
      PaperProps={{
        ...paperProps,
      }}
      hideBackdrop={hideBackDrop}
      disableBackdropClick={disableBackdropClick}
      anchor={position}
      open={open}
      onClose={() => history.goBack()}
      transitionDuration={{
        enter: 200,
        exit: 200,
      }}
      style={{ ...style }}
    >
      <Content user={user} builder={false} />
    </Drawer>
  );
};

export default STDrawer;
