import { useState } from "react";
import {
  OptionSelection,
  CartItem,
  CartItemCollection,
} from "../../database/cart";
import {
  CatalogItem_Database,
  CatalogItem_WithDefaults,
} from "../../database/catalogItem";
import { useMemo, useEffect, useCallback, FC } from "react";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import { getSignature } from "../../utilities/orderProcessing";
import { CartContext } from "./useCartApi";
import { storeKey } from "../../Firebase/siteFirebase";
import { useSiteFirebase } from "../../Firebase/context";
import ROUTES from "../../utilities/constants/routes";
import { orderExists } from "../../utilities/httpsCallables/httpsCallables";
import { useQuery } from "../../utilities/useQuery";
import { useSiteSettings } from "../../customization/siteSettingsContext";
import { convertItemAndAddDefaults } from "../../utilities/typeConversions";

export interface Cart {
  _version: 2;
  orderId: string;
  cartItems: CartItemCollection;
}

interface params {
  catalogItem: CatalogItem_WithDefaults;
  cartItemId: string;
  count: number;
  instructions?: string;
  optionSelections?: OptionSelection[];
}
export const createCartItem = ({
  catalogItem,
  count,
  instructions,
  optionSelections,
  cartItemId,
}: params): CartItem => ({
  _version: 2,
  cartItemId,
  catalogItem,
  instructions,
  optionSelections: optionSelections ?? [],
  count,
  checked: 0,
});

export interface CartApi extends Cart {
  clearCart: (becauseTheyPaid?: boolean) => void;
  restoreCart: (items: CartItemCollection) => Promise<string | undefined>;
  addItem: (
    catalogItem: CatalogItem_WithDefaults,
    count: number,
    instructions?: string,
    options?: OptionSelection[]
  ) => void;
  removeItem: (cartItemId: string) => void;
  setItemInstructions: (cartItemId: string, instructions: string) => void;
  setItemOptionSelections: (
    cartItemId: string,
    selections: OptionSelection[]
  ) => void;
  setItemCount: (cartItemId: string, count: number) => void;
}

// Note: Ids are only unique for a given session, so reloading old meals requires a new id.
export const createUniqueID = (() => {
  let id = 0;
  return () => {
    id += 1;
    return "" + id;
  };
})();

const CartApiProvider: FC = (props) => {
  const firebase = useSiteFirebase();
  const { labels, taxPercentage, priceIncludesTax, currency } =
    useSiteSettings();
  const initialCart = useMemo<Cart>(() => {
    const ref = firebase.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("orders")
      .doc();
    return {
      _version: 2,
      orderId: ref.id,
      cartItems: {},
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [cart, setCart] = useState<Cart>(initialCart);
  useEffect(() => {
    if (cart !== initialCart) {
      sessionStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart, initialCart]);

  const query = useQuery();
  const justPaid =
    window.location.pathname === ROUTES.TRACK &&
    query.get("success") === "true";

  useEffect(() => {
    const loadFromStorage = async () => {
      if (justPaid) {
        // Returning from stripe checkout; clear storage
        sessionStorage.removeItem("cart");
      } else {
        // Attempt to restore from storage
        const value = sessionStorage.getItem("cart");
        if (!value) {
          return;
        }

        let parsed;
        try {
          parsed = JSON.parse(value);
        } catch (err) {}

        // Validate that the saved data is in the expected form
        if (
          typeof parsed === "object" &&
          parsed !== null &&
          parsed._version === 2 &&
          typeof parsed.orderId === "string" &&
          typeof parsed.cartItems === "object"
        ) {
          const cart = parsed as Cart;
          // Most of the time, we want to restore the cart, so do it immediately
          setCart(cart);
          // But then double check that this order hasn't already been completed.
          //    This only happens if the user does something during checkout which
          //    interrupts the redirect
          const exists = await orderExists(firebase, { orderId: cart.orderId });
          if (exists) {
            clearCart();
          }
        }
      }
    };

    loadFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const clearCart = useCallback(() => {
    const ref = firebase.firestore
      .collection("stores")
      .doc(storeKey)
      .collection("orders")
      .doc();
    setCart({
      _version: 2,
      orderId: ref.id,
      cartItems: {},
    });
  }, [firebase.firestore, setCart]);

  const restoreCart = useCallback(
    async (items: CartItemCollection) => {
      if (Object.values(items).length === 0) {
        return;
      }
      let missingItems = false;
      const newItems: CartItemCollection = {};
      const promises: Promise<void>[] = [];
      // Look up the latest catalogItem for each cart item
      const validateItem = async (cartItem: CartItem) => {
        const doc = await firebase.firestore
          .collection("stores")
          .doc(storeKey)
          .collection("items")
          .doc(cartItem.catalogItem.itemId)
          .get();
        const latestCatalogItem = doc.data() as
          | CatalogItem_Database
          | undefined;

        if (!latestCatalogItem || latestCatalogItem.unavailable) {
          // This item no longer exists
          missingItems = true;
        } else {
          const catalogItem = convertItemAndAddDefaults(
            latestCatalogItem,
            labels,
            taxPercentage,
            priceIncludesTax,
            currency
          );
          newItems[cartItem.cartItemId] = {
            ...cartItem,
            catalogItem,
          };
        }
      };

      for (const itemId in items) {
        const cartItem = items[itemId];
        promises.push(validateItem(cartItem));
      }
      await Promise.all(promises);

      const restoredAtLeastOneItem = Object.values(newItems).length > 0;
      if (!restoredAtLeastOneItem) {
        return t("store.orders.failureToRestoreCart");
      }
      setCart((prevCart) => ({
        ...prevCart,
        cartItems: newItems,
      }));
      return missingItems
        ? t("store.orders.partialFailureToRestoreCart")
        : undefined;
    },
    [currency, firebase.firestore, priceIncludesTax, t, labels, taxPercentage]
  );

  const addItem = useCallback(
    (
      catalogItem: CatalogItem_WithDefaults,
      count: number,
      instructions?: string,
      optionSelections?: OptionSelection[]
    ) => {
      setCart((prevCart) => {
        let newItems = {
          ...prevCart.cartItems,
        };

        let cartItemId;
        do {
          // Loop in case we restored an old order and need to
          //   increment past the ids it contains.
          cartItemId = createUniqueID();
        } while (newItems[cartItemId]);

        const newItem = createCartItem({
          catalogItem,
          cartItemId,
          count,
          instructions,
          optionSelections,
        });
        const signature = getSignature(newItem);

        // Check if this item already is in the cart.
        const matchingItem = Object.values(newItems).find(
          (item) => getSignature(item) === signature
        );
        if (matchingItem) {
          // If so, bump its count
          newItems[matchingItem.cartItemId] = {
            ...newItems[matchingItem.cartItemId],
            count: newItems[matchingItem.cartItemId].count + count,
          };
        } else {
          newItems[cartItemId] = newItem;
        }

        return {
          ...prevCart,
          cartItems: newItems,
        };
      });
    },
    [setCart]
  );

  const removeItem = useCallback(
    (cartItemId: string) => {
      setCart((prevCart) => {
        return {
          ...prevCart,
          cartItems: R.omit([cartItemId], prevCart.cartItems),
        };
      });
    },
    [setCart]
  );

  const setItemInstructions = useCallback(
    (cartItemId: string, instructions: string) => {
      setCart((prevCart) => {
        const newCart: Cart = R.evolve(
          {
            cartItems: {
              [cartItemId]: {
                instructions: () => instructions,
              },
            },
          },
          prevCart
        );
        return newCart;
      });
    },
    [setCart]
  );

  const setItemOptionSelections = useCallback(
    (cartItemId: string, selections: OptionSelection[]) => {
      setCart((prevCart) => {
        const newCart: Cart = R.evolve(
          {
            cartItems: {
              [cartItemId]: {
                optionSelections: () => selections,
              },
            },
          },
          prevCart
        );
        return newCart;
      });
    },
    [setCart]
  );

  const setItemCount = useCallback(
    (cartItemId: string, count: number) => {
      setCart((prevCart) => {
        const newCart: Cart = R.evolve(
          {
            cartItems: {
              [cartItemId]: {
                count: () => count,
              },
            },
          },
          prevCart
        );
        return newCart;
      });
    },
    [setCart]
  );

  const value = useMemo(() => {
    const api: CartApi = {
      ...cart,
      clearCart,
      addItem,
      removeItem,
      restoreCart,
      setItemInstructions,
      setItemOptionSelections,
      setItemCount,
    };
    return api;
  }, [
    addItem,
    cart,
    clearCart,
    removeItem,
    restoreCart,
    setItemCount,
    setItemInstructions,
    setItemOptionSelections,
  ]);
  return (
    <CartContext.Provider value={value}>{props.children}</CartContext.Provider>
  );
};

export default CartApiProvider;
