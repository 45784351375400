import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CSSProperties, FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneNumber } from "../../../../database/siteSettings";
import { countries } from "./countries";
import "./styles.css";
import { ListboxComponent } from "./listboxComponent";
import { formatNumber } from "./formatNumber";

export interface PhoneInputProps {
  value: PhoneNumber | null;
  onChange: (newValue: PhoneNumber | null) => void;
  style?: CSSProperties;
  disabled?: boolean;
  placeholder?: string;
  siteCountryCode?: string;
  className?: string;
}

const PhoneInput: FC<PhoneInputProps> = ({
  value,
  onChange,
  style,
  disabled,
  placeholder,
  siteCountryCode = "1",
  className,
}) => {
  const { t } = useTranslation();
  placeholder = placeholder || t("store.messages.enterPhone");
  const [showFormatted, setShowFormatted] = useState(true);
  const countryCode = value?.countryCode ?? siteCountryCode ?? "1";
  const country = useMemo(() => {
    const matches = countries.filter(
      (country) => country.dialCode === countryCode
    );
    matches.sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
    return matches[0] ?? null;
  }, [countryCode]);
  const selectedIndex = useMemo(() => {
    return countries.indexOf(country);
  }, [country]);
  const [defaultValue] = useState(country);

  let mainNumber = value ? value.raw.slice(countryCode.length).trim() : "";

  return (
    <div style={style} className="smartWidthMd">
      <Autocomplete
        fullWidth
        disabled={disabled}
        inputMode="tel"
        defaultValue={defaultValue}
        onChange={(event, newValue) => {
          if (newValue) {
            onChange({
              raw: newValue.dialCode + mainNumber,
              formatted:
                `+${newValue.dialCode} ` + formatNumber(mainNumber, country),
              countryCode: newValue.dialCode,
            });
          }
        }}
        className={className}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        disableListWrap
        ListboxComponent={ListboxComponent}
        ListboxProps={{ selectedIndex }}
        options={countries}
        getOptionLabel={(country) =>
          t(`store.account.country.${country.nameKey}`) +
          ` (+${country.dialCode})`
        }
        getOptionSelected={(country) => country.dialCode === countryCode}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(country) => (
          <div
            style={{
              display: "flex",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              padding: 0,
            }}
          >
            <div
              className={`flag ${country.iso2Code}`}
              style={{ marginRight: "0.25em" }}
            />
            <div
              style={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t(`store.account.country.${country.nameKey}`)}
            </div>
            <div style={{ marginLeft: "0.25em" }}>+{country.dialCode}</div>
          </div>
        )}
      />
      <TextField
        style={{ marginTop: "0.5em" }}
        fullWidth
        disabled={disabled}
        label="Phone Number"
        placeholder={placeholder}
        value={showFormatted ? formatNumber(mainNumber, country) : mainNumber}
        onFocus={() => setShowFormatted(false)}
        onBlur={() => setShowFormatted(true)}
        onChange={(e) => {
          onChange({
            raw: countryCode + e.currentTarget.value,
            formatted:
              `+${countryCode} ` + formatNumber(e.currentTarget.value, country),
            countryCode,
          });
        }}
      />
    </div>
  );
};

export default PhoneInput;
