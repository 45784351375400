export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      navigator.serviceWorker.addEventListener("message", (e) => {
        console.log("serviceWorker", e.data);
      });

      //THE ORDER OF THE SERVICE WORKER REGISTRATION WILL VARY.
      //SOMETIMES IT WILL SHOW BEING REGISTERED
      //AFTER IT WAS ACTIVATED. THIS IS NORMAL BEHAVIOR.
      // console.log("serviceWorker registered", reg);
    } catch (error) {
      console.log("serviceWorker not registered.", error);
    }
  } else {
    //INDICATE NO BROWSER SUPPORT TO THE USER?
  }
};
