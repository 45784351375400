import { PageState } from "../components/Dashboard/Builder/WebsiteBuilder/pageApi/reducer";
import { PhoneNumber, PostalAddress } from "./siteSettings";

/**
 * Information about a user of the site. They are stored in
 * /stores/store/users
 */
export interface SiteUser {
  contactInfo: ContactInfo;
  // First version of favorites was an unordered collection. Now
  //   it's an ordered array
  favorites2?: string[];
  notifications: NotificationOptions;
  claims: SiteClaims;
  uid: string;
  /**
   * Used to know how many pages to expect in the tracking page
   */
  orderCount: number;
  /**
   * Saved copies of pages that are being worked on
   */
  pageWorkInProgress?: PageState;
}

/**
 * Someone with a special role on the site.
 *
 * Note: these accounts are administered by the gridbash authentication
 * system, not the site system. Site authentication only contains
 * customer accounts (who's role is null | undefined)
 */
export type SiteAdmin = SiteUser & {
  claims: {
    role: GridbashRole;
  };
};

/**
 * @deprecated
 */
export interface ContactInfoOld {
  city: string;
  country: string;
  email: string;
  emailSearch: string[];
  name: string;
  phone: string;
  state: string;
  street: string;
  suite: string;
  zip: string;
}

export interface ContactInfo {
  email: string;
  emailSearch: string[];
  name: string;
  phone: PhoneNumber | null;
  phoneSearch: string[];
  address: PostalAddress;
  /**
   * If true, the user has explicitly asked us not to send them sms.
   */
  smsOptOut?: boolean;
}

export interface FavoriteCollection {
  [catalogItemId: string]: Favorite;
}

export interface Favorite {
  fav: boolean;
}

/**
 * Deprecated
 */
export interface NotificationOptions_v1 {
  email: boolean;
  push: boolean;
  pushTokens: string[];
  sms: boolean;
}

interface NotificationTypes {
  email: boolean;
  push: boolean;
  sms: boolean;
}

export interface NotificationOptions {
  pushTokens: string[];
  /**
   * Controls notifications for customer-related events, such as your
   * order changing status, or an admin sending you a message
   */
  customer: NotificationTypes;
  /**
   * Controls notifications to an admin when a customer pays for an
   * order or upcharge.
   */
  newOrder: NotificationTypes;
  /**
   * Controls notifications to an admin when a new message arrives
   */
  newMessage: NotificationTypes;
}

export interface GridbashUser {
  uid: string;
  claims: GridbashClaims;
}

export interface GridbashClaims {
  siteId?: string | null;
  role?: GridbashRole | null;
}

// They're identical. Admins login with gridbash, and then their claims
//   get copied over to the custom token for the site. Customers login
//   with the site, and have values of undefined and/or null.
export type SiteClaims = GridbashClaims;

export enum GridbashRole {
  superuser = "superuser",
  owner = "owner",
  admin = "admin",
  employee = "employee",
}

/**
 * Invitations for someone to join a site, usually as an employee.
 *
 * Stored in gridbash firestore at /invitations/{invitationId}
 */
export interface Invitation {
  siteId: string;
  email: string;
  siteName: string;
  timeCreated: number;
  timeSent: number;
  role?: GridbashRole;
}
