import type firebase from "firebase";
import { createContext } from "react";
import { GridbashRole } from "../../database/userData";

const GridbashUserContext = createContext<
  | {
      loading: boolean;
      user: firebase.User | null;
      role: GridbashRole | null;
      siteId: string | null;
    }
  | undefined
>(undefined);

GridbashUserContext.displayName = "Gridbash User";

export default GridbashUserContext;
