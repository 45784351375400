import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import { DeliveryOption } from "./checkoutMain";
import DeliveryIcon from "@material-ui/icons/LocalTaxi";
import { Button, ButtonGroup, Tooltip, Typography } from "@material-ui/core";
import useWindowResize from "../../../utilities/useWindowResize";

export interface DeliveryOptionSelectProps {
  deliveryOption: DeliveryOption;
  setDeliveryOption: React.Dispatch<React.SetStateAction<DeliveryOption>>;
  builder: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DeliveryOptionSelect: FC<DeliveryOptionSelectProps> = ({
  deliveryOption,
  setDeliveryOption,
  builder,
  onClick,
}) => {
  const { t } = useTranslation();

  const { theme2 } = useSiteSettings();

  const dimensions = useWindowResize("deliveryOptionSelect");

  return (
    <div
      className={!builder ? "checkoutButton" : undefined}
      style={{
        opacity: 1,
        display: "grid",
        gridTemplateColumns: dimensions.w >= 768 ? "12em 1fr" : "auto 1fr",
        gridAutoRows: "4em",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "1em",
      }}
      onClick={onClick}
    >
      <Tooltip
        title={
          !builder
            ? ""
            : `${t("dashboard.builder.primaryDark")} - ${t(
                "dashboard.builder.onPrimary"
              )}`
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: dimensions.w >= 768 ? "flex-start" : "center",
            paddingLeft: "1em",
            paddingRight: "1em",
            backgroundColor: theme2.colors.primaryDark.value,
            color: theme2.colors.onPrimary.value,
            height: "100%",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        >
          <DeliveryIcon />
          {dimensions.w >= 768 && (
            <React.Fragment>
              &nbsp;&nbsp;
              <Typography>{t("store.orders.deliveryOption")}</Typography>
            </React.Fragment>
          )}
        </div>
      </Tooltip>

      <Tooltip
        title={
          !builder
            ? ""
            : `${t("dashboard.builder.primaryLight")} - ${t(
                "dashboard.builder.accent"
              )} - ${t("dashboard.builder.onAccent")}`
        }
      >
        <div
          style={{
            backgroundColor: theme2.colors.primaryLight.value,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
          }}
        >
          <ButtonGroup color="inherit">
            <Button
              variant={deliveryOption === "delivery" ? "contained" : undefined}
              color={deliveryOption === "delivery" ? "secondary" : undefined}
              onClick={(e) => {
                e.stopPropagation();
                setDeliveryOption("delivery");
              }}
            >
              {t("store.orders.delivery")}
            </Button>
            <Button
              variant={deliveryOption === "pickup" ? "contained" : undefined}
              color={deliveryOption === "pickup" ? "secondary" : undefined}
              onClick={(e) => {
                e.stopPropagation();
                setDeliveryOption("pickup");
              }}
            >
              {t("store.orders.pickup")}
            </Button>
          </ButtonGroup>
        </div>
      </Tooltip>
    </div>
  );
};

export default DeliveryOptionSelect;
