import { Fragment, FC, useState } from "react";
import { useSettingsApi } from "./settingsApiContext";
import { useTranslation } from "react-i18next";
import OrderTimingInput from "./orderTimingInput";
import {
  DialogActions,
  DialogContent,
  Icon,
  Typography,
} from "@material-ui/core";

import { history } from "../../App/history";
import Button from "@material-ui/core/Button";
import MUIDialog from "../Components/mui-dialog";

export const defaultOrderIncrement = 15;

const OrderTimingSettings: FC<{ initPath: string }> = ({ initPath }) => {
  const { t } = useTranslation();
  const settingsApi = useSettingsApi();

  const [newMin, setNewMin] = useState(settingsApi.settings.orderIncrement);
  const [newMax, setNewMax] = useState(settingsApi.settings.orderMaximumTime);

  const [minAnchor, setMinAnchor] = useState<HTMLButtonElement | null>(null);
  const [maxAnchor, setMaxAnchor] = useState<HTMLButtonElement | null>(null);

  const changed =
    newMin !== settingsApi.settings.orderIncrement ||
    newMax !== settingsApi.settings.orderMaximumTime;

  return (
    <Fragment>
      {minAnchor && (
        <MUIDialog route={`${initPath}/min`}>
          <OrderTimingInput
            editing="min"
            initialValue={newMin}
            handleSubmit={(value) => {
              setNewMin(value);
            }}
          />
        </MUIDialog>
      )}
      {maxAnchor && (
        <MUIDialog route={`${initPath}/max`}>
          <OrderTimingInput
            editing="max"
            initialValue={newMax}
            handleSubmit={(value) => {
              setNewMax(value);
            }}
          />
        </MUIDialog>
      )}
      <DialogContent className="smartWidthMd">
        <Typography variant="h6">
          {t("dashboard.settings.orderTiming.minOrderTime")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("dashboard.settings.orderTiming.minOrderTime2")}
        </Typography>
        <div style={{ marginTop: "0.5em" }} />
        <Typography>
          <Duration duration={newMin} />
        </Typography>

        <Button
          startIcon={<Icon>arrow_right</Icon>}
          onClick={(e) => {
            e.stopPropagation();

            const path = `${initPath}/min`;
            if (!window.location.pathname.includes(path)) history.push(path);
            setMinAnchor(e.currentTarget);
          }}
        >
          Edit
        </Button>
        <div style={{ marginTop: "1em" }} />
        <Typography variant="h6">
          {t("dashboard.settings.orderTiming.maxOrderTime")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("dashboard.settings.orderTiming.maxOrderTime2")}
        </Typography>
        <div style={{ marginTop: "0.5em" }} />
        <Typography>
          <Duration duration={newMax} />
        </Typography>

        <Button
          startIcon={<Icon>arrow_right</Icon>}
          onClick={(e) => {
            e.stopPropagation();
            const path = `${initPath}/max`;
            if (!window.location.pathname.includes(path)) history.push(path);
            setMaxAnchor(e.currentTarget);
          }}
        >
          Edit
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            history.goBack();
          }}
        >
          {t("dashboard.cancel")}
        </Button>
        <Button
          disabled={!changed}
          onClick={(e) => {
            e.stopPropagation();
            if (changed) {
              settingsApi.editSettings({
                orderIncrement: newMin,
                orderMaximumTime: newMax,
              });
              history.goBack();
            }
          }}
        >
          {t("dashboard.submit")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export const Duration = ({ duration }: { duration: number }) => {
  const { t } = useTranslation();
  const days = t("dashboard.settings.orderTiming.days", {
    count: Math.floor(duration / 1440),
  });
  const hours = t("dashboard.settings.orderTiming.hours", {
    count: Math.floor(duration / 60) % 24,
  });
  const minutes = t("dashboard.settings.orderTiming.minutes", {
    count: duration % 60,
  });

  return (
    <span>
      {days} {hours} {minutes}
    </span>
  );
};

export default OrderTimingSettings;
