import { FC, useState } from "react";
import * as React from "react";
import { CatalogItem_WithDefaults } from "../../../database/catalogItem";
import { getUnitName } from "../../../utilities/getUnitName";
import { useTranslation } from "react-i18next";
import { checkUnitRules } from "../../CartManager/checkUnitRules";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import useToast from "../../Main/useToast";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import SubtractIcon from "@material-ui/icons/IndeterminateCheckBox";
import AddIcon from "@material-ui/icons/AddBox";
import useWindowResize from "../../../utilities/useWindowResize";
import { DialogContent, Typography } from "@material-ui/core";
import NumberInput from "../../Dashboard/Components/numberInput";
import { history } from "../../App/history";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import { useLocation } from "react-router";

interface ItemCounterProps {
  catalogItem: CatalogItem_WithDefaults;
  count: number;
  setCount: (value: number) => void;
  removeConfirm?: (e: React.SyntheticEvent, previousValue: number) => void;
}
const ItemCounter: FC<ItemCounterProps> = React.memo(
  ({ catalogItem, count, setCount, removeConfirm }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const location = useLocation<{ itemCount?: boolean } | undefined>();

    const [valueBeforeEdit, setValueBeforeEdit] = useState(count);

    const dimensions = useWindowResize("addToCart");

    const checkUnits = () => {
      const result = checkUnitRules({
        catalogItem,
        count,
        t,
      });
      if (result) {
        setCount(result.adjustedCount);

        toast({
          message: result.message,
          color: "normal",
        });
      }
    };

    return (
      <React.Fragment>
        {dimensions.touch ? (
          <React.Fragment>
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (location.state?.itemCount !== true) {
                  history.push(location.pathname, {
                    ...location.state,
                    itemCount: true,
                  });
                }
              }}
            >
              <Typography variant="h5">{count}</Typography>
            </div>
            <MUIDialog
              route={""}
              stateMatch={(state) => state.itemCount === true}
            >
              <DialogContent>
                <FormControl fullWidth>
                  <InputLabel>{t("store.orders.quantity")}</InputLabel>
                  <NumberInput
                    fullWidth
                    onFocus={() => {
                      // Remember what it started at, in case we need to revert
                      setValueBeforeEdit(count);
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      const valueNum = Number(value);
                      const minValue = catalogItem.unit?.minValue ?? 1;
                      if (removeConfirm && valueNum < minValue) {
                        removeConfirm(e, valueBeforeEdit);
                        return;
                      }
                      setCount(valueNum);
                      checkUnits();
                    }}
                    inputProps={{
                      step: catalogItem.unit?.increment ?? 1,
                      min: catalogItem.unit?.minValue ?? 1,
                      max: catalogItem.unit?.maxValue,
                      style: { textAlign: "center", fontSize: "1.5rem" },
                    }}
                    onChange={(e, value) => {
                      setCount(value);
                    }}
                    value={count}
                    disabled={!catalogItem}
                  />
                </FormControl>
              </DialogContent>
            </MUIDialog>
          </React.Fragment>
        ) : (
          <NumberInput
            fullWidth
            onFocus={() => {
              // Remember what it started at, in case we need to revert
              setValueBeforeEdit(count);
            }}
            style={{ borderBottom: "none" }}
            onBlur={(e) => {
              const value = e.target.value;
              const valueNum = Number(value);
              const minValue = catalogItem.unit?.minValue ?? 1;
              if (removeConfirm && valueNum < minValue) {
                removeConfirm(e, valueBeforeEdit);
                return;
              }
              setCount(valueNum);
              checkUnits();
            }}
            inputProps={{
              step: catalogItem.unit?.increment ?? 1,
              min: catalogItem.unit?.minValue ?? 1,
              max: catalogItem.unit?.maxValue,
              style: { textAlign: "center", fontSize: "1.5rem" },
            }}
            value={count}
            disabled={!catalogItem}
            onChange={(e, value) => {
              setCount(value);
            }}
          />
        )}
        {catalogItem?.unit ? (
          <Typography align="center">
            {getUnitName(catalogItem.unit, 1000, t)}
          </Typography>
        ) : undefined}

        {dimensions.touch && (
          <div style={{ textAlign: "center", marginTop: "0.5em" }}>
            <ButtonGroup variant="contained">
              <Button
                color="primary"
                disabled={count <= (catalogItem.unit?.minValue ?? 1)}
                onClick={(e) => {
                  e.stopPropagation();
                  const newCount = count - (catalogItem.unit?.increment ?? 1);
                  const minValue = catalogItem.unit?.minValue ?? 1;
                  if (newCount < minValue && removeConfirm) {
                    removeConfirm(e, valueBeforeEdit);
                    return;
                  }

                  const result = checkUnitRules({
                    catalogItem,
                    count: newCount,
                    t,
                  });
                  setCount(result?.adjustedCount ?? newCount);
                }}
              >
                <SubtractIcon />
              </Button>
              <Button
                color="primary"
                disabled={
                  count >=
                  (catalogItem.unit?.maxValue ?? Number.MAX_SAFE_INTEGER)
                }
                onClick={(e) => {
                  e.stopPropagation();
                  const newCount = count + (catalogItem.unit?.increment ?? 1);
                  const result = checkUnitRules({
                    catalogItem,
                    count: newCount,
                    t,
                  });
                  setCount(result?.adjustedCount ?? newCount);
                }}
              >
                <AddIcon />
              </Button>
            </ButtonGroup>
          </div>
        )}
      </React.Fragment>
    );
  }
);

export default ItemCounter;
