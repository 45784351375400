import * as R from "ramda";
import {
  PageCell,
  PageWidgetPropMap,
  PageWidgetType,
} from "../../../../Store/PageRenderer/widgetType";
import uuid from "uuid/v4";
import { catalogItemTemplateId } from "../../../../Store/PageRenderer/widgets/catalogItemWidget";
import { ThemeColor } from "../../../../../database/theme";

export const createCell = <T extends PageWidgetType>(type: T): PageCell<T> => ({
  id: uuid(),
  type,
  props: R.clone(defaultProps[type]),
});

const defaultProps: {
  [key in PageWidgetType]: PageWidgetPropMap[key];
} = {
  [PageWidgetType.row]: {
    columns: [
      {
        width: 50,
        cellAlign: "center",
        cells: [],
      },
      {
        width: 50,
        cellAlign: "center",
        cells: [],
      },
    ],
  },
  [PageWidgetType.textOrIcon]: {
    fancyText: { text: "Title", fullWidth: true },
  },
  [PageWidgetType.navLinks]: {
    variant: "menu",
    fancyText: { text: "", iconPosition: "left" },
    highlightColor: { literal: ThemeColor.accent },
    scrollType: "multiple",
    itemsInView: 0, //AUTO
  },
  [PageWidgetType.presetText]: {
    fancyText: { text: "" },
  },
  [PageWidgetType.wealthyText]: {
    text: "",
    boxShadowX: 0,
    boxShadowY: 0,
    boxShadowSpread: 0,
    boxShadowBlur: 0,
    boxShadowColor: "#000000",
    borderWidth: 0,
    borderRadius: 0,
    borderStyle: "solid",
    borderColor: "#000000",
    backgroundColor: undefined,
  },
  [PageWidgetType.button]: {
    fancyText: {
      text: "button",
      align: "center",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      borderBottomLeftRadius: 4,
    },
    variant: "contained",
    action: null,
    animate: true,
  },
  [PageWidgetType.image]: {
    url: "",
    urls: {},
    widthWeight: 1,
    topWeight: 0.5,
    leftWeight: 0.5,
    aspectRatio: 16 / 9,
    boxShadowX: 0,
    boxShadowY: 0,
    boxShadowSpread: 0,
    boxShadowBlur: 0,
    boxShadowColor: "#000000",
    borderWidth: 0,
    borderRadius: 0,
    borderStyle: undefined,
    borderColor: "#000000",
  },
  [PageWidgetType.customWidget]: {
    templateId: "",
  },
  [PageWidgetType.catalogItem]: {
    templateId: catalogItemTemplateId,
    catalogItemId: null,
  },
  [PageWidgetType.catalogGrid]: {
    itemTemplateId: catalogItemTemplateId,
    catalogId: null,
    breakpointMd: 768,
    breakpointLg: 1500,
    columnsSm: 1,
    columnsMd: 2,
    columnsLg: 3,
  },
  [PageWidgetType.textLabels]: {
    overrides: {
      fullWidth: false,
      marginLeft: 2,
      marginRight: 2,
    },
  },
  [PageWidgetType.filterLabels]: {
    overrides: {
      fullWidth: false,
      marginLeft: 2,
      marginRight: 2,
    },
  },
  [PageWidgetType.navDrawer]: {},
  [PageWidgetType.cartIcon]: {
    fancyText: { text: "", align: "center", icon: "add_shopping_cart" },
    variant: "text",
    action: null,
    animate: false,
  },
  [PageWidgetType.favoriteIcon]: {
    fancyText: {
      text: "",
      align: "center",
      icon: "favorite",
    },
    variant: "text",
    action: null,
    animate: false,
  },
  [PageWidgetType.emptySpace]: {
    height: 30,
    boxShadowX: 0,
    boxShadowY: 0,
    boxShadowSpread: 0,
    boxShadowBlur: 0,
    boxShadowColor: "#000000",
    borderWidth: 0,
    borderRadius: 0,
    borderStyle: undefined,
    borderColor: "#000000",
    backgroundColor: undefined,
    dividerHeight: 0,
    dividerRadius: 0,
    dividerStyle: "solid",
    dividerColor: "#000000",
  },
  [PageWidgetType.widgetPicker]: {
    title: "",
  },
};
