import { optionType, Option } from "../database/option";
import { CatalogItem_WithDefaults } from "../database/catalogItem";
import {
  CartItem,
  OptionSelection,
  ListOptionSelection,
  NumberOptionSelection,
} from "../database/cart";
import { useState, useEffect } from "react";
import usePrevious from "./usePrevious";
import * as R from "ramda";

export const getDefaultSelections = (
  catalogItem: CatalogItem_WithDefaults | undefined,
  cartItem?: CartItem
): OptionSelection[] => {
  if (!catalogItem) {
    return [];
  } else if (cartItem?.optionSelections) {
    // If the item is in the cart, use the options they have selected
    return cartItem.optionSelections;
  } else {
    // Otherwise, use the default options from the catalog
    return catalogItem.options.map(getDefaultSelectionForOption);
  }
};

export const getDefaultSelectionForOption = (
  option: Option
): OptionSelection => {
  switch (option.type) {
    case optionType.LIST: {
      const selectedItems: { [id: string]: boolean } = {};
      option.items.forEach((optionItem) => {
        if (optionItem.selectedByDefault) {
          selectedItems[optionItem.id] = true;
        }
      });
      const selection: ListOptionSelection = {
        type: optionType.LIST,
        optionId: option.optionId,
        selectedItems,
      };
      return selection;
    }
    case optionType.NUMBER: {
      const selection: NumberOptionSelection = {
        type: optionType.NUMBER,
        optionId: option.optionId,
        value: option.defaultValue,
      };
      return selection;
    }
  }
};

/**
 * Creates state for selections, starting with defaults and resetting
 * to defaults if new data is published
 */
export const useSelectedOptions = (
  catalogItem: CatalogItem_WithDefaults,
  cartItem?: CartItem
): [
  OptionSelection[],
  React.Dispatch<React.SetStateAction<OptionSelection[]>>
] => {
  const [selectedOptions, setSelectedOptions] = useState(() =>
    getDefaultSelections(catalogItem, cartItem)
  );

  const previousOptions = usePrevious(catalogItem.options);
  useEffect(() => {
    if (previousOptions && catalogItem.options !== previousOptions) {
      // We may have new options. Check in greater detail
      if (!R.equals(catalogItem.options, previousOptions)) {
        // We *do* have new options. Set new defaults
        setSelectedOptions(getDefaultSelections(catalogItem, cartItem));
      }
    }
  }, [cartItem, catalogItem, previousOptions]);

  return [selectedOptions, setSelectedOptions];
};
