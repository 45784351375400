import { Fragment, FC, useMemo, useState } from "react";
import { useSiteSettings } from "../../../customization/siteSettingsContext";
import ImageIcon from "@material-ui/icons/Image";
import ROUTES from "../../../utilities/constants/routes";
import { history } from "../../App/history";
import MUIDialog from "../../Dashboard/Components/mui-dialog";
import { useTranslation } from "react-i18next";
import useToast from "../../Main/useToast";
import useCartApi from "../../CartManager/useCartApi";
import { Order, orderStatus, revisionStatus } from "../../../database/order";
import { trackingStatusStrings } from "./track";
import { trackingStatusIcons } from "./track";
import WarningIcon from "@material-ui/icons/Warning";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import getOrderIdStrings from "../../../utilities/getOrderIdString";
import Button from "@material-ui/core/Button";
import { useMessageThread } from "../../../utilities/useMessages";
import { Message } from "../../../database/message";
import Details from "./details";
import Typography from "@material-ui/core/Typography";
import { srcSetString } from "../../Main/main";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

interface ItemTrackCardProps {
  order: Order;
}

const ItemTrackCard: FC<ItemTrackCardProps> = ({ order }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { theme2, chargesEnabled } = useSiteSettings();

  const latestRevision = order.revisions[order.revisions.length - 1];

  const needsPaymentAuth =
    latestRevision.status === revisionStatus.pendingauth ||
    latestRevision.status === revisionStatus.lockedForPayment;
  const needsAddress = order.delivery.delivery && !order.recipient.address;

  const { thread } = useMessageThread(order.messageThreadId);

  let icon;
  if (needsPaymentAuth || needsAddress) {
    icon = <WarningIcon color="secondary" />;
  } else if (
    thread &&
    Object.values(thread.messages).some(
      (message: Message) => message.from === "admin" && !message.read
    )
  ) {
    icon = <AnnouncementIcon color="secondary" />;
  } else {
    const Component = trackingStatusIcons[order.status];
    icon = <Component color="primary" />;
  }

  const orderIdStrings = getOrderIdStrings(order.orderId);

  const isPast =
    order.status === orderStatus.ready ||
    order.status === orderStatus.intransit ||
    order.status === orderStatus.delivered;

  const { restoreCart } = useCartApi();

  const [imageInfo, setImageInfo] = useState<{
    loaded: boolean;
    error: boolean;
  }>({
    loaded: false,
    error: false,
  });

  const itemCount = useMemo(() => {
    let result = 0;
    Object.values(order.cumulativeCartItems).forEach((cartItem) => {
      if (cartItem.catalogItem.unit) {
        // For the purpose of the item count label, we treat a value of
        //   500 grams as being 1, not 500.
        result += 1;
      } else {
        result += cartItem.count;
      }
    });
    return result;
  }, [order]);

  const exampleItem = useMemo(() => {
    // Find the first item with an image url
    for (const cartItem of Object.values(order.cumulativeCartItems)) {
      if (cartItem.catalogItem.imageUrl) {
        return cartItem.catalogItem;
      }
    }
    return undefined;
  }, [order.cumulativeCartItems]);

  const orderAgain = async () => {
    if (!isPast || !chargesEnabled) {
      return;
    }
    const failureMessage = await restoreCart(order.revisions[0].itemsAdded);
    if (failureMessage) {
      toast({
        dialog: true,
        color: "error",
        message: failureMessage,
      });
    } else {
      toast({
        duration: 2000,
        color: "normal",
        message: t("store.track.reoderConfirmation"),
      });
    }
  };

  return (
    <Fragment>
      <MUIDialog route={`${ROUTES.TRACK}/${order.orderId}`}>
        <Details
          order={order}
          orderIdString={`${orderIdStrings.mainId} - ${orderIdStrings.subId}`}
          isPast={isPast}
          initPath={`${ROUTES.TRACK}/${order.orderId}`}
          orderAgain={orderAgain}
        />
      </MUIDialog>
      <div
        className={
          imageInfo.loaded ||
          imageInfo.error ||
          !exampleItem ||
          !exampleItem.imageUrl
            ? "anim_moveUp_small_0303 boxShadow1"
            : ""
        }
        style={{
          opacity: 0,
          borderRadius: "4px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          const path = `${ROUTES.TRACK}/${order.orderId}`;
          if (!window.location.pathname.includes(path)) history.push(path);
        }}
      >
        <div
          style={{
            padding: "0.5em 1em",
            backgroundColor: theme2.colors.primaryLight.value,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography noWrap align="left" color="textPrimary">
            {order.delivery.timeCreated.toDate().toLocaleDateString([], {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
          {icon}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min(25vw, 7em) 1fr",
            gap: "1em",
            marginBottom: "1em",
            padding: "1em",
            backgroundColor: theme2.colors.primaryLight.value,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: exampleItem?.imageUrl && imageInfo.error ? 0.5 : 1,
            }}
          >
            {exampleItem?.imageUrl && imageInfo.error ? (
              <ImageIcon />
            ) : !exampleItem?.imageUrl ? (
              <span></span>
            ) : (
              <img
                alt={exampleItem.name}
                src={exampleItem.imageUrl}
                /**
                 * SRCSET EXPLAINED
                 *
                 * IN DEV TOOLS
                 * THE DOWNLOADED IMG IS INDICATED BY INTRINSIC VALUE
                 * THE RENDERED SIZE INDICATES HOW MUCH WIDTH THE IMAGE IS
                 * ACTUALLY TAKING UP.
                 *
                 * THE GOAL IS TO DOWNLOAD (INTRINSIC) THE IMAGE RESOLUTION
                 * EQUAL TO OR THE NEXT ONE UP OF THE RENDERED VALUE
                 */
                srcSet={srcSetString(exampleItem.imageUrls)}
                /**
                 * SIZES EXPLAINED
                 *
                 * WE ARE SETTING THE SIZE TO THE WIDTH SET IN THE STYLE
                 * FOR THE LEFT GRID COLUMN ABOVE
                 */
                sizes="min(25vw, 7em)"
                onLoad={() => {
                  setImageInfo({ loaded: true, error: false });
                }}
                onError={() => {
                  setImageInfo({ loaded: true, error: true });
                }}
                className="boxShadow2"
                style={{
                  objectFit: "cover",
                  objectPosition: exampleItem.imageZoom
                    ? `${exampleItem.imageZoom.x}% ${exampleItem.imageZoom.y}%`
                    : "center",
                  verticalAlign: "middle",
                  width: "100%",
                  maxWidth: "min(25vw, 7em)",
                  height: "100%",
                  maxHeight: "min(25vw, 7em)",
                  opacity: imageInfo.loaded ? 1 : 0,
                  transition: "opacity 0.5s ease 0.5s",
                  borderRadius: "5px",
                }}
              />
            )}
          </div>

          <div style={{ overflow: "hidden", position: "relative" }}>
            <Typography noWrap align="left" color="primary">
              {needsPaymentAuth
                ? t("store.track.awaitingPayment")
                : needsAddress
                ? t("store.track.missingDeliveryOptions")
                : t(
                    trackingStatusStrings[order.status] ||
                      "store.track.unknownStatus"
                  )}
            </Typography>
            <Typography
              noWrap
              variant="body2"
              color="textSecondary"
              align="left"
            >
              {t("store.track.itemCount", { count: itemCount })}
            </Typography>
            <Typography
              noWrap
              variant="body2"
              component="div"
              color="textSecondary"
              align="left"
              style={{
                fontFamily: "Arial",
                fontVariant: "slashed-zero",
                fontVariantNumeric: "slashed-zero",
              }}
            >
              {`${orderIdStrings.mainId} - ${orderIdStrings.subId}`}
            </Typography>

            <div
              style={{
                textAlign: "right",
                opacity: isPast && chargesEnabled ? 1 : 0,
              }}
            >
              <Button
                onClick={async (e) => {
                  e.stopPropagation();
                  orderAgain();
                }}
                startIcon={<ArrowRightIcon />}
              >
                {t("store.orders.reOrder")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ItemTrackCard;
