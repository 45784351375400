import firebase from "firebase";
import { FC, useMemo, useState } from "react";
import { useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { GridbashClaims } from "../../database/userData";
import { useGridBashFirebase } from "../../Firebase/context";
import GridbashUserContext from "./gridbashUserContext";

/**
 * Listens for changes to the gridbash user, and makes it available
 * to the rest of the app.
 */
const GridbashUserProvider: FC = ({ children }) => {
  const gridbashFirebase = useGridBashFirebase();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [claims, setClaims] = useState<GridbashClaims>({});

  useEffect(() => {
    return gridbashFirebase.auth.onAuthStateChanged(async (user) => {
      let gridbashClaims: GridbashClaims;
      if (!user) {
        gridbashClaims = {};
      } else {
        const { claims } = await user.getIdTokenResult();
        gridbashClaims = {
          role: claims.role,
          siteId: claims.siteId,
        };
      }
      unstable_batchedUpdates(() => {
        setUser(user);
        setClaims(gridbashClaims);
        setLoading(false);
      });
    });
  }, [gridbashFirebase.auth]);

  const value = useMemo(() => {
    return {
      loading,
      user,
      role: claims.role ?? null,
      siteId: claims.siteId ?? null,
    };
  }, [loading, user, claims]);

  return (
    <GridbashUserContext.Provider value={value}>
      {children}
    </GridbashUserContext.Provider>
  );
};

export default GridbashUserProvider;
