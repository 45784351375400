import { FC, useMemo, useState, useCallback } from "react";
import {
  useLocationId,
  useRawSiteSettings,
} from "../../../customization/siteSettingsContext";
import * as R from "ramda";
import { SettingsApiContext } from "./settingsApiContext";
import { PrivateSiteSettings } from "../../../database/privateSiteSettings";
import { storeKey } from "../../../Firebase/siteFirebase";
import { SiteSettings } from "../../../database/siteSettings";
import { useSiteFirebase } from "../../../Firebase/context";
import { deleteUndefinedsRecursive } from "../../../utilities/deleteUndefineds";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";
import { useTranslation } from "react-i18next";
import useToast from "../../Main/useToast";
import { usePrivateSiteSettings } from "../../../utilities/usePrivateSiteSettings";

const SettingsApiProvider: FC<{}> = (props) => {
  const { t } = useTranslation();
  const locationId = useLocationId();
  const firebase = useSiteFirebase();
  const toast = useToast();
  const settingsInDb = useRawSiteSettings();
  /**
   * Stores edits that are in progress locally
   */
  const [settingsEdit, setSettingsEdit] = useState<Partial<SiteSettings>>({});
  /**
   * Combines the local changes with the data in the database. This what the
   * settings will look like if they publish the changes.
   */
  const mergedSettings = useMemo(() => {
    return {
      ...settingsInDb,
      // Writing to firestore can't include explicit undefineds, so we delete them
      ...deleteUndefinedsRecursive(settingsEdit),
    };
  }, [settingsEdit, settingsInDb]);
  let settingsDirty = useMemo(() => {
    return !R.equals(settingsInDb, mergedSettings);
  }, [settingsInDb, mergedSettings]);

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});

  const privateSettingsInDb = usePrivateSiteSettings();
  /**
   * Stores edits that are in progress locally
   */
  const [privateSettingsEdit, setPrivateSettingsEdit] = useState<
    Partial<PrivateSiteSettings>
  >({});
  /**
   * Combines the local changes with the data in the database. This what the
   * private settings will look like if they publish the changes.
   */
  const mergedPrivateSettings = useMemo(() => {
    if (!privateSettingsInDb) {
      // loading
      return null;
    }
    return {
      ...privateSettingsInDb,
      // Writing to firestore can't include explicit undefineds, so we delete them
      ...deleteUndefinedsRecursive(privateSettingsEdit),
    };
  }, [privateSettingsEdit, privateSettingsInDb]);
  const privateSettingsDirty = useMemo(() => {
    return !R.equals(privateSettingsInDb, mergedPrivateSettings);
  }, [mergedPrivateSettings, privateSettingsInDb]);

  const editSettings = useCallback(
    (value: Partial<SiteSettings>) => {
      setSettingsEdit((prev) => ({
        ...prev,
        ...value,
      }));

      if (
        process.env.NODE_ENV === "development" &&
        Object.keys(value).length === Object.keys(mergedSettings).length
      ) {
        // Our old code used to set the entire object. In order to minimize conflicts
        //   when two admins are editing, we now set only partial objects, so the rest
        //   can be merged with the values in the database
        console.warn(
          "Don't set the entire settings object, just set the part you've changed."
        );
      }
    },
    [mergedSettings]
  );

  const editPrivateSettings = useCallback(
    (value: Partial<PrivateSiteSettings>) => {
      setPrivateSettingsEdit((prev) => ({
        ...prev,
        ...value,
      }));
    },
    []
  );

  const revert = useCallback(() => {
    setSettingsEdit({});
    setPrivateSettingsEdit({});
  }, []);

  const publish = useCallback(async () => {
    try {
      let promises = [];
      if (settingsDirty) {
        promises.push(
          firebase.firestore
            .collection("stores")
            .doc(storeKey)
            .collection("locations")
            .doc(locationId)
            .set(mergedSettings)
        );
      }
      if (privateSettingsDirty && mergedPrivateSettings) {
        promises.push(
          firebase.firestore
            .collection("stores")
            .doc(storeKey)
            .collection("locations")
            .doc(locationId)
            .collection("private")
            .doc("privateSettings")
            .set(mergedPrivateSettings)
        );
      }
      await Promise.all(promises);

      revert();
      // TESTED 2/8/21
      toast({
        message: t("dashboard.toast.successPublish"),
        duration: 2000,
      });
    } catch (err) {
      if (err.code === "permission-denied") {
        toast({
          dialog: true,
          color: "error",
          message: t("dashboard.settings.permissionDenied"),
          duration: TOASTDURATION_ERROR,
        });
      } else {
        console.log("error publishing settings", err);
        // TESTED 2/8/21
        toast({
          dialog: true,
          color: "error",
          message: t("toast.systemError"),
          duration: TOASTDURATION_ERROR,
        });
      }
    }
  }, [
    settingsDirty,
    privateSettingsDirty,
    mergedPrivateSettings,
    revert,
    toast,
    t,
    firebase.firestore,
    locationId,
    mergedSettings,
  ]);

  const value = useMemo(
    () => ({
      settings: mergedSettings,
      editSettings,
      settingsInDb: settingsInDb,
      settingsDirty,
      publish,
      revert,
      privateSettings: mergedPrivateSettings,
      editPrivateSettings,
      privateSettingsInDb: privateSettingsInDb ?? null,
      privateSettingsDirty,
      validationErrors,
      setValidationErrors,
      valid: Object.keys(validationErrors).length === 0,
    }),
    [
      mergedSettings,
      editSettings,
      settingsInDb,
      settingsDirty,
      publish,
      revert,
      mergedPrivateSettings,
      editPrivateSettings,
      privateSettingsInDb,
      privateSettingsDirty,
      validationErrors,
    ]
  );

  return (
    <SettingsApiContext.Provider value={value}>
      {props.children}
    </SettingsApiContext.Provider>
  );
};

export default SettingsApiProvider;
