import { PageConfig, PageRow } from "../../../../database/page";
import uuid from "uuid/v4";
import { PageWidgetType } from "../../../Store/PageRenderer/widgetType";
import { ThemeColor } from "../../../../database/theme";

export const createPage = (pageId: string) => {
  const defaultPage: PageConfig = {
    pageId,
    path: null,
    rows: [],
  };
  return defaultPage;
};

export const defaultNavRow: PageRow = {
  id: uuid(),
  type: PageWidgetType.row,
  props: {
    columns: [
      {
        width: 25,
        cellAlign: "center",
        cells: [
          {
            id: uuid(),
            type: PageWidgetType.textOrIcon,
            props: {},
          },
        ],
      },
      {
        width: 75,
        cellAlign: "center",
        cells: [
          {
            id: uuid(),
            type: PageWidgetType.navLinks,
            props: {
              variant: "menu",
              fancyText: { text: "" },
              highlightColor: { literal: ThemeColor.accent },
              scrollType: "multiple",
              itemsInView: 0, //AUTO
            },
          },
        ],
      },
    ],
  },
};
