import { useCallback } from "react";
import { useSiteSettings } from "../../customization/siteSettingsContext";
import { SiteSettings } from "../../database/siteSettings";
import { ColorConfig } from "./colorConfig";

export const useThemeColor = (siteSettingsOverride?: SiteSettings) => {
  const siteSettings = useSiteSettings();
  const theme2 = siteSettingsOverride?.theme2 ?? siteSettings.theme2;
  const calculateColor = useCallback(
    (color: ColorConfig | undefined) => {
      if (!color) {
        return undefined;
      } else if (color.literal) {
        return color.literal;
      } else if (color.key) {
        return theme2.colors[color.key]?.value;
      } else {
        return undefined;
      }
    },
    [theme2.colors]
  );

  return calculateColor;
};
